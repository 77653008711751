import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { Stack } from '@mui/material';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import { useForm } from '../components/BasicForm';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import TeamTimeUserList from '../components/TeamTime/TeamTimeUserList';
import Timezone from '../components/Util/Timezone';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useGo2Members } from '../hooks/member';
import { useUserProvider } from '../providers/user/user';
import { UserMetadata } from '../types/profile';
import { HHMMSSOptionsJSONRet, getWeekRange, toHHMMSS } from '../utils/date';
import { UseFetchTimeDetailsResponse } from '../hooks/edit-time';
import { IterableObject } from '../types/types';
import { getUserProfileDisplayName } from '../utils/user';
import { sortBy } from '../utils/array';
import { exportLinkToCSV } from '../utils/download';

type Props = {};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

type ExportWorklogItem = {
  teammate: string;
  date: string;
  start_time: string;
  end_time: string;
  total_time: string;
  task: string;
};

const TeamTimeV2: React.FC<Props> = () => {
  const { current } = useWorkspaceProvider();
  const { teamScheduleParams, setTeamScheduleParams } = useMetadataProvider();
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();

  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const teamTimeUserListDataRef = useRef<
    IterableObject<UseFetchTimeDetailsResponse[]>
  >({});

  const dateForm = useForm<DateFormState>({
    start: teamScheduleParams.date_start,
    end: teamScheduleParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });

  const divisionGo2Members = useGo2Members({
    division: current!.id === 'my-squad' ? undefined : +current!.id,
    employee_ids: current!.id === 'my-squad' ? current!.memberId : undefined,
    limit: 300,
    is_active: true,
  });
  const userList = useMemo(() => {
    let usersMetadata: UserMetadata[] = [];
    divisionGo2Members.data?.results.forEach((item) => {
      usersMetadata.push(getUser('staff_id', +item.id)!);
    });
    return usersMetadata.filter((u) => !!u);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, divisionGo2Members.data]);

  const [range, setRange] = useState(() => [
    getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
      'YYYY-MM-DD',
    ),
    getWeekRange(moment().endOf('week').add(1, 'day')).end.format('YYYY-MM-DD'),
  ]);

  const isToday = moment();
  const isCurrentWeek =
    isToday.isSameOrAfter(range[0]) && isToday.isSameOrBefore(range[1]);

  const handlePrevWeek = () => {
    setRange((range) =>
      range.map((d) => moment(d).subtract(1, 'week').format('YYYY-MM-DD')),
    );
  };

  const handleNextWeek = () => {
    setRange((range) =>
      range.map((d) => moment(d).add(1, 'week').format('YYYY-MM-DD')),
    );
  };

  const handleOnResetDateToThisWeek = () => {
    setRange(() => [
      getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
        'YYYY-MM-DD',
      ),
      getWeekRange(moment().endOf('week').add(1, 'day')).end.format(
        'YYYY-MM-DD',
      ),
    ]);
  };

  const handleOnDownloadWorklogs = useCallback(() => {
    const userGroups = teamTimeUserListDataRef.current;
    let json: ExportWorklogItem[] = [];

    Object.keys(userGroups).forEach((userId) => {
      const user = getUser('user_id', userId);
      const data = userGroups[userId];

      if (!user) return;

      sortBy(data, 'start_time_local', true, 'asc', 'date').forEach((d) => {
        const hms = toHHMMSS(d.duration, {
          json: true,
          hrDigit: 1,
          minDigit: 1,
        }) as HHMMSSOptionsJSONRet;
        const { hours, minutes } = hms;

        json.push({
          teammate: getUserProfileDisplayName(user).fullName!,
          date: d.start_time_local.format('YYYY-MM-DD'),
          start_time: d.start_time_local.format('hh:mm a'),
          end_time: d.end_time_local!.format('hh:mm a'),
          total_time: hours
            ? `${hours}h ${minutes}min`
            : minutes
            ? `${minutes}min`
            : '',
          task: d.task_name,
        });
      });
    });

    json = sortBy<ExportWorklogItem>(json, 'teammate', true, 'ASC');

    const csvHeaders = [
      'teammate',
      'date',
      'start_time',
      'end_time',
      'total_time',
      'task',
    ];

    exportLinkToCSV({
      data: json,
      csvHeaders,
      csvHeaderKeys: csvHeaders,
      filename: `time_${range[0]}_${[range[1]]}`,
    });
  }, [teamTimeUserListDataRef, getUser, range]);

  useEffect(() => {
    setTeamScheduleParams({
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState.start, dateForm.formState.end]);

  useEffect(() => {
    if (divisionGo2Members.data) {
      let user_ids: number[] = [];
      let staff_ids: number[] = [];
      divisionGo2Members.data.results.forEach((item) => {
        staff_ids.push(+item.id);
        user_ids.push(+item.reports_to);
      });
      setUniqueIdsToFetch({ staff_ids, user_ids });
    }
    // eslint-disable-next-line
  }, [divisionGo2Members.data]);

  return (
    <Fragment>
      <DocumentTitle title='Time' trailingTitle='Team' />
      <MainContainer
        ref={mainContainerRef}
        sx={{
          maxWidth: null,
        }}
      >
        <AppActionToolbar>
          <Stack direction='row' gap={2} alignItems={'center'}>
            <M3IconButton
              onClick={handlePrevWeek}
              sx={{
                marginLeft: -2,
              }}
            >
              <ArrowLeftOutlinedIcon sx={{ left: -1, position: 'relative' }} />
            </M3IconButton>
            <FilterColumnPanel
              name='date'
              label='Date'
              displayValue={`${moment(range[0]).format(
                'MMM D, YYYY',
              )} - ${moment(range[1]).format('MMM D, YYYY')}`}
              paperSx={{ ml: 1 }}
              textFieldSx={{ width: 240, pointerEvents: 'none' }}
            />
            <M3IconButton onClick={handleNextWeek}>
              <ArrowLeftOutlinedIcon
                sx={{
                  left: 1,
                  transform: 'rotateZ(180deg)',
                  position: 'relative',
                }}
              />
            </M3IconButton>
            {!isCurrentWeek && (
              <M3Button onClick={handleOnResetDateToThisWeek}>
                This week
              </M3Button>
            )}
            <M3IconButton
              size='large'
              sx={{ width: 50, height: 50 }}
              onClick={handleOnDownloadWorklogs}
            >
              <FileDownloadOutlinedIcon />
            </M3IconButton>
          </Stack>
        </AppActionToolbar>
        <Timezone />
        <br />
        <TeamTimeUserList
          teamTimeUserListDataRef={teamTimeUserListDataRef}
          users={userList}
          range={range}
        />
      </MainContainer>
    </Fragment>
  );
};

export default TeamTimeV2;
