import { UseQueryOptions } from '@tanstack/react-query';
import { JobSeekerItemResponse } from '../types/jobseeker';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

export type UseJobseekersProps = ListQuery & {
  is_active_user?: boolean;
};
export function useJobseekers<R = JobSeekerItemResponse>(
  params: UseJobseekersProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseJobseekersProps>({
    params,
    baseIdentifier: 'useJobseekers',
    identifierKeys: ['search'],
    queryParamKeys: ['is_active_user'],
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/career-center/jobseekers/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
