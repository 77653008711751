import React from 'react';
import { Box, AppBar, SxProps, Toolbar } from '@mui/material';
import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';
import { applyOpacityOnColor } from '../utils/color';

type Props = {
  height?: number;
  secondaryHeight?: number;
  toolbarOnly?: boolean;
  sx?: SxProps;
  appBarSx?: SxProps;
  children?: ReactRenderElement;
  withBottomDivider?: boolean;
};

const AppActionToolbar = ({
  height,
  secondaryHeight,
  children,
  toolbarOnly,
  sx,
  appBarSx,
  withBottomDivider = true,
}: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    subToolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const containerHeight = height || toolbarHeight;
  const secondaryContainerHeight = secondaryHeight || subToolbarHeight;

  const _appBarSx = {
    left: drawerWidth,
    top: containerHeight,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
    background: isDarkMode
      ? 'var(--md-sys-color-background-dark)'
      : 'var(--md-sys-color-background-light)',
    ...(withBottomDivider
      ? {
          borderBottom: `2px solid ${applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.neutral.variant20']
              : palette['md.ref.palette.neutral.variant90'],
            0.5,
          )}`,
        }
      : null),
    ...appBarSx,
  };
  const toolbarSx = {
    height: `${secondaryContainerHeight}px !important`,
    minHeight: `${secondaryContainerHeight}px !important`,
    borderTopLeftRadius: 12,
    background: isDarkMode
      ? 'var(--md-sys-color-background-dark)'
      : 'var(--md-sys-color-on-primary-light)',
    ...sx,
  };

  if (toolbarOnly) {
    return <Toolbar sx={toolbarSx}>{children}</Toolbar>;
  }

  return (
    <>
      <AppBar position='fixed' sx={_appBarSx}>
        <Box
          sx={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: 'absolute',
            pointerEvents: 'none',
          }}
        />
        <Toolbar sx={toolbarSx}>{children}</Toolbar>
      </AppBar>
      <Toolbar sx={{ ...toolbarSx, background: 'transparent' }} />
    </>
  );
};

export default AppActionToolbar;
