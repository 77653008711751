import { useState } from 'react';
import { IterableObject } from '../../types/types';

export type UseToggleSelectionRet = {
  count: number;
  hasSelected: boolean;
  isTotalSelected: boolean;
  selected: { [key: string]: boolean };
  setSelected: (id: string | number, value: boolean) => void;
  reset: () => void;
  selectAllItems: () => void;
  deselectAllItems: () => void;
  isAllSelected: boolean;
  selectAll: () => void;
  deselectAll: () => void;
  getSelectedItems: () => string[];
};
export function useToggleSelection<T = IterableObject>(
  items: T[] = [],
  key: string = 'id',
): UseToggleSelectionRet {
  const [selected, setSelected] = useState<UseToggleSelectionRet['selected']>(
    {},
  );
  const [isAllSelected, setIsAllSelected] = useState(false);

  const checked = Object.values(selected).filter((b) => b);
  const count = checked.length;
  const hasSelected = !!count;
  const isTotalSelected = hasSelected && count === items.length;

  function setUpdatedSelected(id: string | number, value: boolean) {
    setSelected((state: UseToggleSelectionRet['selected']) => ({
      ...state,
      [id]: value,
    }));
  }

  function selectAllItems() {
    const state: UseToggleSelectionRet['selected'] = {};
    items.forEach((item: T) => {
      const valueKey = (item as IterableObject)[key];
      state[valueKey] = true;
    });
    setSelected(state);
  }

  function deselectAllItems() {
    setSelected({});
  }

  function selectAll() {
    setIsAllSelected(true);
  }

  function deselectAll() {
    setIsAllSelected(false);
  }

  function reset() {
    deselectAll();
    deselectAllItems();
  }

  function getSelectedItems(): string[] {
    return Object.keys(selected).filter((key) => selected[key]);
  }

  return {
    count,
    hasSelected,
    isTotalSelected,
    selected,
    setSelected: setUpdatedSelected,
    selectAllItems,
    deselectAllItems,
    reset,
    isAllSelected,
    selectAll,
    deselectAll,
    getSelectedItems,
  };
}
