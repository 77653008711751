import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { AudienceSegment, AudienceUserType } from '../../types/audience';

type ContentAudienceItemProps = {
  item: AudienceSegment | AudienceUserType;
};

const ContentAudienceItem = ({ item }: ContentAudienceItemProps) => {
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();
  const [total] = useState(() => Math.ceil(Math.random() * 5));

  return (
    <M3Card
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(`/content/target-audience/${item.id}`);
      }}
    >
      <M3SurfaceContainer
        elevation={1}
        wrapperSx={{ height: '100%' }}
        sx={{ p: 2, height: '100%' }}
      >
        <Typography
          component='h5'
          fontSize={18}
          fontWeight={500}
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-primary80)'
              : 'var(--md-ref-palette-primary40)',
          }}
        >
          {item.name}
        </Typography>
        <Typography
          fontSize={12}
          style={{
            opacity: 0.5,
            maxWidth: 630,
            padding: '5px 0 0',
          }}
        >
          Collection: {total}
        </Typography>
      </M3SurfaceContainer>
    </M3Card>
  );
};

export default ContentAudienceItem;
