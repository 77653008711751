import React, { Fragment, useMemo } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import MainContainer from '../components/MainContainer';
import IntercomBreadcrumbs from '../components/Intercom/IntercomBreadcrumbs';
import WindowScrollTop from '../components/WindowScrollTop';
import IntercomArticleItem from '../components/Intercom/IntercomArticleItem';
import { useIntercomCollectionDetail } from '../components/Intercom/IntercomCollectionDetail';

import { useAppProvider } from '../providers/app/app';
import {
  getArticleItemProps,
  getIcon as getIntercomCollectionIcon,
} from '../providers/intercom';
import { audienceSegments, audienceUserTypes } from '../types/audience';
import {
  useIntercomArticlesInCollection,
  useIntercomCollectionById,
  useIntercomSections,
} from '../hooks/intercom';
import { mutateFieldEscapeString } from '../utils/object';
import { IntercomArticle, IntercomSection } from '../types/intercom';

type Props = {};

const ContentAssignDetailCollection = (props: Props) => {
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();
  const { audienceSegmentId, collectionId } = useParams();

  const audienceSegment = useMemo(() => {
    return (
      audienceUserTypes.find((as) => as.id === audienceSegmentId!) ??
      audienceSegments.find((as) => as.id === audienceSegmentId!)
    );
  }, [audienceSegmentId]);

  const collection = useIntercomCollectionById(
    { id: collectionId! },
    { enabled: !!collectionId },
  );
  const collectionData = useMemo(() => {
    const data = collection.data ? [{ ...collection.data }] : [];
    mutateFieldEscapeString(data, ['name', 'description']);
    return data[0];
  }, [collection.data]);

  const sectionsQuery = useIntercomSections(
    {
      parent_id: collectionId!,
      omit: 'translated_content',
    },
    {
      enabled: !!collection,
    },
  );

  const articlesQuery = useIntercomArticlesInCollection(
    {
      collectionId,
      omit: 'body,author',
    },
    {
      enabled: !!collectionId,
    },
  );

  const collectionHash = useIntercomCollectionDetail({
    collection: collectionData,
    sectionsQuery,
    articlesQuery,
  });

  const renderHeadline = () => {
    if (!collectionData) return null;

    const CollectionIcon = getIntercomCollectionIcon(collectionData.icon);

    return (
      <Box className='intercom-section'>
        <Paper
          className='collection__paper'
          sx={{
            display: 'flex',
            position: 'relative',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
          style={{
            border: 0,
            boxShadow: 'none',
            background: 'transparent',
          }}
        >
          <Box className='collection__photo'>
            <Box
              style={{
                height: 48,
                width: 48,
                margin: 'auto',
              }}
            >
              <CollectionIcon />
            </Box>
          </Box>
          <Box
            flex={1}
            style={{
              paddingLeft: 150,
            }}
          >
            <Typography
              fontSize={33}
              sx={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-primary80) !important'
                  : 'var(--md-ref-palette-primary40)',
              }}
            >
              {collectionData.name}
            </Typography>
            {!!collectionData.description && (
              <Typography
                fontSize={16}
                sx={{
                  opacity: 0.8,
                  color: 'var(--md-sys-color-on-surface-light)',
                }}
                style={{
                  maxWidth: 630,
                  padding: '5px 0 0',
                }}
              >
                {collectionData.description}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderSection = (section: IntercomSection) => {
    return (
      <Fragment key={section.id}>
        <Typography
          fontSize={18}
          fontWeight={700}
          style={{
            padding: '16px 30px 11px',
            color: isDarkMode
              ? 'var(--md-ref-palette-primary80)'
              : 'var(--md-ref-palette-primary40)',
          }}
        >
          {section.name}
        </Typography>
        <Box
          style={{
            margin: '0 30px',
          }}
        >
          {section.articles.map((article, index, arr) => {
            const item = getArticleItemProps(article);
            const first = index === 0;
            const last = arr.length - 1 === index;
            return (
              <IntercomArticleItem
                key={item.id}
                item={item}
                first={first}
                last={last}
              />
            );
          })}
        </Box>
      </Fragment>
    );
  };

  const renderArticles = (
    articles: IntercomArticle[] = [],
    sections: IntercomSection[] = [],
  ) => {
    if (!articles.length) return null;

    return (
      <Box
        style={{
          margin: '0 30px 0',
          marginTop: sections.length ? 30 : 0,
        }}
      >
        {articles.map((article, index, arr) => {
          const item = getArticleItemProps(article);
          const first = index === 0;
          const last = arr.length - 1 === index;
          return (
            <IntercomArticleItem
              key={item.id}
              item={item}
              first={first}
              last={last}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <WindowScrollTop />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              navigate(`/content/target-audience/${audienceSegmentId}`);
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
        </AppActionToolbar>
        <IntercomBreadcrumbs
          items={[
            {
              id: 'all',
              label: 'Target Audience',
              path: '/content/target-audience',
            },
            {
              id: audienceSegment ? audienceSegment.id : audienceSegmentId!,
              label: audienceSegment ? audienceSegment.name : audienceSegmentId,
              path: `/content/target-audience/${audienceSegmentId}`,
            },
            {
              id: collectionData ? collectionData.id : collectionId!,
              label: collectionData ? collectionData.name : collectionId,
              path: `/content/target-audience/${audienceSegmentId}/collection/${collectionId}`,
            },
          ]}
        />
        <Stack direction='row' justifyContent='stretch'>
          <Box flex={1}>
            <br />
            {renderHeadline()}
            <br />
          </Box>
        </Stack>
        <Box sx={{ ml: -4, mr: -4 }}>
          {!!collectionHash.articles.length &&
            renderArticles(collectionHash.articles, collectionHash.sections)}
          {!!collectionHash.sections.length &&
            collectionHash.sections.map(renderSection)}
        </Box>
      </MainContainer>
    </>
  );
};

export default ContentAssignDetailCollection;
