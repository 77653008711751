import { IterableObject } from '../../../../types/types';
import { UseFetchTimeDetailsResponse } from '../../../../hooks/edit-time';

type Props = {
  data?: UseFetchTimeDetailsResponse[] | null;
};

let colorMap: { tasks: IterableObject<string>; index: number } = {
  tasks: {},
  index: 0,
};
let hasMountedOnce = false;

const colorList = [
  '#996633', // your original colors
  '#3399FF',
  '#66CC33',
  '#FFCC33',
  '#9966CC',
  '#FF6633', // new colors
  '#33CCFF',
  '#CC3366',
  '#66FFCC',
  '#CC99FF',
  '#339966',
  '#9966FF',
  '#FF3366',
  '#66CC99',
  '#CCFF33',
  '#336699',
  '#FF6699',
  '#33CC66',
  '#9933FF',
  '#66FF99',
  '#CC33FF',
  '#3366CC',
  '#FF9933',
  '#33FFCC',
  '#9933CC',
  '#6699FF',
  '#CC6633',
  '#33FF99',
  '#CC66FF',
  '#669933',
];

// Maximum possible value for an RGB color when combined into a single 24-bit number
const maxRGBValue = 16777215;

while (colorList.length < 100) {
  // Generate a random number up to maxRGBValue, convert it to a hexadecimal string, and prepend with '#'
  const newColor = '#' + Math.floor(Math.random() * maxRGBValue).toString(16);

  // Ensure this color is not already in the list
  if (!colorList.includes(newColor)) {
    colorList.push(newColor);
  }
}

export function getColorByTaskName(taskName: string) {
  if (!hasMountedOnce) {
    console.warn(
      'Accessing color without mounting TimelineColorMap once is discouraged',
    );
  }

  return (
    colorMap.tasks[taskName] ||
    colorList[Math.floor(Math.random() * colorList.length)]
  );
}

const TimelineColorMap = ({ data: timeDetailsData }: Props) => {
  (timeDetailsData || []).forEach((t) => {
    // assign a color by task
    colorMap.tasks[t.task_name] =
      colorMap.tasks[t.task_name] || colorList[colorMap.index++];

    // if it reaches the level of maximum color, let's reset the colors from start
    if (colorMap.index > colorList.length - 1) {
      colorMap.index = 0;
    }
  });

  hasMountedOnce = true;

  return null;
};

export default TimelineColorMap;
