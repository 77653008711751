import React from 'react';
import { Box } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import BasicForm, { FormOptions, useForm } from '../BasicForm';
import { M3TextField } from '../M3/M3TextField';

type SearchFormProps = FormOptions<FormState> & {
  name?: string;
  placeholder?: string;
  onSubmit?: (data: FormState) => void;
};
type FormState = {
  search: '';
};

const SearchForm = ({
  name = 'search',
  placeholder,
  onSubmit: propsOnSubmit,
  onStateUpdated: propsOnStateUpdated,
}: SearchFormProps) => {
  const { formState, handleChange, handleSubmit } = useForm<FormState>(
    {
      search: '',
    },
    {
      onStateUpdated: (state: FormState, changed: Partial<FormState>) => {
        propsOnStateUpdated?.(state, changed);
      },
    },
  );

  const onSubmit = handleSubmit((data) => {
    // NOTE: Intended to be empty
    propsOnSubmit?.(data);
  });

  return (
    <Box flex={1} sx={{ position: 'relative', ml: -1.5, mr: -1.5 }}>
      <BasicForm onSubmit={onSubmit}>
        <M3TextField
          fullWidth
          variant='filled'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          autoSave='off'
          name={name}
          placeholder={placeholder}
          value={formState.search}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            '.MuiFilledInput-root,.MuiOutlinedInput-notchedOutline': {
              borderRadius: 20,
              background: 'transparent',
            },
            '.MuiInputBase-input': {
              pl: 6,
            },
            '.MuiInputLabel-shrink': {
              transform: 'translate(20px, -8px) scale(0.74)',
            },
          }}
          onChange={handleChange}
        />
      </BasicForm>
      <SearchOutlinedIcon
        sx={{
          top: 13,
          left: 15,
          opacity: 0.5,
          position: 'absolute',
          pointerEvents: 'none',
          color: `var(--md-sys-color-on-surface-light)`,
        }}
      />
    </Box>
  );
};

export default SearchForm;
