import React from 'react';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { ReactRenderElement } from '../types/types';

type Props = {
  sx?: SxProps;
  height?: number;
  isLoading?: boolean;
  children?: ReactRenderElement;
  hasReachEnd?: boolean;
  reachEndMessage?: string;
};

const LoadMorePlaceholder = ({
  isLoading,
  height = 64,
  sx,
  hasReachEnd,
  reachEndMessage = `It looks like you've reached the end`,
  children,
}: Props) => {
  return (
    <Box
      flex={1}
      display='flex'
      alignItems='flex-start'
      justifyContent='flex-start'
      sx={{ height, overflow: 'hidden', ...sx }}
    >
      {hasReachEnd ? (
        <>
          <Typography
            flex={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            sx={{
              pt: 1,
              opacity: 0.6,
              height: '100%',
              color: 'var(--md-ref-palette-primary40)',
            }}
          >
            <DoneAllOutlinedIcon
              sx={{
                mr: 1.6,
                ml: -1.6,
                color: 'var(--md-ref-palette-primary40)',
              }}
            />
            {reachEndMessage}
          </Typography>
        </>
      ) : (
        <>
          {isLoading &&
            (children || (
              <Box
                flex={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  pt: 1,
                  height: '100%',
                }}
              >
                <CircularProgress size={24} sx={{ margin: 'auto' }} />
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};

export default LoadMorePlaceholder;
