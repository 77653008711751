import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as portal from '../../services/portal';
import { defaultReactQueryParams } from '../../utils/request';
import { QueryParams } from '../global/useApi';
import { useIdentifier } from '../global/useIdentifier';

export type UseDomoEmbedSearchAnswersDashboardProps = {
  key?: string;
  embed_type: 'cards' | 'stories';
  embed_id: string;
  sod_id?: number | null;
  division?: string;
  division_name?: string;
  date?: string;
  date_range?: string[];
  week?: string;
  month?: string;
  member?: string;
  member_id?: string;
  go2_member_id?: string | null;
  focus?: string;
  focus_id?: string;
  question?: string;
  task_id?: string;
  task_name?: string;
  parent_field_name?: string;
};

export function useDomoEmbedDashboard<R = any>(
  params: UseDomoEmbedSearchAnswersDashboardProps,
  reactQueryParams?: Partial<QueryParams>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseDomoEmbedSearchAnswersDashboardProps>({
      params,
      baseIdentifier: 'useDomoEmbedDashboard',
      identifierKeys: [
        'division',
        'division_name',
        'date',
        'date_range',
        'week',
        'month',
        'sod_id',
        'member',
        'member_id',
        'go2_member_id',
        'focus',
        'question',
        'task_id',
        'task_name',
        'parent_field_name',
      ],
      queryParamKeys: [
        'division',
        'division_name',
        'date',
        'date_range',
        'week',
        'month',
        'sod_id',
        'member',
        'member_id',
        'go2_member_id',
        'focus',
        'question',
        'embed_id',
        'embed_type',
        'task_id',
        'task_name',
        'parent_field_name',
      ],
    });

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await portal.request({
          url: '/domo/dashboard',
          signal,
          params: queryParams,
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}

type UseDomoEmbedStaticChartProps = {
  embed_id: string;
  embed_type: 'cards' | 'stories';
};
export function useDomoEmbedStaticChart<R = any>(
  params: UseDomoEmbedStaticChartProps,
  reactQueryParams?: Partial<QueryParams>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseDomoEmbedStaticChartProps>({
      params,
      baseIdentifier: 'useDomoEmbedStaticChart',
      identifierKeys: ['embed_id'],
      queryParamKeys: ['embed_id', 'embed_type'],
    });

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await portal.request({
          url: '/domo/dashboard',
          signal,
          params: queryParams,
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}

export type UseDomoExecuteApiProps =
  Partial<UseDomoEmbedSearchAnswersDashboardProps> & {
    data_source_id: string;
    name: 'top_visited_urls' | 'kpi_resources';
  };
export function useDomoExecuteApi<R = any>(
  params: UseDomoExecuteApiProps,
  reactQueryParams?: Partial<QueryParams>,
) {
  const { identifiers, queryParams } = useIdentifier<UseDomoExecuteApiProps>({
    params,
    baseIdentifier: 'useDomoExecuteApi',
    identifierKeys: ['name', 'data_source_id'],
    queryParamKeys: [
      'name',
      'data_source_id',
      'embed_type',
      'embed_id',
      'sod_id',
      'division_name',
      'date',
      'date_range',
      'member',
      'member_id',
      'go2_member_id',
      'focus_id',
      'focus',
      'question',
      'task_id',
      'task_name',
    ],
  });

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await portal.request({
          url: '/domo/execute',
          signal,
          params: queryParams,
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}
