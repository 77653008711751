import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';

import {
  getCurrentUserJobDescription,
  getUserInitials,
  getUserPhotoUrl,
} from '../../../utils/user';
import { UserMetadata } from '../../../types/profile';
import { useAppProvider } from '../../../providers/app/app';
import UserChipStatus from '../UserChipStatus';

interface Props {
  user: UserMetadata | undefined;
}

const UserProfile: React.FC<Props> = ({ user }) => {
  const { isDarkMode } = useAppProvider();
  const { job_title } = getCurrentUserJobDescription(user);

  return (
    <Stack gap={2} flexDirection='row' position='relative' mt={2.5}>
      <Avatar
        sx={{
          width: 50,
          height: 50,
        }}
        src={getUserPhotoUrl(user?.photo_url)}
      >
        {getUserInitials(user?.first_name).initial}
      </Avatar>
      <Box>
        <Typography component='div' fontSize={20} fontWeight={500}>
          {user?.first_name} {user?.last_name}
        </Typography>
        <Typography
          component='div'
          fontSize={14}
          fontWeight={500}
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-tertiary80)'
              : 'var(--md-ref-palette-tertiary30)',
          }}
        >
          {job_title}
        </Typography>
        <UserChipStatus />
      </Box>
    </Stack>
  );
};

export default UserProfile;
