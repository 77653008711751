import axios, { AxiosRequestConfig } from 'axios';
import { parseError } from '../hooks/global/useApi';
import { getRawCodeMessage } from '../utils/response';
import { getFromSessionStorage } from '../utils/sessionstorage';

export function createRequest(config: Partial<AxiosRequestConfig>) {
  const { baseURL, headers, ...restConfig } = config || {};
  const request = axios.create({
    ...restConfig,
    baseURL: baseURL || `${process.env.REACT_APP_AUTH_URL}/api`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  });

  request.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (err) => {
      throw getRawCodeMessage(parseError(err));
    },
  );

  request.interceptors.request.use(
    (config) => config,
    (err) => {
      throw getRawCodeMessage(parseError(err));
    },
  );

  return request;
}

export function getConfigWithAuthorization(
  token: string,
): Partial<AxiosRequestConfig> {
  const impersonation = getFromSessionStorage('impersonation', true, true);

  return {
    headers: {
      authorization: impersonation
        ? `Token ${impersonation.token}`
        : `Bearer ${token}`,
    },
  };
}
