import React, { useState, useEffect, useMemo } from 'react';
import { Moment } from 'moment';
import { Box, Tooltip } from '@mui/material';
import M3SurfaceContainer, { Elevation } from '../../../M3/M3SurfaceContainer';
import { M3Card } from '../../../M3/M3Card';
import {
  HHMMSSOptionsJSONRet,
  getSeconds,
  toHHMMSS,
} from '../../../../utils/date';

import './timeblock.css';

interface TimeBlockProps {
  color?: string;
  taskName: string;
  timeStarted: Moment;
  timeEnded?: Moment | null;
  duration: number;
  timelineWidth: number;
}

const TimeBlock: React.FC<TimeBlockProps> = ({
  color,
  taskName,
  timeStarted,
  timeEnded,
  duration,
  timelineWidth,
}) => {
  const [rowElevation, setRowElevation] = useState<Elevation>(8);
  const [left, setLeft] = useState('0%');
  const [width, setWidth] = useState('0%');

  const getToolTipTitle = useMemo(() => {
    const hms = toHHMMSS(duration, {
      minDigit: 1,
      hrDigit: 1,
      secDigit: 1,
      json: true,
    }) as HHMMSSOptionsJSONRet;
    const { hours, minutes } = hms;

    return (
      <>
        <span className='tooltip-duration'>
          {`${hours}h ${minutes}m`.trim()}
        </span>
        <br />
        <span className='tooltip-task-name'>{taskName}</span>
        <br />
        <span className='tooltip-start-end'>
          {timeStarted.format('hh:mm A')} -{' '}
          {timeEnded ? timeEnded.format('hh:mm A') : 'ongoing'}
        </span>
      </>
    );
  }, [duration, taskName, timeStarted, timeEnded]);

  useEffect(() => {
    const twentyFourHoursInSec = 86400;
    const leftPercentage =
      getSeconds(timeStarted.format('HH:mm')) / twentyFourHoursInSec;
    const widthPercentage = duration / twentyFourHoursInSec;

    // Convert the percentages to pixels based on the timeline width
    const leftPixels = leftPercentage * timelineWidth;
    const widthPixels = widthPercentage * timelineWidth;

    setLeft(`${leftPixels}px`);
    setWidth(`${widthPixels}px`);
  }, [timeStarted, timeEnded, duration, timelineWidth]);

  return (
    <M3Card
      sx={{
        left,
        width,
        position: 'absolute',
        marginBottom: '1.2%',
      }}
      onMouseEnter={() => setRowElevation(9)}
      onMouseLeave={() => setRowElevation(8)}
    >
      <M3SurfaceContainer
        elevation={rowElevation}
        borderRadius={1}
        sx={{
          minHeight: '45px',
          height: '45px',
          background: color ? `${color} !important` : undefined,
        }}
      >
        <Tooltip title={getToolTipTitle}>
          <Box className='tooltip-box' />
        </Tooltip>
      </M3SurfaceContainer>
    </M3Card>
  );
};

export default React.memo(TimeBlock);
