import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import { useForm } from '../components/BasicForm';
import { AutocompleteFormState } from '../components/Filters/AutocompleteSelectionPopover';
import AbsoluteCenterBox from '../components/AbsoluteCenterBox';
import BasicIframe from '../components/BasicIframe';
import DateRangeSelectionPopover from '../components/Filters/DateRangeSelectionPopover';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';
import { toURL } from '../utils/url';
import { useCurrentProfile } from '../hooks/profile';

type Props = {};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

type RenderCardItemProps = {
  title?: ReactRenderElement;
  html?: string;
  sx?: SxProps;
};

const TeamMySprints = (props: Props) => {
  const { current: currentWorkspace } = useWorkspaceProvider();
  const { teamMySprintsParams, setTeamMySprintsParams } = useMetadataProvider();
  const { subToolbarHeight, isDarkMode } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();

  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const dateForm = useForm<DateFormState>({
    start: teamMySprintsParams.date_start,
    end: teamMySprintsParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });
  const memberForm = useForm<AutocompleteFormState>({
    checked: {},
    optionById: {},
  });

  const renderCardEmbed = ({ title, sx }: RenderCardItemProps) => {
    const wrapperSX: SxProps = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      overflow: 'hidden',
      background: isDarkMode
        ? 'var(--md-sys-color-background-dark)'
        : '#f5f5f5',
    };

    return (
      <>
        {!!title && (
          <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
            {title}
          </Typography>
        )}
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            boxShadow: isDarkMode
              ? '0 0 0 1px rgba(255, 255, 255, 0.1)'
              : '0 0 0 1px #e0e0e0',
          }}
        >
          <Box
            sx={{
              ...wrapperSX,
              '&:before': {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
                position: 'absolute',
                background: 'rgba(255, 255, 255, 0.05)',
              },
            }}
          >
            <AbsoluteCenterBox>
              <CircularProgress size={24} />
            </AbsoluteCenterBox>
            <Box sx={{ ...wrapperSX, ...sx }}>
              <BasicIframe
                src={toURL(`https://go2io.retool.com/p/mysprint`, {
                  // user_id here is employee_id. ie. 100000...
                  user_id: currentProfile?.employee_id!,
                  start_date: dateForm.formState.start!,
                  end_date: dateForm.formState.end!,
                })}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    memberForm.resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace]);

  useEffect(() => {
    setTeamMySprintsParams({
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState.start, dateForm.formState.end]);

  return (
    <>
      <DocumentTitle title='My Sprints' trailingTitle='Sprints | Team' />
      <MainContainer
        ref={mainContainerRef}
        sx={{
          maxWidth: null,
          p: 2,
          pt: 1.5,
          position: 'relative',
          height: `calc(100vh - ${subToolbarHeight}px)`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box width='100%'>
          <AppActionToolbar>
            <FilterColumnPanel
              name='date'
              label='Date'
              displayValue={`${moment(dateForm.formState.start).format(
                'MMM D, YYYY',
              )} - ${moment(dateForm.formState.end).format('MMM D, YYYY')}`}
              sx={{ ml: -1 }}
              paperSx={{ ml: 1 }}
              textFieldSx={{ width: 240 }}
            >
              <DateRangeSelectionPopover
                disableFutureToDate={false}
                startDate={dateForm.formState.start}
                endDate={dateForm.formState.end}
                onChange={({ start, end }) => {
                  dateForm.updateState((state) => ({ ...state, start, end }));
                }}
              />
            </FilterColumnPanel>
          </AppActionToolbar>
        </Box>
        <Box
          sx={{
            height: 0,
            flexGrow: 2,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              top: -10,
              left: -16,
              right: -16,
              bottom: -16,
              position: 'absolute',
            }}
          >
            {renderCardEmbed({})}
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default TeamMySprints;
