import React from 'react';
import { Box, Link, Skeleton, Typography } from '@mui/material';

import { TopVisitedURLItem } from '../../types/task';
import { toTitleCaseFirst } from '../../utils/string';

type Props = {
  topVisitedURLData: any;
  topVisitedURLsIsLoading: boolean;
};

const TopVisitedUrls: React.FC<Props> = ({
  topVisitedURLData,
  topVisitedURLsIsLoading,
}) => {
  const rows: string[][] = topVisitedURLData?.rows ?? [];
  const topURLs: TopVisitedURLItem[] = rows.map((row: string[]) => {
    try {
      let url = row[0] || '';
      let urlObj = new URL(url);
      return {
        url,
        title: row[1] || urlObj.hostname || urlObj.host,
      };
    } catch (e) {
      return {
        url: row[0],
        title: row[1],
      };
    }
  });

  return (
    <Box>
      <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
        Top Visited URLs
      </Typography>
      {topVisitedURLsIsLoading && (
        <Skeleton variant='text' sx={{ fontSize: 21, mb: 0.1 }} />
      )}
      {!topVisitedURLsIsLoading && !topURLs.length && (
        <Typography
          component='div'
          fontSize={14}
          style={{
            color: 'var(--md-ref-palette-neutral50)',
          }}
          sx={{
            pb: 1.45,
          }}
        >
          No web data available
        </Typography>
      )}
      {topURLs.map(({ title, url }: TopVisitedURLItem, index: number) => {
        return (
          <Box
            key={index}
            display='flex'
            sx={{ pb: 1 }}
            style={{
              opacity: url ? 1 : 0.5,
              cursor: url ? undefined : 'text',
            }}
          >
            <Typography component='span'>{index + 1}.&nbsp;</Typography>
            <Link
              href={url ? url : ''}
              target='_blank'
              className='text-truncate m3-button-link'
              sx={{
                display: 'block',
                whiteSpace: 'nowrap',
              }}
              onClick={url ? undefined : (evt) => evt.preventDefault()}
              style={{
                cursor: url ? undefined : 'text',
                textDecoration: url ? undefined : 'none',
              }}
            >
              {toTitleCaseFirst(title)}
            </Link>
          </Box>
        );
      })}
      <br />
    </Box>
  );
};

export default TopVisitedUrls;
