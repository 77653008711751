import React from 'react';

import MainContainer from '../components/MainContainer';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import SidecarGo2Desktop from './SidecarGo2Desktop';

import { usePathnameSegments } from '../hooks/utils/url';

type SidecarProps = {};

export type SidecarDrawerSegments = 'users' | 'go2-desktop';

const Sidecar = (props: SidecarProps) => {
  const [, segment] = usePathnameSegments<SidecarDrawerSegments[]>();

  if (segment === 'go2-desktop') {
    return <SidecarGo2Desktop />;
  }

  return (
    <MainContainer>
      <ErrorUnauthorizedAccess />
    </MainContainer>
  );
};

export default Sidecar;
