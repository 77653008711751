import { UseQueryOptions } from '@tanstack/react-query';
import { useIdentifier } from './global/useIdentifier';
import { useMutationApi, useQueryApi } from './global/useApi';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { AxiosRequestConfig } from 'axios';
import { Moment } from 'moment';
import { ListQuery } from '../types/request';
import { DateInUTC } from '../types/types';

type UseFetchTimeDetailsParams = ListQuery & {
  start_date: string;
  timezone?: string;
  end_date?: string;
  project?: number;
  task?: number;
  users?: string;
};
export type UseFetchTimeDetailsResponse = {
  duration: number;
  end?: string | null;
  end_time_local?: Moment | null;
  id: number;
  notes?: string;
  start: string;
  day?: number;
  start_time_local: Moment;
  task: number;
  task_name: string;
  user: number;
  created: DateInUTC;
  modified: DateInUTC;
  _id?: string;
};
export function useFetchTimeDetails<
  R = ListResult<UseFetchTimeDetailsResponse>,
>(
  params: UseFetchTimeDetailsParams,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } = useIdentifier<UseFetchTimeDetailsParams>(
    {
      params,
      baseIdentifier: 'useFetchTimeDetails',
      identifierKeys: ['start_date', 'end_date', 'timezone', 'users'],
      queryParamKeys: ['start_date', 'end_date', 'timezone', 'users'],
    },
  );

  return useQueryApi<UseFetchTimeDetailsParams, R>(
    identifiers,
    '/time-tracker/worklogs/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type TaskJob = {
  id: string;
  name: string;
};
type UseFetchTasksParams = ListQuery &
  Partial<{
    assigned_to?: number | string;
    is_active?: boolean;
    name: string;
    project?: number | string;
  }>;
type UseFetchTasksResponse = {
  id: number;
  project?: {
    id: number;
    name: string;
  } | null;
  created: string;
  modified: string;
  name: string;
  jobs: TaskJob[];
  description: string;
  is_active: boolean;
};
export function useFetchTasks<R = UseFetchTasksResponse>(
  params?: UseFetchTasksParams,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } = useIdentifier<UseFetchTasksParams>({
    params: params ?? {},
    baseIdentifier: 'useFetchTasks',
    identifierKeys: ['assigned_to', 'is_active', 'name', 'project'],
    queryParamKeys: ['assigned_to', 'is_active', 'name', 'project'],
  });

  return useQueryApi<UseFetchTasksParams, ListResult<R>>(
    identifiers,
    '/time-tracker/tasks/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type MutateWorklogsDataParams = {
  end: string;
  start: string;
  notes: string;
  task: number;
  user: number;
};
type MutateWorklogsDataResponse = {
  end: string;
  created: string;
  modified: string;
  start: string;
  notes: string;
  task: number;
  user: number;
};
export function useAddWorklogsData(axiosConfig?: Partial<AxiosRequestConfig>) {
  return useMutationApi<MutateWorklogsDataParams, MutateWorklogsDataResponse>(
    `/time-tracker/worklogs/`,
    {},
    axiosConfig,
  );
}

export function useUpdateWorklogsData(
  worklogId: number,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<MutateWorklogsDataParams, MutateWorklogsDataResponse>(
    `/time-tracker/worklogs/${worklogId}/`,
    {},
    axiosConfig,
  );
}

export function useDeleteWorklogsData<R = any>(
  worklogId: number,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<void, R>(
    `/time-tracker/worklogs/${worklogId}/`,
    {},
    axiosConfig,
  );
}
