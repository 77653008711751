import React from 'react';
import { IconProps, withWrapper } from './index';

type Props = IconProps;

const SlackSvgIcon = ({ fillColor = '#fff', ...restProps }: Props) => (
  <svg
    fill='none'
    width='28'
    height='26'
    viewBox={`0 0 28 26`}
    xmlns='http://www.w3.org/2000/svg'
    {...restProps}
  >
    <path
      fill={fillColor}
      d='M25.78 18.84h-3.202V3.204C22.578 1.507 21.165 0 19.375 0H3.737C2.041 0 .628 1.413.628 3.203v2.732c0 .66.566 1.224 1.225 1.224h3.203V22.42c0 .942.283 1.79.848 2.45.565.659 1.413 1.13 2.355 1.13h15.543c1.79 0 3.203-1.601 3.203-3.58v-2.26c0-.754-.565-1.32-1.224-1.32ZM19.376 1.979c.094 0 .282 0 .377.094.47.189.847.566.847 1.13v15.639h-9.797c-.565 0-1.036.376-1.224.942 0 .094-.095.188-.095.282v2.355c0 .848-.565 1.602-1.224 1.602-.283 0-.565-.189-.848-.377-.283-.283-.377-.754-.377-1.225V3.203c0-.471-.094-.848-.282-1.225h12.623ZM2.607 3.203c0-.66.565-1.225 1.319-1.225.659 0 1.224.565 1.224 1.225v2.072H2.607V3.203Zm22.42 19.217c0 .848-.565 1.602-1.225 1.602H11.085c.188-.471.377-1.037.377-1.602V20.82h13.565v1.601Z'
    />
    <path
      fill={fillColor}
      d='M9.955 11.398h7.819a.944.944 0 0 0 .942-.942.944.944 0 0 0-.942-.942H9.955a.944.944 0 0 0-.942.942c-.094.471.377.942.942.942ZM9.956 7.442h7.819a.944.944 0 0 0 .942-.942c0-.471-.471-.942-.942-.942h-7.82a.944.944 0 0 0-.941.942c0 .471.376.942.942.942ZM9.955 15.26h7.819a.944.944 0 0 0 .942-.941.944.944 0 0 0-.942-.942H9.955a.944.944 0 0 0-.942.942c-.094.47.377.942.942.942Z'
    />
  </svg>
);

export default withWrapper(SlackSvgIcon);
