import React from 'react';
import { Paper, Box } from '@mui/material';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';

import WorkspaceMenuList, { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';

type Props = {};

const BuilderDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const menus: MenuItem[] = [
    {
      id: 'headline-settings',
      name: 'Builder',
      icon: <HandymanOutlinedIcon />,
      headline: true,
    },
    {
      id: 'jobs',
      name: 'Jobs',
      basePath: '/builder/jobs',
      path: '/builder/jobs',
      roles: ['superuser', 'internal', 'partner_member', 'path_beta', 'guest'],
    },
    {
      id: 'focus',
      name: 'Focus',
      basePath: '/builder/focus',
      path: '/builder/focus',
      roles: ['superuser', 'internal', 'partner_member', 'path_beta', 'guest'],
    },
  ];

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1}>
          <AppDrawerMenuList menus={menus} />
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default BuilderDrawer;
