import React from 'react';
import { AvatarGroup, Box, Paper, Skeleton } from '@mui/material';

type Props = {};

const IntercomHeadlineSkeleton = (props: Props) => {
  return (
    <Paper
      className='collection__paper'
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
      style={{
        border: 0,
        boxShadow: 'none',
        background: 'transparent',
      }}
    >
      <Box className='collection__photo'>
        <Box
          style={{
            height: 48,
            width: 48,
            margin: 'auto',
          }}
        ></Box>
      </Box>
      <Box
        flex={1}
        style={{
          paddingLeft: 150,
        }}
      >
        <Skeleton
          variant='text'
          sx={{ fontSize: 33, maxWidth: 630, margin: '0 0 11px' }}
        />
        <Box display='flex' justifyContent='flex-start' alignItems='flex-start'>
          <AvatarGroup max={2} style={{ paddingRight: 10 }}>
            <Skeleton variant='circular' width={36} height={36} />
          </AvatarGroup>
          <Box flex={1} sx={{ maxWidth: 200 }}>
            <Skeleton variant='text' sx={{ fontSize: 13 }} />
            <Skeleton variant='text' sx={{ fontSize: 13 }} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default IntercomHeadlineSkeleton;
