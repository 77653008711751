import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';

import JobDescriptionDetailModalView from '../UserProfile/JobDescriptionDetailModalView';
import TimeOffProfileModalView from '../UserProfile/TimeOffProfileModalView';
import TaskDetailModalView from '../TaskDetailModalView';

import { parseURL, toURL } from '../../utils/url';
import { DateYYYYMMDD, IterableObject } from '../../types/types';
import { useUserProvider } from '../../providers/user/user';
import { useTaskById } from '../../hooks/task';
import {
  UseExportSearchAnswersProps,
  useExportSearchAnswers,
} from '../../hooks/domo';
import { csvToJson } from '../../utils/csv';
import { UserMetadata } from '../../types/profile';
import { TaskTimedoctorItem } from '../../types/task';

type Props = {};

type ModalTypeOpenSearchedParams = {
  type_open?: 'job_description' | 'time_off' | 'manifest_focus' | string;
  employee_id?: string;
  user_id?: number;
  focus_id?: number;
};

const ModalTypeOpenDetector = (props: Props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchedParams = parseURL<ModalTypeOpenSearchedParams>(search);

  const initialSearchedParamsRef = useRef(searchedParams);
  const currentSearchedParamsRef = useRef(searchedParams);
  currentSearchedParamsRef.current = searchedParams;

  //----------- TASK / MANIFEST FOCUS
  const taskById = useTaskById(
    { id: searchedParams.focus_id! },
    { enabled: !!searchedParams.focus_id },
  );

  const handleOnModalClose = useCallback(() => {
    const keys = Object.keys(initialSearchedParamsRef.current);
    const newSearchedParams: IterableObject = { ...searchedParams };
    // deleting not needed on opening the type open modal
    keys.forEach((key) => delete newSearchedParams[key]);

    setIsModalOpen(false);
    navigate(toURL(pathname, newSearchedParams), {
      replace: true,
    });
    // eslint-disable-next-line
  }, [
    initialSearchedParamsRef,
    searchedParams,
    pathname,
    search,
    setIsModalOpen,
  ]);

  const renderModalContent = () => {
    const { type_open } = initialSearchedParamsRef.current;
    const { employee_id, user_id, ...restParams } =
      currentSearchedParamsRef.current;

    if (type_open === 'job_description') {
      const user = getUser('user_id', user_id);

      return (
        !!user && (
          <JobDescriptionDetailModalView
            user={user}
            close={handleOnModalClose}
          />
        )
      );
    }

    if (type_open === 'time_off') {
      const user = getUser(
        employee_id ? 'employee_id' : 'user_id',
        employee_id ? employee_id : user_id,
      );
      return (
        !!user && (
          <TimeOffProfileModalView user={user} close={handleOnModalClose} />
        )
      );
    }

    if (type_open === 'manifest_focus') {
      const user = getUser('user_id', user_id);
      const { date, focus_id } = restParams as any;
      const taskData = taskById.data;
      return (
        !!user &&
        !!taskData && (
          <TaskDetailFetcher
            date={date}
            focusId={focus_id}
            employeeId={user.employee_id!}
            user={user}
            taskData={taskData}
            close={handleOnModalClose}
          />
        )
      );
    }

    return null;
  };

  useEffect(() => {
    const { type_open: initialTypeOpen } = initialSearchedParamsRef.current;
    const { type_open } = currentSearchedParamsRef.current;

    // if there's no any modal to open on first load
    if (!initialTypeOpen) return;

    // check if the initial is not the same anymore when it first loads, means, it's already closes
    if (initialTypeOpen !== type_open) {
      setIsModalOpen(false);
    } else if (
      type_open === 'job_description' ||
      type_open === 'time_off' ||
      type_open === 'manifest_focus'
    ) {
      setIsModalOpen(true);
    }
  }, [
    initialSearchedParamsRef,
    currentSearchedParamsRef,
    search,
    pathname,
    setIsModalOpen,
  ]);

  useEffect(() => {
    setUniqueIdsToFetch({
      employee_ids: [searchedParams.employee_id],
      user_ids: [searchedParams.user_id],
    });
    // eslint-disable-next-line
  }, [users, searchedParams.employee_id, searchedParams.user_id]);

  return (
    <Modal open={isModalOpen}>
      <>{renderModalContent()}</>
    </Modal>
  );
};

export default ModalTypeOpenDetector;

type TaskDetailFetcherProps = {
  date: DateYYYYMMDD;
  focusId: string | number;
  employeeId: string;
  user: UserMetadata;
  taskData: TaskTimedoctorItem;
  close: () => void;
};
function TaskDetailFetcher({
  date,
  focusId,
  employeeId,
  user,
  taskData,
  close,
}: TaskDetailFetcherProps) {
  const searchAnswersParams: any = {
    date_start: date,
    date_end: date,
    focus: focusId?.toString(),
    member: employeeId,
    fields:
      'focus_id,report_id,report_type,member_id,date,member,focus,question_id,question,answer',
  };
  const { data: csv } = useExportSearchAnswers(
    {
      key: 'modal_type_open_raw_search_answers',
      ...searchAnswersParams,
    } as UseExportSearchAnswersProps,
    {
      enabled: !!(date && focusId && employeeId),
      keepPreviousData: false,
    },
  );
  let result = csvToJson(csv);

  const questions = result.map((item) => ({
    id: item.question_id,
    question: item.question,
    answer: item.answer,
  }));

  return (
    <TaskDetailModalView
      date={date}
      user={user!}
      task={{
        task_id: taskData.id,
        task_name: taskData.name,
      }}
      questions={questions}
      close={close}
    />
  );
}
