import {
  Box,
  CircularProgress,
  Divider,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Virtuoso } from 'react-virtuoso';

import { M3TextField } from '../M3/M3TextField';
import AbsoluteCenterBox from '../AbsoluteCenterBox';

import { useEmojis } from '../../hooks/services/emojis';
import { useAppProvider } from '../../providers/app/app';
import { EmojiItem } from '../../types/emojis';
import { escapeRegExp } from '../../utils/string';

type EmojisButtonProps = {
  disableRipple?: boolean;
  open: boolean;
  setOpen?: (open: boolean) => void;
  onSelect?: (text: string) => void;
};

const EmojisButton = ({
  open,
  setOpen,
  onSelect: propsOnSelect,
}: EmojisButtonProps) => {
  const { isDarkMode } = useAppProvider();
  const ref = useRef<HTMLElement | null>(null);

  function onClose() {
    setOpen?.(false);
  }

  function onSelect(emoji: EmojiItem) {
    propsOnSelect?.(emoji.character);
  }

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
      }}
    >
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            overflow: 'hidden',
            background: isDarkMode
              ? 'var(--md-sys-color-background-dark)'
              : 'var(--md-sys-color-background-light)',
            border: isDarkMode
              ? '1px solid var(--md-ref-palette-neutral30)'
              : 'none',
          },
        }}
      >
        <EmojisPanel onClose={onClose} onSelect={onSelect} />
      </Popover>
    </Box>
  );
};

type EmojisPanelProps = {
  onClose?: () => void;
  onSelect?: (emoji: EmojiItem) => void;
};
function EmojisPanel({ onClose, onSelect }: EmojisPanelProps) {
  const { isDarkMode } = useAppProvider();
  const [search, setSearch] = useState('');
  const listHeight = 94;
  const listLimit = 300;
  const itemPerRow = 7;
  const panelWidth = 224;

  let { data: emojisResult, isLoading } = useEmojis(
    {},
    { cacheTime: Infinity },
  );
  /**
   * Split the emojis into a multi-array of emojis
   */
  const splitEmojisPerRow = useCallback(
    (emojis: EmojiItem[]) => {
      let multiItems: EmojiItem[][] = [];
      let _emojis: EmojiItem[] = [...emojis];
      let emoji: EmojiItem | null = null;
      let index = 0;

      while (_emojis.length) {
        emoji = _emojis.shift() as EmojiItem;
        // check if there's not enough item in the row, so increase it by 1
        if ((multiItems[index] ?? []).length >= itemPerRow) {
          index++;
        }
        // assigning array per index and pushing it
        multiItems[index] = multiItems[index] || [];
        if (multiItems[index].length < itemPerRow) {
          multiItems[index].push(emoji);
        }
      }

      return multiItems;
    },
    [itemPerRow],
  );

  const emojis = useMemo(() => {
    let items = emojisResult || [];
    if (search) {
      const searchRegex = new RegExp(escapeRegExp(search), 'i');
      items = items.filter(
        (item: EmojiItem) =>
          searchRegex.test(item.unicodeName) ||
          searchRegex.test(item.group.split('-').join(' ')) ||
          searchRegex.test(item.subGroup.split('-').join(' ')),
      );
      if (search.length <= 2) {
        items = items.slice(0, listLimit);
      }
      return splitEmojisPerRow(items);
    }

    return splitEmojisPerRow(items.slice(0, listLimit));
  }, [search, emojisResult, splitEmojisPerRow]);

  return (
    <Box
      sx={{
        width: panelWidth,
      }}
    >
      <M3TextField
        fullWidth
        autoFocus
        stripAllAutoProps
        placeholder='Search emoji...'
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: '0 !important',
          },
        }}
        onChange={(evt: SyntheticEvent) =>
          setSearch((evt.currentTarget as HTMLInputElement).value)
        }
        inputProps={{
          style: {
            height: 40,
            fontSize: 14,
          },
        }}
      />
      <Divider />
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Virtuoso
          totalCount={emojis.length}
          fixedItemHeight={31}
          defaultItemHeight={31}
          increaseViewportBy={100}
          itemContent={(index) => {
            const rowItems = emojis[index];

            if (!rowItems || !rowItems.length) {
              return <Box sx={{ height: 31 }} />;
            }

            return (
              <Stack
                direction='row'
                flexWrap='nowrap'
                sx={{
                  height: 31,
                  width: panelWidth,
                  overflow: 'hidden',
                }}
              >
                {rowItems.map((item: EmojiItem, index: number) => (
                  <ItemContainer key={index}>
                    <EmojiButton
                      emoji={item}
                      isDarkMode={isDarkMode}
                      onClose={onClose}
                      onSelect={onSelect}
                    />
                  </ItemContainer>
                ))}
              </Stack>
            );
          }}
          style={{
            height: listHeight,
            width: panelWidth + 20,
            overflow: 'hidden auto',
          }}
        />
        <AbsoluteCenterBox sx={{ pointerEvents: 'none' }}>
          {!emojis.length && (
            <Typography
              pb={1}
              component='div'
              fontSize={14}
              textAlign='center'
              sx={{
                opacity: 0.4,
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'No results found'}
            </Typography>
          )}
        </AbsoluteCenterBox>
      </Box>
    </Box>
  );
}

function ItemContainer({ children }: PropsWithChildren) {
  return (
    <Box
      p={0.4}
      sx={{
        width: 32,
        minWidth: 32,
        height: 31,
        minHeight: 31,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}

type EmojiButtonProps = {
  emoji: EmojiItem;
  isDarkMode: boolean;
  onClose: EmojisPanelProps['onClose'];
  onSelect: EmojisPanelProps['onSelect'];
};
function EmojiButton({ emoji, isDarkMode, onSelect }: EmojiButtonProps) {
  return (
    <Box
      sx={{
        width: 30,
        height: 30,
        minWidth: 30,
        minHeight: 30,
        fontSize: 20,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: isDarkMode
            ? 'var(--md-ref-palette-neutral20)'
            : 'var(--md-ref-palette-neutral90)',
        },
      }}
      className='noselect'
      onClick={() => {
        onSelect?.(emoji);
      }}
    >
      {emoji.character}
    </Box>
  );
}

export default EmojisButton;
