import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

// API keys for Firebase services are not used to control access to backend resources, so it's fine to put it in here
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// initialize firebase app
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// this is to register the firebase service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(() => {
      console.log('Service worker for firebase messaging has been registered.');
    })
    .catch((error) => {
      console.error(
        'Service worker for firebase messaging registration has been failed.',
      );
    });
}
