import React, {
  CSSProperties,
  FC,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import {
  AppBar,
  Box,
  CircularProgress,
  Modal,
  Stack,
  SxProps,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import AppBarControlPanel, {
  AppBarControlPanelProps,
} from './AppBarControlPanel';
import AppToolbar from './AppToolbar';
import { M3Button } from './M3/M3Button';
import { M3Fab } from './M3/M3Fab';
import SODForm, { SODFormModalViewProps } from './SodEodForm/SODForm';
import EODForm from './SodEodForm/EODForm';
import { WorkspaceCircleMenu, workspaceWidth } from './WorkspaceMenuList';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';
import SodEodSelectionView from './SodEodForm/SodEodSelectionView';
import SodEodErrorView from './SodEodForm/SodEodErrorView';
import ModalTypeOpenDetector from './Modals/ModalTypeOpenDetector';

import { useAppProvider } from '../providers/app/app';
import {
  useCurrentProfile,
  useUserShiftReportingStatus,
} from '../hooks/profile';
import { ReportType } from '../types/report';
import { MenuItem, ReactRenderElement } from '../types/types';
import { parseURL, toURL } from '../utils/url';
import { applyOpacityOnColor } from '../utils/color';
import { useAuthForm } from '../providers/auth/auth';
import * as posthog from '../services/posthog';
import * as pusher from '../services/pusher';

type Props = {
  children?: any;
};

type SodEodProps = {
  icon: ReactRenderElement;
  label: ReactRenderElement;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
};
type SodEodState = {
  open: boolean;
  type?: ReportType | 'SOD_EOD';
  Component: FC<SODFormModalViewProps | any>;
};

const menuIconButtonSx: SxProps = {};

const DummyComponent = forwardRef((props, ref) => null);

function AppNavigationBar({ children }: Props) {
  let { logout } = useAuthForm();
  const navigate = useNavigate();
  const { pathname, search: locationSearch } = useLocation();
  const {
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
    setDarkMode,
    updateActionKeyCounter,
  } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();

  const [sodEodState, setSodEodState] = useState<SodEodState>({
    open: false,
    Component: DummyComponent,
  });
  const parsedURL = parseURL(locationSearch);

  const standaloneMenus: (MenuItem & Partial<AppBarControlPanelProps>)[] = [
    {
      id: 'dark-mode-switch',
      navigate: false,
      name: isDarkMode ? 'Light Mode' : 'Dark Mode',
      icon: isDarkMode ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />,
      onClick: () => {
        setDarkMode(!isDarkMode);
        updateActionKeyCounter('toggle_switch_dark_mode');
      },
    },
    {
      id: 'help',
      name: 'Help',
      icon: <HelpOutlineOutlinedIcon />,
      withPopover: true,
      menus: [
        {
          id: 'help-center',
          name: 'Help Center',
          basePath: '/help/center',
          path: `/help/center`,
          active: pathname.includes('/help/center'),
          onClick: () => {
            posthog.capture('help center clicked');
          },
        },
        {
          id: 'help-view-updates',
          name: 'View Updates',
          onClick: () => {
            posthog.capture('view go2 portal updates clicked');
            window.open(
              'https://docs.google.com/document/d/1y8JxcqgFaOlT8J56mBYwjMljUFdA-M43kPKBzVyhxlw',
              '_target',
            );
          },
        },
      ],
    },
    {
      id: 'settings',
      name: 'Settings',
      icon: <SettingsOutlinedIcon />,
      basePath: '/settings',
      path: '/settings/personal/profile',
      active: pathname.includes('/settings'),
      withPopover: false,
    },
  ];

  const shiftReportingStatus = useUserShiftReportingStatus({ type: 'SOD_EOD' });
  const shiftReportingStatusData = shiftReportingStatus.data;

  const onStartEndClick = (type: SodEodState['type']) => {
    if (type === 'SOD') {
      posthog.capture('manifest SOD clicked');
    } else if (type === 'EOD') {
      posthog.capture('manifest EOD clicked');
    } else if (type === 'SOD_EOD') {
      posthog.capture('manifest SOD_EOD clicked');
    }

    const parsed = parseURL(locationSearch);
    navigate(
      toURL(pathname, {
        ...parsed,
        report_type_open: type,
      }),
      { replace: true },
    );
  };

  const renderSodEodButtons = () => {
    const sodEodProps: SodEodProps = {
      icon: <CreateOutlinedIcon sx={{ fontSize: 30 }} />,
      label: 'Checking...',
      disabled: true,
    };

    // check if this need to be a backtrack thing
    if (shiftReportingStatusData?.is_backtrack_allowed) {
      sodEodProps.label =
        shiftReportingStatusData?.next_report_type === 'SOD' ? 'Start' : 'End';
      sodEodProps.disabled = false;
      sodEodProps.style = {
        backgroundColor: isDarkMode
          ? 'var(--md-ref-palette-error50)'
          : 'var(--md-ref-palette-error80)',
      };
      sodEodProps.onClick = () => onStartEndClick('SOD_EOD');
    }
    // check if SOD has not submitted yet
    else if (shiftReportingStatusData?.next_report_type === 'SOD') {
      sodEodProps.label = 'Start';
      sodEodProps.disabled = false;
      sodEodProps.onClick = () => onStartEndClick('SOD');
    }
    // check if EOD has not submitted yet
    else if (shiftReportingStatusData?.next_report_type === 'EOD') {
      sodEodProps.label = 'End';
      sodEodProps.disabled = false;
      sodEodProps.onClick = () => onStartEndClick('EOD');
    }

    if (shiftReportingStatus.isLoading) {
      sodEodProps.label = 'Checking...';
      sodEodProps.disabled = true;
      sodEodProps.icon = <CircularProgress size={22} sx={{ mr: 1 }} />;
    }

    return (
      <M3Fab
        elevated={false}
        variant='extended'
        disabled={sodEodProps.disabled}
        onClick={sodEodProps.onClick}
        sx={{
          '&.Mui-disabled': {
            color: isDarkMode
              ? 'var(--md-ref-palette-primary90)'
              : 'var(--md-ref-palette-primary10)',
            background: 'transparent',
          },
        }}
        style={sodEodProps.style}
        data-testid='sod-eod-button'
      >
        {sodEodProps.icon}
        {sodEodProps.label}
      </M3Fab>
    );
  };

  const renderPlanRealityModal = () => {
    return (
      <Modal open={sodEodState.open}>
        {
          <sodEodState.Component
            fullView={parsedURL.report_type_platform === 'desktop'}
            animateOnMount={shiftReportingStatusData?.is_backtrack_allowed}
            close={(type: ReportType, param?: any) => {
              const parsed = parseURL(locationSearch);
              delete parsed.report_type_open;
              navigate(
                toURL(pathname, {
                  ...parsed,
                  ...param,
                }),
                { replace: true },
              );
              // Check if after being closed there's type provided
              // which means backtracking is allowed
              if (type === 'SOD' || type === 'EOD') {
                /**
                 * NOTE: Intended to differ the call here in order to properly
                 * close the conflict modal first before opening the next modal
                 */
                setTimeout(() => {
                  type === 'SOD'
                    ? onStartEndClick('SOD')
                    : onStartEndClick('EOD');
                }, 0);
              } else {
                shiftReportingStatus.refetch();
              }
            }}
          />
        }
      </Modal>
    );
  };

  /**
   * Listen when sod/eod form is open,
   * and update the url query state
   */
  useEffect(() => {
    const searchParsed = parseURL(locationSearch);

    /**
     * Check if it's not open and no report type is open, then there's
     * nothing to do here
     */
    if (!sodEodState.open && !searchParsed.report_type_open) {
      return;
    }

    /**
     * Check if the modal is already open
     */
    if (sodEodState.open) {
      // Check if there's no more type in the url
      if (!searchParsed.report_type_open) {
        setSodEodState({
          open: false,
          Component: DummyComponent,
        });
      }
      // Intended to return immediately if modal is already open
      return;
    }

    /**
     * Open only the SOD modal if the next shift status report type is also SOD
     */
    if (!!shiftReportingStatusData && searchParsed.report_type_open === 'SOD') {
      if (shiftReportingStatusData.next_report_type === 'SOD') {
        return setSodEodState({
          open: true,
          type: 'SOD',
          Component: SODForm,
        });
      } else {
        return setSodEodState({
          open: true,
          type: undefined,
          Component: SodEodErrorView,
        });
      }
    }

    /**
     * Open only the EOD modal if the next shift status report type is also EOD
     * or if backtracking is allowed
     */
    if (!!shiftReportingStatusData && searchParsed.report_type_open === 'EOD') {
      if (
        shiftReportingStatusData.next_report_type === 'EOD' ||
        shiftReportingStatusData.is_backtrack_allowed
      ) {
        return setSodEodState({
          open: true,
          type: 'EOD',
          Component: EODForm,
        });
      } else {
        return setSodEodState({
          open: true,
          type: undefined,
          Component: SodEodErrorView,
        });
      }
    }

    /**
     * Open only the SOD/EOD modal confirmation
     * if the backtracking is allowed and next shift status report type is SOD
     */
    if (
      !!shiftReportingStatusData &&
      searchParsed.report_type_open === 'SOD_EOD'
    ) {
      if (
        shiftReportingStatusData.is_backtrack_allowed &&
        shiftReportingStatusData.next_report_type === 'SOD'
      ) {
        return setSodEodState({
          open: true,
          type: 'SOD_EOD',
          Component: SodEodSelectionView,
        });
      } else {
        return setSodEodState({
          open: true,
          type: undefined,
          Component: SodEodErrorView,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, locationSearch, sodEodState, shiftReportingStatusData]);

  useEffect(() => {
    const eventCallback = (data: any) => {
      shiftReportingStatus.refetch();
    };
    const events = {
      'report-created': eventCallback,
    };

    let unsubscribe = pusher.subscribe(
      `cache-report_${currentProfile!.id}`,
      events,
    );

    return unsubscribe;
    // eslint-disable-next-line
  }, [currentProfile]);

  if (parsedURL.report_type_platform === 'desktop') {
    return <>{renderPlanRealityModal()}</>;
  }

  return (
    <>
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <AppToolbar
          style={{
            paddingLeft: 0,
            background: applyOpacityOnColor(
              isDarkMode
                ? palette['md.ref.palette.primary80']
                : palette['md.ref.palette.primary40'],
              getSurfaceOpacityByElevation(2),
            ),
          }}
        >
          <Box flex={1} display='flex' alignItems='center'>
            <Box
              sx={{
                height: toolbarHeight,
                width: workspaceWidth,
                minWidth: workspaceWidth,
                background: isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : 'var(--md-sys-color-background-light)',
                mr: 3,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: applyOpacityOnColor(
                    isDarkMode
                      ? palette['md.ref.palette.primary80']
                      : palette['md.ref.palette.primary40'],
                    getSurfaceOpacityByElevation(5),
                  ),
                }}
              >
                <WorkspaceCircleMenu />
              </Box>
            </Box>
            {renderSodEodButtons()}
          </Box>
          <Stack
            gap={1}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            <M3Button
              color='secondary'
              variant='outlined'
              sx={{ mr: 1.5 }}
              onClick={() => {
                posthog.capture('go2bots clicked');
                window.open(process.env.REACT_APP_GO2_CHATGPT_URL!);
              }}
            >
              &nbsp;Go2bots&nbsp;
            </M3Button>
            {standaloneMenus.map(
              (menu: MenuItem & Partial<AppBarControlPanelProps>) => {
                return (
                  <AppBarControlPanel
                    key={menu.id}
                    tooltip={{
                      title: menu.name as string,
                      placement: 'bottom',
                    }}
                    active={pathname.includes(menu.basePath!)}
                    redirectTo={menu.path}
                    icon={menu.icon}
                    onClick={menu.onClick}
                    iconButtonSx={menuIconButtonSx}
                    withPopover={menu.withPopover}
                    menus={menu.menus}
                  />
                );
              },
            )}
          </Stack>
          <M3Button
            sx={{
              pl: 2,
              pr: 2,
            }}
            onClick={logout}
            data-testid='logout-button'
          >
            <LogoutIcon sx={{ fontSize: 22 }} />
            Log out
          </M3Button>
        </AppToolbar>
      </AppBar>
      <AppToolbar />
      {renderPlanRealityModal()}
      <ModalTypeOpenDetector />
    </>
  );
}

export default AppNavigationBar;
