import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { CommentItem } from '../types/comment';
import { ContentTypeID } from '../types/content-type';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

export type UseCommentsProps = ListQuery & {
  content_type: ContentTypeID;
  object_id: number | string;
};
export function useComments<R = CommentItem>(
  params: UseCommentsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseCommentsProps>({
    params,
    baseIdentifier: 'useComments',
    identifierKeys: ['content_type', 'object_id'],
    queryParamKeys: ['content_type', 'object_id'],
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/core/comments/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export function usePostComment(axiosConfig?: Partial<AxiosRequestConfig>) {
  return useMutationApi('/core/comments/', {}, axiosConfig);
}

export function useUpdateComment<D>(
  id: number,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>(
    `/core/comments/${id}/`,
    {},
    { method: 'PATCH', ...axiosConfig },
  );
}

export function useDeleteComment(
  id?: number,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(
    `/core/comments/${id}/`,
    {},
    { method: 'DELETE', ...axiosConfig },
  );
}
