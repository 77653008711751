import React from 'react';
import clsx from 'clsx';
import { Card, CardProps, styled } from '@mui/material';
import { useAppProvider } from '../../providers/app/app';

export const M3CardBase = styled(Card)`
  transition: 50ms;
  border-radius: 12px;
  overflow: initial;

  &:not(.no-hover) {
    &.focused,
    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &.dark-mode-card {
    background-color: var(--md-sys-color-background-dark);

    &:not(.no-hover) {
      &.focused,
      &:hover {
        box-shadow: 0px 2px 1px -1px rgba(255, 255, 255, 0.2),
          0px 1px 1px 0px rgba(255, 255, 255, 0.14),
          0px 1px 3px 0px rgba(255, 255, 255, 0.12);
      }
    }
  }
`;

type M3CardProps = CardProps & {
  focused?: boolean;
  noHover?: boolean;
};
export const M3Card = ({
  className,
  focused,
  noHover,
  ...restProps
}: M3CardProps) => {
  const { isDarkMode } = useAppProvider();
  return (
    <M3CardBase
      {...restProps}
      className={clsx(className, 'm3-card', {
        focused,
        'no-hover': noHover,
        'dark-mode-card': isDarkMode,
      })}
    />
  );
};
