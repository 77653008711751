import { Box, Divider, Stack, Tabs, Typography } from '@mui/material';
import React, {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3TextField } from '../M3/M3TextField';
import RichEditor from '../RichEditor/RichEditor';
import { M3Button } from '../M3/M3Button';
import { useForm } from '../BasicForm';
import { M3Autocomplete } from '../M3/M3Autocomplete';
import RichTextContent from '../RichEditor/RichTextContent';
import { M3TabButton } from '../M3/M3TabButton';

import { useCurrentProfile, useUserSearch } from '../../hooks/profile';
import { useDebounce } from '../../hooks/utils/misc';
import { getUserProfileDisplayName } from '../../utils/user';
import { UserMetadata, UserSearchItemResponse } from '../../types/profile';
import { usePostGo2BotsMessage } from '../../hooks/go2bots/messages';
import { Go2BotsMessageItem } from '../../types/go2bots';
import * as posthog from '../../services/posthog';

type Props = ModalCardViewCloseProps & {
  onSuccess?: (data: Go2BotsMessageItem) => void;
};

type NewChatFormState = {
  user: string;
  user_id: number | null;
  content: string;
  title: string;
};

export function InboxDataNewChatFormModalViewBase({ close, onSuccess }: Props) {
  const { data: currentProfile } = useCurrentProfile();

  const { formState, handleSubmit, handleChange } = useForm<NewChatFormState>({
    user: '',
    user_id: null,
    content: '',
    title: '',
  });
  const [tabIndex, setTabIndex] = useState(0);

  const onTabIndexHandleChange = (event: SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  const searchSelect =
    (s: string) =>
    (data: UserSearchItemResponse[]): UserSearchItemResponse[] => {
      if (
        getUserProfileDisplayName(currentProfile!).fullName?.includes(
          s.toLowerCase(),
        ) ||
        currentProfile?.email.includes(s.toLowerCase())
      ) {
        data = [
          ...data,
          {
            id: `${currentProfile!.id}`,
            first_name: currentProfile!.first_name,
            last_name: currentProfile!.last_name,
            email: currentProfile!.email,
            photo_url: currentProfile!.photo_url,
            preferred_name: currentProfile!.preferred_name,
          },
        ];
      }

      return data;
    };

  const prevUser = useDebounce(formState.user);

  const { data: userSearchResults, isLoading: userSearchIsLoading } =
    useUserSearch(
      {
        s: prevUser || 'a',
      },
      {
        select: searchSelect(prevUser || 'a') as () => UserSearchItemResponse[],
      },
    );
  const userSearchOptions: any[] = useMemo(() => {
    return (userSearchResults ?? []).map((u) => ({
      id: u.id,
      label: getUserProfileDisplayName(u as unknown as Partial<UserMetadata>)
        .fullName,
    }));
  }, [userSearchResults]);

  const postGo2BotsMessage = usePostGo2BotsMessage();

  const submitDisabled =
    postGo2BotsMessage.isLoading ||
    !(formState.content && formState.user_id && formState.title);

  const onSubmit = handleSubmit((data) => {
    if (!data.content) return;

    postGo2BotsMessage.mutate({
      content: data.content,
      title: data.title,
      user: data.user_id,
      created_by: currentProfile!.id,
      metadata: {
        is_admin: true,
      },
    });
  });

  const handleOnEditorValueChange = (name: string) => (html: string) => {
    handleChange({
      target: {
        name,
        value: html,
      },
    });
  };

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      handleChange({
        target: {
          name,
          value: option ? option.id : null,
        },
      });
    };

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <ChatBubbleOutlineOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Box display='flex' flexWrap='nowrap'>
            <Typography
              display='flex'
              alignItems='center'
              flexWrap='nowrap'
              fontSize={20}
              fontWeight={500}
              component='div'
            >
              <span className='text-truncate' style={{ maxWidth: 420 }}>
                Start a New Chat
              </span>
            </Typography>
          </Box>
        </Typography>
      </Stack>
    );
  };

  const renderBottomPanel = () => {
    return (
      <Stack
        direction='row'
        sx={{ p: 2, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 100,
          }}
          disabled={submitDisabled}
          onClick={onSubmit}
        >
          Send
        </M3Button>
      </Stack>
    );
  };

  const renderUserSelection = () => {
    return (
      <Box
        sx={{
          mb: 2,
        }}
      >
        <M3Autocomplete
          // key={formKeyCount.requester}
          options={userSearchOptions as unknown as any}
          // getOptionLabel={(option: any) =>
          //   getUserProfileDisplayName(option as UserMetadata).fullName ?? ''
          // }
          loading={userSearchIsLoading}
          onChange={onAutocompleteChangeHandler('user_id')}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <M3TextField
              {...params}
              name='user'
              label='User'
              fullWidth
              value={formState.user}
              onChange={handleChange}
            />
          )}
          sx={{
            flex: 1,
          }}
        />
      </Box>
    );
  };

  const renderForm = () => {
    return (
      <>
        <M3TextField
          fullWidth
          stripAllAutoProps
          label='Title'
          name='title'
          placeholder='Title'
          value={formState.title}
          onChange={handleChange}
          sx={{
            mb: 2,
          }}
        />
      </>
    );
  };

  const renderEditor = () => {
    return (
      <>
        <Typography component='div' fontSize={13} mb={1} sx={{ opacity: 0.5 }}>
          Write your message below:
        </Typography>
        <RichEditor
          withMentions={false}
          value={formState.content}
          onValueChange={handleOnEditorValueChange('content')}
          placeholder={'Write here...'}
        />
      </>
    );
  };

  const renderPreview = () => {
    return (
      <>
        <Typography>
          <RichTextContent content={formState.content} />
        </Typography>
      </>
    );
  };

  const renderTabNavigation = () => {
    return (
      <Box
        position='relative'
        sx={{
          mt: -2,
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={onTabIndexHandleChange}
          style={{
            height: 56,
          }}
        >
          <M3TabButton disableRipple label='Write' />
          <M3TabButton disableRipple label='Preview' />
        </Tabs>
        <Divider flexItem sx={{ borderWidth: 1, opacity: 0.5, mb: 1.5 }} />
      </Box>
    );
  };

  useEffect(() => {
    if (postGo2BotsMessage.isSuccess) {
      const data = postGo2BotsMessage.data as Go2BotsMessageItem;
      posthog.capture('inbox new chat submitted', {
        id: data.id,
        title: data.title,
        user: data.user,
      });
      onSuccess?.(postGo2BotsMessage.data);
      close?.();
    }
    // eslint-disable-next-line
  }, [postGo2BotsMessage.isSuccess]);

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={renderBottomPanel()}
      close={close}
    >
      <Box sx={{ pt: 4, pb: 2 }}>
        {renderUserSelection()}
        {renderForm()}
        {renderTabNavigation()}
        {tabIndex === 0 && renderEditor()}
        {tabIndex === 1 && renderPreview()}
      </Box>
    </ModalCardView>
  );
}

const InboxDataNewChatFormModalView = forwardRef((props: Props, ref) => (
  <InboxDataNewChatFormModalViewBase {...props} />
));

export default InboxDataNewChatFormModalView;
