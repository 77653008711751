import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import '../TimeDetailsTable/time-details-table.css';

const TimeDetailsSkeleton = (props: any) => {
  return (
    <Box pl={2}>
      {Array.from({ length: 7 }, (_, i) => (
        <Box key={i} height={55}>
          <Skeleton
            height={72}
            sx={{
              borderRadius: 3,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default TimeDetailsSkeleton;
