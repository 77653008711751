import React, { useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import IntercomCollectionSkeleton from './IntercomCollectionSkeleton';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import { M3Card } from '../M3/M3Card';

import {
  CollectionItemProps,
  getCollectionItemProps,
} from '../../providers/intercom';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import { cleanURL } from '../../utils/url';
import { useAppProvider } from '../../providers/app/app';

type IntercomCollectionsProps = {
  basePath: string;
};

const IntercomCollections = ({ basePath }: IntercomCollectionsProps) => {
  const { collections, admins } = useIntercomProvider();

  const items: CollectionItemProps[] = useMemo(() => {
    const items: CollectionItemProps[] = [];
    collections.map((collection) =>
      items.push(getCollectionItemProps(collection, admins)),
    );
    return items;
  }, [collections, admins]);

  return (
    <>
      <br />
      {!items.length &&
        new Array(3)
          .fill(null)
          .map((_, i) => <IntercomCollectionSkeleton key={i} />)}
      {items.map((item, index) => {
        return (
          <IntercomCollectionItem
            key={item.id}
            item={item}
            to={`${basePath}/${item.collection.type ?? 'collection'}/${
              item.id
            }`}
          />
        );
      })}
    </>
  );
};

type IntercomCollectionItemProps = {
  item: CollectionItemProps;
  to: string;
};
export const IntercomCollectionItem = ({
  to,
  item,
}: IntercomCollectionItemProps) => {
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();

  return (
    <M3Card
      sx={{
        marginBottom: '16px',
      }}
      className='m3-card'
    >
      <M3SurfaceContainer elevation={1}>
        <Paper
          className='collection__paper'
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            position: 'relative',
          }}
          style={{
            marginTop: 0,
            minHeight: 118,
            background: 'transparent',
          }}
          onClick={() => {
            navigate(cleanURL(to));
          }}
        >
          <Box className='collection__photo'>
            <Box
              style={{
                height: 48,
                width: 48,
                margin: 'auto',
              }}
            >
              <item.Icon />
            </Box>
          </Box>
          <Box flex={1} className='collection__content'>
            <Typography
              fontSize={18}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)',
              }}
            >
              {item.collection.name}
            </Typography>
            {!!item.collection.description && (
              <Typography
                fontSize={16}
                sx={{
                  opacity: 0.8,
                  color: isDarkMode
                    ? 'var(--md-sys-color-on-surface-dark)'
                    : 'var(--md-sys-color-on-surface-light)',
                }}
                style={{
                  maxWidth: 630,
                  padding: '5px 0 0',
                }}
              >
                {item.collection.description}
              </Typography>
            )}
          </Box>
        </Paper>
      </M3SurfaceContainer>
    </M3Card>
  );
};

export default IntercomCollections;
