import React, { useMemo, useState, SyntheticEvent, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import { M3OptionItem } from '../../Popover/BasicPopoverWithSearch';
import AutocompleteTags, { TagItem } from '../../Filters/AutocompleteTags';

import { useJobDescriptions } from '../../../hooks/jobs';
import { useDebounce } from '../../../hooks/utils/misc';
import { IterableObject } from '../../../types/types';
import { useWorkspaceProvider } from '../../../providers/workspace/workspace';
import { useUpdateEffect } from '../../../hooks/global/useUpdateEffect';

type JobOptionItem = { id: number; title: string };
type FocusJobsProjectsProps = {
  isNew?: boolean;
  editable?: boolean;
  divisionId?: number | string | null;
  disableRemoveWhenOneLeft?: boolean;
  jobs?: JobOptionItem[];
  onJobsChanged?: (
    jobs: JobOptionItem[],
    job?: JobOptionItem,
    action?: 'add' | 'remove',
  ) => void;
};

const FocusJobsProjects = ({
  isNew,
  editable,
  divisionId,
  onJobsChanged,
  disableRemoveWhenOneLeft,
  jobs = [],
}: FocusJobsProjectsProps) => {
  const [search, setSearch] = useState('');
  const searchDebounce = useDebounce(search);
  const { current: currentWorkspace } = useWorkspaceProvider();

  const [linkedJobs, setLinkedJobs] = useState<number[]>(() =>
    jobs.map((j) => j.id),
  );
  const [linkedJobsDetail, setLinkedJobsDetail] = useState<
    IterableObject<M3OptionItem>
  >(() => {
    const state: IterableObject<M3OptionItem> = {};
    jobs.forEach((j) => {
      state[j.id] = {
        id: j.id,
        label: j.title,
      };
    });
    return state;
  });

  const jobDescriptions = useJobDescriptions(
    {
      key: 'focus_jobs',
      limit: 30,
      title: searchDebounce,
      divisions: divisionId,
    },
    {
      cacheTime: 0,
    },
  );
  const projectOptions: M3OptionItem[] = useMemo(() => {
    return (jobDescriptions.data?.results ?? [])
      .filter((jd) => {
        return !linkedJobs.includes(+jd.id);
      })
      .map((jd) => ({
        id: jd.id,
        label: jd.title,
      }));
  }, [jobDescriptions.data, linkedJobs]);

  const handleOnJobSearchChange = (evt: SyntheticEvent) => {
    const value = (evt.currentTarget as HTMLInputElement).value;
    setSearch(value);
  };

  /**
   * Reset all selected linked jobs
   */
  useEffect(() => {
    if (!isNew) {
      setLinkedJobs(jobs.map((j) => j.id));
      setLinkedJobsDetail(() => {
        const state: IterableObject<M3OptionItem> = {};
        jobs.forEach((j) => {
          state[j.id] = {
            id: j.id,
            label: j.title,
          };
        });
        return state;
      });
    }
    // eslint-disable-next-line
  }, [isNew, jobs, currentWorkspace, setLinkedJobs, setLinkedJobsDetail]);

  /**
   * Reset all selected linked jobs only if the workspace changes while
   * in the jobs tabs
   */
  useUpdateEffect(() => {
    if (!isNew) {
      setLinkedJobs(jobs.map((j) => j.id));
      setLinkedJobsDetail(() => {
        const state: IterableObject<M3OptionItem> = {};
        jobs.forEach((j) => {
          state[j.id] = {
            id: j.id,
            label: j.title,
          };
        });
        return state;
      });
    } else {
      setLinkedJobs([]);
      onJobsChanged?.([]);
    }
    // eslint-disable-next-line
  }, [isNew, currentWorkspace, setLinkedJobs, setLinkedJobsDetail]);

  return (
    <Box
      gap={1}
      pb={2}
      mt={-4}
      minWidth={300}
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='flex-start'
    >
      <Typography
        component='div'
        display='flex'
        width='100%'
        justifyContent='space-between'
        fontWeight={500}
        fontSize={14}
      >
        <span></span>
        <span>
          ({linkedJobs.length}) job{linkedJobs.length > 1 ? 's' : ''} selected
        </span>
      </Typography>
      <Box width='100%'>
        <AutocompleteTags
          tagsOnly={!editable}
          placeholder={`Search jobs${
            currentWorkspace?.id === 'my-squad'
              ? ''
              : ` in ${currentWorkspace?.name}`
          }`}
          options={projectOptions}
          getOptionLabel={(option: any) => option.label}
          loading={jobDescriptions.isFetching}
          tags={linkedJobs.map((id) => ({
            id,
            value: linkedJobsDetail[id].label,
          }))}
          onDeleteTag={
            disableRemoveWhenOneLeft && linkedJobs.length <= 1
              ? undefined
              : (tag: TagItem) => {
                  setLinkedJobs((state) => {
                    state = state.filter((id) => id !== tag.id);
                    onJobsChanged?.(
                      state.map((id) => ({
                        id: id,
                        title: linkedJobsDetail[id].label,
                      })) as JobOptionItem[],
                      {
                        id: +tag.id,
                        title: tag.value,
                      },
                      'remove',
                    );
                    return state;
                  });
                }
          }
          onSelect={(evt: SyntheticEvent, option: M3OptionItem) => {
            if (option) {
              setLinkedJobs((state) => {
                state = Array.from(new Set([...state, +option.id]));
                onJobsChanged?.(
                  state.map((id) => ({
                    id: id,
                    title: (linkedJobsDetail[id] ?? option).label,
                  })) as JobOptionItem[],
                  {
                    id: +option.id,
                    title: option.label,
                  },
                  'add',
                );
                return state;
              });
              setLinkedJobsDetail((state) => ({
                ...state,
                [option.id]: option,
              }));
            }
          }}
          onChange={handleOnJobSearchChange}
        />
      </Box>
    </Box>
  );
};

export default FocusJobsProjects;
