import React from 'react';
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import MainContainer from '../components/MainContainer';
import IntercomHeader from '../components/Intercom/IntercomHeader';
import IntercomCollections from '../components/Intercom/IntercomCollections';
import IntercomSearchResults from '../components/Intercom/IntercomSearchResults';
import IntercomCollectionDetail from '../components/Intercom/IntercomCollectionDetail';
import AppFooter from '../components/AppFooter';

import { useIntercomProvider } from '../providers/intercom/intercom';
import { useIntercomAllData } from '../providers/intercom';
import {
  useIntercomArticles,
  useIntercomSections,
  useIntercomArticlesInCollection,
  useIntercomAdmins,
  useIntercomCollections,
} from '../hooks/intercom';

type DataRoomProps = {
  basePath?: string;
};

const DataRoom = ({ basePath: defaultBasePath }: DataRoomProps) => {
  const basePath = defaultBasePath ?? '/help/dataroom';
  const { search } = useIntercomProvider();

  useIntercomAllData({
    useIntercomAdminsHook: useIntercomAdmins,
    useIntercomCollectionsHook: useIntercomCollections,
  });

  return (
    <>
      <Box
        flex={1}
        sx={{ position: 'relative', zIndex: 1 }}
        className='intercom-page'
      >
        <IntercomHeader
          title='WIP Data Room'
          withGo2HelpLink={false}
          basePath={basePath}
        />
        <Box>
          <MainContainer
            flex={false}
            sx={{
              maxWidth: 980,
              minHeight: 'calc(100vh - 412px)',
              margin: 'auto',
            }}
          >
            <Routes>
              <Route
                path='/collection/:collectionId'
                element={
                  <IntercomCollectionDetail
                    basePath={basePath}
                    useIntercomSectionsHook={useIntercomSections}
                    useIntercomArticlesInCollectionHook={
                      useIntercomArticlesInCollection
                    }
                  />
                }
              />
              <Route
                path='/'
                element={
                  search ? (
                    <IntercomSearchResults
                      useIntercomArticlesHook={useIntercomArticles}
                    />
                  ) : (
                    <IntercomCollections basePath={basePath} />
                  )
                }
              />
            </Routes>
          </MainContainer>
        </Box>
        <AppFooter withDrawerPadding={false} withGo2HelpLink={false} />
      </Box>
    </>
  );
};

export default DataRoom;
