import TeamCalendar from './TeamCalendar';
import TeamTime from './TeamTime';
import TeamSchedule from './TeamSchedule';
import MainContainer from '../components/MainContainer';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import SettingsAdminSchedule from './SettingsAdminSchedule';
import TeamUtilization from './TeamUtilization';
import TeamJobs from './TeamJobs';
import TeamFocus from './TeamFocus';
import TeamResources from './TeamResources';
import TeamTeamOneOnOne from './TeamTeamOneOnOne';
import TeamMyOneOnOne from './TeamMyOneOnOne';
import TeamTeamSprints from './TeamTeamSprints';
import TeamMySprints from './TeamMySprints';

import { useHasViewPermission } from '../components/HasViewPermission';
import { usePathnameSegments } from '../hooks/utils/url';

type TeamProps = {};

export type TeamDrawerSegments =
  | 'calendar'
  | 'time'
  | 'schedule'
  | 'utilization'
  | 'jobs'
  | 'focus'
  | 'resources'
  | '1-1s'
  | 'sprints';

const Team = (props: TeamProps) => {
  const [, segment, secondSegment] =
    usePathnameSegments<TeamDrawerSegments[]>();

  const hasAdminScheduleRights = useHasViewPermission({
    roles: ['superuser', 'internal', 'partner_member', 'guest'],
  });
  const hasTeamOneOneOneRights = useHasViewPermission({
    roles: ['superuser', 'developer', 'escalation_manager', 'squad_lead'],
  });
  const hasTeamSprintRights = useHasViewPermission({
    roles: ['superuser', 'developer', 'escalation_manager', 'squad_lead'],
  });

  if (segment === 'calendar') {
    return <TeamCalendar />;
  }

  if (segment === 'time') {
    return <TeamTime />;
  }

  if (segment === 'schedule') {
    if (hasAdminScheduleRights) return <SettingsAdminSchedule />;

    return <TeamSchedule />;
  }

  if (segment === 'resources') {
    return <TeamResources />;
  }

  if (segment === 'utilization') {
    return <TeamUtilization />;
  }

  if (segment === 'jobs') {
    return <TeamJobs />;
  }

  if (segment === 'focus') {
    return <TeamFocus />;
  }

  if (segment === '1-1s') {
    if ((secondSegment as string) === 'team-1-1s') {
      if (hasTeamOneOneOneRights) {
        return <TeamTeamOneOnOne />;
      }
    } else if ((secondSegment as string) === 'my-1-1s') {
      return <TeamMyOneOnOne />;
    }
  }

  if (segment === 'sprints') {
    if ((secondSegment as string) === 'team-sprints') {
      if (hasTeamSprintRights) {
        return <TeamTeamSprints />;
      }
    } else if ((secondSegment as string) === 'my-sprints') {
      return <TeamMySprints />;
    }
  }

  return (
    <MainContainer>
      <ErrorUnauthorizedAccess />
    </MainContainer>
  );
};

export default Team;
