/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import AppActionToolbar from '../components/AppActionToolbar';
import MainContainer from '../components/MainContainer';
import IntercomBreadcrumbs, {
  BreadcrumbItem,
} from '../components/Intercom/IntercomBreadcrumbs';
import SearchForm from '../components/Filters/SearchForm';
import PageWait from '../components/PageWait';
import { M3Button } from '../components/M3/M3Button';
import JobDescriptionItem, {
  JobDescriptionItemSkeleton,
} from '../components/TeamSettings/JobDescription/JobDescriptionItem';
import DocumentTitle from '../components/DocumentTitle';
import LoadMorePlaceholder from '../components/LoadMorePlaceholder';
import { M3OptionItem } from '../components/Popover/BasicPopoverWithSearch';

import { JobDescriptionItemResult } from '../types/job-description';
import { useJobDescriptions, UseJobDescriptionsProps } from '../hooks/jobs';
import { useInfinite } from '../hooks/global/useInfinite';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useSnackbar } from 'notistack';
import HasViewPermission from '../components/HasViewPermission';

type SettingsTeamAllJobsProps = {
  editable?: boolean;
  routeKey: 'builder' | 'team';
};
interface ISettingsTeamAllJobsLocationState {
  deletedJobId?: string;
}

const SettingsTeamAllJobs = ({
  editable = true,
  routeKey,
}: SettingsTeamAllJobsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { items: workspaces, current: currentWorkspace } =
    useWorkspaceProvider();
  const { enqueueSnackbar } = useSnackbar();

  const workspaceOptions: M3OptionItem[] = useMemo(() => {
    const oses = workspaces.filter((ws) => ws.type === 'division_partner');
    return [
      oses.length > 1
        ? {
            id: 'all',
            label: 'All',
          }
        : null,
      ...oses.map((ws) => ({
        id: ws.id as string,
        label: ws.name,
      })),
    ].filter((m) => !!m) as M3OptionItem[];
  }, [workspaces]);

  const infinite = useInfinite<
    JobDescriptionItemResult,
    UseJobDescriptionsProps
  >({
    useQuery: useJobDescriptions,
    queryParams: {
      limit: 30,
      title: search,
      divisions:
        currentWorkspace?.id === 'my-squad'
          ? workspaceOptions
              .filter((ws) => ws.id !== 'all')
              .map((ws) => ws.id)
              .join(',')
          : currentWorkspace?.id,
    },
  });

  const getRoutes = () => {
    let breadCrumbs: BreadcrumbItem[] = [];
    let basePath = '';
    let trailingTitle = '';

    if (routeKey === 'builder') {
      trailingTitle = 'Builder';
      basePath = `/builder/jobs`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Jobs',
          path: basePath,
        },
      ];
    } else if (routeKey === 'team') {
      trailingTitle = 'Team';
      basePath = `/team/jobs/${currentWorkspace?.id}`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Jobs',
          path: basePath,
        },
      ];
    }

    return {
      breadCrumbs,
      basePath,
      trailingTitle,
    };
  };

  const onCreateJob = () => {
    navigate('/builder/jobs/create');
  };

  useEffect(() => {
    infinite.reset({
      emptyList: true,
    });
  }, [search, currentWorkspace]);

  useEffect(() => {
    if ((location.state as ISettingsTeamAllJobsLocationState)?.deletedJobId) {
      enqueueSnackbar('Job Description successfully deleted.');
    }
  }, [location]);

  return (
    <>
      <DocumentTitle title='Jobs' trailingTitle={getRoutes().trailingTitle} />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          {/* {renderTypeSwitcherDropdown()} */}
          <SearchForm
            placeholder={`Search jobs${
              currentWorkspace?.id === 'my-squad'
                ? ''
                : ` in ${currentWorkspace?.name}`
            }`}
            onStateUpdated={(state) => {
              setSearch(state.search);
            }}
          />
          <Box
            ml={3}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            {editable && (
              <HasViewPermission restrict={['guest']}>
                <M3Button
                  color='primary'
                  variant='contained'
                  onClick={onCreateJob}
                >
                  <AddOutlinedIcon />
                  Create a Job
                </M3Button>
              </HasViewPermission>
            )}
          </Box>
        </AppActionToolbar>
        <IntercomBreadcrumbs items={getRoutes().breadCrumbs} />
        <br />
        {!infinite.data.length &&
          infinite.isLoading &&
          new Array(3)
            .fill(null)
            .map((_, i) => <JobDescriptionItemSkeleton key={i} />)}
        {!infinite.data.length && !infinite.isLoading && !!search && (
          <PageWait
            title={`No job description found`}
            message={
              <>
                We could not find any job description:{' '}
                <strong style={{ fontWeight: 500 }}>{search}</strong>.
              </>
            }
          />
        )}
        {infinite.data.map((item) => {
          return (
            <Box key={item.id} position='relative'>
              <JobDescriptionItem
                item={item}
                path={`${getRoutes().basePath}/${item.id}`}
              />
            </Box>
          );
        })}
        <LoadMorePlaceholder
          hasReachEnd={infinite.hasReachEnd}
          isLoading={infinite.isLoadingMore}
        />
      </MainContainer>
    </>
  );
};

export default SettingsTeamAllJobs;
