import React from 'react';
import moment from 'moment';
import { Box, SxProps, Typography } from '@mui/material';

type DayColumnsProps = {
  columnSx: SxProps;
  days: moment.Moment[];
  range: string[];
};

const DayColumns: React.FC<DayColumnsProps> = ({ columnSx, days, range }) => {
  return (
    <Box flex={1}>
      <Box flex={1} display='flex'>
        {days.map((m, i) => {
          const date = moment(range[0]).add(i, 'day');

          return (
            <Typography
              key={i}
              component='div'
              flex={1}
              width={0}
              fontWeight={500}
              fontSize={16}
              sx={{
                ...columnSx,
                height: 30,
                minHeight: 30,
                textAlign: 'center',
              }}
            >
              {date.format('ddd')}
              <span
                style={{
                  opacity: 0.5,
                  fontSize: 12,
                  paddingLeft: 4,
                  marginRight: -10,
                }}
              >
                ({date.format('MMM D')})
              </span>
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default DayColumns;
