import { Box, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  oneDark,
  oneLight,
} from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { useAppProvider } from '../../providers/app/app';

interface Props {
  language?: string;
  value: string;
}

export const CodeBlock: FC<Props> = memo(({ language, value }) => {
  const { isDarkMode } = useAppProvider();

  return (
    <Box>
      {!!language && (
        <Box
          sx={{
            pb: 1,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            marginBottom: -1,
            background: isDarkMode
              ? 'var(--md-ref-palette-neutral-variant10)'
              : 'var(--md-ref-palette-neutral-variant90)',
          }}
        >
          <Typography
            component='div'
            fontSize={11}
            fontWeight={700}
            style={{
              color: isDarkMode
                ? 'var(--md-ref-palette-tertiary80)'
                : 'var(--md-ref-palette-tertiary40)',
            }}
            sx={{
              p: 0.5,
              pl: 1.5,
              pr: 1.5,
            }}
          >
            {language}
          </Typography>
        </Box>
      )}

      <SyntaxHighlighter
        language={language ?? 'code'}
        style={isDarkMode ? oneDark : oneLight}
        customStyle={{ margin: 0 }}
      >
        {value}
      </SyntaxHighlighter>
    </Box>
  );
});

CodeBlock.displayName = 'CodeBlock';
