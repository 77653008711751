import { Box, SxProps } from '@mui/material';
import React, { CSSProperties, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { M3IconButton } from '../M3/M3Button';

import { ReactRenderElement } from '../../types/types';

type QuillCustomToolbarButtonProps = PropsWithChildren & {
  content?: ReactRenderElement;
  sx?: SxProps;
  style?: CSSProperties;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

const QuillCustomToolbarButton = ({
  content,
  sx,
  style,
  disabled,
  active,
  className,
  onClick,
  children,
}: QuillCustomToolbarButtonProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      position='relative'
    >
      <M3IconButton
        sx={{
          ...sx,
        }}
        disableRipple
        active={active}
        style={style}
        disabled={disabled}
        className={clsx('quill-custom-toolbar-button', className)}
        onClick={onClick}
      >
        {content}
      </M3IconButton>
      {children}
    </Box>
  );
};

export default QuillCustomToolbarButton;
