import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { useForm } from '../../BasicForm';
import { M3TextField } from '../../M3/M3TextField';
import { M3IconButton } from '../../M3/M3Button';
import { useAppProvider } from '../../../providers/app/app';
import { usePatchTimeTrackerTask } from '../../../hooks/time-tracker';

type Props = {
  taskFocusId?: number | null;
  title?: string;
  editable?: boolean;
  withSave?: boolean;
  onChange?: (value: string) => void;
  onSaved?: () => void;
};

const FocusTitleForm = ({
  taskFocusId,
  title: initialTitle,
  withSave,
  editable,
  onChange,
  onSaved,
}: Props) => {
  const { isDarkMode } = useAppProvider();

  const { formState, handleChange, hasChanged, resetState } = useForm(
    { title: initialTitle ?? '' },
    {
      onStateUpdated: (state) => {
        onChange?.(state.title);
      },
    },
  );

  const patchTimeTrackerTask = usePatchTimeTrackerTask({ id: taskFocusId! });
  const disableSubmit = patchTimeTrackerTask.isLoading;

  const onSave = () => {
    if (
      !editable ||
      !withSave ||
      !taskFocusId ||
      patchTimeTrackerTask.isLoading
    ) {
      return;
    }
    patchTimeTrackerTask.mutate({
      name: formState.title,
    });
  };

  /**
   * After successfully updated the job description title, set the flag that
   * the job description should refetch the new updated data
   */
  useEffect(() => {
    if (patchTimeTrackerTask.isSuccess) {
      onSaved?.();
      resetState({ title: patchTimeTrackerTask.data.name });
      patchTimeTrackerTask.reset();
    }
    // eslint-disable-next-line
  }, [patchTimeTrackerTask.isSuccess]);

  return (
    <Stack mb={1} gap={3} flex={1} direction='row'>
      {editable ? (
        <M3TextField
          fullWidth
          name='title'
          placeholder='Focus Name'
          value={formState.title}
          onChange={handleChange}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            minWidth: 220,
            '.MuiInputBase-input': {
              height: 40,
              fontSize: 20,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 1,
              fontWeight: 500,
            },
            '.MuiOutlinedInput-notchedOutline': {
              fontSize: 20,
              borderTop: '0 !important',
              borderLeft: '0 !important',
              borderRight: '0 !important',
              borderRadius: 0,
            },
            fieldset: {
              border: 'none !important',
            },
          }}
        />
      ) : (
        <Typography
          component='h5'
          fontSize={20}
          fontWeight={500}
          whiteSpace='nowrap'
          position='relative'
          sx={{
            pb: 0.5,
            mb: 0.8,
            ':before': {
              left: 0,
              bottom: 0,
              height: 3,
              width: '100%',
              content: '""',
              opacity: 0.5,
              borderRadius: 1,
              position: 'absolute',
              backgroundColor: isDarkMode
                ? 'var(--md-ref-palette-tertiary30)'
                : 'var(--md-ref-palette-tertiary80)',
            },
          }}
        >
          {initialTitle || formState.title}
        </Typography>
      )}
      {(hasChanged || withSave || editable) && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: 30,
            height: 30,
            minWidth: 30,
            maxWidth: 30,
          }}
        >
          {hasChanged && withSave ? (
            <M3IconButton
              color='primary'
              sx={{
                width: 30,
                height: 30,
                background: isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)',
                '&:hover': {
                  background: isDarkMode
                    ? 'var(--md-ref-palette-primary90) !important'
                    : 'var(--md-ref-palette-primary50) !important',
                },
              }}
              disabled={disableSubmit}
              onClick={onSave}
            >
              <DoneOutlinedIcon
                style={{
                  fontSize: 20,
                  color: isDarkMode
                    ? 'var(--md-ref-palette-primary10)'
                    : 'var(--md-ref-palette-primary90)',
                }}
              />
            </M3IconButton>
          ) : (
            editable && (
              <EditOutlinedIcon
                style={{
                  fontSize: 20,
                  opacity: 0.8,
                }}
              />
            )
          )}
        </Box>
      )}
    </Stack>
  );
};

export default FocusTitleForm;
