/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import _isUndefined from 'lodash/isUndefined';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import TimeDetailsEditForm from '../components/EditTime/components/TimeDetailsEditForm/TimeDetailsEditForm';
import TimeInfoCardContainer from '../components/EditTime/components/TimeInfoCardContainer/TimeInfoCardContainer';
import Timeline from '../components/EditTime/components/Timeline/Timeline';
import TimeDetailsTable from '../components/EditTime/components/TimeDetailsTable/TimeDetailsTable';
import { M3Autocomplete } from '../components/M3/M3Autocomplete';
import { M3TextField } from '../components/M3/M3TextField';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import { M3OptionItem } from '../components/Popover/BasicPopoverWithSearch';
import { IEditTimeDetailsForm } from '../components/EditTime/constants/edit-time-details-form.interface';
import { defaultTimeDetailsFormValues } from '../components/EditTime/constants/default-time-details-form-values';
import { isActiveWorklog } from '../components/EditTime/components/TimeDetailsTableRow/TimeDetailsTableRow';
import Timezone, { useTimezone } from '../components/Util/Timezone';
import TimelineColorMap from '../components/EditTime/components/Timeline/TimelineColorMap';

import { formatDate } from '../utils/date';
import { useUserSearch } from '../hooks/profile';
import { MemberModel } from '../types/member';
import { UserSearchItemResponse } from '../types/profile';
import { useFetchTimeDetails } from '../hooks/edit-time';
import { useTick } from '../hooks/utils/tick';
import * as posthog from '../services/posthog';

type M3UsersOption = M3OptionItem<
  MemberModel | UserSearchItemResponse | undefined
>;

type SettingsAdminEditTimeProps = {};
const SettingsAdminEditTime: FC<SettingsAdminEditTimeProps> = () => {
  const dateFormat = 'YYYY-MM-DD';

  const [selectedDate, setSelectedDate] = useState(formatDate(dateFormat));
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isTimeDetailsModalOpen, setIsTimeDetailsModalOpen] = useState(false);
  const [timeDetailsModalHeader, setTimeDetailsModalHeader] = useState('');
  const [formData, setFormData] = useState<IEditTimeDetailsForm>(
    defaultTimeDetailsFormValues,
  );

  /**
   * In order for the whole component page to keep updating the time so it
   * forces the whole component to recheck their initial state. Good use
   * to that active worklog have always current time
   */
  useTick();

  const momentSelectedDate = moment(selectedDate);
  const timezone = useTimezone();

  const { data: searchedUser, isLoading: isSearchedUserLoading } =
    useUserSearch(
      { s: debouncedSearchTerm },
      { enabled: debouncedSearchTerm.length > 0 },
    );

  const {
    refetch: refetchTimelineData,
    data: timeDetailsData,
    isLoading: isTimeDetailsDataLoading,
  } = useFetchTimeDetails(
    {
      key: 'admin_edit_time_fetch_details',
      start_date: momentSelectedDate.clone().startOf('day').utc().format(),
      timezone,
      limit: 200,
      users: selectedUserId?.toString(),
    },
    {
      // Refetch the interval every 4 minutes, 1 minute less between sync time on Go2 desktop
      enabled: !!selectedUserId,
      refetchInterval: 240 * 1000,
      refetchOnWindowFocus: true,
      select(data) {
        const newData = data.results.map(
          ({ start, end, ...timeDetails }, index) => {
            const endTimeLocal = end ? moment.utc(end).local() : null;
            let startTimeLocal = moment.utc(start).local();

            if (
              startTimeLocal.isBefore(momentSelectedDate.clone().startOf('day'))
            ) {
              startTimeLocal = momentSelectedDate.clone().startOf('day');
            }

            return {
              ...timeDetails,
              start,
              end,
              start_time_local: startTimeLocal,
              end_time_local: endTimeLocal,
            };
          },
        );

        return {
          ...data,
          results: [...newData],
        };
      },
    },
  );

  // Helper function for mapping data to M3UsersOption format
  const mapToOption = ({
    id,
    first_name,
    last_name,
  }: UserSearchItemResponse | MemberModel) => ({
    id,
    label: `${first_name} ${last_name}`,
  });

  const usersDropdownListData: M3UsersOption[] =
    searchedUser?.map(mapToOption) ?? [];

  const handleMemberNameInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchTerm(event.target.value);

  const handleUserSelect = (
    event: React.SyntheticEvent<Element, Event>,
    selected:
      | NonNullable<string | M3UsersOption>
      | (string | M3UsersOption)[]
      | null,
  ) => {
    const objSelectedUser = selected as M3UsersOption;
    setSelectedUserId(objSelectedUser?.id as number);
  };

  const handleDateChange = (newDate: Moment | null): void =>
    setSelectedDate(formatDate(dateFormat, newDate));

  const handleIncrementDate = () =>
    setSelectedDate(moment(selectedDate).add(1, 'day').format(dateFormat));

  const handleDecrementDate = () => {
    setSelectedDate(moment(selectedDate).subtract(1, 'day').format(dateFormat));
  };

  const handleToggleModalState = () =>
    setIsTimeDetailsModalOpen((prevState) => !prevState);

  const handleModalOpen = (isEditTime = false) => {
    handleToggleModalState();
    setTimeDetailsModalHeader(`${isEditTime ? 'Edit' : 'Add'} Time`);
  };

  const handleModalClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      handleToggleModalState();
      setFormData(defaultTimeDetailsFormValues);
    }
    !!selectedUserId && refetchTimelineData();
  };

  const handleOnDeleteSuccess = useCallback(
    () => !!selectedUserId && refetchTimelineData(),
    [selectedUserId, refetchTimelineData],
  );

  const debouncedUpdate = useCallback(
    debounce((term) => {
      setDebouncedSearchTerm(term);
    }, 300),
    [],
  );

  const latestWorklogEntry =
    timeDetailsData?.results.find((row, index) =>
      isActiveWorklog(row, index),
    ) ?? null;

  useEffect(() => {
    debouncedUpdate(searchTerm);
  }, [searchTerm, debouncedUpdate]);

  useEffect(() => {
    !!selectedUserId && refetchTimelineData();
  }, [selectedDate, refetchTimelineData, selectedUserId]);

  return (
    <>
      <DocumentTitle title='Edit Time' trailingTitle='Admin Settings' />
      <TimelineColorMap data={timeDetailsData?.results} />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppActionToolbar>
          <Box sx={{ position: 'relative', ml: -1, mr: -1.5, width: '100%' }}>
            <Stack direction='row' justifyContent='space-between'>
              <M3Autocomplete
                options={usersDropdownListData}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isSearchedUserLoading}
                onChange={handleUserSelect}
                noOptionsText='No users found'
                renderInput={(params) => (
                  <M3TextField
                    {...params}
                    sx={{ width: '240px' }}
                    name='userLabel'
                    label='Users'
                    value={''}
                    onChange={handleMemberNameInput}
                    fullWidth
                  />
                )}
              />

              <Stack direction='row' gap={2} alignItems={'center'}>
                <M3IconButton onClick={handleDecrementDate}>
                  <ArrowLeft sx={{ left: -1, position: 'relative' }} />
                </M3IconButton>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  maxDate={moment()}
                  renderInput={(props) => (
                    <M3TextField sx={{ width: '169px' }} {...props} />
                  )}
                />
                <M3IconButton
                  onClick={handleIncrementDate}
                  disabled={moment(selectedDate).isSame(moment(), 'day')}
                >
                  <ArrowRight />
                </M3IconButton>

                <M3Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    posthog.capture('edit time add time clicked');
                    handleModalOpen();
                  }}
                  sx={{ marginTop: '4px' }}
                  disabled={_isUndefined(selectedUserId)}
                >
                  <AddOutlinedIcon />
                  Add Time
                </M3Button>
              </Stack>
            </Stack>
          </Box>
        </AppActionToolbar>

        <Timezone />

        <TimeInfoCardContainer
          timeDetailsData={timeDetailsData?.results ?? []}
          isTimeDetailsDataLoading={isTimeDetailsDataLoading}
        />

        <Timeline data={timeDetailsData?.results} />

        <TimeDetailsTable
          isLoading={isTimeDetailsDataLoading}
          data={timeDetailsData?.results ?? []}
          toggleModalState={() => handleModalOpen(true)}
          updateFormData={setFormData}
          onDeleteSuccess={handleOnDeleteSuccess}
        />
      </MainContainer>

      <Modal
        open={isTimeDetailsModalOpen}
        onClose={(event, reason) => handleModalClose(reason)}
      >
        <TimeDetailsEditForm
          modalHeader={timeDetailsModalHeader}
          formData={formData}
          dateToDisplay={selectedDate}
          latestWorklogEntry={latestWorklogEntry}
          userId={selectedUserId}
          onModalClose={handleModalClose}
        />
      </Modal>
    </>
  );
};

export default SettingsAdminEditTime;
