import { useMemo, useState } from 'react';
import { Box, Divider } from '@mui/material';
import moment, { unitOfTime } from 'moment';

import { M3Button } from '../M3/M3Button';
import { M3MenuItem } from '../M3/M3MenuItem';
import BasicDateRangePicker from '../Pickers/BasicDateRangePicker';
import { getWeekRange } from '../../utils/date';

type DateRangeSelectionPopoverProps = {
  format?: string;
  startDate?: string | null;
  endDate?: string | null;
  disableFutureToDate?: boolean;
  onChange?: (props: OnChangeProps) => void;
  close?: () => void;
  dateSelectionTransform?: (sel: DateSelection) => DateSelection;
};
type OnChangeProps = {
  start: string;
  end: string;
};

type DateId = 'this_week' | 'last_7_days' | 'last_14_days' | 'last_30_days';
type DateSelection = {
  id: DateId;
  label: string;
  days: number;
  startOf?: unitOfTime.StartOf;
  startOfDay?: number;
};

const dateSelections: DateSelection[] = [
  {
    id: 'this_week',
    label: 'This week',
    days: 0,
    startOf: 'week',
    startOfDay: 1,
  },
  {
    id: 'last_7_days',
    label: 'Last 7 days',
    days: 7,
  },
  {
    id: 'last_14_days',
    label: 'Last 14 days',
    days: 14,
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    days: 30,
  },
];

const DateRangeSelectionPopover = ({
  startDate,
  endDate,
  format = 'YYYY-MM-DD',
  onChange,
  close,
  dateSelectionTransform,
  disableFutureToDate,
}: DateRangeSelectionPopoverProps) => {
  const [customRangeDates, setCustomRangeDates] = useState<
    (string | null | undefined)[]
  >([startDate || moment().format(format), endDate || moment().format(format)]);

  const _dateSelections = useMemo(() => {
    return dateSelectionTransform
      ? dateSelections.map(dateSelectionTransform)
      : dateSelections;
  }, [dateSelectionTransform]);

  return (
    <>
      <Box sx={{ pt: 1, pb: 1 }}>
        {_dateSelections.map((ds) => {
          let daysAdded = ds.days ? ds.days - 1 : ds.days;
          // Start/End filter dates
          let fsDate = moment().subtract(daysAdded, 'days').format(format);
          let feDate = moment().format(format);

          if (ds.id === 'this_week') {
            let { start, end } = getWeekRange(
              moment().endOf('week').add(1, 'day'),
            );
            fsDate = start.format(format);
            feDate = end.format(format);
          }

          return (
            <M3MenuItem
              key={ds.id}
              selected={startDate === fsDate && endDate === feDate}
              onClick={() => {
                onChange?.({
                  start: fsDate,
                  end: feDate,
                });
                close?.();
              }}
            >
              {ds.label}
            </M3MenuItem>
          );
        })}
      </Box>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Divider sx={{ mb: 2 }} />
        <BasicDateRangePicker
          width='100%'
          direction='column'
          startDate={customRangeDates[0]}
          endDate={customRangeDates[1]}
          disableFutureToDate={disableFutureToDate}
          onChange={(start, end) => {
            setCustomRangeDates([start, end]);
          }}
        />
        <Box display='flex' justifyContent='center' sx={{ pt: 1.5, pb: 1.5 }}>
          <M3Button
            fullWidth
            color='primary'
            variant='contained'
            disabled={
              !!customRangeDates[0] &&
              !!customRangeDates[1] &&
              moment(customRangeDates[0]).isSameOrBefore(customRangeDates[1])
                ? false
                : true
            }
            onClick={() => {
              onChange?.({
                start: customRangeDates[0]!,
                end: customRangeDates[1]!,
              });
              close?.();
            }}
          >
            Submit
          </M3Button>
        </Box>
      </Box>
    </>
  );
};

export default DateRangeSelectionPopover;
