import { Box } from '@mui/material';
import React, { FC } from 'react';

import { M3Card } from './M3/M3Card';
import PageWait from './PageWait';

import { useAppProvider } from '../providers/app/app';

export type PageLazyLoaderProps = {
  title?: string;
  message?: string;
  withNavbar?: boolean;
  withDrawer?: boolean;
};

export type PageLazyLoaderOptions = {
  CustomLoader?: FC;
};

const PageLazyLoader = ({
  title = 'Please wait...',
  message = 'The page is being loaded from the server',
  withNavbar = true,
  withDrawer = true,
}: PageLazyLoaderProps) => {
  const { toolbarHeight, drawerWidth } = useAppProvider();

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        p: 6,
        width: `calc(100vw - ${withDrawer ? drawerWidth : 0}px)`,
        height: `calc(100vh - ${withNavbar ? toolbarHeight : 0}px)`,
      }}
    >
      <M3Card
        noHover
        elevation={2}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PageWait
          spinner
          title={title}
          message={message}
          sx={{
            padding: '0 0 200px',
          }}
        />
      </M3Card>
    </Box>
  );
};

export default PageLazyLoader;
