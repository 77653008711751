import { Stack, Typography, Box } from '@mui/material';
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3Button } from '../M3/M3Button';
import { M3Dropdown } from '../M3/M3Select';
import { M3OptionItem } from '../Popover/BasicPopoverWithSearch';

import {
  useGo2MemberById,
  usePostGo2MembersToggleTimeOffRequestAutoApprove,
} from '../../hooks/member';

type AutoApproveModalProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    staffId: string;
  };

export function AutoApproveModalBase({
  staffId,
  close,
}: AutoApproveModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [hasChanged, setHasChanged] = useState(false);
  const [autoApproveOption, setAutoApproveOption] = useState<M3OptionItem>({
    id: 'no',
    label: 'No',
  });
  const yesNoOptions: M3OptionItem[] = useMemo(
    () => [
      {
        id: 'no',
        label: 'No',
      },
      {
        id: 'yes',
        label: 'Yes',
      },
    ],
    [],
  );

  const { data: go2MemberData, refetch: refetchGo2MemberById } =
    useGo2MemberById({ staffId });

  const postGo2MembersToggleTimeOffRequestAutoApprove =
    usePostGo2MembersToggleTimeOffRequestAutoApprove();

  const handleOnSubmit = () => {
    if (!hasChanged) return;

    postGo2MembersToggleTimeOffRequestAutoApprove.mutate({});
  };

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <PublishedWithChangesOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            Auto Approve Time-off Requests
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderBottomPanel = () => {
    let buttonDisabled =
      postGo2MembersToggleTimeOffRequestAutoApprove.isLoading || !hasChanged;

    return (
      <Stack
        direction='row'
        sx={{ p: 1, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 90,
          }}
          disabled={buttonDisabled}
          onClick={handleOnSubmit}
        >
          Save
        </M3Button>
      </Stack>
    );
  };

  /**
   * Setting the initial auto-approve state
   */
  useEffect(() => {
    if (!go2MemberData) return;

    if (go2MemberData.auto_approve_timeoff_request) {
      setAutoApproveOption(yesNoOptions[1]);
    } else {
      setAutoApproveOption(yesNoOptions[0]);
    }

    // eslint-disable-next-line
  }, [go2MemberData]);

  useEffect(() => {
    if (postGo2MembersToggleTimeOffRequestAutoApprove.isSuccess) {
      refetchGo2MemberById();
      enqueueSnackbar('Auto-approve has been successfully updated.');
      close?.();
    }
    // eslint-disable-next-line
  }, [postGo2MembersToggleTimeOffRequestAutoApprove.isSuccess]);

  useEffect(() => {
    refetchGo2MemberById();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={renderBottomPanel()}
      close={close}
      sx={{
        maxWidth: 680,
      }}
    >
      <Box sx={{ pt: 3, pb: 0 }}>
        <Typography
          display='flex'
          component='div'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box>
            Would you like to enable the auto-approval feature?
            <Typography
              mt={0.5}
              pr={2}
              component='div'
              fontSize={14}
              fontStyle='italic'
              sx={{
                opacity: 0.5,
              }}
            >
              This will allow any requests submitted by your direct reports to
              gain approval automatically.
            </Typography>
          </Box>
          <M3Dropdown
            withIcon
            selected={autoApproveOption}
            options={yesNoOptions}
            paperProps={{
              style: {
                width: 70,
              },
            }}
            onSelect={(opt) => {
              setHasChanged(true);
              setAutoApproveOption(opt);
            }}
          />
        </Typography>
      </Box>
    </ModalCardView>
  );
}

const AutoApproveModal = forwardRef((props: AutoApproveModalProps, ref) => (
  <AutoApproveModalBase {...props} />
));

export default AutoApproveModal;
