import { UseQueryOptions } from '@tanstack/react-query';

import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

import { DepartmentModel } from '../types/department';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';

type UseDepartmentsProps = ListQuery & {
  name?: string;
};

export function useDepartments<R = DepartmentModel>(
  params: UseDepartmentsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseDepartmentsProps>({
    params,
    baseIdentifier: 'useDepartments',
    identifierKeys: ['name'],
    queryParamKeys: ['name'],
  });

  return useQueryApi<UseDepartmentsProps, ListResult<R>>(
    identifiers,
    '/admin/departments/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
