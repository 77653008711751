import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Box, Stack, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import RichEditor from '../../RichEditor/RichEditor';
import { useForm } from '../../BasicForm';
import { M3Button, M3IconButton } from '../../M3/M3Button';
import JobDescriptionMembers from './JobDescriptionMembers';
import RichTextContent from '../../RichEditor/RichTextContent';
import JobDescriptionOSSelection from './JobDescriptionOSSelection';
import JobDescriptionTitleForm from './JobDescriptionTitleForm';
import JobDescriptionDetailSkeleton from './JobDescriptionDetailSkeleton';
import Error from '../../../pages/Error';
import JobDescriptionTemplate from '../../PDF/Template/JobDescription';

import { ReactRenderElement, ValueOf } from '../../../types/types';
import { useAppProvider } from '../../../providers/app/app';
import {
  useDeleteJobDescription,
  useJobDescriptionById,
  usePatchJobDescription,
} from '../../../hooks/jobs';
import { useUserProvider } from '../../../providers/user/user';
import { useHasViewPermission } from '../../HasViewPermission';
import { UseAIAssistSideSheetRet } from '../../SideSheet/AIAssistSideSheet';
import { stringToKey } from '../../../utils/string';
import { useReactPDF } from '../../PDF/ReactPDF';
import { JobDescriptionItemResult } from '../../../types/job-description';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';
import CommentsList from '../../CommentsList';
import { useCurrentProfile } from '../../../hooks/profile';
import { useMetadataProvider } from '../../../providers/metadata/metadata';
import { useIsCardFocused } from '../../../hooks/utils/card';

type JobDescriptionDetailProps = {
  jobDescriptionId?: string | number | null;
  editable?: boolean;
  userView?: boolean;
  withDownloadPDF?: boolean;
  withDelete?: boolean;
  aiAssist?: UseAIAssistSideSheetRet;
};

type JobDescriptionState = {
  title: string;
  division?: string | number;
  position_summary: string;
  principal_duties: string;
  other_responsibilities: string;
  success_indicator: string;
};

type IsFormOpen = {
  position_summary: boolean;
  principal_duties: boolean;
  other_responsibilities: boolean;
  success_indicator: boolean;
};

type IsCommentOpen = {
  position_summary: boolean;
  principal_duties: boolean;
  other_responsibilities: boolean;
  success_indicator: boolean;
};

const actionButtonStyle = {
  height: 25,
  lineHeight: 1,
  fontSize: 13,
  width: 'initial',
  minWidth: 'initial',
  padding: '0 14px',
};

const JobDescriptionDetail = ({
  jobDescriptionId,
  editable = false,
  userView,
  withDownloadPDF,
  withDelete,
  aiAssist,
}: JobDescriptionDetailProps) => {
  const { isDarkMode } = useAppProvider();
  const { enqueueSnackbar } = useSnackbar();
  const { setUniqueIdsToFetch } = useUserProvider();
  const { contentTypes } = useMetadataProvider();
  const { data: currentProfile } = useCurrentProfile();
  const isCardFocused = useIsCardFocused();

  const [isFormOpen, setIsFormOpen] = useState<IsFormOpen>({
    position_summary: false,
    principal_duties: false,
    other_responsibilities: false,
    success_indicator: false,
  });

  // NOTE: Just for lint for now
  // eslint-disable-next-line
  const [isCommentOpen, setIsCommentOpen] = useState<IsCommentOpen>({
    position_summary: false,
    principal_duties: false,
    other_responsibilities: false,
    success_indicator: false,
  });

  const jobDescription = useJobDescriptionById(
    { id: jobDescriptionId! },
    { enabled: !!jobDescriptionId },
  );
  const deleteJobDescription = useDeleteJobDescription({
    id: jobDescriptionId!,
  });
  const jdData = jobDescription.data;

  const {
    formState,
    handleChange,
    updateState: updateFormState,
  } = useForm<JobDescriptionState>({
    title: jdData?.title ?? '',
    position_summary: jdData?.position_summary ?? '',
    principal_duties: jdData?.principal_duties ?? '',
    other_responsibilities: jdData?.other_responsibilities ?? '',
    success_indicator: jdData?.success_indicator ?? '',
  });

  const reactPDF = useReactPDF();
  const confirmationDialog = useConfirmationDialog();
  const navigate = useNavigate();

  const onJobDescriptionUpdated = () => {
    jobDescription.refetch();
    enqueueSnackbar('Job description has been updated!');
  };

  const openForm = (...args: (keyof IsFormOpen)[]) => {
    setIsFormOpen((state) => {
      state = { ...state };
      args.forEach((key) => (state[key] = true));
      return state;
    });
  };

  const closeForm = (...args: (keyof IsFormOpen)[]) => {
    setIsFormOpen((state) => {
      state = { ...state };
      args.forEach((key) => (state[key] = false));
      return state;
    });
  };

  const renderRichEditor = ({
    name,
    ...props
  }: Omit<JobRichTextEditorProps, 'id'>) => {
    return (
      <JobRichTextEditor
        {...props}
        name={name}
        id={jdData!.id}
        aiAssist={aiAssist}
        onSave={(value: string) => {
          // handleChange({ target: { name, value } });
          onJobDescriptionUpdated();
          closeForm(name as keyof IsFormOpen);
        }}
        onCancel={() => {
          closeForm(name as keyof IsFormOpen);
        }}
      />
    );
  };

  const renderRichDisplay = ({
    icon,
    name,
    content,
    title,
    withBackground,
  }: {
    icon: ReactRenderElement;
    name: keyof IsFormOpen;
    content: string;
    title?: ReactRenderElement;
    withBackground?: boolean;
  }) => {
    return (
      <Box mb={5}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography
            display='flex'
            alignItems='center'
            component='div'
            fontSize={22}
            minHeight={40}
          >
            <Box position='relative' top={4} pr={1}>
              {icon}
            </Box>
            {title}
          </Typography>
          <Stack
            gap={1}
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
          >
            {/* <Typography
              component='div'
              fontSize={14}
              sx={{
                opacity: 0.5,
              }}
            >
              0 Comment
            </Typography>
            <M3IconButton
              sx={{
                minWidth: 40,
                minHeight: 40,
              }}
            >
              <CommentOutlinedIcon sx={{ fontSize: 20 }} />
            </M3IconButton> */}
            {editable && (
              <M3IconButton
                sx={{
                  minWidth: 40,
                  minHeight: 40,
                }}
                onClick={() => openForm(name)}
              >
                <EditOutlinedIcon sx={{ fontSize: 20 }} />
              </M3IconButton>
            )}
          </Stack>
        </Stack>
        <Typography component='div'>
          {withBackground ? (
            <Typography
              component='div'
              sx={{
                p: 3,
                mt: 1,
                borderRadius: 1,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              <RichTextContent content={content} />
            </Typography>
          ) : (
            <RichTextContent content={content} />
          )}
        </Typography>
      </Box>
    );
  };

  const renderJobDescriptionDetailForm = () => {
    return (
      <>
        <Stack gap={2} direction='row' justifyContent='space-between' mb={1}>
          <Box flex={1}>
            <JobDescriptionTitleForm
              withSave={editable}
              editable={editable}
              title={jdData?.title}
              jobDescriptionId={jdData?.id}
              onSaved={onJobDescriptionUpdated}
            />
            <JobDescriptionOSSelection
              editable={false}
              divisionId={jdData?.division}
              onSelect={(opt) => {
                handleChange({
                  target: {
                    name: 'division',
                    value: opt.id,
                  },
                });
              }}
            />
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <JobDescriptionMembers
              editable={editable}
              divisionId={jdData?.division}
              jobDescriptionId={jdData?.id}
            />
          </Box>
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Typography component='div' fontSize={13} sx={{ opacity: 0.5 }}>
            Last Edited: {moment.utc(jdData?.modified).format('MMM D, YYYY')}
          </Typography>

          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
            sx={{
              mt: -2,
              top: 4,
              position: 'relative',
            }}
          >
            {withDownloadPDF && (
              <M3Button
                onClick={() =>
                  reactPDF.generatePDF({
                    name: jdData?.title!,
                    data: {
                      ...jdData,
                      // division: divisionData?.name,
                    } as JobDescriptionItemResult,
                    Component: JobDescriptionTemplate,
                  })
                }
              >
                <FileDownloadOutlinedIcon />
                &nbsp;Download PDF
              </M3Button>
            )}
          </Box>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    if (jdData) {
      updateFormState((state) => ({
        ...state,
        title: jdData.title,
        position_summary: jdData.position_summary as string,
        principal_duties: jdData.principal_duties as string,
        other_responsibilities: jdData.other_responsibilities as string,
        success_indicator: jdData.success_indicator as string,
      }));
    }
    // eslint-disable-next-line
  }, [jdData]);

  useEffect(() => {
    if (jdData?.go2_members.length) {
      setUniqueIdsToFetch(
        {
          staff_ids: jdData.go2_members.map((member) => member.id),
        },
        true,
      );
    }
    // eslint-disable-next-line
  }, [jdData]);

  useEffect(() => {
    confirmationDialog.confirm.setIsOpen(false);

    if (deleteJobDescription.isSuccess) {
      navigate('/settings/team/jobs', {
        state: { deletedJobId: jobDescriptionId },
      });
    } else if (deleteJobDescription.isError) {
      enqueueSnackbar(
        'Sorry, we could not delete the Job Description.\nPlease try again later or contact support if the problem persists.',
      );
    }
    // eslint-disable-next-line
  }, [deleteJobDescription.isSuccess]);

  /**
   * Check if there's not yet any job description
   */
  if (!jobDescription.data || !jobDescriptionId) {
    if (jobDescription.isLoading) {
      return <JobDescriptionDetailSkeleton />;
    } else {
      return (
        <Error
          title='Job Description Unavailable'
          message={
            userView
              ? 'This user has not yet been assigned to a job description within the system.'
              : 'We have encountered a problem while attempting to fetch the job information from the system.'
          }
          sx={{
            paddingTop: '4vh',
          }}
        />
      );
    }
  }

  return (
    <>
      <Box>
        {renderJobDescriptionDetailForm()}
        <br />
        <br />
        {isFormOpen.position_summary
          ? renderRichEditor({
              icon: <BookmarkBorderOutlinedIcon />,
              name: 'position_summary',
              value: formState.position_summary,
              title: 'Position Summary:',
              subtitle:
                'This is a brief, 2-to-3 sentence section that summarizes position.',
            })
          : renderRichDisplay({
              icon: <BookmarkBorderOutlinedIcon />,
              name: 'position_summary',
              title: 'Position Summary:',
              withBackground: true,
              content: formState.position_summary,
            })}
        {isFormOpen.principal_duties
          ? renderRichEditor({
              icon: <PlaylistAddCheckOutlinedIcon />,
              name: 'principal_duties',
              value: formState.principal_duties,
              title: 'Principal Duties and Responsibilities:',
              subtitle:
                'Key tasks and obligations that define the success of this role.',
            })
          : renderRichDisplay({
              icon: <PlaylistAddCheckOutlinedIcon />,
              name: 'principal_duties',
              title: 'Principal Duties and Responsibilities:',
              withBackground: true,
              content: formState.principal_duties,
            })}
        {isFormOpen.other_responsibilities
          ? renderRichEditor({
              icon: <ExtensionOutlinedIcon />,
              name: 'other_responsibilities',
              value: formState.other_responsibilities,
              title: 'Other Duties and Responsibilities:',
              subtitle:
                'This section contains responsibilities and important duties performed occasionally or in addition to the essential functions of the position. The above statement is on all job descriptions in this section.',
            })
          : renderRichDisplay({
              icon: <ExtensionOutlinedIcon />,
              name: 'other_responsibilities',
              title: 'Other Duties and Responsibilities:',
              withBackground: true,
              content: formState.other_responsibilities,
            })}
        <Box
          ml={-4}
          mr={-4}
          mt={8}
          p={4}
          pl={4}
          pr={4}
          pb={1.5}
          sx={{
            backgroundColor: isDarkMode
              ? 'var(--md-ref-palette-primary30)'
              : 'var(--md-ref-palette-primary90)',
          }}
        >
          {isFormOpen.success_indicator
            ? renderRichEditor({
                icon: <AutoAwesomeOutlinedIcon />,
                name: 'success_indicator',
                value: formState.success_indicator,
                title: 'What Does Success Look Like:',
                subtitle:
                  'Write a few sentences describing the performance, attributes, and attitude of an imaginary person who is a successful person in this role.',
              })
            : renderRichDisplay({
                icon: <AutoAwesomeOutlinedIcon />,
                name: 'success_indicator',
                title: 'What Does Success Look Like:',
                content: formState.success_indicator,
              })}
        </Box>

        <Box mt={5}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography
              display='flex'
              alignItems='center'
              component='div'
              fontSize={22}
              minHeight={40}
            >
              <Box position='relative' top={4} pr={1}>
                <CommentOutlinedIcon />
              </Box>
              Comments
            </Typography>

            {withDelete && (
              <Stack
                gap={1}
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
              >
                <M3Button
                  onClick={() => confirmationDialog.confirm.setIsOpen(true)}
                  style={{
                    backgroundColor: isDarkMode
                      ? 'var(--md-ref-palette-error50)'
                      : 'var(--md-ref-palette-error80)',
                  }}
                >
                  <DeleteOutlinedIcon />
                  Delete Job Description
                </M3Button>
              </Stack>
            )}
          </Stack>
        </Box>
        <ConfirmationDialog
          {...confirmationDialog.confirm}
          title='Delete Job Description'
          message={
            <>
              Are you sure you want to delete this Job Description?
              <br />
              This action cannot be undone and any associated focus will be
              removed.
            </>
          }
          isLoading={deleteJobDescription.isLoading}
          onConfirm={() => deleteJobDescription.mutate({})}
        />
        <Box mx={-3}>
          <CommentsList
            currentProfile={currentProfile}
            contentType={contentTypes.project}
            objectId={jobDescriptionId}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
          />
        </Box>
      </Box>
    </>
  );
};

export default JobDescriptionDetail;

type JobRichTextEditorProps = {
  id: string | number;
  icon?: ReactRenderElement;
  name: keyof JobDescriptionState | keyof IsFormOpen;
  value: ValueOf<JobDescriptionState> | string;
  title?: ReactRenderElement;
  subtitle?: ReactRenderElement;
  aiAssist?: UseAIAssistSideSheetRet;
  onCancel?: () => void;
  onSave?: (value: string) => void;
};

function JobRichTextEditor({
  id,
  icon,
  name,
  value: initialValue,
  title,
  subtitle,
  onCancel,
  onSave,
  aiAssist,
}: JobRichTextEditorProps) {
  const [value, setValue] = useState(initialValue);
  const changed = value !== initialValue;

  const patchJobDescription = usePatchJobDescription({ id });
  const isSubmitDisabled = patchJobDescription.isLoading || !changed;

  const isPromptEngineer = useHasViewPermission({
    roles: ['superuser', 'prompt_engineer'],
  });
  let promptIdentifier = stringToKey(`job-description-${name}`, '-');

  const onSubmit = () => {
    patchJobDescription.mutate({
      [name]: value,
    });
  };

  useEffect(() => {
    if (patchJobDescription.isSuccess) {
      onSave?.(value as string);
    }
    // eslint-disable-next-line
  }, [value, patchJobDescription.isSuccess]);

  return (
    <Box mb={5}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          display='flex'
          alignItems='center'
          component='div'
          fontSize={22}
          minHeight={40}
        >
          <Box position='relative' top={4} pr={1}>
            {icon}
          </Box>
          {title}
        </Typography>
        <Stack
          gap={1}
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
        >
          <M3Button style={actionButtonStyle} onClick={onCancel}>
            Cancel
          </M3Button>
          <M3Button
            color='primary'
            variant='contained'
            style={{
              ...actionButtonStyle,
              marginLeft: 8,
            }}
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          >
            Save
          </M3Button>
        </Stack>
      </Stack>
      {!!subtitle && (
        <Typography
          pt={0.5}
          component='div'
          fontSize={14}
          sx={{ opacity: 0.5 }}
        >
          {subtitle}
        </Typography>
      )}
      <RichEditor
        withAIAssist
        withAIEditPrompt={isPromptEngineer}
        value={value as string}
        withMentions={false}
        onValueChange={setValue}
        placeholder='Write here...'
        sx={{ marginTop: 1 }}
        chatGPTPlaygroundProps={{
          title: title as string,
          prompt_identifier: promptIdentifier,
        }}
        onAssistClick={() => {
          aiAssist?.open();
          aiAssist?.start({
            title: title as string,
            prompt_identifier: promptIdentifier,
            data: {
              prompt_text: value as string,
            },
          });
        }}
      />
    </Box>
  );
}
