import React, { forwardRef, PropsWithChildren, useMemo } from 'react';
import { Box, Stack, Typography, Divider, Chip, SxProps } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3Button } from '../M3/M3Button';
import BasicForm, { useForm } from '../BasicForm';
import ContentArticleMenuOptions from './ContentArticleMenuOptions';

import { useIntercomArticleById } from '../../hooks/intercom';
import { useAppProvider } from '../../providers/app/app';
import { audienceSegments, audienceUserTypes } from '../../types/audience';
import { IterableObject } from '../../types/types';

type ContentTargetAudienceFormProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    articleId: string;
    onSuccess?: () => void;
  };

type TargetAudienceFormState = {
  userTypesSelected: IterableObject<boolean>;
  segmentsSelected: IterableObject<boolean>;
};

const defaultChipSx: SxProps = {
  opacity: 0.7,
  borderRadius: 1.2,
  cursor: 'pointer',
  '.MuiChip-label': {
    fontWeight: 500,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
};

const ContentTargetAudienceForm = forwardRef(
  ({ close, onSuccess, articleId }: ContentTargetAudienceFormProps, ref) => {
    const { isDarkMode } = useAppProvider();
    const { submit, formRef, formState, handleSubmit, updateState } =
      useForm<TargetAudienceFormState>({
        userTypesSelected: {
          user: true,
          leads: true,
          visitors: true,
        },
        segmentsSelected: {
          '62b1c36197f288d42f5873d1': true,
        },
      });

    const article = useIntercomArticleById({ id: articleId });
    const articleData = article.data;

    const selected = useMemo(() => {
      return {
        userTypes: Object.values(formState.userTypesSelected).filter((v) => v)
          .length,
        segments: Object.values(formState.segmentsSelected).filter((v) => v)
          .length,
      };
    }, [formState]);

    // TODO: Should send mutation here
    const onSubmit = handleSubmit((data) => {
      close?.();
    });

    const renderTopPanel = () => {
      return (
        <Stack
          sx={{ flex: 1 }}
          flexDirection='row'
          alignItems='flex-start'
          justifyContent='flex-start'
        >
          <GroupsOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
          <Typography component='h5' fontSize={20} fontWeight={500}>
            Assign Target Audience
          </Typography>
        </Stack>
      );
    };

    const renderBottomPanel = () => {
      return (
        <Stack
          direction='row'
          sx={{ p: 2, flex: 1 }}
          alignItems='center'
          justifyContent='center'
        >
          <M3Button
            color='primary'
            variant='contained'
            onClick={submit}
            sx={{
              width: 210,
            }}
          >
            <>
              <EditOutlinedIcon />
              Save Target Audience
            </>
          </M3Button>
        </Stack>
      );
    };

    const renderChip = (
      item: string,
      index: number,
      selected: boolean,
      onClick: () => void,
    ) => {
      return (
        <Chip
          key={index}
          label={
            <>
              {selected && (
                <DoneOutlinedIcon
                  sx={{
                    fontSize: 18,
                    mr: 1,
                    ml: -0.5,
                  }}
                />
              )}
              {item}
            </>
          }
          sx={{
            ...defaultChipSx,
          }}
          style={{
            ...(selected
              ? {
                  opacity: 1,
                  background: isDarkMode
                    ? 'var(--md-ref-palette-neutral40)'
                    : 'var(--md-ref-palette-neutral90)',
                }
              : null),
          }}
          className='noselect'
          onClick={onClick}
        />
      );
    };

    const renderUserTypes = () => {
      return (
        <Stack gap={1} direction='row' flexWrap='wrap' pt={1}>
          {audienceUserTypes.map((item, index) => {
            let selected = formState.userTypesSelected[item.id];
            return renderChip(item.name, index, selected, () => {
              updateState((state) => {
                return {
                  ...state,
                  userTypesSelected: {
                    ...state.userTypesSelected,
                    [item.id]: !selected,
                  },
                };
              });
            });
          })}
        </Stack>
      );
    };

    const renderSegments = () => {
      return (
        <Stack gap={1} direction='row' flexWrap='wrap' pt={1}>
          {audienceSegments.map((item, index) => {
            let selected = formState.segmentsSelected[item.id];
            return renderChip(item.name, index, selected, () => {
              updateState((state) => {
                return {
                  ...state,
                  segmentsSelected: {
                    ...state.segmentsSelected,
                    [item.id]: !selected,
                  },
                };
              });
            });
          })}
        </Stack>
      );
    };

    return (
      <ModalCardView
        header={renderTopPanel()}
        headerSx={{
          pt: 2,
          pb: 2,
        }}
        footer={renderBottomPanel()}
        close={close}
      >
        <Box sx={{ mt: 2, minHeight: 400 }}>
          <BasicForm setRef={formRef} onSubmit={onSubmit}>
            <Stack gap={2} direction='row'>
              <Box flex={1}>
                <Typography
                  component='h5'
                  variant='h5'
                  style={{
                    color: isDarkMode
                      ? 'var(--md-ref-palette-primary80)'
                      : 'var(--md-ref-palette-primary40)',
                  }}
                >
                  {articleData?.title}
                </Typography>
                {!!articleData?.description && (
                  <Typography
                    fontSize={16}
                    sx={{
                      opacity: 0.8,
                      color: 'var(--md-sys-color-on-surface-light)',
                    }}
                    style={{
                      padding: '5px 0 0',
                    }}
                  >
                    {articleData.description}
                  </Typography>
                )}
              </Box>
              {articleData && (
                <ContentArticleMenuOptions
                  popover={false}
                  article={articleData}
                  sx={{
                    mr: -2,
                  }}
                />
              )}
            </Stack>
            <Divider sx={{ ml: -4, mr: -4, mt: 1.4 }} />
            <br />
            <Typography
              fontSize={13}
              component='div'
              fontWeight={700}
              textTransform='uppercase'
            >
              User Types ({selected.userTypes})
            </Typography>
            {renderUserTypes()}
            <br />
            <br />
            <Typography
              fontSize={13}
              component='div'
              fontWeight={700}
              textTransform='uppercase'
            >
              Segments ({selected.segments})
            </Typography>
            {renderSegments()}
            <br />
            <br />
            <br />
            <br />
          </BasicForm>
        </Box>
      </ModalCardView>
    );
  },
);

export default ContentTargetAudienceForm;
