import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { InboxAllPaths } from '../../components/Inbox/InboxAllList';
import { useNotificationsCount } from '../../hooks/notification';
import {
  InboxNotificationItem,
  InboxPageParamsRet,
} from '../../pages/InboxAll';
import { NotificationTopic } from '../../types/notification';
import { IterableObject, ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../app/app';
import { useCurrentProfile } from '../../hooks/profile';
import * as pusher from '../../services/pusher';

type NotificationProviderProps = {
  children?: ReactRenderElement;
};
type InboxItemStateParams = null | {
  itemIndex: number;
  tabIndex: number;
  paths: InboxAllPaths;
  item: InboxNotificationItem;
  listQuery: InboxPageParamsRet['listQuery'];
};
/**
 * State that we can mutate
 */
type NotificationInitialState = {
  unreadDailyReportCount: number;
  unreadPTOCount: number;
  unreadHelpNeededCount: number;
  unreadJobDescriptionCount: number;
  unreadGo2BotsCount: number;
  unreadScheduleRequestCount: number;
  inboxItemState: InboxItemStateParams;
  topics: {
    comments: NotificationTopic;
    time_off: NotificationTopic;
    help_needed: NotificationTopic;
    report: NotificationTopic;
    go2bots: NotificationTopic;
    schedule_change_request: NotificationTopic;
  };
  verbs: {
    daily_reports: string;
    time_off: string;
    help_needed: string;
    report: string;
    job_description: string;
    go2bots: string;
    schedule_change_request: string;
  };
  filters: {
    all: InboxFilterTypeId;
    help_needed: InboxFilterTypeId;
  };
  pusherCounter: number;
};
export type InboxFilterTypeId =
  | 'all'
  | 'report'
  | 'job_description'
  | 'time_off'
  | 'schedule_change_request'
  | 'help_needed'
  | 'go2bots';
export type InboxFilterTypeName = 'all' | 'help_needed' | 'go2bots';

/**
 * Reducers that mutate the state
 */
type NotificationReducers = {
  updateUnreadCount: (
    key:
      | 'unreadDailyReportCount'
      | 'unreadPTOCount'
      | 'unreadHelpNeededCount'
      | 'unreadJobDescriptionCount'
      | 'unreadGo2BotsCount'
      | 'unreadScheduleRequestCount',
    count?: number | null,
  ) => void;
  setInboxItemState: (params: InboxItemStateParams) => void;
  setFilterById: (id: InboxFilterTypeId, name: InboxFilterTypeName) => void;
  getAllCount: () => number;
};
/**
 * Single store
 */
type NotificationStore = NotificationInitialState & NotificationReducers;
/**
 * Initial state / store
 */
const initialStore: NotificationStore = {
  unreadDailyReportCount: 0,
  unreadPTOCount: 0,
  unreadHelpNeededCount: 0,
  unreadJobDescriptionCount: 0,
  unreadGo2BotsCount: 0,
  unreadScheduleRequestCount: 0,
  inboxItemState: null,
  topics: {
    comments: 'comments',
    time_off: 'timeoff',
    help_needed: 'help_needed',
    report: 'report',
    go2bots: 'go2botsmessage',
    schedule_change_request: 'schedule_change_request',
  },
  verbs: {
    daily_reports: 'commented_report',
    time_off:
      'timeoff_requested,timeoff_approved,timeoff_canceled,timeoff_denied,timeoff_superceded,commented_bhrtimeoffrequests',
    help_needed: '',
    report: 'mentioned_report',
    job_description: 'commented_jobdescription,commented_project',
    go2bots: 'commented_go2botmessage',
    schedule_change_request:
      'schedule_change_requested,schedule_change_approved,schedule_change_canceled,schedule_change_rejected',
  },
  filters: {
    all: 'all',
    help_needed: 'all',
  },
  pusherCounter: 0,
  updateUnreadCount: (
    key:
      | 'unreadDailyReportCount'
      | 'unreadPTOCount'
      | 'unreadHelpNeededCount'
      | 'unreadJobDescriptionCount'
      | 'unreadGo2BotsCount'
      | 'unreadScheduleRequestCount',
    count?: number | null,
  ) => {
    throw new Error('Implementation required');
  },
  setInboxItemState: (params: InboxItemStateParams) => {
    throw new Error('Implementation required');
  },
  setFilterById: (id: InboxFilterTypeId, name: InboxFilterTypeName) => {
    throw new Error('Implementation required');
  },
  getAllCount: () => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const NotificationContext = createContext<NotificationStore>(initialStore);

export function useNotificationProvider(): NotificationStore {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const { getActionKeyCounter } = useAppProvider();
  const [state, setState] = useState<NotificationStore>(initialStore);
  const { data: currentProfile } = useCurrentProfile();

  const rqParams = { refetchInterval: 300 * 1000 };
  const { data: dailyReportUnreadCount, refetch: refetchDailyReport } =
    useNotificationsCount(
      {
        key: 'daily_report_count',
        status: 'unread',
        verb: [state.verbs.daily_reports, state.verbs.report].join(','),
      },
      rqParams,
    );
  const { data: ptoUnreadCount, refetch: refetchPTO } = useNotificationsCount(
    {
      key: 'paid_time_off_count',
      status: 'unread',
      verb: state.verbs.time_off,
    },
    rqParams,
  );
  const { data: jobDescriptionUnreadCount, refetch: refetchJobDescription } =
    useNotificationsCount(
      {
        key: 'job_description',
        status: 'unread',
        topic: state.topics.comments,
        verb: state.verbs.job_description,
      },
      rqParams,
    );
  const { data: helpNeededUnreadCount, refetch: refetchHelpNeeded } =
    useNotificationsCount(
      {
        key: 'help_needed_count',
        status: 'unread',
        topic: state.topics.help_needed,
      },
      rqParams,
    );
  const { data: go2BotsUnreadCount, refetch: refetchGo2Bots } =
    useNotificationsCount(
      {
        key: 'go2bots_count',
        status: 'unread',
        topic: state.topics.go2bots,
      },
      rqParams,
    );
  const { data: scheduleRequestUnreadCount, refetch: refetchScheduleRequest } =
    useNotificationsCount(
      {
        key: 'schedule_change_request_count',
        status: 'unread',
        topic: state.topics.schedule_change_request,
      },
      rqParams,
    );

  const setFilterById = useCallback(
    (id: InboxFilterTypeId, name: InboxFilterTypeName) => {
      setState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          [name]: id,
        },
      }));
    },
    [setState],
  );

  const getAllCount = () => {
    const {
      unreadDailyReportCount,
      unreadPTOCount,
      unreadJobDescriptionCount,
      unreadHelpNeededCount,
      unreadGo2BotsCount,
      unreadScheduleRequestCount,
    } = state;
    let allCount = 0;
    if (state.filters.all === 'all') {
      allCount =
        unreadDailyReportCount +
        unreadJobDescriptionCount +
        unreadPTOCount +
        unreadHelpNeededCount +
        unreadGo2BotsCount +
        unreadScheduleRequestCount;
    } else if (state.filters.all === 'report') {
      allCount = unreadDailyReportCount;
    } else if (state.filters.all === 'job_description') {
      allCount = unreadJobDescriptionCount;
    } else if (state.filters.all === 'time_off') {
      allCount = unreadPTOCount;
    } else if (state.filters.all === 'help_needed') {
      allCount = unreadHelpNeededCount;
    } else if (state.filters.all === 'go2bots') {
      allCount = unreadGo2BotsCount;
    } else if (state.filters.all === 'schedule_change_request') {
      allCount = unreadScheduleRequestCount;
    }
    return allCount;
  };

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function updateUnreadCount(
    key:
      | 'unreadDailyReportCount'
      | 'unreadPTOCount'
      | 'unreadHelpNeededCount'
      | 'unreadJobDescriptionCount'
      | 'unreadGo2BotsCount'
      | 'unreadScheduleRequestCount',
    count?: number | null,
  ) {
    setState((state) => ({ ...state, [key]: count || 0 }));
  }

  function setInboxItemState(params: InboxItemStateParams) {
    setState((state) => ({ ...state, inboxItemState: params }));
  }

  /**
   * Define all side effects here...
   */
  useEffect(() => {
    updateUnreadCount('unreadDailyReportCount', dailyReportUnreadCount?.count);
  }, [dailyReportUnreadCount?.count]);

  useEffect(() => {
    updateUnreadCount('unreadPTOCount', ptoUnreadCount?.count);
  }, [ptoUnreadCount?.count]);

  useEffect(() => {
    updateUnreadCount('unreadHelpNeededCount', helpNeededUnreadCount?.count);
  }, [helpNeededUnreadCount?.count]);

  useEffect(() => {
    updateUnreadCount(
      'unreadJobDescriptionCount',
      jobDescriptionUnreadCount?.count,
    );
  }, [jobDescriptionUnreadCount?.count]);

  useEffect(() => {
    updateUnreadCount('unreadGo2BotsCount', go2BotsUnreadCount?.count);
  }, [go2BotsUnreadCount?.count]);

  useEffect(() => {
    updateUnreadCount(
      'unreadScheduleRequestCount',
      scheduleRequestUnreadCount?.count,
    );
  }, [scheduleRequestUnreadCount?.count]);

  useEffect(() => {
    refetchPTO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActionKeyCounter('pto_request_submission')]);

  useEffect(() => {
    refetchPTO();
    refetchDailyReport();
    refetchHelpNeeded();
    refetchJobDescription();
    refetchGo2Bots();
    refetchScheduleRequest();
    setState((state) => ({
      ...state,
      pusherCounter: state.pusherCounter + 1,
    }));
    // eslint-disable-next-line
  }, [
    setState,
    getActionKeyCounter('fetch_all_notification_count'), // eslint-disable-line
    getActionKeyCounter('schedule_change_request_submitted'), // eslint-disable-line
    getActionKeyCounter('schedule_change_request_action_taken'), // eslint-disable-line
  ]);

  useEffect(() => {
    const notificationTopics: NotificationTopic[] = [
      'comments',
      'go2botsmessage',
      'help_needed',
      'report',
      'schedule_change_request',
      'timeoff',
    ];
    const eventCallback = (data: any) => {
      refetchPTO();
      refetchDailyReport();
      refetchHelpNeeded();
      refetchJobDescription();
      refetchGo2Bots();
      refetchScheduleRequest();
      setState((state) => ({
        ...state,
        pusherCounter: state.pusherCounter + 1,
      }));
    };
    const events = notificationTopics.reduce((prev, curr) => {
      prev[curr] = eventCallback;
      return prev;
    }, {} as IterableObject);

    let unsubscribe = pusher.subscribe(
      `private-notifications_${currentProfile!.id}`,
      events,
    );

    return unsubscribe;
  }, [
    currentProfile,
    setState,
    refetchPTO,
    refetchDailyReport,
    refetchHelpNeeded,
    refetchJobDescription,
    refetchGo2Bots,
    refetchScheduleRequest,
  ]);

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        updateUnreadCount,
        setInboxItemState,
        setFilterById,
        getAllCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
