import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { M3MenuItem } from '../M3/M3MenuItem';

type MonthSelectionPopoverProps = {
  month: number;
  onChange?: (index: number) => void;
  close?: () => void;
  monthSelectionTransform?: (sel: DateSelection) => DateSelection;
};

type DateSelection = {
  id: number;
  label: string;
};

const monthSelections: DateSelection[] = [
  {
    id: 0,
    label: 'January',
  },
  {
    id: 1,
    label: 'February',
  },
  {
    id: 2,
    label: 'March',
  },
  {
    id: 3,
    label: 'April',
  },
  {
    id: 4,
    label: 'May',
  },
  {
    id: 5,
    label: 'June',
  },
  {
    id: 6,
    label: 'July',
  },
  {
    id: 7,
    label: 'August',
  },
  {
    id: 8,
    label: 'September',
  },
  {
    id: 9,
    label: 'October',
  },
  {
    id: 10,
    label: 'November',
  },
  {
    id: 11,
    label: 'December',
  },
];

export function getMonthLabel(index: number) {
  return monthSelections[index].label;
}

const MonthSelectionPopover = ({
  month,
  onChange,
  close,
  monthSelectionTransform,
}: MonthSelectionPopoverProps) => {
  const _monthSelections = useMemo(() => {
    return monthSelectionTransform
      ? monthSelections.map(monthSelectionTransform)
      : monthSelections;
  }, [monthSelectionTransform]);

  return (
    <>
      <Box sx={{ pt: 1, pb: 1 }}>
        {_monthSelections.map((ds) => {
          return (
            <M3MenuItem
              key={ds.id}
              selected={month === ds.id}
              onClick={() => {
                onChange?.(ds.id);
                close?.();
              }}
            >
              {ds.label}
            </M3MenuItem>
          );
        })}
      </Box>
    </>
  );
};

export default MonthSelectionPopover;
