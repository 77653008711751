import { Stack, Typography, Box } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { v1 as uuid } from 'uuid';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { M3Button, M3IconButton } from '../M3/M3Button';
import { M3TextField } from '../M3/M3TextField';

import { useForm } from '../BasicForm';
import { IterableObject } from '../../types/types';
import { spliceFromArray } from '../../utils/array';
import { isNotNullAndUndefined } from '../../utils/object';

type EODShipLinkFormProps = {
  links?: ShipmentLinkItem[];
  onDataChange?: (items: ShipmentLinkItem[]) => void;
};
type ShipFormState = {
  ids: string[];
  data: IterableObject<ShipmentLinkItem>;
};

export type ShipmentLinkItem = {
  id: string;
  url: string;
  title: string;
};

type HandleChange = (evt: SyntheticEvent, item: ShipmentLinkItem) => void;

type RemoveHandle = (item: ShipmentLinkItem) => void;

function renderShipLinkRow(
  item: ShipmentLinkItem,
  handleChange: HandleChange,
  onRemove: RemoveHandle,
) {
  function onChange(evt: SyntheticEvent) {
    handleChange(evt, item);
  }

  return (
    <Stack key={item.id} gap={3} direction='row' alignItems='center' pb={1}>
      <Box width={260} minWidth={260}>
        <M3TextField
          name='title'
          stripAllAutoProps
          fullWidth
          label='Title'
          value={item.title ?? ''}
          onChange={onChange}
        />
      </Box>
      <M3TextField
        name='url'
        stripAllAutoProps
        fullWidth
        label='URL'
        value={item.url ?? ''}
        onChange={onChange}
      />
      <Box sx={{ ml: -2 }}>
        <M3IconButton onClick={() => onRemove(item)}>
          <CloseOutlinedIcon />
        </M3IconButton>
      </Box>
    </Stack>
  );
}

function getInitialLinkState(): ShipmentLinkItem {
  return {
    id: uuid(),
    title: '',
    url: '',
  };
}

const EODShipLinkForm = ({
  links: shipmentLinks = [],
  onDataChange,
}: EODShipLinkFormProps) => {
  const { formState, updateState } = useForm<ShipFormState>(
    () => {
      const initialData: ShipmentLinkItem = getInitialLinkState();

      if (shipmentLinks?.length) {
        return {
          ids: shipmentLinks.map(({ id }) => id),
          data: shipmentLinks.reduce((data, link) => {
            data[link.id] = link;
            return data;
          }, {} as IterableObject<ShipmentLinkItem>),
        };
      }

      return {
        ids: [initialData.id],
        data: {
          [initialData.id]: initialData,
        },
      };
    },
    {
      onStateUpdated(state) {
        const links = state.ids
          .map((id) => state.data[id])
          .filter(
            (item) =>
              (!!item.title && isNotNullAndUndefined(item.title)) ||
              (!!item.url && isNotNullAndUndefined(item.url)),
          );
        onDataChange?.(links);
      },
    },
  );

  function handleChange(evt: SyntheticEvent, item: ShipmentLinkItem) {
    const input = evt.target as HTMLInputElement;
    const name = input.name;
    const value = input.value;
    updateState((state) => {
      const currItem = (state.data ?? formState.data)[item.id];
      return {
        ...state,
        data: {
          ...state.data,
          [item.id]: {
            ...currItem,
            [name]: value,
          },
        },
      };
    });
  }

  function addLink() {
    updateState((state) => {
      const initialData: ShipmentLinkItem = getInitialLinkState();
      return {
        ...state,
        ids: [...(state.ids ?? []), initialData.id],
        data: {
          ...state.data,
          [initialData.id]: initialData,
        },
      };
    });
  }

  function removeLink(item: ShipmentLinkItem) {
    updateState((state) => {
      const ids = [...(state.ids ?? [])];
      const data = { ...state.data };
      spliceFromArray(ids, item.id);
      delete data[item.id];
      return {
        ...state,
        ids,
        data,
      };
    });
  }

  return (
    <Box pt={2}>
      <Typography fontWeight={500} sx={{ mb: 2 }}>
        Links of the shipment
      </Typography>
      {formState.ids.map((id: string) => {
        return renderShipLinkRow(formState.data[id], handleChange, removeLink);
      })}
      <M3Button
        style={{
          padding: 0,
          paddingLeft: 16,
          paddingRight: 16,
          height: 'initial',
          minHeight: 'initial',
        }}
        onClick={addLink}
      >
        + Add a Link
      </M3Button>
    </Box>
  );
};

export default EODShipLinkForm;
