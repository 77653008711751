import {
  QueryFunctionContext,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import {
  BatchChangeScheduleItem,
  ChangeRequestMetadataObject,
  ChangeRequestScheduleItem,
  DailyScheduleItem,
  ScheduleItem,
  UserScheduleItem,
} from '../types/schedules';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { QueryParams, useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DateYYYYMMDD, IterableObject } from '../types/types';
import moment from 'moment';
import {
  convertPyToJsDay,
  convertTemporaryRestDayToLocalSchedules,
  extractScheduleWithDateStartEndTime,
  getTemporaryScheduleStartEndLocalDates,
  go2SchedulesSelectTransform,
  isScheduleRestDay,
} from '../utils/schedule';
import { useAuthProvider } from '../providers/auth/auth';
import { getConfigWithAuthorization } from '../services/base';
import * as cosmos from '../services/cosmos';
import { sortBy } from '../utils/array';

type UseSchedulesProps = ListQuery & {
  users?: string;
  weekday?: number;
  from_date?: string;
  to_date?: string;
};
export function useSchedules<R = ScheduleItem>(
  params: UseSchedulesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseSchedulesProps>({
    params,
    baseIdentifier: 'useSchedules',
    identifierKeys: ['users', 'weekday', 'from_date', 'to_date'],
    queryParamKeys: ['users', 'weekday', 'from_date', 'to_date'],
  });

  return useQueryApi<UseSchedulesProps, ListResult<R>>(
    identifiers,
    '/go2-schedules/schedules/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseDailySchedulesProps = ListQuery & {
  users?: string;
  from_date?: string;
  to_date?: string;
};
export function useDailySchedules<R = DailyScheduleItem>(
  params: UseDailySchedulesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseDailySchedulesProps>({
    params,
    baseIdentifier: 'useDailySchedules',
    identifierKeys: ['users', 'from_date', 'to_date'],
    queryParamKeys: ['users', 'from_date', 'to_date'],
  });

  return useQueryApi<UseDailySchedulesProps, R[]>(
    identifiers,
    '/go2-schedules/schedules/daily/',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select: go2SchedulesSelectTransform,
    },
  );
}

type UsePermanentTemporarySchedulesProps = ListQuery & {
  users?: string;
  from_date?: string;
  to_date?: string;
};
export function usePermanentTemporarySchedules<R = DailyScheduleItem>(
  params: UsePermanentTemporarySchedulesProps = {},
  reactQueryParams?: Partial<QueryParams>,
) {
  const { getTokenSilently } = useAuthProvider();
  const { identifiers, queryParams } =
    useIdentifier<UsePermanentTemporarySchedulesProps>({
      params,
      baseIdentifier: 'usePermanentTemporarySchedules',
      identifierKeys: ['users', 'from_date', 'to_date'],
      queryParamKeys: ['users', 'from_date', 'to_date'],
    });

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<R[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const config = getConfigWithAuthorization(await getTokenSilently());
        const limit = 1000;
        const result: [
          AxiosResponse<ListResult<ScheduleItem>>,
          AxiosResponse<{ user: number; schedules: UserScheduleItem[] }[]>,
        ] = await Promise.all([
          cosmos.request({
            url: '/api/go2-schedules/schedules/',
            signal,
            ...config,
            params: {
              ...queryParams,
              page_size: limit,
              include_deleted: false,
              include_metadata: true,
            },
          }),
          cosmos.request({
            url: '/api/go2-schedules/schedules/users/',
            signal,
            ...config,
            params: {
              limit: limit,
              users: queryParams.users,
              is_temporary: false,
            },
          }),
        ]);

        const permanentByUser: IterableObject<UserScheduleItem[]> = {};
        const temporaryByUser: IterableObject<ScheduleItem[]> = {};

        /**
         * Since the query from_date and to_date have been modified to
         * add on the end date, and subtract to start date. In order to
         * compensate on the timezone we expand the range by 1 day on both
         * sides.
         */
        const fromDate = moment(queryParams.from_date!).startOf('day');
        const toDate = moment(queryParams.to_date!).startOf('day');
        /**
         * TODO: Not sure if we need to convert back the schedule by adding -+1 day on each.
         * Convert it back to the actual range on the displayed list in the table
         */
        const range = [
          fromDate.clone().format('YYYY-MM-DD'),
          toDate.clone().format('YYYY-MM-DD'),
        ];

        const users = (queryParams.users?.split(',') ?? []).map((u) => +u);
        const [temporarySchedules, permanentSchedules] = result as unknown as [
          ListResult<ScheduleItem>,
          { user: number; schedules: UserScheduleItem[] }[],
        ];
        users.forEach((user) => {
          /**
           * Iterate through the users and normalize the data, attach the user to have a reference later
           */
          let permanent = (
            permanentSchedules.find((ps) => ps.user === user)?.schedules ?? []
          ).map((s) => ({
            ...s,
            user: user,
          }));
          /**
           * There are multiple schedules for the same user on the same weekday.
           * In order to avoid duplication that points to the same date.
           * Let's filter them to avoid the scenario. Using the keys
           * weekday, start & end time, and timezone.
           */
          const permDupShield: IterableObject = {};
          // sort them by descending, means latest created
          permanent = sortBy(permanent, 'id', true, 'DESC', 'number');
          permanent = permanent.filter((s) => {
            const key = `${s.weekday}_${s.tzinfo}`;
            if (permDupShield[key]) return false;
            return (permDupShield[key] = true);
          });
          // sort them back to ascending, in order to order them by oldest
          permanent = sortBy(permanent, 'id', true, 'ASC', 'number');
          permanentByUser[user] = permanent;

          /**
           * Grouping the results of the temporary schedule by user
           */
          temporaryByUser[user] = temporarySchedules.results
            .filter((temp) => temp.user === user)
            .map((ts) => {
              if (isScheduleRestDay(ts)) {
                return convertTemporaryRestDayToLocalSchedules(ts);
              }
              return ts;
            })
            .map((ts) => {
              const { startDateTime, endDateTime } =
                getTemporaryScheduleStartEndLocalDates(ts);

              return {
                ...ts,
                date: startDateTime.format('YYYY-MM-DD'),
                start_date_time: startDateTime.clone().utc().format(),
                end_date_time: endDateTime.clone().utc().format(),
              };
            })
            /**
             * Filter the temporary schedule that's available on the range only
             */
            .filter(
              (ts) =>
                moment(ts.date).isSameOrAfter(range[0]) &&
                moment(ts.date).isSameOrBefore(range[1]),
            );
        });

        let schedules: ScheduleItem[] = [];
        let formattedDailySchedule: DailyScheduleItem[] = [];

        /**
         * Do the temporary schedules first since there's date already specified
         * Extracting the local date, start_date_time and end_date_time to UTC.
         */
        Object.keys(temporaryByUser).forEach((user) => {
          const temporary = temporaryByUser[user];
          temporary.forEach((ts) => schedules.push(ts));
        });

        while (fromDate.isSameOrBefore(toDate)) {
          let date = fromDate.format('YYYY-MM-DD');
          let weekday = fromDate.day();

          Object.keys(permanentByUser).forEach((user) => {
            const permanent = permanentByUser[user];
            const temporary = sortBy(
              temporaryByUser[user] ?? [],
              'id',
              true,
              'DESC',
              'number',
            );
            const temporaryByDate: IterableObject<ScheduleItem[]> =
              temporary.reduce((prev, curr) => {
                prev[curr.date] = [...(prev[curr.date] ?? []), curr];
                return prev;
              }, {} as IterableObject<ScheduleItem[]>);

            permanent.forEach((s) => {
              /**
               * Permanent schedules are only having the weekday, start/time
               * and timezone based on their creation.
               */
              const dateLocalStartTime = moment
                .tz(
                  `${convertPyToJsDay(s.weekday)} ${s.start_time}`,
                  'e HH:mm',
                  s.tzinfo,
                )
                .local();
              const dateLocalEndTime = moment
                .tz(
                  `${convertPyToJsDay(s.weekday)} ${s.end_time}`,
                  'e HH:mm',
                  s.tzinfo,
                )
                .local();

              /**
               * Match the local day of the permanent schedule to the
               * current weekday iterated to local weekday
               */
              if (dateLocalStartTime.day() === weekday) {
                const dateLocal = moment(date);
                dateLocalStartTime.set({
                  date: dateLocal.date(),
                  month: dateLocal.month(),
                  year: dateLocal.year(),
                });
                dateLocalEndTime.set({
                  date: dateLocal.date(),
                  month: dateLocal.month(),
                  year: dateLocal.year(),
                });

                const p: Partial<ScheduleItem> = {
                  ...s,
                  date,
                  start_date_time: dateLocalStartTime.clone().utc().format(),
                  end_date_time: dateLocalEndTime.clone().utc().format(),
                };
                /**
                 * Check schedule for permanent id if it exist to avoid duplication.
                 * It's possible the permanent will be used multiple on a different
                 * day with same weekday, we check if it's in the same date.
                 */
                const isExist = schedules.some(
                  (itemSchedule) =>
                    itemSchedule.id === p.id && itemSchedule.date === p.date,
                );
                /**
                 * When working with temporary schedule.
                 * The attached permanent schedule goes to a different date
                 * when working with timezone. In order to properly overwrite
                 * The correct behavior, we need to exclude.
                 */
                let excluded = (temporaryByDate[p.date!] ?? [])
                  /**
                   * Excluding the permanent if there's a direct permanent
                   * in the metadata of temporary schedule
                   */
                  .some((ts) => ts.metadata?.some((pm) => pm.id === p.id));

                /**
                 * Check for duplicate of id permanent schedule
                 * and it's not excluded in when there's temporary schedule
                 */
                if (!isExist && !excluded) {
                  schedules.push(p as ScheduleItem);
                }
              }
            });
          });

          // NOTE: Don't forget to increase the date to stop the loop
          fromDate.add(1, 'day');
        }

        /**
         * First let's get the schedule by weekday.
         */
        schedules.forEach((schedule) => {
          /**
           * Create the container for the schedule, it should be by date
           */
          formattedDailySchedule.push({
            date: schedule.date,
            user: schedule.user,
            weekday: schedule.weekday,
            schedules: [schedule],
          });
        });

        resolve(
          go2SchedulesSelectTransform(
            formattedDailySchedule,
          ) as unknown as Promise<AxiosResponse<R[]>>,
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  const queryResult = useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
  return {
    ...queryResult,
    /**
     * NOTE: There's a bug on react-query@v4 that 'isLoading' seems to be
     * true always even when 'enabled' is false
     * https://github.com/TanStack/query/issues/3584
     */
    isLoading: queryResult.isLoading && queryResult.fetchStatus !== 'idle',
  } as UseQueryResult<R[]>;
}

type UseScheduleByIdProps = {
  id: number;
  include_deleted?: boolean;
};
export function useScheduleById<R = ScheduleItem>(
  params: UseScheduleByIdProps,
  reactQueryParams?: UseQueryOptions,
) {
  if (!('include_deleted' in params)) {
    params.include_deleted = true;
  }

  const { identifiers, queryParams } = useIdentifier<UseScheduleByIdProps>({
    params,
    baseIdentifier: 'useSchedules',
    identifierKeys: ['id'],
    queryParamKeys: ['include_deleted'],
  });

  return useQueryApi<UseScheduleByIdProps, R>(
    identifiers,
    `/go2-schedules/schedules/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UsePostSchedulePayload = {
  weekday?: number;
  start_time?: string;
  end_time?: string;
  user: number;
  tzinfo: string;
  deleted?: boolean;
}[];
export function usePostSchedule<D = UsePostSchedulePayload>(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>('/go2-schedules/schedules/', {}, axiosConfig);
}

export type UseChangeRequestSchedulesProps = ListQuery & {
  approved_by?: number;
  from_date?: string;
  to_date?: string;
  is_temporary?: boolean;
  schedule?: number;
  status?: 'pending' | 'approved' | 'rejected' | 'canceled';
  submitted_by?: number;
  weekday?: number; // pyDay
};
export function useChangeRequestSchedules<R = ChangeRequestScheduleItem>(
  params: UseChangeRequestSchedulesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseChangeRequestSchedulesProps>({
      params,
      baseIdentifier: 'useChangeRequestSchedules',
      identifierKeys: [
        'approved_by',
        'from_date',
        'to_date',
        'is_temporary',
        'schedule',
        'status',
        'submitted_by',
        'weekday',
      ],
      queryParamKeys: [
        'approved_by',
        'from_date',
        'to_date',
        'is_temporary',
        'schedule',
        'status',
        'submitted_by',
        'weekday',
      ],
    });

  return useQueryApi<UseChangeRequestSchedulesProps, ListResult<R>>(
    identifiers,
    '/go2-schedules/change-requests/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UsePostChangeRequestSchedulePayload = {
  schedule?: number;
  new_start_time?: string;
  new_end_time?: string;
  tzinfo?: string;
  weekday?: number;
  reason: string;
  requested_by_role: 'teammate' | 'manager';
  delete?: boolean;
  metadata?: Partial<ChangeRequestMetadataObject & IterableObject>;
}[];
export function usePostChangeRequestSchedule<
  D = UsePostChangeRequestSchedulePayload,
>(axiosConfig?: Partial<AxiosRequestConfig>) {
  return useMutationApi<D>('/go2-schedules/change-requests/', {}, axiosConfig);
}

export type UseChangeRequestRejectCancelProps = {
  id: number;
  action: 'approve' | 'cancel' | 'reject';
};
export function useChangeRequestRejectCancel<D = any>(
  { id, action }: UseChangeRequestRejectCancelProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>(
    `/go2-schedules/change-requests/${id}/${action}/`,
    {},
    axiosConfig,
  );
}

export type UseBatchChangeRequestRejectCancelProps = {
  batchId: string;
  action: 'approve' | 'cancel' | 'reject';
};
export function useBatchChangeRequestRejectCancel<D = any>(
  { batchId, action }: UseBatchChangeRequestRejectCancelProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>(
    `/go2-schedules/change-requests/batch/${batchId}/${action}/`,
    {},
    axiosConfig,
  );
}

export type UsePostChangeRequestTemporarySchedulePayload = {
  user?: number;
  weekday?: number;
  schedule?: number;
  tzinfo?: string;
  new_start_time?: string;
  new_end_time?: string;
  from_date?: DateYYYYMMDD;
  to_date?: DateYYYYMMDD;
  reason: string;
  requested_by_role: 'teammate' | 'manager';
  delete?: boolean;
  metadata?: ChangeRequestMetadataObject | null;
}[];
export function usePostChangeRequestTemporarySchedule<
  D = UsePostChangeRequestTemporarySchedulePayload,
>(axiosConfig?: Partial<AxiosRequestConfig>) {
  return useMutationApi<D>(
    '/go2-schedules/change-requests/temporary/',
    {},
    axiosConfig,
  );
}

export type UseBatchChangeRequestSchedulesProps = ListQuery & {
  is_temporary?: boolean;
  user_ids?: string;
  submitted_by_ids?: string;
  status?: 'pending' | 'approved' | 'rejected' | 'canceled';
};
export function useBatchChangeRequestSchedules<R = BatchChangeScheduleItem>(
  params: UseBatchChangeRequestSchedulesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseBatchChangeRequestSchedulesProps>({
      params,
      baseIdentifier: 'useBatchChangeRequestSchedules',
      identifierKeys: [
        'is_temporary',
        'user_ids',
        'submitted_by_ids',
        'status',
      ],
      queryParamKeys: [
        'is_temporary',
        'user_ids',
        'submitted_by_ids',
        'status',
      ],
    });

  return useQueryApi<UseChangeRequestSchedulesProps, R[]>(
    identifiers,
    '/go2-schedules/change-requests/batch/',
    queryParams,
    {
      ...defaultReactQueryParams,
      select(data: BatchChangeScheduleItem[]) {
        data.forEach((item) => (item.id = item.id ?? item.batch_id));
        return data;
      },
      ...reactQueryParams,
    },
  );
}

export type UseBatchChangeRequestScheduleByBatchIdProps = {
  batch_id?: string;
};
export function useBatchChangeRequestScheduleByBatchId<
  R = BatchChangeScheduleItem,
>(
  params: UseBatchChangeRequestScheduleByBatchIdProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseBatchChangeRequestScheduleByBatchIdProps>({
      params,
      baseIdentifier: 'useBatchChangeRequestScheduleByBatchId',
      identifierKeys: ['batch_id'],
    });

  return useQueryApi<{}, R>(
    identifiers,
    `/go2-schedules/change-requests/batch/${params.batch_id}/`,
    queryParams,
    {
      ...defaultReactQueryParams,
      select(data: BatchChangeScheduleItem) {
        data.id = data.id ?? data.batch_id;
        return data;
      },
      ...reactQueryParams,
    },
  );
}

export type UseUserPermanentSchedulesProps = ListQuery & {
  is_temporary?: boolean;
  users?: string;
};
export function useUserPermanentSchedules<
  R = {
    user: number;
    schedules: UserScheduleItem[];
  },
>(params: UseUserPermanentSchedulesProps, reactQueryParams?: UseQueryOptions) {
  const { identifiers, queryParams } =
    useIdentifier<UseUserPermanentSchedulesProps>({
      params,
      baseIdentifier: 'useUserPermanentSchedules',
      identifierKeys: ['users', 'is_temporary'],
      queryParamKeys: ['users', 'is_temporary'],
    });

  return useQueryApi<UseChangeRequestSchedulesProps, R[]>(
    identifiers,
    '/go2-schedules/schedules/users/',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(
        data: {
          user: number;
          schedules: UserScheduleItem[];
        }[],
      ) {
        data.forEach((item) => {
          item.schedules.forEach((schedule) => {
            Object.assign(
              schedule,
              extractScheduleWithDateStartEndTime(schedule),
            );
          });
        });

        return data;
      },
    },
  );
}
