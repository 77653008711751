import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TimeBlock from '../TimeBlock/TimeBlock';

import { isActiveWorklog } from '../TimeDetailsTableRow/TimeDetailsTableRow';
import { getColorByTaskName } from './TimelineColorMap';

import './timeline.css';
import { UseFetchTimeDetailsResponse } from '../../../../hooks/edit-time';
import { useWindowDimension } from '../../../../hooks/utils/window';

const formatHour = (hour: number) => {
  if (hour === 0) {
    return '';
  }
  let modHour = hour % 12;
  modHour = modHour ? modHour : 12; // If hour is 0 (i.e., 12 AM), make it 12
  const suffix = hour >= 12 ? 'PM' : 'AM';
  return `${modHour} ${suffix}`;
};

type TimelineProps = {
  data?: UseFetchTimeDetailsResponse[];
};
const Timeline: React.FC<TimelineProps> = ({ data: timeDetailsData = [] }) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const hoursLength = 24;
  const hours = Array.from({ length: hoursLength + 1 }, (_, i) => i);

  useWindowDimension();

  return (
    <Box
      id='timeline'
      ref={timelineRef}
      justifyContent='space-evenly'
      component='section'
      flexWrap='nowrap'
      sx={{
        marginBottom: '69px',
        marginTop: '60px',
        position: 'relative',
        height: '100px',
      }}
      className='timeline-grid-container'
    >
      <Grid
        container
        direction='row'
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        }}
      >
        {hours.map((hour, index) => {
          let pctLength = 100 / hoursLength;
          const style = {
            top: 0,
            left: `${pctLength * index}%`,
            position: 'absolute',
          };

          if (index % 2 === 0) {
            return (
              <Grid item key={index} className={'line'} sx={style}>
                {index === 0 || index === hours.length - 1 ? null : (
                  <Typography variant='body1' className={'label'}>
                    {formatHour(hour)}
                  </Typography>
                )}
              </Grid>
            );
          } else {
            return (
              <Grid item key={index} className={'line smallLine'} sx={style} />
            );
          }
        })}
      </Grid>
      <Grid
        container
        className='timeline-time-slots'
        sx={{
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        {timeDetailsData.map((detail, index) => {
          const { id, start_time_local, end_time_local, duration, task_name } =
            detail;
          const taskColor = getColorByTaskName(task_name);
          const isActive = isActiveWorklog(detail, index);

          return (
            <TimeBlock
              key={id}
              color={taskColor}
              taskName={task_name}
              timeStarted={start_time_local}
              timeEnded={isActive ? null : end_time_local}
              duration={duration}
              timelineWidth={
                timelineRef.current ? timelineRef.current.clientWidth : 0
              }
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default React.memo(Timeline);
