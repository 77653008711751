import React, {
  useMemo,
  useRef,
  useState,
  SyntheticEvent,
  useEffect,
} from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { M3MenuItem } from '../../M3/M3MenuItem';
import { M3Button, M3IconButton } from '../../M3/M3Button';
import BasicPopoverWithSearch from '../../Popover/BasicPopoverWithSearch';

import { useUserProvider } from '../../../providers/user/user';
import {
  getUserInitials,
  getUserProfileDisplayName,
} from '../../../utils/user';
import { useAppProvider } from '../../../providers/app/app';
import { spliceFromArray } from '../../../utils/array';
import {
  useJobDescriptionAddRemoveMembers,
  useJobDescriptionById,
} from '../../../hooks/jobs';
import { useGo2Members } from '../../../hooks/member';
import { UserMetadata } from '../../../types/profile';

type JobDescriptionMembersProps = {
  editable?: boolean;
  divisionId?: number | string | null;
  jobDescriptionId?: number | string;
  onMembersChanged?: (members: MemberItem[]) => void;
};

type AddMemberState = {
  open: boolean;
  type: 'add' | 'view' | null;
  element: HTMLElement | null;
};

type MemberItem = {
  id: string;
  label: string;
  url?: string;
};

const JobDescriptionMembers = ({
  editable,
  jobDescriptionId,
  divisionId,
  onMembersChanged,
}: JobDescriptionMembersProps) => {
  const { isDarkMode } = useAppProvider();
  const { getUser, users, setUniqueIdsToFetch } = useUserProvider();

  const membersRef = useRef(null);
  const allMembersRef = useRef(null);

  const jobDescription = useJobDescriptionById(
    { id: jobDescriptionId! },
    { enabled: !!jobDescriptionId },
  );
  const divisionMembers = useGo2Members(
    {
      key: 'JobDescriptionMembers',
      limit: 100,
      is_active: true,
      division: divisionId!,
    },
    { enabled: false },
  );
  const jdData = jobDescription.data;

  const [membersState, setMembersState] = useState<AddMemberState>({
    open: false,
    type: null,
    element: null,
  });
  const [addedMembers, setAddedMembers] = useState<MemberItem[]>([]);
  const [removedMembers, setRemovedMembers] = useState<MemberItem[]>([]);

  const viewUserOptions: MemberItem[] = useMemo(() => {
    if (jobDescriptionId) {
      return (jdData?.go2_members ?? [])
        .filter((member) => !!getUser('employee_id', member.employee_id))
        .map((member) => {
          const user = getUser('employee_id', member.employee_id)!;
          return {
            id: user.id as unknown as string,
            label: getUserProfileDisplayName(user).fullName as string,
            url: user.photo_url,
          };
        });
    }

    return addedMembers;
    // eslint-disable-next-line
  }, [jobDescriptionId, jdData, users, addedMembers]);

  const addUserOptions: MemberItem[] = useMemo(() => {
    return (divisionMembers.data?.results ?? [])
      .filter((member) => !!getUser('employee_id', member.employee_id))
      .map((member) => {
        const user = getUser('employee_id', member.employee_id)!;
        return {
          id: user.id as unknown as string,
          label: getUserProfileDisplayName(user).fullName as string,
          url: user.photo_url,
        };
      });
    // eslint-disable-next-line
  }, [divisionMembers, users, viewUserOptions, membersState]);

  const { total, max, members } = useMemo(() => {
    const max = 5;
    return {
      max,
      total: viewUserOptions.length,
      members: viewUserOptions.slice(0, max),
    };
    // eslint-disable-next-line
  }, [viewUserOptions, users]);

  const userOptions: MemberItem[] =
    membersState.type === 'add'
      ? addUserOptions
      : membersState.type === 'view'
      ? viewUserOptions
      : [];

  const getPlaceholderText = () => {
    if (membersState.type === 'view') {
      return 'Search member...';
    }

    return 'Search member...';
  };

  const getAddButtonText = () => {
    return '+ Add member';
  };

  const addMember = (member: MemberItem) => {
    setAddedMembers((state) => {
      if (!state.find((m) => m.id === member.id)) {
        state = [member, ...state];
      }
      return state;
    });
    setRemovedMembers((state) => {
      state = [...state];
      spliceFromArray(state, member);
      return state;
    });
  };

  const removeMember = (member: MemberItem) => {
    setAddedMembers((state) => {
      state = [...state];
      spliceFromArray(state, member);
      return state;
    });
    setRemovedMembers((state) => {
      if (!state.find((m) => m.id === member.id)) {
        state = [...state, member];
      }
      return state;
    });
  };

  const addJDMembers = useJobDescriptionAddRemoveMembers({
    id: jobDescriptionId!,
    segment: 'add-members',
  });
  const removeJDMembers = useJobDescriptionAddRemoveMembers({
    id: jobDescriptionId!,
    segment: 'remove-members',
  });

  const renderAllMembers = () => {
    return (
      <Box ref={allMembersRef} position='relative'>
        <M3Button
          disabled={!total}
          active={membersState.open && membersState.type === 'view'}
          sx={{
            fontSize: 14,
            mr: total ? 0 : -2,
          }}
          onClick={() => {
            setMembersState((state) => ({
              ...state,
              open: true,
              type: 'view',
              element: allMembersRef.current,
            }));
          }}
        >
          {total ? `See all members` : 'No members added'}
        </M3Button>
      </Box>
    );
  };

  useEffect(() => {
    onMembersChanged?.([...viewUserOptions]);
    // eslint-disable-next-line
  }, [viewUserOptions]);

  /**
   * Reset all members when new division has been selected
   */
  useEffect(() => {
    if (divisionId) {
      divisionMembers.refetch();
    }
    // eslint-disable-next-line
  }, [divisionId]);

  useEffect(() => {
    setUniqueIdsToFetch({
      employee_ids: [
        ...(divisionMembers.data?.results ?? []).map(
          (member) => member.employee_id,
        ),
        ...(jdData?.go2_members ?? []).map((member) => member.employee_id),
      ],
    });
    // eslint-disable-next-line
  }, [divisionMembers.data, jdData]);

  /**
   * Listen when new members will be added
   */
  useEffect(() => {
    const addedUsers: UserMetadata[] = addedMembers
      .map((member) => getUser('user_id', member.id) as UserMetadata)
      .filter((user) => !!user);

    if (jobDescriptionId && addedUsers.length) {
      addJDMembers.mutate({
        staff_ids: addedUsers.map((user) => user.staff_id),
      });
    }
    // eslint-disable-next-line
  }, [jobDescriptionId, addedMembers]);

  /**
   * Listen when new members will be removed
   */
  useEffect(() => {
    const removedUsers: UserMetadata[] = removedMembers
      .map((member) => getUser('user_id', member.id) as UserMetadata)
      .filter((user) => !!user);

    if (jobDescriptionId && removedUsers.length) {
      removeJDMembers.mutate({
        staff_ids: removedUsers.map((user) => user.staff_id),
      });
    }
    // eslint-disable-next-line
  }, [jobDescriptionId, removedMembers]);

  /**
   * When adding/removing members successfully, refetch the job descriptions
   * to get the updated job description in the database
   */
  useEffect(() => {
    if (
      jobDescriptionId &&
      (addJDMembers.isSuccess || removeJDMembers.isSuccess)
    ) {
      jobDescription.refetch();
    }

    /**
     * Reset the container which contains the added/removed
     */
    if (addJDMembers.isSuccess) {
      setAddedMembers([]);
    }
    if (removeJDMembers.isSuccess) {
      setRemovedMembers([]);
    }
    // eslint-disable-next-line
  }, [jobDescriptionId, addJDMembers.isSuccess, removeJDMembers.isSuccess]);

  return (
    <Box
      gap={1}
      minWidth={305}
      display='flex'
      flexDirection='column'
      alignItems='flex-end'
      justifyContent='flex-start'
    >
      <Typography
        mt={-2.3}
        pr={0.4}
        fontSize={14}
        component='div'
        sx={{ opacity: 0.5 }}
      >
        Members ({total})
      </Typography>
      <Stack direction='row' alignItems='center' gap={1} minHeight={40}>
        {editable && renderAllMembers()}
        <AvatarGroup max={max}>
          {members.map((member, index) => {
            let user = getUser('user_id', member.id);

            if (!user) return null;

            return (
              <Tooltip
                key={index}
                title={`${user.first_name || ''} ${
                  user.last_name || ''
                }`.trim()}
              >
                <Avatar
                  src={user.photo_url}
                  sx={{
                    width: 30,
                    height: 30,
                    borderColor: isDarkMode
                      ? 'var(--md-ref-palette-neutral10) !important'
                      : undefined,
                  }}
                >
                  {
                    getUserInitials(
                      `${user.preferred_name || ''} ${
                        user.first_name || ''
                      }`.trim(),
                    ).initial
                  }
                </Avatar>
              </Tooltip>
            );
          })}
        </AvatarGroup>
      </Stack>
      {editable && (
        <Box ref={membersRef}>
          <M3Button
            active={membersState.open && membersState.type === 'add'}
            sx={{
              mr: -1,
              fontSize: 14,
            }}
            onClick={() => {
              setMembersState((state) => ({
                ...state,
                open: true,
                type: 'add',
                element: membersRef.current,
              }));
            }}
          >
            {getAddButtonText()}
          </M3Button>
        </Box>
      )}
      <Box mr={-1}>{!editable && renderAllMembers()}</Box>
      <BasicPopoverWithSearch
        open={membersState.open}
        anchorEl={membersState.element}
        options={userOptions}
        placeholder={getPlaceholderText()}
        renderOption={(_, option) => {
          return (
            <UserMemberItem
              key={option.id}
              data-id={option.id}
              option={option as MemberItem}
              editable={editable}
              viewAll={!!editable && membersState.type === 'view'}
              onClick={() => {
                if (!editable) return;

                addMember(option as MemberItem);
                setMembersState((state) => ({
                  ...state,
                  open: false,
                }));
              }}
              onRemove={() => {
                if (!editable) return;

                removeMember(option as MemberItem);
                setMembersState((state) => ({
                  ...state,
                  open: false,
                }));
              }}
            />
          );
        }}
        onClose={() => {
          setMembersState((state) => ({
            ...state,
            open: false,
          }));
        }}
      />
    </Box>
  );
};

export default JobDescriptionMembers;

type UserMemberItemProps = {
  option: MemberItem;
  viewAll: boolean;
  editable?: boolean;
  onClick?: (evt: SyntheticEvent) => void;
  onRemove?: (evt: SyntheticEvent) => void;
};
const UserMemberItem = ({
  editable,
  option,
  viewAll,
  onClick,
  onRemove,
}: UserMemberItemProps) => {
  return (
    <M3MenuItem
      data-id={option.id}
      disableRipple={viewAll || !editable}
      onClick={viewAll || !editable ? undefined : onClick}
      sx={{
        cursor: viewAll || !editable ? 'initial' : undefined,
      }}
    >
      <Avatar
        src={option.url}
        sx={{
          mr: 1.5,
          ml: -0.2,
          width: 30,
          height: 30,
        }}
      />
      <Typography
        flex={1}
        width={0}
        fontSize={14}
        component='span'
        lineHeight={1.3}
        title={option.label}
        whiteSpace='nowrap'
        className='text-truncate'
      >
        {option.label}
      </Typography>
      {viewAll && (
        <M3IconButton
          sx={{
            ml: 1,
            mr: -1,
            width: 30,
            height: 30,
          }}
          onClick={onRemove}
        >
          <CloseOutlinedIcon
            sx={{
              fontSize: 20,
            }}
          />
        </M3IconButton>
      )}
    </M3MenuItem>
  );
};
