import React from 'react';
import { Typography } from '@mui/material';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import SidecarUserGo2DesktopForm from '../components/Sidecar/Go2Desktop/SidecarUserGo2DesktopForm';

type Props = {};

const SidecarGo2Desktop = (props: Props) => {
  return (
    <>
      <DocumentTitle title='Go2 Desktop' trailingTitle='Sidecar' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppActionToolbar>
          <Typography component='span' fontSize={22}>
            Go2 Desktop
          </Typography>
        </AppActionToolbar>
        <SidecarUserGo2DesktopForm />
      </MainContainer>
    </>
  );
};

export default SidecarGo2Desktop;
