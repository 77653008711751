import React, { useEffect, useState, useRef } from 'react';
import { Paper, Box, Stack, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import WorkspaceMenuList, { workspaceWidth } from './WorkspaceMenuList';
import AppDrawerMenuList from './AppDrawerMenuList';
import DrawerPermanent from './DrawerPermanent';
import PerfectScrollbar, { PerfectScrollbarRef } from './PerfectScrollbar';

import { useAppProvider } from '../providers/app/app';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { MenuItem } from '../types/types';
import { parseURL, toURL } from '../utils/url';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { applyOpacityOnColor } from '../utils/color';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';
import * as posthog from '../services/posthog';

type Props = {};

export const searchAnswerMaxCharLimit = 19;

export function trimSearchAnswerQuestion(text: string = '') {
  return (text || '').trim().replace(/\s+/g, ' ').trim();
}

const AppDrawer = (props: Props) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const parsedUrl = parseURL(search);
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const { current: currentWorkspace } = useWorkspaceProvider();
  const {
    focuses: answerFocusItems,
    focusesRequestState,
    questions: searchAnswersQuestionItems,
    questionsRequestState,
  } = useMetadataProvider();

  const [searchAnswersMenus, setSearchAnswersMenus] = useState<MenuItem[]>([]);
  const [searchAnswerFocusesMenu, setSearchAnswerFocusesMenu] = useState<
    MenuItem[]
  >([]);

  const perfectScrollbarRef = useRef<null | PerfectScrollbarRef>(null);
  const [, setScrollYMenuList] = useState(0);

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const menus: MenuItem[] = [
    {
      id: 'manifest',
      name: 'Manifest',
      basePath: '/manifest',
      path: `/manifest/${currentWorkspace!.id}`,
      onClick: () => {
        posthog.capture('manifest clicked', {
          division_id: currentWorkspace!.id,
        });
      },
    },
    {
      id: 'answers',
      name: 'Answers',
      collapsable: true,
      onClick: () => {
        posthog.capture('reports focus clicked', {
          division_id: currentWorkspace!.id,
        });
      },
      children: [
        {
          id: 'search_answers',
          name: (
            <Stack
              flex={1}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              position='relative'
              sx={{
                pointerEvents: 'none',
              }}
            >
              Words
              {questionsRequestState.isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    top: 0,
                    right: searchAnswersMenus.length ? -60 : -4,
                    opacity: 0.3,
                    position: 'absolute',
                  }}
                />
              )}
            </Stack>
          ),
          basePath: '/answers/words',
          path: `/answers/words/${currentWorkspace!.id}`,
          collapsable: !!searchAnswersMenus.length,
          children: searchAnswersMenus.map((menu) => ({
            ...menu,
            active: menu.id === parsedUrl.qid,
          })),
          onClick: () => {
            posthog.capture('reports focus answers clicked', {
              division_id: currentWorkspace!.id,
            });
          },
        },
        {
          id: 'answers-numbers',
          name: (
            <Stack
              flex={1}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              position='relative'
              sx={{
                pointerEvents: 'none',
              }}
            >
              Numbers
              {focusesRequestState.isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    top: 0,
                    right: searchAnswerFocusesMenu.length ? -60 : -4,
                    opacity: 0.3,
                    position: 'absolute',
                  }}
                />
              )}
            </Stack>
          ),
          collapsable: !!searchAnswerFocusesMenu.length,
          children: searchAnswerFocusesMenu.map((menu) => ({
            ...menu,
            active: menu.id === parsedUrl.fid?.toString(),
          })),
          onClick: () => {
            posthog.capture('reports focus numbers clicked', {
              division_id: currentWorkspace!.id,
            });
          },
        },
      ],
    },
    {
      id: 'team',
      name: 'Team',
      collapsable: true,
      onClick: () => {
        posthog.capture('team clicked');
      },
      children: [
        {
          id: 'team-calendar',
          name: 'Calendar',
          basePath: '/team/calendar',
          path: `/team/calendar/${currentWorkspace!.id}`,
          onClick: () => {
            posthog.capture('team calendar clicked', {
              division_id: currentWorkspace!.id,
            });
          },
        },
        {
          id: 'team-time',
          name: 'Time',
          basePath: '/team/time',
          path: `/team/time/${currentWorkspace!.id}`,
          onClick: () => {
            posthog.capture('team time clicked', {
              division_id: currentWorkspace!.id,
            });
          },
        },
        {
          id: 'team-schedule',
          name: 'Schedule',
          basePath: '/team/schedule',
          path: `/team/schedule/${currentWorkspace!.id}`,
          onClick: () => {
            posthog.capture('team schedule clicked', {
              division_id: currentWorkspace!.id,
            });
          },
          featureKey: 'team_schedule_enabled',
        },
        {
          id: 'team-resources',
          name: 'Resources',
          basePath: '/team/resources',
          path: `/team/resources/${currentWorkspace!.id}`,
          onClick: () => {},
        },
        {
          id: 'team-utilization',
          name: 'Utilization',
          basePath: '/team/utilization',
          path: `/team/utilization/${currentWorkspace!.id}`,
          onClick: () => {},
        },
        {
          id: '1-1s',
          name: '1:1s',
          collapsable: true,
          children: [
            {
              id: 'my-1-1s',
              name: 'My 1:1s',
              basePath: '/team/1-1s/my-1-1s',
              path: `/team/1-1s/my-1-1s/${currentWorkspace!.id}`,
            },
            {
              id: 'team-1-1s',
              name: 'Team 1:1s',
              basePath: '/team/1-1s/team-1-1s',
              path: `/team/1-1s/team-1-1s/${currentWorkspace!.id}`,
              roles: [
                'superuser',
                'developer',
                'escalation_manager',
                'squad_lead',
              ],
            },
          ],
        },
        {
          id: 'sprints',
          name: 'Sprints',
          collapsable: true,
          children: [
            {
              id: 'my-sprints',
              name: 'My Sprints',
              basePath: '/team/sprints/my-sprints',
              path: `/team/sprints/my-sprints/${currentWorkspace!.id}`,
            },
            {
              id: 'team-sprints',
              name: 'Team Sprints',
              basePath: '/team/sprints/team-sprints',
              path: `/team/sprints/team-sprints/${currentWorkspace!.id}`,
              roles: [
                'superuser',
                'developer',
                'escalation_manager',
                'squad_lead',
              ],
            },
          ],
        },
        {
          id: 'team-jobs',
          name: 'Jobs',
          basePath: '/team/jobs',
          path: `/team/jobs/${currentWorkspace!.id}`,
          onClick: () => {},
        },
        {
          id: 'team-focus',
          name: 'Focus',
          basePath: '/team/focus',
          path: `/team/focus/${currentWorkspace!.id}`,
          onClick: () => {},
        },
      ],
    },
  ];

  /**
   * Get the list of questions, stripping the questions character to maximum limit
   */
  useEffect(() => {
    if (searchAnswersQuestionItems) {
      /**
       * Convert the search answer questions to a Menu model
       */
      let searchQuestionMenus: MenuItem[] = searchAnswersQuestionItems.map(
        (q) => {
          const id = q.id;
          return {
            id,
            name: q.display,
            path: toURL(`/answers/words/${currentWorkspace!.id}`, {
              qid: id,
            }),
            props: q,
          };
        },
      );

      setSearchAnswersMenus(searchQuestionMenus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAnswersQuestionItems]);

  useEffect(() => {
    if (answerFocusItems) {
      /**
       * Convert the search answer focuses to a Menu model
       */
      let searchFocusMenus: MenuItem[] = answerFocusItems.map((focus) => {
        return {
          id: focus.focus_id,
          name: trimSearchAnswerQuestion(focus.focus).substring(0, 19),
          path: toURL(`/answers/numbers/${currentWorkspace!.id}`, {
            fid: focus.focus_id,
          }),
          props: focus,
        };
      });

      setSearchAnswerFocusesMenu(searchFocusMenus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerFocusItems]);

  useEffect(() => {
    if (
      searchAnswerFocusesMenu.length &&
      pathname.includes('/answers/numbers') &&
      !parsedUrl.fid
    ) {
      const firstFocus = searchAnswerFocusesMenu[0];
      navigate(
        toURL(`/answers/numbers/${currentWorkspace!.id}`, {
          fid: firstFocus.props!.focus_id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[pathname, parsedUrl.fid, searchAnswerFocusesMenu]]);

  /**
   * Navigate to the first question in the list when in /search-answers path
   */
  useEffect(() => {
    if (
      searchAnswersMenus.length &&
      pathname.includes('/answers/words') &&
      !parsedUrl.question
    ) {
      const firstQuestion = searchAnswersMenus[0];
      navigate(
        toURL(`/answers/words/${currentWorkspace!.id}`, {
          qid: firstQuestion.props!.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, parsedUrl.question, searchAnswersMenus]);

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box
          style={{
            height: toolbarHeight,
            minHeight: toolbarHeight,
          }}
        />
        <Box flex={1} sx={{ height: 0 }}>
          <PerfectScrollbar onSetRef={perfectScrollbarRef}>
            <AppDrawerMenuList
              menus={menus}
              menusDependencies={[questionsRequestState]}
              onExpanded={() => setScrollYMenuList((s) => ++s)}
            />
          </PerfectScrollbar>
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default AppDrawer;
