/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  CircularProgress,
  Divider,
  FormHelperText,
  ThemeProvider,
  createTheme,
} from '@mui/material';

import BasicForm, { useForm } from '../../../BasicForm';
import ModalCardView from '../../../ModalCardView';
import {
  IEditTimeDetailsForm,
  ITaskList,
} from '../../constants/edit-time-details-form.interface';
import { M3Button } from '../../../M3/M3Button';
import { M3TextField } from '../../../M3/M3TextField';
import { M3Autocomplete } from '../../../M3/M3Autocomplete';
import { useAppProvider } from '../../../../providers/app/app';
import { isNotNullAndUndefined } from '../../../../utils/object';
import {
  MutateWorklogsDataParams,
  UseFetchTimeDetailsResponse,
  useAddWorklogsData,
  useFetchTasks,
  useUpdateWorklogsData,
} from '../../../../hooks/edit-time';
import { getRawCodeMessage } from '../../../../utils/response';
import { IterableObject } from '../../../../types/types';
import { M3OptionItem } from '../../../Popover/BasicPopoverWithSearch';
import { M3MenuItem } from '../../../M3/M3MenuItem';
import * as posthog from '../../../../services/posthog';

import './time-details-edit-form.css';
import { useUserData } from '../../../../hooks/profile';

type M3TaskRecordOption = M3OptionItem<ITaskList & { isFolder?: boolean }>;
type TimeDetailsEditFormProps = {
  formData: IEditTimeDetailsForm;
  modalHeader: string;
  dateToDisplay: string;
  latestWorklogEntry: UseFetchTimeDetailsResponse | null;
  userId?: number | null;
  onModalClose: () => void;
};
const TimeDetailsEditForm: FC<TimeDetailsEditFormProps> = forwardRef(
  (
    {
      modalHeader,
      formData,
      dateToDisplay,
      latestWorklogEntry,
      userId,
      onModalClose,
    },
    ref,
  ) => {
    const {
      timeEnded,
      timeStarted,
      totalTime,
      taskId,
      taskLabel,
      notes,
      worklogId,
    } = formData;

    const {
      formKeyCount,
      formState,
      hasChanged,
      handleChange,
      updateState,
      handleSubmit,
    } = useForm<IEditTimeDetailsForm>({
      timeStarted,
      timeEnded,
      totalTime,
      taskId,
      worklogId,
      taskLabel: taskLabel ?? '',
      notes,
    });

    const { data: taskList, isLoading: isTaskListLoading } = useFetchTasks({
      assigned_to: userId?.toString(),
      limit: 200,
      is_active: true,
    });
    const { data: userData } = useUserData({
      user_ids: userId?.toString(),
      key: 'user_id',
    });
    const { isDarkMode } = useAppProvider();
    const initialRender = useRef(true);
    const addWorklogData = useAddWorklogsData({ method: 'POST' });
    const editWorklogData = useUpdateWorklogsData(worklogId ?? 0, {
      method: 'PUT',
    });

    const taskListData: M3TaskRecordOption[] = useMemo(() => {
      if (!userData?.length) return [];

      const taskItems = Object.values(
        (taskList?.results ?? [])
          .filter((task) => {
            return task.name !== 'Break';
          })
          .reduce(
            (accu, curr) => {
              const { jobs } = curr;

              // get the user id details to get the job_description_id
              const { job_description_id } = userData[0];

              // get first the specific job id from user and check the jobs array
              const userJob = jobs.find(
                (job) => job.id === job_description_id?.toString(),
              );
              // if userJob has value, get the id, otherwise, get the first job id from jobs.
              let jobId = userJob?.id ?? jobs[0]?.id;
              let jobName = userJob?.name ?? jobs[0]?.name;
              let folderId: string;
              let folderName: string;

              if (jobId) {
                folderId = `job-${jobId}`;
                folderName = jobName;
              } else {
                folderId = 'uncategorized';
                folderName = 'Uncategorized';
              }

              const folder = accu[folderId] || {};
              folder.id = folderId;
              folder.name = folderName;
              folder.tasks = [...(folder.tasks || []), curr];
              accu[folderId] = folder;

              return accu;
            },
            {} as IterableObject<{
              id: number | string;
              name: string;
              tasks: ITaskList[];
            }>,
          ),
      );

      let options: M3TaskRecordOption[] = [];

      taskItems.forEach((folder) => {
        const { id: folderId, name: folderName } = folder;

        options.push({
          id: folderId,
          label: folderName,
          props: {
            isFolder: true,
          },
        } as M3TaskRecordOption);

        folder.tasks.forEach((task) => {
          const { id: taskId, name: taskLabel } = task;

          options.push({
            id: taskId,
            label: taskLabel,
            props: task,
          });
        });
      });

      return options;
    }, [taskList, userData]);

    const rawCodeMessage = getRawCodeMessage(
      addWorklogData.error || editWorklogData.error,
    );

    const theme = createTheme({
      palette: {
        mode: isDarkMode ? 'dark' : 'light',
      },
    });

    const allRequiredAreFilled = !!(
      formState.timeStarted &&
      formState.timeEnded &&
      formState.totalTime &&
      formState.taskId &&
      formState.notes?.trim()
    );

    const [helperTextTimeStart, setHelperTextTimeStart] = useState<
      string | null
    >(null);
    const [helperTextTimeEnd, setHelperTextTimeEnd] = useState<string | null>(
      null,
    );

    const handleFormatTimeValue = (time?: string | null) =>
      time ? moment(time, 'hh:mm:ss A').toDate() : null;

    const handleFieldValueChange = (fieldName: string, value: any) => {
      handleChange({
        target: {
          name: fieldName,
          value,
        },
      });
    };

    const handleTimeFieldUpdate = (newTime: Date | null, fieldName: string) => {
      const formattedTime = newTime
        ? moment(newTime).format('hh:mm:ss A')
        : null;
      const errorText =
        formattedTime === 'Invalid date' ? 'Please enter a valid time' : null;
      const setHelperText =
        fieldName === 'timeStarted'
          ? setHelperTextTimeStart
          : setHelperTextTimeEnd;

      handleFieldValueChange(fieldName, formattedTime);
      setHelperText(errorText);
    };

    const onSubmit = handleSubmit((data) => {
      const date = moment(dateToDisplay);
      const start = moment(data.timeStarted!, 'hh:mm:ss A');
      const end = moment(data.timeEnded!, 'hh:mm:ss A');
      const dateSetObj = {
        date: date.date(),
        month: date.month(),
        year: date.year(),
      };

      // set the date for start/end time
      start.set(dateSetObj);
      end.set(dateSetObj);

      const worklogPayload: MutateWorklogsDataParams = {
        end: end.utc().format(),
        start: start.utc().format(),
        notes: data.notes?.trim()!,
        task: data.taskId!,
        user: userId!,
      };

      formState.worklogId
        ? editWorklogData.mutate(worklogPayload)
        : addWorklogData.mutate(worklogPayload);
    });

    useEffect(() => {
      // Skip the effect on initial render
      if (initialRender.current) {
        initialRender.current = false;
      } else {
        const { timeStarted, timeEnded } = formState;

        if (
          timeStarted === 'Invalid date' ||
          timeEnded === 'Invalid date' ||
          _isEmpty(timeStarted) ||
          _isEmpty(timeEnded)
        ) {
          handleFieldValueChange('totalTime', '');
          return;
        }

        const momentTimeStart = moment(timeStarted, 'hh:mm:ss A');
        const momentTimeEnd = moment(timeEnded, 'hh:mm:ss A');
        const momentDateSelected = moment(dateToDisplay);

        if (momentTimeEnd.isBefore(momentTimeStart)) {
          setHelperTextTimeEnd('End time cannot be earlier than start time');
          setHelperTextTimeStart('Start time cannot be later than end time');
          return;
        }

        if (!_isNull(latestWorklogEntry)) {
          if (
            momentTimeStart.isSameOrAfter(
              latestWorklogEntry.start_time_local,
            ) ||
            momentTimeEnd.isSameOrAfter(latestWorklogEntry.start_time_local)
          ) {
            setHelperTextTimeEnd('');
            setHelperTextTimeStart(
              'You cannot add time beyond your current ongoing worklog.',
            );
            return;
          }
        }

        if (
          momentDateSelected.isSame(moment(), 'day') &&
          momentTimeStart.isAfter(moment())
        ) {
          setHelperTextTimeStart('You cannot add time in the future.');
          return;
        }

        if (
          momentDateSelected.isSame(moment(), 'day') &&
          momentTimeEnd.isAfter(moment())
        ) {
          setHelperTextTimeEnd('You cannot add time in the future.');
          return;
        }

        const duration = moment.duration(momentTimeEnd.diff(momentTimeStart));
        const hours = duration.hours();
        const minutes = duration.minutes();

        setHelperTextTimeStart(null);
        setHelperTextTimeEnd(null);
        handleFieldValueChange('totalTime', `${hours}h ${minutes}m`);
      }
    }, [dateToDisplay, formState.timeStarted, formState.timeEnded]);

    useEffect(() => {
      if (addWorklogData.isSuccess || editWorklogData.isSuccess) {
        onModalClose();
      }
    }, [addWorklogData.isSuccess, editWorklogData.isSuccess]);

    return (
      <ModalCardView
        headerSx={{ pt: 2, pb: 2 }}
        footerSx={{ px: 2, py: 1 }}
        animateOnMount={true}
        close={onModalClose}
        header={
          <Typography component='div' className='editModalHeaderTitle'>
            <span className='text-truncate' style={{ maxWidth: 420 }}>
              {modalHeader}
            </span>
          </Typography>
        }
        footer={
          <Stack direction='row' justifyContent={'center'}>
            <M3Button
              color='primary'
              variant='contained'
              sx={{ width: 100 }}
              disabled={
                addWorklogData.isLoading || editWorklogData.isLoading
                  ? true
                  : hasChanged || helperTextTimeStart || helperTextTimeEnd
                  ? !allRequiredAreFilled
                  : true
              }
              onClick={() => {
                posthog.capture('edit time edit submit clicked', {
                  worklog_id: worklogId,
                });
                onSubmit();
              }}
            >
              {addWorklogData.isLoading || editWorklogData.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                'Submit'
              )}
            </M3Button>
          </Stack>
        }
      >
        <Box sx={{ p: 2, pt: 3 }}>
          <BasicForm>
            <Grid
              container
              spacing={3}
              direction='column'
              sx={{
                '.MuiFormHelperText-root': {
                  color: isDarkMode
                    ? 'var(--md-ref-palette-error60) !important'
                    : 'var(--md-ref-palette-error50)',
                  marginLeft: '0',
                },
              }}
            >
              <Grid item pt={1} pb={1}>
                <Typography className='modalDateText'>
                  {`Date: ${moment(dateToDisplay).format('MMMM D, YYYY')}`}
                </Typography>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={4}>
                  <ThemeProvider theme={theme}>
                    <TimePicker
                      label='Time Started'
                      value={handleFormatTimeValue(formState.timeStarted)}
                      renderInput={(props) => (
                        <M3TextField
                          {...props}
                          helperText={helperTextTimeStart}
                          error={isNotNullAndUndefined(helperTextTimeStart)}
                        />
                      )}
                      onChange={(newStartTime) =>
                        handleTimeFieldUpdate(newStartTime, 'timeStarted')
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={4}>
                  <ThemeProvider theme={theme}>
                    <TimePicker
                      label='Time Ended'
                      value={handleFormatTimeValue(formState.timeEnded)}
                      renderInput={(props) => (
                        <M3TextField
                          {...props}
                          helperText={helperTextTimeEnd}
                          error={isNotNullAndUndefined(helperTextTimeEnd)}
                        />
                      )}
                      onChange={(newEndTime) =>
                        handleTimeFieldUpdate(newEndTime, 'timeEnded')
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={4}>
                  <Stack sx={{ gap: '5px' }}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: '100',
                        marginTop: '-8px',
                      }}
                    >
                      Total Time
                    </Typography>
                    <Typography>{formState.totalTime}</Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Grid item>
                <M3Autocomplete
                  key={`${formKeyCount.taskId}_${formKeyCount.taskLabel}`}
                  options={taskListData}
                  loading={isTaskListLoading}
                  value={
                    taskListData.find((task) => task.id === formState.taskId) ??
                    null
                  }
                  onChange={(event, selectedTask) => {
                    updateState((state) => {
                      state = { ...state };
                      state.taskId = ((selectedTask as M3TaskRecordOption)
                        ?.id ?? null) as number;
                      state.taskLabel =
                        (selectedTask as M3TaskRecordOption)?.label ?? '';

                      return state;
                    });
                  }}
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: M3TaskRecordOption,
                  ) => {
                    if (option.props?.isFolder) {
                      return (
                        <li key={`${option.id}_${option.label}`}>
                          <Stack
                            gap={1}
                            flex={1}
                            direction='row'
                            alignItems='center'
                            justifyContent='flex-end'
                            style={{
                              opacity: 0.29,
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Divider />
                            </div>
                            <div
                              style={{
                                fontSize: 10,
                              }}
                            >
                              {option.label}
                            </div>
                            <div style={{ width: 20, minWidth: 20 }}>
                              <Divider />
                            </div>
                          </Stack>
                        </li>
                      );
                    }

                    return (
                      <M3MenuItem {...props} key={option.id}>
                        {option.label}
                      </M3MenuItem>
                    );
                  }}
                  renderInput={(params) => (
                    <M3TextField
                      {...params}
                      name='taskLabel'
                      label='Focus Area'
                      value={formState.taskLabel ?? ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <M3TextField
                  name='notes'
                  label='Reason'
                  minRows={3}
                  value={formState.notes}
                  onChange={handleChange}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
          </BasicForm>
          {rawCodeMessage.error && (
            <FormHelperText error sx={{ mt: 2, mb: -2 }}>
              {rawCodeMessage.message}
            </FormHelperText>
          )}
        </Box>
      </ModalCardView>
    );
  },
);

export default TimeDetailsEditForm;
