import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Avatar, Box, Popover, Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import clsx from 'clsx';

import Calendar, {
  CalendarEvent,
  CalendarEventPropHandler,
} from '../components/Calendar/Calendar';
import MainContainer from '../components/MainContainer';
import M3SurfaceContainer from '../components/M3/M3SurfaceContainer';
import DocumentTitle from '../components/DocumentTitle';
import WindowScrollTop from '../components/WindowScrollTop';

import { parseDateWithoutTimezone } from '../utils/date';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useWhoIsOut, useWhoIsOutMySquad } from '../hooks/hr';
import { useCurrentProfile } from '../hooks/profile';
import {
  getUserDisplayName,
  getUserInitials,
  getUserPhotoUrl,
} from '../utils/user';
import { UserMetadata } from '../types/profile';
import { IterableObject, ReactRenderElement } from '../types/types';
import { WhoIsOutItemResponse, HrTimeOffTypeId } from '../types/hr';
import { useAppProvider } from '../providers/app/app';
import { getTimeOffDateRequestedText } from '../utils/pto';
import { useUserProvider } from '../providers/user/user';
import { useWindowDimension } from '../hooks/utils/window';
import { useGo2Members } from '../hooks/member';
import { Go2MemberModel } from '../types/member';
import { getOrdinalSuffix } from '../utils/number';

type Props = {};

type PopoverEvent = {
  open: boolean;
  anchorEl: HTMLElement | null;
  event: CalendarEvent | null;
};

type CalendarState = {
  view: 'month' | 'week' | 'day';
  start: string;
  end: string;
};

type CalendarResourceMixed = (WhoIsOutItemResponse | Go2MemberModel) & {
  resourceType: 'time-off' | 'birthday' | 'anniversary';
};

const TeamCalendar = (props: Props) => {
  const { toolbarHeight, drawerWidth, subToolbarHeight, isDarkMode } =
    useAppProvider();
  const { current: currentWorkspace } = useWorkspaceProvider();
  const { data: currentProfile } = useCurrentProfile();
  const { getUser, users: allUsers, setUniqueIdsToFetch } = useUserProvider();

  // NOTE: intended to fire always this whole component when resizing
  useWindowDimension();

  const getStartEndDate = (
    startDate?: string | null,
    endDate?: string | null,
  ) => {
    const now = moment();
    const start = startDate
      ? moment(startDate).format('YYYY-MM-DD')
      : now.clone().set({ date: 1 }).format('YYYY-MM-DD');
    const end = endDate
      ? moment(endDate).format('YYYY-MM-DD')
      : now
          .clone()
          .set({ date: now.endOf('month').date() })
          .format('YYYY-MM-DD');
    return { start, end };
  };
  const limit = 300;

  const [popoverEvent, setPopoverEvent] = useState<PopoverEvent>({
    open: false,
    anchorEl: null,
    event: null,
  });
  const [calendar, setCalendar] = useState<CalendarState>({
    view: 'week',
    ...getStartEndDate(),
  });

  const { start, end } = calendar;
  const whoIsOut = useWhoIsOut(
    {
      key: 'team_calendar_who_is_out',
      limit,
      start__gte: start,
      end__lte: end,
      division: +currentWorkspace!.id,
    },
    { enabled: false },
  );
  const whoIsOutMySquad = useWhoIsOutMySquad(
    {
      key: 'team_calendar_who_is_out_my_squad',
      limit,
      start,
      end,
      user_id: currentProfile!.id,
    },
    { enabled: false },
  );

  const go2Members = useGo2Members({
    division:
      currentWorkspace!.id === 'my-squad' ? undefined : +currentWorkspace!.id,
    employee_ids:
      currentWorkspace!.id === 'my-squad'
        ? currentWorkspace!.memberId
        : undefined,
    limit,
    is_active: true,
  });

  const query =
    currentWorkspace!.id === 'my-squad' ? whoIsOutMySquad : whoIsOut;

  const getBirthdayAnniversaryEvents = (members: Go2MemberModel[]) => {
    let _events: CalendarEvent<CalendarResourceMixed>[] = [];
    members.forEach((member) => {
      if (member.birth_date) {
        const year = moment(calendar.start).year();
        const date = moment(member.birth_date)
          .set({ year })
          .set({ hour: 0, minute: 0, second: 0 });
        const bday = Math.abs(date.diff(member.birth_date, 'year'));
        // only include member birthday's not passed their birth date
        if (bday) {
          _events.push({
            title: `${member.full_name}'s Birthday`,
            start: date.toDate(),
            end: date.toDate(),
            resource: {
              ...member,
              resourceType: 'birthday',
            },
          });
        }
      }

      if (member.hire_date) {
        const year = moment(calendar.start).year();
        const date = moment(member.hire_date)
          .set({ year })
          .set({ hour: 0, minute: 0, second: 0 });
        const anniv = Math.abs(date.diff(member.hire_date, 'year'));
        // only include the member having work a year or more
        if (anniv) {
          _events.push({
            title: `${member.full_name}'s Anniversary`,
            start: date.toDate(),
            end: date.toDate(),
            resource: {
              ...member,
              resourceType: 'anniversary',
            },
          });
        }
      }
    });
    return _events;
  };

  const events: CalendarEvent<CalendarResourceMixed>[] = useMemo(() => {
    let _events: CalendarEvent<CalendarResourceMixed>[] = [];

    if (query.data) {
      const uniqueEvents: IterableObject = {};
      query.data.results.forEach((item) => {
        let key = `${item.pto.id}`;

        if (item.pto.status === 'approved') {
          if (!uniqueEvents[key]) {
            uniqueEvents[key] = item;
          }
        }
      });

      const normalizer = (
        item: WhoIsOutItemResponse,
      ): CalendarEvent<CalendarResourceMixed> => {
        let event: CalendarEvent<CalendarResourceMixed> = {
          title: getUserDisplayName(item.staff as any).fullName,
          start: moment(parseDateWithoutTimezone(item.start).date)
            .set({ hour: 0, minute: 0, second: 0 })
            .toDate(),
          end: moment(parseDateWithoutTimezone(item.end).date)
            .set({ hour: 0, minute: 0, second: 0 })
            .toDate(),
          resource: {
            ...item,
            resourceType: 'time-off',
          },
        };
        /**
         * NOTE: Calendar plugin has an issue of include/exclusive date, in order
         * to fix it, we need to set the entire time of the last date to near the
         * midnight, we need to ignore start/end having the same
         */
        if (item.start !== item.end) {
          event.end = moment(parseDateWithoutTimezone(item.end).date)
            .set({ hour: 23, minute: 59, second: 59 })
            .toDate();
        }
        return event;
      };

      _events = [
        ...Object.keys(uniqueEvents)
          .map((key) => uniqueEvents[key])
          .flatMap((item) => {
            const pto = item.pto;
            // placeholder for date range results without the 0 hour/rest days.
            const results = [];

            if (pto && pto.dates) {
              // placeholder for registering an object with fields "start" and "end"
              let placeholder: IterableObject = {};

              // checks for PTO amount with 0 value and disregards it
              for (const [date, amount] of Object.entries(pto.dates)) {
                if (amount === '0') {
                  if (placeholder.start) {
                    // if the start exists we push the placeholder to the results container
                    results.push({ ...placeholder });
                    // clear out the placeholder to register new ones
                    placeholder = {};
                  }
                } else {
                  // registering placeholder with start and end fields
                  if (!placeholder.start) {
                    placeholder.start = date;
                  }
                  placeholder.end = date;
                }
              }

              // at the end of the loop above, we need to push the last placeholder
              if (placeholder.start) {
                results.push({ ...placeholder });
              }
            }

            /** returns a new event items based on the stored results for example we have a pto.dates with values
              {
                "2023-04-03": "8",
                "2023-04-04": "8",
                "2023-04-05": "0",
                "2023-04-06": "0",
                "2023-04-07": "8"
              };

              then the result would be:

              [
                { start: '2023-04-03', end: '2023-04-04' },
                { start: '2023-04-07', end: '2023-04-07' }
              ]

              ignoring the 0 dates.
            */
            return results.map((result) => ({
              ...item,
              start: result.start,
              end: result.end,
            }));
          })
          .map(normalizer),
      ];
    }

    if (go2Members.data) {
      let results = go2Members.data.results
        .filter((member) => {
          if (currentWorkspace!.id === 'my-squad') {
            const sqMembers = (currentWorkspace?.memberId ?? '').split(',');
            return sqMembers.indexOf(member.employee_id) > -1;
          }
          return true;
        })
        .filter((member) => !!getUser('employee_id', member.employee_id));
      _events = [..._events, ...getBirthdayAnniversaryEvents(results)];
    }

    return _events;
    // eslint-disable-next-line
  }, [
    allUsers,
    query.data,
    go2Members.data,
    currentWorkspace,
    calendar.start,
    currentProfile,
  ]);

  const getPtoTypeClassName = (type_id: HrTimeOffTypeId) => {
    return type_id === '92'
      ? 'pto-type-event'
      : type_id === '93'
      ? 'flex-holiday-type-event'
      : type_id === '96'
      ? 'uto-type-event'
      : 'others-type-event';
  };

  const renderLegend = (color = '#de9d00', label: string) => {
    return (
      <Stack direction='row' alignItems='center'>
        <CircleIcon
          sx={{
            mr: 0.5,
            opacity: 0.5,
          }}
          style={{
            fontSize: 12,
            color,
          }}
        />
        <Typography
          component='div'
          fontSize={13}
          fontWeight={500}
          sx={{ opacity: 0.8 }}
        >
          {label}
        </Typography>
      </Stack>
    );
  };

  const renderCalendarLegend = () => {
    return (
      <Stack direction='row' gap={4} pt={1} style={{ height: 27 }}>
        {renderLegend(
          'rgb(var(--calendar-event-pto-type-color))',
          'Paid Time Off (PTO)',
        )}
        {renderLegend(
          'rgb(var(--calendar-event-flex-holiday-type-color))',
          'Flexible Holidays',
        )}
        {renderLegend('rgb(var(--calendar-event-uto-type-color))', 'UTO')}
        {renderLegend(
          'rgb(var(--calendar-event-birthday-type-color))',
          'Birthday',
        )}
        {renderLegend(
          'rgb(var(--calendar-event-anniversary-type-color))',
          'Anniversary',
        )}
        {renderLegend('rgb(var(--calendar-event-others-type-color))', 'Others')}
      </Stack>
    );
  };

  /**
   * Renders the Time Off Popover
   */
  const renderUserEventTimeOffPopover = (
    event: CalendarEvent<WhoIsOutItemResponse>,
  ) => {
    const dateText = getTimeOffDateRequestedText({
      start: moment(event.start).format(),
      end: moment(event.end!).format(),
    });
    const user = getUser('employee_id', event.resource!.staff?.employee_id);
    const whoIsOut = event.resource!;

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        sx={{
          pt: 2,
          position: 'relative',
        }}
        className={clsx({
          [getPtoTypeClassName(event.resource!.pto.type_id)]: true,
          selected: true,
        })}
      >
        <Stack
          gap={2}
          pl={2}
          pr={2}
          pb={1.5}
          flexDirection='row'
          position='relative'
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
            }}
            src={getUserPhotoUrl(user?.photo_url)}
          >
            {getUserInitials(user?.first_name).initial}
          </Avatar>
          <Box>
            <Typography
              component='div'
              fontSize={18}
              fontWeight={500}
              minWidth={70}
            >
              {`${user?.preferred_name ?? user?.first_name ?? ''}`.trim() ||
                event.title}
            </Typography>
            <Typography
              component='div'
              fontSize={13}
              minWidth={70}
              sx={{ opacity: 0.8 }}
            >
              {whoIsOut.pto.amount} hours of {whoIsOut.pto.type_name}
            </Typography>
            <Typography
              component='div'
              fontSize={13}
              minWidth={70}
              sx={{ opacity: 0.5 }}
            >
              {dateText}
            </Typography>
          </Box>
        </Stack>
        <Box minWidth={280}>
          {!!whoIsOut.pto.notes.employee && (
            <Typography
              component='div'
              fontSize={14}
              sx={{
                p: 1,
                pl: 2,
                pr: 2,
                maxWidth: 420,
                backgroundColor: isDarkMode
                  ? 'var(--md-ref-palette-neutral-variant20)'
                  : 'var(--md-ref-palette-neutral-variant95)',
              }}
            >
              {whoIsOut.pto.notes.employee}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  /**
   * Renders the birthday/anniversary pop over
   */
  const renderUserEventBirthdayAnniversaryPopover = (
    event: CalendarEvent<
      Go2MemberModel & { resourceType: 'birthday' | 'anniversary' }
    >,
  ) => {
    const dateText = getTimeOffDateRequestedText({
      start: moment(event.start).format(),
      end: moment(event.end!).format(),
    });
    const user = getUser('employee_id', event.resource!.employee_id);
    const member = event.resource!;
    let title: ReactRenderElement = null;
    let icon: ReactRenderElement = null;
    let className: string = '';

    if (event.resource!.resourceType === 'birthday') {
      title = `${user?.preferred_name ?? user?.first_name ?? ''}${
        user ? `'s Birthday` : ''
      }`.trim() || <>...</>;
      className = 'birthday-type-event selected';
      icon = (
        <Stack direction='row' alignItems='flex-end'>
          <CakeOutlinedIcon
            style={{
              fontSize: 16,
              position: 'relative',
              top: -1,
            }}
          />
          <Typography
            pl={0.5}
            component='div'
            fontSize={14}
            fontWeight={500}
            lineHeight={1}
          >
            Birthday
          </Typography>
        </Stack>
      );
    } else if (event.resource!.resourceType === 'anniversary') {
      const year = moment(calendar.start).year();
      const date = moment(member.hire_date)
        .set({ year })
        .set({ hour: 0, minute: 0, second: 0 });
      const anniv = Math.abs(date.diff(member.hire_date, 'year'));
      className = 'anniversary-type-event selected';

      title = `${user?.preferred_name ?? user?.first_name ?? ''}${
        user ? `'s Anniversary` : ''
      }`.trim() || <>...</>;
      icon = (
        <Stack direction='row' alignItems='flex-end'>
          <CelebrationOutlinedIcon style={{ fontSize: 16 }} />
          <Typography
            pl={0.5}
            component='div'
            fontSize={14}
            fontWeight={500}
            lineHeight={1}
          >
            {getOrdinalSuffix(anniv)} year
          </Typography>
        </Stack>
      );
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        sx={{
          pt: 2,
          position: 'relative',
        }}
        className={className}
      >
        <Stack
          gap={2}
          pl={2}
          pr={2}
          pb={1.5}
          flexDirection='row'
          position='relative'
        >
          {icon}
        </Stack>
        <Stack
          gap={2}
          pl={2}
          pr={2}
          pb={1.5}
          flexDirection='row'
          position='relative'
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
            }}
            src={getUserPhotoUrl(user?.photo_url)}
          >
            {getUserInitials(user?.preferred_name ?? user?.first_name).initial}
          </Avatar>
          <Box>
            <Typography
              component='div'
              fontSize={18}
              fontWeight={500}
              minWidth={70}
            >
              {title}
            </Typography>
            <Typography
              component='div'
              fontSize={13}
              minWidth={70}
              sx={{ opacity: 0.5 }}
            >
              {dateText}
            </Typography>
          </Box>
        </Stack>
        <Box minWidth={280}></Box>
      </Box>
    );
  };

  const renderCalendarEventPopup = () => {
    return (
      <Popover
        open={popoverEvent.open}
        anchorEl={popoverEvent.anchorEl}
        onClose={() => {
          setPopoverEvent((state) => ({ ...state, open: false }));
        }}
        anchorOrigin={{
          vertical: 40,
          horizontal: 40,
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            overflow: 'hidden',
            background: isDarkMode
              ? 'var(--md-sys-color-background-dark)'
              : 'var(--md-sys-color-background-light)',
            border: isDarkMode
              ? '1px solid var(--md-ref-palette-neutral30)'
              : 'none',
          },
        }}
      >
        {!!popoverEvent.event &&
          (popoverEvent.event.resource.resourceType !== 'time-off'
            ? renderUserEventBirthdayAnniversaryPopover(popoverEvent.event!)
            : renderUserEventTimeOffPopover(popoverEvent.event!))}
      </Popover>
    );
  };

  /**
   * Renders the single slot for the 'day' view
   */
  const renderUserEventForDay = (
    event: CalendarEvent<CalendarResourceMixed>,
    props: CalendarEventPropHandler,
  ) => {
    const dateText = getTimeOffDateRequestedText({
      start: moment(event.start).format(),
      end: moment(event.end!).format(),
    });
    let user: Partial<UserMetadata> | null = null;
    let title: ReactRenderElement = null;
    let subtitle: ReactRenderElement = null;
    let notes: ReactRenderElement = null;

    if (
      event.resource?.resourceType === 'birthday' ||
      event.resource?.resourceType === 'anniversary'
    ) {
      return renderBirthdayAnniversaryForWeek(
        event as CalendarEvent<
          Go2MemberModel & { resourceType: 'birthday' | 'anniversary' }
        >,
        props,
      );
    } else {
      const resource: WhoIsOutItemResponse =
        event.resource as unknown as WhoIsOutItemResponse;
      const pto = resource.pto;

      user = getUser('employee_id', resource!.staff?.employee_id);
      title = `${user?.preferred_name ?? user?.first_name ?? ''}`.trim() || (
        <>...</>
      );
      subtitle = (
        <>
          {pto.amount} hours of {pto.type_name}
        </>
      );
      notes = pto.notes.employee;
    }

    return (
      <M3SurfaceContainer
        elevation={1}
        wrapperSx={{
          m: 0.5,
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          borderRadius={3}
          sx={{
            pt: 2,
            position: 'relative',
          }}
          className={clsx(
            getPtoTypeClassName(
              (event.resource as unknown as WhoIsOutItemResponse).pto.type_id,
            ),
            {
              selected: true,
            },
          )}
        >
          <Stack
            gap={2}
            pl={2}
            pr={2}
            pb={1.5}
            flexDirection='row'
            position='relative'
          >
            <Avatar
              sx={{
                width: 40,
                height: 40,
              }}
              src={getUserPhotoUrl(user?.photo_url)}
            >
              {
                getUserInitials(user?.preferred_name ?? user?.first_name ?? '')
                  .initial
              }
            </Avatar>
            <Box>
              <Typography
                component='div'
                fontSize={14}
                fontWeight={500}
                minWidth={70}
                lineHeight={1.3}
              >
                {title}
              </Typography>
              <Typography
                component='div'
                fontSize={13}
                minWidth={70}
                sx={{ opacity: 0.8 }}
              >
                {subtitle}
              </Typography>
              <Typography
                component='div'
                fontSize={13}
                minWidth={70}
                sx={{ opacity: 0.5 }}
              >
                {dateText}
              </Typography>
            </Box>
          </Stack>
          <Box>
            {!!notes && (
              <Typography
                component='div'
                fontSize={14}
                sx={{
                  p: 1,
                  pl: 2,
                  pr: 2,
                  borderRadius: '0 0 8px 8px',
                  backgroundColor: isDarkMode
                    ? 'var(--md-ref-palette-neutral-variant20)'
                    : 'var(--md-ref-palette-neutral-variant95)',
                }}
              >
                {notes}
              </Typography>
            )}
          </Box>
        </Box>
      </M3SurfaceContainer>
    );
  };

  const renderUserEventForWeek = (
    event: CalendarEvent<CalendarResourceMixed>,
    props: CalendarEventPropHandler,
  ) => {
    if (
      event.resource?.resourceType === 'birthday' ||
      event.resource?.resourceType === 'anniversary'
    ) {
      return renderBirthdayAnniversaryForWeek(
        event as CalendarEvent<
          Go2MemberModel & { resourceType: 'birthday' | 'anniversary' }
        >,
        props,
      );
    }

    return renderTimeOffForWeek(
      event as CalendarEvent<
        WhoIsOutItemResponse & { resourceType: 'time-off' }
      >,
      props,
    );
  };

  const renderTimeOffForWeek = (
    event: CalendarEvent<WhoIsOutItemResponse & { resourceType: 'time-off' }>,
    { slotStart }: CalendarEventPropHandler,
  ) => {
    const dateText = getTimeOffDateRequestedText({
      start: moment(event.start).format(),
      end: moment(event.end!).format(),
    });
    const slotStartDate = moment(slotStart);
    const slotEndDate = moment(slotStart).clone().add(6, 'day');
    let isSingleSlotTaken =
      slotEndDate.isSame(event.start!, 'day') ||
      slotStartDate.isSame(event.end!, 'day') ||
      moment(event.start).isSame(event.end!);
    let user: Partial<UserMetadata> | null = null;
    let className: string = '';
    let title: ReactRenderElement = null;
    let subtitle: ReactRenderElement = null;

    const resource: WhoIsOutItemResponse =
      event.resource as unknown as WhoIsOutItemResponse;
    const pto = resource.pto;

    user = getUser('employee_id', resource!.staff?.employee_id);
    title = `${user?.preferred_name ?? user?.first_name ?? ''}`.trim() || (
      <>...</>
    );
    subtitle = (
      <>
        {pto.amount} hours of {pto.type_name}
      </>
    );
    className = clsx(getPtoTypeClassName(pto.type_id), {
      active:
        popoverEvent.open &&
        popoverEvent.event?.resource?.id === event?.resource?.id,
    });

    return (
      <M3SurfaceContainer
        elevation={1}
        wrapperSx={{
          m: 0.5,
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          borderRadius={3}
          className={className}
          sx={{
            pt: 2,
            cursor: 'pointer',
            position: 'relative',
          }}
          onClick={(evt) => {
            const target = (evt.target as HTMLElement)!.closest(
              '.rbc-row-segment',
            ) as HTMLElement;
            setPopoverEvent((state) => ({
              ...state,
              anchorEl: target!,
              open: true,
              event,
            }));
          }}
        >
          <Stack
            pl={2}
            pr={2}
            pb={1.5}
            position='relative'
            flexDirection={isSingleSlotTaken ? 'column' : 'row'}
          >
            <Avatar
              sx={{
                mb: isSingleSlotTaken ? 0.5 : 0,
                width: isSingleSlotTaken ? 30 : 40,
                height: isSingleSlotTaken ? 30 : 40,
              }}
              src={getUserPhotoUrl(user?.photo_url)}
            >
              {
                getUserInitials(user?.preferred_name ?? user?.first_name ?? '')
                  .initial
              }
            </Avatar>
            <Box pl={isSingleSlotTaken ? 0 : 1.4}>
              <Typography
                component='div'
                fontSize={14}
                fontWeight={500}
                minWidth={70}
                lineHeight={1.3}
              >
                {title}
              </Typography>
              <Typography
                component='div'
                fontSize={12}
                minWidth={70}
                sx={{ opacity: 0.8 }}
              >
                {subtitle}
              </Typography>
              <Typography
                component='div'
                fontSize={12}
                minWidth={70}
                sx={{ opacity: 0.5 }}
              >
                {dateText}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </M3SurfaceContainer>
    );
  };

  const renderBirthdayAnniversaryForWeek = (
    event: CalendarEvent<
      Go2MemberModel & { resourceType: 'birthday' | 'anniversary' }
    >,
    { slotStart }: CalendarEventPropHandler,
  ) => {
    const dateText = getTimeOffDateRequestedText({
      start: moment(event.start).format(),
      end: moment(event.end!).format(),
    });
    const slotStartDate = moment(slotStart);
    const slotEndDate = moment(slotStart).clone().add(6, 'day');
    let isSingleSlotTaken =
      slotEndDate.isSame(event.start!, 'day') ||
      slotStartDate.isSame(event.end!, 'day') ||
      moment(event.start).isSame(event.end!);
    let user: Partial<UserMetadata> | null = null;
    let className: string = '';
    let title: ReactRenderElement = null;
    let subtitle: ReactRenderElement = null;
    let icon: ReactRenderElement = null;
    const resource: Go2MemberModel =
      event.resource as unknown as Go2MemberModel;
    user = getUser('employee_id', resource.employee_id);

    if (event.resource?.resourceType === 'birthday') {
      subtitle = `${user?.preferred_name ?? user?.first_name ?? ''}${
        user ? `'s Birthday` : ''
      }`.trim() || <>...</>;
      icon = (
        <Stack pl={2} pr={2} pb={2} direction='row' alignItems='flex-end'>
          <CakeOutlinedIcon
            style={{
              fontSize: 16,
              position: 'relative',
              top: -1,
            }}
          />
          <Typography
            pl={0.5}
            component='div'
            fontSize={14}
            fontWeight={500}
            lineHeight={1}
          >
            Birthday
          </Typography>
        </Stack>
      );

      className = clsx('birthday-type-event', {
        active:
          popoverEvent.open &&
          popoverEvent.event?.resource.id === event.resource.id,
      });
    } else if (event.resource?.resourceType === 'anniversary') {
      const year = moment(calendar.start).year();
      const date = moment(resource.hire_date)
        .set({ year })
        .set({ hour: 0, minute: 0, second: 0 });
      const anniv = Math.abs(date.diff(resource.hire_date, 'year'));
      subtitle = `${user?.preferred_name ?? user?.first_name ?? ''}${
        user ? `'s Anniversary` : ''
      }`.trim() || <>...</>;

      icon = (
        <Stack pl={2} pr={2} pb={2} direction='row' alignItems='flex-end'>
          <CelebrationOutlinedIcon style={{ fontSize: 16 }} />
          <Typography
            pl={0.5}
            component='div'
            fontSize={14}
            fontWeight={500}
            lineHeight={1}
          >
            {getOrdinalSuffix(anniv)} year
          </Typography>
        </Stack>
      );
      className = clsx('anniversary-type-event', {
        active:
          popoverEvent.open &&
          popoverEvent.event?.resource.id === event.resource.id,
      });
    }

    return (
      <M3SurfaceContainer
        elevation={1}
        wrapperSx={{
          m: 0.5,
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          borderRadius={3}
          className={className}
          sx={{
            pt: 2,
            cursor: 'pointer',
            position: 'relative',
          }}
          onClick={(evt) => {
            const target = (evt.target as HTMLElement)!.closest(
              '.rbc-row-segment',
            ) as HTMLElement;
            setPopoverEvent((state) => ({
              ...state,
              anchorEl: target!,
              open: true,
              event,
            }));
          }}
        >
          {icon}
          <Stack pl={2} pr={2} pb={1.5} position='relative' direction='row'>
            <Avatar
              sx={{
                mb: isSingleSlotTaken ? 0.5 : 0,
                width: isSingleSlotTaken ? 30 : 40,
                height: isSingleSlotTaken ? 30 : 40,
              }}
              src={getUserPhotoUrl(user?.photo_url)}
            >
              {
                getUserInitials(user?.preferred_name ?? user?.first_name ?? '')
                  .initial
              }
            </Avatar>
            <Box pl={1}>
              {title}
              <Typography
                component='div'
                fontSize={14}
                fontWeight={500}
                minWidth={70}
                lineHeight={1.3}
              >
                {subtitle}
              </Typography>
              <Typography
                component='div'
                fontSize={12}
                minWidth={70}
                sx={{ opacity: 0.5 }}
              >
                {dateText}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </M3SurfaceContainer>
    );
  };

  useEffect(() => {
    const employee_ids = [
      ...(query.data?.results?.map((item) => item.staff.employee_id) ?? []),
      ...(go2Members.data?.results ?? []).map((member) => member.employee_id),
    ];
    setUniqueIdsToFetch({
      employee_ids,
    });
    // eslint-disable-next-line
  }, [
    currentWorkspace,
    calendar.start,
    calendar.end,
    query.data,
    go2Members.data,
  ]);

  useEffect(() => {
    if (currentWorkspace!.type === 'my_squad') {
      whoIsOutMySquad.refetch();
      go2Members.refetch();
    } else {
      whoIsOut.refetch();
      go2Members.refetch();
    }
    // eslint-disable-next-line
  }, [currentWorkspace, calendar.start, calendar.end]);

  return (
    <>
      <DocumentTitle title='Calendar' trailingTitle='Team' />
      <MainContainer
        sx={{
          maxWidth: 'initial',
          '.react-big-calendar': {
            marginTop: `${toolbarHeight}px`,
            '.rbc-toolbar': {
              right: 0,
              zIndex: 3,
              paddingLeft: 2,
              paddingRight: 2,
              alignItems: 'center',
              position: 'fixed',
              left: drawerWidth,
              top: toolbarHeight,
              height: subToolbarHeight,
              background: isDarkMode
                ? 'var(--md-ref-palette-neutral-variant40)'
                : 'var(--md-ref-palette-neutral-variant90)',
            },
          },
        }}
      >
        <Calendar
          noTimeSlot
          events={events}
          tooltipAccessor={() => ''}
          view={calendar.view}
          views={['month', 'week', 'day']}
          onView={(view) =>
            setCalendar((state) => ({
              ...state,
              view: view as CalendarState['view'],
            }))
          }
          messages={{
            previous: (
              <NavigateBeforeOutlinedIcon style={{ right: 1 }} />
            ) as unknown as string,
            next: (
              <NavigateNextOutlinedIcon style={{ left: 1 }} />
            ) as unknown as string,
            today:
              calendar.view === 'week'
                ? 'This Week'
                : calendar.view === 'month'
                ? 'This Month'
                : 'Today',
          }}
          className={`${calendar.view}-as-current-view`}
          components={{
            timeGutterWrapper: () => null,
            timeGutterHeader: () => null,
            timeSlotWrapper: () => null,
            eventWrapper:
              calendar.view === 'week' || calendar.view === 'month'
                ? (event) =>
                    renderUserEventForWeek(
                      event.event as CalendarEvent<CalendarResourceMixed>,
                      event as unknown as CalendarEventPropHandler,
                    )
                : calendar.view === 'day'
                ? (event) =>
                    renderUserEventForDay(
                      event.event as CalendarEvent<CalendarResourceMixed>,
                      event as unknown as CalendarEventPropHandler,
                    )
                : undefined,
          }}
          onSelectEvent={(event: CalendarEvent, evt: SyntheticEvent) => {
            const target = evt.currentTarget as HTMLElement;
            setPopoverEvent((state) => ({
              ...state,
              anchorEl: target,
              open: true,
              event,
            }));
          }}
          onRangeChange={(range: Date[] | { start: Date; end: Date }) => {
            let start: Date, end: Date;
            if (Array.isArray(range)) {
              start = range[0];
              end = range[range.length - 1];
            } else {
              start = range.start;
              end = range.end;
            }
            setCalendar((state) => ({
              ...state,
              start: moment(start).format('YYYY-MM-DD'),
              end: moment(end).format('YYYY-MM-DD'),
            }));
          }}
        />
        {renderCalendarLegend()}
        {renderCalendarEventPopup()}
      </MainContainer>
      <WindowScrollTop deps={[currentWorkspace]} />
    </>
  );
};

export default TeamCalendar;
