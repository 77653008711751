import React, { useMemo, useRef, useState } from 'react';
import { Paper, Box } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import WorkspaceMenuList, { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';
import PerfectScrollbar, { PerfectScrollbarRef } from '../PerfectScrollbar';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';
import { getAdminEditTimeRoles } from '../../constants/roles-admin-time-edit';
import { getFromSessionStorage } from '../../utils/sessionstorage';
import { useIsPartnerMember, useIsPathBetaUser } from '../../hooks/utils/user';

type Props = {};

const SettingsDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const perfectScrollbarRef = useRef<null | PerfectScrollbarRef>(null);
  const [, setScrollYMenuList] = useState(0);

  const isPartnerMember = useIsPartnerMember();
  const isPathBetaUser = useIsPathBetaUser();
  const impersonation = useMemo(
    () => getFromSessionStorage('impersonation'),
    [],
  );

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  /**
   * The roles admin are group types accumulated for any admin available
   * below the `admin-label` | `admin-divider`
   */
  const rolesAdmin: MenuItem['roles'] = [
    'superuser',
    'preview',
    'escalation_manager',
    ...(!isPartnerMember && !impersonation ? getAdminEditTimeRoles() : []),
  ];

  const menus: MenuItem[] = [
    {
      id: 'headline-settings',
      name: 'Settings',
      icon: <SettingsOutlinedIcon />,
      headline: true,
    },
    {
      id: 'personal-label',
      name: 'Personal',
      isLabel: true,
    },
    {
      id: 'profile',
      name: 'Profile',
      basePath: '/settings/personal/profile',
      path: '/settings/personal/profile',
    },
    {
      id: 'admin-divider',
      divider: true,
      roles: rolesAdmin,
    },
    {
      id: 'admin-label',
      name: 'Admin',
      isLabel: true,
      roles: rolesAdmin,
    },
    {
      id: 'preview-as',
      name: 'Preview As',
      basePath: '/settings/admin/preview-as',
      path: '/settings/admin/preview-as',
      roles: ['superuser', 'preview'],
    },
    {
      id: 'user-provisioning',
      name: 'User Provisioning',
      basePath: '/settings/admin/user-provisioning',
      path: '/settings/admin/user-provisioning',
      roles: ['developer', 'it', 'superuser', 'onboarding'],
    },
  ];

  if (!isPathBetaUser && !impersonation) {
    /**
     * Personal Settings
     * - Profile
     * - Edit Time
     */
    menus.splice(menus.findIndex((m) => m.id === 'profile')! + 1, 0, {
      id: 'edit-time',
      name: 'Edit Time',
      basePath: '/settings/personal/edit-time',
      path: '/settings/personal/edit-time',
    });

    /**
     * Admin Settings
     * - Preview as
     * - Edit Time
     */
    menus.splice(menus.findIndex((m) => m.id === 'preview-as')! + 1, 0, {
      id: 'admin-edit-time',
      name: 'Edit Time',
      basePath: '/settings/admin/edit-time',
      path: '/settings/admin/edit-time',
      roles: getAdminEditTimeRoles(),
    });
  }

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1} sx={{ height: 0 }}>
          <PerfectScrollbar onSetRef={perfectScrollbarRef}>
            <AppDrawerMenuList
              menus={menus}
              onExpanded={() => setScrollYMenuList((s) => ++s)}
            />
          </PerfectScrollbar>
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default SettingsDrawer;
