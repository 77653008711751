import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import RichTextContent from '../RichEditor/RichTextContent';
import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';

type Props = {
  title: ReactRenderElement;
  subtitle?: ReactRenderElement;
  html?: string;
};

const TaskFieldItem: React.FC<Props> = ({ title, subtitle, html }) => {
  const { isDarkMode } = useAppProvider();

  const blockSx: SxProps = {
    p: 0.8,
    pr: 2,
    pl: 2,
    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : '#f5f5f5',
    '.rich-text-content': {
      pt: 1,
      pb: 1,
    },
  };

  return (
    <Box>
      <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
        {title}
      </Typography>
      {html ? (
        <Typography component='div' sx={blockSx}>
          <RichTextContent content={html} />
        </Typography>
      ) : (
        subtitle && (
          <Typography component='pre' sx={blockSx}>
            {typeof subtitle === 'string'
              ? subtitle
                  .trim()
                  .replace(/(\t|\r|\n)+$/i, '')
                  .trim()
              : subtitle}
          </Typography>
        )
      )}
    </Box>
  );
};

export default TaskFieldItem;
