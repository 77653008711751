import Pusher from 'pusher-js';
import { request as cosmosRequest } from '../services/cosmos';
import { IterableObject } from '../types/types';
import {
  UserAuthenticationCallback,
  UserAuthenticationData,
  UserAuthenticationRequestParams,
  ChannelAuthorizationRequestParams,
  ChannelAuthorizationCallback,
  ChannelAuthorizationData,
} from 'pusher-js/types/src/core/auth/options';
import { getConfigWithAuthorization } from './base';

type PusherChannel = any;

type PusherOptions = {
  getTokenSilently: () => Promise<string>;
};

let pusher: null | Pusher = null;

export function init(opt: PusherOptions): Pusher {
  if (pusher) return pusher;

  const { getTokenSilently } = opt;

  pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY!, {
    cluster: 'us2',
    forceTLS: process.env.NODE_ENV === 'production',
    userAuthentication: {
      customHandler: async (
        params: UserAuthenticationRequestParams,
        callback: UserAuthenticationCallback,
      ) => {
        try {
          const response: UserAuthenticationData = await cosmosRequest.post(
            `/api/users/authenticate-user-websocket/`,
            { socket_id: params.socketId },
            getConfigWithAuthorization(await getTokenSilently()),
          );
          callback(null, response);
        } catch (e) {
          callback(e as Error, null);
        }
      },
    },
    channelAuthorization: {
      customHandler: async (
        params: ChannelAuthorizationRequestParams,
        callback: ChannelAuthorizationCallback,
      ) => {
        try {
          const response: ChannelAuthorizationData = await cosmosRequest.post(
            `/api/core/pusher/channel-auth/`,
            {
              socket_id: params.socketId,
              channel_name: params.channelName,
            },
            getConfigWithAuthorization(await getTokenSilently()),
          );
          callback(null, response);
        } catch (e) {
          callback(e as Error, null);
        }
      },
    },
  });

  return pusher;
}

export function getClient(): Pusher {
  if (!pusher) throw new Error('Pusher has not been initialized');

  return pusher as Pusher;
}

export function subscribe(
  channelName: PusherChannel,
  events: IterableObject<Function> = {},
): () => void {
  const ps = getClient();
  const eventList: { event: string; fn: Function }[] = [];

  /**
   * Join in the channel
   */
  const channel = ps.subscribe(channelName);

  Object.keys(events).forEach((event) => {
    eventList.push({
      event,
      fn: events[event],
    });
  });

  /**
   * Bind all the listeners on the channel
   */
  eventList.forEach(({ event, fn }) => channel.bind(event, fn));

  return () => {
    /**
     * Unbind all the listeners on the channel
     */
    eventList.forEach(({ event, fn }) => channel.unbind(event, fn));

    /**
     * Leave on the channel
     */
    ps.unsubscribe(channelName);
  };
}
