import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppActionToolbar from '../components/AppActionToolbar';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import MainContainer from '../components/MainContainer';
import IntercomBreadcrumbs, {
  BreadcrumbItem,
} from '../components/Intercom/IntercomBreadcrumbs';
import WindowScrollTop from '../components/WindowScrollTop';
import ContentSectionItem from '../components/Content/ContentSectionItem';
import ContentArticleItem from '../components/Content/ContentArticleItem';
import M3SurfaceContainer from '../components/M3/M3SurfaceContainer';
import PerfectScrollbar from '../components/PerfectScrollbar';
import { ContentItemBase } from '../components/Content/Content';
import { useIntercomCollectionDetail } from '../components/Intercom/IntercomCollectionDetail';
import ContentTargetAudiences, {
  ContentTargetAudiencesProps,
} from '../components/Content/ContentTargetAudiences';
import ContentArticleMenuOptions from '../components/Content/ContentArticleMenuOptions';
import ContentDragDropUnsaved from '../components/Content/ContentDragDropUnsaved';
import RichTextContent from '../components/RichEditor/RichTextContent';

import { useIntercomProvider } from '../providers/intercom/intercom';
import { useIntercomCollectionItems } from '../providers/intercom';
import { cleanURL } from '../utils/url';
import { useAppProvider } from '../providers/app/app';
import {
  useIntercomArticleById,
  useIntercomArticlesInCollection,
  useIntercomSections,
} from '../hooks/intercom';
import { IntercomArticle, IntercomSection } from '../types/intercom';
import { getArticleEditLink } from '../utils/article';
import { useDragAndDrop } from '../hooks/global/useDragAndDrop';
import { IterableObject } from '../types/types';

type Props = {};

type SectionArticleSelected = {
  selected: IntercomSection | IntercomArticle;
  type: 'section' | 'article';
};

type UnsavedSectionArticle = {
  articles: IterableObject<boolean>;
  sections: IterableObject<boolean>;
};

const ContentCollectionDetail = (props: Props) => {
  const navigate = useNavigate();
  const { isDarkMode, toolbarHeight, subToolbarHeight } = useAppProvider();

  const sidebarPerfectScrollbarRef = useRef(null);

  const { pathname } = useLocation();
  const segments = pathname.split('/').filter((s) => !!s);
  const [, segment] = segments;

  const { articleId, collectionId } = useParams();
  const { collections, admins } = useIntercomProvider();
  const collectionItems = useIntercomCollectionItems({ collections, admins });
  const collection = useMemo(
    () => collectionItems.find((item) => item.id === collectionId),
    [collectionItems, collectionId],
  );

  const article = useIntercomArticleById(
    { id: articleId! },
    { enabled: segment === 'article' && !!articleId },
  );
  const articleData = article.data;

  const [isArticlesFirst, setIsArticlesFirst] = useState(true);
  const [sectionArticleSelected, setSectionArticleSelected] =
    useState<SectionArticleSelected | null>(null);
  const [articleSelected, setArticleSelected] =
    useState<IntercomArticle | null>(null);

  const [unsaved, setUnsaved] = useState<UnsavedSectionArticle>({
    articles: {},
    sections: {},
  });
  const hasUnsaved = useMemo(() => {
    return !![
      ...Object.values(unsaved.articles),
      ...Object.values(unsaved.sections),
    ].filter((v) => v).length;
  }, [unsaved]);

  const sectionsQuery = useIntercomSections({
    key: `content_section_${collectionId}`,
    parent_id: collectionId,
    omit: 'translated_content',
  });

  const articlesQuery = useIntercomArticlesInCollection({
    key: `content_articles_collection_${collectionId}`,
    collectionId,
  });

  const collectionHash = useIntercomCollectionDetail({
    collection: collection?.collection,
    sectionsQuery,
    articlesQuery,
  });

  /**
   * Ordered articles of current collection
   */
  const {
    items: orderedCollectionArticleItems,
    onDragEnd: collectionArticleItemsDragEnd,
  } = useDragAndDrop<IntercomArticle>({
    items: collectionHash.articles,
  });
  /**
   * Ordered sections of current collection
   */
  const {
    items: orderedCollectionSectionItems,
    onDragEnd: collectionSectionItemsDragEnd,
  } = useDragAndDrop<IntercomSection>({
    items: collectionHash.sections,
  });

  /**
   * Section article dictionary based by section,
   * container for a list of reordered article ids by section
   */
  const [sectionDictArticles, setSectionDictArticles] = useState<
    IterableObject<string[]>
  >({});
  /**
   * Ordered articles of selected section
   */
  const {
    items: orderedSectionArticleItems,
    onDragEnd: sectionArticleItemsDragEnd,
    setItemsIds: sectionArticleSetItemsIds,
  } = useDragAndDrop<IntercomArticle, string>({
    /**
     * Get the articles of the selected section
     */
    items:
      sectionArticleSelected?.type === 'section'
        ? (sectionArticleSelected.selected as IntercomSection).articles
        : [],
    onChange: ({ newItems }) => {
      /**
       * When articles in section (right sidebar) changes the order
       * cache the new order to preserve ordering
       */
      if (sectionArticleSelected?.type === 'section') {
        setSectionDictArticles((state) => ({
          ...state,
          [sectionArticleSelected.selected.id]: newItems.map((item) => item.id),
        }));
        setUnsaved((state) => ({
          ...state,
          sections: {
            ...state.sections,
            [sectionArticleSelected.selected.id]: true,
          },
        }));
      }
    },
  });

  const onMainDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { draggableId, source, destination } = result;
    // check if nothing changes in the order
    if (!destination || source.index === destination?.index) return null;
    // drag/drop on articles of collection
    if (source.droppableId === 'collection-articles') {
      collectionArticleItemsDragEnd(result, provided);
      setUnsaved((state) => ({
        ...state,
        articles: {
          ...state.articles,
          [draggableId]: true,
        },
      }));
    }
    // drag/drop on sections of collection
    else if (source.droppableId === 'collection-sections') {
      collectionSectionItemsDragEnd(result, provided);
      setUnsaved((state) => ({
        ...state,
        sections: {
          ...state.sections,
          [draggableId]: true,
        },
      }));
    }
    // drag/drop on articles of section
    else if (source.droppableId === 'section-articles') {
      sectionArticleItemsDragEnd(result, provided);
      setUnsaved((state) => ({
        ...state,
        articles: {
          ...state.articles,
          [draggableId]: true,
        },
      }));
    }
  };

  const onSaveCollection = () => {
    setUnsaved({
      articles: {},
      sections: {},
    });
  };

  const buildBreadcrumbItems = (): BreadcrumbItem[] => {
    const items: BreadcrumbItem[] = [];

    if (segment === 'article') {
      items.push({
        id: 'all',
        label: 'All Articles',
        path: `/content/article`,
      });
      items.push({
        id: articleId!,
        label: articleData ? articleData.title : `${articleId}`,
        path: `/content/article/${articleId}`,
      });
      items.push({
        id: collection ? collection.id : collectionId ?? '',
        label: collection ? collection.collection.name : collectionId ?? '',
        path: collection
          ? cleanURL(
              `/content/article/${articleId}/collection/${collection.id}`,
            )
          : '',
      });
    } else {
      items.push({
        id: 'all',
        label: 'All Collections',
        path: `/content/collection`,
      });
      items.push({
        id: collection ? collection.id : collectionId ?? '',
        label: collection ? collection.collection.name : collectionId ?? '',
        path: collection
          ? cleanURL(`/content/collection/${collection.id}`)
          : '',
      });
    }

    return items;
  };

  const buildBackButtonUrl = () => {
    if (segment === 'article') {
      return `/content/article/${articleId}`;
    } else {
      return '/content/collection';
    }
  };

  const renderHeadline = () => {
    return (
      <>
        <Stack direction='row' gap={2}>
          <Box
            className='collection__photo'
            style={{
              width: 100,
              marginTop: 10,
              position: 'static',
              alignItems: 'flex-start',
            }}
          >
            <Box
              style={{
                height: 48,
                width: 48,
              }}
            >
              {collection?.Icon && <collection.Icon />}
            </Box>
          </Box>
          <Box>
            <Typography
              component='div'
              fontSize={33}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)',
              }}
            >
              {collection?.collection.name}
            </Typography>
            {!!collection?.collection.description && (
              <Typography
                fontSize={16}
                sx={{
                  opacity: 0.8,
                  color: 'var(--md-sys-color-on-surface-light)',
                }}
                style={{
                  padding: '5px 0 0',
                }}
              >
                {collection?.collection.description}
              </Typography>
            )}
          </Box>
        </Stack>
      </>
    );
  };

  const renderArticle = (
    article: IntercomArticle,
    index: number,
    total: number,
    props: Partial<
      ContentItemBase<IntercomArticle> & {
        dragHandleProps?: DraggableProvidedDragHandleProps | null;
      }
    > = {},
  ) => {
    return (
      <Draggable
        key={article.id}
        index={index}
        draggableId={article.id}
        isDragDisabled={total <= 1}
      >
        {(provided, snapshot) => (
          <div
            key={article.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            className='content-article-draggable-wrapper'
          >
            <div
              style={{
                paddingBottom: 8,
                position: 'relative',
              }}
            >
              <ContentArticleItem
                withDescription={false}
                item={article}
                snapshot={snapshot}
                dragHandleProps={provided.dragHandleProps}
                sx={{ mb: 0 }}
                {...props}
              />
              {unsaved.articles[article.id] && (
                <ContentDragDropUnsaved title='This article has not been saved' />
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const renderArticlesInSections = () => {
    if (!orderedCollectionSectionItems.length) return null;

    return (
      <>
        <Typography component='h5' fontWeight={500} pb={1}>
          Sections
        </Typography>
        <Droppable droppableId='collection-sections' type='section'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Box>
                {orderedCollectionSectionItems.map((section, index) => {
                  return (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          key={section.id}
                          ref={provided.innerRef}
                          className='content-section-draggable-wrapper'
                          {...provided.draggableProps}
                        >
                          <div
                            style={{
                              paddingBottom: 8,
                              position: 'relative',
                            }}
                          >
                            <ContentSectionItem
                              item={section}
                              withLabel={false}
                              active={
                                section === sectionArticleSelected?.selected
                              }
                              dragHandleProps={provided.dragHandleProps}
                              snapshot={snapshot}
                              onClick={() => {
                                setArticleSelected(null);
                                setSectionArticleSelected({
                                  type: 'section',
                                  selected: section,
                                });
                                sectionArticleSetItemsIds(
                                  sectionDictArticles[section.id] ?? [],
                                );
                              }}
                              sx={{ mb: 0 }}
                            />
                            {unsaved.sections[section.id] && (
                              <ContentDragDropUnsaved title='This section has not been saved' />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            </div>
          )}
        </Droppable>
      </>
    );
  };

  const renderArticles = () => {
    if (!orderedCollectionArticleItems.length) return null;

    return (
      <Droppable droppableId='collection-articles' type='article'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Box
              sx={{
                pt: isArticlesFirst ? 0 : 3,
                pb: isArticlesFirst ? 3 : 0,
              }}
            >
              <Typography component='h5' fontWeight={500} pb={1}>
                Articles
              </Typography>
              <Box>
                {orderedCollectionArticleItems.map((article, index) =>
                  renderArticle(
                    article,
                    index,
                    orderedCollectionArticleItems.length,
                    {
                      draggable: true,
                      withLabel: false,
                      active: article === sectionArticleSelected?.selected,
                      onClick: () => {
                        setArticleSelected(article);
                        setSectionArticleSelected({
                          type: 'article',
                          selected: article,
                        });
                      },
                    },
                  ),
                )}
                {provided.placeholder}
              </Box>
            </Box>
          </div>
        )}
      </Droppable>
    );
  };

  const renderArticleSectionSwitch = () => {
    return (
      <Stack direction='row' alignItems='center' className='noselect'>
        <FormControlLabel
          control={
            <Checkbox
              checked={isArticlesFirst}
              onChange={(evt: SyntheticEvent) => {
                setIsArticlesFirst((evt.target as HTMLInputElement).checked);
              }}
            />
          }
          label={'Make article first'}
          sx={{
            '.MuiTypography-root': {
              fontSize: 14,
            },
          }}
        />
      </Stack>
    );
  };

  const renderActionSidebar = () => {
    const width = 400;
    const height = '100%';
    const rightSideSpace = 24;

    return (
      <>
        <Box sx={{ width }} />
        <Box
          sx={{
            top: 0,
            right: rightSideSpace,
            bottom: 0,
            position: 'fixed',
          }}
          style={{
            paddingTop: toolbarHeight + subToolbarHeight + rightSideSpace,
            paddingBottom: rightSideSpace,
          }}
        >
          <M3SurfaceContainer
            elevation={2}
            borderRadius={2}
            wrapperSx={{ height }}
            sx={{
              width: width - rightSideSpace,
              height,
            }}
          >
            {renderSectionArticlesInSidebar()}
            {renderArticleDetailInSidebar()}
          </M3SurfaceContainer>
        </Box>
      </>
    );
  };

  const renderActionSidebarHeadline = ({
    name,
    children,
    withBackButton = false,
    externalLink,
    editLink,
    isArticle,
    targetAudienceProps,
    onBackClick,
  }: {
    name: string;
    children: any;
    withBackButton?: boolean;
    externalLink?: string;
    editLink?: string;
    isArticle?: boolean;
    targetAudienceProps?: Partial<ContentTargetAudiencesProps>;
    onBackClick?: () => void;
  }) => {
    return (
      <>
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          position='relative'
          sx={{
            p: 3,
            pt: 2.5,
            pb: 0,
          }}
        >
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            {withBackButton && (
              <M3IconButton
                sx={{
                  top: 13,
                  left: 16,
                  position: 'absolute',
                }}
                onClick={onBackClick}
                className='m3-icon-button-back-detail-button'
              >
                <ArrowBackIcon />
              </M3IconButton>
            )}
            <Box flex={1}>
              <Typography
                pr={6}
                fontSize={21}
                fontWeight={500}
                lineHeight={1.2}
                style={{
                  color: isDarkMode
                    ? 'var(--md-ref-palette-primary80)'
                    : 'var(--md-ref-palette-primary40)',
                  paddingLeft: withBackButton ? 28 : 0,
                }}
              >
                {name}
              </Typography>
            </Box>
            {!isArticle &&
              sectionArticleSelected?.type === 'section' &&
              unsaved.sections[sectionArticleSelected.selected.id] && (
                <Box
                  sx={{
                    top: 35,
                    right: 13,
                    position: 'absolute',
                  }}
                >
                  <ContentDragDropUnsaved title='This section has not been saved' />
                </Box>
              )}
            {isArticle && (
              <Box
                sx={{
                  top: 13,
                  right: 16,
                  position: 'absolute',
                }}
              >
                <ContentArticleMenuOptions
                  article={articleSelected!}
                  paperStyle={{
                    transform: `translate3d(-16px, 0, 0)`,
                  }}
                />
              </Box>
            )}
          </Stack>
          <ContentTargetAudiences
            {...targetAudienceProps}
            withTitle={false}
            items={['Leads, Users and Visitors', 'Go2 Members | Internal']}
            sx={{
              pt: 3,
              pb: 0,
            }}
          />
          <Divider
            sx={{
              ml: -3,
              mr: -3,
              mt: 1,
              borderWidth: 1,
            }}
          />
          <Box
            flex={1}
            height={0}
            sx={{
              ml: -3,
              mr: -3,
            }}
          >
            <PerfectScrollbar onSetRef={sidebarPerfectScrollbarRef}>
              <Box sx={{ p: 3 }}>{children}</Box>
            </PerfectScrollbar>
          </Box>
        </Box>
      </>
    );
  };

  const renderArticleBody = (body: string) => {
    return (
      <Typography component='div'>
        <RichTextContent content={body} className='intercom-detail-body' />
      </Typography>
    );
  };

  const renderSectionArticlesInSidebar = () => {
    if (sectionArticleSelected?.type !== 'section') return null;

    const section = sectionArticleSelected.selected as IntercomSection;

    return renderActionSidebarHeadline({
      name: articleSelected ? articleSelected.title : section.name,
      withBackButton: !!articleSelected,
      externalLink: articleSelected?.url,
      isArticle: !!articleSelected,
      onBackClick: () => setArticleSelected(null),
      children: articleSelected ? (
        renderArticleBody(articleSelected.body)
      ) : (
        <Droppable droppableId='section-articles' type='article'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='content-sidebar-section-articles-wrapper'
            >
              {orderedSectionArticleItems.map((article, index) =>
                renderArticle(
                  article,
                  index,
                  orderedSectionArticleItems.length,
                  {
                    draggable: true,
                    withAudience: true,
                    audienceSmallVersion: true,
                    active: article === articleSelected,
                    onClick: () => {
                      setArticleSelected(article);
                    },
                  },
                ),
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ),
      targetAudienceProps: articleSelected
        ? {
            withAddButton: true,
            withCloseButton: true,
            articleId: articleSelected.id,
          }
        : undefined,
    });
  };

  const renderArticleDetailInSidebar = () => {
    if (sectionArticleSelected?.type !== 'article') return null;

    const article = sectionArticleSelected.selected as IntercomArticle;

    return renderActionSidebarHeadline({
      name: article.title,
      externalLink: article.url,
      isArticle: true,
      editLink: getArticleEditLink(article.id),
      children: renderArticleBody(article.body),
      targetAudienceProps: {
        withAddButton: true,
        withCloseButton: true,
        articleId: article.id,
      },
    });
  };

  /**
   * If there's no item selected yet upon mounted, choose the first
   * entry to be the default
   */
  useEffect(() => {
    if (
      !sectionArticleSelected &&
      !!(collectionHash.articles.length || collectionHash.sections.length)
    ) {
      let articleSelected: IntercomArticle | null = collectionHash.articles[0];
      let sectionSelected: IntercomSection | null = collectionHash.sections[0];

      if (
        (isArticlesFirst && articleSelected) ||
        (!sectionSelected && articleSelected)
      ) {
        setArticleSelected(articleSelected);
        setSectionArticleSelected({
          type: 'article',
          selected: articleSelected,
        });
      } else if (sectionSelected) {
        setSectionArticleSelected({
          type: 'section',
          selected: sectionSelected,
        });
      }
    }
  }, [isArticlesFirst, sectionArticleSelected, collectionHash]);

  return (
    <>
      <WindowScrollTop />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              navigate(buildBackButtonUrl());
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box
            flex={1}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <M3Button
              disabled={!hasUnsaved}
              color='primary'
              variant='contained'
              onClick={onSaveCollection}
            >
              <ModeEditOutlineOutlinedIcon />
              Save Collection
            </M3Button>
          </Box>
        </AppActionToolbar>
        <IntercomBreadcrumbs items={buildBreadcrumbItems()} />
        <DragDropContext onDragEnd={onMainDragEnd}>
          <Stack direction='row' justifyContent='stretch'>
            <Box flex={1}>
              <br />
              {renderHeadline()}
              <br />
              <ContentTargetAudiences
                items={['Leads, Users and Visitors', 'Go2 Members | Internal']}
              />
              {renderArticleSectionSwitch()}
              <br />
              {isArticlesFirst && renderArticles()}
              {renderArticlesInSections()}
              {!isArticlesFirst && renderArticles()}
            </Box>
            {renderActionSidebar()}
          </Stack>
        </DragDropContext>
        {/* {renderTabNavigation()} */}
        {/* {tabIndex === 0 && renderSectionTab()} */}
        {/* {tabIndex === 1 && renderArticleTab()} */}
      </MainContainer>
    </>
  );
};

export default ContentCollectionDetail;
