import React, { SyntheticEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { FormHelperText, Stack } from '@mui/material';

import BasicForm, { useForm } from '../../BasicForm';
import { M3TextField } from '../../M3/M3TextField';
import { M3Autocomplete } from '../../M3/M3Autocomplete';
import { M3Button } from '../../M3/M3Button';

import {
  UsePostUserProvisionPartnerMemberData,
  usePostUserProvisionPartnerMember,
} from '../../../hooks/user-provisioning';
import { useCircles } from '../../../hooks/circle';
import { useDebounce } from '../../../hooks/utils/misc';
import { usePartners } from '../../../hooks/partner';
import { usePagination } from '../../../hooks/global/usePagination';
import { useDivisionProvider } from '../../../providers/division/division';
import { getRawCodeMessage } from '../../../utils/response';
import { defaultListResult } from '../../../types/response';
import { sortBy } from '../../../utils/array';

type FormState = UsePostUserProvisionPartnerMemberData & {
  circle: string | undefined;
  division: string | undefined;
  partner: string | undefined;
};

type FieldSearchResponse = {
  id: number | string;
  name: string;
};

type PartnerMemberFormProps = {};

const PartnerMemberForm: React.FC<PartnerMemberFormProps> = () => {
  const { isFetching: isDivisionFetching, items: divisionItems } =
    useDivisionProvider();
  const { params } = usePagination(defaultListResult);

  const { enqueueSnackbar } = useSnackbar();
  const postPartnerMemberUser = usePostUserProvisionPartnerMember();
  const rawCodeMessage = getRawCodeMessage(postPartnerMemberUser.error);

  const {
    formKeyCount,
    formState,
    hasChanged,
    handleChange,
    handleSubmit,
    resetState,
  } = useForm<FormState>({
    circle: '',
    circle_id: null,
    division: '',
    division_id: null,
    email: '',
    first_name: '',
    job_title: '',
    last_name: '',
    partner_id: null,
    partner: '',
    phone_number: '',
  });

  const allRequiredAreFilled = !!(
    formState.circle_id &&
    formState.division_id &&
    formState.email &&
    formState.first_name &&
    formState.job_title &&
    formState.last_name &&
    formState.partner_id
  );

  const searchPartner = useDebounce(formState.partner!);
  const searchCircle = useDebounce(formState.circle!);

  const clearState = (name: string) => {
    // getting rid of the _id on the id names
    let targetName: string = name.slice(0, -3);

    handleChange({
      target: {
        name: targetName,
        value: '',
      },
    });
  };

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      if (!option) {
        clearState(name);
      }

      handleChange({
        target: {
          name,
          value: option ? (option as FieldSearchResponse).id : null,
        },
      });
    };

  const onSubmit = handleSubmit((data) => {
    if (postPartnerMemberUser.isLoading) return;

    delete data.circle;
    delete data.division;
    delete data.partner;

    if (!data.phone_number) {
      delete data.phone_number;
    }

    postPartnerMemberUser.mutate(data);
  });

  const { isFetching: isCirclesFetching, data: circles } = useCircles({
    ...params,
    limit: 1000,
    name: searchCircle,
  });

  const { isFetching: isPartnersFetching, data: partners } = usePartners({
    ...params,
    limit: 1000,
    status: 'active',
    name: searchPartner,
  });

  useEffect(() => {
    if (postPartnerMemberUser.isSuccess) {
      resetState();
      enqueueSnackbar(
        'Your submission for Partner/Member User has been successful.',
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postPartnerMemberUser.isSuccess]);

  return (
    <BasicForm onSubmit={onSubmit}>
      <Stack gap={2} mt={2}>
        <Stack direction='row' gap={2}>
          <M3TextField
            label='First name'
            name='first_name'
            fullWidth
            value={formState.first_name}
            onChange={handleChange}
          />
          <M3TextField
            label='Last name'
            name='last_name'
            fullWidth
            value={formState.last_name}
            onChange={handleChange}
          />
        </Stack>
        <Stack direction='row' gap={2}>
          <M3TextField
            label='Email'
            name='email'
            type='email'
            fullWidth
            value={formState.email}
            onChange={handleChange}
          />
          <M3TextField
            label='Job title'
            name='job_title'
            fullWidth
            value={formState.job_title}
            onChange={handleChange}
          />
        </Stack>
        <Stack direction='row' gap={2}>
          <M3TextField
            sx={{ width: '49%' }}
            label='Phone number'
            name='phone_number'
            value={formState.phone_number}
            onChange={handleChange}
          />
          <M3Autocomplete
            key={formKeyCount.circle}
            options={
              circles?.results
                ? sortBy(circles.results, 'name', true, 'ASC')
                : []
            }
            getOptionLabel={(option: any) =>
              (option as FieldSearchResponse).name ?? ''
            }
            loading={isCirclesFetching}
            onChange={onAutocompleteChangeHandler('circle_id')}
            renderInput={(params) => (
              <M3TextField
                {...params}
                name='circle'
                label='Circle name'
                fullWidth
                placeholder='Type circle name to search'
                value={formState.circle}
                onChange={handleChange}
              />
            )}
            sx={{
              flex: 1,
            }}
          />
        </Stack>
        <Stack direction='row' gap={2}>
          <M3Autocomplete
            key={formKeyCount.division}
            options={divisionItems ?? []}
            getOptionLabel={(option: any) =>
              (option as FieldSearchResponse).name ?? ''
            }
            loading={isDivisionFetching}
            onChange={onAutocompleteChangeHandler('division_id')}
            renderInput={(params) => (
              <M3TextField
                {...params}
                name='division'
                label='Division name'
                fullWidth
                value={formState.division}
                onChange={handleChange}
              />
            )}
            sx={{
              flex: 1,
            }}
          />
          <M3Autocomplete
            key={formKeyCount.partner}
            options={
              partners?.results
                ? sortBy(partners.results, 'name', true, 'ASC')
                : []
            }
            getOptionLabel={(option: any) =>
              (option as FieldSearchResponse).name ?? ''
            }
            loading={isPartnersFetching}
            onChange={onAutocompleteChangeHandler('partner_id')}
            renderInput={(params) => (
              <M3TextField
                {...params}
                name='partner'
                label='Partner name'
                fullWidth
                placeholder='Type partner name to search'
                value={formState.partner}
                onChange={handleChange}
              />
            )}
            sx={{
              flex: 1,
            }}
          />
        </Stack>
        {rawCodeMessage.error && (
          <FormHelperText error>{rawCodeMessage.message}</FormHelperText>
        )}
        <Stack direction='row'>
          <M3Button
            sx={{ minWidth: 110 }}
            color='primary'
            disabled={
              postPartnerMemberUser.isLoading || hasChanged
                ? !allRequiredAreFilled
                : true
            }
            type='submit'
            variant='contained'
          >
            Submit
          </M3Button>
        </Stack>
      </Stack>
    </BasicForm>
  );
};

export default PartnerMemberForm;
