import React, { PropsWithChildren, SyntheticEvent } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import ContentTargetAudiences from './ContentTargetAudiences';

import { CollectionItemProps } from '../../providers/intercom';
import { cleanURL } from '../../utils/url';
import { useAppProvider } from '../../providers/app/app';
import { ContentItemBase } from './Content';

type ContentCollectionItemProps = PropsWithChildren &
  ContentItemBase<CollectionItemProps> & {
    to: string;
    draggable?: boolean;
  };

export const ContentCollectionItem = ({
  to,
  item,
  draggable = true,
  dragHandleProps,
  dragDisabled,
  snapshot,
  sx,
}: ContentCollectionItemProps) => {
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();

  return (
    <M3Card
      sx={{
        marginBottom: '12px',
        ...sx,
      }}
      focused={snapshot?.isDragging}
      className='m3-card'
    >
      <M3SurfaceContainer elevation={1}>
        <Paper
          className='collection__paper'
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            position: 'relative',
          }}
          style={{
            marginTop: 0,
            paddingTop: 10,
            paddingBottom: 14,
            background: 'transparent',
          }}
          onClick={() => {
            navigate(cleanURL(to));
          }}
        >
          {draggable && (
            <div
              {...(dragDisabled
                ? {
                    onClick: (evt: SyntheticEvent) => evt.stopPropagation(),
                  }
                : dragHandleProps)}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: 32,
                  zIndex: 2,
                  position: 'absolute',
                  opacity: dragDisabled ? 0.3 : 1,
                  cursor: dragDisabled ? 'default' : undefined,
                }}
              >
                <DragIndicatorOutlinedIcon sx={{ ml: 0.5, mr: 0.5 }} />
              </Box>
            </div>
          )}
          <Box
            className='collection__photo'
            style={{ width: draggable ? 160 : 90 }}
          >
            <Box
              style={{
                height: 48,
                width: 48,
                margin: 'auto',
                marginLeft: draggable ? 'auto' : 28,
              }}
            >
              <item.Icon style={{ transform: 'scale(0.8)' }} />
            </Box>
          </Box>
          <Box
            flex={1}
            display='flex'
            alignItems='center'
            style={{
              paddingLeft: draggable ? 110 : 75,
            }}
          >
            <Box flex={2} pr={2} minWidth={200}>
              <Typography
                fontSize={22}
                style={{
                  color: isDarkMode
                    ? 'var(--md-ref-palette-primary80)'
                    : 'var(--md-ref-palette-primary40)',
                }}
              >
                {item.collection.name}
              </Typography>
              <ContentTargetAudiences
                withTitle={false}
                items={['Leads, Users and Visitors', 'Go2 Members | Internal']}
                sx={{
                  pt: 1,
                  pb: 0,
                }}
                chipSx={{
                  fontSize: 12,
                }}
              />
            </Box>
          </Box>
        </Paper>
      </M3SurfaceContainer>
    </M3Card>
  );
};

export default ContentCollectionItem;
