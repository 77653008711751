import { UseQueryOptions } from '@tanstack/react-query';
import { DailyQuoteResponse } from '../types/quote';
import {
  SODReportParams,
  EODReportParams,
  TeamManifestsResponse,
  DailyReportItemResponse,
  ReportingReportItemResponse,
} from '../types/report';
import { ListQuery } from '../types/request';
import { DateYYYYMMDD } from '../types/types';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

/**
 * Post an SOD or EOD report
 */
export function usePostReport<P = SODReportParams | EODReportParams>() {
  return useMutationApi<P>('/reporting/v2/reports/');
}

type UseGetReportParams = {
  id: number;
};
export function useReportingReport<T>(
  params: UseGetReportParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseGetReportParams>({
    params,
    baseIdentifier: 'useReportingReport',
    identifierKeys: ['id'],
  });

  return useQueryApi<UseGetReportParams, ReportingReportItemResponse<T>>(
    identifiers,
    `/reporting/v2/reports/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseTeamManifestsParams = ListQuery & {
  divisions?: string | number;
  date_start: DateYYYYMMDD;
  date_end: DateYYYYMMDD;
  members?: string;
  help_needed?: 'yes' | 'no';
};
export function useTeamManifests<R = TeamManifestsResponse>(
  params: UseTeamManifestsParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseTeamManifestsParams>({
    params,
    baseIdentifier: 'useTeamManifests',
    identifierKeys: [
      'date_start',
      'date_end',
      'divisions',
      'members',
      'help_needed',
    ],
    queryParamKeys: [
      'date_start',
      'date_end',
      'divisions',
      'members',
      'help_needed',
    ],
  });

  return useQueryApi<UseTeamManifestsParams, R>(
    identifiers,
    '/reporting/v2/reports/team-manifests-v2/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseTeamManifestDailyReportByIdParams = {
  id?: string | number | null;
};
export function useTeamManifestDailyReportById<R = DailyReportItemResponse>(
  params: UseTeamManifestDailyReportByIdParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseTeamManifestDailyReportByIdParams>({
      params,
      baseIdentifier: 'useTeamManifestDailyReportById',
      identifierKeys: ['id'],
    });

  return useQueryApi<UseTeamManifestDailyReportByIdParams, R>(
    identifiers,
    `/reporting/v2/reports/${params.id}/team-manifests-v2/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export function useDailyReportQuoteToday<R = DailyQuoteResponse>(
  params?: any,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<any>({
    params,
    baseIdentifier: 'useDailyReportQuoteToday',
  });

  return useQueryApi<any, R>(
    identifiers,
    `/reporting/v2/quotes/today/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
