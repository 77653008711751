import React from 'react';

import SettingsPersonal from './SettingsPersonal';
import SettingsTeam from './SettingsTeam';
import SettingsAdmin from './SettingsAdmin';
import SystemAdministrator from './SystemAdministrator';
import MainContainer from '../components/MainContainer';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';

import { usePathnameSegments } from '../hooks/utils/url';

type SettingsProps = {};

export type SettingsDrawerSegments =
  | 'personal'
  | 'team'
  | 'admin'
  | 'system-admin';

const Settings = (props: SettingsProps) => {
  const [, segment] = usePathnameSegments<SettingsDrawerSegments[]>();

  if (segment === 'personal') {
    return <SettingsPersonal />;
  }

  if (segment === 'team') {
    return <SettingsTeam />;
  }

  if (segment === 'admin') {
    return <SettingsAdmin />;
  }

  if (segment === 'system-admin') {
    return <SystemAdministrator />;
  }

  return (
    <MainContainer>
      <ErrorUnauthorizedAccess />
    </MainContainer>
  );
};

export default Settings;
