import React from 'react';
import { Navigate } from 'react-router-dom';

import SettingsTeamJobs from './SettingsTeamJobs';
import SettingsTeamFocusAreas from './SettingsTeamFocusAreas';

import { usePathnameSegments } from '../hooks/utils/url';

type SettingsTeamProps = {};

export type SettingsTeamSegments = 'jobs' | 'focus';

const SettingsTeam = (props: SettingsTeamProps) => {
  const [, , nextSegment] = usePathnameSegments<SettingsTeamSegments[]>();

  if (nextSegment === 'jobs') {
    return <SettingsTeamJobs />;
  }

  if (nextSegment === 'focus') {
    return <SettingsTeamFocusAreas />;
  }

  return <Navigate to='/settings/team/jobs' />;
};

export default SettingsTeam;
