import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Box, Modal, Stack, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

import MainContainer from '../components/MainContainer';
import RichEditor from '../components/RichEditor/RichEditor';
import BasicForm, { useForm } from '../components/BasicForm';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import AppActionToolbar from '../components/AppActionToolbar';
import FocusTitleForm from '../components/TeamSettings/Focus/FocusTitleForm';
import WindowScrollTop from '../components/WindowScrollTop';
import DocumentTitle from '../components/DocumentTitle';
import PageWait from '../components/PageWait';
import { useHasViewPermission } from '../components/HasViewPermission';
import AIAssistSideSheet, {
  useAIAssistSideSheet,
} from '../components/SideSheet/AIAssistSideSheet';
import { M3TabButton } from '../components/M3/M3TabButton';
import FocusJobsProjects from '../components/TeamSettings/Focus/FocusJobsProjects';
import FocusQuestionItem from '../components/TeamSettings/Focus/FocusQuestionItem';
import FocusQuestionFormModalView from '../components/TeamSettings/Focus/FocusQuestionFormModalView';

import { ReactRenderElement, ValueOf } from '../types/types';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { stringToKey } from '../utils/string';
import { EODFieldParams } from '../types/report';
import { UsePostTimeTrackerTaskData } from '../hooks/time-tracker';
import { useAuthProvider } from '../providers/auth/auth';
import { request as cosmosRequest } from '../services/cosmos';
import { getConfigWithAuthorization } from '../services/base';
import { useCurrentProfile } from '../hooks/profile';
import { TimeTrackerTaskItem } from '../types/time-tracker';
import { useFormRequestStatus } from '../hooks/global/useFormRequestStatus';
import { useAppProvider } from '../providers/app/app';
import { FocusEODFormPayload } from '../hooks/eod-forms';
import { BreadcrumbItem } from '../components/Intercom/IntercomBreadcrumbs';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../components/Dialogs/ConfirmationDialog';

type SettingsTeamFocusCreateFocusProps = {
  routeKey: 'builder' | 'team';
};

type TaskFocusFormState = {
  title: string;
  overview: string;
  desired_outcome: string;
  success_measurement: string;
  jobs: { id: number; title: string }[];
};

const SettingsTeamFocusCreateFocus = ({
  routeKey,
}: SettingsTeamFocusCreateFocusProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { items: workspaces, current: currentWorkspace } =
    useWorkspaceProvider();
  const { getTokenSilently } = useAuthProvider();
  const { isDarkMode } = useAppProvider();

  const { data: currentProfile } = useCurrentProfile();

  const [tabIndex, setTabIndex] = useState(0);
  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);
  const [, setFormRequestStatus] = useFormRequestStatus();

  const [eodQuestions, setEodQuestions] = useState<EODFieldParams[]>([]);
  const [filterQuestionType] = useState<EODFieldParams['question_type'] | null>(
    null,
  );
  const [questionToBeDeleted, setQuestionTobeDeleted] = useState<string>('');

  const filteredEodQuestions = useMemo(() => {
    return filterQuestionType
      ? eodQuestions.filter((q) => q.question_type === filterQuestionType)
      : eodQuestions;
  }, [eodQuestions, filterQuestionType]);

  const onTabIndexHandleChange = (evt: SyntheticEvent, index: number) => {
    setTabIndex(index);

    if (index !== 0) {
      aiAssist.close();
    }
  };

  // When creating a new focus, set the division ids to all assigned to user
  const divisionId =
    currentWorkspace?.id === 'my-squad'
      ? workspaces
          .filter((ws) => ws.type === 'division_partner')
          .map((w) => w.id)
          .join(',')
      : currentWorkspace?.id.toString();

  const { formState, handleChange, handleSubmit } = useForm<TaskFocusFormState>(
    {
      title: '',
      overview: '',
      desired_outcome: '',
      success_measurement: '',
      jobs: [],
    },
  );

  const isPromptEngineer = useHasViewPermission({
    roles: ['superuser', 'prompt_engineer'],
  });
  const aiAssist = useAIAssistSideSheet();

  const disableSubmit = !(
    formState.title &&
    formState.overview &&
    formState.desired_outcome &&
    formState.success_measurement &&
    formState.jobs.length
  );

  const onEditorValueChange = (name: keyof TaskFocusFormState) => {
    return (value: ValueOf<TaskFocusFormState>) => {
      handleChange({
        target: {
          name,
          value,
        },
      });
    };
  };

  const onSubmit = handleSubmit(async (data: TaskFocusFormState) => {
    try {
      setFormRequestStatus({
        status: 'processing',
        error: null,
      });

      const createdBy = currentProfile!.id;
      const taskFocusPayload: UsePostTimeTrackerTaskData = {
        name: data.title,
        // is_generic: boolean,
        // generic_to: 0,
        created_by: createdBy,
      };

      /**
       * Let's create the focus first
       */
      const taskFocusResult: TimeTrackerTaskItem = await cosmosRequest.post(
        '/api/time-tracker/tasks/',
        taskFocusPayload,
        getConfigWithAuthorization(await getTokenSilently()),
      );

      /**
       * check if there are more jobs that we need to associate to this focus
       */
      if (data.jobs.length) {
        try {
          const config = getConfigWithAuthorization(await getTokenSilently());
          await Promise.all(
            data.jobs.map((job) =>
              cosmosRequest.post(
                `/api/hr/job-descriptions/${job.id}/add-focuses/`,
                {
                  focus_ids: [taskFocusResult.id],
                },
                config,
              ),
            ),
          );
        } catch (e) {
          Sentry.captureException(e);
        }
      }

      /**
       * After we create the focus, let's create the eod form that we will
       * attach on later to the task/focus
       */
      const eodFormPayload: Partial<FocusEODFormPayload> = {
        fields: eodQuestions.map((q) => ({ ...q })),
        tasks: [taskFocusResult.id],
        // TODO: Need to attach the emoji here...
        // "emoji": {
        //   "code": "string",
        //   "url": nu
        // },
        name: data.title,
        overview: data.overview,
        desired_outcome: data.desired_outcome,
        success_measurement: data.success_measurement,
      };
      await cosmosRequest.post(
        '/api/forms/eod-forms/',
        eodFormPayload,
        getConfigWithAuthorization(await getTokenSilently()),
      );

      setFormRequestStatus({
        status: 'success',
        error: null,
      });

      navigate(`${getRoutes().basePath}/${taskFocusResult.id}`, {
        replace: true,
      });
      enqueueSnackbar('New focus has been created!');
    } catch (e) {
      setFormRequestStatus({
        status: 'error',
        error: e as Error,
      });
    }
  });

  const handleOnJobsChanged = useCallback(
    (jobs: { id: number; title: string }[]) => {
      handleChange({
        target: {
          name: 'jobs',
          value: [...jobs],
        },
      });
    },
    // eslint-disable-next-line
    [],
  );
  const confirmationDialog = useConfirmationDialog();

  const onDeleteQuestion = (id: string) => {
    setQuestionTobeDeleted(id);
    confirmationDialog.confirm.setIsOpen(true);
  };

  const onConfirmDeleteQuestion = () => {
    const items = eodQuestions.filter(
      (question) => questionToBeDeleted !== question.id,
    );
    setEodQuestions(items);
    setQuestionTobeDeleted('');
    confirmationDialog.confirm.setIsOpen(false);
  };

  const getRoutes = () => {
    let breadCrumbs: BreadcrumbItem[] = [];
    let basePath = '';

    if (routeKey === 'builder') {
      basePath = `/builder/focus`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Focus',
          path: basePath,
        },
        {
          id: 'create',
          label: 'Create A Focus',
          path: `${basePath}/create`,
        },
      ];
    } else if (routeKey === 'team') {
    }

    return {
      breadCrumbs,
      basePath,
    };
  };

  const handleOnQuestionSave = (
    data: EODFieldParams,
    fields: EODFieldParams[],
  ) => {
    setEodQuestions((state) => {
      const index = state.findIndex((q) => q.id === data.id);
      state = [...state];

      if (index === -1) {
        state.push(data);
      } else {
        state[index] = { ...state[index], ...data };
      }

      return state;
    });
  };

  const renderRichEditor = ({
    icon,
    name,
    value,
    title,
    subtitle,
    placeholder,
  }: {
    icon: ReactRenderElement;
    name: keyof TaskFocusFormState;
    value: ValueOf<TaskFocusFormState>;
    title?: ReactRenderElement;
    subtitle?: ReactRenderElement;
    placeholder?: string;
  }) => {
    let promptIdentifier = stringToKey(`task-focus-${name}`, '-');

    return (
      <Box mb={5}>
        {!!title && (
          <Typography
            display='flex'
            alignItems='center'
            component='div'
            fontSize={22}
          >
            <Box position='relative' top={4} pr={1}>
              {icon}
            </Box>
            {title}
          </Typography>
        )}
        {!!subtitle && (
          <Typography component='div' sx={{ opacity: 0.5 }}>
            {subtitle}
          </Typography>
        )}
        <RichEditor
          withAIAssist
          withAIEditPrompt={isPromptEngineer}
          withMentions={false}
          value={value as string}
          onValueChange={onEditorValueChange(name)}
          placeholder={placeholder ?? 'Write here...'}
          sx={{
            marginTop: 2,
          }}
          chatGPTPlaygroundProps={{
            title: title as string,
            prompt_identifier: promptIdentifier,
          }}
          onAssistClick={() => {
            aiAssist?.open();
            aiAssist?.start({
              title: title as string,
              prompt_identifier: promptIdentifier,
              data: {
                prompt_text: value as string,
              },
            });
          }}
        />
      </Box>
    );
  };

  const renderFocusItemDetailForm = () => {
    return (
      <>
        <Stack
          gap={2}
          direction='row'
          justifyContent='space-between'
          pl={1}
          pt={0.5}
        >
          <Box flex={1} maxWidth='10%'>
            <FocusTitleForm
              editable
              withSave={false}
              onChange={(value: string) => {
                handleChange({
                  target: {
                    name: 'title',
                    value,
                  },
                });
              }}
            />
          </Box>
          {/* <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-end'
            justifyContent='flex-start'
          >
            <FocusEmojiSelector />
            <FocusJobsMetadata />
          </Box> */}
        </Stack>
      </>
    );
  };

  const renderFocusForm = () => {
    return (
      <>
        <BasicForm onSubmit={onSubmit} disabled={disableSubmit}>
          {renderRichEditor({
            icon: <BookmarkBorderOutlinedIcon />,
            name: 'overview',
            value: formState.overview,
            title: 'Overview:',
            subtitle:
              'A high-level summary or description of the focus area. Provide context for the desired outcome and measurement of success.',
          })}
          {renderRichEditor({
            icon: <PlaylistAddCheckOutlinedIcon />,
            name: 'desired_outcome',
            value: formState.desired_outcome,
            title: 'Desired Outcome:',
            subtitle:
              'Specify the specific goal or outcome you want to achieve with the focus. Be clear and concise, outlining what success looks like.',
          })}
          {renderRichEditor({
            icon: <ExtensionOutlinedIcon />,
            name: 'success_measurement',
            value: formState.success_measurement,
            title: 'Measurement of Success:',
            subtitle:
              'Define how you will measure the success or progress of the focus. It could include specific metrics, key performance indicators (KPIs), or any other measurable criteria.',
          })}
          <Stack direction='row' justifyContent='space-between'>
            <M3Button
              color='primary'
              variant='contained'
              onClick={onSubmit}
              disabled={disableSubmit}
              sx={{ minWidth: 110 }}
            >
              Submit
            </M3Button>
          </Stack>
        </BasicForm>
      </>
    );
  };

  const renderJobsProjectsForm = () => {
    return (
      <Box>
        <FocusJobsProjects
          isNew
          editable
          jobs={formState.jobs}
          divisionId={divisionId}
          onJobsChanged={handleOnJobsChanged}
        />
      </Box>
    );
  };

  const renderQuestions = () => {
    return (
      <Box pb={2} mt={-6}>
        <Stack direction='row' justifyContent='space-between'>
          <Box />
          <M3Button
            color='secondary'
            variant='outlined'
            onClick={() => {
              setTabIndex(1);
              setIsQuestionFormOpen(true);
            }}
          >
            + Add Question
          </M3Button>
        </Stack>
        {!eodQuestions.length && (
          <PageWait
            title='No questions'
            message={`This focus doesn't have a question`}
            icon={
              <QuestionAnswerOutlinedIcon
                sx={{
                  mb: 2,
                  fontSize: 80,
                  opacity: isDarkMode ? 0.1 : 0.05,
                }}
              />
            }
            sx={{
              paddingTop: '8vh',
            }}
          />
        )}
        {filteredEodQuestions.map((question, index) => (
          <FocusQuestionItem
            key={index}
            item={question}
            editable
            questions={eodQuestions}
            onSave={handleOnQuestionSave}
            onDeleteQuestion={onDeleteQuestion}
          />
        ))}
      </Box>
    );
  };

  const renderTabs = () => {
    return (
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Tabs
          value={tabIndex}
          onChange={onTabIndexHandleChange}
          sx={{
            ml: -1,
            height: 32,
            minHeight: 32,
            paddingTop: 0,
          }}
        >
          <M3TabButton size='small' disableRipple label='Overview' />
          <M3TabButton
            disableRipple
            size='small'
            label={`Questions (${eodQuestions.length})`}
          />
          <M3TabButton
            disableRipple
            size='small'
            label={`Jobs (${formState.jobs.length})`}
          />
        </Tabs>
      </Box>
    );
  };

  return (
    <>
      <DocumentTitle
        title='Create a Focus'
        trailingTitle='Focus | Team Settings'
      />
      <AIAssistSideSheet
        inPage
        withToolbar
        withSubToolbar
        title={aiAssist.title}
        parentSelector='body'
        containerSelector='body'
        headerSx={{
          height: 'initial',
        }}
        offsetTop={28}
        aiAssist={aiAssist}
        onClose={aiAssist.close}
      >
        <MainContainer flex sx={{ maxWidth: null }}>
          <AppActionToolbar
            secondaryHeight={92}
            sx={{
              pt: 1,
              alignItems: 'flex-start',
            }}
          >
            <M3IconButton
              onClick={() => {
                navigate(getRoutes().basePath);
              }}
              className='m3-icon-button-back-detail-button'
            >
              <ArrowBackIcon />
            </M3IconButton>
            <Box flex={1} pl={1}>
              {renderFocusItemDetailForm()}
              {renderTabs()}
            </Box>
          </AppActionToolbar>
          <Typography
            mb={4}
            mt={-1}
            component='div'
            fontSize={13}
            style={{
              opacity: 0.5,
              color: isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          >
            Note: Focus must be associated with at least one job
          </Typography>
          {tabIndex === 0 && renderFocusForm()}
          {tabIndex === 1 && renderQuestions()}
          {tabIndex === 2 && renderJobsProjectsForm()}
        </MainContainer>
      </AIAssistSideSheet>
      <WindowScrollTop />
      <Modal open={isQuestionFormOpen}>
        <FocusQuestionFormModalView
          questions={eodQuestions}
          close={() => {
            setIsQuestionFormOpen(false);
          }}
          onSave={handleOnQuestionSave}
        />
      </Modal>
      <ConfirmationDialog
        {...confirmationDialog.confirm}
        title='Delete Question'
        message={
          <>
            Are you sure you want to delete this question?
            <br />
            This action cannot be undone and all associated data will be
            permanently lost.
          </>
        }
        onConfirm={onConfirmDeleteQuestion}
      />
    </>
  );
};

export default SettingsTeamFocusCreateFocus;
