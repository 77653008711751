import React from 'react';
import { AvatarGroup, Box, Paper, Skeleton } from '@mui/material';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';

type Props = {};

const IntercomCollectionSkeleton = (props: Props) => {
  return (
    <M3SurfaceContainer elevation={1} sx={{ borderRadius: '12px' }}>
      <Paper className='collection__paper'>
        <Skeleton variant='text' sx={{ fontSize: 18, maxWidth: 630 }} />
        <Skeleton
          variant='text'
          sx={{ fontSize: 16 }}
          style={{
            maxWidth: 630,
            margin: '5px 0 11px',
          }}
        />
        <Box display='flex' justifyContent='flex-start' alignItems='flex-start'>
          <AvatarGroup max={2} style={{ paddingRight: 10 }}>
            <Skeleton variant='circular' width={36} height={36} />
          </AvatarGroup>
          <Box flex={1} sx={{ maxWidth: 200 }}>
            <Skeleton variant='text' sx={{ fontSize: 13 }} />
            <Skeleton variant='text' sx={{ fontSize: 13 }} />
          </Box>
        </Box>
      </Paper>
    </M3SurfaceContainer>
  );
};

export default IntercomCollectionSkeleton;
