import React from 'react';
import { Box, Chip, SxProps } from '@mui/material';

import { IterableObject } from '../../types/types';

type EODDivisionChipProps = {
  label: string;
  sx?: SxProps;
  wrapperSx?: SxProps;
};

const EODDivisionChip = ({ label, sx, wrapperSx }: EODDivisionChipProps) => {
  const muiChipLabel = ((sx ?? {}) as IterableObject)['.MuiChip-label'];

  return (
    <Box
      sx={{
        lineHeight: 1,
        mt: -0.2,
        ...wrapperSx,
      }}
    >
      <Chip
        label={label}
        size='small'
        sx={{
          opacity: 0.8,
          borderRadius: 1,
          height: 'initial',
          ...sx,
          '.MuiChip-label': {
            p: 0.5,
            pl: 1,
            pr: 1,
            height: 'initial',
            fontSize: 12,
            fontWeight: 500,
            ...muiChipLabel,
          },
        }}
      />
    </Box>
  );
};

export default EODDivisionChip;
