import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Modal,
  Stack,
  Tabs,
  // Tooltip,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
// import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import AppActionToolbar from '../../AppActionToolbar';
import RichEditor from '../../RichEditor/RichEditor';
import { useForm } from '../../BasicForm';
import { M3Button, M3IconButton } from '../../M3/M3Button';
import RichTextContent from '../../RichEditor/RichTextContent';
import FocusTitleForm from './FocusTitleForm';
import FocusDetailSkeleton from './FocusDetailSkeleton';
import Error from '../../../pages/Error';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';
import FocusJobsProjects from './FocusJobsProjects';
import { M3TabButton } from '../../M3/M3TabButton';
import FocusQuestionItem from './FocusQuestionItem';
import PageWait from '../../PageWait';
import FocusQuestionFormModalView from './FocusQuestionFormModalView';
import { BreadcrumbItem } from '../../Intercom/IntercomBreadcrumbs';

import { ReactRenderElement, ValueOf } from '../../../types/types';
import { useAppProvider } from '../../../providers/app/app';
import { useHasViewPermission } from '../../HasViewPermission';
import { UseAIAssistSideSheetRet } from '../../SideSheet/AIAssistSideSheet';
import { stringToKey } from '../../../utils/string';
import { useTimeTrackerTaskById } from '../../../hooks/time-tracker';
import { EODFieldParams } from '../../../types/report';
import { sortBy } from '../../../utils/array';
import { transformEODFieldParams } from '../../../utils/task';
import { useAuthProvider } from '../../../providers/auth/auth';
import { getConfigWithAuthorization } from '../../../services/base';
import { request as cosmosRequest } from '../../../services/cosmos';
import { useWorkspaceProvider } from '../../../providers/workspace/workspace';
import {
  FocusEODFormPayload,
  usePatchEODForms,
} from '../../../hooks/eod-forms';
import { TimeTrackerTaskItemDetail } from '../../../types/time-tracker';
import { useFormRequestStatus } from '../../../hooks/global/useFormRequestStatus';
import {
  useDeleteFocus,
  useDeleteQuestion,
  useSetGeneric,
} from '../../../hooks/focus';

type FocusItemDetailProps = {
  taskFocusId?: string | number | null;
  editable?: boolean;
  userView?: boolean;
  withDelete?: boolean;
  aiAssist?: UseAIAssistSideSheetRet;
  routeKey: 'builder' | 'team';
};

type FocusItemState = {
  title: string;
  division?: string | number;
  overview: string;
  desired_outcome: string;
  success_measurement: string;
};

type IsFormOpen = {
  overview: boolean;
  desired_outcome: boolean;
  success_measurement: boolean;
};

const actionButtonStyle = {
  height: 25,
  lineHeight: 1,
  fontSize: 13,
  width: 'initial',
  minWidth: 'initial',
  padding: '0 14px',
};

const FocusItemDetail = ({
  taskFocusId,
  editable = false,
  userView,
  withDelete,
  aiAssist,
  routeKey,
}: FocusItemDetailProps) => {
  const navigate = useNavigate();

  const { isDarkMode } = useAppProvider();
  const { enqueueSnackbar } = useSnackbar();
  const { getTokenSilently } = useAuthProvider();
  const { items: workspaces, current: currentWorkspace } =
    useWorkspaceProvider();

  const [tabIndex, setTabIndex] = useState(0);
  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);
  const [filterQuestionType] = useState<EODFieldParams['question_type'] | null>(
    null,
  );
  const [questionToBeDeleted, setQuestionToBeDeleted] = useState<string>('');

  const [isFormOpen, setIsFormOpen] = useState<IsFormOpen>({
    overview: false,
    desired_outcome: false,
    success_measurement: false,
  });

  const timeTrackerTaskFocus = useTimeTrackerTaskById(
    { id: taskFocusId! },
    { enabled: !!taskFocusId },
  );
  const deleteFocusItem = useDeleteFocus({
    id: taskFocusId!,
  });
  const taskFocusData = timeTrackerTaskFocus.data;
  const eodFormData = taskFocusData?.eod_form;

  const setGeneric = useSetGeneric({
    id: taskFocusId!,
  });

  const deleteQuestion = useDeleteQuestion({
    id: eodFormData?.id!,
  });

  const timeTrackerData = useTimeTrackerTaskById({ id: taskFocusId! });
  const isFocusActive = timeTrackerData.data?.is_active;
  const isFocusGeneric = timeTrackerData.data?.is_generic;

  // Only show the generic switcher to internal
  const hasPermission = useHasViewPermission({
    roles: ['superuser', 'developer', 'internal'],
  });
  // eslint-disable-next-line
  const showGenericSwitch = editable && hasPermission;

  // When creating a new focus, set the division ids to all assigned to user
  const divisionId = workspaces
    .filter((ws) => ws.type === 'division_partner')
    .map((w) => w.id)
    .join(',');

  let eodQuestions: EODFieldParams[] = useMemo(() => {
    let questions: EODFieldParams[] = eodFormData?.fields ?? [];
    questions = sortBy<EODFieldParams>(questions, 'priority', true, 'ASC');
    return questions.map(transformEODFieldParams);
  }, [eodFormData]);

  const filteredEodQuestions = useMemo(() => {
    return filterQuestionType
      ? eodQuestions.filter((q) => q.question_type === filterQuestionType)
      : eodQuestions;
  }, [eodQuestions, filterQuestionType]);

  const {
    formState,
    handleChange,
    updateState: updateFormState,
  } = useForm<FocusItemState>({
    title: taskFocusData?.name ?? '',
    overview: eodFormData?.overview ?? '',
    desired_outcome: eodFormData?.desired_outcome ?? '',
    success_measurement: eodFormData?.success_measurement ?? '',
  });

  const confirmationDialog = useConfirmationDialog();
  const confirmationDialogQ = useConfirmationDialog();
  const confirmationDialogGeneric = useConfirmationDialog();

  const handleOnJobsChanged = useCallback(
    async (
      jobs: { id: number; title: string }[],
      job?: { id: number; title: string },
      action?: 'add' | 'remove',
    ) => {
      const payload = {
        focus_ids: [taskFocusId!],
      };
      try {
        if (action === 'add') {
          await cosmosRequest.post(
            `/api/hr/job-descriptions/${job!.id}/add-focuses/`,
            payload,
            getConfigWithAuthorization(await getTokenSilently()),
          );
        } else if (action === 'remove') {
          await cosmosRequest.post(
            `/api/hr/job-descriptions/${job!.id}/remove-focuses/`,
            payload,
            getConfigWithAuthorization(await getTokenSilently()),
          );
        }
        timeTrackerTaskFocus.refetch();
      } catch (e) {}
    },
    // eslint-disable-next-line
    [getTokenSilently, taskFocusId],
  );

  const getRoutes = () => {
    let breadCrumbs: BreadcrumbItem[] = [];
    let basePath = '';

    if (routeKey === 'builder') {
      basePath = `/builder/focus`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Focus',
          path: basePath,
        },
        {
          id: taskFocusId?.toString()!,
          label: taskFocusData?.name ?? taskFocusId?.toString(),
          path: `${basePath}/${taskFocusId}`,
        },
      ];
    } else if (routeKey === 'team') {
      basePath = `/team/focus/${currentWorkspace?.id}`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Focus',
          path: basePath,
        },
        {
          id: taskFocusId?.toString()!,
          label: taskFocusData?.name ?? taskFocusId?.toString(),
          path: `${basePath}/${taskFocusId}`,
        },
      ];
    }

    return {
      breadCrumbs,
      basePath,
    };
  };

  const onFocusItemUpdated = () => {
    timeTrackerTaskFocus.refetch();
    enqueueSnackbar('Focus has been updated!');
  };

  const onTabIndexHandleChange = (evt: SyntheticEvent, index: number) =>
    setTabIndex(index);

  const openForm = (...args: (keyof IsFormOpen)[]) => {
    setIsFormOpen((state) => {
      state = { ...state };
      args.forEach((key) => (state[key] = true));
      return state;
    });
  };

  const closeForm = (...args: (keyof IsFormOpen)[]) => {
    setIsFormOpen((state) => {
      state = { ...state };
      args.forEach((key) => (state[key] = false));
      return state;
    });
  };

  const handleOnQuestionSave = (
    data: EODFieldParams,
    fields: EODFieldParams[],
  ) => {
    onFocusItemUpdated();
  };

  const toggleFocusActive = () => {
    if (isFocusActive) {
      confirmationDialog.confirm.setIsOpen(true);
    } else {
      deleteFocusItem.mutate({ is_active: !isFocusActive });
    }
  };

  // eslint-disable-next-line
  const toggleGenericActive = () => {
    if (isFocusGeneric) {
      confirmationDialogGeneric.confirm.setIsOpen(true);
    } else {
      setGeneric.mutate({ is_generic: !isFocusGeneric });
    }
  };

  const onDeleteQuestion = (id: string) => {
    setQuestionToBeDeleted(id);
    confirmationDialogQ.confirm.setIsOpen(true);
  };

  const confirmDeleteQuestion = () => {
    const newFields = eodFormData?.fields.filter(
      (field) => field.id !== questionToBeDeleted,
    );
    deleteQuestion.mutate({ fields: newFields });
  };

  const renderRichEditor = ({
    name,
    ...props
  }: Omit<TaskFocusRichTextEditorProps, 'id'>) => {
    return (
      <TaskFocusRichTextEditor
        {...props}
        name={name}
        taskFocusData={taskFocusData}
        id={eodFormData?.id}
        aiAssist={aiAssist}
        onSave={(value: string) => {
          handleChange({ target: { name, value } });
          onFocusItemUpdated();
          closeForm(name as keyof IsFormOpen);
        }}
        onCancel={() => {
          closeForm(name as keyof IsFormOpen);
        }}
      />
    );
  };

  const renderRichDisplay = ({
    icon,
    name,
    content,
    title,
    withBackground,
  }: {
    icon: ReactRenderElement;
    name: keyof IsFormOpen;
    content: string;
    title?: ReactRenderElement;
    withBackground?: boolean;
  }) => {
    return (
      <Box mb={5}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography
            display='flex'
            alignItems='center'
            component='div'
            fontSize={22}
            minHeight={40}
          >
            <Box position='relative' top={4} pr={1}>
              {icon}
            </Box>
            {title}
          </Typography>
          <Stack
            gap={1}
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
          >
            {/* <Typography
              component='div'
              fontSize={14}
              sx={{
                opacity: 0.5,
              }}
            >
              0 Comment
            </Typography>
            <M3IconButton
              sx={{
                minWidth: 40,
                minHeight: 40,
              }}
            >
              <CommentOutlinedIcon sx={{ fontSize: 20 }} />
            </M3IconButton> */}
            {editable && (
              <M3IconButton
                sx={{
                  minWidth: 40,
                  minHeight: 40,
                }}
                onClick={() => openForm(name)}
              >
                <EditOutlinedIcon sx={{ fontSize: 20 }} />
              </M3IconButton>
            )}
          </Stack>
        </Stack>
        <Typography component='div'>
          {withBackground ? (
            <Typography
              component='div'
              sx={{
                p: 3,
                mt: 1,
                borderRadius: 1,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              <RichTextContent content={content} />
            </Typography>
          ) : (
            <RichTextContent content={content} />
          )}
        </Typography>
      </Box>
    );
  };

  const renderFocusItemDetailForm = () => {
    return (
      <>
        <Stack
          gap={2}
          direction='row'
          justifyContent='space-between'
          pl={1}
          pt={0.5}
        >
          <Box flex={1} maxWidth='10%'>
            <FocusTitleForm
              withSave={editable}
              editable={editable}
              title={taskFocusData?.name}
              taskFocusId={taskFocusData?.id}
              onSaved={onFocusItemUpdated}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-end'
            justifyContent='flex-start'
            marginRight={2}
          >
            <Typography
              fontSize={12}
              fontWeight={500}
              sx={{ mb: 0.5, textAlign: 'right' }}
            >
              <span
                style={{
                  opacity: 0.5,
                  fontWeight: 400,
                }}
              >
                Status:
              </span>
              &nbsp;{' '}
              <span
                style={{
                  color: !isFocusActive
                    ? isDarkMode
                      ? 'var(--md-ref-palette-error80)'
                      : 'var(--md-ref-palette-error40)'
                    : undefined,
                }}
              >
                {isFocusActive ? 'Active' : 'Inactive'}
              </span>
            </Typography>
            <Typography
              display='flex'
              fontSize={12}
              fontWeight={500}
              sx={{ mb: 0.5, textAlign: 'right' }}
            >
              {/* {showGenericSwitch && (
                <Tooltip title={`${isFocusGeneric ? 'Unset' : 'Set'} Generic`}>
                  <Box
                    display='flex'
                    position='relative'
                    style={{
                      top: 4,
                      marginTop: -10,
                      marginRight: 8,
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={toggleGenericActive}
                  >
                    {isFocusGeneric ? (
                      <ToggleOnIcon style={{ fontSize: 32 }} />
                    ) : (
                      <ToggleOffOutlinedIcon style={{ fontSize: 32 }} />
                    )}
                  </Box>
                </Tooltip>
              )} */}
              <span
                style={{
                  opacity: 0.5,
                  fontWeight: 400,
                }}
              >
                Type:
              </span>
              &nbsp; {isFocusGeneric ? 'Generic' : 'Not Generic'}
            </Typography>
          </Box>
        </Stack>
      </>
    );
  };

  const renderFocusForm = () => {
    return (
      <>
        {isFormOpen.overview
          ? renderRichEditor({
              icon: <BookmarkBorderOutlinedIcon />,
              name: 'overview',
              value: formState.overview,
              title: 'Overview:',
              subtitle:
                'This is a brief, 2-to-3 sentence section that summarizes position.',
            })
          : renderRichDisplay({
              icon: <BookmarkBorderOutlinedIcon />,
              name: 'overview',
              title: 'Overview:',
              withBackground: true,
              content: formState.overview,
            })}
        {isFormOpen.desired_outcome
          ? renderRichEditor({
              icon: <PlaylistAddCheckOutlinedIcon />,
              name: 'desired_outcome',
              value: formState.desired_outcome,
              title: 'Desired Outcome:',
              subtitle:
                'Key tasks and obligations that define the success of this role.',
            })
          : renderRichDisplay({
              icon: <PlaylistAddCheckOutlinedIcon />,
              name: 'desired_outcome',
              title: 'Desired Outcome:',
              withBackground: true,
              content: formState.desired_outcome,
            })}
        {isFormOpen.success_measurement
          ? renderRichEditor({
              icon: <ExtensionOutlinedIcon />,
              name: 'success_measurement',
              value: formState.success_measurement,
              title: 'Measurement of Success:',
              subtitle:
                'This section contains responsibilities and important duties performed occasionally or in addition to the essential functions of the position. The above statement is on all job descriptions in this section.',
            })
          : renderRichDisplay({
              icon: <ExtensionOutlinedIcon />,
              name: 'success_measurement',
              title: 'Measurement of Success:',
              withBackground: true,
              content: formState.success_measurement,
            })}
        <Box mt={5} mb={10}>
          {withDelete && (
            <Box display={'flex'} gap={2} justifyContent='flex-end'>
              <M3Button
                onClick={toggleFocusActive}
                style={{
                  backgroundColor: isFocusActive
                    ? isDarkMode
                      ? 'var(--md-ref-palette-error50)'
                      : 'var(--md-ref-palette-error80)'
                    : isDarkMode
                    ? 'var(--md-ref-palette-secondary30)'
                    : 'var(--md-ref-palette-secondary90)',
                }}
              >
                {isFocusActive ? 'Make Inactive' : 'Make Active'}
              </M3Button>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const renderJobsProjectsForm = () => {
    return (
      <Box>
        <FocusJobsProjects
          editable={editable}
          isNew={false}
          disableRemoveWhenOneLeft
          jobs={taskFocusData?.jobs}
          divisionId={divisionId}
          onJobsChanged={handleOnJobsChanged}
        />
      </Box>
    );
  };

  const renderQuestions = () => {
    return (
      <Box pb={2} mt={-6}>
        <Stack direction='row' justifyContent='space-between'>
          <Box minHeight={40} />
          {editable && (
            <M3Button
              color='secondary'
              variant='outlined'
              onClick={() => {
                setTabIndex(1);
                setIsQuestionFormOpen(true);
              }}
            >
              + Add Question
            </M3Button>
          )}
        </Stack>
        {!eodQuestions.length && (
          <PageWait
            title='No questions'
            message={`This focus doesn't have a question`}
            icon={
              <QuestionAnswerOutlinedIcon
                sx={{
                  mb: 2,
                  fontSize: 80,
                  opacity: isDarkMode ? 0.1 : 0.05,
                }}
              />
            }
            sx={{
              paddingTop: '8vh',
            }}
          />
        )}
        {filteredEodQuestions.map((question, index) => (
          <FocusQuestionItem
            key={index}
            item={question}
            editable={editable}
            questions={eodQuestions}
            eodFormId={eodFormData?.id}
            onSave={handleOnQuestionSave}
            onDeleteQuestion={onDeleteQuestion}
          />
        ))}
      </Box>
    );
  };

  const renderTabs = () => {
    return (
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Tabs
          value={tabIndex}
          onChange={onTabIndexHandleChange}
          sx={{
            ml: -1,
            height: 32,
            minHeight: 32,
            paddingTop: 0,
          }}
        >
          <M3TabButton size='small' disableRipple label='Overview' />
          <M3TabButton
            disableRipple
            size='small'
            label={`Questions (${eodQuestions.length})`}
          />
          <M3TabButton
            disableRipple
            size='small'
            label={`Jobs (${taskFocusData?.jobs?.length ?? 0})`}
          />
        </Tabs>
      </Box>
    );
  };

  useEffect(() => {
    if (taskFocusData) {
      const eodFormData = taskFocusData?.eod_form;
      updateFormState((state) => ({
        ...state,
        title: taskFocusData.name ?? '',
        overview: eodFormData?.overview ?? '',
        desired_outcome: eodFormData?.desired_outcome ?? '',
        success_measurement: eodFormData?.success_measurement ?? '',
      }));
    }
    // eslint-disable-next-line
  }, [taskFocusData]);

  useEffect(() => {
    // On toggle active/inactive of focus
    confirmationDialog.confirm.setIsOpen(false);

    if (deleteFocusItem.isSuccess) onFocusItemUpdated();
    else if (deleteFocusItem.isError) {
      enqueueSnackbar(
        'Sorry, Unable to update the Focus status.\nPlease try again later or contact support if the problem persists.',
      );
    }
    // eslint-disable-next-line
  }, [deleteFocusItem.isSuccess]);

  useEffect(() => {
    // On deletion of Questions
    confirmationDialogQ.confirm.setIsOpen(false);

    if (deleteQuestion.isSuccess) {
      onFocusItemUpdated();
      setQuestionToBeDeleted('');
    } else if (deleteFocusItem.isError) {
      enqueueSnackbar(
        'Sorry, Unable to delete the Question.\nPlease try again later or contact support if the problem persists.',
      );
    }
    // eslint-disable-next-line
  }, [deleteQuestion.isSuccess]);

  useEffect(() => {
    // On deletion of Questions
    confirmationDialogGeneric.confirm.setIsOpen(false);

    if (setGeneric.isSuccess) {
      onFocusItemUpdated();
    } else if (setGeneric.isError) {
      enqueueSnackbar(
        'Sorry, Unable to update the Focus status.\nPlease try again later or contact support if the problem persists.',
      );
    }
    // eslint-disable-next-line
  }, [setGeneric.isSuccess]);

  /**
   * Check if there's not yet any job description
   */
  if (!timeTrackerTaskFocus.data || !taskFocusId) {
    if (timeTrackerTaskFocus.isLoading) {
      return <FocusDetailSkeleton />;
    } else {
      return (
        <Error
          title='Focus Unavailable'
          message={
            userView
              ? 'This user has not yet been assigned to a job description within the system.'
              : 'We have encountered a problem while attempting to fetch the job information from the system.'
          }
          sx={{
            paddingTop: '4vh',
          }}
        />
      );
    }
  }

  return (
    <>
      <AppActionToolbar
        secondaryHeight={92}
        sx={{
          pt: 1,
          alignItems: 'flex-start',
        }}
      >
        <M3IconButton
          onClick={() => {
            navigate(getRoutes().basePath);
          }}
          className='m3-icon-button-back-detail-button'
        >
          <ArrowBackIcon />
        </M3IconButton>
        <Box flex={1} pl={1}>
          {renderFocusItemDetailForm()}
          {renderTabs()}
        </Box>
      </AppActionToolbar>
      <Typography
        mb={4}
        mt={-1}
        component='div'
        fontSize={13}
        style={{
          opacity: 0.5,
          color: isDarkMode
            ? 'var(--md-ref-palette-error80)'
            : 'var(--md-ref-palette-error40)',
        }}
      >
        Note: Focus must be associated with at least one job
      </Typography>
      {tabIndex === 0 && renderFocusForm()}
      {tabIndex === 1 && renderQuestions()}
      {tabIndex === 2 && renderJobsProjectsForm()}
      <Box>
        <ConfirmationDialog
          {...confirmationDialog.confirm}
          title='Update Focus'
          message={
            <>
              Are you sure you want to make this Focus Inactive?
              <br />
              You can later set the focus to be active
            </>
          }
          isLoading={deleteFocusItem.isLoading}
          onConfirm={() =>
            deleteFocusItem.mutate({ is_active: !isFocusActive })
          }
        />
        <ConfirmationDialog
          {...confirmationDialogQ.confirm}
          title='Delete Question'
          message={
            <>
              Are you sure you want to delete this question?
              <br />
              This action cannot be undone and all associated data will be
              permanently lost.
            </>
          }
          isLoading={deleteQuestion.isLoading}
          onConfirm={confirmDeleteQuestion}
        />
        <ConfirmationDialog
          {...confirmationDialogGeneric.confirm}
          title='Update Focus'
          message={
            <>
              Are you sure you want to set this Focus to Non Generic?
              <br />
              You can later set the focus to be Generic
            </>
          }
          isLoading={setGeneric.isLoading}
          onConfirm={() => setGeneric.mutate({ is_generic: !isFocusGeneric })}
        />
      </Box>
      <Modal open={isQuestionFormOpen}>
        <FocusQuestionFormModalView
          taskFocusData={taskFocusData}
          eodFormId={eodFormData?.id}
          questions={eodQuestions}
          close={() => {
            setIsQuestionFormOpen(false);
          }}
          onSave={handleOnQuestionSave}
        />
      </Modal>
    </>
  );
};

export default FocusItemDetail;

type TaskFocusRichTextEditorProps = {
  id?: string | number;
  icon?: ReactRenderElement;
  name: keyof FocusItemState | keyof IsFormOpen;
  value: ValueOf<FocusItemState> | string;
  title?: ReactRenderElement;
  subtitle?: ReactRenderElement;
  aiAssist?: UseAIAssistSideSheetRet;
  taskFocusData?: TimeTrackerTaskItemDetail;
  onCancel?: () => void;
  onSave?: (value: string) => void;
};

function TaskFocusRichTextEditor({
  id,
  icon,
  name,
  value: initialValue,
  title,
  subtitle,
  onCancel,
  onSave,
  aiAssist,
  taskFocusData,
}: TaskFocusRichTextEditorProps) {
  const { getTokenSilently } = useAuthProvider();
  const [value, setValue] = useState(initialValue);
  const changed = value !== initialValue;

  const [formRequestStatus, setFormRequestStatus] = useFormRequestStatus();

  const patchEODForms = usePatchEODForms({ id });
  const isSubmitDisabled =
    formRequestStatus.status === 'processing' ||
    patchEODForms.isLoading ||
    !changed;

  const isPromptEngineer = useHasViewPermission({
    roles: ['superuser', 'prompt_engineer'],
  });
  let promptIdentifier = stringToKey(`task-focus-${name}`, '-');

  const onSubmit = async () => {
    if (id) {
      patchEODForms.mutate({
        [name]: value,
      });
    }
    // If there's no EOD form yet, let's create the eod first that we will attach
    else {
      try {
        setFormRequestStatus({
          status: 'processing',
          error: null,
        });

        const eodFormPayload: Partial<FocusEODFormPayload> = {
          fields: [],
          tasks: [taskFocusData!.id],
          name: taskFocusData!.name,
          // Add the field that requested that started to attach the eod
          [name]: value,
        };

        await cosmosRequest.post(
          `/api/forms/eod-forms/`,
          eodFormPayload,
          getConfigWithAuthorization(await getTokenSilently()),
        );

        onSave?.(value as string);
      } catch (e) {
        setFormRequestStatus({
          status: 'error',
          error: e as Error,
        });
      }
    }
  };

  useEffect(() => {
    if (patchEODForms.isSuccess) {
      onSave?.(value as string);
    }
    // eslint-disable-next-line
  }, [value, patchEODForms.isSuccess]);

  return (
    <Box mb={5}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          display='flex'
          alignItems='center'
          component='div'
          fontSize={22}
          minHeight={40}
        >
          <Box position='relative' top={4} pr={1}>
            {icon}
          </Box>
          {title}
        </Typography>
        <Stack
          gap={1}
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
        >
          <M3Button style={actionButtonStyle} onClick={onCancel}>
            Cancel
          </M3Button>
          <M3Button
            color='primary'
            variant='contained'
            style={{
              ...actionButtonStyle,
              marginLeft: 8,
            }}
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          >
            Save
          </M3Button>
        </Stack>
      </Stack>
      {!!subtitle && (
        <Typography
          pt={0.5}
          component='div'
          fontSize={14}
          sx={{ opacity: 0.5 }}
        >
          {subtitle}
        </Typography>
      )}
      <RichEditor
        withAIAssist
        withAIEditPrompt={isPromptEngineer}
        value={value as string}
        withMentions={false}
        onValueChange={setValue}
        placeholder='Write here...'
        sx={{ marginTop: 1 }}
        chatGPTPlaygroundProps={{
          title: title as string,
          prompt_identifier: promptIdentifier,
        }}
        onAssistClick={() => {
          aiAssist?.open();
          aiAssist?.start({
            title: title as string,
            prompt_identifier: promptIdentifier,
            data: {
              prompt_text: value as string,
            },
          });
        }}
      />
    </Box>
  );
}
