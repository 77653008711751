import { UseQueryOptions } from '@tanstack/react-query';

import { BasicQuery } from '../types/request';
import { TimeTrackerDailySummaryItem } from '../types/worklogs';
import { useIdentifier } from './global/useIdentifier';
import { useQueryApi } from './global/useApi';
import { defaultReactQueryParams } from '../utils/request';

type UseTimeTrackerDailySummaryParams = BasicQuery & {
  start_date: string;
  end_date?: string;
  task?: number;
  project?: string;
  timezone?: string;
  users?: number | string;
  exclude_active?: boolean;
};

export function useTimeTrackerDailySummary<R = TimeTrackerDailySummaryItem>(
  params: UseTimeTrackerDailySummaryParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseTimeTrackerDailySummaryParams>({
      params,
      baseIdentifier: 'useTimeTrackerDailySummary',
      identifierKeys: [
        'start_date',
        'end_date',
        'task',
        'project',
        'timezone',
        'users',
        'exclude_active',
      ],
      queryParamKeys: [
        'start_date',
        'end_date',
        'task',
        'project',
        'timezone',
        'users',
        'exclude_active',
      ],
    });

  return useQueryApi<UseTimeTrackerDailySummaryParams, R[]>(
    identifiers,
    '/time-tracker/worklogs/daily-summary/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
