import moment from 'moment';
import { v1 as uuid } from 'uuid';
import { HrPTOItemResponse, HrTimeOffTypes } from '../types/hr';
import { PTOStatus } from '../types/pto';
import { parseDateWithoutTimezone } from './date';

type PTOStatusObject = {
  label: string;
  status: PTOStatus;
};
export function getPTOStatus(status: PTOStatus): PTOStatusObject {
  if (status === 'requested') return { label: 'Awaiting Approval', status };

  return { label: status, status };
}

export type HrPTOItemResponseExpanded = HrPTOItemResponse & {
  refId: string;
};
export function hrPTOItemTransformData(
  item: HrPTOItemResponse,
): HrPTOItemResponseExpanded {
  return {
    ...item,
    refId: uuid(),
  };
}

export const hrTimeOffTypes: HrTimeOffTypes = {
  medical_leave: '84',
  pto: '92',
  flex_holiday: '93',
  buffer: '94',
  uto: '96',
  parental_leave: '98',
  go2_universal_holiday: '99',
  medical_leave_of_absence: '100',
  partner_requested_time_off: '102',
  others: '103',
  special_time_off: '105',
};

export const getTimeOffDateRequestedText = ({
  start,
  end,
}: {
  start: string | null;
  end: string | null;
}) => {
  const startM = moment(parseDateWithoutTimezone(start!).date);
  const endM = moment(parseDateWithoutTimezone(end || start!).date);
  let dateRequestText: string = '';

  if (startM.isSame(endM)) {
    dateRequestText = ` ${startM.format('MMM D, YYYY')}`;
  } else {
    const isSameYear = startM.year() === endM.year();
    dateRequestText =
      startM.month() !== endM.month()
        ? [
            startM.format(`MMM D${isSameYear ? '' : ', YYYY'}`),
            endM.format(`MMM D${isSameYear ? '' : ', YYYY'}`),
          ].join(' - ') + (isSameYear ? `, ${startM.year()}` : '')
        : startM.format('MMM ') +
          [
            startM.format(`D${isSameYear ? '' : ', YYYY'}`),
            endM.format(`D${isSameYear ? '' : ', YYYY'}`),
          ].join(' - ') +
          (isSameYear ? `, ${startM.year()}` : '');
  }

  // if (dateRequestText) {
  //   dateRequestText = ` for ${dateRequestText}`;
  // }

  return dateRequestText;
};
