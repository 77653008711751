import { Box, Typography } from '@mui/material';
import React from 'react';

import { useAppProvider } from '../../providers/app/app';

type UserChipStatusProps = {
  status?: string | null;
  background?: string;
};

const UserChipStatus = ({ status, background }: UserChipStatusProps) => {
  const { isDarkMode } = useAppProvider();

  if (!status) return null;

  return (
    <Box display='flex'>
      <Typography
        component='div'
        fontSize={10}
        fontWeight={700}
        display='flex'
        alignItems='center'
        textTransform='uppercase'
        sx={{
          pl: 0.4,
          pr: 0.4,
          borderRadius: 0.5,
          background: background ?? '#28c0de',
        }}
        style={{
          color: isDarkMode ? '#000' : '#fff',
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};

export default UserChipStatus;
