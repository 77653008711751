import React, { FC, useState, useEffect, useCallback } from 'react';
import moment, { Moment } from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { M3Button, M3IconButton } from '../components/M3/M3Button';
import { M3TextField } from '../components/M3/M3TextField';
import DocumentTitle from '../components/DocumentTitle';
import WindowScrollTop from '../components/WindowScrollTop';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import TimeInfoCardContainer from '../components/EditTime/components/TimeInfoCardContainer/TimeInfoCardContainer';
import Timeline from '../components/EditTime/components/Timeline/Timeline';
import TimeDetailsTable from '../components/EditTime/components/TimeDetailsTable/TimeDetailsTable';
import TimeDetailsEditForm from '../components/EditTime/components/TimeDetailsEditForm/TimeDetailsEditForm';
import Timezone, { useTimezone } from '../components/Util/Timezone';
import { isActiveWorklog } from '../components/EditTime/components/TimeDetailsTableRow/TimeDetailsTableRow';
import { defaultTimeDetailsFormValues } from '../components/EditTime/constants/default-time-details-form-values';
import { IEditTimeDetailsForm } from '../components/EditTime/constants/edit-time-details-form.interface';
import TimelineColorMap from '../components/EditTime/components/Timeline/TimelineColorMap';

import { useFetchTimeDetails } from '../hooks/edit-time';
import { formatDate } from '../utils/date';
import { useTick } from '../hooks/utils/tick';
import { useCurrentProfile } from '../hooks/profile';

type EditTimeProps = {};
const EditTime: FC<EditTimeProps> = () => {
  const dateFormat = 'YYYY-MM-DD';

  const [selectedDate, setSelectedDate] = useState(formatDate(dateFormat));
  const [isTimeDetailsModalOpen, setIsTimeDetailsModalOpen] = useState(false);
  const [timeDetailsModalHeader, setTimeDetailsModalHeader] = useState('');
  const [formData, setFormData] = useState<IEditTimeDetailsForm>(
    defaultTimeDetailsFormValues,
  );

  /**
   * In order for the whole component page to keep updating the time so it
   * forces the whole component to recheck their initial state. Good use
   * to that active worklog have always current time
   */
  useTick();

  const { data: currentProfile } = useCurrentProfile();

  const momentSelectedDate = moment(selectedDate);
  const timezone = useTimezone();

  const {
    refetch: refetchTimelineData,
    data: timeDetailsData,
    isLoading: isTimeDetailsDataLoading,
  } = useFetchTimeDetails(
    {
      start_date: momentSelectedDate.clone().startOf('day').utc().format(),
      timezone,
      limit: 200,
    },
    {
      // Refetch the interval every 4 minutes, 1 minute less between sync time on Go2 desktop
      refetchInterval: 240 * 1000,
      refetchOnWindowFocus: true,
      select(data) {
        const newData = data.results.map(
          ({ start, end, ...timeDetails }, index) => {
            const endTimeLocal = end ? moment.utc(end).local() : null;
            let startTimeLocal = moment.utc(start).local();

            if (
              startTimeLocal.isBefore(momentSelectedDate.clone().startOf('day'))
            ) {
              startTimeLocal = momentSelectedDate.clone().startOf('day');
            }

            return {
              ...timeDetails,
              start,
              end,
              start_time_local: startTimeLocal,
              end_time_local: endTimeLocal,
            };
          },
        );

        return {
          ...data,
          results: [...newData],
        };
      },
    },
  );

  const handleDateChange = (newDate: Moment | null): void =>
    setSelectedDate(formatDate(dateFormat, newDate));

  const handleToggleModalState = () =>
    setIsTimeDetailsModalOpen((prevState) => !prevState);

  const handleModalOpen = (isEditTime = false) => {
    handleToggleModalState();
    setTimeDetailsModalHeader(`${isEditTime ? 'Edit' : 'Add'} Time`);
  };

  const handleModalClose = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      handleToggleModalState();
      setFormData(defaultTimeDetailsFormValues);
    }
    refetchTimelineData();
  };

  const handleOnDeleteSuccess = useCallback(
    () => refetchTimelineData(),
    [refetchTimelineData],
  );

  const handleIncrementDate = () =>
    setSelectedDate(moment(selectedDate).add(1, 'day').format(dateFormat));

  const handleDecrementDate = () => {
    setSelectedDate(moment(selectedDate).subtract(1, 'day').format(dateFormat));
  };

  const latestWorklogEntry =
    timeDetailsData?.results.find((row, index) =>
      isActiveWorklog(row, index),
    ) ?? null;

  useEffect(() => {
    refetchTimelineData();
  }, [selectedDate, refetchTimelineData]);

  return (
    <>
      <DocumentTitle title='Edit Time' trailingTitle='Personal Settings' />
      <WindowScrollTop />
      <TimelineColorMap data={timeDetailsData?.results} />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppActionToolbar>
          <Box
            ml={3}
            display='flex'
            justifyContent='space-between'
            width={'100%'}
            marginLeft={'0'}
            marginRight={'16px'}
          >
            <Typography component='span' fontSize={22} mt={1}>
              Edit Time
            </Typography>

            <Stack direction='row' gap={2} alignItems={'center'}>
              <M3IconButton onClick={handleDecrementDate}>
                <ArrowLeft sx={{ left: -1, position: 'relative' }} />
              </M3IconButton>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                maxDate={moment()}
                renderInput={(props) => (
                  <M3TextField sx={{ width: '169px' }} {...props} />
                )}
              />
              <M3IconButton
                onClick={handleIncrementDate}
                disabled={moment(selectedDate).isSame(moment(), 'day')}
              >
                <ArrowRight />
              </M3IconButton>

              <M3Button
                color='primary'
                variant='contained'
                onClick={() => handleModalOpen()}
                sx={{ marginTop: '4px' }}
              >
                <AddOutlinedIcon />
                Add Time
              </M3Button>
            </Stack>
          </Box>
        </AppActionToolbar>

        <Timezone />

        <TimeInfoCardContainer
          timeDetailsData={timeDetailsData?.results ?? []}
          isTimeDetailsDataLoading={isTimeDetailsDataLoading}
        />

        <Timeline data={timeDetailsData?.results} />

        <TimeDetailsTable
          hasLatestWorklogEntry={!!latestWorklogEntry}
          isLoading={isTimeDetailsDataLoading}
          data={timeDetailsData?.results ?? []}
          toggleModalState={() => handleModalOpen(true)}
          updateFormData={setFormData}
          onDeleteSuccess={handleOnDeleteSuccess}
        />
      </MainContainer>

      <Modal
        open={isTimeDetailsModalOpen}
        onClose={(event, reason) => handleModalClose(reason)}
      >
        <TimeDetailsEditForm
          modalHeader={timeDetailsModalHeader}
          formData={formData}
          dateToDisplay={selectedDate}
          latestWorklogEntry={latestWorklogEntry}
          userId={currentProfile?.id}
          onModalClose={handleModalClose}
        />
      </Modal>
    </>
  );
};

export default EditTime;
