import React, { useRef, useState } from 'react';
import { Paper, Box } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';

import WorkspaceMenuList, { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList, { AppMenuChip } from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';
import PerfectScrollbar, { PerfectScrollbarRef } from '../PerfectScrollbar';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import * as intercom from '../../services/intercom';
import { useNotificationProvider } from '../../providers/notification/notification';
import { applyOpacityOnColor } from '../../utils/color';
import * as posthog from '../../services/posthog';
import { useIsPartnerMember } from '../../hooks/utils/user';

type Props = {};

const InboxDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const { supportUnreadCount } = useIntercomProvider();
  const { unreadHelpNeededCount, getAllCount, unreadGo2BotsCount } =
    useNotificationProvider();

  const perfectScrollbarRef = useRef<null | PerfectScrollbarRef>(null);
  const [, setScrollYMenuList] = useState(0);

  const isPartnerMember = useIsPartnerMember();

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  let moreMenus: MenuItem[] = [];

  if (isPartnerMember) {
    /**
     * Forms for partner member access only
     */
    moreMenus = [
      {
        id: 'partner-member-forms',
        name: 'Forms',
        collapsable: true,
        roles: ['partner_member'],
        restrict: [],
        children: [
          {
            id: 'partner-forms-additional-teammate',
            name: 'Additional Teammate',
            basePath: '/inbox/forms/additional-teammate',
            path: '/inbox/forms/additional-teammate',
            roles: ['partner_member'],
          },
          {
            id: 'partner-forms-bonus',
            name: 'Give a Bonus',
            basePath: '/inbox/forms/bonus',
            path: '/inbox/forms/bonus',
            roles: ['partner_member'],
          },
        ],
      },
    ];
  } else {
    /**
     * Forms for internal and go2 users
     */
    moreMenus = [
      {
        id: 'inbox-forms',
        name: 'Forms',
        collapsable: true,
        roles: ['internal', 'teammate', 'escalation_manager'],
        restrict: ['partner_member'],
        children: [
          {
            id: 'form-request',
            name: 'Time Off',
            basePath: '/inbox/forms/request/time-off',
            path: '/inbox/forms/request/time-off',
            restrict: ['path_beta'],
          },
          {
            id: 'form-issue-attendance',
            name: 'Attendance',
            basePath: '/inbox/forms/issue/attendance',
            path: '/inbox/forms/issue/attendance',
            restrict: ['path_beta'],
          },
          {
            id: 'form-issue-tools',
            name: 'IT Tech',
            basePath: '/inbox/forms/issue/tools',
            path: '/inbox/forms/issue/tools',
          },
          {
            id: 'form-issue-bk',
            name: 'Bookkeeping',
            basePath: '/inbox/forms/issue/bk',
            path: '/inbox/forms/issue/bk',
            restrict: ['path_beta'],
          },
          {
            id: 'form-issue-engage',
            name: 'Engage',
            basePath: '/inbox/forms/issue/engage',
            path: '/inbox/forms/issue/engage',
            roles: ['internal'],
          },
          {
            id: 'forms-setup',
            name: 'New Hire Setup',
            basePath: '/inbox/forms/new-hire-setup',
            path: '/inbox/forms/new-hire-setup',
            roles: ['internal'],
          },
          {
            id: 'internal-forms-additional-teammate',
            name: 'Additional Teammate',
            basePath: '/inbox/forms/additional-teammate',
            path: '/inbox/forms/additional-teammate',
            roles: ['superuser'],
          },
          {
            id: 'internal-forms-bonus',
            name: 'Give a Bonus',
            basePath: '/inbox/forms/bonus',
            path: '/inbox/forms/bonus',
            roles: ['superuser'],
          },
        ],
      },
    ];
  }

  const menus: MenuItem[] = [
    {
      id: 'headline-inbox',
      name: 'Messages',
      icon: <InboxIcon />,
      headline: true,
    },
    {
      id: 'inbox',
      name: 'All',
      basePath: '/inbox/all',
      path: '/inbox/all/0',
      secondaryAction: <AppMenuChip total={getAllCount()} />,
      onClick: () => {
        posthog.capture('inbox clicked');
      },
    },
    {
      id: 'go2-bots',
      name: 'Go2Bots',
      basePath: '/inbox/go2bots',
      path: '/inbox/go2bots/0',
      secondaryAction: <AppMenuChip total={unreadGo2BotsCount} />,
      onClick: () => {
        posthog.capture('inbox go2bots clicked');
      },
    },
    /*
    {
      id: 'daily-report',
      name: 'Daily Reports',
      basePath: '/inbox/daily-report',
      path: '/inbox/daily-report/0',
      secondaryAction: <AppMenuChip total={unreadDailyReportCount} />,
    },
    */
    {
      id: 'help-needed',
      name: 'Help Needed',
      basePath: '/inbox/help-needed',
      path: '/inbox/help-needed/0',
      secondaryAction: <AppMenuChip total={unreadHelpNeededCount} />,
      onClick: () => {
        posthog.capture('inbox help needed clicked');
      },
    },
    /*
    {
      id: 'requests',
      name: 'Requests',
      basePath: '/inbox/request',
      path: '/inbox/request/0',
      secondaryAction: <AppMenuChip total={unreadPTOCount} />,
    },
    */
    /*
    {
      id: 'job-description',
      name: 'Job Description',
      basePath: '/inbox/job-description',
      path: '/inbox/job-description/0',
      secondaryAction: <AppMenuChip total={unreadJobDescriptionCount} />,
    },
    */
    {
      id: 'support-divider',
      divider: true,
    },
    {
      id: 'support-conversation',
      name: 'Support',
      basePath: '/inbox/support',
      path: '/inbox/support',
      secondaryAction: <AppMenuChip total={supportUnreadCount} />,
      onClick: () => {
        posthog.capture('inbox support clicked');
        intercom.emit('showMessages');
      },
    },
    ...moreMenus,
  ];

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1} sx={{ height: 0 }}>
          <PerfectScrollbar onSetRef={perfectScrollbarRef}>
            <AppDrawerMenuList
              menus={menus}
              onExpanded={() => setScrollYMenuList((s) => ++s)}
            />
          </PerfectScrollbar>
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default InboxDrawer;
