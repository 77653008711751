import React from 'react';
import { Box } from '@mui/material';

type Props = {
  spinner?: boolean;
  sx?: any;
};

const LoadingSpinner = () => {
  return (
    <figure className='loading-spinner-figure'>
      <div className='loading-spinner-dot loading-spinner-white'></div>
      <div className='loading-spinner-dot '></div>
      <div className='loading-spinner-dot '></div>
      <div className='loading-spinner-dot '></div>
      <div className='loading-spinner-dot '></div>
    </figure>
  );
};

function PageLoader({ spinner, sx }: Props) {
  return (
    <Box sx={{ flex: 1, ...sx }} justifyContent='center' alignItems='center'>
      <LoadingSpinner />
    </Box>
  );
}

export default PageLoader;
