import React, { Fragment, SyntheticEvent, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  Skeleton,
  Stack,
  SxProps,
  Tabs,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import MainContainer from '../components/MainContainer';
import IntercomBreadcrumbs from '../components/Intercom/IntercomBreadcrumbs';
import WindowScrollTop from '../components/WindowScrollTop';
import ContentTargetAudiences from '../components/Content/ContentTargetAudiences';
import { M3TabButton } from '../components/M3/M3TabButton';
import ContentArticleMenuOptions from '../components/Content/ContentArticleMenuOptions';
import RichTextContent from '../components/RichEditor/RichTextContent';

import { cleanURL } from '../utils/url';
import { useAppProvider } from '../providers/app/app';
import {
  useIntercomArticleById,
  useIntercomCollectionById,
  useIntercomSectionById,
} from '../hooks/intercom';
import { ReactRenderElement } from '../types/types';
import { mutateFieldEscapeString } from '../utils/object';

type Props = {};

const ContentArticleDetail = (props: Props) => {
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();

  const { articleId } = useParams();
  const article = useIntercomArticleById({ id: articleId! });
  const articleData = article.data;

  const section = useIntercomSectionById(
    { id: articleData?.parent_id! },
    { enabled: articleData?.parent_type === 'section' },
  );
  const sectionData = useMemo(() => {
    const data = section.data ? [{ ...section.data }] : [];
    mutateFieldEscapeString(data, ['name']);
    return data[0];
  }, [section.data]);

  const collection = useIntercomCollectionById(
    { id: sectionData?.parent_id! || articleData?.parent_id! },
    {
      enabled:
        !!sectionData?.parent_id || articleData?.parent_type === 'collection',
    },
  );
  const collectionData = useMemo(() => {
    const data = collection.data ? [{ ...collection.data }] : [];
    mutateFieldEscapeString(data, ['name', 'description']);
    return data[0];
  }, [collection.data]);

  // const articlesInCollectionQuery = useIntercomArticlesInCollection({
  //   key: `content_article_collection_by_id_${collectionData?.id || ''}`,
  //   collectionId: collectionData?.id,
  // });

  const [tabIndex, setTabIndex] = useState(0);

  const onTabIndexHandleChange = (event: SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  const renderHeadline = () => {
    return (
      <>
        <Stack
          direction='row'
          gap={2}
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Box flex={1}>
            <Typography
              component='div'
              fontSize={33}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)',
              }}
            >
              {articleData?.title}
            </Typography>
            {!!articleData?.description && (
              <Typography
                fontSize={16}
                sx={{
                  opacity: 0.8,
                  color: 'var(--md-sys-color-on-surface-light)',
                }}
                style={{
                  padding: '5px 0 0',
                }}
              >
                {articleData.description}
              </Typography>
            )}
          </Box>
          {!!articleData && (
            <ContentArticleMenuOptions article={articleData} popover={false} />
          )}
        </Stack>
      </>
    );
  };

  const renderHeadlineSkeleton = () => {
    return (
      <>
        <Stack
          direction='row'
          gap={2}
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Box flex={1}>
            <Skeleton
              variant='text'
              sx={{
                mb: 1,
                fontSize: 31,
                maxWidth: 600,
              }}
            />
            <Skeleton
              variant='text'
              sx={{
                fontSize: 16,
              }}
            />
          </Box>
        </Stack>
      </>
    );
  };

  const renderTabNavigation = () => {
    const tabButtonSx: SxProps = {
      minWidth: 'initial',
    };
    return (
      <>
        <Tabs
          value={tabIndex}
          onChange={onTabIndexHandleChange}
          style={{
            height: 56,
          }}
        >
          <M3TabButton disableRipple label='Detail' sx={tabButtonSx} />
          {/* <M3TabButton
            disableRipple
            label='Related Articles'
            sx={tabButtonSx}
          /> */}
        </Tabs>
        <Divider flexItem sx={{ borderWidth: 1, opacity: 0.5 }} />
      </>
    );
  };

  const renderDetailTab = () => {
    if (!articleData) return null;

    return (
      <Typography component='div'>
        <RichTextContent
          content={articleData.body}
          domPurifyOptions={{}}
          className='intercom-detail-body'
        />
      </Typography>
    );
  };

  const renderCollectionTab = () => {
    return null;
  };

  const renderCollectionSectionNotice = () => {
    const content: ReactRenderElement[] = [];
    const getKey = () => `${content.length + 1}`;

    if (collectionData) {
      content.push(
        <Fragment key={getKey()}>
          <Typography component='div'>
            <span style={{ opacity: 0.8, fontWeight: 500 }}>
              Collection:&nbsp;
            </span>
            <span className='noselect cursor' style={{ fontWeight: 500 }}>
              <Link
                to={`/content/article/${articleId}/collection/${collectionData.id}`}
                style={{
                  color: isDarkMode
                    ? 'var(--md-ref-palette-primary80)'
                    : 'var(--md-ref-palette-primary40)',
                }}
              >
                {collectionData.name}
              </Link>
            </span>
          </Typography>
        </Fragment>,
      );
    }

    if (sectionData) {
      content.push(
        <Fragment key={getKey()}>
          <Typography component='div'>
            <span style={{ opacity: 0.8, fontWeight: 500 }}>
              Section:&nbsp;
            </span>
            <span style={{ fontWeight: 500 }}>{sectionData.name}</span>
          </Typography>
        </Fragment>,
      );
    }

    if (content.length) {
      content.push(<br key={getKey()} />);
    }

    return content;
  };

  return (
    <>
      <WindowScrollTop />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              navigate('/content/article');
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
        </AppActionToolbar>
        <IntercomBreadcrumbs
          items={[
            {
              id: 'all',
              label: 'All Articles',
              path: '/content/article',
            },
            {
              id: articleData ? articleData.id : articleId ?? '',
              label: articleData ? articleData.title : articleId ?? '',
              path: articleData
                ? cleanURL(`/content/article/${articleData.id}`)
                : '',
            },
          ]}
        />
        <Stack direction='row' justifyContent='stretch'>
          <Box flex={1}>
            <br />
            {article.isLoading && !articleData
              ? renderHeadlineSkeleton()
              : renderHeadline()}
            <br />
            {renderCollectionSectionNotice()}
            <ContentTargetAudiences
              withAddButton
              withCloseButton
              articleId={articleId}
              items={['Leads, Users and Visitors', 'Go2 Members | Internal']}
            />
            {renderTabNavigation()}
          </Box>
        </Stack>
        <br />
        {tabIndex === 0 && renderDetailTab()}
        {tabIndex === 1 && renderCollectionTab()}
      </MainContainer>
    </>
  );
};

export default ContentArticleDetail;
