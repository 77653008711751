import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import jsPDF from 'jspdf';

type Props = PropsWithChildren & {};

type PDFOptions = {
  name: string;
  data: any;
  Component: FC<any>;
  renderAsync?: () => void;
};

export function useReactPDF() {
  const targetRef = useRef<HTMLDivElement | null>(null);

  const generatePDF = useCallback(
    async ({ name, data, Component }: PDFOptions) => {
      const doc = new jsPDF({
        format: 'a4',
        unit: 'px',
      });
      const html = renderToStaticMarkup(<Component data={data} />);

      doc.html(html, {
        width: 385,
        windowWidth: 385,
        margin: [40, 0, 40, 0],
        callback: async (doc) => {
          await doc.save(name);
        },
      });
    },
    [],
  );

  return {
    targetRef,
    generatePDF,
  };
}

const ReactPDF = ({ children }: Props) => {
  return children;
};

export default ReactPDF;
