import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';

import { ReportType } from '../../types/report';

type SodEodErrorViewProps = ModalCardViewCloseProps & {
  close?: (type: ReportType) => void;
};

const SodEodErrorView = forwardRef(({ close }: SodEodErrorViewProps, ref) => {
  const renderTopPanel = () => {
    return (
      <>
        <Typography
          component='div'
          variant='h6'
          fontWeight={500}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' alignItems='center'>
            Request Submission Error
          </Box>
        </Typography>
      </>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
      sx={{ maxWidth: 720 }}
    >
      <Typography component='div' fontSize={18} pt={3} pb={4}>
        We're sorry, but there was an issue submitting your request. Please try
        again later. Thank you for your patience and understanding.
      </Typography>
    </ModalCardView>
  );
});

export default SodEodErrorView;
