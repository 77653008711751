type Props = {};

const InboxSupport = (props: Props) => {
  /**
   * Always show the page loader in background, even if intercom already loaded.
   * There's a delay from intercom when they show they UI
   */
  return null;
};

export default InboxSupport;
