import { useState } from 'react';

type UseIsCardFocusedRet = {
  focused: boolean;
  focus: () => void;
  blur: () => void;
};
export function useIsCardFocused(): UseIsCardFocusedRet {
  const [isCardFocused, setIsCardFocused] = useState(false);

  const focusCard = () => setIsCardFocused(true);
  const blurCard = () => setIsCardFocused(false);

  return {
    focused: isCardFocused,
    focus: focusCard,
    blur: blurCard,
  };
}
