import { Avatar, Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { M3Button } from './M3/M3Button';

import { useCurrentProfile } from '../hooks/profile';
import { useAppProvider } from '../providers/app/app';
import { useUserProvider } from '../providers/user/user';
import { UserMetadata } from '../types/profile';
import {
  getFromSessionStorage,
  removeFromSessionStorage,
} from '../utils/sessionstorage';

type Props = {};

type MixedUserMetadata =
  | UserMetadata
  | {
      name?: string;
      email: string;
    };
type JobseekerImpersonator = {
  name: string;
  email: string;
};

const ImpersonationPanel = (props: Props) => {
  const { data: currentProfile } = useCurrentProfile();
  const { getUser } = useUserProvider();
  const { isDarkMode } = useAppProvider();
  const impersonation = useMemo(
    () => getFromSessionStorage('impersonation'),
    [],
  );
  const user = getUser('user_id', currentProfile!.id);

  const onEndPreviewAs = () => {
    removeFromSessionStorage('impersonation');
    window.location.href = '/';
  };
  const getName = () => {
    if (!impersonation || !user) return '';

    let mixedUser: MixedUserMetadata = user;

    // Jobseeker impersonation
    if (currentProfile!.roles.indexOf('jobseekers') > -1) {
      mixedUser = {
        email: currentProfile!.email,
        name:
          currentProfile!.first_name ||
          mixedUser.first_name ||
          impersonation.name,
      } as JobseekerImpersonator;
      return `${mixedUser.name || mixedUser!.email || ''}`.trim();
    }
    // Go2 Member or Partner Member impersonation
    else {
      mixedUser = mixedUser as UserMetadata;
      return `${mixedUser!.first_name || ''} ${
        mixedUser!.last_name || ''
      }`.trim();
    }
  };

  if (!impersonation || !user) return null;

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        left: 0,
        right: 0,
        bottom: 10,
        zIndex: (theme) => theme.zIndex.modal + 1,
        position: 'fixed',
        pointerEvents: 'none',
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        sx={{
          p: 0.4,
          pl: 1,
          pr: 0.6,
          borderRadius: 20,
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : '#fff',
          pointerEvents: 'auto',
          boxShadow: isDarkMode
            ? '0 0 20px 0 rgba(0, 0, 0, 0.3), 0 0 2px 0 var(--md-ref-palette-neutral20)'
            : '0 0 20px 0 rgba(0, 0, 0, 0.3), 0 0 2px 0 #aea9b4',
        }}
      >
        <Stack
          gap={1}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Avatar src={user.photo_url} sx={{ width: 30, height: 30 }} />
          <Typography component='div' fontSize={12} style={{ fontWeight: 500 }}>
            <span style={{ opacity: 0.6 }}>You are viewing as</span>
            <br />
            <span style={{ opacity: 0.9 }}>{getName()}</span>
          </Typography>
        </Stack>
        <M3Button
          variant='contained'
          sx={{
            ml: 3,
            fontSize: 14,
            height: 32,
            '&.MuiButton-containedPrimary': {
              color: '#fff !important',
              background: '#f34722 !important',
            },
          }}
          onClick={onEndPreviewAs}
        >
          End Preview As
        </M3Button>
      </Stack>
    </Box>
  );
};

export default ImpersonationPanel;
