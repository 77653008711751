import { EODFieldParams } from '../types/report';

export function transformEODFieldParams(eodField: EODFieldParams) {
  const transformedTaskFocus: EODFieldParams = {
    ...eodField,
    question_type:
      eodField?.question_type ??
      (eodField?.type === 'number' ? 'quantitative' : 'qualitative'),
  };

  if (
    transformedTaskFocus.question_type === 'quantitative' &&
    transformedTaskFocus.type !== 'number'
  ) {
    transformedTaskFocus.type = 'number';
  } else if (
    transformedTaskFocus.question_type === 'qualitative' &&
    transformedTaskFocus.type === 'number'
  ) {
    transformedTaskFocus.question_type = 'quantitative';
  }

  transformedTaskFocus.type = transformedTaskFocus.type ?? 'input';

  if (
    transformedTaskFocus.question_type === 'quantitative' &&
    typeof transformedTaskFocus.is_there_a_parent !== 'boolean'
  ) {
    transformedTaskFocus.is_there_a_parent = false;
    transformedTaskFocus.is_parent = transformedTaskFocus.is_parent ?? false;
  }

  return transformedTaskFocus;
}
