import React from 'react';
import { AppBar, Box, Stack, SxProps } from '@mui/material';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import AppBarControlPanel from '../AppBarControlPanel';
import AppToolbar from '../AppToolbar';
import { M3Button } from '../M3/M3Button';
import { workspaceWidth } from '../WorkspaceMenuList';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';
import { useAuthForm } from '../../providers/auth/auth';

type Props = {
  children?: any;
};

const menuIconButtonSx: SxProps = {};

function AppNavigationBar({ children }: Props) {
  let { logout } = useAuthForm();
  const { pathname } = useLocation();
  const {
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
    setDarkMode,
    updateActionKeyCounter,
  } = useAppProvider();

  const standaloneMenus: MenuItem[] = [
    {
      id: 'dark-mode-switch',
      navigate: false,
      name: isDarkMode ? 'Light Mode' : 'Dark Mode',
      icon: isDarkMode ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />,
      onClick: () => {
        setDarkMode(!isDarkMode);
        updateActionKeyCounter('toggle_switch_dark_mode');
      },
    },
  ];

  return (
    <>
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <AppToolbar
          style={{
            paddingLeft: 0,
            background: applyOpacityOnColor(
              isDarkMode
                ? palette['md.ref.palette.primary80']
                : palette['md.ref.palette.primary40'],
              getSurfaceOpacityByElevation(2),
            ),
          }}
        >
          <Box flex={1} display='flex' alignItems='center'>
            <Box
              sx={{
                height: toolbarHeight,
                width: workspaceWidth,
                minWidth: workspaceWidth,
                background: isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : 'var(--md-sys-color-background-light)',
                mr: 3,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: applyOpacityOnColor(
                    isDarkMode
                      ? palette['md.ref.palette.primary80']
                      : palette['md.ref.palette.primary40'],
                    getSurfaceOpacityByElevation(2),
                  ),
                }}
              >
                {/* <img
                  src='/icon-128.png'
                  height={44}
                  width={44}
                  style={{
                    borderRadius: 5,
                    objectFit: 'cover',
                  }}
                /> */}
              </Box>
            </Box>
          </Box>
          <Stack
            gap={1}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            {standaloneMenus.map((menu: MenuItem) => {
              return (
                <AppBarControlPanel
                  key={menu.id}
                  tooltip={{
                    title: menu.name as string,
                    placement: 'bottom',
                  }}
                  active={pathname.includes(menu.basePath!)}
                  redirectTo={menu.path}
                  icon={menu.icon}
                  onClick={menu.onClick}
                  iconButtonSx={menuIconButtonSx}
                />
              );
            })}
          </Stack>
          <M3Button
            sx={{
              pl: 2,
              pr: 2,
            }}
            onClick={logout}
          >
            <LogoutIcon sx={{ fontSize: 22 }} />
            Log out
          </M3Button>
        </AppToolbar>
      </AppBar>
      <AppToolbar />
    </>
  );
}

export default AppNavigationBar;
