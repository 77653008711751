import {
  Avatar,
  Box,
  Divider,
  ListItemAvatar,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import InboxCardListItem from '../Inbox/InboxCardListItem';
import { M3ListItemText } from '../M3/M3ListItem';
import LoadMorePlaceholder from '../LoadMorePlaceholder';

import { Go2BotsMessageItem } from '../../types/go2bots';
import { useAppProvider } from '../../providers/app/app';
import { useUserProvider } from '../../providers/user/user';
import { getUserInitials } from '../../utils/user';
import { toURL } from '../../utils/url';

type Props = {
  scrollCounter: number;
  items: Go2BotsMessageItem[];
  isLoadingMore?: boolean;
  hasReachEnd?: boolean;
  onSelect?: (item: Go2BotsMessageItem) => void;
  onEndReached?: () => void;
};

export const width = 360;

const AIAIAIUsersList = ({
  scrollCounter,
  items,
  isLoadingMore,
  hasReachEnd,
  onSelect,
  onEndReached,
}: Props) => {
  const { toolbarHeight, subToolbarHeight } = useAppProvider();
  const [virtuosoScrollRef, setVirtuosoScrollRef] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    if (virtuosoScrollRef) {
      const container = virtuosoScrollRef;
      container.scrollTop = 0;
    }
  }, [virtuosoScrollRef, scrollCounter]);

  return (
    <Box
      display='flex'
      sx={{
        width: width,
        maxWidth: width,
        overflow: 'hidden',
        height: `calc(100vh - ${toolbarHeight}px - ${subToolbarHeight}px)`,
      }}
    >
      <Box flex={1} width={0} height='100%'>
        <Virtuoso
          scrollerRef={(ref) => setVirtuosoScrollRef(ref as HTMLElement)}
          totalCount={items.length + (isLoadingMore || hasReachEnd ? 1 : 0)}
          itemContent={(index) => {
            const item = items[index];

            if (!item) {
              if (isLoadingMore || hasReachEnd) {
                return (
                  <LoadMorePlaceholder
                    hasReachEnd={hasReachEnd}
                    isLoading={isLoadingMore}
                  />
                );
              }

              return <Box />;
            }

            return (
              <AIAIAIUserItem
                key={item.id}
                item={item}
                onClick={() => onSelect?.(item)}
              />
            );
          }}
          endReached={onEndReached}
          style={{
            height: '100%',
            overflow: 'hidden auto',
          }}
        />
      </Box>
      <Divider flexItem orientation='vertical' />
    </Box>
  );
};

export default AIAIAIUsersList;

type AIAIAIUserItemProps = {
  item: Go2BotsMessageItem;
  onClick?: (evt: SyntheticEvent) => void;
};
function AIAIAIUserItem({ item, onClick }: AIAIAIUserItemProps) {
  const { isDarkMode } = useAppProvider();
  const { getUser } = useUserProvider();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const user = getUser('user_id', item.user);
  const url = toURL(`/chat/message/${item.id}`);
  const isActive = pathname === url;

  return (
    <InboxCardListItem
      active={isActive}
      borderRadius={0}
      sx={{
        border: 0,
        borderRadius: 0,
        cursor: 'pointer',
        boxShadow: isDarkMode
          ? `0 0 1px 0 var(--md-ref-palette-neutral-variant30) !important`
          : `0 0 1px 0 var(--md-ref-palette-neutral-variant90) !important`,
      }}
      surfaceSx={{
        boxShadow: 'none',
      }}
      className={clsx({
        // unread: !!item.unread,
      })}
      onClick={(evt: SyntheticEvent) => {
        onClick?.(evt);
        navigate(url);
      }}
    >
      <ListItemAvatar>
        <Avatar src={user?.photo_url}>
          {
            getUserInitials(
              `${user?.first_name || ''} ${user?.last_name || ''}`,
            ).initial
          }
        </Avatar>
      </ListItemAvatar>
      <M3ListItemText
        primary={
          <Typography display='flex' alignItems='center' component='div'>
            <Typography component='div' fontWeight={500} flex={1}>
              {user?.first_name} {user?.last_name}
              {!(user?.first_name || user?.last_name) && (
                <Skeleton width={100} />
              )}
            </Typography>
            &nbsp;
            <span
              style={{
                opacity: 0.5,
                fontSize: 11,
              }}
            >
              {moment.utc(item.modified).local().fromNow()}
            </span>
          </Typography>
        }
        secondary={
          <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
            {item.title}
          </Typography>
        }
        sx={{
          '.MuiListItemText-secondary': {
            paddingRight: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </InboxCardListItem>
  );
}
