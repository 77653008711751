import React from 'react';
import { Stack, Typography } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

type Props = {};

const TopPanel: React.FC<Props> = () => {
  return (
    <Stack
      sx={{ flex: 1 }}
      flexDirection='row'
      alignItems='center'
      justifyContent='flex-start'
    >
      <AccessTimeOutlinedIcon sx={{ mr: 1 }} />
      <Typography flex={1} component='div' position='relative'>
        <Typography fontSize={20} fontWeight={500} component='div'>
          View Logs
        </Typography>
      </Typography>
    </Stack>
  );
};

export default TopPanel;
