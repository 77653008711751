import { Box, Tooltip, Typography } from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

import RichTextContent from '../RichEditor/RichTextContent';

import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';

type EODOverviewTooltipProps = {
  iconAsLabel?: boolean;
  label?: ReactRenderElement;
  overview?: string | null;
  desiredOutcome?: string | null;
  successMeasurement?: string | null;
};

const EODOverviewTooltip = ({
  iconAsLabel = false,
  label = 'See details',
  overview,
  desiredOutcome,
  successMeasurement,
}: EODOverviewTooltipProps) => {
  const { isDarkMode } = useAppProvider();

  const renderTaskTooltipRow = ({ title, description }: any) => {
    return (
      <Box p={1} pb={1.5}>
        <Typography
          component='div'
          fontSize={16}
          fontWeight={700}
          style={{
            color: isDarkMode
              ? 'var(--md-sys-color-on-background-light)'
              : 'var(--md-sys-color-on-background-dark)',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            fontSize: 16,
            opacity: 0.8,
            fontWeight: 400,
            lineHeight: 1.3,

            '.rich-text-content': {
              pointerEvents: 'auto',
            },
          }}
        >
          <RichTextContent content={description} />
        </Box>
      </Box>
    );
  };

  const renderTaskDescription = () => {
    return (
      <Tooltip
        leaveDelay={300}
        placement='right'
        title={
          <Box>
            {!!overview &&
              renderTaskTooltipRow({
                title: 'Overview',
                description: overview,
              })}
            {!!desiredOutcome &&
              renderTaskTooltipRow({
                title: 'Desired Outcome',
                description: desiredOutcome,
              })}
            {!!successMeasurement &&
              renderTaskTooltipRow({
                title: 'Measurement of Success',
                description: successMeasurement,
              })}
          </Box>
        }
      >
        <Box
          sx={{
            pl: iconAsLabel ? 1.2 : 1.8,
            pt: 0.24,
            userSelect: 'none',
          }}
        >
          <Typography
            fontSize={14}
            component='span'
            whiteSpace='normal'
            style={{
              color: isDarkMode
                ? 'var(--md-ref-palette-secondary80)'
                : 'var(--md-ref-palette-secondary40)',
            }}
            sx={{
              '&:hover': {
                '.MuiSvgIcon-root': {
                  opacity: `0.8 !important`,
                },
              },
            }}
          >
            {iconAsLabel ? (
              <HelpOutlinedIcon
                style={{
                  fontSize: 22,
                  opacity: 0.5,
                }}
              />
            ) : (
              label
            )}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const hasTaskDescription = !!(
    overview ??
    desiredOutcome ??
    successMeasurement
  );

  if (!hasTaskDescription) {
    return null;
  }

  return renderTaskDescription();
};

export default EODOverviewTooltip;
