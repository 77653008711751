import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import { FormStateRet, useForm } from '../components/BasicForm';
import AutocompleteSelectionPopover from '../components/Filters/AutocompleteSelectionPopover';
import AbsoluteCenterBox from '../components/AbsoluteCenterBox';
import BasicIframe from '../components/BasicIframe';
import { M3Button } from '../components/M3/M3Button';
import DateRangeSelectionPopover from '../components/Filters/DateRangeSelectionPopover';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useAppProvider } from '../providers/app/app';
import { MenuOption } from '../types/app';
import { useDomoEmbedDashboard } from '../hooks/services/domo';
import { ReactRenderElement } from '../types/types';
import { useGo2Members } from '../hooks/member';
import { useLocation } from 'react-router-dom';
import { parseURL } from '../utils/url';
import { useGetEODForms } from '../hooks/focus';
import { useMetabaseIFrame } from '../hooks/utils/metabase';

type Props = {};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

type AutocompleteFormState = {
  checked: {
    [key: string]: boolean;
  };
  optionById: {
    [key: string]: MenuOption;
  };
};

type RenderCardItemProps = {
  title?: ReactRenderElement;
  html?: string;
  isLoading: boolean;
  sx?: SxProps;
};

const SearchAnswersNumbers = (props: Props) => {
  const [focusName, setFocusName] = useState<string>('');
  const [focusIds, setFocusIds] = useState<string[]>([]);

  const { search } = useLocation();
  const parsedUrl = parseURL(search);
  const { current } = useWorkspaceProvider();
  const {
    focuses,
    focusFormFocusIds,
    questionsParams: searchAnswersQuestionsParams,
    setQuestionsParams,
  } = useMetadataProvider();
  const { subToolbarHeight, isDarkMode } = useAppProvider();

  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const dateForm = useForm<DateFormState>({
    start: searchAnswersQuestionsParams.date_start,
    end: searchAnswersQuestionsParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });
  const memberForm = useForm<AutocompleteFormState>({
    checked: {},
    optionById: {},
  });

  const divisionGo2Members = useGo2Members({
    division: current!.id === 'my-squad' ? undefined : +current!.id,
    employee_ids: current!.id === 'my-squad' ? current!.memberId : undefined,
    limit: 300,
    is_active: true,
  });
  const divisionGo2MembersData = (divisionGo2Members.data?.results ?? []).map(
    (member) => ({
      id: member.employee_id,
      name: `${member.full_name}`,
    }),
  );

  const eodForm = useGetEODForms(
    {
      task: focusIds.join(','),
    },
    {
      enabled: !!focusIds.length,
    },
  );

  // get the eodForm data based on task ids
  const eodFormResultsData = eodForm.data?.results[0].fields;

  /**
   * then find the appropriate parent_field_name by determining first if the field is_parent true then otherwise
   * check for priority number is 1 and the is_there_a_parent is false
   */
  const eodFormFields = eodFormResultsData?.filter((field) => {
    return (
      field.question_type === 'quantitative' &&
      (field.is_parent || (field.priority === 1 && !field.is_there_a_parent))
    );
  })[0];
  // use to embed the parent_field_name for numbers output
  const eodParentFieldName = eodFormFields?.label ?? '';

  /**
   * NOTE: Previously used for domo, now it's going to be used to metabase
   */
  const dashboardParams = useMemo(
    () => ({
      key: `search_answers_numbers_html_${
        current?.id ?? current?.queryId ?? ''
      }`,
      embed_type: 'stories',
      focus: focusName,
      parent_field_name: eodParentFieldName,
      embed_id: process.env.REACT_APP_DOMO_SEARCH_ANSWERS_NUMBERS_EMBED_ID!,
      date_range: [dateForm.formState.start!, dateForm.formState.end!],
      division_name: current!.id === 'my-squad' ? undefined : current!.name!,
      go2_member_id: getAutocompleteSelectedByForm(
        memberForm,
        current!.memberId,
      ),
    }),
    [
      current,
      focusName,
      eodParentFieldName,
      dateForm.formState.start!,
      dateForm.formState.end!,
      memberForm.formState,
      current!.memberId,
      eodForm.data,
    ],
  );
  const { isLoading: dashboardIsLoading, data: dashboardHTML } =
    useDomoEmbedDashboard(dashboardParams as any);

  const metabaseIFrameParams = useMemo(
    () => ({
      date: dashboardParams.date_range.join('~'),
      division: dashboardParams.division_name || null,
      focus: dashboardParams.focus || null,
      parent: dashboardParams.parent_field_name || null,
      member_id: dashboardParams.go2_member_id
        ? dashboardParams.go2_member_id.split(',')
        : [],
    }),
    [dashboardParams],
  );
  const metabaseIFrame = useMetabaseIFrame({
    value: 74,
    params: metabaseIFrameParams,
  });

  function getAutocompleteSelectedByForm(
    form: FormStateRet<AutocompleteFormState>,
    members?: string,
  ) {
    let values: string[] = Object.keys(form.formState.checked || {}).filter(
      (key) => {
        return form.formState.checked[key];
      },
    );
    return values.length ? values.join(',') : members ? members : undefined;
  }

  const renderChipFilters = (
    label: string,
    form: FormStateRet<AutocompleteFormState>,
  ) => {
    const selected = Object.keys(form.formState.checked || {}).filter((key) => {
      return form.formState.checked[key];
    });
    return selected.map((id) => {
      const option = form.formState.optionById[id];
      return (
        <Chip
          key={option.id}
          size='small'
          label={
            <>
              <span style={{ opacity: 0.4 }}>{label}:</span>{' '}
              <strong>{option.value}</strong>
            </>
          }
          sx={{
            fontSize: 12,
            background: 'transparent',
          }}
          onDelete={() => {
            form.updateState((state: AutocompleteFormState) => {
              const stateChecked = state.checked || {};
              const stateOptionById = state.optionById || {};
              const checked = !stateChecked[option.id];
              const newState = {
                ...state,
                checked: {
                  ...stateChecked,
                  [option.id]: checked,
                },
                optionById: {
                  ...stateOptionById,
                  [option.id]: option,
                },
              };
              delete newState.checked[option.id];
              delete newState.optionById[option.id];

              return newState;
            });
          }}
        />
      );
    });
  };

  const renderFilterResults = () => {
    let hasFilters = [
      ...Object.values(memberForm.formState.checked || {}),
    ].some((v) => !!v);

    if (!hasFilters) return null;

    return (
      <Stack
        gap={1}
        direction='column'
        alignItems='flex-start'
        sx={{ p: 3, pt: 2 }}
      >
        <Stack
          gap={1}
          flex={1}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          flexWrap='wrap'
          style={{ marginLeft: -8 }}
        >
          {renderChipFilters('Member', memberForm)}
        </Stack>
        <Divider sx={{ opacity: 0.8, flex: 1, width: '100%' }} />
        <M3Button
          variant='outlined'
          size='small'
          onClick={() => {
            memberForm.resetState();
          }}
          sx={{
            mt: 0.6,
            height: 30,
          }}
        >
          <Typography fontWeight={500} fontSize={12}>
            Clear Filters
          </Typography>
        </M3Button>
      </Stack>
    );
  };

  const renderCardEmbed = ({
    title,
    html,
    isLoading,
    sx,
  }: RenderCardItemProps) => {
    const wrapperSX: SxProps = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      overflow: 'hidden',
      background: isDarkMode
        ? 'var(--md-sys-color-background-dark)'
        : '#f5f5f5',
    };
    return (
      <>
        <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            boxShadow: isDarkMode
              ? '0 0 0 1px rgba(255, 255, 255, 0.1)'
              : '0 0 0 1px #e0e0e0',
          }}
        >
          <Box
            sx={{
              ...wrapperSX,
              '&:before': {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
                position: 'absolute',
                background: 'rgba(255, 255, 255, 0.05)',
              },
            }}
          >
            <AbsoluteCenterBox>
              <CircularProgress size={24} />
            </AbsoluteCenterBox>
            {!!metabaseIFrame && (
              <Box sx={{ ...wrapperSX, ...sx }}>
                <BasicIframe src={metabaseIFrame.iframe} />
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (parsedUrl.fid && focuses.length > 0) {
      const fid = parsedUrl.fid;

      const getFocus = focuses.find(
        (focus) => focus.focus_id === fid?.toString(),
      );

      if (getFocus) {
        const focusId = getFocus.focus_id;

        const getFocusName = focusFormFocusIds[focusId];

        if (getFocusName.length > 0) {
          setFocusName(getFocusName[0].focus);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedUrl.fid]);

  useEffect(() => {
    if (focuses.length && focusName) {
      const getFocusIds = focuses
        .filter((f) => f.focus === focusName)
        .map((f) => f.focus_id);

      if (getFocusIds.length) {
        setFocusIds(getFocusIds);
      }
    }
  }, [focusName, focuses]);

  useEffect(() => {
    memberForm.resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    setQuestionsParams({
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState.start, dateForm.formState.end]);

  return (
    <>
      <DocumentTitle title='Numbers' trailingTitle='Focus' />
      <MainContainer
        ref={mainContainerRef}
        sx={{
          maxWidth: null,
          p: 2,
          pt: 0,
          position: 'relative',
          height: `calc(100vh - ${subToolbarHeight + 8}px)`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box width='100%'>
          <AppActionToolbar>
            <FilterColumnPanel
              name='date'
              label='Date'
              displayValue={`${moment(dateForm.formState.start).format(
                'MMM D, YYYY',
              )} - ${moment(dateForm.formState.end).format('MMM D, YYYY')}`}
              sx={{ ml: -1 }}
              paperSx={{ ml: 1 }}
              textFieldSx={{ width: 240 }}
            >
              <DateRangeSelectionPopover
                startDate={dateForm.formState.start}
                endDate={dateForm.formState.end}
                onChange={({ start, end }) => {
                  dateForm.updateState((state) => ({ ...state, start, end }));
                }}
              />
            </FilterColumnPanel>
            <AutocompleteSelectionPopover
              width={240}
              title='Members'
              placeholder='Select members'
              options={
                current!.id === 'my-squad'
                  ? current!.members!.map((member) => ({
                      id: member.id,
                      value: member.name,
                    }))
                  : divisionGo2MembersData.map((m) => ({
                      id: m.id,
                      value: m.name,
                    }))
              }
              form={memberForm}
            />
          </AppActionToolbar>
          {renderFilterResults()}
        </Box>
        <Box
          sx={{
            height: 0,
            flexGrow: 2,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              top: -10,
              left: -16,
              right: -16,
              bottom: -16,
              position: 'absolute',
            }}
          >
            {renderCardEmbed({
              html: dashboardHTML,
              isLoading: dashboardIsLoading,
            })}
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default SearchAnswersNumbers;
