import React from 'react';
import { Box, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { ReactComponent as IntercomFacebookIcon } from '../assets/intercom/facebook.svg';
import { useAppProvider } from '../providers/app/app';

type AppFooterProps = {
  withDrawerPadding?: boolean;
  withGo2HelpLink?: boolean;
  go2HelpText?: string;
  go2HelpLink?: string;
};

export const appFooterHeight = 140;

const AppFooter = ({
  withDrawerPadding = true,
  withGo2HelpLink = true,
  go2HelpText = 'Go2 Help Center',
  go2HelpLink = 'https://support.go2.io/en/',
}: AppFooterProps) => {
  const { pathname } = useLocation();
  const { drawerWidth, isDarkMode } = useAppProvider();
  /**
   * NOTE: Filter the path that don't need the padding since there are pages
   * that doesn't have a drawer
   */
  const paddingLeft =
    pathname === '/welcome' ||
    pathname === '/signup' ||
    pathname === '/signup/numbers'
      ? 0
      : drawerWidth;

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        height: appFooterHeight,
        background: isDarkMode
          ? 'var(--md-ref-palette-neutral20)'
          : 'var(--md-ref-palette-primary99)',
      }}
      style={{
        paddingLeft: withDrawerPadding ? paddingLeft : 0,
      }}
    >
      {withGo2HelpLink && (
        <Link
          underline='none'
          sx={{
            mb: 2,
            fontSize: 18,
            opacity: 0.5,
            color: 'var(--md-sys-color-on-surface-light)',
          }}
          target='_blank'
          href={go2HelpLink}
        >
          {go2HelpText}
        </Link>
      )}
      <Link
        underline='none'
        target='_blank'
        href='https://www.facebook.com/go2recruitment/'
      >
        <IntercomFacebookIcon
          style={{
            width: 17,
            height: 17,
            opacity: 0.5,
            color: 'var(--md-sys-color-on-surface-light)',
          }}
        />
      </Link>
    </Box>
  );
};

export default AppFooter;
