import React, { Dispatch, SetStateAction, useState } from 'react';
import moment from 'moment';
import { Box, Divider, Stack, Typography } from '@mui/material';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';

import { TimeTrackerDailySummaryItem } from '../../../types/worklogs';
import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../utils/date';
import { M3MenuItem } from '../../M3/M3MenuItem';
import { useUserProvider } from '../../../providers/user/user';
import { TaskData } from '../../../types/task';

type UserLogsProps = {
  date: string;
  data: (TimeTrackerDailySummaryItem & { active: boolean })[];
  setTaskData: Dispatch<SetStateAction<TaskData>>;
  setIsTaskOpen: Dispatch<SetStateAction<boolean>>;
};

const UserLogs: React.FC<UserLogsProps> = ({
  date,
  data,
  setTaskData,
  setIsTaskOpen,
}) => {
  const { getUser } = useUserProvider();
  const [hoverId, setIsHoverId] = useState<null | number>(null);

  const groupDate = moment(date).set({
    hour: 0,
    minute: 0,
    second: 0,
  });
  const total = data.map((wl) => wl.total_duration).reduce((a, b) => a + b, 0);

  const hms = toHHMMSS(total, {
    json: true,
    hrDigit: 1,
    minDigit: 1,
  }) as HHMMSSOptionsJSONRet;

  const handleOnClick = (summary: UserLogsProps['data'][0]) => {
    const user = getUser('user_id', summary.user);

    const task = { ...summary, taskId: summary.task.toString() };

    setTaskData((state) => ({
      ...state,
      task,
      date,
      user,
    }));

    setIsTaskOpen(true);
  };

  return (
    <Box pt={4}>
      <Typography
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        component='div'
        fontSize={16}
        fontWeight={500}
        textAlign='center'
        sx={{
          pb: 0.4,
        }}
      >
        <span>
          {groupDate.format('MMM D, YYYY')}
          <span
            style={{
              opacity: 0.3,
              fontSize: 12,
              paddingLeft: 4,
              fontWeight: 400,
            }}
          >
            ({groupDate.format('ddd')})
          </span>
        </span>
        <span
          style={{
            fontSize: 16,
            paddingTop: 2,
            whiteSpace: 'nowrap',
          }}
        >
          {hms.hasHours ? `${hms.hours}h` : ''} {hms.minutes}m
        </span>
      </Typography>
      <Divider />
      {data.map((summary) => {
        const isHover = hoverId === summary.task;
        const hms = toHHMMSS(summary.total_duration, {
          json: true,
          hrDigit: 1,
          minDigit: 1,
        }) as HHMMSSOptionsJSONRet;

        return (
          <Box key={summary.task} mx={-2}>
            <M3MenuItem
              LinkComponent={Box}
              sx={{
                py: 0,
                borderRadius: 8,
                height: 'initial',
                minHeight: 'initial',
                whiteSpace: 'initial',
              }}
              onMouseEnter={() => setIsHoverId(summary.task)}
              onMouseLeave={() => setIsHoverId(null)}
              onClick={() => handleOnClick(summary)}
            >
              <WysiwygOutlinedIcon
                style={{
                  opacity: isHover ? 0.8 : 0.29,
                  fontSize: 16,
                  marginLeft: -4,
                }}
                sx={{
                  mr: 1,
                }}
              />
              <Stack
                gap={2}
                flex={1}
                py={1}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  opacity: summary.active || isHover ? 0.8 : 0.5,
                }}
              >
                <Typography
                  component='div'
                  fontSize={14}
                  fontWeight={summary.active ? 500 : 400}
                  pr={2}
                >
                  {summary.task_name}
                </Typography>
                <Typography
                  component='div'
                  fontSize={14}
                  whiteSpace='nowrap'
                  fontWeight={summary.active ? 500 : 400}
                >
                  {hms.hasHours ? `${hms.hours}h` : ''} {hms.minutes}m
                </Typography>
              </Stack>
            </M3MenuItem>
          </Box>
        );
      })}
    </Box>
  );
};

export default UserLogs;
