import { Box, Chip, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';

import M3SurfaceContainer from '../../M3/M3SurfaceContainer';
import { M3IconButton } from '../../M3/M3Button';
import FocusQuestionFormModalView from './FocusQuestionFormModalView';

import { EODFieldParams } from '../../../types/report';
import { toTitleCase } from '../../../utils/string';
import { useAppProvider } from '../../../providers/app/app';
import { applyOpacityOnColor } from '../../../utils/color';

type Props = {
  editable?: boolean;
  eodFormId?: number;
  item: EODFieldParams;
  questions: EODFieldParams[];
  onSave?: (data: EODFieldParams, fields: EODFieldParams[]) => void;
  onDeleteQuestion?: (itemId: string) => void;
};

const FocusQuestionItem = ({
  editable,
  eodFormId,
  item,
  questions = [],
  onSave,
  onDeleteQuestion,
}: Props) => {
  const {
    isDarkMode,
    palettes: { main: palette },
  } = useAppProvider();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Box py={1}>
        <M3SurfaceContainer
          elevation={1}
          sx={{
            display: 'flex',
          }}
        >
          <Box
            flex={1}
            sx={{
              py: 2,
              px: 3,
            }}
          >
            <Typography component='div' fontWeight={500} fontSize={20}>
              {item.question_type === 'quantitative' ? (
                <NumbersOutlinedIcon
                  style={{
                    fontSize: 21,
                    marginBottom: -2,
                    marginRight: -2,
                  }}
                />
              ) : (
                <QuestionAnswerOutlinedIcon
                  style={{
                    fontSize: 20,
                    marginBottom: -3,
                  }}
                />
              )}
              &nbsp;&nbsp;
              {item.label}
            </Typography>
            <Typography
              component='div'
              fontSize={15}
              sx={{
                opacity: 0.8,
              }}
            >
              {item.caption}
            </Typography>
            <Box display='flex' alignItems='center' gap={1} pt={5}>
              <Chip
                label={toTitleCase(item.question_type || 'qualitative')}
                size='small'
                style={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              />
              {item.required && (
                <Chip
                  label='Required'
                  size='small'
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: 12,
                    fontWeight: 500,
                    background: isDarkMode
                      ? applyOpacityOnColor(
                          palette['md.ref.palette.error80'],
                          0.2,
                        )
                      : applyOpacityOnColor(
                          palette['md.ref.palette.error40'],
                          0.2,
                        ),
                  }}
                />
              )}
              {item.is_parent && (
                <Chip
                  label='Parent'
                  size='small'
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
              )}
              {!item.is_parent && item.is_there_a_parent && (
                <Chip
                  label='Child'
                  size='small'
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
              )}
              <Box
                mr={-8}
                pr={0.5}
                gap={2}
                flex={1}
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
              >
                {/* <M3Button>Set as parent</M3Button> */}
                {/* <Box>
                  <DragIndicatorOutlinedIcon
                    style={{
                      fontSize: 28,
                    }}
                  />
                </Box> */}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              pr: 2,
              pt: 2,
            }}
          >
            {editable && (
              <>
                <M3IconButton onClick={() => setIsOpen(true)}>
                  <EditOutlinedIcon />
                </M3IconButton>
                <M3IconButton
                  sx={{
                    minWidth: 40,
                    minHeight: 40,
                  }}
                  onClick={() => onDeleteQuestion?.(item.id)}
                >
                  <DeleteOutlinedIcon sx={{ fontSize: 20 }} />
                </M3IconButton>
              </>
            )}
          </Box>
        </M3SurfaceContainer>
      </Box>
      <Modal open={isOpen}>
        <FocusQuestionFormModalView
          eodFormId={eodFormId}
          initialData={item}
          questions={questions}
          onSave={onSave}
          close={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default FocusQuestionItem;
