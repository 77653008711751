import React, { Dispatch, SyntheticEvent } from 'react';
import { Typography } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { M3TextField } from '../../M3/M3TextField';

type SearchUserProps = {
  firstColWidth: number;
  searchUserText: string;
  setSearchUserText: Dispatch<React.SetStateAction<string>>;
};

const SearchUser: React.FC<SearchUserProps> = ({
  firstColWidth,
  searchUserText,
  setSearchUserText,
}) => {
  return (
    <Typography
      component='div'
      minWidth={firstColWidth}
      fontWeight={500}
      fontSize={16}
      position='relative'
      display='flex'
    >
      <SearchOutlined
        style={{
          top: -7,
          zIndex: 4,
          opacity: 0.3,
          fontSize: 22,
          position: 'relative',
        }}
      />
      <M3TextField
        stripAllAutoProps
        variant='standard'
        placeholder='Search users...'
        value={searchUserText}
        InputProps={{
          disableUnderline: true,
          sx: {
            height: 38,
            paddingLeft: 2,
          },
        }}
        sx={{
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        }}
        onChange={(evt: SyntheticEvent) =>
          setSearchUserText((evt.target as HTMLInputElement).value)
        }
      />
    </Typography>
  );
};

export default SearchUser;
