import { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import {
  searchAnswerMaxCharLimit,
  trimSearchAnswerQuestion,
} from '../../components/AppDrawer';

import { useDomoFilterFocus, useDomoFilterQuestion } from '../../hooks/domo';
import {
  useIntercomAdmins,
  useIntercomCollections,
} from '../../hooks/intercom';
import { ContentTypeID } from '../../types/content-type';
import { DomoFilterFocus, DomoFilterQuestion } from '../../types/domo';
import { IterableObject, ReactRenderElement } from '../../types/types';
import { useIntercomAllData } from '../intercom';
import { useUserProvider } from '../user/user';
import { useWorkspaceProvider } from '../workspace/workspace';
import { getWeekRange } from '../../utils/date';
import { useContentTypes } from '../../hooks/content-type';

type MetadataProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */

export type QuestionGroup = {
  /** string here can be multiple a generated uuid */
  id: string;
  display: string;
  questions: DomoFilterQuestion[];
};

export type FocusGroup = {};

export type ContentTypeModels =
  | 'report'
  | 'bhrtimeoffrequests'
  | 'jobdescription'
  | 'botkeystrokemessage'
  | 'go2botmessage'
  | 'schedulechangerequest';
type MetadataInitialState = {
  contentTypes: {
    report: ContentTypeID;
    bhrtimeoffrequests: ContentTypeID;
    project: ContentTypeID;
    [key: string]: ContentTypeID;
  };
  // focuses items
  focuses: DomoFilterFocus[];
  focusesRequestState: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  focusFormFocusIds: IterableObject<DomoFilterFocus[]>;
  // question items is a unique list of questions items, grouped/unique display of menu
  questions: QuestionGroup[];
  questionsParams: QuestionsParams;
  questionsRequestState: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  /**
   * Container for form:focus(es) ids
   * ie. {[form_id]: string[]}
   */
  questionFormFocusIds: IterableObject<DomoFilterQuestion[]>;
  teamManifestsParams: TeamManifestsParams;
  teamTimeParams: TeamTimeParams;
  teamScheduleParams: TeamScheduleParams;
  teamTeamOneOnOneParams: TeamTeamOneOnOneParams;
  teamMyOneOnOneParams: TeamMyOneOnOneParams;
  teamTeamSprintsParams: TeamTeamSprintsParams;
  teamMySprintsParams: TeamMySprintsParams;
  resourcesParams: ResourcesParams;
  utilizationParams: UtilizationParams;
};
type BasicParams = {
  date_start: string;
  date_end: string;
};
type QuestionsParams = BasicParams;
type TeamManifestsParams = BasicParams & {
  need_help?: boolean;
};
type ResourcesParams = BasicParams;
type TeamTimeParams = BasicParams;
type TeamScheduleParams = BasicParams;
type TeamTeamOneOnOneParams = BasicParams;
type TeamMyOneOnOneParams = BasicParams;
type TeamTeamSprintsParams = BasicParams;
type TeamMySprintsParams = BasicParams;
type UtilizationParams = BasicParams;
/**
 * Reducers that mutate the state
 */
type MetadataReducers = {
  setQuestionsParams: (questionsParams: Partial<QuestionsParams>) => void;
  setTeamManifestsParams: (
    teamManifestsParams: Partial<TeamManifestsParams>,
  ) => void;
  setTeamTimeParams: (teamTimeParams: Partial<TeamTimeParams>) => void;
  setTeamScheduleParams: (
    teamScheduleParams: Partial<TeamScheduleParams>,
  ) => void;
  setTeamTeamOneOnOneParams: (
    teamTeamOneOnOneParams: Partial<TeamTeamOneOnOneParams>,
  ) => void;
  setTeamMyOneOnOneParams: (
    teamMyOneOnOneParams: Partial<TeamMyOneOnOneParams>,
  ) => void;
  setTeamTeamSprintsParams: (
    teamTeamSprintsParams: Partial<TeamTeamSprintsParams>,
  ) => void;
  setTeamMySprintsParams: (
    teamMySprintsParams: Partial<TeamMySprintsParams>,
  ) => void;
  setResourcesParams: (resourcesParams: Partial<ResourcesParams>) => void;
  setUtilizationParams: (utilizationParams: Partial<UtilizationParams>) => void;
};
/**
 * Single store
 */
type MetadataStore = MetadataInitialState & MetadataReducers;

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const basicDateParams = {
  date_start: getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
    DEFAULT_DATE_FORMAT,
  ),
  date_end: getWeekRange(moment().endOf('week').add(1, 'day')).end.format(
    DEFAULT_DATE_FORMAT,
  ),
};
const basicDate30DaysParams = {
  date_start: moment().subtract(29, 'days').format(DEFAULT_DATE_FORMAT),
  date_end: moment().format(DEFAULT_DATE_FORMAT),
};
/**
 * Initial state / store
 */
const initialStore: MetadataStore = {
  /**
   * NOTE: hardcoding content types
   */
  contentTypes: {
    report: 29,
    bhrtimeoffrequests: 217,
    project: 369,
  },
  focuses: [],
  focusesRequestState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  focusFormFocusIds: {},
  questions: [],
  questionsParams: {
    ...basicDate30DaysParams,
  },
  questionsRequestState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  questionFormFocusIds: {},
  teamManifestsParams: {
    need_help: false,
    ...basicDate30DaysParams,
  },
  teamTimeParams: {
    ...basicDateParams,
  },
  teamScheduleParams: {
    ...basicDateParams,
  },
  teamTeamOneOnOneParams: {
    ...basicDate30DaysParams,
  },
  teamMyOneOnOneParams: {
    ...basicDate30DaysParams,
  },
  teamTeamSprintsParams: {
    ...basicDate30DaysParams,
  },
  teamMySprintsParams: {
    ...basicDate30DaysParams,
  },
  utilizationParams: {
    ...basicDateParams,
  },
  resourcesParams: {
    ...basicDateParams,
  },
  setQuestionsParams: (questionsParams: Partial<QuestionsParams>) => {
    throw new Error('Implementation required');
  },
  setTeamManifestsParams: (
    teamManifestsParams: Partial<TeamManifestsParams>,
  ) => {
    throw new Error('Implementation required');
  },
  setTeamTimeParams: (teamTimeParams: Partial<TeamTimeParams>) => {
    throw new Error('Implementation required');
  },
  setTeamScheduleParams: (teamScheduleParams: Partial<TeamScheduleParams>) => {
    throw new Error('Implementation required');
  },
  setUtilizationParams: (utilizationParams: Partial<UtilizationParams>) => {
    throw new Error('Implementation required');
  },
  setResourcesParams: (resourcesParams: Partial<ResourcesParams>) => {
    throw new Error('Implementation required');
  },
  setTeamTeamOneOnOneParams: (
    teamTeamOneOnOneParams: Partial<TeamTeamOneOnOneParams>,
  ) => {
    throw new Error('Implementation required');
  },
  setTeamMyOneOnOneParams: (
    teamMyOneOnOneParams: Partial<TeamMyOneOnOneParams>,
  ) => {
    throw new Error('Implementation required');
  },
  setTeamTeamSprintsParams: (
    teamTeamSprintsParams: Partial<TeamTeamSprintsParams>,
  ) => {
    throw new Error('Implementation required');
  },
  setTeamMySprintsParams: (
    teamMySprintsParams: Partial<TeamMySprintsParams>,
  ) => {
    throw new Error('Implementation required');
  },
};

/**
 * Context Instance
 */
const MetadataContext = createContext<MetadataStore>(initialStore);

export function useMetadataProvider(): MetadataStore {
  return useContext(MetadataContext);
}

export function MetadataProvider({ children }: MetadataProviderProps) {
  const [state, setState] = useState<MetadataStore>(initialStore);
  const { current: currentWorkspace, squadMembers } = useWorkspaceProvider();
  const { setUniqueIdsToFetch } = useUserProvider();

  const { data: contentTypesData } = useContentTypes({
    limit: 100,
    model: (
      [
        'report',
        'bhrtimeoffrequests',
        'project',
        'botkeystrokemessage',
        'go2botmessage',
        'schedulechangerequest',
      ] as ContentTypeModels[]
    ).join(),
  });

  /**
   * This hook only runs when there's a workspace (OS), only go2 employees
   * and partner members will that workspace
   */
  const filterQuestion = useDomoFilterQuestion(
    {
      divisions:
        currentWorkspace?.id === 'my-squad'
          ? currentWorkspace?.queryName!
          : currentWorkspace?.name!,
      date_start: state.questionsParams.date_start,
      date_end: state.questionsParams.date_end,
      members: currentWorkspace?.memberId || undefined,
    },
    {
      enabled: !!currentWorkspace,
    },
  );

  /**
   * This hook is intended for search answers focus v2
   */
  const filterAnswerFocus = useDomoFilterFocus(
    {
      divisions:
        currentWorkspace?.id === 'my-squad'
          ? currentWorkspace?.queryName!
          : currentWorkspace?.name!,
      date_start: state.questionsParams.date_start,
      date_end: state.questionsParams.date_end,
      has_question_type: 'quantitative',
    },
    {
      enabled: !!currentWorkspace,
    },
  );

  /**
   * Get the help center on initialization since in the `jobseekers` user
   * It needs to show immediately the list of collections
   */
  useIntercomAllData({
    useIntercomAdminsHook: useIntercomAdmins,
    useIntercomCollectionsHook: useIntercomCollections,
  });

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function setQuestionsParams(questionsParams: Partial<QuestionsParams>) {
    setState((state) => ({
      ...state,
      questionsParams: { ...state.questionsParams, ...questionsParams },
    }));
  }

  function setTeamManifestsParams(
    teamManifestsParams: Partial<TeamManifestsParams>,
  ) {
    setState((state) => ({
      ...state,
      teamManifestsParams: {
        ...state.teamManifestsParams,
        ...teamManifestsParams,
      },
    }));
  }

  function setTeamTimeParams(teamTimeParams: Partial<TeamTimeParams>) {
    setState((state) => ({
      ...state,
      teamTimeParams: {
        ...state.teamTimeParams,
        ...teamTimeParams,
      },
    }));
  }

  function setTeamScheduleParams(
    teamScheduleParams: Partial<TeamScheduleParams>,
  ) {
    setState((state) => ({
      ...state,
      teamScheduleParams: {
        ...state.teamScheduleParams,
        ...teamScheduleParams,
      },
    }));
  }

  function setTeamTeamOneOnOneParams(
    teamTeamOneOnOneParams: Partial<TeamTeamOneOnOneParams>,
  ) {
    setState((state) => ({
      ...state,
      teamTeamOneOnOneParams: {
        ...state.teamTeamOneOnOneParams,
        ...teamTeamOneOnOneParams,
      },
    }));
  }

  function setTeamMyOneOnOneParams(
    teamMyOneOnOneParams: Partial<TeamMyOneOnOneParams>,
  ) {
    setState((state) => ({
      ...state,
      teamMyOneOnOneParams: {
        ...state.teamMyOneOnOneParams,
        ...teamMyOneOnOneParams,
      },
    }));
  }

  function setTeamTeamSprintsParams(
    teamTeamSprintsParams: Partial<TeamTeamSprintsParams>,
  ) {
    setState((state) => ({
      ...state,
      teamTeamSprintsParams: {
        ...state.teamTeamSprintsParams,
        ...teamTeamSprintsParams,
      },
    }));
  }

  function setTeamMySprintsParams(
    teamMySprintsParams: Partial<TeamMySprintsParams>,
  ) {
    setState((state) => ({
      ...state,
      teamMySprintsParams: {
        ...state.teamMySprintsParams,
        ...teamMySprintsParams,
      },
    }));
  }

  function setUtilizationParams(utilizationParams: Partial<UtilizationParams>) {
    setState((state) => ({
      ...state,
      utilizationParams: {
        ...state.utilizationParams,
        ...utilizationParams,
      },
    }));
  }

  function setResourcesParams(resourcesParams: Partial<ResourcesParams>) {
    setState((state) => ({
      ...state,
      resourcesParams: {
        ...state.resourcesParams,
        ...resourcesParams,
      },
    }));
  }

  /**
   * Define all side effects here...
   */
  useEffect(() => {
    if (filterAnswerFocus.data) {
      let focusesDict: IterableObject<DomoFilterFocus[]> = {};
      let focusesGroup: DomoFilterFocus[] = [];

      filterAnswerFocus.data.forEach((focus) => {
        const key = focus.focus_id;

        focusesDict[key] = focusesDict[key] || [];
        focusesDict[key].push(focus);

        if (!focusesGroup.find((f) => f.focus === focus.focus)) {
          focusesGroup.push(focus);
        }
      });

      setState((state) => {
        return {
          ...state,
          focuses: focusesGroup,
          focusFormFocusIds: focusesDict,
        };
      });
    }
  }, [filterAnswerFocus.data]);

  useEffect(() => {
    /**
     * Get unique questions, there are questions that are the same but different
     * only on focus, in order not to look duplicated in side menu we need to join the dupe
     */
    setState((state) => {
      let questionsGroup: QuestionGroup[] = [];
      let formFocusDict: IterableObject<DomoFilterQuestion[]> = {};

      const groupQuestions = (questions: DomoFilterQuestion[] = []) => {
        questions.forEach((q) => {
          /**
           * Use the truncated/trimmed question to be the key to avoid duplicate
           * question display in the sidebar, that also matches the focus/tasks
           */
          const qt: string = q.question.replace(/\s+/g, ' ').trim();
          // trimming the display/question into limit characters
          q.display = trimSearchAnswerQuestion(qt).substring(
            0,
            searchAnswerMaxCharLimit,
          );

          /**
           * Grouping the questions based on their form/eod form
           */
          const key = q.form_id;
          formFocusDict[key] = formFocusDict[key] || [];
          formFocusDict[key].push(q);
        });

        /**
         * Get the unique questions only or parent question for qualitative/quantitative questions
         * This also converts children questions to be attached to their parent.
         */
        const multiGroupedQuestions = Object.keys(formFocusDict).map((key) => {
          let fields = formFocusDict[key];
          let uniqueFields: DomoFilterQuestion[] = [];
          /**
           * NOTE: Intended to use !== 'quantitative', so that any non-updated
           * form yet will be caught, and assumed to be qualitative
           */
          let qualitativeFields = fields.filter(
            (f) => f.question_type !== 'quantitative',
          );
          let quantitativeFields = fields.filter(
            (f) => f.question_type === 'quantitative',
          );
          const setInsertFieldsToUnique = (
            quantQualFields: DomoFilterQuestion[] = [],
          ) => {
            // check for qualitative/quantitative questions
            if (quantQualFields.length) {
              // check for a parent question
              const parentField = quantQualFields.find((f) => f.is_parent);
              /**
               * Only 1 parent per focus, per qualitative/quantitative so it's safe to add only the parent
               * but add all its children questions
               */
              if (parentField) {
                parentField.child_questions = quantQualFields.filter(
                  (q) => q.id !== parentField.id,
                );
                uniqueFields.push(parentField);
              } else {
                uniqueFields = uniqueFields.concat(quantQualFields);
              }
            }
          };
          setInsertFieldsToUnique(qualitativeFields);
          setInsertFieldsToUnique(quantitativeFields);

          return uniqueFields;
        });

        /**
         * Flatten out the display, removing duplicate display of question,
         * while also adding the associated other questions
         */
        multiGroupedQuestions.forEach((ugq) => {
          ugq.forEach((q) => {
            const qg = questionsGroup.find((qg) => qg.display === q.display);
            if (qg) {
              qg.questions.push(q);
            } else {
              questionsGroup.push({
                id: q.id,
                display: q.display,
                questions: [q],
              });
            }
          });
        });

        return questionsGroup;
      };
      const questionWinData: DomoFilterQuestion[] = [
        {
          id: 'win',
          question: 'Win',
          label: 'What will make today a win?',
          question_type: 'qualitative',
          is_parent: true,
          is_there_a_parent: true,
          form_id: 0,
          task_id: 'win',
          display: 'Win',
        },
      ];
      const qualitativeQuestions = (filterQuestion.data || []).filter((q) => {
        // TODO: Need to check what type of form field this question for old
        // question not set as quantitative but have an type of "number"
        if (q.question_type === 'quantitative') {
          return false;
        }
        return true;
      });

      questionsGroup = groupQuestions([
        ...questionWinData,
        ...qualitativeQuestions,
      ]);

      return {
        ...state,
        questions: questionsGroup,
        questionFormFocusIds: formFocusDict,
      };
    });
  }, [filterQuestion.data]);

  /**
   * Cache the questions request state
   */
  useEffect(() => {
    setState((state) => {
      return {
        ...state,
        questionsRequestState: {
          isLoading: filterQuestion.isLoading,
          isSuccess: filterQuestion.isSuccess,
          isError: filterQuestion.isError,
        },
      };
    });
  }, [
    filterQuestion.isLoading,
    filterQuestion.isSuccess,
    filterQuestion.isError,
  ]);

  /**
   * Fetch the squad members user's data on init
   */
  useEffect(() => {
    setUniqueIdsToFetch({
      employee_ids: squadMembers.map(({ id }) => id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squadMembers]);

  useEffect(() => {
    if (!contentTypesData) return;

    const contentTypes = contentTypesData.results ?? [];

    setState((state) => ({
      ...state,
      contentTypes: {
        ...state.contentTypes,
        ...contentTypes.reduce((prev, accu) => {
          prev[accu.model] = +accu.id;
          return prev;
        }, {} as IterableObject),
      },
    }));
  }, [contentTypesData, setState]);

  return (
    <MetadataContext.Provider
      value={{
        ...state,
        setQuestionsParams,
        setTeamManifestsParams,
        setTeamTimeParams,
        setTeamScheduleParams,
        setTeamTeamOneOnOneParams,
        setTeamMyOneOnOneParams,
        setTeamTeamSprintsParams,
        setTeamMySprintsParams,
        setUtilizationParams,
        setResourcesParams,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
}
