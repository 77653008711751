import React from 'react';
import { Box, Divider, SxProps, Typography } from '@mui/material';

import Timeline from './Timeline';
import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';
import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../utils/date';

type TimelineCardProps = {
  columnSx: SxProps;
  data?: UseFetchTimeDetailsResponse[];
};

const TimelineCard: React.FC<TimelineCardProps> = ({ columnSx, data }) => {
  const totalTime = data?.reduce((total, curr) => total + curr.duration, 0);
  const hms = toHHMMSS(totalTime!, {
    json: true,
  }) as HHMMSSOptionsJSONRet;
  const { hours, minutes } = hms;

  return (
    <Box
      flex={1}
      sx={{
        ...columnSx,
      }}
    >
      <Divider orientation='vertical' flexItem />
      <Timeline data={data} />
      <Typography align='center'>
        {hours}h {minutes}m
      </Typography>
    </Box>
  );
};

export default TimelineCard;
