import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { useMutationApi, useQueryApi } from './global/useApi';
import { EODFieldParams } from '../types/report';
import { EODForms } from '../types/forms';
import { useIdentifier } from './global/useIdentifier';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';

type UseDeleteFocusParams = {
  id?: string | number;
  is_active?: boolean | undefined;
};
export function useDeleteFocus(
  params: UseDeleteFocusParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UseDeleteFocusParams, any>(
    `/time-tracker/tasks/${params.id}/`,
    {},
    { ...axiosConfig, method: 'PATCH' },
  );
}
type UseGetEODFormsParams = ListQuery & {
  task?: string;
};
export function useGetEODForms<R = EODForms>(
  params: UseGetEODFormsParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseGetEODFormsParams>({
    params,
    baseIdentifier: 'useGetEODForms',
    identifierKeys: ['task'],
    queryParamKeys: ['task'],
  });

  return useQueryApi<UseGetEODFormsParams, ListResult<R>>(
    identifiers,
    '/forms/eod-forms/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseDeleteQuestionParams = {
  id?: string | number;
  fields?: EODFieldParams[];
};
export function useDeleteQuestion(
  params: UseDeleteQuestionParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UseDeleteQuestionParams, any>(
    `/forms/eod-forms/${params.id}/`,
    {},
    { ...axiosConfig, method: 'PATCH' },
  );
}

// Set focus as generic or not
type UseSetGenericParams = {
  id?: string | number;
  is_generic?: boolean | undefined;
};
export function useSetGeneric(
  params: UseDeleteFocusParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UseSetGenericParams, any>(
    `/time-tracker/tasks/${params.id}/`,
    {},
    { ...axiosConfig, method: 'PATCH' },
  );
}
