import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import SlackSvgIcon from '../components/SvgIcons/SlackSvgIcon';

import { useSetSlackToken } from '../hooks/slack';
import { parseURL, toURL } from '../utils/url';
import { useCurrentProfile } from '../hooks/profile';

type Props = {
  children?: any;
};

type SlackConnectResponseCode = {
  code: string;
  state?: string;
};

type RendererProps = {
  title: string;
  subtitle: string;
};

function Renderer({ title, subtitle }: RendererProps) {
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{ width: '100vw', height: '100vh', padding: '10vh 20px 10vh' }}
      >
        <SlackSvgIcon height={60} />
        <Typography variant='h4' display='block' fontSize={22} fontWeight={300}>
          {title}
        </Typography>
        <Typography
          variant='h6'
          display='block'
          fontSize={16}
          fontWeight={400}
          sx={{ mt: 1, opacity: 0.8 }}
        >
          {subtitle}
        </Typography>
        <div style={{ height: 200 }}></div>
      </Box>
    </>
  );
}

function SlackConnect({ children }: Props) {
  const { data: userProfile, error: userProfileError } = useCurrentProfile();
  let slackTokenMutation = useSetSlackToken();
  let location = useLocation();

  let redirectInSec = 3;

  let searchParams = (
    location.search ? parseURL(location.search!) : { code: null, state: null }
  ) as SlackConnectResponseCode;

  const redirect = () => {
    let url = toURL('https://slack.com/oauth/v2/authorize', {
      scope: '',
      user_scope:
        'channels:read chat:write files:write groups:read remote_files:share users.profile:write',
      redirect_uri: process.env.REACT_APP_SLACK_REDIRECT_URI!,
      client_id: process.env.REACT_APP_SLACK_CLIENT_ID!,
    });
    window.location.href = url;
  };

  /**
   * Redirects user on a given set of time when slack isn't configured yet
   */
  useEffect(() => {
    let timer: any = null;
    // if slack is not configured... redirect to main to connect
    if (
      !userProfile?.is_slack_configured &&
      !searchParams.code &&
      !slackTokenMutation.isLoading
    ) {
      timer = setTimeout(redirect, redirectInSec * 1000);
    }
    return () => timer && clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.code,
    userProfile?.is_slack_configured,
    slackTokenMutation.isLoading,
  ]);

  /**
   * Check for success callback from slack oauth page and update our server
   */
  useEffect(() => {
    if (
      !slackTokenMutation.isLoading &&
      !slackTokenMutation.isError &&
      !slackTokenMutation.isSuccess &&
      !userProfile?.is_slack_configured &&
      searchParams.code
    ) {
      slackTokenMutation.mutate({
        code: searchParams.code,
        redirect_uri: process.env.REACT_APP_SLACK_REDIRECT_URI!,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    slackTokenMutation.isLoading,
    slackTokenMutation.isError,
    slackTokenMutation.isSuccess,
    userProfile?.is_slack_configured,
    searchParams.code,
  ]);

  /**
   * Check when it's successfully set the token to the server and we refetch
   * updated info of current logged in employee
   */
  useEffect(() => {
    if (slackTokenMutation.isSuccess) {
      window.location.href = '/';
    }
  }, [slackTokenMutation.isSuccess]);

  if (!!userProfileError) return null;

  /**
   * Redirect already configured user to main page
   */
  if (slackTokenMutation?.isError) {
    return (
      <Renderer
        title='Connecting slack failed...'
        subtitle='Please contact your administrator'
      />
    );
  }

  /**
   * Redirect already configured user to main page
   */
  if (userProfile?.is_slack_configured) {
    return <Navigate to='/' />;
  }

  /**
   * Showing some loading screen
   * */
  if (searchParams?.code) {
    return (
      <Renderer
        title='Connecting slack account...'
        subtitle='Please wait while we verify the connection'
      />
    );
  }

  /**
   * Showing some visual before redirecting users
   */
  return (
    <Renderer
      title='Connect your slack account'
      subtitle={`The app requires you to connect to slack, you will be redirected in ${redirectInSec} seconds`}
    />
  );
}

export default SlackConnect;
