import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Radio,
  Select,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import React, {
  useState,
  SyntheticEvent,
  ReactNode,
  useEffect,
  useMemo,
  Fragment,
  forwardRef,
  useRef,
  useCallback,
  CSSProperties,
} from 'react';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

import RichEditor from '../RichEditor/RichEditor';
import { useForm } from '../BasicForm';
import EODKPIView from './EODKPIView';
import { M3Button } from '../M3/M3Button';
import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import QuoteView from './QuoteView';
import WindowScrollTop from '../WindowScrollTop';
import { M3MenuItem } from '../M3/M3MenuItem';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import EODShipLinkForm, { ShipmentLinkItem } from './EODShipLinkForm';
import RichTextContent from '../RichEditor/RichTextContent';
import AIAssistSideSheet, {
  useAIAssistSideSheet,
} from '../SideSheet/AIAssistSideSheet';

import {
  useDailyReportQuoteToday,
  usePostReport,
  useReportingReport,
} from '../../hooks/report';
import {
  EODReportDataContent,
  EODReportKPI,
  EODReportParams,
  SODReportData,
} from '../../types/report';
import {
  useCurrentProfile,
  useUserRecentWorkLogs,
  useUserShiftReportingStatus,
} from '../../hooks/profile';
import { UserWorkLogItem } from '../../types/profile';
import { IterableObject } from '../../types/types';
import { isNotNullAndUndefined } from '../../utils/object';
import { useIsCardFocused } from '../../hooks/utils/card';
import { useAppProvider } from '../../providers/app/app';
import { isDidYouShipAnythingVisible } from '../../utils/report';
import { MenuOption } from '../../types/app';
import { useHasViewPermission } from '../HasViewPermission';
import { parseURL } from '../../utils/url';
import * as posthog from '../../services/posthog';

type HTMLStateMap = IterableObject<string> & {
  today_headline: string;
  did_get_win_about: string;
  news_worthy: string;
  next_shift: string;
  need_help_about: string;
  did_you_ship_anything_about?: string;
};
type HeadTitle = {
  id?: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  tooltipId?: string;
};
export type WorkLogMapState = {
  task_id: string;
  passed: boolean;
  data: IterableObject;
  total?: number;
};

type stepName = 'Write' | 'Quote' | 'Review';
const steps = ['Write', 'Quote'];
export const feelingSelection: {
  id: number;
  value: '😞' | '😐' | '😃' | '😠';
}[] = [
  {
    id: 1,
    value: '😞',
  },
  {
    id: 2,
    value: '😐',
  },
  {
    id: 3,
    value: '😃',
  },
  {
    id: 4,
    value: '😠',
  },
];

const yesNoSelection = [
  { id: 'yes', value: 'Yes' },
  { id: 'no', value: 'No' },
];

type EODFormStaticState = IterableObject & {
  feeling_selected: number;
  did_get_win: null | 'yes' | 'no';
  need_help: null | 'yes' | 'no';
  did_you_ship_anything?: null | 'yes' | 'no';
  shipment_links?: ShipmentLinkItem[];
};

export type EODFormModalViewProps = ModalCardViewCloseProps & {
  fullView?: boolean;
};

const EODForm = forwardRef(
  ({ close, animateOnMount, fullView }: EODFormModalViewProps, ref) => {
    const { isDarkMode, updateActionKeyCounter } = useAppProvider();
    const [activeStep, setActiveStep] = useState(0);
    const [scrollElement, setScrollElement] = useState<
      HTMLDivElement | null | undefined
    >(null);
    const postReport = usePostReport<EODReportParams>();
    const recentWorkLogs = useUserRecentWorkLogs(
      {},
      {
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: true,
      },
    );
    const shiftReportingStatus = useUserShiftReportingStatus({ type: 'EOD' });
    const sodId =
      shiftReportingStatus.data?.last_report.type === 'SOD'
        ? shiftReportingStatus.data?.last_report.id
        : 0;
    const { data: sodReport } = useReportingReport<SODReportData>(
      { id: sodId },
      { enabled: !!sodId },
    );

    const { data: currentProfile } = useCurrentProfile();
    const willDidYouShipFieldVisible = isDidYouShipAnythingVisible(
      currentProfile!,
    );

    const {
      formState,
      handleChange,
      updateState: formStaticUpdateState,
    } = useForm<EODFormStaticState>({
      feeling_selected: 0,
      did_get_win: null,
      need_help: null,
      ...(willDidYouShipFieldVisible
        ? {
            did_you_ship_anything: null,
            shipment_links: [],
          }
        : null),
    });
    /**
     * NOTE: we added a new flag for request since the new flow requires
     * a series of fetching before submitting.
     */
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const parsedURL = parseURL(window.location.search);

    const [workLogMapState, setWorkLogMapState] = useState<{
      [key: string]: WorkLogMapState;
    }>({});

    const isPromptEngineer = useHasViewPermission({
      roles: ['superuser', 'prompt_engineer'],
    });

    const sideSheetContainerRef = useRef<null | HTMLElement>(null);
    const [, setSideSheetKey] = useState(0);
    const aiAssist = useAIAssistSideSheet({
      openOnInt: true,
    });

    const workLogs = useMemo(() => {
      if (!recentWorkLogs.data) return [];
      return recentWorkLogs.data.map((workLog) => {
        workLog = { ...workLog };
        /**
         * Fallback for taskId/task_id naming convention
         */
        workLog.task_id = workLog.taskId || workLog.task_id;
        workLog.task_name = workLog.taskName || workLog.task_name;
        /**
         * Order the eod form fields by its priority
         */
        if (workLog.eod_form) {
          workLog.eod_form.fields.sort((a, b) => a.priority - b.priority);
        }
        return workLog;
      });
    }, [recentWorkLogs.data]);

    const [htmlStateMap, setHtmlStateMap] = useState<HTMLStateMap>({
      today_headline: '',
      did_get_win_about: '',
      news_worthy: '',
      next_shift: '',
      need_help_about: '',
      ...(willDidYouShipFieldVisible
        ? {
            did_you_ship_anything_about: '',
          }
        : null),
    });

    const dailyReportQuoteToday = useDailyReportQuoteToday(
      { key: 'eod_quote' },
      {
        keepPreviousData: false,
        cacheTime: 0,
      },
    );

    const isCardFocused = useIsCardFocused();

    const isInBetweenStep = (stepName: stepName) =>
      steps[activeStep] === stepName;

    const isFirstStep = () => activeStep === 0;

    const isLastStep = () => activeStep === steps.length - 1;

    const nextStep = () => {
      setIsDrawerOpen(false);
      setActiveStep((step) => step + 1);
    };

    const prevStep = () => {
      if (isLastStep()) {
        setIsDrawerOpen(true);
        setActiveStep((step) => step - 1);
      } else {
        setIsDrawerOpen(false);
        setActiveStep((step) => step - 1);
      }
    };

    const onEditorValueChange = (name: string) => (html: string) => {
      setHtmlStateMap((state) => ({ ...state, [name]: html }));
    };

    const isAllFormFilled = () => {
      const {
        /**
         * NOTE: Be sure to always destructure the optional fields that doesn't need immediate counting
         */
        need_help_about,
        did_you_ship_anything_about,
        ...requiredHtmlStateMap
      } = htmlStateMap;
      const keys = Object.keys(requiredHtmlStateMap);
      const workLogKeys = Object.keys(workLogMapState);

      return !!(
        keys.length &&
        keys.length ===
          keys.filter((key) => !!requiredHtmlStateMap[key]).length &&
        formState.feeling_selected &&
        formState.did_get_win &&
        // checking if need help is filled
        (formState.need_help === 'yes'
          ? need_help_about
          : formState.need_help) &&
        /**
         * check first if did you ship anything is visible, and
         * the did you ship about is filled also
         */
        (willDidYouShipFieldVisible
          ? formState.did_you_ship_anything === 'yes'
            ? did_you_ship_anything_about
            : formState.did_you_ship_anything
          : true) &&
        // Check also the kpi forms if all required has been filled
        (!!workLogs.length
          ? workLogKeys.length ===
            workLogKeys.filter((key) => workLogMapState[key].passed).length
          : true)
      );
    };

    function getTotalRequiredToFill() {
      const {
        /**
         * NOTE: Be sure to always destructure the optional fields that doesn't need immediate counting
         */
        need_help_about,
        did_you_ship_anything_about,
        ...restRequiredHtmlStateMap
      } = htmlStateMap;
      const {
        /**
         * NOTE: Be sure to always destructure the optional fields that doesn't need immediate counting
         */
        did_you_ship_anything,
        shipment_links,
        ...restRequiredFormState
      } = formState;

      const keys = Object.keys(restRequiredHtmlStateMap);
      const formKeys = Object.keys(restRequiredFormState);
      const workLogKeys = Object.keys(workLogMapState);

      // Total number of static fields to fill up
      let staticTotal = keys.length + formKeys.length;
      // total number of static fields already filled up
      let staticFilled =
        keys.filter((k) => {
          const value = htmlStateMap[k];
          return isNotNullAndUndefined(value) && !!value;
        }).length +
        formKeys.filter((k) => {
          const value = formState[k];
          return isNotNullAndUndefined(value) && !!value;
        }).length;

      /**
       * When did you ship anything field visible is visible let's increase the
       * total static
       */
      if (willDidYouShipFieldVisible) {
        ++staticTotal;
      }
      /**
       * If did you ship anything is yes, increase static total for
       * the tell us more (include links)
       */
      if (did_you_ship_anything === 'yes') {
        ++staticTotal;
      }

      /**
       * Increase the total static count when answering yes on need help since
       * the "Can you provide a little info on what you need help with?" field
       * will be visible
       */
      if (formState.need_help === 'yes') {
        ++staticTotal;

        /**
         * Increase the total filled count when answering yes on need help and has value
         */
        if (isNotNullAndUndefined(need_help_about) && !!need_help_about) {
          ++staticFilled;
        }
      }
      /**
       * If did you ship anything has any value. Then increase total filled
       * since either yes/no is considered as filled
       */
      if (did_you_ship_anything) {
        ++staticFilled;
        /**
         * Increase the total filled count when answering yes on did you ship anything
         * and did you ship about has filled
         */
        if (
          did_you_ship_anything === 'yes' &&
          isNotNullAndUndefined(did_you_ship_anything_about) &&
          !!did_you_ship_anything_about
        ) {
          ++staticFilled;
        }
      }

      /**
       * Get only the total fields on each worklogs that is required
       */
      const eodFormTotalFields = workLogs
        .filter((wl) => !!wl.eod_form)
        .map((wl) => wl.eod_form!.fields.filter((f) => f.required).length)
        .reduce((prev: number, curr: number) => prev + curr, 0);
      /**
       * Get the total filled on each worklogs that is required
       */
      const eodFormTotalFilled = workLogKeys
        .map((key) => {
          const { total } = workLogMapState[key];
          return total || 0;
        })
        .reduce((prev: number, curr: number) => prev + curr, 0);

      return {
        staticFields: {
          total: staticTotal,
          filled: staticFilled,
        },
        kpiFields: {
          total: eodFormTotalFields,
          filled: eodFormTotalFilled,
        },
      };
    }

    function onKPIDataChange(workLog: UserWorkLogItem) {
      return (
        data: IterableObject,
        dataPassed: boolean,
        totalFilled: number,
      ) => {
        setWorkLogMapState((state) => ({
          ...state,
          [workLog.task_id]: {
            task_id: workLog.task_id,
            passed: dataPassed,
            data: data,
            total: totalFilled,
          },
        }));
      };
    }

    function getWorkLogMapStateFieldsData() {
      const kpis: EODReportKPI[] = [];

      workLogs.forEach((workLog) => {
        const mapState = workLogMapState[workLog.task_id] ?? {
          data: {},
        };

        kpis.push({
          duration: workLog.duration,
          task_id: workLog.task_id,
          task_name: workLog.task_name,
          division_id: workLog.division_id,
          division_name: workLog.division_name,
          data: mapState.data,
          fields: workLog.eod_form
            ? (workLog.eod_form.fields ?? []).map((field) => {
                return {
                  fieldId: field.id,
                  type: field.type,
                  label: field.label,
                  value: mapState.data[field.id] ?? null,
                };
              })
            : [],
        });
      });

      return kpis;
    }

    function getPostReportData(): EODReportParams {
      return {
        type: 'EOD',
        data: {
          overview: {
            ...htmlStateMap,
            ...formState,
          } as EODReportDataContent,
          kpis: getWorkLogMapStateFieldsData(),
          sod: sodReport,
          metadata: {
            report_type_platform: parsedURL.report_type_platform,
          },
        },
      };
    }

    function onShipmentLinkDataChange(items: ShipmentLinkItem[]) {
      formStaticUpdateState((state) => {
        return {
          ...state,
          shipment_links: items,
        };
      });
    }

    const renderFeelingSelection = () => {
      return (
        <Stack direction='row' gap={5}>
          {feelingSelection.map((item) => {
            return (
              <Box
                key={item.id}
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleChange({
                    target: {
                      name: 'feeling_selected',
                      value: item.id,
                    },
                  });
                }}
              >
                <Typography component='div' fontSize={40} sx={{ mb: -1.2 }}>
                  {item.value}
                </Typography>
                <Radio
                  value={item.id}
                  checked={formState.feeling_selected === item.id}
                />
              </Box>
            );
          })}
        </Stack>
      );
    };

    const renderFormStep = () => {
      const autoFormOffProps: { [key: string]: 'off' | undefined } = {
        autoSave: 'off',
        autoCorrect: 'off',
        autoComplete: 'off',
        autoCapitalize: 'off',
      };
      const renderHeadTitle = ({
        title,
        subtitle,
        id,
        tooltipId,
      }: HeadTitle) => {
        return (
          <Box id={id}>
            <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
              {title}
              {tooltipId && (
                <span style={{ position: 'relative' }}>
                  <HelpOutlinedIcon
                    id={tooltipId}
                    sx={{
                      ml: 1,
                      fontSize: 22,
                      opacity: 0.5,
                      cursor: 'pointer',
                      position: 'absolute',
                      ':hover': {
                        opacity: 1,
                      },
                    }}
                    style={{
                      color: isDarkMode
                        ? 'var(--md-ref-palette-neutral80)'
                        : 'var(--md-ref-palette-neutral40)',
                    }}
                  />
                </span>
              )}
            </Typography>
            {!!subtitle && (
              <Typography
                component='div'
                fontSize={14}
                sx={{ opacity: 0.6, mb: 1, mt: -1 }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        );
      };
      const renderSelectFormField = ({ name, value, options = [] }: any) => {
        return (
          <FormControl fullWidth>
            <Select
              fullWidth
              {...autoFormOffProps}
              name={name}
              value={value ?? ''}
              onChange={(evt) => handleChange(evt as SyntheticEvent)}
              onFocus={isCardFocused.focus}
              onBlur={isCardFocused.blur}
            >
              {options.map((menu: MenuOption) => (
                <M3MenuItem key={menu.id} value={menu.id}>
                  {menu.value}
                </M3MenuItem>
              ))}
            </Select>
            {!value && (
              <FormHelperText
                sx={{
                  top: 15,
                  left: 0,
                  bottom: 0,
                  fontSize: 14,
                  position: 'absolute',
                  pointerEvents: 'none',
                }}
              >
                Select...
              </FormHelperText>
            )}
          </FormControl>
        );
      };

      return (
        <Box
          sx={{
            pt: 3,
            pb: 8,
            ...(fullView
              ? {
                  minHeight: `calc(100vh - 64px - 94px)`,
                }
              : {}),
          }}
        >
          <Box>
            {renderHeadTitle({
              id: 'eod_today_headline',
              title: `Today's Headline:`,
              subtitle:
                'If there is one thing about your day, thing that happened today, or even something important about tomorrow that you want your peers, EM, or manager to know, what is it?  This can be an accomplishment, a callout, a concern or even something personal.',
            })}
            <RichEditor
              withAIAssist
              withAIEditPrompt={isPromptEngineer}
              value={htmlStateMap.today_headline}
              onValueChange={onEditorValueChange('today_headline')}
              placeholder='Write here...'
              onFocus={isCardFocused.focus}
              onBlur={isCardFocused.blur}
              chatGPTPlaygroundProps={{
                title: `Today's Headline:`,
                prompt_identifier: 'completions-reports-eod-today-headline',
              }}
              onAssistClick={() => {
                posthog.capture('manifest assist clicked', {
                  type: 'EOD',
                  title: `Today's headline`,
                });
                aiAssist.start({
                  title: `Today's Headline:`,
                  prompt_identifier: 'completions-reports-eod-today-headline',
                  data: {
                    prompt_text: htmlStateMap.today_headline,
                  },
                });
              }}
            />
            <br />
            {renderHeadTitle({
              id: 'eod_feeling_selected',
              title: 'How did you feel about your day?',
              subtitle: '',
            })}
            {renderFeelingSelection()}
            <br />
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
            >
              <Box flexWrap='nowrap' minWidth={170}>
                {renderHeadTitle({
                  id: 'eod_did_get_win',
                  title: (
                    <div style={{ marginTop: 8 }}>Did you get your win?</div>
                  ),
                  subtitle: '',
                })}
              </Box>
              {renderSelectFormField({
                name: 'did_get_win',
                value: formState.did_get_win ?? '',
                options: yesNoSelection,
              })}
            </Stack>
            {isNotNullAndUndefined(sodReport?.data?.today_win) && (
              <Box mt={2}>
                <M3SurfaceContainer elevation={1} borderRadius={1}>
                  <Typography
                    component='div'
                    fontSize={14}
                    fontWeight={500}
                    sx={{
                      pt: 2,
                      pl: 3,
                      pb: 0.5,
                    }}
                  >
                    What will make today a win?
                  </Typography>
                  <Divider sx={{ ml: 3, mr: 3, mb: 2, opacity: 0.8 }} />
                  <Typography
                    component='div'
                    sx={{
                      pb: 2,
                      fontSize: 14,
                      '.rich-text-content': {
                        pl: 3,
                        pr: 3,
                      },
                    }}
                  >
                    <RichTextContent
                      content={sodReport?.data?.today_win ?? ''}
                    />
                  </Typography>
                </M3SurfaceContainer>
              </Box>
            )}
            <br />
            {renderHeadTitle({
              id: 'eod_did_get_win_about',
              title: 'Tell us a little something about it',
              subtitle: (
                <>
                  Why did you go with yes or no. If yes, brag a little and tell
                  us how you got that W. (W stands for Win)
                  <br />
                  If you didn’t get the W, will try again tomorrow? What did you
                  learn from today that will help you get the W tomorrow?
                </>
              ),
            })}
            <RichEditor
              withAIAssist
              withAIEditPrompt={isPromptEngineer}
              value={htmlStateMap.did_get_win_about}
              onValueChange={onEditorValueChange('did_get_win_about')}
              placeholder='Write here...'
              onFocus={isCardFocused.focus}
              onBlur={isCardFocused.blur}
              chatGPTPlaygroundProps={{
                title: 'Tell us a little something about it',
                prompt_identifier: 'completions-reports-eod-did-get-win-about',
              }}
              onAssistClick={() => {
                posthog.capture('manifest assist clicked', {
                  type: 'EOD',
                  title: 'Tell us a little something about it',
                });
                aiAssist.start({
                  title: 'Tell us a little something about it',
                  prompt_identifier:
                    'completions-reports-eod-did-get-win-about',
                  data: {
                    prompt_text: htmlStateMap.did_get_win_about,
                  },
                });
              }}
            />
            <br />
            {renderHeadTitle({
              id: 'eod_news_worthy',
              title: `What else about today was newsworthy? Any accomplishments, learnings or escalations others should be aware of? 
            `,
              subtitle: `Include info that will help keep you and others aligned. If you are bored typing it… nobody is reading it and it doesn’t matter.  What actually matters? Did you learn something interesting about a customer, a project or a peer? 
            `,
            })}
            <RichEditor
              withAIAssist
              withAIEditPrompt={isPromptEngineer}
              value={htmlStateMap.news_worthy}
              onValueChange={onEditorValueChange('news_worthy')}
              placeholder='Write here...'
              onFocus={isCardFocused.focus}
              onBlur={isCardFocused.blur}
              chatGPTPlaygroundProps={{
                title: `What else about today was newsworthy? Any accomplishments, learnings or escalations others should be aware of? 
              `,
                prompt_identifier: 'completions-reports-eod-news-worth',
              }}
              onAssistClick={() => {
                posthog.capture('manifest assist clicked', {
                  type: 'EOD',
                  title: 'Newsworthy',
                });
                aiAssist.start({
                  title: `What else about today was newsworthy? Any accomplishments, learnings or escalations others should be aware of? 
                `,
                  prompt_identifier: 'completions-reports-eod-news-worth',
                  data: {
                    prompt_text: htmlStateMap.news_worthy,
                  },
                });
              }}
            />
            <br />
            {renderHeadTitle({
              id: 'eod_next_shift',
              title: 'Whats a key focus for next shift?',
            })}
            <RichEditor
              withAIAssist
              withAIEditPrompt={isPromptEngineer}
              value={htmlStateMap.next_shift}
              onValueChange={onEditorValueChange('next_shift')}
              placeholder='Write here...'
              onFocus={isCardFocused.focus}
              onBlur={isCardFocused.blur}
              chatGPTPlaygroundProps={{
                title: 'Whats a key focus for next shift?',
                prompt_identifier: 'completions-reports-eod-next-shift',
              }}
              onAssistClick={() => {
                posthog.capture('manifest assist clicked', {
                  type: 'EOD',
                  title: 'Focus for next shift',
                });
                aiAssist.start({
                  title: 'Whats a key focus for next shift?',
                  prompt_identifier: 'completions-reports-eod-next-shift',
                  data: {
                    prompt_text: htmlStateMap.next_shift,
                  },
                });
              }}
            />
            <br />
            {renderHeadTitle({
              id: 'eod_need_help',
              title: `Do you need help from your manager to make the most of your workday tomorrow?`,
              tooltipId: 'eod-need-help-tooltip',
            })}
            {renderSelectFormField({
              name: 'need_help',
              value: formState.need_help ?? '',
              options: yesNoSelection,
            })}
            {formState.need_help === 'yes' && (
              <>
                <br />
                <br />
                {renderHeadTitle({
                  id: 'eod_need_help_about',
                  title: `Can you provide a little info on what you need help with?`,
                })}
                <RichEditor
                  withAIAssist
                  withAIEditPrompt={isPromptEngineer}
                  value={htmlStateMap.need_help_about}
                  onValueChange={onEditorValueChange('need_help_about')}
                  placeholder='Write here...'
                  onFocus={isCardFocused.focus}
                  onBlur={isCardFocused.blur}
                  chatGPTPlaygroundProps={{
                    title: `Can you provide a little info on what you need help with?`,
                    prompt_identifier:
                      'completions-reports-eod-need-help-about',
                  }}
                  onAssistClick={() => {
                    posthog.capture('manifest assist clicked', {
                      type: 'EOD',
                      title: 'Little info on what help needs',
                    });
                    aiAssist.start({
                      title: `Can you provide a little info on what you need help with?`,
                      prompt_identifier:
                        'completions-reports-eod-need-help-about',
                      data: {
                        prompt_text: htmlStateMap.need_help_about,
                      },
                    });
                  }}
                />
              </>
            )}
            {willDidYouShipFieldVisible && (
              <>
                {formState.need_help !== 'yes' && <br />}
                <br />
                {renderHeadTitle({
                  id: 'eod_did_you_ship_anything',
                  title: `Did you ship anything today?`,
                  tooltipId: 'eod-did-you-ship-anything-tooltip',
                })}
                {renderSelectFormField({
                  name: 'did_you_ship_anything',
                  value: formState.did_you_ship_anything ?? '',
                  options: yesNoSelection,
                })}
              </>
            )}
            {formState.did_you_ship_anything === 'yes' && (
              <>
                <br />
                <br />
                {renderHeadTitle({
                  id: 'eod_did_you_ship_anything_about',
                  title: `Tell us more about what did you ship today`,
                })}
                <RichEditor
                  withAIAssist
                  withAIEditPrompt={isPromptEngineer}
                  value={htmlStateMap.did_you_ship_anything_about}
                  onValueChange={onEditorValueChange(
                    'did_you_ship_anything_about',
                  )}
                  placeholder='Write here...'
                  onFocus={isCardFocused.focus}
                  onBlur={isCardFocused.blur}
                  chatGPTPlaygroundProps={{
                    title: `Tell us more about what did you ship today`,
                    prompt_identifier:
                      'completions-reports-eod-did-you-ship-anything-about',
                  }}
                  onAssistClick={() => {
                    posthog.capture('manifest assist clicked', {
                      type: 'EOD',
                      title: 'Ship today',
                    });
                    aiAssist.start({
                      title: `Tell us more about what did you ship today`,
                      prompt_identifier:
                        'completions-reports-eod-did-you-ship-anything-about',
                      data: {
                        prompt_text: htmlStateMap.did_you_ship_anything_about,
                      },
                    });
                  }}
                />
                <EODShipLinkForm
                  links={formState.shipment_links}
                  onDataChange={onShipmentLinkDataChange}
                />
              </>
            )}
          </Box>
          {workLogs.map((workLog) => {
            return (
              <Fragment key={workLog.task_id}>
                <Divider sx={{ mt: 4, mb: 3, ml: -4, mr: -4 }} />
                <EODKPIView
                  initialData={workLogMapState[workLog.task_id]?.data}
                  workLog={workLog}
                  onDataChange={onKPIDataChange(workLog)}
                  onFocus={isCardFocused.focus}
                  onBlur={isCardFocused.blur}
                  aiAssist={aiAssist}
                />
              </Fragment>
            );
          })}
        </Box>
      );
    };

    const renderQuoteStep = () => {
      return (
        <QuoteView
          reportType='EOD'
          fullView={fullView}
          quoteToday={dailyReportQuoteToday.data}
        />
      );
    };

    const renderPrevNextButtons = () => {
      const { staticFields, kpiFields } = getTotalRequiredToFill();

      let remaining =
        staticFields.total +
        kpiFields.total -
        (staticFields.filled + kpiFields.filled);

      return (
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ p: 2, pt: 1, pb: 1, minHeight: 56 }}
        >
          <Box
            display='flex'
            justifyContent='flex-start'
            sx={{ minWidth: '20%' }}
          >
            {isInBetweenStep('Quote')
              ? null
              : !postReport.isSuccess &&
                !isFirstStep() && (
                  <M3Button
                    color='primary'
                    variant='contained'
                    onClick={prevStep}
                    sx={{ pl: 2, pr: 2, minWidth: 100 }}
                  >
                    Previous
                  </M3Button>
                )}
          </Box>
          <Box
            flex={1}
            flexDirection='column'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Typography component='div'>
              <strong>{remaining}</strong>{' '}
              <span style={{ opacity: 0.8 }}>things left</span>
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{ minWidth: '20%' }}
          >
            {isInBetweenStep('Quote') || postReport.isSuccess ? null : (
              <M3Button
                color='primary'
                variant='contained'
                disabled={
                  isFirstStep()
                    ? isAllFormFilled()
                      ? false
                      : true
                    : isSubmitting ||
                      postReport.isLoading ||
                      postReport.isSuccess
                }
                sx={{ pl: 2, pr: 2, minWidth: 100 }}
                onClick={() => {
                  // if it's the last step, then submit the form...
                  if (isFirstStep()) {
                    recentWorkLogs.refetch();
                    setIsSubmitting(true);
                  }
                  // else, go show next
                  else {
                    nextStep();
                  }
                }}
              >
                {isFirstStep()
                  ? isSubmitting || postReport.isLoading
                    ? 'Please wait...'
                    : postReport.isSuccess
                    ? 'All done'
                    : 'Submit'
                  : 'Next'}
              </M3Button>
            )}
          </Box>
        </Stack>
      );
    };

    const renderTopPanel = () => {
      return (
        <>
          <Typography
            component='div'
            variant='h6'
            fontWeight={500}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' alignItems='center' style={{ width: 200 }}>
              Reality
            </Box>
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={{ flex: 1, width: 0 }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    disabled
                    color='inherit'
                    style={{
                      paddingLeft: label === 'Quote' ? 20 : undefined,
                    }}
                    sx={{
                      '.MuiStepIcon-root.Mui-active': {
                        color: 'var(--md-ref-palette-primary40)',
                      },
                    }}
                  >
                    {label !== 'Quote' && (
                      <Typography component='div' fontWeight={500}>
                        {label}
                      </Typography>
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <span style={{ width: 120 }}></span>
          </Typography>
        </>
      );
    };

    const renderQuestionsDrawerSummary = () => {
      return (
        <Box
          ref={sideSheetContainerRef}
          sx={{
            top: 8,
            right: 0,
            zIndex: 1,
            width: 300,
            opacity: 0,
            position: 'absolute',
            // transition: 'transform 200ms',
            transform: 'translate3d(100%, 0, 0)',
          }}
        >
          <AIAssistSideSheet
            fixed={false}
            title={aiAssist.title}
            parentSelector='.modal-card-view-wrapper'
            containerSelector='.modal-card-view-body-content'
            headerSx={{
              height: 'initial',
            }}
            aiAssist={aiAssist}
          />
        </Box>
      );
    };

    const onLayoutChange = useCallback(
      (layout: IterableObject<CSSProperties>) => {
        if (!sideSheetContainerRef.current) return;

        if (layout.body) {
          const gap = 8;
          let height = +layout.body.height!;
          // NOTE: Top/Bottom gap on the side sheet
          height = height - gap * 2;
          sideSheetContainerRef.current.style.height = `${height}px`;
          sideSheetContainerRef.current.style.opacity = '1';
          sideSheetContainerRef.current.style.transform =
            'translate3d(0, 0, 0)';
        }

        setSideSheetKey((key) => ++key);
      },
      [sideSheetContainerRef, setSideSheetKey],
    );

    useEffect(() => {
      let timer: ReturnType<typeof setTimeout> | null = null;
      if (isInBetweenStep('Quote')) {
        timer = setTimeout(() => {
          close?.();
        }, 4000);
      }
      return () => {
        timer && clearTimeout(timer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInBetweenStep('Quote')]);

    useEffect(() => {
      if (postReport.isSuccess) {
        shiftReportingStatus.refetch();
        updateActionKeyCounter('sod_eod_submission');
        nextStep();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postReport.isSuccess]);

    /**
     * Check if it's submitting and recent worklogs has been newly fetched
     * and user has not submitted the report yet, this will ensure the worklogs
     * is up to date
     */
    useEffect(() => {
      if (isSubmitting && recentWorkLogs.isSuccess && !postReport.isSuccess) {
        postReport.mutate(getPostReportData());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting, recentWorkLogs.isSuccess, postReport.isSuccess]);

    /**
     * Reset back the isSubmitting status if none is processing
     */
    useEffect(() => {
      if (!recentWorkLogs.isLoading && !postReport.isLoading && isSubmitting) {
        setIsSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    return (
      <ModalCardView
        fullView={fullView}
        header={renderTopPanel()}
        headerSx={{ pt: 2, pb: 2 }}
        footer={isInBetweenStep('Quote') ? null : renderPrevNextButtons()}
        close={close}
        animateOnMount={animateOnMount}
        willUpdateLayout={activeStep}
        onSetScrollElementRef={setScrollElement}
        afterChildren={isFirstStep() ? renderQuestionsDrawerSummary() : null}
        sx={{
          ...(fullView ? {} : { maxWidth: 1200 }),
        }}
        bodySx={{
          paddingRight: isDrawerOpen ? '316px' : undefined,
        }}
        onLayoutChange={onLayoutChange}
      >
        {isFirstStep() && renderFormStep()}
        {isInBetweenStep('Quote') && renderQuoteStep()}
        {/* {isLastStep() && renderSummaryStep(htmlStateMap)} */}
        <br />
        <WindowScrollTop handler={scrollElement} deps={[activeStep]} />
      </ModalCardView>
    );
  },
);

export default EODForm;
