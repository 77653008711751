import { Box, Divider, Modal, Stack, Typography } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import moment, { Moment } from 'moment';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { M3Button } from '../M3/M3Button';
import PTORequestForm, { RequestFormParams } from './PTORequestForm';

import { HrPTOItemResponse } from '../../types/hr';
import { dgs, toTitleCase } from '../../utils/string';
import { parseDateWithoutTimezone } from '../../utils/date';
import { useAppProvider } from '../../providers/app/app';
import { getPTOStatus } from '../../utils/pto';
import { IterableObject } from '../../types/types';

type TimeOffRequestItemProps = {
  item: HrPTOItemResponse;
  first: boolean;
  last: boolean;
  editable?: boolean;
  staffId?: string | null;
};

function renderPTOStatus(
  item: TimeOffRequestItemProps['item'],
  isDarkMode: boolean,
) {
  let style: CSSProperties = {
    borderRadius: 20,
    whiteSpace: 'nowrap',
    padding: '2px 10px 2px 10px',
    background: isDarkMode
      ? 'rgba(255, 255, 255, 0.03)'
      : 'rgba(0, 0, 0, 0.03)',
  };
  const state = getPTOStatus(item.status);

  if (item.status === 'approved') {
    style.color = '#fff';
    style.background = '#0f840d';
  }
  if (item.status === 'denied' || item.status === 'canceled') {
    style.color = '#fff';
    style.background = 'red';
  }

  return <div style={style}>{toTitleCase(state.label)}</div>;
}

export function getTimeOffRequestStartEndDateDisplay(
  startDate: string,
  endDate: string,
) {
  const requestedDates: Moment[] = generateRequestDates(startDate, endDate);
  const start = requestedDates[0];
  const end = requestedDates[1] || start;

  return {
    date:
      start.month() === end.month()
        ? `${start.format('MMM')} ${start.format('D')}${
            start.date() !== end.date() ? ` – ${end.date()}` : ''
          }`
        : [start.format('MMM D'), end.format('MMM D')].join(' – '),
    year:
      start.year() === end.year()
        ? start.year()
        : [start.year(), end.year()].join(' – '),
  };
}

function generateRequestDates(start: string, end: string): Moment[] {
  return [start, start === end ? null : end]
    .filter((date) => !!date)
    .map((date) => moment(parseDateWithoutTimezone(date!).date));
}

const TimeOffRequestItem = ({
  item,
  last,
  editable,
  staffId,
}: TimeOffRequestItemProps) => {
  const { isDarkMode, updateActionKeyCounter } = useAppProvider();
  const requestedDates: Moment[] = generateRequestDates(item.start, item.end);
  const [isOpen, setIsOpen] = useState(false);
  const [formParams, setFormParams] = useState<RequestFormParams | null>(null);

  const { date, year } = getTimeOffRequestStartEndDateDisplay(
    item.start,
    item.end,
  );

  function onEditCancelRequest() {
    const start = requestedDates[0];
    const end = requestedDates[1] || start;

    setFormParams({
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      type: item.type_id,
      amount: `${item.amount || 0}`,
      note: item.notes.employee,
      dates: Object.keys(item.dates)
        .map((key) => ({
          date: key,
          amount: item.dates[key],
        }))
        .reduce((prev, curr) => {
          prev[curr.date] = curr;
          return prev;
        }, {} as IterableObject),
    });
    setIsOpen(true);
  }

  const renderTimeRequestFormModal = () => {
    return (
      <Modal open={isOpen}>
        <PTORequestForm
          edit
          modal
          id={item.id}
          status={item.status}
          staffId={staffId}
          params={formParams as RequestFormParams}
          onSuccess={() => {
            updateActionKeyCounter('pto_request_submission');
          }}
          close={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    );
  };

  return (
    <>
      <Box display='flex' sx={{ pt: 2, pb: 2 }} alignItems='flex-start'>
        <Box flex={1} pt={0.5} pl={1} pr={editable ? 4 : 0}>
          <Typography
            component='div'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            {date}
            <span
              style={{
                fontSize: 9,
                opacity: isDarkMode ? 0.5 : 0.4,
                marginLeft: 8,
                borderRadius: 3,
                fontWeight: 500,
                padding: '1px 4px',
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              {year}
            </span>
          </Typography>
          <Stack
            direction='row'
            alignItems='flex-start'
            justifyContent='space-between'
          >
            <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
              {dgs(item.amount)} hour{item.amount > 1 ? 's' : ''} of{' '}
              {item.type_name}
            </Typography>
            <Typography
              pb={0.5}
              mt={-2}
              component='div'
              fontWeight={500}
              fontSize={12}
              sx={{ opacity: 0.8 }}
            >
              {renderPTOStatus(item, isDarkMode)}
            </Typography>
          </Stack>
          {!!item.notes?.employee && (
            <Typography
              p={1}
              pl={2}
              pr={2}
              mt={0.5}
              borderRadius={1}
              component='div'
              fontSize={14}
              fontStyle='italic'
              sx={{
                opacity: 0.5,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              {item.notes?.employee}
            </Typography>
          )}
        </Box>
        {editable && (
          <Box
            pt={2.7}
            width={130}
            minWidth={130}
            display='flex'
            justifyContent='flex-end'
          >
            <Box sx={{ height: 50 }}>
              {
                <M3Button
                  disabled={
                    item.status === 'requested' || item.status === 'approved'
                      ? false
                      : true
                  }
                  sx={{
                    ml: -2,
                    fontSize: 14,
                  }}
                  onClick={onEditCancelRequest}
                >
                  <EditOutlinedIcon style={{ fontSize: 20 }} />
                  Edit Request&nbsp;
                </M3Button>
              }
            </Box>
          </Box>
        )}
      </Box>
      {!last && <Divider sx={{ opacity: 0.5 }} />}
      {renderTimeRequestFormModal()}
    </>
  );
};

export default TimeOffRequestItem;
