import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InboxIcon from '@mui/icons-material/Inbox';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

import { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList, { AppMenuChip } from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';
import {
  CollectionItemProps,
  getCollectionItemProps,
} from '../../providers/intercom';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import * as intercom from '../../services/intercom';

type Props = {};

const JobSeekerDrawer = (props: Props) => {
  const { pathname } = useLocation();
  const {
    drawerWidth,
    toolbarHeight,
    isTopBannerVisible,
    palettes: { main: palette },
    mediaQuery,
    isDrawerOpen,
    isDarkMode,
    setIsDrawerOpen,
  } = useAppProvider();
  const { supportUnreadCount, collections, admins } = useIntercomProvider();

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const collectionItems: CollectionItemProps[] = useMemo(() => {
    const items: CollectionItemProps[] = [];
    collections.map((collection) =>
      items.push(getCollectionItemProps(collection, admins)),
    );
    return items;
  }, [collections, admins]);

  const menus: MenuItem[] = [
    {
      id: 'help-center',
      name: 'Help Center',
      icon: <HomeIcon />,
      collapsable: true,
      children: [
        {
          id: 'help-center-all',
          name: 'All',
          path: `/`,
          basePath: `/`,
          isExact: true,
        } as MenuItem,
        ...collectionItems.map((collection) => {
          return {
            id: collection.id,
            name: collection.collection.name.slice(0, 26),
            path: `/collection/${collection.id}`,
            basePath: `/collection/${collection.id}`,
          } as MenuItem;
        }),
      ],
    },
    {
      id: 'path',
      name: 'Join Path Beta',
      icon: <SmartToyOutlinedIcon />,
      basePath: '/path',
      path: '/path',
    },
    {
      id: 'inbox',
      name: 'Inbox',
      icon: <InboxIcon />,
      basePath: '/inbox',
      path: '/inbox/support',
      secondaryAction: <AppMenuChip total={supportUnreadCount} />,
      onClick: () => {
        intercom.emit('showMessages');
      },
    },
  ];

  useEffect(() => {
    if (mediaQuery) {
      setIsDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, mediaQuery]);

  return (
    <DrawerPermanent
      withWorkspace={false}
      open={isDrawerOpen}
      variant={mediaQuery.mobile ? 'temporary' : 'permanent'}
      withDrawerBackground={!mediaQuery.mobile}
      onClose={mediaQuery.mobile ? () => setIsDrawerOpen(false) : undefined}
    >
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box
          style={{
            height: toolbarHeight + (isTopBannerVisible ? toolbarHeight : 0),
          }}
        />
        <Box flex={1}>
          <AppDrawerMenuList menus={menus} />
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default JobSeekerDrawer;
