import { useMemo, useState } from 'react';
import { DropResult, OnDragEndResponder } from 'react-beautiful-dnd';

import { IterableObject } from '../../types/types';
import { moveObjectAtIndex } from '../../utils/array';

type IdType = string | number;
type UseDragAndDropProps<T = IterableObject> = {
  key?: string;
  items: T[];
  onChange?: (props: onChangeProps<T>) => void;
};
type onChangeProps<T = IterableObject> = DropResult & {
  oldItems: T[];
  newItems: T[];
};
type UseDragAndDropRet<T = IterableObject, IT = IdType> = {
  items: T[];
  onDragEnd: OnDragEndResponder;
  setItemsIds: (initIds: IT[]) => void;
};
export function useDragAndDrop<T = IterableObject, IT = IdType>({
  key = 'id',
  items,
  onChange,
}: UseDragAndDropProps<T>): UseDragAndDropRet<T, IT> {
  const [ids, setIds] = useState<IT[]>([]);

  const orderedItems: T[] = useMemo(() => {
    if (ids.length) {
      return ids
        .map((id) =>
          items.find((item: T) => (item as IterableObject)[key] === id),
        )
        .filter((item) => !!item) as T[];
    }
    return items;
  }, [key, ids, items]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // check if nothing changes in the order
    if (!destination || source.index === destination?.index) return null;
    // original order
    let items = [...orderedItems];
    // swap items on the new order...
    moveObjectAtIndex(items, source.index, destination.index);
    // get the new ordered items id
    let newItemIds = items.map((item) => (item as IterableObject)[key]);
    // set the new ordered items
    let newItems: T[] = newItemIds.map((id) =>
      items.find((item) => id === (item as IterableObject)[key]),
    ) as T[];

    setIds(newItemIds);
    // fire any handler when drag changes
    onChange?.({
      oldItems: items,
      newItems,
      ...result,
    });
  };

  const setItemsIds = (initIds: IT[]) => {
    setIds(initIds);
  };

  return {
    onDragEnd,
    items: orderedItems,
    setItemsIds,
  };
}
