import React, { Dispatch, Fragment, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import { IterableObject, ReactRenderElement } from '../../../types/types';
import { DayScheduleLocal } from '../ScheduleUserList';
import { UserMetadata } from '../../../types/profile';
import { M3IconButton } from '../../M3/M3Button';
import { useAppProvider } from '../../../providers/app/app';
import { getTimeDifferenceBetweenStartAndEnd } from '../../../utils/date';
import { useCurrentProfile } from '../../../hooks/profile';
import {
  AddOutlined,
  DeleteOutlined,
  EditOutlined,
  NightsStayOutlined,
} from '@mui/icons-material';
import AbsoluteCenterBox from '../../AbsoluteCenterBox';
import {
  getLatestAssociatedMetadataSchedule,
  getLatestProperLocalSchedules,
  isScheduleRestDay,
} from '../../../utils/schedule';

export type DayPayload = {
  day: number;
  start?: string;
  end?: string;
  isNew?: boolean;
  scheduleId?: number;
  isTemporary?: boolean;
  fromDate?: string;
  toDate?: string;
  localDate?: string;
};

type ScheduleCardProps = {
  columnSx: SxProps;
  range: string[];
  days: moment.Moment[];
  editable: boolean;
  onlyOwnUserEditable?: boolean;
  setDayPayload: Dispatch<React.SetStateAction<DayPayload | null>>;
  setIsTemporaryScheduleOpen: Dispatch<React.SetStateAction<boolean>>;
  setIsScheduleOpen: Dispatch<React.SetStateAction<boolean>>;
  setTempSchedulesAssociated: Dispatch<
    React.SetStateAction<DayScheduleLocal[]>
  >;
  setUserSelected: Dispatch<React.SetStateAction<UserMetadata | null>>;
  user: UserMetadata;
  userSchedules: IterableObject<DayScheduleLocal[]>;
};

const ScheduleCard: React.FC<ScheduleCardProps> = ({
  columnSx,
  days,
  range,
  editable,
  onlyOwnUserEditable,
  setDayPayload,
  setIsScheduleOpen,
  setIsTemporaryScheduleOpen,
  setTempSchedulesAssociated,
  setUserSelected,
  user,
  userSchedules,
}) => {
  const { isDarkMode } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();
  const isCreatePermanentAllowed = useMemo(() => {
    return currentProfile?.roles.some(
      (role) => role === 'it' || role === 'developer' || role === 'superuser',
    );
  }, [currentProfile]);
  const isUpdatePermanentAllowed = useMemo(() => {
    return currentProfile?.roles.some(
      (role) => role === 'it' || role === 'developer' || role === 'superuser',
    );
  }, [currentProfile]);

  return (
    <Box flex={1}>
      <Box flex={1} display='flex'>
        {days.map((m, i) => {
          const date = m.format('YYYY-MM-DD');
          /**
           * Filter the schedules to only the the conditions that they
           * are in range, and matches the associated schedules that needs
           * to be overwritten.
           */
          let filteredDaySchedules = getUserFilteredDayLocalSchedules(
            userSchedules,
            {
              days,
              range,
              date,
            },
          );

          const onScheduleClick = (
            schedule: Partial<DayScheduleLocal>,
            associated: DayScheduleLocal[] = [],
          ) => {
            setUserSelected(user!);
            setDayPayload({
              scheduleId: schedule.id,
              isNew: !schedule.id,
              day: m.day(),
              start: schedule.start_time_local ?? '',
              end: schedule.end_time_local ?? '',
              isTemporary: schedule.is_temporary,
              fromDate: schedule.from_date!,
              toDate: schedule.to_date!,
              localDate: schedule.date,
            });

            if (schedule.is_temporary) {
              setIsTemporaryScheduleOpen(true);
              /**
               * Only set associated temporary schedule only. So it won't
               * affect the permanent when deleting this schedule.
               */
              setTempSchedulesAssociated([
                schedule as DayScheduleLocal,
                ...associated,
                /**
                 * Include also associated schedules to delete from the old temporary schedules.
                 * If it is undefined, we pass an empty array.
                 */
                ...((schedule.associated_to_delete_schedules as DayScheduleLocal[]) ??
                  []),
              ]);
            } else {
              setIsScheduleOpen(true);
              setTempSchedulesAssociated([]);
            }
          };

          const renderSchedule = (user: UserMetadata) => {
            /**
             * NOTE: Let's get the latest in the array of schedules
             * in case there are multiple instances of it. This might happen
             * if user submits temporary schedule for the same date even if
             * there's already a temporary schedule set
             */
            const latestSchedule =
              getLatestProperLocalSchedules(filteredDaySchedules);
            // Filtering to get the temporary schedules associated only.
            // This when we delete the temporary schedule (current), it also
            // allows us to delete the associated temporary schedules that
            // gets overwritten
            const latestAssociatedTemporary =
              getLatestAssociatedMetadataSchedule(latestSchedule).filter(
                (las) => las.is_temporary,
              );

            return latestSchedule.map((schedule) => {
              const timeDiff = getTimeDifferenceBetweenStartAndEnd(
                schedule.start_time_local,
                schedule.end_time_local,
              );
              const isRestDay = isScheduleRestDay(schedule);

              const renderActionButton = (
                icon?: ReactRenderElement,
                onClick?: () => void,
              ) => {
                return (
                  <M3IconButton
                    onClick={onClick}
                    sx={{
                      width: 30,
                      height: 30,
                      minHeight: 'initial',
                      minWidth: 'initial',
                      ':hover': {
                        svg: {
                          color: isDarkMode
                            ? 'var(--md-ref-palette-secondary90) !important'
                            : 'var(--md-ref-palette-secondary20) !important',
                        },
                      },
                      svg: {
                        color: isDarkMode
                          ? `var(--md-ref-palette-secondary20) ${
                              isRestDay ? undefined : '!important'
                            }`
                          : `var(--md-ref-palette-secondary90) ${
                              isRestDay ? undefined : '!important'
                            }`,
                      },
                    }}
                  >
                    {icon}
                  </M3IconButton>
                );
              };

              let actionButton: ReactRenderElement = null;
              /**
               * Only allow to delete temporary schedule of current user. Or
               * If you are IT, you can edit/delete the permanent schedule
               */
              if (
                (onlyOwnUserEditable &&
                  user.id === currentProfile!.id &&
                  schedule.is_temporary) ||
                isUpdatePermanentAllowed
              ) {
                actionButton = renderActionButton(
                  schedule.is_temporary ? (
                    <DeleteOutlined
                      style={{
                        fontSize: 16,
                      }}
                    />
                  ) : (
                    <EditOutlined
                      style={{
                        fontSize: 16,
                      }}
                    />
                  ),
                  () => onScheduleClick(schedule, latestAssociatedTemporary),
                );
              }

              if (isRestDay) {
                return (
                  <Fragment key={schedule.id}>
                    <Box
                      sx={{
                        py: 1,
                        pl: 1,
                        pb: 0.5,
                        width: '100%',
                        height: '100%',
                        borderRadius: 2,
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        position: 'relative',
                      }}
                    >
                      <Box
                        component='div'
                        fontWeight={500}
                        fontSize={13}
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Rest Day
                      </Box>
                      <Stack direction='row' justifyContent='flex-end' mr={0.4}>
                        {actionButton}
                      </Stack>
                    </Box>
                  </Fragment>
                );
              }

              return (
                <Fragment key={schedule.id}>
                  <Box
                    data-id={schedule.id}
                    data-weekday={schedule.weekday}
                    data-tzinfo={schedule.tzinfo}
                    sx={{
                      py: 1,
                      pl: 1,
                      pb: 0.5,
                      width: '100%',
                      height: '100%',
                      borderRadius: 2,
                      display: 'flex',
                      overflow: 'hidden',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      position: 'relative',
                      background: isDarkMode
                        ? schedule.is_temporary
                          ? 'var(--md-ref-palette-error60)'
                          : 'var(--md-ref-palette-secondary80)'
                        : schedule.is_temporary
                        ? 'var(--md-ref-palette-error50)'
                        : 'var(--md-ref-palette-secondary40)',
                      color: isDarkMode
                        ? 'var(--md-ref-palette-secondary20)'
                        : 'var(--md-ref-palette-secondary90)',
                    }}
                  >
                    <Box
                      component='div'
                      fontWeight={500}
                      fontSize={13}
                      className='text-truncate'
                      sx={{
                        color: 'inherit',
                        whiteSpace: 'nowrap',
                        svg: {
                          color: isDarkMode
                            ? 'var(--md-ref-palette-secondary20) !important'
                            : 'var(--md-ref-palette-secondary90) !important',
                        },
                      }}
                    >
                      {timeDiff.overlap && (
                        <NightsStayOutlined
                          style={{
                            top: 2,
                            fontSize: 14,
                            position: 'relative',
                          }}
                        />
                      )}
                      {schedule.start_time_local} - {schedule.end_time_local}
                    </Box>
                    <Stack direction='row' alignItems='flex-end' mr={0.4}>
                      <Box
                        flex={1}
                        fontSize={12}
                        pb={0.5}
                        sx={{
                          opacity: 0.8,
                          color: 'inherit',
                        }}
                      >
                        {timeDiff.hours}hr
                      </Box>
                      {actionButton}
                    </Stack>
                  </Box>
                </Fragment>
              );
            });
          };

          return (
            <Fragment key={i}>
              <Divider orientation='vertical' flexItem />
              <Typography
                component='div'
                flex={1}
                width={0}
                sx={{
                  ...columnSx,
                  py: 0.8,
                  px: 0.8,
                  gap: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                  '.schedule-add-button': {
                    opacity: 0,
                  },
                  ':hover': {
                    '.schedule-add-button': {
                      opacity: 1,
                    },
                  },
                }}
              >
                {filteredDaySchedules.length
                  ? renderSchedule(user)
                  : isCreatePermanentAllowed && (
                      <AbsoluteCenterBox
                        sx={{
                          cursor: 'pointer',
                        }}
                        className='schedule-add-button'
                        onClick={() => {
                          onScheduleClick({});
                        }}
                      >
                        <AddOutlined style={{ fontSize: 20 }} />
                      </AbsoluteCenterBox>
                    )}
              </Typography>
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default ScheduleCard;

type GetUserFilteredDayLocalSchedulesOpt = {
  date?: string;
  days: Moment[];
  range: string[];
};
export function getUserFilteredDayLocalSchedules(
  userSchedules: IterableObject<DayScheduleLocal[]>,
  opt: GetUserFilteredDayLocalSchedulesOpt,
): DayScheduleLocal[] {
  const { date, days = [], range = [] } = opt ?? {};

  /**
   * In getting the association from the edge days of the week.
   * We need to adjust the days range. So that Sunday(previous week),
   * Monday (next week) will be included in the calculation to override
   * a schedule.
   */
  const spanDays = [
    days[0].clone().subtract(1, 'day'),
    ...days,
    days[days.length - 1].clone().add(1, 'day'),
  ];
  const allAssociatedLatestSchedule = spanDays
    .map((m) => {
      const daySchedules = userSchedules[m.format('YYYY-MM-DD')] ?? [];
      const latestSchedule = getLatestProperLocalSchedules(daySchedules);
      const latestAssociated =
        getLatestAssociatedMetadataSchedule(latestSchedule);
      return latestAssociated;
    })
    .flat(Infinity) as DayScheduleLocal[];

  const dates = Array.from(
    new Set(allAssociatedLatestSchedule.map((ass) => ass.date).sort()),
  );
  const associatedDates = [
    moment(dates[0]).format('YYYY-MM-DD'),
    moment(dates[dates.length - 1]).format('YYYY-MM-DD'),
  ];

  let currentDaySchedules = getLatestProperLocalSchedules(
    userSchedules[date ?? ''] ?? [],
  );

  let filteredDaySchedules = currentDaySchedules.filter((schedule) => {
    /**
     * Do not render the schedule that's have been overwritten.
     * Overwrite the schedule within the range of min/max associate dates.
     * It fixes the Sunday(end), trying to overwrite the Monday
     * of the same day.
     */
    if (
      allAssociatedLatestSchedule.some(
        (as) =>
          as.id === schedule.id &&
          moment(schedule.date).isSameOrAfter(associatedDates[0]) &&
          moment(schedule.date).isSameOrBefore(
            associatedDates[associatedDates.length - 1],
          ),
      ) ||
      /**
       * Do not render the schedule if it's outside the displayed
       * range in the table
       */
      !(
        moment(schedule.date).isSameOrAfter(range[0]) &&
        moment(schedule.date).isSameOrBefore(range[1])
      )
    ) {
      return false;
    }

    return true;
  });

  let temporarySchedules = filteredDaySchedules.filter((s) => s.is_temporary);
  let permanentSchedules = filteredDaySchedules.filter((s) => !s.is_temporary);

  /**
   * Check if there are multiple temporary schedules and there's a rest day.
   * There are overlaps of dates due to timezone. It happens when plotting
   * a temporary schedule from EST where the dates moved when in PST.
   * ie. EST Mon 1:00pm -> PHT Tue 1:00am
   */
  if (temporarySchedules.length > 1 && !permanentSchedules.length) {
    filteredDaySchedules = filteredDaySchedules.filter(
      (s) => !isScheduleRestDay(s),
    );
  }

  /**
   * Check if there are more temporary schedules, there's a permanent,
   * there's rest day on the same day. There are existing entries (old)
   * temporary schedule that has been set in the future prior to overall fix.
   * In order to compensate to that, we need to hide some of the schedules.
   * This should resolve the issue for future old temporary schedules
   * and gets overwritten by request new temporary schedules after fix.
   */
  if (temporarySchedules.length > 1 && permanentSchedules.length) {
    const firstActiveSchedule = temporarySchedules[0];
    const hasRestDay = temporarySchedules.some(isScheduleRestDay);
    const hasTempDay = temporarySchedules.some((s) => !isScheduleRestDay(s));
    // Check if has a rest day and a temporary schedule day
    if (hasRestDay && hasTempDay) {
      firstActiveSchedule.associated_to_delete_schedules =
        filteredDaySchedules.filter((s) =>
          s.is_temporary ? s.id !== firstActiveSchedule.id : false,
        );
      filteredDaySchedules = [firstActiveSchedule];
    }
  }

  /**
   * get the latest proper local schedules using the filtered
   * day schedules. Then we filter more the local filtered day
   * schedules for temporary rest days to overwrite permanent
   * schedules that have been moved due to timezone.
   *
   * Filter out also a day where it has schedules with rest day, if it has
   * a multiple temporary schedules and a rest day. Don't show the rest day
   * on that day.
   */
  /*
  TODO: Temporary removed this schedule. What will happen???
  const filteredLatestSchedules =
    getLatestProperLocalSchedules(filteredDaySchedules);
  const filterHasRestDay = filteredLatestSchedules.some(isScheduleRestDay);
  filteredDaySchedules = filteredDaySchedules.filter(
    (s) => s.is_temporary || !filterHasRestDay,
  );
  // Check if it has multiple temporary schedules and it has rest day in it
  // Let's hide the rest day instead
  if (filteredDaySchedules.length > 1) {
    filteredDaySchedules = filteredDaySchedules.filter((s) =>
      filterHasRestDay ? !isScheduleRestDay(s) : true,
    );
  }
  */

  return filteredDaySchedules;
}
