import React, { useMemo } from 'react';
import { Box, Divider } from '@mui/material';

import { UserMetadata } from '../../types/profile';
import { DailyReportEODDataKPI } from '../../types/report';
import TopVisitedUrls from './TopVisitedUrls';
import TaskCardEmbed from './TaskCardEmbed';
import TaskFieldItem from './TaskFieldItem';
import { useMetabaseIFrame } from '../../hooks/utils/metabase';

type Props = {
  sodId?: number | null;
  date: string;
  user: UserMetadata;
  task: Partial<DailyReportEODDataKPI>;
  questions?: { id: string; question: string; answer: string }[];
};

const TaskDetailView: React.FC<Props> = ({
  sodId,
  date,
  user,
  task,
  questions,
}) => {
  const taskId = task.task_id || task.taskId;

  const bridgeMetabaseIFrameParams = useMemo(
    () => ({
      date: date,
      sod_id: sodId || null,
      task_id: taskId || null,
      member_id: user.employee_id || [],
    }),
    [date, sodId, taskId, user.employee_id],
  );

  const topVisitedURLMetabaseIFrame = useMetabaseIFrame({
    endpoint: '/dataset',
    params: useMemo(
      () => ({
        name: 'top_visited_urls',
        ...bridgeMetabaseIFrameParams,
      }),
      [bridgeMetabaseIFrameParams],
    ),
  });

  const topUsedWebMetabaseIFrame = useMetabaseIFrame({
    url: '/embed/question/',
    key: 'question',
    value: 205,
    params: bridgeMetabaseIFrameParams,
  });

  const topUsedAppMetabaseIFrame = useMetabaseIFrame({
    url: '/embed/question/',
    key: 'question',
    value: 206,
    params: bridgeMetabaseIFrameParams,
  });

  return (
    <Box sx={{ mt: 2, pb: 0, minHeight: 400 }}>
      {task.fields?.map((field) => {
        const isTextType =
          field.type === 'richtext' || field.type === 'textarea';

        return (
          <Box key={field.fieldId} style={{ paddingBottom: 24 }}>
            <TaskFieldItem
              title={field.label}
              html={isTextType && field.value}
              subtitle={!isTextType && field.value}
            />
          </Box>
        );
      })}
      {questions?.map((q) => {
        return (
          <Box key={q.id} style={{ paddingBottom: 24 }}>
            <TaskCardEmbed html={q.answer} title={q.question} />
          </Box>
        );
      })}
      {!!(task.fields?.length || questions?.length) && (
        <Divider sx={{ ml: -4, mr: -4, mt: 1, mb: 3 }} />
      )}
      <TopVisitedUrls
        topVisitedURLData={topVisitedURLMetabaseIFrame?.result?.data as any}
        topVisitedURLsIsLoading={topVisitedURLMetabaseIFrame.isLoading}
      />
      <TaskCardEmbed
        src={topUsedWebMetabaseIFrame?.iframe}
        isLoading={topUsedWebMetabaseIFrame.isLoading}
        title='Top Used Websites'
      />
      <TaskCardEmbed
        src={topUsedAppMetabaseIFrame?.iframe}
        isLoading={topUsedAppMetabaseIFrame.isLoading}
        title='Top Used Applications'
      />
    </Box>
  );
};

export default TaskDetailView;
