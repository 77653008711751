import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as auth from '../../services/auth';
import { cleanURL } from '../../utils/url';
import { parseError } from '../global/useApi';

type UseAuthRefreshTokenProps = {
  refresh_token: string;
  id_token?: string;
  platform: 'desktop' | 'web' | 'mobile';
};
export async function fetchAuthRefreshToken<R = any>(
  data: UseAuthRefreshTokenProps,
): Promise<AxiosResponse<R>> {
  const result: AxiosResponse<R> = await auth.request({
    url: cleanURL('token/refresh'),
    method: 'POST',
    data,
  });
  return result;
}
export function useAuthRefreshToken<R = any>() {
  async function api(
    data: UseAuthRefreshTokenProps,
  ): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await fetchAuthRefreshToken(data);
        resolve(result);
      } catch (e) {
        reject(parseError(e));
      }
    });
  }

  return useMutation(api);
}

type UseAuthCodeTokensProps = {
  code: string;
};
export function useAuthCodeTokens<R = any>() {
  async function api(data: UseAuthCodeTokensProps): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await auth.request({
          url: cleanURL(`token/code/${data.code}`),
          method: 'POST',
          data,
        });
        resolve(result);
      } catch (e) {
        reject(parseError(e));
      }
    });
  }

  return useMutation(api);
}
