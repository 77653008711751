import { Stack, Typography, Box, Avatar } from '@mui/material';
import React, { forwardRef, PropsWithChildren } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import TimeOffAccrualList from '../Request/TimeOffAccrualList';
import TimeOffRequestListItems from '../Request/TimeOffRequestListItems';
import UserChipStatus from './UserChipStatus';

import { UserMetadata } from '../../types/profile';
import {
  getCurrentUserJobDescription,
  getUserInitials,
  getUserPhotoUrl,
} from '../../utils/user';
import { useAppProvider } from '../../providers/app/app';

type TimeOffProfileModalViewProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    user?: UserMetadata;
  };

export function TimeOffProfileModalViewBase({
  user,
  close,
}: TimeOffProfileModalViewProps) {
  const { isDarkMode } = useAppProvider();
  const { job_title } = getCurrentUserJobDescription(user);

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <CalendarMonthOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            Time Off
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderUserProfile = () => {
    return (
      <Stack gap={2} flexDirection='row' position='relative'>
        <Avatar
          sx={{
            width: 50,
            height: 50,
          }}
          src={getUserPhotoUrl(user?.photo_url)}
        >
          {getUserInitials(user?.first_name).initial}
        </Avatar>
        <Box>
          <Typography component='div' fontSize={20} fontWeight={500}>
            {user?.first_name} {user?.last_name}
          </Typography>
          <Typography
            component='div'
            fontSize={14}
            fontWeight={500}
            style={{
              color: isDarkMode
                ? 'var(--md-ref-palette-tertiary80)'
                : 'var(--md-ref-palette-tertiary30)',
            }}
          >
            {job_title}
          </Typography>
          <UserChipStatus />
        </Box>
      </Stack>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
    >
      <Box sx={{ pt: 2, pb: 6 }}>
        {renderUserProfile()}
        <TimeOffAccrualList staffId={user?.staff_id!} />
        <TimeOffRequestListItems
          staffId={user?.staff_id!}
          title='Upcoming Time Off'
          pageWaitOptions={{
            title: 'No Upcoming Time Off',
            message: `${user?.first_name} doesn't have an upcoming time off`,
          }}
        />
      </Box>
    </ModalCardView>
  );
}

const TimeOffProfileModalView = forwardRef(
  (props: TimeOffProfileModalViewProps, ref) => (
    <TimeOffProfileModalViewBase {...props} />
  ),
);

export default TimeOffProfileModalView;
