import { Box, Popover } from '@mui/material';
import React, { useRef } from 'react';
import MenuDivisionDropdown from './MenuDivisionDropdown';

type Props = {
  open: boolean;
  onClose?: () => void;
};

const MenuDivisionJumper = ({ open, onClose }: Props) => {
  const ref = useRef(null);
  return (
    <>
      <Box ref={ref} />
      <Popover
        open={open}
        anchorEl={ref.current}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onClose}
        PaperProps={{
          sx: {
            ml: 6,
            overflow: 'hidden',
            '.MuiPaper-root': {
              borderRadius: 0,
              boxShadow: 'none',
            },
            '.MuiAutocomplete-listbox': {
              maxHeight: 245,
            },
          },
        }}
      >
        <Box sx={{ height: 320, minWidth: 272 }}>
          <Box sx={{ flex: 1 }}>
            <MenuDivisionDropdown close={onClose} />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default MenuDivisionJumper;
