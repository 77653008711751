import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppActionToolbar from '../components/AppActionToolbar';
import MainContainer from '../components/MainContainer';
import IntercomBreadcrumbs from '../components/Intercom/IntercomBreadcrumbs';
import SearchForm from '../components/Filters/SearchForm';
import PageWait from '../components/PageWait';
import InfiniteScrollListener from '../components/InfiniteScrollListener';
import IntercomArticleSkeleton from '../components/Intercom/IntercomArticleSkeleton';
import ContentArticleItem from '../components/Content/ContentArticleItem';
import LoadMorePlaceholder from '../components/LoadMorePlaceholder';

import {
  useIntercomArticles,
  UseIntercomArticlesProps,
} from '../hooks/intercom';
import { useInfinite } from '../hooks/global/useInfinite';
import { IntercomArticle } from '../types/intercom';

type ContentArticlesProps = {};

const ContentArticles = (props: ContentArticlesProps) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const articles = useInfinite<IntercomArticle, UseIntercomArticlesProps>({
    useQuery: useIntercomArticles,
    queryParams: {
      limit: 10,
      search,
    },
  });

  useEffect(() => {
    articles.reset({
      emptyList: true,
    });
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <SearchForm
            placeholder='Search articles by title or description...'
            onStateUpdated={(state) => {
              setSearch(state.search);
            }}
          />
        </AppActionToolbar>
        <IntercomBreadcrumbs
          items={[
            {
              id: 'all',
              label: 'All Articles',
              path: '/content/article',
            },
          ]}
        />
        <br />
        {!articles.isLoading && !articles.data.length && (
          <PageWait
            title={`No articles found`}
            message={
              <>
                We could not find any articles:{' '}
                <strong style={{ fontWeight: 500 }}>{search}</strong>.
              </>
            }
          />
        )}
        {articles.isLoading &&
          !articles.data.length &&
          new Array(10)
            .fill(null)
            .map((_, i) => (
              <IntercomArticleSkeleton
                key={i}
                boxStyle={{ margin: '0 0 8px' }}
              />
            ))}
        {articles.data.map((item) => {
          return (
            <ContentArticleItem
              key={item.id}
              item={item}
              draggable={false}
              withLabel={false}
              withEditLink
              externalLink={item.url}
              descriptionStyle={{ maxWidth: undefined }}
              onClick={() => {
                navigate(`/content/article/${item.id}`);
              }}
            />
          );
        })}
        <LoadMorePlaceholder
          hasReachEnd={articles.hasReachEnd}
          isLoading={articles.isLoadingMore}
        />
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => {
          articles.loadNext();
        }}
      />
    </>
  );
};

export default ContentArticles;
