import { UseQueryOptions } from '@tanstack/react-query';
import { HrTimeOffTypeId, WhoIsOutItemResponse } from '../types/hr';
import { PTOStatus } from '../types/pto';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { DateYYYYMMDD } from '../types/types';
import { defaultReactQueryParams } from '../utils/request';
import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

type WhoIsOutMySquadProps = ListQuery & WhoIsOutMySquadParams;
type WhoIsOutMySquadParams = {
  start?: DateYYYYMMDD;
  end?: DateYYYYMMDD;
  user_id?: string | number;
};

export function useWhoIsOutMySquad<R = WhoIsOutItemResponse>(
  params: WhoIsOutMySquadProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<WhoIsOutMySquadProps>({
    params,
    baseIdentifier: 'useWhoIsOutMySquad',
    identifierKeys: ['start', 'end', 'user_id'],
    queryParamKeys: ['start', 'end', 'user_id'],
  });

  return useQueryApi<WhoIsOutMySquadParams, ListResult<R>>(
    identifiers,
    `/hr/get-whos-out/squad/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseWhoIsOutProps = ListQuery & {
  start__gte?: string;
  end__lte?: string;
  staff?: string;
  division?: number;
};
export function useWhoIsOut<R = WhoIsOutItemResponse>(
  params: UseWhoIsOutProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseWhoIsOutProps>({
    params,
    baseIdentifier: 'useWhoIsOut',
    identifierKeys: ['start__gte', 'end__lte', 'staff', 'division'],
    queryParamKeys: ['start__gte', 'end__lte', 'staff', 'division'],
  });

  return useQueryApi<UseWhoIsOutProps, ListResult<R>>(
    identifiers,
    `/hr/get-whos-out/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseHrPTOProps = Partial<ListQuery> & {
  start?: DateYYYYMMDD;
  end?: DateYYYYMMDD;
  staff?: string | number;
  division?: number | string;
  employee_id?: string;
  status?:
    | PTOStatus
    | `${PTOStatus},${PTOStatus}`
    | `${PTOStatus},${PTOStatus},${PTOStatus}`;
  type_id?:
    | HrTimeOffTypeId
    | `${HrTimeOffTypeId},${HrTimeOffTypeId}`
    | `${HrTimeOffTypeId},${HrTimeOffTypeId},${HrTimeOffTypeId}`;
};
export function useHrPTO<R = any>(
  params: UseHrPTOProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseHrPTOProps>({
    params,
    baseIdentifier: 'useHrPTO',
    identifierKeys: [
      'start',
      'end',
      'staff',
      'division',
      'employee_id',
      'status',
      'type_id',
    ],
    queryParamKeys: [
      'end',
      'start',
      'staff',
      'division',
      'employee_id',
      'status',
      'type_id',
    ],
  });

  return useQueryApi<UseHrPTOProps, ListResult<R>>(
    identifiers,
    `/hr/pto/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
