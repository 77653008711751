import React from 'react';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';

import MainContainer from '../components/MainContainer';
import DocumentTitle from '../components/DocumentTitle';
import { workspaceWidth } from '../components/WorkspaceMenuList';

import { useAppProvider } from '../providers/app/app';

type Props = {};

type URLSegmentType = 'profit-and-loss-statement';
type URLSegmentData = { title: string; url: string };

const DataRoomEmbed = (props: Props) => {
  const { segment } = useParams();
  const { toolbarHeight } = useAppProvider();

  const getEmbedData = (segment: URLSegmentType): URLSegmentData | null => {
    if (segment === 'profit-and-loss-statement') {
      return {
        title: 'Profit And Loss Statement',
        url: 'https://public.domo.com/embed/pages/qxPOG',
      };
    }

    return null;
  };

  const renderIframe = ({ url, title }: URLSegmentData) => {
    return (
      <Box
        sx={{
          zIndex: 1,
          overflow: 'hidden',
          background: '#fff',
          position: 'relative',
          height: `calc(100vh - ${toolbarHeight}px)`,
        }}
      >
        <iframe
          title={title}
          src={url}
          style={{
            border: 0,
            margin: 0,
            width: '100%',
            height: '100%',
            background: 'transparent',
          }}
        ></iframe>
      </Box>
    );
  };

  const segmentEmbedData = getEmbedData(segment as URLSegmentType);

  if (!segmentEmbedData) {
    return <Navigate to='/embed/profit-and-loss-statement' />;
  }

  return (
    <>
      <DocumentTitle title={segmentEmbedData.title} />
      <MainContainer
        sx={{
          p: 0,
          pl: 0,
          pr: 0,
          pt: 0,
          pb: 0,
          maxWidth: null,
        }}
      >
        <Box
          style={{
            paddingLeft: workspaceWidth * 0.3,
          }}
        >
          {renderIframe(segmentEmbedData)}
        </Box>
      </MainContainer>
    </>
  );
};

export default DataRoomEmbed;
