import moment from 'moment';
import { UserProfile } from '../types/profile';
import { DailyReportItem, DailyReportItemResponse } from '../types/report';
import { employeeIdUsers } from './user';

export type UserHealth = {
  status: 'active' | 'healthy' | 'over' | 'under';
  color: string;
  hours: number;
  showHours: boolean;
  visible: boolean;
};

export type DailyReportItemExpanded = DailyReportItem & {
  user_health: UserHealth;
};

const userHealthColor = {
  active: '#28c0de',
  healthy: '#13ae5c',
  over: '#f34722',
  under: '#ffcd2a',
};

export function getUserHealth(
  totalTime: number | null,
  item: DailyReportItemExpanded,
): UserHealth {
  let health: UserHealth = {
    status: 'active',
    color: userHealthColor.active,
    hours: 0,
    showHours: false,
    visible: true,
  };

  if (!totalTime) {
    if (item.reports?.find((r) => r.type === 'EOD')) {
      health.visible = false;
      health.status = 'healthy';
      health.color = userHealthColor.healthy;
    }

    return health;
  }

  // converting to hours
  let totalDuration = parseFloat((totalTime / 3600).toFixed(2));

  /**
   * NOTE: The conditions are based on here:
   * https://www.figma.com/file/Yp6M961hKEmRiVTWCDjK5g/Portal-Views%3A-View-1?node-id=42%3A233
   */
  if (totalDuration >= 9.5) {
    health.status = 'over';
    health.color = userHealthColor.over;
    health.hours = totalDuration;
    health.showHours = true;
  } else if (
    (totalDuration > 6.5 && totalDuration < 9.5) ||
    totalDuration <= 0
  ) {
    health.status = 'healthy';
    health.color = userHealthColor.healthy;
    health.hours = totalDuration;
    health.showHours = false;
  } else if (totalDuration < 6.5) {
    health.status = 'under';
    health.color = userHealthColor.under;
    health.hours = totalDuration;
    health.showHours = true;
  }

  return health;
}

export function getDailyReportObjectId(item: DailyReportItem): number {
  const { reports } = item;
  const sodReport = reports?.find((report) => report.type === 'SOD');
  const eodReport = reports?.find((report) => report.type === 'EOD');

  if (sodReport && eodReport) {
    const sodSubmitted = moment(sodReport.created);
    const eodSubmitted = moment(eodReport.created);
    if (sodSubmitted.isSameOrBefore(eodSubmitted)) {
      return sodReport.id;
    }
    return eodReport.id;
  } else {
    let sodId = sodReport?.id;
    let eodId = eodReport?.id;
    if (sodId && eodId) {
      return sodId < eodId ? sodId : eodId;
    } else if (sodId) {
      return sodId;
    } else {
      return eodId!;
    }
  }
}

export function parseDailyReportItemResponse(
  data?: DailyReportItemResponse | null,
): DailyReportItemExpanded | null {
  if (!data) return null;

  const expanded = { ...data } as DailyReportItemExpanded;

  expanded.object_id = getDailyReportObjectId(expanded);
  expanded.id = expanded.id || expanded.object_id;
  expanded.user_health = getUserHealth(expanded.total_work_duration, expanded);

  return expanded;
}

export function isDidYouShipAnythingVisible(user: UserProfile) {
  const { reports_to, employee_id } = user;
  return !!(
    employeeIdUsers[employee_id || ''] || employeeIdUsers[reports_to?.id || '']
  );
}
