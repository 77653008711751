import React, { SyntheticEvent } from 'react';
import { Box, Tabs } from '@mui/material';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';

import AppActionToolbar from '../AppActionToolbar';
import { M3TabButton } from '../M3/M3TabButton';

import { UseToggleSelectionRet } from '../../hooks/global/useToggleSelection';
import { ReactRenderElement } from '../../types/types';
import * as posthog from '../../services/posthog';

type InboxAppActionToolbarProps = {
  totalUnread: number;
  tabIndex: number;
  onTabIndexHandleChange: (event: SyntheticEvent, index: number) => void;
  toggleSelection: UseToggleSelectionRet;
  onClickMarkAsRead?: () => void;
  rightActions?: ReactRenderElement;
};

const InboxAppActionToolbar = ({
  totalUnread,
  tabIndex,
  onTabIndexHandleChange,
  rightActions,
}: InboxAppActionToolbarProps) => {
  const getTotalItems = (total = 0) => {
    if (!total) return null;
    if (total > 99) return `(99+)`;
    return `(${total})`;
  };

  return (
    <AppActionToolbar
      sx={{
        paddingLeft: '0 !important',
      }}
    >
      <Box sx={{ width: '100%' }} display='flex' flexDirection='row'>
        <Tabs
          value={tabIndex}
          onChange={onTabIndexHandleChange}
          sx={{
            top: 4,
            flex: 1,
            position: 'relative',
          }}
        >
          <M3TabButton
            disableRipple
            sx={{ width: 200 }}
            onClick={() => posthog.capture('inbox unread tab clicked')}
            label={
              <>
                <MarkEmailUnreadOutlinedIcon />
                Unread {getTotalItems(totalUnread)}
              </>
            }
          />
          <M3TabButton
            disableRipple
            sx={{ width: 200 }}
            onClick={() => posthog.capture('inbox all messages tab clicked')}
            label={
              <>
                <MarkunreadOutlinedIcon />
                All Messages
              </>
            }
          />
        </Tabs>
        {rightActions}
      </Box>
    </AppActionToolbar>
  );
};

export default InboxAppActionToolbar;
