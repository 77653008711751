import React from 'react';
import { Skeleton, Stack } from '@mui/material';

import { M3Card } from './M3/M3Card';
import M3SurfaceContainer from './M3/M3SurfaceContainer';

type Props = {
  withToggle?: boolean;
};

const MyDayCardSkeleton = ({ withToggle = true }: Props) => {
  return (
    <M3Card
      noHover
      sx={{
        mb: 2,
        flex: 1,
      }}
    >
      <M3SurfaceContainer
        elevation={1}
        sx={{
          p: 4,
        }}
      >
        <Stack
          mb={2}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Skeleton
            variant='rectangular'
            sx={{
              height: 80,
              width: 450,
              borderRadius: 3,
            }}
          />
          {withToggle && (
            <Skeleton
              variant='rectangular'
              sx={{
                width: 56,
                height: 56,
                borderRadius: 2,
              }}
            />
          )}
        </Stack>
        <Skeleton
          variant='rectangular'
          sx={{
            mb: 2,
            height: 70,
            width: '100%',
            borderRadius: 3,
          }}
        />
        <Skeleton
          variant='rectangular'
          sx={{
            height: 70,
            width: '100%',
            borderRadius: 3,
          }}
        />
      </M3SurfaceContainer>
    </M3Card>
  );
};

export default MyDayCardSkeleton;
