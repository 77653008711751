import React, { SyntheticEvent, useEffect, useState } from 'react';
import { getToken, onMessage } from 'firebase/messaging';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Fade,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MuiNotification from '../Notification';

import { useAppProvider } from '../../providers/app/app';
import { useAddNotificationDeviceData } from '../../hooks/device';
import { messaging } from '../../services/firebase';
import { NotificationPayload } from '../../types/notification';
import { setToLocalStorage } from '../../utils/localstorage';

type PushNotificationListenerProps = {};

const PushNotificationListener: React.FC<
  PushNotificationListenerProps
> = () => {
  const { isDarkMode } = useAppProvider();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationPayload | null>(
    null,
  );
  const [snackBarKeyCounter, setSnackBarKeyCounter] = useState(0);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const postNotificationDevice = useAddNotificationDeviceData();

  const handleRedirectUser = () => {
    if (!notification) return;

    setIsSnackbarOpen(false);

    if (notification.data.topic === 'go2botsmessage') {
      return navigate('/inbox/go2bots/0');
    }

    if (notification.data.topic === 'help_needed') {
      return navigate('/inbox/help-needed/0');
    }

    return navigate('/inbox/all/0');
  };

  const handleCloseSnackbar = (evt: SyntheticEvent) => {
    evt.stopPropagation();
    setIsSnackbarOpen(false);
  };

  const onCloseSnackbar = () => setIsSnackbarOpen(false);

  const action = (
    <Box zIndex={600} position='absolute' top={5} right={6}>
      <IconButton
        sx={{
          color: isDarkMode
            ? 'var(--md-sys-color-on-primary-light)'
            : 'var(--md-sys-color-shadow-dark)',
        }}
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </Box>
  );

  useEffect(() => {
    // function to request permission and get the token
    const requestPermissionAndGetToken = async () => {
      try {
        // checks if the notification permission has been granted
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          // Get the token
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });

          if (currentToken) {
            // setting the token to local storage to be used to delete the registration_id in auth.tsx
            setToLocalStorage('firebase-token', currentToken);
            // Send the token to the server
            postNotificationDevice.mutate({
              registration_id: currentToken,
              type: 'web',
            });
          }
        } else {
          enqueueSnackbar('Notification permission has been denied.');
        }
      } catch (error) {
        enqueueSnackbar(
          'Error occurred while requesting notification permission.',
        );
      }
    };

    // Call the function to request permission and get the token
    requestPermissionAndGetToken();

    // Subscribe to messages
    const unsubscribeOnMessage = onMessage(messaging, (payload) => {
      const parseStringifyNotification: NotificationPayload = JSON.parse(
        payload.data?.notification as string,
      );

      setNotification(parseStringifyNotification);
      // Handle foreground messages here
      if (parseStringifyNotification) {
        setIsSnackbarOpen(false);
        setSnackBarKeyCounter((n) => ++n);
        requestAnimationFrame(() => setIsSnackbarOpen(true));
      }
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribeOnMessage();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Snackbar
      key={snackBarKeyCounter}
      sx={{
        width: 500,
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={3000}
      open={isSnackbarOpen}
      onClick={handleRedirectUser}
      onClose={onCloseSnackbar}
      TransitionComponent={Fade}
    >
      <div>
        <Slide direction='left' in>
          <SnackbarContent
            sx={{
              background: isDarkMode
                ? 'var(--md-ref-palette-tertiary10)'
                : 'var(--md-ref-palette-tertiary90)',
            }}
            action={action}
            message={<MuiNotification notification={notification} />}
          />
        </Slide>
      </div>
    </Snackbar>
  );
};

export default PushNotificationListener;
