import React, { SyntheticEvent, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import BasicForm, { useForm } from '../../BasicForm';
import MainContainer from '../../MainContainer';
import { M3TextField } from '../../M3/M3TextField';
import { M3Autocomplete } from '../../M3/M3Autocomplete';
import { M3SelectGroup } from '../../M3/M3Select';
import { M3Button } from '../../M3/M3Button';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';
import DocumentTitle from '../../DocumentTitle';

import {
  useIntercomFieldSearch,
  usePostHelpEngage,
  UsePostHelpEngageProps,
} from '../../../hooks/intercom';
import { useCurrentProfile } from '../../../hooks/profile';

type Props = {};

type FormState = UsePostHelpEngageProps & {
  division: string;
  teammate: string;
};

type FieldSearchResponse = {
  id: number | string;
  name: string;
};

const levelOfUrgencyOptions = ['Low', 'Medium', 'High', 'Urgent'].map(
  (level) => ({
    id: level,
    label: level,
  }),
);

const EngageForm = (props: Props) => {
  const { data: currentProfile } = useCurrentProfile();
  const { enqueueSnackbar } = useSnackbar();

  const {
    formKeyCount,
    formState,
    hasChanged,
    handleChange,
    handleSubmit,
    resetState,
    updateFormKeyCount,
  } = useForm<FormState>({
    division: '',
    division_id: null,
    teammate: '',
    teammate_id: null,
    level_of_urgency: '',
    issue_details: '',
    resource_urls: '',
    proposed_resolutions: '',
    intercom_user_id: currentProfile!.employee_id!,
  });

  const divisionSearch = useIntercomFieldSearch<FieldSearchResponse>({
    limit: 20,
    field: 'division',
    q: formState.division || '',
  });

  const teammateSearch = useIntercomFieldSearch<FieldSearchResponse>({
    limit: 20,
    field: 'teammate',
    q: formState.teammate || '',
  });

  const postHelpEngage = usePostHelpEngage();

  const confirmationDialog = useConfirmationDialog();

  const allRequiredAreFilled = !!(
    formState.division_id &&
    formState.level_of_urgency &&
    formState.issue_details &&
    formState.resource_urls &&
    formState.proposed_resolutions
  );

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      handleChange({
        target: {
          name,
          value: option ? (option as FieldSearchResponse).id : null,
        },
      });
    };

  const onSubmit = handleSubmit((data) => {
    confirmationDialog.confirm.setIsOpen(true);
  });

  const onSubmitConfirm = handleSubmit((data) => {
    if (postHelpEngage.isLoading) return;

    const payload: any = { ...data };
    delete payload.division;
    delete payload.teammate;

    postHelpEngage.mutate(payload);
  });

  useEffect(() => {
    if (postHelpEngage.isSuccess) {
      resetState();
      updateFormKeyCount('division', 'teammate');
      postHelpEngage.reset();
      confirmationDialog.confirm.setIsOpen(false);
      enqueueSnackbar(
        'Your submission of the engagement issue was successful.',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postHelpEngage.isSuccess]);

  return (
    <>
      <DocumentTitle title='Engage' trailingTitle='Help' />
      <MainContainer>
        <Box sx={{ p: 2, pt: 1 }}>
          <Typography component='h6' variant='h6' fontWeight={500}>
            Engagement Issue Form
          </Typography>
          <Typography fontWeight={400} fontSize={14} sx={{ opacity: 0.8 }}>
            Please ensure that all fields are filled out with the required
            information
          </Typography>
          <br />
          <BasicForm onSubmit={onSubmit}>
            <Stack gap={2}>
              <Stack direction='row' gap={2}>
                <M3Autocomplete
                  key={formKeyCount.division}
                  options={divisionSearch.data ?? []}
                  getOptionLabel={(option: any) =>
                    (option as FieldSearchResponse).name ?? ''
                  }
                  loading={divisionSearch.isFetching}
                  onChange={onAutocompleteChangeHandler('division_id')}
                  renderInput={(params) => (
                    <M3TextField
                      {...params}
                      name='division'
                      label='Partner Name'
                      fullWidth
                      value={formState.division}
                      onChange={handleChange}
                    />
                  )}
                  sx={{
                    flex: 1,
                  }}
                />
                <M3Autocomplete
                  key={formKeyCount.teammate}
                  options={teammateSearch.data ?? []}
                  getOptionLabel={(option: any) =>
                    (option as FieldSearchResponse).name ?? ''
                  }
                  loading={teammateSearch.isFetching}
                  onChange={onAutocompleteChangeHandler('teammate_id')}
                  renderInput={(params) => (
                    <M3TextField
                      {...params}
                      name='teammate'
                      label='Teammate (when applicable)'
                      fullWidth
                      value={formState.teammate}
                      onChange={handleChange}
                    />
                  )}
                  sx={{
                    flex: 1,
                  }}
                />
              </Stack>
              <Box>
                <M3SelectGroup
                  label='Level of urgency'
                  options={levelOfUrgencyOptions}
                  selectProps={{
                    fullWidth: true,
                    name: 'level_of_urgency',
                    value: formState.level_of_urgency,
                    onChange: handleChange,
                  }}
                  formControlSx={{ width: '100%' }}
                />
              </Box>
              <M3TextField
                label='Detail your issue'
                name='issue_details'
                multiline
                minRows={3}
                value={formState.issue_details}
                onChange={handleChange}
              />
              <M3TextField
                label='Links to additional resources'
                name='resource_urls'
                multiline
                minRows={3}
                value={formState.resource_urls}
                onChange={handleChange}
              />
              <M3TextField
                label='Proposed Resolution(s)'
                name='proposed_resolutions'
                multiline
                minRows={3}
                value={formState.proposed_resolutions}
                onChange={handleChange}
              />
              <Stack direction='row'>
                <M3Button
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={
                    postHelpEngage.isLoading || hasChanged
                      ? !allRequiredAreFilled
                      : true
                  }
                  sx={{ minWidth: 110 }}
                >
                  Submit
                </M3Button>
              </Stack>
            </Stack>
          </BasicForm>
        </Box>
      </MainContainer>
      <ConfirmationDialog
        {...confirmationDialog.confirm}
        title='Engagement Issue Confirmation'
        message={
          'Are you ready to submit the issue? Please click the confirm button to proceed.'
        }
        isLoading={postHelpEngage.isLoading}
        onConfirm={onSubmitConfirm}
      />
    </>
  );
};

export default EngageForm;
