import React, { useState } from 'react';
import { Modal } from '@mui/material';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';

import { M3Button } from '../../M3/M3Button';
import JobBreakPolicyModal from './JobBreakPolicyModal';

type Props = {
  divisionId?: number;
};

const JobBreakPolicy = ({ divisionId }: Props) => {
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);

  return (
    <>
      <M3Button active={isPolicyOpen} onClick={() => setIsPolicyOpen(true)}>
        <PolicyOutlinedIcon /> Break Policy
      </M3Button>
      <Modal open={isPolicyOpen}>
        <JobBreakPolicyModal
          divisionId={divisionId}
          close={() => setIsPolicyOpen(false)}
        />
      </Modal>
    </>
  );
};

export default JobBreakPolicy;
