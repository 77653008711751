import React from 'react';
import clsx from 'clsx';
import { Fab, FabProps, styled } from '@mui/material';
import { useAppProvider } from '../../providers/app/app';

export const M3FabBase = styled(Fab)`
  border-radius: 16px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  text-transform: initial;
  color: var(--md-ref-palette-primary10);
  background: var(--md-ref-palette-primary90);

  .MuiSvgIcon-root {
    font-size: 24px;
  }

  &.MuiFab-sizeLarge {
    height: 56px;
    min-width: 56px;
  }

  &.MuiFab-extended {
    padding-right: 24px;
    .MuiSvgIcon-root {
      margin-right: 8px;
    }
  }

  &.elevated {
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%),
      0 4px 8px 3px rgb(60 64 67 / 15%);
  }

  &:hover {
    background: var(--md-ref-palette-primary90);
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%),
      0 4px 8px 3px rgb(60 64 67 / 15%);
  }

  &.dark-mode-button {
    color: var(--md-ref-palette-primary100);
    background: var(--md-ref-palette-primary30);

    &:hover {
      box-shadow: 0 1px 3px 0 rgb(255 255 255 / 30%),
        0 4px 8px 3px rgb(255 255 255 / 15%);
    }
  }
`;

type M3FabProps = FabProps & {
  elevated?: boolean;
};
export const M3Fab = ({ elevated = true, ...restProps }: M3FabProps) => {
  const { isDarkMode } = useAppProvider();
  return (
    <M3FabBase
      {...restProps}
      className={clsx({
        elevated,
        'dark-mode-button': isDarkMode,
      })}
    />
  );
};
