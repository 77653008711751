import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';
import {
  TimeTrackerTaskItem,
  TimeTrackerTaskItemDetail,
  TimeTrackerUserProfileResponse,
} from '../types/time-tracker';
import { BasicQuery, ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { AxiosRequestConfig } from 'axios';

export type UseTimeTrackerTasksParams = ListQuery & {
  assigned_to?: string;
  is_active?: boolean;
  project?: string;
  name?: string;
  divisions?: string | number;
  unassigned?: boolean;
};
export function useTimeTrackerTasks<R = TimeTrackerTaskItem>(
  params: UseTimeTrackerTasksParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseTimeTrackerTasksParams>(
    {
      params,
      baseIdentifier: 'useTimeTrackerTasks',
      identifierKeys: [
        'assigned_to',
        'is_active',
        'project',
        'divisions',
        'unassigned',
      ],
      queryParamKeys: [
        'assigned_to',
        'is_active',
        'project',
        'name',
        'divisions',
        'unassigned',
      ],
    },
  );

  return useQueryApi<UseTimeTrackerTasksParams, ListResult<R>>(
    identifiers,
    '/time-tracker/tasks/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseTimeTrackerTaskByIdParams = BasicQuery & {
  id: string | number;
};
export function useTimeTrackerTaskById<R = TimeTrackerTaskItemDetail>(
  params: UseTimeTrackerTaskByIdParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers } = useIdentifier<UseTimeTrackerTaskByIdParams>({
    params,
    baseIdentifier: 'useTimeTrackerTaskById',
    identifierKeys: ['id'],
  });

  return useQueryApi<{}, R>(
    identifiers,
    `/time-tracker/tasks/${params.id}`,
    {},
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UsePostTimeTrackerTaskData = {
  name: string;
  description?: string;
  tdid?: string;
  is_generic?: boolean;
  project?: number;
  eod_form?: number;
  generic_to?: 0;
  created_by?: number;
};
export function usePostTimeTrackerTask<D = UsePostTimeTrackerTaskData>(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>(`/time-tracker/tasks/`, {}, axiosConfig);
}

export type UseUpdateTimeTrackerTaskData = {
  name: string;
  description?: string;
  tdid?: string;
  is_generic: boolean;
  project: number;
  eod_form?: number;
  generic_to?: 0;
};
export function useUpdateTimeTrackerTaskById<D = UsePostTimeTrackerTaskData>(
  { id: taskFocusId }: any,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>(
    `/time-tracker/tasks/${taskFocusId}/`,
    {},
    {
      ...axiosConfig,
      method: axiosConfig?.method ?? 'PATCH',
    },
  );
}

type UsePatchTimeTrackerTaskParams = {
  id: string | number;
};
export function usePatchTimeTrackerTask(
  data: UsePatchTimeTrackerTaskParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<
    Partial<UsePostTimeTrackerTaskData>,
    TimeTrackerTaskItem
  >(`/time-tracker/tasks/${data.id}/`, {}, { ...axiosConfig, method: 'PATCH' });
}

export type UseTimeTrackerProfileByUserParams = {
  userId: number;
};
export function useTimeTrackerProfileByUser<R = TimeTrackerUserProfileResponse>(
  params: UseTimeTrackerProfileByUserParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseTimeTrackerProfileByUserParams>({
      params,
      baseIdentifier: 'useTimeTrackerProfileByUser',
      identifierKeys: ['userId'],
    });

  return useQueryApi<UseTimeTrackerProfileByUserParams, R>(
    identifiers,
    `/time-tracker/profiles/${params.userId}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UsePatchTimeTrackerProfileByUserParams = {
  userId: string | number;
};
export function usePatchTimeTrackerProfileByUser(
  params: UsePatchTimeTrackerProfileByUserParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<
    Partial<{
      idle_threshold: number;
    }>,
    TimeTrackerTaskItem
  >(
    `/time-tracker/profiles/${params.userId}/`,
    {},
    { ...axiosConfig, method: 'PATCH' },
  );
}
