import React, { PropsWithChildren } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Chip, Skeleton, Stack, Typography } from '@mui/material';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';

import { M3Card } from '../../M3/M3Card';
import M3SurfaceContainer from '../../M3/M3SurfaceContainer';

import { TimeTrackerTaskItem } from '../../../types/time-tracker';

type FocusAreasFocusItemProps = PropsWithChildren & {
  item: TimeTrackerTaskItem;
  path: string;
};

export const FocusAreasFocusItem = ({
  item,
  path,
}: FocusAreasFocusItemProps) => {
  return (
    <Link
      to={path}
      style={{
        color: 'initial',
        textDecoration: 'initial',
      }}
    >
      <M3Card
        sx={{
          cursor: 'pointer',
          marginBottom: '12px',
        }}
      >
        <M3SurfaceContainer
          elevation={1}
          borderRadius={3}
          sx={{
            p: 2,
            pl: 3,
            pr: 3,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              display='flex'
              alignItems='center'
              component='div'
              fontSize={22}
              pb={0.5}
            >
              <WysiwygOutlinedIcon style={{ fontSize: 22, marginRight: 8 }} />
              {item.name}
            </Typography>
            <Typography
              display='flex'
              alignItems='center'
              component='div'
              gap={1}
              fontSize={14}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {item.jobs.map((job) => (
                  <Chip key={job.id} label={job.name} size='small' />
                ))}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Stack direction={'column'}>
              <Typography
                component='div'
                fontSize={12}
                sx={{ opacity: 0.5 }}
                align='right'
              >
                Last Edited
              </Typography>
              <Typography component='div' fontSize={12} align='right'>
                {moment.utc(item.modified).local().format('MMM D, YYYY')}
              </Typography>
            </Stack>

            <Stack
              direction='row'
              spacing={1}
              mt={1}
              justifyContent={'flex-end'}
            >
              {!item.is_active && (
                <Chip
                  label='Inactive'
                  size='small'
                  variant='outlined'
                  color='warning'
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
              )}
              {item.is_generic && (
                <Chip
                  label={'Generic'}
                  size='small'
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
              )}
            </Stack>
          </Box>
        </M3SurfaceContainer>
      </M3Card>
    </Link>
  );
};

export default FocusAreasFocusItem;

type FocusAreasFocusItemSkeletonProps = PropsWithChildren;
export function FocusAreasFocusItemSkeleton(
  props: FocusAreasFocusItemSkeletonProps,
) {
  return (
    <M3Card
      sx={{
        marginBottom: '12px',
      }}
    >
      <M3SurfaceContainer
        elevation={1}
        borderRadius={3}
        sx={{
          p: 2,
          pl: 3,
          pr: 3,
          pb: 1.3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Skeleton variant='text' height={40} width='20vw' sx={{ mt: -0.5 }} />
          <Skeleton variant='text' height={36} width={80} />
        </Box>
        <Box>
          <Skeleton variant='text' height={20} width={80} sx={{ mt: -0.2 }} />
          <Skeleton variant='text' height={20} width={60} sx={{ mt: -0.2 }} />
        </Box>
      </M3SurfaceContainer>
    </M3Card>
  );
}
