import React, { SyntheticEvent, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import BasicForm, { useForm } from '../../BasicForm';
import MainContainer from '../../MainContainer';
import { M3TextField } from '../../M3/M3TextField';
import { M3Autocomplete } from '../../M3/M3Autocomplete';
import { M3Button } from '../../M3/M3Button';
import AutocompleteTags, { TagItem } from '../../Filters/AutocompleteTags';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';
import DocumentTitle from '../../DocumentTitle';

import {
  useIntercomFieldSearch,
  UsePostHelpBookkeepingProps,
  usePostHelpBookkeeping,
} from '../../../hooks/intercom';
import { spliceFromArray } from '../../../utils/array';

type Props = {};

type FormState = UsePostHelpBookkeepingProps & {
  division: string;
  teammate: string;
  teammates: TagItem[];
};

type FieldSearchResponse = {
  id: number | string;
  name: string;
};

const BookkeepingForm = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    formKeyCount,
    formState,
    hasChanged,
    handleChange,
    handleSubmit,
    resetState,
    updateFormKeyCount,
  } = useForm<Omit<FormState, 'teammate_ids'>>({
    division: '',
    division_id: null,
    teammate: '',
    teammates: [],
    amount: '',
    priority_request: '',
  });

  const divisionSearch = useIntercomFieldSearch<FieldSearchResponse>({
    limit: 20,
    field: 'division',
    q: formState.division || 'a',
  });

  const teammateSearch = useIntercomFieldSearch<FieldSearchResponse>({
    limit: 20,
    field: 'teammate',
    q: formState.teammate || 'a',
  });

  const postHelpBookkeeping = usePostHelpBookkeeping();

  const confirmationDialog = useConfirmationDialog();

  const teammate_ids = formState.teammates.map((teammate) => teammate.id);
  const allRequiredAreFilled = !!(
    formState.division_id &&
    teammate_ids.length &&
    formState.amount &&
    formState.priority_request
  );

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      handleChange({
        target: {
          name,
          value: option ? (option as FieldSearchResponse).id : null,
        },
      });
    };

  const onSubmit = handleSubmit((data) => {
    confirmationDialog.confirm.setIsOpen(true);
  });

  const onSubmitConfirm = handleSubmit((data) => {
    if (postHelpBookkeeping.isLoading) return;

    const teammate_ids = (data.teammates ?? []).map((teammate) => teammate.id);
    const payload: {
      division_id: FormState['division_id'];
      amount: FormState['amount'];
      priority_request: FormState['priority_request'];
      teammate_ids: (string | number)[];
    } = {
      division_id: data.division_id,
      amount: data.amount,
      priority_request: data.priority_request,
      teammate_ids,
    };

    postHelpBookkeeping.mutate(payload);
  });

  useEffect(() => {
    if (postHelpBookkeeping.isSuccess) {
      resetState();
      updateFormKeyCount('division', 'teammate');
      postHelpBookkeeping.reset();
      confirmationDialog.confirm.setIsOpen(false);
      enqueueSnackbar(
        'Your submission of the bookkeeping issue was successful.',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postHelpBookkeeping.isSuccess]);

  return (
    <>
      <DocumentTitle title='Bookkeeping' trailingTitle='Help' />
      <MainContainer>
        <Box sx={{ p: 2, pt: 1 }}>
          <Typography component='h6' variant='h6' fontWeight={500}>
            Bookkeeping Concerns Form
          </Typography>
          <Typography fontWeight={400} fontSize={14} sx={{ opacity: 0.8 }}>
            Please ensure that all fields are filled out with the required
            information
          </Typography>
          <br />
          <BasicForm onSubmit={onSubmit}>
            <Stack direction='column' gap={2}>
              <Stack
                direction='row'
                gap={2}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <M3Autocomplete
                  key={formKeyCount.division}
                  options={divisionSearch.data ?? []}
                  getOptionLabel={(option: any) =>
                    (option as FieldSearchResponse).name ?? ''
                  }
                  loading={divisionSearch.isFetching}
                  onChange={onAutocompleteChangeHandler('division_id')}
                  renderInput={(params) => (
                    <M3TextField
                      {...params}
                      name='division'
                      label='Partner Name'
                      fullWidth
                      value={formState.division}
                      onChange={handleChange}
                    />
                  )}
                  sx={{
                    flex: 1,
                  }}
                />
                <M3TextField
                  label='Amount'
                  name='amount'
                  value={formState.amount}
                  onChange={handleChange}
                  sx={{
                    minWidth: 300,
                  }}
                />
              </Stack>
              <AutocompleteTags
                key={formKeyCount.teammate}
                name='teammate'
                placeholder='Select Teammates...'
                options={teammateSearch.data ?? []}
                getOptionLabel={(option: any) =>
                  (option as FieldSearchResponse).name ?? ''
                }
                loading={teammateSearch.isFetching}
                tags={formState.teammates}
                onDeleteTag={(tag: TagItem) => {
                  let value = [...formState.teammates];
                  spliceFromArray(value, tag, 'id');
                  handleChange({
                    target: {
                      name: 'teammates',
                      value,
                    },
                  });
                }}
                onSelect={(
                  evt: SyntheticEvent,
                  option: FieldSearchResponse,
                ) => {
                  if (option) {
                    handleChange({
                      target: {
                        name: 'teammates',
                        value: option
                          ? [
                              ...formState.teammates,
                              { id: option.id, value: option.name },
                            ]
                          : formState.teammates,
                      },
                    });
                  }
                }}
                onChange={handleChange}
              />
              <M3TextField
                label='Detailed Message'
                name='priority_request'
                multiline
                minRows={3}
                value={formState.priority_request}
                onChange={handleChange}
              />
              <Stack direction='row'>
                <M3Button
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={
                    postHelpBookkeeping.isLoading || hasChanged
                      ? !allRequiredAreFilled
                      : true
                  }
                  sx={{ minWidth: 110 }}
                >
                  Submit
                </M3Button>
              </Stack>
            </Stack>
          </BasicForm>
        </Box>
      </MainContainer>
      <ConfirmationDialog
        {...confirmationDialog.confirm}
        title='Bookkeeping Concerns Confirmation'
        message={
          'Are you ready to submit the issue? Please click the confirm button to proceed.'
        }
        isLoading={postHelpBookkeeping.isLoading}
        onConfirm={onSubmitConfirm}
      />
    </>
  );
};

export default BookkeepingForm;
