import { Box, Skeleton, SxProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { ReactRenderElement } from '../types/types';

type Props = {
  icon?: ReactRenderElement;
  title: ReactNode | string | null;
  message?: ReactNode | string | null;
  spinner?: boolean;
  sx?: SxProps;
};

const PageWait = ({
  icon,
  title,
  message = (
    <>
      Maybe this page used to exist or you just spelled something wrong. Chances
      are you spelled something wrong, so you can double check the URL?
      <br />
      <br />
      Mind checking your internet connect as well?
    </>
  ),
  spinner,
  sx,
}: Props) => {
  const renderSkeleton = () => {
    return (
      <Skeleton
        variant='rectangular'
        sx={{
          mt: 2,
          mb: 2,
          ml: 'auto',
          mr: 'auto',
          width: '120%',
          height: 10,
          marginLeft: '-10%',
          borderRadius: 5,
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        padding: '20vh 0 10vh',
        ...sx,
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          flex: 1,
          maxWidth: 600,
          margin: '0 auto',
        }}
      >
        {icon}
        <Typography
          component='div'
          fontSize={22}
          fontWeight={500}
          textAlign='center'
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
        <Typography
          component='div'
          fontSize={16}
          fontWeight={400}
          textAlign='center'
        >
          {message}
        </Typography>
        {spinner && renderSkeleton()}
      </Box>
    </Box>
  );
};

export default PageWait;
