import React from 'react';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';

import AbsoluteCenterBox from '../AbsoluteCenterBox';
import BasicIframe from '../BasicIframe';
import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';

type Props = {
  html?: string;
  src?: string;
  isLoading?: boolean;
  sx?: SxProps;
  title: ReactRenderElement;
};

const CardEmbed: React.FC<Props> = ({ src, title, html, sx }) => {
  const { isDarkMode } = useAppProvider();

  const wrapperSX: SxProps = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
    background: isDarkMode ? 'var(--md-sys-color-background-dark)' : '#f5f5f5',
  };
  return (
    <Box mt={2}>
      <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Box
        sx={{
          height: 420,
          borderRadius: 1.5,
          overflow: 'hidden',
          position: 'relative',
          boxShadow: isDarkMode
            ? '0 0 0 1px rgba(255, 255, 255, 0.1)'
            : '0 0 0 1px #e0e0e0',
        }}
      >
        <Box
          sx={{
            ...wrapperSX,
            '&:before': {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              content: '""',
              position: 'absolute',
              background: 'rgba(255, 255, 255, 0.05)',
            },
          }}
        >
          <AbsoluteCenterBox>
            <CircularProgress size={24} />
          </AbsoluteCenterBox>
          {!!(html || src) && (
            <Box sx={{ ...wrapperSX, ...sx }}>
              <BasicIframe src={src} srcDoc={html} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardEmbed;
