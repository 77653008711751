import React, { CSSProperties } from 'react';
import { Config } from 'dompurify';

import RichTextContent from '../../RichEditor/RichTextContent';

import { JobDescriptionItemResult } from '../../../types/job-description';
import { IterableObject } from '../../../types/types';

type JobDescriptionProps = {
  data: JobDescriptionItemResult;
};

const JobDescription = ({ data }: JobDescriptionProps) => {
  const domPurifyOptions: Partial<Config> = {};

  const styles: IterableObject<CSSProperties> = {
    page: {
      left: 0,
      right: 0,
      width: '100%',
      marginLeft: '2rem',
      marginRight: '2rem',
      pageBreakAfter: 'always',
      fontFamily: `'Roboto Flex', 'Roboto', 'Helvetica Neue', 'Helvetica', 'sans-serif'`,
    },

    row: {
      display: 'flex',
      flexDirection: 'row',
    },

    justifyBetween: {
      justifyContent: 'space-between',
    },

    intro: {
      fontSize: 18,
      paddingBottom: 2,
      borderBottom: '2px solid #f3f3f3',
    },

    subIntro: {
      fontSize: 8,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },

    title: {
      fontSize: 12,
      paddingBottom: 4,
    },

    content: {
      fontSize: 9,
      borderRadius: 4,
      background: '#f3f3f3',
      padding: '10px 20px',
    },

    pageBreakInsideAvoid: {
      pageBreakBefore: 'always',
      pageBreakInside: 'avoid',
    },

    circle: {
      top: 4,
      width: 18,
      height: 18,
      fontSize: 8,
      lineHeight: 1,
      marginRight: 8,
      paddingBottom: 3,
      position: 'relative',
      borderRadius: '50%',
      textAlign: 'center',
      background: '#f3f3f3',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    timestamp: {
      fontSize: 6,
    },
  };

  const renderContent = (content: string) => {
    content = (content || '')
      .replace(/<li><\/li>|<li><br><\/li>|<p><br><\/p>/gi, '')
      .replace(/<ul>/gi, (ul) => {
        return `<ul style="list-style-type: disc; margin-left: -16px">`;
      })
      .replace(/<ol>/gi, (ol) => {
        return `<ol style="list-style-type: decimal; margin-left: -16px">`;
      })
      .replace(/<li>/gi, (li) => {
        return `<li style="padding-left: 8px">`;
      });
    return (
      <RichTextContent content={content} domPurifyOptions={domPurifyOptions} />
    );
  };

  return (
    <div style={styles.page}>
      <div
        style={{
          ...styles.row,
          ...styles.justifyBetween,
        }}
      >
        <div style={{ marginTop: -4 }}>
          <h4 style={styles.intro}>{data.title}</h4>
          {/* <h5 style={styles.subIntro}>
            <div style={styles.circle}>
              {
                getUserInitials(
                  (data.division || '') as string,
                  ((data.division || '') as string).charAt(0) === '-' ? 2 : 1,
                ).initial
              }
            </div>
            {data.division}
          </h5> */}
        </div>
        {/* <div style={styles.timestamp}>
          <span style={{ color: '#777777' }}>Last Edited:&nbsp;</span>
          {moment.utc(data.modified).local().format('MMM D, YYYY')}
        </div> */}
      </div>
      <br />
      <div style={styles.pageBreakInsideAvoid}>
        <h6 style={styles.title}>Position Summary:</h6>
        <div style={styles.content}>
          {renderContent(data.position_summary!)}
        </div>
      </div>
      <br />
      <div style={styles.pageBreakInsideAvoid}>
        <h6 style={styles.title}>Principal Duties and Responsibilities:</h6>
        <div style={styles.content}>
          {renderContent(data.principal_duties!)}
        </div>
      </div>
      <br />
      <div style={styles.pageBreakInsideAvoid}>
        <h6 style={styles.title}>Other Duties and Responsibilities:</h6>
        <div style={styles.content}>
          {renderContent(data.other_responsibilities!)}
        </div>
      </div>
      <br />
      <div style={styles.pageBreakInsideAvoid}>
        <h6 style={styles.title}>What Does Success Look Like:</h6>
        <div style={styles.content}>
          {renderContent(data.success_indicator!)}
        </div>
      </div>
    </div>
  );
};

export default JobDescription;
