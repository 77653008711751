import React, { SyntheticEvent } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Checkbox,
  ListItemAvatar,
  Skeleton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import InboxCardListItem from './InboxCardListItem';
import { M3ListItemText } from '../M3/M3ListItem';
import RichTextContent from '../RichEditor/RichTextContent';

import { UserMetadata } from '../../types/profile';
import { getUserInitials } from '../../utils/user';
import { DateInUTC, ReactRenderElement } from '../../types/types';
import * as posthog from '../../services/posthog';

type InboxItem = {
  id: number;
  unread: boolean;
  content: string | ReactRenderElement;
  created: DateInUTC;
  modified: DateInUTC;
  object_id: number;
};
type InboxListItemProps = {
  url: string;
  selected?: boolean;
  user?: Partial<UserMetadata>;
  item: InboxItem;
  withCheckbox?: boolean;
  tag?: ReactRenderElement;
  onSelect?: (checked: boolean, item: InboxItem) => void;
  onClick?: (evt: SyntheticEvent) => void;
};

const InboxListItem = ({
  url,
  selected,
  user,
  item,
  withCheckbox = true,
  tag,
  onSelect,
  onClick,
}: InboxListItemProps) => {
  const navigate = useNavigate();

  return (
    <InboxCardListItem
      key={item.id}
      unread={item.unread}
      sx={{
        mt: 1,
        mb: 1,
        cursor: 'pointer',
      }}
      surfaceSx={{
        ml: withCheckbox ? 2 : 0,
      }}
      className={clsx({
        unread: !!item.unread,
      })}
      onClick={(evt: SyntheticEvent) => {
        if (onClick) {
          if (item.unread) {
            posthog.capture('inbox unread message clicked', {
              item_id: item.id,
            });
          } else {
            posthog.capture('inbox read message clicked', { item_id: item.id });
          }

          onClick(evt);
        }

        navigate(url);
      }}
    >
      {withCheckbox &&
        renderCheckbox({
          item,
          read: !item.unread,
          selected,
          onSelect,
        })}
      <ListItemAvatar>
        <Avatar src={user?.photo_url}>
          {
            getUserInitials(
              `${user?.first_name || ''} ${user?.last_name || ''}`,
            ).initial
          }
        </Avatar>
      </ListItemAvatar>
      <M3ListItemText
        primary={
          <Typography display='flex' alignItems='center' component='div'>
            <Typography component='div' fontWeight={500}>
              {user?.first_name} {user?.last_name}
              {!(user?.first_name || user?.last_name) && (
                <Skeleton width={100} />
              )}
            </Typography>
            &nbsp;
            <span
              style={{
                opacity: 0.5,
                fontSize: 12,
              }}
            >
              - {moment.utc(item.created).local().format('MMM D, YYYY')}{' '}
            </span>
          </Typography>
        }
        secondary={
          item.content === 'string' ? (
            <RichTextContent
              content={item.content as string}
              domPurifyOptions={{ ALLOWED_TAGS: [] }}
              className='secondary-comment'
            />
          ) : (
            item.content
          )
        }
        sx={{
          '.MuiListItemText-secondary': {
            paddingRight: 5,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
      />
      {tag}
      <ChevronRightIcon sx={{ opacity: 0.5 }} />
    </InboxCardListItem>
  );
};

const renderCheckbox = ({
  item,
  selected = false,
  read = false,
  onSelect,
}: {
  read?: boolean;
  selected?: boolean;
  item: InboxListItemProps['item'];
  onSelect?: InboxListItemProps['onSelect'];
}) => {
  if (read) return null;

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        top: 0,
        bottom: 0,
        width: 50,
        left: -48,
        cursor: 'initial',
        position: 'absolute',
      }}
      onClick={(evt) => evt.stopPropagation()}
    >
      <Checkbox
        size='small'
        checked={selected}
        onChange={(evt) => {
          onSelect?.(evt.currentTarget.checked, item);
        }}
      />
    </Box>
  );
};

export default InboxListItem;
