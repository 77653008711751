import { UseQueryOptions } from '@tanstack/react-query';
import {
  DomoFilterFocus,
  DomoFilterMember,
  DomoFilterQuestion,
} from '../types/domo';
import { BasicQuery, ListQuery } from '../types/request';
import { DateYYYYMMDD } from '../types/types';
import { defaultReactQueryParams } from '../utils/request';
import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

type UseDomoFilterMemberProps = ListQuery & {
  divisions?: number | string;
  date_start: string;
  date_end: string;
};
export function useDomoFilterMember<R = DomoFilterMember>(
  params: UseDomoFilterMemberProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseDomoFilterMemberProps>({
    params,
    baseIdentifier: 'useDomoFilterMember',
    identifierKeys: ['divisions', 'date_start', 'date_end'],
    queryParamKeys: ['divisions', 'date_start', 'date_end'],
  });

  return useQueryApi<UseDomoFilterMemberProps, R[]>(
    identifiers,
    '/core/domo-filters/filter-search-answers-member-v2/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseDomoFilterFocusProps = ListQuery & {
  divisions?: number | string;
  date_start: string;
  date_end: string;
  member?: string;
  has_question_type?: 'qualitative' | 'quantitative';
};
export function useDomoFilterFocus<R = DomoFilterFocus>(
  params: UseDomoFilterFocusProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseDomoFilterFocusProps>({
    params,
    baseIdentifier: 'useDomoFilterFocus',
    identifierKeys: [
      'divisions',
      'date_start',
      'date_end',
      'member',
      'has_question_type',
    ],
    queryParamKeys: [
      'divisions',
      'date_start',
      'date_end',
      'member',
      'has_question_type',
    ],
  });

  return useQueryApi<ListQuery, R[]>(
    identifiers,
    '/core/domo-filters/filter-search-answers-focus-v2/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseDomoFilterQuestionProps = ListQuery & {
  divisions?: string | number;
  date_start: string;
  date_end: string;
  members?: string;
  focus?: string;
};
export function useDomoFilterQuestion<R = DomoFilterQuestion>(
  params: UseDomoFilterQuestionProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseDomoFilterQuestionProps>({
      params,
      baseIdentifier: 'useDomoFilterQuestion',
      identifierKeys: [
        'divisions',
        'date_start',
        'date_end',
        'members',
        'focus',
      ],
      queryParamKeys: [
        'divisions',
        'date_start',
        'date_end',
        'members',
        'focus',
      ],
    });

  return useQueryApi<ListQuery, R[]>(
    identifiers,
    '/core/domo-filters/filter-search-answers-question-v2/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseExportSearchAnswersProps = {
  date_start: DateYYYYMMDD;
  date_end: DateYYYYMMDD;
  division?: string;
  focus?: string;
  member?: string;
  question?: string;
  fields: string;
};
export function useExportSearchAnswers(
  params: UseExportSearchAnswersProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseExportSearchAnswersProps>({
      params,
      baseIdentifier: 'useExportSearchAnswers',
      identifierKeys: [
        'date_start',
        'date_end',
        'division',
        'focus',
        'member',
        'question',
      ],
      queryParamKeys: [
        'date_start',
        'date_end',
        'division',
        'focus',
        'member',
        'question',
        'fields',
      ],
    });

  return useQueryApi<UseExportSearchAnswersProps & { format?: 'csv' }, any>(
    identifiers,
    '/core/domo-filters/export-search-answers-v2/',
    { ...queryParams, format: 'csv' },
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseExportDidGetWinProps = BasicQuery & {
  start_date: DateYYYYMMDD;
  end_date: DateYYYYMMDD;
  divisions?: string;
  circles?: string;
  users?: string;
};
export function useExportDidGetWin(
  params: UseExportDidGetWinProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseExportDidGetWinProps>({
    params,
    baseIdentifier: 'useExportDidGetWin',
    identifierKeys: ['start_date', 'end_date', 'divisions', 'circles', 'users'],
    queryParamKeys: ['start_date', 'end_date', 'divisions', 'circles', 'users'],
  });

  return useQueryApi<UseExportDidGetWinProps & { format?: 'csv' }, any>(
    identifiers,
    '/reporting/v2/reports/export-did-get-win/',
    { ...queryParams, format: 'csv' },
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
