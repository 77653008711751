import React, { Fragment, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MainContainer from '../components/MainContainer';
import WindowScrollTop from '../components/WindowScrollTop';
import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import Error from './Error';
import InboxScheduleRequestItemDetail from '../components/Inbox/InboxScheduleRequestItemDetail';
import InboxScheduleChangRequestItemDetailSkeleton from '../components/Inbox/InboxScheduleChangRequestItemDetailSkeleton';

import { parseURL } from '../utils/url';
import { ReactRenderElement } from '../types/types';
import { useBatchChangeRequestScheduleByBatchId } from '../hooks/go2-schedules';

type Props = {
  scheduleBatchId?: string;
  isFromAllPage?: boolean;
  nextPrevNavigation?: ReactRenderElement;
};

const InboxScheduleRequestDetail = ({
  scheduleBatchId,
  nextPrevNavigation,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isLoading,
    data: scheduleBatchData,
    refetch: refetchScheduleBatchData,
  } = useBatchChangeRequestScheduleByBatchId(
    { batch_id: scheduleBatchId },
    { enabled: !!scheduleBatchId },
  );

  const handleOnTakenActionSuccess = useCallback(() => {
    refetchScheduleBatchData();
  }, [refetchScheduleBatchData]);

  const renderContent = () => {
    if (isLoading && !scheduleBatchData) {
      return <InboxScheduleChangRequestItemDetailSkeleton />;
    }

    if (!scheduleBatchData) return <Error title='Schedule Request Not Found' />;

    return (
      <InboxScheduleRequestItemDetail
        scheduleChangeRequestData={scheduleBatchData}
        onTakenActionSuccess={handleOnTakenActionSuccess}
      />
    );
  };

  return (
    <Fragment key={scheduleBatchId}>
      <WindowScrollTop />
      <MainContainer>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              const { t } = parseURL(location.search);
              const p = location.pathname
                .split('/')
                .filter((s) => !!s)
                .slice(0, 2)
                .join('/');
              navigate(`/${p}/${t ?? 0}`);
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box flex={1} />
          {nextPrevNavigation}
        </AppActionToolbar>
        {renderContent()}
      </MainContainer>
    </Fragment>
  );
};

export default InboxScheduleRequestDetail;
