import React from 'react';

import Intercom from './Intercom';

type Props = {};

const HelpFAQ = (props: Props) => {
  return <Intercom {...props} />;
};

export default HelpFAQ;
