import React, { FC } from 'react';
import { ReactRenderElement } from '../types/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import WindowScrollTop from '../components/WindowScrollTop';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import { parseURL } from '../utils/url';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { useJobDescriptionById } from '../hooks/jobs';
import JobDescriptionDetailSkeleton from '../components/TeamSettings/JobDescription/JobDescriptionDetailSkeleton';
import Error from './Error';
import JobDescriptionDetail from '../components/TeamSettings/JobDescription/JobDescriptionDetail';

type InboxJobDescriptionDetailProps = {
  objectId?: string;
  isFromAllPage?: boolean;
  nextPrevNavigation?: ReactRenderElement;
};
type JobDescriptionRouteParams = {
  jobDescriptionId: string;
};

const InboxJobDescriptionDetail: FC<InboxJobDescriptionDetailProps> = ({
  objectId,
  nextPrevNavigation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jobDescriptionId } = useParams<JobDescriptionRouteParams>();
  const jobDescription = useJobDescriptionById({
    id: objectId ?? jobDescriptionId!,
  });
  const jobDescriptionData = jobDescription.data;

  const renderContent = () => {
    if (jobDescription.isLoading && !jobDescriptionData) {
      return <JobDescriptionDetailSkeleton />;
    }

    if (!jobDescriptionData || !jobDescriptionData.id) {
      return <Error title='Not Found' />;
    }

    return (
      <JobDescriptionDetail
        userView
        editable={false}
        jobDescriptionId={objectId ?? jobDescriptionId}
      />
    );
  };

  return (
    <>
      <WindowScrollTop />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              const { t } = parseURL(location.search);
              const p = location.pathname
                .split('/')
                .filter((s) => !!s)
                .slice(0, 2)
                .join('/');
              navigate(`/${p}/${t ?? 0}`);
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box flex={1} />
          {nextPrevNavigation}
        </AppActionToolbar>
        <br />
        {renderContent()}
      </MainContainer>
    </>
  );
};

export default InboxJobDescriptionDetail;
