import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntercomCollectionItems } from '../../providers/intercom';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import { IntercomCollectionItem } from '../Intercom/IntercomCollections';

type Props = {};

const ContentPreviewCollections = (props: Props) => {
  const { audienceSegmentId } = useParams();
  const { collections, admins } = useIntercomProvider();
  const collectionItems = useIntercomCollectionItems({ collections, admins });

  return (
    <>
      {collectionItems.map((item) => (
        <IntercomCollectionItem
          key={item.id}
          item={item}
          to={`/content/target-audience/${audienceSegmentId}/collection/${item.id}`}
        />
      ))}
    </>
  );
};

export default ContentPreviewCollections;
