import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../utils/request';
import { useQueryApi } from './global/useApi';
import { TaskTimedoctorItem } from '../types/task';
import { useIdentifier } from './global/useIdentifier';

export type UseTaskByIdParams = {
  id: string | number;
};
export function useTaskById<R = TaskTimedoctorItem>(
  params: UseTaskByIdParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseTaskByIdParams>({
    params,
    baseIdentifier: 'useTaskById',
    identifierKeys: ['id'],
  });
  const getEndpoint = () => {
    if (isNaN(+params.id)) {
      return `/timedoctor/tasks/${params.id}/`;
    } else {
      return `/time-tracker/tasks/${params.id}/`;
    }
  };

  return useQueryApi<UseTaskByIdParams, R>(
    identifiers,
    getEndpoint(),
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
