import { AxiosRequestConfig } from 'axios';

import { useMutationApi } from './global/useApi';

import { DateInUTC } from '../types/types';

export type MutateNotificationDeviceDataParams = {
  registration_id: string;
  type: 'android' | 'ios' | 'web';
};

type MutateNotificationDeviceDataResponse = {
  id: number;
  name?: string;
  registration_id: string;
  device_id?: string;
  active: boolean;
  date_created: DateInUTC;
  type: string;
};

export function useAddNotificationDeviceData(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<
    MutateNotificationDeviceDataParams,
    MutateNotificationDeviceDataResponse
  >(`/notifications/devices/`, {}, axiosConfig);
}
