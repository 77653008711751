import React, { Fragment, useMemo } from 'react';
import moment from 'moment';
import { Box, Divider, SxProps } from '@mui/material';

import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';
import TimelineCard from './TimelineCard';
import { IterableObject } from '../../../types/types';

type TimelineContainerProps = {
  columnSx: SxProps;
  days: moment.Moment[];
  isLoading: boolean;
  worklogsUserData: UseFetchTimeDetailsResponse[];
};

const TimelineContainer: React.FC<TimelineContainerProps> = ({
  columnSx,
  days,
  isLoading,
  worklogsUserData,
}) => {
  const groupWorklogsByDay = useMemo(() => {
    let userGroup: IterableObject<UseFetchTimeDetailsResponse[]> = {};

    if (!worklogsUserData?.length) return {};

    worklogsUserData.forEach((result) => {
      if (typeof result.day === 'number') {
        if (!userGroup[result.day]) {
          userGroup[result.day] = [];
        }

        userGroup[result.day].push(result);
      }
    });

    return userGroup;
  }, [worklogsUserData]);

  return (
    <Box flex={1}>
      <Box flex={1} display='flex' justifyContent='center' alignItems='center'>
        {days.map((d, i) => {
          const data = groupWorklogsByDay[d.day()] ?? [];

          return (
            <Fragment key={i}>
              <Divider orientation='vertical' flexItem />
              <TimelineCard columnSx={columnSx} data={data} />
              {days.length - 1 === i && (
                <Divider orientation='vertical' flexItem />
              )}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default TimelineContainer;
