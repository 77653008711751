import { useState } from 'react';

type UseImageIsLoadedRet = {
  isLoaded: boolean;
  onLoad: () => void;
};
export function useImageIsLoaded(): UseImageIsLoadedRet {
  const [isLoaded, setIsLoaded] = useState(false);

  function onLoad() {
    setIsLoaded(true);
  }

  return {
    isLoaded,
    onLoad,
  };
}
