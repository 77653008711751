import React, { useEffect, useState } from 'react';
import { Box, List, useMediaQuery } from '@mui/material';
import { Navigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { M3TextField } from '../components/M3/M3TextField';
import BasicForm, { useForm } from '../components/BasicForm';
import SearchPreviewUserResult, {
  SearchPreviewUserResultSkeleton,
} from '../components/SearchPreviewUserResult';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import LoadMorePlaceholder from '../components/LoadMorePlaceholder';
import PageWait from '../components/PageWait';
import InfiniteScrollListener from '../components/InfiniteScrollListener';
import DocumentTitle from '../components/DocumentTitle';
import { M3SelectGroup } from '../components/M3/M3Select';
import JobSeekerPreviewAsItemResult from '../components/UserJobseekers/JobSeekerPreviewAsItemResult';

import { useUserProvider } from '../providers/user/user';
import { useMembers, UseMembersProps } from '../hooks/member';
import { useInfinite } from '../hooks/global/useInfinite';
import { MemberModel } from '../types/member';
import { setToSessionStorage } from '../utils/sessionstorage';
import { useObtainImpersonateToken } from '../hooks/misc';
import { useHasViewPermission } from '../components/HasViewPermission';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useDebounce } from '../hooks/utils/misc';
import { useJobseekers, UseJobseekersProps } from '../hooks/career-center';
import { JobSeekerItemResponse } from '../types/jobseeker';

type Props = {};

type FormState = {
  search: string;
};
type SearchType = 'go2_member' | 'partner_member' | 'jobseeker';

const searchTypes: { id: SearchType; label: string }[] = [
  {
    id: 'go2_member',
    label: 'Go2 Members',
  },
  {
    id: 'partner_member',
    label: 'Partner Members',
  },
  {
    id: 'jobseeker',
    label: 'Jobseekers',
  },
];

const SettingsPreviewAs = (props: Props) => {
  const { current: currentWorkspace } = useWorkspaceProvider();
  const { setUniqueIdsToFetch } = useUserProvider();
  const { formState, handleChange, handleSubmit } = useForm<FormState>({
    search: '',
  });
  const hasViewPermission = useHasViewPermission({
    roles: ['superuser', 'preview'],
  });
  const [searchType, setSearchType] = useState<SearchType>('go2_member');
  const [jobseekerName, setJobseekerName] = useState('');

  const search = useDebounce(formState.search);
  const maxDesktopWidth = useMediaQuery(`(min-width: 1400px)`);
  const maxDesktopHeight = useMediaQuery(`(min-height: 1024px)`);
  const [first_name, last_name] = search.split(' ');
  const initialMembersCount = maxDesktopWidth || maxDesktopHeight ? 20 : 10;
  const members = useInfinite<MemberModel, UseMembersProps>({
    useQuery: useMembers,
    queryParams: {
      limit: initialMembersCount,
      first_name,
      last_name,
      existing: true,
      type: searchType === 'jobseeker' ? undefined : searchType,
    },
  });

  const jobseekers = useInfinite<JobSeekerItemResponse, UseJobseekersProps>({
    useQuery: useJobseekers,
    queryParams: {
      limit: 10,
      search,
      is_active_user: true,
    },
  });

  const infinite = searchType === 'jobseeker' ? jobseekers : members;

  const obtainImpersonateToken = useObtainImpersonateToken<{
    user_id: string;
  }>();

  const onSubmit = handleSubmit((data) => {
    // NOTE: Intended to be empty
  });

  const onImpersonateClick = (item: MemberModel | JobSeekerItemResponse) => {
    if (searchType === 'jobseeker') {
      item = item as JobSeekerItemResponse;
      if (item.auth_user) {
        setJobseekerName(item.name);
        obtainImpersonateToken.mutate({
          user_id: `${item.auth_user}`,
        });
      }
    } else {
      item = item as MemberModel;
      obtainImpersonateToken.mutate({
        user_id: `${item.user_id}`,
      });
    }
  };

  const renderTypeSwitcherDropdown = () => {
    return (
      <Box
        sx={{
          mr: 2,
          width: 240,
          minWidth: 240,
        }}
      >
        <M3SelectGroup
          label='Type'
          labelId='pto-type-select-label'
          options={searchTypes}
          selectProps={{
            autoFocus: false,
            fullWidth: true,
            name: 'type',
            value: searchType,
            placeholder: 'Select type...',
            onChange: (evt) => {
              setSearchType(evt.target.value as SearchType);
            },
          }}
          formControlSx={{
            width: '100%',
            '.MuiInputLabel-root': {
              transition: 'none',
            },
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: members.data.map((m) => m.user_id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members.data]);

  useEffect(() => {
    if (obtainImpersonateToken.isSuccess) {
      setToSessionStorage('impersonation', {
        name: jobseekerName,
        ...obtainImpersonateToken.data,
      });
      window.location.href = '/';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobseekerName, obtainImpersonateToken.isSuccess]);

  useEffect(() => {
    if (searchType === 'jobseeker') {
      jobseekers.reset({
        emptyList: true,
      });
    } else {
      members.reset({
        emptyList: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, search]);

  if (!hasViewPermission) {
    return <Navigate to={`/manifest/${currentWorkspace!.id}`} />;
  }

  return (
    <>
      <DocumentTitle title='Preview As' trailingTitle='Admin Settings' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppActionToolbar>
          <Box
            flex={1}
            display='flex'
            alignItems='center'
            sx={{ position: 'relative', ml: -1, mr: -1.5 }}
          >
            {renderTypeSwitcherDropdown()}
            <BasicForm
              style={{
                flex: 1,
                width: 0,
                position: 'relative',
              }}
              onSubmit={onSubmit}
            >
              <M3TextField
                fullWidth
                variant='filled'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                autoSave='off'
                placeholder={`Search for user's name...`}
                name='search'
                value={formState.search}
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  '.MuiFilledInput-root,.MuiOutlinedInput-notchedOutline': {
                    borderRadius: 20,
                  },
                  '.MuiInputBase-input': {
                    pl: 5.4,
                  },
                  '.MuiInputLabel-shrink': {
                    transform: 'translate(20px, -8px) scale(0.74)',
                  },
                }}
                onChange={handleChange}
              />
              <SearchOutlinedIcon
                sx={{
                  top: 13,
                  left: 15,
                  opacity: 0.5,
                  position: 'absolute',
                  pointerEvents: 'none',
                  color: `var(--md-sys-color-on-surface-light)`,
                }}
              />
            </BasicForm>
          </Box>
        </AppActionToolbar>
        <List sx={{ pt: 0, pb: 0 }}>
          {!infinite.isLoading && !infinite.data.length && (
            <PageWait
              title={`No users found`}
              message={
                <>
                  We could not find any user for{' '}
                  <strong style={{ fontWeight: 500 }}>
                    {formState.search}
                  </strong>
                  .
                </>
              }
            />
          )}
          {infinite.isLoading &&
            !infinite.data.length &&
            new Array(initialMembersCount)
              .fill(null)
              .map((_, i) => <SearchPreviewUserResultSkeleton key={i} />)}
          {infinite.data.map((item) => {
            if (searchType === 'jobseeker') {
              return (
                <JobSeekerPreviewAsItemResult
                  key={item.id}
                  item={item as JobSeekerItemResponse}
                  onImpersonateClick={() => onImpersonateClick(item)}
                />
              );
            }

            return (
              <SearchPreviewUserResult
                key={item.id}
                item={item as MemberModel}
                onImpersonateClick={() => onImpersonateClick(item)}
              />
            );
          })}
          <LoadMorePlaceholder
            hasReachEnd={infinite.hasReachEnd}
            isLoading={infinite.isLoadingMore}
          />
        </List>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => {
          infinite.loadNext();
        }}
      />
    </>
  );
};

export default SettingsPreviewAs;
