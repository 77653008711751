import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { M3Card } from '../M3/M3Card';
import BasicCard from '../BasicCard';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import CommentsList from '../CommentsList';
import { M3Button } from '../M3/M3Button';
import PermanentChangeRequestScheduleListItem from '../Schedule/PermanentChangeRequestScheduleListItem';
import TemporaryChangeRequestScheduleListItem from '../Schedule/TemporaryChangeRequestScheduleListItem';

import { useAppProvider } from '../../providers/app/app';
import { useIsCardFocused } from '../../hooks/utils/card';
import { useMetadataProvider } from '../../providers/metadata/metadata';
import { useCurrentProfile } from '../../hooks/profile';
import { DateInUTC } from '../../types/types';
import { BatchChangeScheduleItem } from '../../types/schedules';
import { useUserProvider } from '../../providers/user/user';
import { useBatchChangeRequestRejectCancel } from '../../hooks/go2-schedules';
import { RejectCancelDialog } from './InboxPTOItemDetail';
import { useTick } from '../../hooks/utils/tick';

type Props = {
  scheduleChangeRequestData: BatchChangeScheduleItem &
    Partial<{
      requested_date: DateInUTC | null;
    }>;
  onTakenActionSuccess?: () => void;
};

const InboxScheduleRequestItemDetail = ({
  scheduleChangeRequestData,
  onTakenActionSuccess,
}: Props) => {
  const { contentTypes } = useMetadataProvider();
  const isCardFocused = useIsCardFocused();
  const {
    palettes: { main: palette },
    isDarkMode,
    updateActionKeyCounter,
  } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();
  const { getUser, setUniqueIdsToFetch } = useUserProvider();

  const user = getUser('user_id', scheduleChangeRequestData.user);
  const userReportsTo = getUser('user_id', user?.reports_to_id);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  useTick();

  /**
   * NOTE: Get the first request data since all request will be having the same
   * schedule time
   */
  const request = scheduleChangeRequestData.requests[0];

  const approveChangeRequest = useBatchChangeRequestRejectCancel({
    batchId: scheduleChangeRequestData.batch_id,
    action: 'approve',
  });
  const rejectChangeRequest = useBatchChangeRequestRejectCancel({
    batchId: scheduleChangeRequestData.batch_id,
    action: 'reject',
  });
  const cancelChangeRequest = useBatchChangeRequestRejectCancel({
    batchId: scheduleChangeRequestData.batch_id,
    action: 'cancel',
  });

  const handleOnCancel = ({ action, note }: any) => {
    cancelChangeRequest.mutate({
      notes: note,
    });
  };

  const handleOnReject = ({ action, note }: any) => {
    rejectChangeRequest.mutate({
      notes: note,
    });
  };

  const renderTopPanelHeader = () => {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          height: 53,
        }}
      >
        <Typography flex={1} fontWeight={300} fontSize={24} sx={{ pl: 1 }}>
          {scheduleChangeRequestData.delete ? 'Delete ' : ''}
          {request.is_temporary ? 'Temporary' : 'Permanent'} Schedule Change
          Request
        </Typography>
        {renderActionButtons()}
      </Box>
    );
  };

  const renderActionButtons = () => {
    const status = scheduleChangeRequestData.status;
    let isTakingAction =
      approveChangeRequest.isLoading ||
      rejectChangeRequest.isLoading ||
      cancelChangeRequest.isLoading;

    const renderApproveDeny = () => {
      return (
        <Stack direction='row' gap={2} alignItems='center'>
          <M3Button
            color='primary'
            variant='contained'
            disableRipple={isTakingAction}
            sx={{
              color: '#fff',
              width: 100,
              pointerEvents: isTakingAction ? 'none' : undefined,
            }}
            onClick={() => approveChangeRequest.mutate({})}
          >
            {approveChangeRequest.isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              />
            ) : (
              'Approve'
            )}
          </M3Button>
          <M3Button
            color='primary'
            variant='outlined'
            disableRipple={isTakingAction}
            sx={{
              width: 100,
              pointerEvents: isTakingAction ? 'none' : undefined,
            }}
            onClick={() => setIsRejectDialogOpen(true)}
          >
            {rejectChangeRequest.isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              />
            ) : (
              'Deny'
            )}
          </M3Button>
        </Stack>
      );
    };

    const renderCancelButton = () => {
      return (
        <Stack direction='row' gap={2} alignItems='center'>
          <M3Button
            color='primary'
            variant='outlined'
            disableRipple={isTakingAction}
            sx={{
              width: 100,
              pointerEvents: isTakingAction ? 'none' : undefined,
            }}
            onClick={() => setIsCancelDialogOpen(true)}
          >
            {cancelChangeRequest.isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              />
            ) : (
              'Cancel'
            )}
          </M3Button>
        </Stack>
      );
    };

    if (status !== 'pending') return null;

    /**
     * When the schedule change was requested by a "teammate",
     * the manager who receives the notification will be notified and can
     * take the action to approve/reject
     */
    if (
      scheduleChangeRequestData.requested_by_role === 'teammate' &&
      userReportsTo &&
      userReportsTo.id === currentProfile!.id
    ) {
      return renderApproveDeny();
    }

    /**
     * When the schedule change was requested by a "manager",
     * the user who receives the notification will be notified and can
     * take the action to approve/reject
     */
    if (
      scheduleChangeRequestData.requested_by_role === 'manager' &&
      scheduleChangeRequestData.user === currentProfile!.id
    ) {
      return renderApproveDeny();
    }

    /**
     * If the user who requested the schedule change request,
     * and doesn't have any manager to reports to. ie: "Scott",
     * then the one who submits the report can do the approval
     */
    if (
      user &&
      !user.reports_to_id &&
      scheduleChangeRequestData.submitted_by === currentProfile!.id
    ) {
      return renderApproveDeny();
    }

    /**
     * Render the cancel request for the user who submitted
     */
    if (scheduleChangeRequestData.submitted_by === currentProfile!.id) {
      return renderCancelButton();
    }

    return null;
  };

  const renderPermanentScheduleBlock = () => {
    return (
      <Box>
        <PermanentChangeRequestScheduleListItem
          withCancelButton={false}
          isRequestsDropdownOpen
          isFirst
          isLast
          withToggleRequestDropdown={false}
          item={scheduleChangeRequestData}
        />
      </Box>
    );
  };

  const renderTemporaryScheduleBlock = () => {
    return (
      <Box>
        <TemporaryChangeRequestScheduleListItem
          withCancelButton={false}
          isRequestsDropdownOpen
          isFirst
          isLast
          withToggleRequestDropdown={false}
          item={scheduleChangeRequestData}
        />
      </Box>
    );
  };

  const renderNoticeNoManager = () => {
    return null;

    /**
     * Show only the notice to user that doesn't have the
     */
    /*
    if (
      user &&
      !user.reports_to_id &&
      scheduleChangeRequestData.submitted_by === currentProfile!.id
    ) {
      return (
        <Typography
          fontSize={13}
          fontWeight={500}
          component='div'
          sx={{
            px: 3,
            py: 0.5,
            background: isDarkMode
              ? 'var(--md-ref-palette-error40)'
              : 'var(--md-ref-palette-error80)',
          }}
        >
          Approval rights for schedule changes are assigned to the user
          submitting the request, as no manager is designated.
        </Typography>
      );
    }

    return null;
    */
  };

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: [
        scheduleChangeRequestData.user,
        scheduleChangeRequestData.submitted_by,
        scheduleChangeRequestData.approved_by,
        user?.reports_to_id,
      ],
    });
    // eslint-disable-next-line
  }, [user, scheduleChangeRequestData]);

  useEffect(() => {
    if (
      approveChangeRequest.isSuccess ||
      rejectChangeRequest.isSuccess ||
      cancelChangeRequest.isSuccess
    ) {
      onTakenActionSuccess?.();
      setIsCancelDialogOpen(false);
      setIsRejectDialogOpen(false);
      updateActionKeyCounter('schedule_change_request_action_taken');
    }
    // eslint-disable-next-line
  }, [
    approveChangeRequest.isSuccess,
    rejectChangeRequest.isSuccess,
    cancelChangeRequest.isSuccess,
    onTakenActionSuccess,
    setIsCancelDialogOpen,
  ]);

  return (
    <>
      <M3Card
        focused={isCardFocused.focused}
        sx={{
          border: isDarkMode
            ? '1px solid var(--md-sys-color-surface-variant-dark)'
            : '1px solid var(--md-sys-color-surface-variant-light)',
        }}
      >
        <Box>
          <BasicCard
            withHeaderDivider={false}
            header={renderTopPanelHeader()}
            headerSx={{
              p: 1,
              pl: 2,
              pr: 2,
              borderRadius: '10px 10px 0 0',
              background: isDarkMode
                ? palette['md.ref.palette.neutral.variant10']
                : palette['md.ref.palette.neutral.variant90'],
            }}
          >
            {renderNoticeNoManager()}
            <M3SurfaceContainer
              elevation={1}
              borderRadius={0}
              sx={{
                p: 4,
                pt: 2,
              }}
            >
              {/* {renderUserInfo()} */}
              {request.is_temporary
                ? renderTemporaryScheduleBlock()
                : renderPermanentScheduleBlock()}
              <br />
              {/* <Divider /> */}
              <br />
              <br />
              {/* {renderPermanentScheduleBlock()} */}
              <Stack
                direction='row'
                gap={4}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                {/* {renderBigCalendar()} */}
                <Box flex={1}>
                  {/* <TimeOffWhosOut
                    isWhoElse
                    start={pto.start}
                    end={pto.end || pto.start}
                    userId={user.id}
                    user={user}
                    maxAllowedRowDate={3}
                  /> */}
                </Box>
              </Stack>
            </M3SurfaceContainer>
          </BasicCard>
        </Box>
        <Box sx={{ pl: 1.4 }}>
          <CommentsList
            currentProfile={currentProfile}
            contentType={
              (contentTypes as unknown as { schedulechangerequest: number })
                .schedulechangerequest
            }
            objectId={request.id}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
          />
        </Box>
      </M3Card>
      <br />
      <br />
      <br />
      <RejectCancelDialog
        isOpen={isCancelDialogOpen}
        user={user!}
        // NOTE: This is intended to use "reject" here
        action='reject'
        isTakingAction={cancelChangeRequest.isLoading}
        onTakeAction={handleOnCancel}
        setIsOpen={(isOpen: boolean) => setIsCancelDialogOpen(isOpen)}
        dialogProps={{
          dialog: {
            title: `Are you sure you want to cancel the schedule change request?`,
          },
          actions: {
            confirm: {
              label: 'Confirm',
              width: 50,
            },
          },
        }}
      />
      <RejectCancelDialog
        isOpen={isRejectDialogOpen}
        user={user!}
        action='reject'
        isTakingAction={rejectChangeRequest.isLoading}
        onTakeAction={handleOnReject}
        setIsOpen={(isOpen: boolean) => setIsRejectDialogOpen(isOpen)}
        dialogProps={{
          dialog: {
            title: `Are you sure you want to reject the schedule change request?`,
          },
          actions: {
            confirm: {
              label: 'Confirm',
              width: 50,
            },
          },
        }}
      />
    </>
  );
};

export default InboxScheduleRequestItemDetail;
