import React, { PropsWithChildren } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { IntercomSection } from '../../types/intercom';
import { ContentItemBase } from './Content';

type ContentSectionItemProps = PropsWithChildren &
  ContentItemBase<IntercomSection> & {};

export const ContentSectionItem = ({
  item,
  active = false,
  draggable = true,
  clickable = true,
  onClick,
  dragHandleProps,
  snapshot,
  sx,
}: ContentSectionItemProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        marginBottom: '8px',
        ...sx,
      }}
    >
      <M3Card
        noHover={!clickable}
        className='m3-card'
        focused={snapshot?.isDragging}
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        <M3SurfaceContainer elevation={active ? 4 : 1}>
          <Paper
            className='collection__paper'
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              cursor: clickable ? 'pointer' : undefined,
            }}
            style={{
              marginTop: 0,
              paddingTop: 11,
              paddingBottom: 11,
              background: 'transparent',
            }}
            onClick={onClick}
          >
            {draggable && (
              <div {...dragHandleProps}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    top: -1,
                    left: -1,
                    bottom: -1,
                    width: 32,
                    position: 'absolute',
                  }}
                >
                  <DragIndicatorOutlinedIcon sx={{ ml: 0.5, mr: 0.5 }} />
                </Box>
              </div>
            )}
            <Stack
              gap={1}
              flex={1}
              direction='row'
              display='flex'
              alignItems='center'
              style={{
                paddingLeft: draggable ? 30 : 0,
              }}
            >
              <Box flex={2} pr={2} minWidth={200}>
                <Typography
                  fontSize={18}
                  style={{
                    color: isDarkMode
                      ? 'var(--md-ref-palette-primary80)'
                      : 'var(--md-ref-palette-primary40)',
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  display='flex'
                  alignItems='flex-start'
                  component='div'
                  fontSize={13}
                  sx={{
                    opacity: 0.5,
                  }}
                >
                  <GroupsOutlinedIcon
                    sx={{
                      mr: 0.5,
                      top: -1,
                      opacity: 0.7,
                      fontSize: 20,
                      position: 'relative',
                    }}
                  />
                  &nbsp;Leads, Users and Visitors + 1 more
                </Typography>
              </Box>
              <Typography
                flex={1}
                fontSize={12}
                minWidth={200}
                component='div'
                textAlign='right'
                style={{
                  color: 'var(--md-ref-palette-neutral60)',
                }}
              >
                Articles:{' '}
                <strong
                  style={{
                    fontWeight: 500,
                    color: isDarkMode
                      ? 'var(--md-ref-palette-neutral80)'
                      : 'var(--md-ref-palette-neutral50)',
                  }}
                >
                  {item.articles.length}
                </strong>
              </Typography>
            </Stack>
          </Paper>
        </M3SurfaceContainer>
      </M3Card>
    </Box>
  );
};

export default ContentSectionItem;
