import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import TimeOffRequestItem from './TimeOffRequestItem';
import PageWait from '../PageWait';

import { useInfinite } from '../../hooks/global/useInfinite';
import { useHrPTO, UseHrPTOProps } from '../../hooks/hr';
import { HrPTOItemResponse } from '../../types/hr';
import {
  HrPTOItemResponseExpanded,
  hrPTOItemTransformData,
} from '../../utils/pto';
import { useAppProvider } from '../../providers/app/app';
import { parseDateWithoutTimezone } from '../../utils/date';

type TimeOffRequestListItemsProps = {
  editable?: boolean;
  staffId?: string;
  title?: string;
  pageWaitOptions?: {
    title: string;
    message?: string;
  };
};

const TimeOffRequestListItems = ({
  editable,
  staffId,
  title,
  pageWaitOptions = {
    title: 'No Upcoming Time Off',
    message: 'Do you need to get away?',
  },
}: TimeOffRequestListItemsProps) => {
  const { isDarkMode, getActionKeyCounter } = useAppProvider();

  const infinite = useInfinite<HrPTOItemResponse, UseHrPTOProps>({
    keyFieldName: 'refId',
    useQuery: useHrPTO,
    queryParams: {
      key: 'upcoming_request',
      staff: staffId!,
      status: 'approved,requested',
      limit: 200,
      // PTO, Flexible Holiday, UTO
      type_id: '92,93,96',
      start: moment().format('YYYY-MM-DD'),
      ordering: 'start',
    },
    transformData: hrPTOItemTransformData,
    skipFetchOnInit: true,
  });

  /**
   * Filter out the PTO that already consumed
   */
  const now = moment();
  const items = infinite.data.filter(
    (pto) =>
      pto.status === 'requested' ||
      now.isSameOrBefore(parseDateWithoutTimezone(pto.start).date),
  );
  /**
   * TODO: What to do for the current PTO the user is undertaking
   */
  /*
  const consumable = infinite.data.find((pto) => {
    return (
      moment(parseDateWithoutTimezone(pto.start).date).isBefore(now) &&
      moment(parseDateWithoutTimezone(pto.end).date).isSameOrAfter(now)
    );
  });
  */

  /**
   * Refetch the unread count when there's new unread count changes
   * When staffId is available that's you fetch the first list
   */
  useEffect(() => {
    if (staffId) {
      infinite.reset({ emptyList: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId, getActionKeyCounter('pto_request_submission')]);

  return (
    <Box sx={{ minHeight: 281 }}>
      <br />
      <br />
      <Stack direction='row' gap={1} pb={0.5}>
        <Typography
          display='flex'
          component='div'
          alignItems='center'
          flexWrap='nowrap'
          fontWeight={500}
        >
          <CalendarMonthOutlinedIcon
            sx={{
              fontSize: 28,
              opacity: 0.5,
            }}
          />
          &nbsp;{title}
        </Typography>
        <Box flex={1} pt={1.8}>
          <Divider orientation='horizontal' flexItem />
        </Box>
      </Stack>
      {items.map((pto, index, arr) => {
        return (
          <TimeOffRequestItem
            key={(pto as HrPTOItemResponseExpanded).refId}
            item={pto}
            editable={editable}
            first={index === 0}
            last={arr.length - 1 === index}
            staffId={staffId}
          />
        );
      })}
      {!items.length && infinite.isLoading && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          p={5}
          pt={9}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      {!items.length && !infinite.isLoading && (
        <>
          <PageWait
            icon={
              <CalendarMonthOutlinedIcon
                sx={{
                  mb: 2,
                  fontSize: 80,
                  opacity: isDarkMode ? 0.1 : 0.05,
                }}
              />
            }
            title={pageWaitOptions?.title}
            message={pageWaitOptions?.message}
            sx={{
              padding: '40px 0 0',
            }}
          />
        </>
      )}
    </Box>
  );
};

export default TimeOffRequestListItems;
