import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactCrop, {
  Crop,
  PixelCrop,
  PercentCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { M3Button } from './M3/M3Button';

import ModalCardView, { ModalCardViewCloseProps } from './ModalCardView';

type CropperStateModalViewProps = ModalCardViewCloseProps & {
  file?: File | null;
  cropOptions?: CropOptions;
  onCrop?: (result: CropResult) => void;
};

type CropperState = ModalCardViewCloseProps & {
  uri?: string;
  crop?: Crop;
  percentageCrop?: PercentCrop;
  image?: HTMLImageElement | null;
};

export type CropOptions = {
  aspect?: number;
};

export type CropResult = {
  file: File;
};

const Cropper = forwardRef(
  ({ file, close, cropOptions, onCrop }: CropperStateModalViewProps, ref) => {
    const [state, setState] = useState<CropperState>({
      uri: '',
      crop: undefined,
      percentageCrop: undefined,
      image: null,
    });
    const [isLoadingImage, setIsLoadingImage] = useState(true);

    const renderTopPanel = () => {
      return (
        <Stack
          sx={{ flex: 1 }}
          alignItems='flex-start'
          justifyContent='flex-start'
        >
          <Typography component='h5' fontSize={20} fontWeight={500}>
            Select to crop image
          </Typography>
        </Stack>
      );
    };

    const renderBottomPanel = () => {
      return (
        <Stack
          direction='row'
          sx={{ p: 2, flex: 1 }}
          alignItems='center'
          justifyContent='center'
        >
          <M3Button
            color='primary'
            variant='contained'
            sx={{
              width: 160,
            }}
            onClick={onCropSubmit}
            disabled={!state.image}
          >
            Crop
          </M3Button>
        </Stack>
      );
    };

    const onCropSubmit = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = state.image!;
      const imgWidth = img!.naturalWidth;
      const imgHeight = img!.naturalHeight;

      const cropX = imgWidth * (state.percentageCrop!.x / 100);
      const cropY = imgHeight * (state.percentageCrop!.y / 100);
      const cropWidth = imgWidth * (state.percentageCrop!.width / 100);
      const cropHeight = imgHeight * (state.percentageCrop!.height / 100);

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx?.drawImage(
        state.image!,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
      );

      const base64 = canvas.toDataURL('image/jpeg');
      const res = await fetch(base64);
      const buff = await res.arrayBuffer();
      const croppedFile = new File([buff], file!.name, { type: file!.type });

      onCrop?.({
        file: croppedFile,
      });
    };

    const onChange = (crop: PixelCrop, percentageCrop: PercentCrop) => {
      setState((state) => ({ ...state, crop, percentageCrop }));
    };

    useEffect(() => {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const img = document.createElement('img');
          img.onload = () => {
            setState((state) => ({
              ...state,
              uri: reader.result as string,
              image: img,
              crop: centerCrop(
                makeAspectCrop(
                  {
                    unit: '%',
                    width: 90,
                  },
                  cropOptions?.aspect ?? 16 / 9,
                  img.naturalWidth,
                  img.naturalHeight,
                ),
                img.naturalWidth,
                img.naturalHeight,
              ),
            }));
          };
          img.src = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }, [file, cropOptions]);

    useEffect(() => {
      setIsLoadingImage(!state.image?.complete);
    }, [state.image?.complete]);

    return (
      <ModalCardView
        close={close}
        header={renderTopPanel()}
        headerSx={{
          pt: 2,
          pb: 2,
        }}
        footer={renderBottomPanel()}
        bodySx={{
          pl: 0,
          pr: 0,
          pt: 0,
          pb: 0,
        }}
        bodyStyle={{
          minHeight: 200,
          background: '#222222',
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            minHeight: 200,
            position: 'relative',
          }}
        >
          {isLoadingImage && (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
                pointerEvents: 'none',
                position: 'absolute',
              }}
            >
              <CircularProgress size={30} sx={{ color: '#fff' }} />
            </Box>
          )}
          <ReactCrop
            keepSelection
            crop={state.crop}
            aspect={cropOptions?.aspect}
            onChange={onChange}
            onComplete={onChange}
          >
            {!!state.image && (
              <Box
                component='img'
                src={state.image.src}
                style={{
                  pointerEvents: 'none',
                  maxHeight: window.innerHeight * 0.8,
                }}
              />
            )}
          </ReactCrop>
        </Box>
      </ModalCardView>
    );
  },
);

export default Cropper;
