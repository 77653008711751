import { GridSize } from '@mui/material';
import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';

type ItemProps = {
  xs?: GridSize;
};

type HeaderMapItem = {
  label: string;
  itemProps: ItemProps;
};

export type KeysMapType = {
  [K in keyof UseFetchTimeDetailsResponse]?: HeaderMapItem;
} & {
  action: HeaderMapItem;
};

export const keysMap: KeysMapType = {
  start_time_local: {
    label: 'Time Started',
    itemProps: { xs: 3 },
  },
  end_time_local: {
    label: 'Time Ended',
    itemProps: { xs: 3 },
  },
  duration: {
    label: 'Total Time',
    itemProps: { xs: 3 },
  },
  task_name: {
    label: 'Focus Area',
    itemProps: { xs: 4 },
  },
  notes: {
    label: 'Reason',
    itemProps: { xs: 5 },
  },
  action: {
    label: 'Action',
    itemProps: { xs: 1 },
  },
};
