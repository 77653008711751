import { useState, useEffect, useCallback } from 'react';
import {
  CreateProjectKeyResponse,
  LiveClient,
  LiveTranscriptionEvents,
  createClient,
} from '@deepgram/sdk';
import { useQueue } from '@uidotdev/usehooks';
import { AxiosResponse } from 'axios';

import * as portal from '../../services/portal';
import { getConfigWithAuthorization } from '../../services/base';
import { useAuthProvider } from '../../providers/auth/auth';

type Caption = {
  start: number;
  duration: number;
  is_final: boolean;
  phrase: string;
};

export function useSpeechToText() {
  const { getTokenSilently } = useAuthProvider();
  const { add, remove, first, size, queue } = useQueue<any>([]);
  const [apiKey, setApiKey] = useState<CreateProjectKeyResponse | null>();
  const [connection, setConnection] = useState<LiveClient | null>();
  const [isListening, setListening] = useState(false);
  const [isLoadingKey, setLoadingKey] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const [micOpen, setMicOpen] = useState(false);
  const [microphone, setMicrophone] = useState<MediaRecorder | null>();
  const [userMedia, setUserMedia] = useState<MediaStream | null>();
  const [caption, setCaption] = useState<Caption | null>();
  const [transcript, setTranscript] = useState<Caption[]>([]);

  const toggleMicrophone = useCallback(async () => {
    if (microphone && userMedia) {
      microphone.stop();
      userMedia.getTracks().forEach((track) => track.stop());

      setMicrophone(null);
      setUserMedia(null);
    } else {
      const userMedia = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const microphone = new MediaRecorder(userMedia);
      microphone.start(500);

      microphone.onstart = () => {
        setMicOpen(true);
      };

      microphone.onstop = () => {
        setMicOpen(false);
      };

      microphone.ondataavailable = (e) => {
        add(e.data);
      };

      setUserMedia(userMedia);
      setMicrophone(microphone);
    }
  }, [add, microphone, userMedia]);

  useEffect(() => {
    if (!apiKey) {
      (async () => {
        try {
          const result: AxiosResponse<CreateProjectKeyResponse> =
            await portal.request({
              url: '/deepgram',
              ...getConfigWithAuthorization(await getTokenSilently()),
            });
          setApiKey(result as unknown as CreateProjectKeyResponse);
          setLoadingKey(false);
        } catch (e) {}
      })();
    }
  }, [apiKey, setApiKey, setLoadingKey, getTokenSilently]);

  useEffect(() => {
    if (apiKey && 'key' in apiKey) {
      const deepgram = createClient(apiKey?.key ?? '');
      const connection = deepgram.listen.live({
        model: 'nova-2',
        language: 'en-US',
        interim_results: true,
        smart_format: true,
        vad_events: true,
      });

      connection.on(LiveTranscriptionEvents.Open, () => {
        setListening(true);
      });

      connection.on(LiveTranscriptionEvents.Close, () => {
        setListening(false);
        setApiKey(null);
        setConnection(null);
      });

      connection.on(LiveTranscriptionEvents.Transcript, (data) => {
        const { start, duration, is_final } = data;

        const words = data.channel.alternatives[0].words;
        const phrase = words
          .map((word: any) => word.punctuated_word ?? word.word)
          .join(' ');

        setCaption({ start, duration, is_final, phrase });
      });

      setConnection(connection);
      setLoading(false);
    }
  }, [apiKey]);

  useEffect(() => {
    if (caption) {
      setTranscript((prev) => [
        ...prev.filter((item) => item.start !== caption.start),
        caption,
      ]);
    }
  }, [caption]);

  useEffect(() => {
    const processQueue = async () => {
      if (size > 0 && !isProcessing) {
        setProcessing(true);

        if (isListening) {
          const blob = first;
          connection?.send(blob);
          remove();
        }

        const waiting = setTimeout(() => {
          clearTimeout(waiting);
          setProcessing(false);
        }, 250);
      }
    };

    processQueue();
  }, [connection, queue, remove, first, size, isProcessing, isListening]);

  return {
    transcript,
    loading: isLoading || isLoadingKey,
    transcribing: micOpen,
    start: toggleMicrophone,
    stop: toggleMicrophone,
  };
}
