import React from 'react';
import {
  Box,
  Avatar,
  ListItemAvatar,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

import { M3IconButton } from './M3/M3Button';
import { M3ListItem, M3ListItemText } from './M3/M3ListItem';

import { MemberModel } from '../types/member';
import { useUserProvider } from '../providers/user/user';
import { useCurrentProfile } from '../hooks/profile';
import { UserMetadata } from '../types/profile';
import { useAppProvider } from '../providers/app/app';

type Props = {
  isPromoteDemote?: boolean;
  item: MemberModel;
  onDemoteClick?: (email: string) => void;
  onImpersonateClick?: () => void;
};

const SearchPreviewUserResult: React.FC<Props> = ({
  isPromoteDemote,
  item,
  onDemoteClick,
  onImpersonateClick,
}) => {
  const { getUser } = useUserProvider();
  const { data: currentProfile } = useCurrentProfile();
  const { isDarkMode } = useAppProvider();
  const user =
    getUser('user_id', item.user_id) ||
    ({
      id: item.user_id,
      employee_id: item.type === 'go2_member' ? item.member_id : null,
      member_id: item.type === 'partner_member' ? item.member_id : null,
      first_name: item.first_name,
      last_name: item.last_name,
    } as Partial<UserMetadata>);
  const isCurrentUser = currentProfile!.id === user.id;

  return (
    <M3ListItem
      sx={{
        borderRadius: 3,
        '&:hover': {
          background: isDarkMode
            ? 'var(--md-ref-palette-neutral20)'
            : 'var(--md-ref-palette-neutral95)',
        },
      }}
    >
      <ListItemAvatar>
        <Avatar src={user?.photo_url || undefined}></Avatar>
      </ListItemAvatar>
      <M3ListItemText
        primary={
          <Typography display='flex' alignItems='center' component='div'>
            <Typography fontWeight={500}>
              {item.first_name} {item.last_name}
            </Typography>
            &nbsp;
            <span
              style={{
                opacity: 0.5,
                fontSize: 12,
              }}
            >
              {item.department_name
                ? `- ${item.department_name}`.replace('- -', '- ')
                : ''}
            </span>
          </Typography>
        }
        secondary={item.division_name || <>&nbsp;</>}
        sx={{
          '.MuiListItemText-secondary': {
            opacity: 0.9,
          },
        }}
      />
      {!isCurrentUser && !isPromoteDemote && (
        <M3IconButton onClick={onImpersonateClick} sx={{ p: 0 }}>
          <Tooltip
            title={`Preview as ${user.first_name || ''} ${
              user.last_name || ''
            }`.trim()}
            placement='left'
            disableInteractive
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{ p: 1, height: '100%', width: '100%' }}
            >
              <PeopleOutlineOutlinedIcon />
            </Box>
          </Tooltip>
        </M3IconButton>
      )}
      {isPromoteDemote && (
        <M3IconButton onClick={() => onDemoteClick?.(item.email)} sx={{ p: 0 }}>
          <Tooltip
            title={`Demote user ${user.first_name || ''} ${
              user.last_name || ''
            } as Jobseeker`.trim()}
            placement='left'
            disableInteractive
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{ p: 1, height: '100%', width: '100%' }}
            >
              <PersonRemoveOutlinedIcon />
            </Box>
          </Tooltip>
        </M3IconButton>
      )}
    </M3ListItem>
  );
};

export function SearchPreviewUserResultSkeleton() {
  return (
    <M3ListItem
      sx={{
        borderRadius: 3,
      }}
    >
      <ListItemAvatar>
        <Skeleton variant='circular' sx={{ width: 40, height: 40 }} />
      </ListItemAvatar>
      <M3ListItemText
        primary={
          <Skeleton variant='text' sx={{ ml: -2, mr: -2, maxWidth: 100 }} />
        }
        secondary={<Skeleton variant='text' sx={{ maxWidth: 200 }} />}
      />
    </M3ListItem>
  );
}

export default SearchPreviewUserResult;
