import React from 'react';

import MainContainer from '../components/MainContainer';
import WindowScrollTop from '../components/WindowScrollTop';
import DocumentTitle from '../components/DocumentTitle';
import FocusItemDetail from '../components/TeamSettings/Focus/FocusItemDetail';
import AIAssistSideSheet, {
  useAIAssistSideSheet,
} from '../components/SideSheet/AIAssistSideSheet';

import { useTimeTrackerTaskById } from '../hooks/time-tracker';
import { useParams } from 'react-router-dom';

type SettingsTeamFocusDetailProps = {
  editable?: boolean;
  routeKey: 'builder' | 'team';
};

const SettingsTeamFocusDetail = ({
  editable = true,
  routeKey,
}: SettingsTeamFocusDetailProps) => {
  const { focusId } = useParams();

  const timeTrackerTaskFocus = useTimeTrackerTaskById({ id: focusId! });
  const taskFocusData = timeTrackerTaskFocus.data;

  const aiAssist = useAIAssistSideSheet();

  return (
    <>
      <DocumentTitle
        title={taskFocusData?.name ?? focusId!}
        trailingTitle='Focus | Team Settings'
      />
      <AIAssistSideSheet
        inPage
        withToolbar
        withSubToolbar
        title={aiAssist.title}
        headerSx={{
          height: 'initial',
        }}
        aiAssist={aiAssist}
        onClose={aiAssist.close}
      >
        <MainContainer flex sx={{ maxWidth: null, pb: 0 }}>
          <FocusItemDetail
            editable={editable}
            withDelete={editable}
            aiAssist={aiAssist}
            taskFocusId={focusId}
            routeKey={routeKey}
          />
        </MainContainer>
      </AIAssistSideSheet>
      <WindowScrollTop />
    </>
  );
};

export default SettingsTeamFocusDetail;
