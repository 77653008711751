import { MenuOption, SOD_EOD } from '../../types/app';

export type SodEodButton = MenuOption & { id: SOD_EOD; path: string };
export type SodEodButtons = SodEodButton[];
export const sodEodButtons: SodEodButtons = [
  {
    id: 'SOD',
    value: 'Start of Day',
    path: '/report/sod',
  },
  {
    id: 'EOD',
    value: 'End of Day',
    path: '/report/eod',
  },
];

export const standaloneRoutes: string[] = [
  /**
   * List of routes to be whitelisted
   */
  '/slack/connect',
  '/help',
  '/inbox',
];

export const outsideRoutes: string[] = ['/signup', '/signup/numbers'];
