import React from 'react';

import QuillEditor, { QuillEditorProps } from './QuillEditor';

export type RichEditorProps = QuillEditorProps & {};
/**
 * We are wrapping the quill editor, because we use a different back then.
 * And the whole app depends on this wrapper component
 */
const RichEditor = (props: RichEditorProps) => {
  return <QuillEditor {...props} />;
};

export default RichEditor;
