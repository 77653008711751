import React, { useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import AIAIAIUserChat from '../components/AIAIAI/AIAIAIUserChat';
import WindowScrollTop from '../components/WindowScrollTop';
import { useHasViewPermission } from '../components/HasViewPermission';

import { parseURL } from '../utils/url';
import { useGo2BotsMessageById } from '../hooks/go2bots/messages';
import { ReactRenderElement } from '../types/types';
import { useAppProvider } from '../providers/app/app';
import { useCurrentProfile } from '../hooks/profile';

type Props = {
  objectId?: string;
  isFromAllPage?: boolean;
  nextPrevNavigation?: ReactRenderElement;
};

const InboxChatDetail = ({ objectId, nextPrevNavigation }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toolbarHeight, subToolbarHeight } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();

  /**
   * This is the same permission on the 'Send a New Chat' page
   */
  const isGo2BotsAdmin = useHasViewPermission({
    roles: ['superuser', 'developer', 'go2bots_admin'],
  });

  const topHeaderRef = useRef<HTMLElement | null>(null);

  const { id } = useParams();
  const go2botMessageId = +(objectId || 0 || id || 0) ?? 0;

  const { data: go2botsMessageData } = useGo2BotsMessageById(
    {
      id: go2botMessageId,
    },
    {
      enabled: !!go2botMessageId,
    },
  );

  const isRecipient = go2botsMessageData?.user === currentProfile?.id;

  const renderContent = () => {
    return (
      <>
        <Box
          display='flex'
          alignItems='center'
          flex={1}
          minHeight={35}
          ref={topHeaderRef}
        >
          {go2botsMessageData && (
            <Typography pl={2} pb={1} component='div' fontSize={18} flex={1}>
              <span style={{ opacity: 0.5, fontSize: 14, fontWeight: 400 }}>
                Title:
              </span>{' '}
              {go2botsMessageData.title}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            borderRadius: 3,
            overflow: 'hidden',
            border: `1px solid ${
              isDarkMode
                ? 'var(--md-ref-palette-neutral-variant20)'
                : 'var(--md-ref-palette-neutral-variant90)'
            }`,
          }}
        >
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='stretch'
            sx={{
              overflow: 'hidden',
              height: `calc(100vh - ${toolbarHeight}px - ${
                subToolbarHeight * 2
              }px - ${topHeaderRef.current?.clientHeight ?? 0}px)`,
            }}
          >
            {!!go2botsMessageData && (
              <AIAIAIUserChat
                isFlipped
                isAdmin={isRecipient ? false : isGo2BotsAdmin}
                withSentBy={false}
                go2BotsMessage={go2botsMessageData}
                redirectToChatWhenAdminReply={
                  isRecipient ? false : isGo2BotsAdmin
                }
              />
            )}
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      <WindowScrollTop />
      <MainContainer
        sx={{
          maxWidth: null,
        }}
      >
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              const { t } = parseURL(location.search);
              const p = location.pathname
                .split('/')
                .filter((s) => !!s)
                .slice(0, 2)
                .join('/');
              navigate(`/${p}/${t ?? 0}`);
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box flex={1} />
          {nextPrevNavigation}
        </AppActionToolbar>
        {renderContent()}
      </MainContainer>
    </>
  );

  /*
  return (
    <>
      <DocumentTitle title='Chat' trailingTitle='Inbox' />
      <MainContainer sx={{ maxWidth: null, pt: 0, pb: 0, pl: 0, pr: 0 }}>
        <AppActionToolbar>
          <Box display='flex' alignItems='center' flex={1}>
            <Typography
              component='div'
              fontSize={22}
              width={usersListWidth - 8 * 3}
              minWidth={usersListWidth - 8 * 3}
            >
              Chat
            </Typography>
            {selectedGo2BotsMessageItem && (
              <Typography pl={2} component='div' fontSize={18} flex={1}>
                <span style={{ opacity: 0.5, fontSize: 14, fontWeight: 400 }}>
                  Title:
                </span>{' '}
                {selectedGo2BotsMessageItem.title}
              </Typography>
            )}
          </Box>
          <M3Button
            variant='contained'
            color='primary'
            onClick={() => setIsNewChatOpen(true)}
          >
            <ChatBubbleOutlineOutlinedIcon style={{ fontSize: 20 }} />
            New Chat
          </M3Button>
        </AppActionToolbar>
        <Stack direction='row' justifyContent='flex-start' alignItems='stretch'>
          <AIAIAIUsersList
            items={infiniteGo2BotsMessages.data}
            onSelect={handleOnSelectGo2BotsMessage}
          />
          {!!selectedGo2BotsMessageItem && (
            <AIAIAIUserChat
              key={`${chatKeyCounter}_${selectedGo2BotsMessageItem.id}`}
              withSentBy
              go2BotsMessage={selectedGo2BotsMessageItem}
              onCommentSuccess={handleOnCommentSuccess}
            />
          )}
        </Stack>
      </MainContainer>
      <Modal open={isNewChatOpen}>
        <InboxNewChatForm
          close={() => {
            setIsNewChatOpen(false);
            infiniteGo2BotsMessages.reset();
          }}
        />
      </Modal>
    </>
  );
  */
};

export default InboxChatDetail;
