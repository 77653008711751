import React, { FC, ReactNode, useMemo } from 'react';
import { Skeleton, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PlayArrow from '@mui/icons-material/PlayArrow';
import ScheduleOutlined from '@mui/icons-material/ScheduleOutlined';
import Stop from '@mui/icons-material/Stop';
import TimeInfoCard from '../TimeInfoCard/TimeInfoCard';

import { UseFetchTimeDetailsResponse } from '../../../../hooks/edit-time';
import { useAppProvider } from '../../../../providers/app/app';
import { toHHMMSS, HHMMSSOptionsJSONRet } from '../../../../utils/date';
import { ReactRenderElement } from '../../../../types/types';
import { isActiveWorklog } from '../TimeDetailsTableRow/TimeDetailsTableRow';

type TimeInfoArray = {
  id: string;
  title: string;
  icon: ReactNode;
  timeInfo: ReactRenderElement;
};
type TimeInfoCardContainerProps = {
  timeDetailsData: UseFetchTimeDetailsResponse[];
  isTimeDetailsDataLoading: boolean;
};
const TimeInfoCardContainer: FC<TimeInfoCardContainerProps> = ({
  timeDetailsData,
  isTimeDetailsDataLoading,
}) => {
  const { isDarkMode } = useAppProvider();

  const objIconStyles: SxProps<Theme> = useMemo(
    () => ({
      fontSize: 240,
      opacity: isDarkMode ? 0.015 : 0.025,
      transform: 'translate3d(-12%, 25%, 0)',
    }),
    [isDarkMode],
  );
  const objTimeInfo: TimeInfoArray[] = (() => {
    let timeSlots = [...timeDetailsData];
    timeSlots = timeSlots.map((row, index) => {
      if (isActiveWorklog(row, index)) {
        return {
          ...row,
          // if it's still ongoing, let's not show the finished product
          end_time_local: null,
        };
      }
      return row;
    });
    /**
     * * Since timeDetailsData.results are sorted in descending order
     * * We pick the firstTime from the last index and the lastTime from the first index
     */
    const firstTime = timeSlots.at(-1);
    const lastTime = timeSlots.at(0);

    const totalTime = timeSlots.reduce(
      (total, curr) => total + curr.duration,
      0,
    );
    const hms = toHHMMSS(totalTime, {
      json: true,
    }) as HHMMSSOptionsJSONRet;
    const { hours, minutes } = hms;

    return [
      {
        id: 'card-worked',
        title: 'Worked',
        icon: <ScheduleOutlined sx={{ ...objIconStyles }} />,
        timeInfo: `${hours}h ${minutes}m`.trim(),
      },
      {
        id: 'card-start',
        title: 'Started',
        icon: <PlayArrow sx={{ ...objIconStyles, fontSize: '215px' }} />,
        timeInfo: firstTime?.start_time_local ? (
          `${firstTime.start_time_local.format('h:mm A')}`
        ) : (
          <span style={{ opacity: 0.3 }}>–</span>
        ),
      },
      {
        id: 'card-finished',
        title: 'Finished',
        icon: <Stop sx={{ ...objIconStyles, fontSize: '215px' }} />,
        timeInfo: lastTime?.end_time_local ? (
          `${lastTime.end_time_local.format('h:mm A')}`
        ) : (
          <span style={{ opacity: 0.3 }}>–</span>
        ),
      },
    ];
  })();

  return (
    <Box component='section' mb={4}>
      <Stack direction='row' gap={2} pt={2}>
        {isTimeDetailsDataLoading
          ? Array.from({ length: 3 }, (_, i) => (
              <Skeleton
                key={i}
                animation='wave'
                variant='rectangular'
                sx={{
                  flex: 1,
                  height: 120,
                  borderRadius: 3,
                }}
              />
            ))
          : objTimeInfo.map((item) => <TimeInfoCard key={item.id} {...item} />)}
      </Stack>
    </Box>
  );
};

export default TimeInfoCardContainer;
