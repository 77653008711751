import { useLayoutEffect, useState } from 'react';
import { useCurrentProfile } from '../../hooks/profile';
import { useWindowDimension } from '../../hooks/utils/window';
import { UserProfile } from '../../types/profile';

type MediaQueryListenerProps = {
  roles: UserProfile['roles'];
};

export type MediaQueryState = {
  mobile: boolean;
  size: 'phone' | 'tablet' | 'desktop';
};

export function getMediaQueryState(
  width: number,
  init?: boolean,
): MediaQueryState {
  return {
    mobile: init ? false : width < 900,
    size: init ? 'desktop' : width <= 600 ? 'phone' : 'tablet',
  };
}

export function useMediaQuery(roles: MediaQueryListenerProps['roles'] = []) {
  const [mediaQuery, setMediaQuery] = useState(() =>
    getMediaQueryState(window.innerWidth),
  );
  const windowDimension = useWindowDimension();
  const { data: currentProfile } = useCurrentProfile();

  const allowMobileClass =
    !roles.length ||
    currentProfile!.roles.some((role) => roles.indexOf(role) > -1);

  /**
   * Define all side effects here...
   */
  useLayoutEffect(() => {
    const mediaQuery = getMediaQueryState(windowDimension.width);
    setMediaQuery(mediaQuery);
    /**
     * NOTE: Add only the mobile version to certain group, in the future
     * ideally, all mobile should have it but right now, only jobseekers have
     */
    if (allowMobileClass) {
      if (mediaQuery.mobile) {
        document.body.classList.add('is-app-mobile');
      } else {
        document.body.classList.remove('is-app-mobile');
      }
    }
  }, [allowMobileClass, windowDimension, currentProfile, setMediaQuery]);

  return mediaQuery;
}
