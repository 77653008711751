import React, { useState } from 'react';
import { Modal } from '@mui/material';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

import { M3Button } from '../M3/M3Button';
import AutoApproveModal from './AutoApproveModal';

type AutoApproveProps = {
  staffId: string;
};

const AutoApprove: React.FC<AutoApproveProps> = ({ staffId }) => {
  const [isAutoAppoveOpen, setIsAutoAppoveOpen] = useState(false);

  return (
    <>
      <M3Button
        active={isAutoAppoveOpen}
        onClick={() => setIsAutoAppoveOpen(true)}
      >
        <PublishedWithChangesOutlinedIcon /> Auto Approve
      </M3Button>
      <Modal open={isAutoAppoveOpen}>
        <AutoApproveModal
          staffId={staffId}
          close={() => setIsAutoAppoveOpen(false)}
        />
      </Modal>
    </>
  );
};

export default AutoApprove;
