import { Stack, Typography, Box, SxProps } from '@mui/material';
import React, {
  PropsWithChildren,
  ReactEventHandler,
  SyntheticEvent,
  useMemo,
} from 'react';
import moment from 'moment';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';

import { M3TextField } from '../M3/M3TextField';
import { useForm } from '../BasicForm';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import { M3MenuItem } from '../M3/M3MenuItem';
import { M3OptionItem } from '../Popover/BasicPopoverWithSearch';
import { M3Autocomplete, M3AutocompleteProps } from '../M3/M3Autocomplete';

import { getTimeDifferenceBetweenStartAndEnd } from '../../utils/date';
import { useUpdateEffect } from '../../hooks/global/useUpdateEffect';
import { ReactRenderElement } from '../../types/types';

type TimePickerProps = PropsWithChildren & {
  title: ReactRenderElement;
  end?: string;
  start?: string;
  onChange?: (data: {
    start: string;
    startValid: boolean;
    end: string;
    endValid: boolean;
  }) => void;
};

type HrMmPeriodForm = {
  hour: string;
  min: string;
  period: 'am' | 'pm';
  initialHourOpt?: M3OptionItem;
  initialMinOpt?: M3OptionItem;
};

export function useHrMinOptions() {
  const hrOptions: M3OptionItem[] = useMemo(
    () =>
      new Array(12).fill(null).map((_, i) => ({
        id: i + 1,
        label: `${i + 1 <= 9 ? '0' : ''}${i + 1}`,
      })),
    [],
  );

  const minOptions: M3OptionItem[] = useMemo(
    () =>
      new Array(2).fill(null).map((_, i) => ({
        id: i * 30,
        label: `${i * 30 <= 9 ? '0' : ''}${i * 30}`,
      })),
    [],
  );

  return {
    hr: hrOptions,
    min: minOptions,
  };
}

function TimePicker({
  title,
  start: initialStartTime,
  end: initialEndTime,
  onChange,
}: TimePickerProps) {
  const { hr: hrOptions, min: minOptions } = useHrMinOptions();

  const startForm = useForm<HrMmPeriodForm>(() => {
    const m = initialStartTime ? moment(initialStartTime, 'hh:mm a') : null;

    return {
      hour: m ? m.format('hh') : '',
      min: m ? m.format('mm') : '',
      period: m ? (m.format('a') as 'am' | 'pm') : 'am',
      initialHourOpt: hrOptions.find((opt) => opt.label === m?.format('hh')),
      initialMinOpt: minOptions.find((opt) => opt.label === m?.format('mm')),
    };
  });
  const endForm = useForm<HrMmPeriodForm>(() => {
    const m = initialEndTime ? moment(initialEndTime, 'hh:mm a') : null;

    return {
      hour: m ? m.format('hh') : '',
      min: m ? m.format('mm') : '',
      period: m ? (m.format('a') as 'am' | 'pm') : 'am',
      initialHourOpt: hrOptions.find((opt) => opt.label === m?.format('hh')),
      initialMinOpt: minOptions.find((opt) => opt.label === m?.format('mm')),
    };
  });

  const getStartEndTime = () => {
    let {
      hour: fromHour,
      min: fromMin,
      period: fromPeriod,
    } = startForm.formState;
    let { hour: toHour, min: toMin, period: toPeriod } = endForm.formState;

    return {
      start: `${fromHour}:${fromMin} ${fromPeriod}`,
      startValid: !!(fromHour && fromMin),
      end: `${toHour}:${toMin} ${toPeriod}`,
      endValid: !!(toHour && toMin),
    };
  };

  const calculateTotalHoursLength = () => {
    let { hour: fromHour, min: fromMin } = startForm.formState;
    let { hour: toHour, min: toMin } = endForm.formState;

    if (!fromHour || !fromMin || !toHour || !toMin) {
      return {
        hours: 0,
        overlap: false,
      };
    }

    const { start, end } = getStartEndTime();
    return getTimeDifferenceBetweenStartAndEnd(start, end);
  };

  const handleOnChange =
    (name: 'from' | 'to', type: 'hour' | 'min') =>
    (evt: SyntheticEvent, option?: M3OptionItem | null) => {
      if (name === 'from') {
        startForm.handleChange({
          target: {
            name: type,
            value: option?.label,
          },
        });
      } else if (name === 'to') {
        endForm.handleChange({
          target: {
            name: type,
            value: option?.label,
          },
        });
      }
    };

  const handleOnSelect =
    (name: 'from' | 'to', type: 'hour' | 'min') =>
    (evt: SyntheticEvent, option?: M3OptionItem | null) => {};

  const handleOnPeriodClick =
    (name: 'from' | 'to') => (period: 'am' | 'pm') => {
      if (name === 'from') {
        startForm.handleChange({
          target: {
            name: 'period',
            value: period,
          },
        });
      } else {
        endForm.handleChange({
          target: {
            name: 'period',
            value: period,
          },
        });
      }
    };

  const renderTimeBlockPicker = ({ name }: { name: 'from' | 'to' }) => {
    return (
      <Stack direction='row' alignItems='center' flex={1} gap={1}>
        {renderPicker({
          placeholder: 'hh',
          options: hrOptions,
          value:
            name === 'from' ? startForm.formState.hour : endForm.formState.hour,
          selected:
            name === 'from'
              ? startForm.formState.initialHourOpt
              : endForm.formState.initialHourOpt,
          onChange: handleOnChange(name, 'hour'),
          onSelect: handleOnSelect(
            name,
            'hour',
          ) as unknown as ReactEventHandler<HTMLDivElement>,
        })}
        <Typography
          component='div'
          pb={0.5}
          fontSize={30}
          fontWeight={500}
          width={20}
          minWidth={20}
          textAlign='center'
          sx={{ opacity: 0.29 }}
        >
          :
        </Typography>
        {renderPicker({
          placeholder: 'mm',
          options: minOptions,
          value:
            name === 'from' ? startForm.formState.min : endForm.formState.min,
          selected:
            name === 'from'
              ? startForm.formState.initialMinOpt
              : endForm.formState.initialMinOpt,
          onChange: handleOnChange(name, 'min'),
          onSelect: handleOnSelect(
            name,
            'min',
          ) as unknown as ReactEventHandler<HTMLDivElement>,
        })}
        {renderAmPm({
          period:
            name === 'from'
              ? startForm.formState.period
              : endForm.formState.period,
          onClick: handleOnPeriodClick(name),
        })}
      </Stack>
    );
  };

  const renderPicker = ({
    value,
    placeholder,
    options,
    onChange,
    onSelect,
    selected,
  }: Partial<
    M3AutocompleteProps & {
      options: M3OptionItem[];
      selected?: M3OptionItem;
    }
  > = {}) => {
    const inputSx: SxProps = {
      border: '0 !important',
      fontSize: '39px !important',
      background: 'transparent !important',
      '.MuiOutlinedInput-root,.MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        borderBottomWidth: 2,
        borderTop: '0 !important',
        borderLeft: '0 !important',
        borderRight: '0 !important',
      },
      '.MuiInputBase-root,.MuiInputBase-input': {
        paddingX: '0 !important',
        border: '0 !important',
        fontSize: '39px !important',
        textAlign: 'center',
        background: 'transparent !important',
      },
    };

    return (
      <Box position='relative' flex={1} sx={inputSx}>
        <M3Autocomplete
          // disablePortal
          fullWidth
          popupIcon={null}
          clearIcon={null}
          options={options as M3OptionItem[]}
          getOptionLabel={(option: string | M3OptionItem) =>
            (option as M3OptionItem).label
          }
          isOptionEqualToValue={(option: M3OptionItem, value?: M3OptionItem) =>
            option?.label === value?.label
          }
          defaultValue={selected}
          onChange={onChange}
          onSelect={onSelect}
          renderInput={(params) => (
            <M3TextField
              {...params}
              inputProps={{
                maxLength: 2,
                ...params.inputProps,
              }}
              name='division'
              fullWidth
              stripAllAutoProps
              placeholder={placeholder}
              value={value}
              // onChange={handleChange}
              sx={inputSx}
            />
          )}
          noOptionsText={<span style={{ opacity: 0.5 }}>–</span>}
          sx={{
            ...inputSx,
          }}
        />
      </Box>
    );
  };

  const renderAmPm = ({
    period,
    onClick,
  }: {
    period: 'am' | 'pm';
    onClick: (period: 'am' | 'pm') => void;
  }) => {
    const btnSx: SxProps = {
      px: 0,
      py: 0.4,
      my: 0.6,
      fontSize: 12,
      fontWeight: 500,
      borderRadius: 1,
      height: 'initial',
      minHeight: 'initial',
      textAlign: 'center',
      justifyContent: 'center',
    };
    return (
      <Box
        sx={{
          width: 34,
          minWidth: 34,
          marginLeft: 1,
        }}
      >
        <M3MenuItem
          sx={btnSx}
          disableRipple
          active={period === 'am'}
          onClick={() => onClick('am')}
        >
          AM
        </M3MenuItem>
        <M3MenuItem
          sx={btnSx}
          disableRipple
          active={period === 'pm'}
          onClick={() => onClick('pm')}
        >
          PM
        </M3MenuItem>
      </Box>
    );
  };

  const { overlap } = calculateTotalHoursLength();

  useUpdateEffect(() => {
    onChange?.(getStartEndTime());
  }, [startForm.formState, endForm.formState]);

  return (
    <>
      <M3SurfaceContainer elevation={1} sx={{ p: 2, px: 3, pb: 2 }}>
        <Typography
          component='h4'
          gap={2}
          fontWeight={500}
          fontSize={24}
          pb={0.5}
          display='flex'
          alignItems='center'
        >
          {title}{' '}
          {overlap && (
            <NightsStayOutlinedIcon
              style={{
                fontSize: 28,
              }}
            />
          )}
        </Typography>
        <Stack direction='row' justifyContent='space-between' flex={1}>
          {renderTimeBlockPicker({
            name: 'from',
          })}
          <Typography
            component='div'
            pt={1}
            fontSize={30}
            fontWeight={500}
            width={80}
            minWidth={80}
            textAlign='center'
            sx={{ opacity: 0.2 }}
          >
            –
          </Typography>
          {renderTimeBlockPicker({
            name: 'to',
          })}
        </Stack>
      </M3SurfaceContainer>
      {/* <Typography component='div' pt={2} fontSize={13}>
        Allows{' '}
        <Typography
          component='span'
          fontSize={16}
          sx={{
            px: 0.1,
            py: 0.1,
            fontWeight: 700,
            borderRadius: 1,
          }}
        >
          {hours}
        </Typography>{' '}
        hour{hours > 1 ? 's' : ''} to complete an{' '}
        <Typography
          component='span'
          fontSize={16}
          sx={{
            px: 0.1,
            py: 0.1,
            fontWeight: 700,
            borderRadius: 1,
          }}
        >
          8
        </Typography>{' '}
        hour shift
      </Typography>
      <Typography
        gap={1}
        pt={0.5}
        fontSize={12}
        display='flex'
        component='div'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <ErrorOutlinedIcon
          style={{
            fontSize: 20,
            opacity: 0.49,
          }}
        />
        <span
          style={{
            opacity: 0.49,
          }}
        >
          Your length of shift should take into account breaks, etc.{' '}
          <i>
            (Some people have shorter/longer range of hours to complete their
            shift depending on the type of work you do.)
          </i>{' '}
          <br />
          For some of us an 8 hour shift is 8.5 hours long and some 12. If you
          are not sure... best to keep it tight.
        </span>
      </Typography> */}
    </>
  );
}

export default TimePicker;
