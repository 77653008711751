import React from 'react';
import { Box, Paper, PaperProps, Typography } from '@mui/material';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';

import { ArticleItemProps } from '../../providers/intercom';
import { useAppProvider } from '../../providers/app/app';

type Props = {
  first?: boolean;
  last?: boolean;
  item: ArticleItemProps;
  paperProps?: PaperProps;
};

const IntercomArticleItem = ({ last, item, paperProps }: Props) => {
  const { isDarkMode } = useAppProvider();

  const content = (
    <Paper
      className='collection__paper'
      sx={{
        cursor: 'pointer',
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        background: 'transparent',
        ...paperProps?.sx,
      }}
      style={{
        border: 0,
        margin: 0,
        borderRadius: 0,
        boxShadow: 'none',
        ...paperProps?.style,
      }}
      onClick={() => {
        let url =
          item.article.url ?? `https://support.go2.io/articles/${item.id}`;
        window.open(url, '_blank');
      }}
    >
      <Box flex={1}>
        <Typography
          fontSize={18}
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-primary80)'
              : 'var(--md-ref-palette-primary40)',
          }}
        >
          {item.article.title}
        </Typography>
        {!!item.article.description && (
          <Typography
            fontSize={16}
            sx={{
              color: isDarkMode
                ? 'var(--md-ref-palette-neutral80)'
                : 'var(--md-ref-palette-neutral40)',
            }}
            style={{
              maxWidth: 630,
              padding: '5px 0 0',
            }}
          >
            {item.article.description}
          </Typography>
        )}
      </Box>
    </Paper>
  );

  return (
    <M3Card
      sx={{
        marginBottom: last ? 0 : '8px',
      }}
    >
      <M3SurfaceContainer elevation={1}>{content}</M3SurfaceContainer>
    </M3Card>
  );
};

export default IntercomArticleItem;
