import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import SendIcon from '@mui/icons-material/Send';
import { Virtuoso } from 'react-virtuoso';
import moment from 'moment';

import { M3TextField } from '../M3/M3TextField';
import SideSheet from './SideSheet';
import { MemoizedReactMarkdown } from '../Markdown/MemoizedReactMarkdown';
import { CodeBlock } from '../Markdown/CodeBlock';
import { M3IconButton } from '../M3/M3Button';
import {
  AIMessage,
  UseAIAssistSideSheetRet,
  useFixedSideSheetContent,
} from './AIAssistSideSheet';

import './AIChatSideSheet.css';
import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';

type AIChatSideSheetProps = PropsWithChildren & {
  fixed?: boolean;
  inPage?: boolean;
  headerSx?: SxProps;
  title?: ReactRenderElement;
  containerSelector?: string;
  parentSelector?: string;
  aiAssist: UseAIAssistSideSheetRet;
  withToolbar?: boolean;
  withSubToolbar?: boolean;
  className?: string;
  onClose?: () => void;
};

type TabViewProps = {
  containerSelector?: AIChatSideSheetProps['containerSelector'];
  parentSelector?: AIChatSideSheetProps['parentSelector'];
  aiAssist: AIChatSideSheetProps['aiAssist'];
};

const AIChatSideSheet = ({
  fixed = true,
  headerSx,
  title,
  inPage,
  containerSelector = 'side-sheet-root-container',
  parentSelector = 'side-sheet-root-container',
  aiAssist,
  children,
  withToolbar = false,
  withSubToolbar = false,
  className,
  onClose,
}: AIChatSideSheetProps) => {
  const { toolbarHeight, subToolbarHeight } = useAppProvider();

  const [messages, setMessages] = useState<AIMessage[]>([
    {
      role: 'intro',
      content: 'What questions do you have about this report?',
    },
    {
      role: 'system',
      content: aiAssist.systemPromptRef.current,
    },
  ]);
  const [content, setContent] = useState('');
  const [streamState, setStreamState] = useState({
    stop: false,
    streaming: false,
    streamingDone: false,
  });

  const renderTabContent = () => {
    const tabsProps: Partial<TabViewProps> & {
      containerSelector: TabViewProps['containerSelector'];
      parentSelector: TabViewProps['parentSelector'];
      aiAssist: TabViewProps['aiAssist'];
    } = {
      containerSelector,
      parentSelector,
      aiAssist,
    };

    return <DetailsTab {...tabsProps} messages={messages} />;
  };

  const renderFooterContent = () => {
    const onKeyDownTextField = (
      e: React.KeyboardEvent<HTMLDivElement>,
    ): void => {
      if (!e.shiftKey && e.key === 'Enter') {
        e.preventDefault();
        if (content.trim()) {
          onSendClick();
        }
      }
    };

    const onSendClick = (): void => {
      setMessages((messages) => [
        ...messages,
        {
          role: 'user',
          content: content,
        },
      ]);
      setContent('');
      setStreamState(() => ({
        stop: false,
        streaming: false,
        streamingDone: false,
      }));
    };

    return (
      <Footer>
        <M3TextField
          fullWidth
          onKeyDown={onKeyDownTextField}
          placeholder='Type here...'
          variant='outlined'
          multiline
          maxRows={5}
          sx={{
            ml: 2,
            mr: 2,
            mb: 1,
            '.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
            '.MuiInputBase-input': {
              paddingRight: '48px !important',
            },
          }}
          value={content}
          onChange={(evt) => setContent(evt.currentTarget.value)}
        />
        <M3IconButton
          sx={{
            top: 10,
            right: 24,
            position: 'absolute',
          }}
          style={{
            width: 30,
            height: 30,
          }}
          disabled={!content}
          onClick={onSendClick}
        >
          <SendIcon style={{ fontSize: 14 }} />
        </M3IconButton>
      </Footer>
    );
  };

  useEffect(() => {
    if (
      /**
       * check if there are messages beyond 2, since 1 and 2 are intro and
       * system prompt, respectively.
       */
      messages.length > 2 &&
      !streamState.streaming &&
      !streamState.streamingDone
    ) {
      const updateSystemPromptMessage = (msgs: AIMessage[]) => {
        return (msgs = [...msgs].map((m) => {
          if (m.role === 'system') {
            return {
              ...m,
              content: aiAssist.systemPromptRef.current,
            };
          }
          return { ...m };
        }));
      };

      const updateAssistantMessage = (
        messages: AIMessage[],
        content: string,
        error?: boolean,
      ) => {
        messages = updateSystemPromptMessage(messages);
        const lastMessage = messages[messages.length - 1];

        if (lastMessage.role === 'assistant') {
          messages[messages.length - 1] = {
            role: 'assistant',
            content,
            error,
          };
        } else {
          messages.push({
            role: 'assistant',
            content,
            error,
          });
        }

        return messages;
      };

      /**
       * Assigning a callback for when the streaming fires, every time new
       * chunk of content is being passed, we update the message
       */
      aiAssist.onStreamRef.current = (
        content: string,
        done?: boolean,
        error?: boolean,
        stopped?: boolean,
      ) => {
        if (!stopped) {
          setMessages(updateAssistantMessage(messages, content, error));
        }

        if (done || stopped) {
          setStreamState({
            stop: false,
            streaming: false,
            streamingDone: true,
          });
        }
      };

      /**
       * Ask the AI assist with the updated messages
       */
      aiAssist.start({
        is_open: true,
        data: {
          ...aiAssist.data,
          messages: updateSystemPromptMessage(
            messages.filter((m) => m.role !== 'intro'),
          ).map((m) => {
            /**
             * When submitting to openai chat, let's change the system as user,
             * it's recommended to not use system anymore.
             */
            if (m.role === 'system') {
              return { ...m, role: 'user' };
            }
            return m;
          }),
        },
      });

      setStreamState({
        stop: false,
        streaming: true,
        streamingDone: false,
      });
    }
    // eslint-disable-next-line
  }, [streamState, messages, setMessages]);

  return (
    <SideSheet
      fixed={fixed}
      width={aiAssist.width}
      isOpen={aiAssist.is_open}
      perfectScrollbarProps={{
        options: {
          suppressScrollX: true,
          wheelPropagation: true,
        },
      }}
      title={
        <Stack
          className='sidesheet-header-title'
          pl={2}
          pr={1}
          pt={2}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack flex={1} gap={1} direction='row' alignItems='center'>
            <Typography component='div' fontSize={14} fontWeight={500}>
              {title}
            </Typography>
          </Stack>
        </Stack>
      }
      headerSx={headerSx}
      content={renderTabContent()}
      footer={renderFooterContent()}
      onClose={onClose}
      sideSheetContentSx={
        inPage
          ? {
              top:
                8 +
                (withToolbar ? toolbarHeight : 0) +
                (withSubToolbar ? subToolbarHeight : 0),
              bottom: 8,
            }
          : undefined
      }
      sx={{ height: '100%' }}
      className={className}
    >
      {children}
    </SideSheet>
  );
};

export default AIChatSideSheet;

type DetailsTabProps = TabViewProps & {
  messages: AIMessage[];
};
function DetailsTab({
  containerSelector,
  parentSelector,
  aiAssist,
  messages = [],
}: DetailsTabProps) {
  const { isDarkMode } = useAppProvider();
  const fixedSideSheetContent = useFixedSideSheetContent({
    containerSelector,
    parentSelector,
  });

  const _messages = useMemo(() => {
    const msgs = messages.filter((m) => m.role !== 'system');

    if (aiAssist.chatRequestState.isLoading) {
      msgs.push({
        role: 'assistant',
        content: 'Please wait...',
        is_ai_loading: aiAssist.chatRequestState.isLoading,
        ai_error: aiAssist.chatRequestState.error,
        modified: moment.utc().format(),
      });
    }

    return msgs;
  }, [messages, aiAssist.chatRequestState]);

  return (
    <BoxContent ref={fixedSideSheetContent.ref}>
      <Box
        ref={fixedSideSheetContent.boxRef}
        style={{
          borderRadius: 4,
          ...(isDarkMode
            ? {
                background: 'var(--md-ref-palette-neutral-variant30)',
                boxShadow: '0 0 0 1px var(--md-ref-palette-neutral-variant40)',
              }
            : {
                background: 'var(--md-ref-palette-neutral-variant95)',
                boxShadow: '0 0 0 1px var(--md-ref-palette-neutral-variant98)',
              }),
        }}
      >
        <Virtuoso
          totalCount={_messages.length}
          itemContent={(index) => {
            const message = _messages[index];
            return <AIChatMessage message={message} />;
          }}
          followOutput='smooth'
          style={{
            height: '100%',
            overflow: 'hidden auto',
          }}
        />
      </Box>
    </BoxContent>
  );
}

type FooterProps = PropsWithChildren;
const Footer = forwardRef<HTMLDivElement, FooterProps>(({ children }, ref) => {
  return (
    <Box className='sidesheet-content-footer' ref={ref}>
      <Divider sx={{ opacity: 0.5 }} />
      <Box
        display='flex'
        justifyContent='center'
        alignItems='flex-end'
        sx={{
          minHeight: 54,
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </Box>
  );
});

type BoxContentProps = PropsWithChildren & {
  sx?: SxProps;
};
const BoxContent = forwardRef(({ children, sx }: BoxContentProps, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        p: 2,
        pl: 2,
        pr: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});

type AIChatMessageProps = {
  message: AIMessage;
};
function AIChatMessage({ message }: AIChatMessageProps) {
  const { isDarkMode } = useAppProvider();
  const markdownTableCSS = {
    borderColor: isDarkMode
      ? 'var(--md-ref-palette-neutral-variant30)'
      : 'var(--md-ref-palette-neutral-variant80)',
  };

  return (
    <Box
      sx={{
        p: 1,
        ...(message.role === 'assistant'
          ? {
              pr: 4,
            }
          : null),
        ...(message.role === 'user'
          ? {
              pl: 4,
            }
          : null),
      }}
      display='flex'
      justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
    >
      <Typography
        component='div'
        fontSize={12}
        maxWidth='100%'
        sx={{
          pl: 1.5,
          pr: 1.5,
          borderRadius: 2,
          background:
            message.role === 'assistant'
              ? isDarkMode
                ? 'transparent'
                : 'transparent'
              : message.role === 'user'
              ? isDarkMode
                ? 'var(--md-ref-palette-neutral-variant40)'
                : 'var(--md-ref-palette-neutral-variant90)'
              : undefined,
          boxShadow:
            message.role === 'assistant'
              ? isDarkMode
                ? '0 0 0 1px var(--md-ref-palette-neutral-variant40)'
                : '0 0 0 1px var(--md-ref-palette-neutral-variant80)'
              : message.role === 'user'
              ? isDarkMode
                ? 'transparent'
                : 'transparent'
              : undefined,
          pre: {
            whiteSpace: 'inherit',
          },
        }}
        style={{
          color: message.error
            ? isDarkMode
              ? 'var(--md-ref-palette-error80)'
              : 'var(--md-ref-palette-error40)'
            : undefined,
        }}
      >
        <>
          <MemoizedReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeMathjax]}
            components={{
              a({ node, className, children, ...props }) {
                return (
                  <a
                    href={props.href}
                    rel='noreferrer'
                    target='_blank'
                    style={{
                      color: isDarkMode
                        ? 'var(--md-ref-palette-primary80)'
                        : 'var(--md-ref-palette-primary40)',
                    }}
                  >
                    {children}
                  </a>
                );
              },
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return (
                  <CodeBlock
                    language={match?.[1]}
                    value={String(children).replace(/\n$/, '')}
                    {...props}
                  />
                );
              },
              table({ children }) {
                return <table style={markdownTableCSS}>{children}</table>;
              },
              th({ children }) {
                return (
                  <th
                    style={{
                      ...markdownTableCSS,
                      background: isDarkMode
                        ? 'var(--md-ref-palette-secondary40)'
                        : 'var(--md-ref-palette-secondary40)',
                    }}
                  >
                    {children}
                  </th>
                );
              },
              td({ children }) {
                return <td style={markdownTableCSS}>{children}</td>;
              },
            }}
          >
            {message.content}
          </MemoizedReactMarkdown>
        </>
      </Typography>
    </Box>
  );
}
