import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Divider,
  Stack,
  SxProps,
  Typography,
  Chip,
  CircularProgress,
  Modal,
} from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom';
import { GroupedVirtuoso } from 'react-virtuoso';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import { FormStateRet, useForm } from '../components/BasicForm';
import DocumentTitle from '../components/DocumentTitle';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import { ModalCardViewCloseProps } from '../components/ModalCardView';
import { TaskDetailModalViewBase } from '../components/TaskDetailModalView';
import DateRangeSelectionPopover from '../components/Filters/DateRangeSelectionPopover';
import AutocompleteSelectionPopover from '../components/Filters/AutocompleteSelectionPopover';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import ModalCardViewError from '../components/ModalCardViewError';
import RichTextContent from '../components/RichEditor/RichTextContent';
import Error from './Error';
import { useAIAssistSideSheet } from '../components/SideSheet/AIAssistSideSheet';
import AIChatSideSheet from '../components/SideSheet/AIChatSideSheet';
import MeritBadge from '../components/MeritBadge';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useAppProvider } from '../providers/app/app';
import { MenuOption } from '../types/app';
import {
  useExportDidGetWin,
  UseExportDidGetWinProps,
  useExportSearchAnswers,
  UseExportSearchAnswersProps,
} from '../hooks/domo';
import { parseURL } from '../utils/url';
import {
  QuestionGroup,
  useMetadataProvider,
} from '../providers/metadata/metadata';
import { csvToJson } from '../utils/csv';
import {
  DailyReportEODData,
  DailyReportEODDataKPI,
  ReportType,
} from '../types/report';
import { useUserProvider } from '../providers/user/user';
import {
  useReportingReport,
  useTeamManifestDailyReportById,
} from '../hooks/report';
import {
  DailyReportItemExpanded,
  parseDailyReportItemResponse,
} from '../utils/report';
import { DateInUTC, IterableObject, ReactRenderElement } from '../types/types';
import { useWindowDimension } from '../hooks/utils/window';
import { isNotNullAndUndefined } from '../utils/object';
import { sortBy } from '../utils/array';
import TeamManifestModalView from '../components/TeamManifestModalView';
import * as posthog from '../services/posthog';
import { useDomoExecuteApi } from '../hooks/services/domo';
import { exportLinkToCSV } from '../utils/download';
import { OpenAIModelID } from '../types/openai';

type Props = {};

type AutocompleteFormState = {
  checked: {
    [key: string]: boolean;
  };
  optionById: {
    [key: string]: MenuOption;
  };
};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

type CSVItemResponse = {
  focus_id: string;
  member_id: string;
  report_id: string;
  report_type: ReportType | 'header';
  date: string;
  member: string;
  focus: string;
  question: string | QuestionJSON[];
  question_id: string;
  answer: string;
  json?: QuestionJSON[];
  today_win?: string;
  did_get_win?: string;
  did_get_win_about?: string;
};

type CSVDidWinItemResponse = {
  did_get_win: 'yes' | 'no';
  did_get_win_about: string;
  email: string;
  plan_entry_date: DateInUTC;
  plan_id: number;
  reality_entry_date: DateInUTC;
  reality_id: number;
  today_win: string;
  user_id: number;
};

type QuestionJSON = {
  answer: string;
  question: string;
  question_id: string;
};

type TableHeader = {
  key: string;
  label: string;
  html?: string;
  element?: ReactRenderElement;
};
type TableHeaders = IterableObject<TableHeader[]> & {
  date: TableHeader[];
  member: TableHeader[];
  focus?: TableHeader[];
  question?: TableHeader[];
  today_win?: TableHeader[];
  did_get_win?: TableHeader[];
};
type TableRowItem = {
  item: TableHeaders;
  data: CSVItemResponse;
};

type KPIRowData = Array<Array<string>>;

type KPIResponse = {
  columns: string[];
  rows: KPIRowData;
};

type KPIResource = {
  date?: string;
  duration: number;
  go2_member_id?: number;
  host: string;
  percent: number;
  sod_id?: number;
  task_id?: number;
  task_name: string;
  type?: string;
};

type RowItemOptions = {
  index: number;
  header?: boolean;
  first?: boolean;
  last?: boolean;
  width?: number;
  onClick?: () => void;
};

type ParseRawDataResult = {
  headers: TableHeaders;
  items: TableRowItem[];
  members: IterableObject<string>;
};

const SearchAnswers = (props: Props) => {
  const { toolbarHeight, subToolbarHeight, drawerWidth, isDarkMode } =
    useAppProvider();
  const { current } = useWorkspaceProvider();
  const {
    questions: searchAnswersQuestionItems,
    questionsParams: searchAnswersQuestionsParams,
    setQuestionsParams,
  } = useMetadataProvider();
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();
  const { search } = useLocation();
  const parsedUrl = parseURL(search);

  const dateForm = useForm<DateFormState>({
    start: searchAnswersQuestionsParams.date_start,
    end: searchAnswersQuestionsParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });
  const memberForm = useForm<AutocompleteFormState>({
    checked: {},
    optionById: {},
  });

  const topPanelHeaderRef = useRef<HTMLDivElement>();

  const [isTaskDetailOpen, setIsTaskDetailOpen] = useState(false);
  const [csvRowItem, setCsvRowItem] = useState<TableRowItem | null>(null);

  const [membersDict, setMembersDict] = useState<IterableObject<string>>({});

  const windowDimension = useWindowDimension();

  const aiAssist = useAIAssistSideSheet({
    stream: true,
  });

  let hasActiveFilters = [
    ...Object.values(memberForm.formState.checked || {}),
  ].some((v) => !!v);

  const getAutocompleteSelectedByForm = (
    form: FormStateRet<AutocompleteFormState>,
    members?: string,
  ) => {
    let values: string[] = Object.keys(form.formState.checked || {}).filter(
      (key) => {
        return form.formState.checked[key];
      },
    );
    return values.length ? values.join(',') : members ? members : undefined;
  };

  /**
   * Get the selected question from the list of question items based on the
   * parsed question id
   */
  const selectedQuestion = searchAnswersQuestionItems.find(
    (q) => parsedUrl.qid === q.id,
  );
  const questionIds = Array.from(
    new Set(
      (
        selectedQuestion?.questions
          .map((q) =>
            [q.id].concat(q.child_questions?.map((cq) => cq.id) ?? []),
          )
          .flat() ?? []
      ).filter((s) => !!s),
    ),
  );
  const focusIds = Array.from(
    new Set(
      (
        selectedQuestion?.questions
          .map((q) =>
            [q.task_id].concat(
              q.child_questions?.map((cq) => cq.task_id) ?? [],
            ),
          )
          .flat() ?? []
      ).filter((s) => !!s),
    ),
  );

  /**
   * Convert the members dict into a single array into a menu option
   */
  const membersOptions = useMemo(() => {
    const options: MenuOption[] = Object.keys(membersDict).map((key) => ({
      id: key,
      value: membersDict[key],
    }));
    return sortBy(options, 'value', true, 'ASC');
  }, [membersDict]);

  const isWinQuestion = selectedQuestion?.id === 'win';

  const searchAnswersParams: Partial<UseExportSearchAnswersProps> = {
    date_start: moment(dateForm.formState.start!).format('YYYY-MM-DD'),
    date_end: moment(dateForm.formState.end!).format('YYYY-MM-DD'),
    focus: focusIds.join(),
    member: getAutocompleteSelectedByForm(memberForm),
    question: questionIds.join(),
    fields:
      'focus_id,report_id,report_type,member_id,date,member,focus,question_id,question,answer',
  };
  const rawSearchAnswers = useExportSearchAnswers(
    {
      key: 'raw_search_answers',
      ...searchAnswersParams,
    } as UseExportSearchAnswersProps,
    {
      enabled: !isWinQuestion && !!(questionIds.length && focusIds.length),
      keepPreviousData: false,
    },
  );
  const downloadExportSearchAnswers = useExportSearchAnswers(
    {
      key: 'export_search_answers',
      ...searchAnswersParams,
    } as UseExportSearchAnswersProps,
    {
      enabled: false,
      keepPreviousData: false,
    },
  );

  const selectedWinMember = getAutocompleteSelectedByForm(
    memberForm,
    current?.memberId,
  );
  const userMembers = selectedWinMember
    ? selectedWinMember
        .split(',')
        .map((m) => getUser('employee_id', m))
        .filter((u) => !!u)
    : [];
  const didGetWinParams: Partial<UseExportDidGetWinProps> = {
    start_date: searchAnswersParams.date_start!,
    end_date: searchAnswersParams.date_end!,
    divisions: current?.id === 'my-squad' ? undefined : (current?.id as string),
    users: userMembers.length
      ? userMembers.map((u) => u!.id).join(',')
      : undefined,
  };

  const rawDidGetWin = useExportDidGetWin(
    {
      key: 'raw_did_get_win',
      ...(didGetWinParams as any),
    },
    {
      enabled: isWinQuestion,
      keepPreviousData: false,
    },
  );
  const downloadDidGetWin = useExportDidGetWin(
    {
      key: 'export_did_get_with',
      ...didGetWinParams,
    } as UseExportDidGetWinProps,
    {
      enabled: false,
      keepPreviousData: false,
    },
  );

  const kpiResources = useDomoExecuteApi<KPIResponse>(
    {
      key: `kpi_resources`,
      name: 'kpi_resources',
      date_range: [dateForm.formState.start!, dateForm.formState.end!],
      data_source_id: process.env.REACT_APP_DOMO_KPI_RESOURCES_DATA_SOURCE_ID!,
      focus_id: focusIds.join(','),
      go2_member_id: membersOptions.length
        ? membersOptions.map((m) => m.id).join(',')
        : undefined,
    },
    {
      enabled:
        !focusIds.some((f) => f === 'win') &&
        !!membersOptions.length &&
        !!focusIds.length,
    },
  );

  const parseRawKpiData = (data: KPIResponse) => {
    const { columns, rows } = data;

    if (!columns.length && !rows.length) return undefined;

    const kpiResultsArray = rows.map((row) => {
      const obj: IterableObject = {};

      columns.forEach((column, index) => {
        obj[column] = row[index];
      });

      return obj;
    });

    const fieldToGroupBy = 'go2_member_id';

    const groupedObject: IterableObject<KPIResource[]> = kpiResultsArray.reduce(
      (acc, obj) => {
        const fieldValue = obj[fieldToGroupBy];
        if (acc[fieldValue]) {
          acc[fieldValue].push(obj);
        } else {
          acc[fieldValue] = [obj];
        }
        return acc;
      },
      {},
    );

    return groupedObject;
  };

  const parsedKpiData = kpiResources.data
    ? parseRawKpiData(kpiResources.data)
    : {};

  const itemMinHeight = 37;
  const itemColSx: SxProps = {
    pt: 1,
    pb: 1,
    pl: 2,
    pr: 2,
    width: 0,
    fontSize: 14,
    minHeight: itemMinHeight,
    lineHeight: 1.4,
    wordBreak: 'break-word',
    borderLeft: `1px solid ${
      isDarkMode
        ? 'var(--md-ref-palette-neutral-variant30)'
        : 'var(--md-ref-palette-neutral-variant80)'
    }`,
  };
  const rowColumnsMinWidth: IterableObject<number> = {
    date: 140,
    member: 180,
    ...(isWinQuestion
      ? {
          today_win: 360,
          did_get_win: 80,
        }
      : {
          focus: 180,
        }),
  };
  const questionMinWidth = 180;

  const parseRawData = (data: string): ParseRawDataResult => {
    const headers: TableHeaders = {
      date: [
        {
          key: 'date',
          label: 'Date',
        },
      ],
      member: [
        {
          key: 'member',
          label: 'Member',
        },
      ],
      ...(isWinQuestion
        ? {
            today_win: [
              {
                key: 'today_win',
                label: 'What will make today a win?',
              },
            ],
            did_get_win: [
              {
                key: 'did_get_win',
                label: 'Win',
              },
            ],
          }
        : {
            focus: [
              {
                key: 'focus',
                label: 'Focus',
              },
            ],
          }),
    };
    const items: TableRowItem[] = [];

    // maximum questions, this will serve also as the total number columns for questions
    let maxQuestionItems: QuestionJSON[] = [];
    let maxQuestionDict: IterableObject<QuestionJSON[]> = {};

    const members: IterableObject<string> = {};

    if (data) {
      let result = csvToJson<CSVItemResponse>(data as string);
      if (isWinQuestion) {
        const winResult = result as unknown as CSVDidWinItemResponse[];
        result = winResult.map((item) => {
          let user = getUser('user_id', item.user_id);
          return {
            focus_id: '0',
            member_id: user?.employee_id,
            report_id: `${item.plan_id}`,
            date: item.plan_entry_date || item.reality_entry_date,
            member:
              `${user?.first_name || ''} ${user?.last_name || ''}`.trim() ||
              item.email,
            focus: item.today_win,
            question: 'Tell us a little something about it',
            question_id: 'win',
            answer: item.did_get_win_about,
            today_win: item.today_win,
            did_get_win: item.did_get_win,
            did_get_win_about: item.did_get_win_about,
          } as CSVItemResponse;
        }) as unknown as CSVItemResponse[];
      }

      result = result.map((item) => {
        item = {
          ...item,
          /**
           * In V2, date is SOD's created date in UTC, same in MyDay card.
           * Converting it to YMD
           */
          date: moment.utc(item.date).local().format('YYYY-MM-DD'),
        };
        /**
         * In V2, there's no longer an array of string to parse, we
         * question will be directly be converted into an array just like in V1,
         * This way, there's not big of a change.
         */
        item.question = [
          {
            answer: item.answer,
            question: item.question,
            question_id: item.question_id,
          } as QuestionJSON,
        ];

        return item;
      });

      result.forEach((res) => {
        // get all the members found in the list
        members[res.member_id] = res.member;

        try {
          // NOTE: Notify Mike to have distinct question
          // parse the json data and get only unique question
          res.json = Array.isArray(res.question)
            ? res.question
            : (JSON.parse(res.question) as QuestionJSON[]) ?? [];
          res.json = res.json.reduce(
            (prev: QuestionJSON[], curr: QuestionJSON) => {
              /**
               * This gets all the maximum/possible question columns
               */
              if (
                !maxQuestionItems.some(
                  (q) => q.question_id === curr.question_id,
                )
              ) {
                maxQuestionItems.push({ ...curr });
              }

              /**
               * This prevent duplicated question to be returned
               */
              if (!prev.some((q) => q.question_id === curr.question_id)) {
                prev.push(curr);
              }

              return prev;
            },
            [],
          );
          // map the item that doesn't change
          const item: TableRowItem = {
            data: res,
            item: {
              date: [
                {
                  key: 'date',
                  label: moment(res.date).format('MMM DD, YYYY'),
                },
              ],
              member: [
                {
                  key: 'member',
                  label: res.member,
                },
              ],
              ...(isWinQuestion
                ? {
                    today_win: [
                      {
                        key: 'today_win',
                        label: DOMPurify.sanitize(res.today_win || '', {
                          ALLOWED_TAGS: [],
                        }),
                        html: DOMPurify.sanitize(res.today_win || ''),
                      },
                    ],
                    did_get_win: [
                      {
                        key: 'did_get_win',
                        label: DOMPurify.sanitize(res.today_win || '', {
                          ALLOWED_TAGS: [],
                        }),
                        element:
                          res.did_get_win === 'yes' ? (
                            <div style={{ padding: '4px' }}>
                              <MeritBadge type='win' size='small' />
                            </div>
                          ) : (
                            <span style={{ opacity: 0.2 }}>–</span>
                          ),
                      },
                    ],
                  }
                : {
                    focus: [
                      {
                        key: 'focus',
                        label: res.focus,
                      },
                    ],
                  }),
            },
          };

          items.push(item);
        } catch (e) {}
      });

      /**
       * Storage for unique question(title) but different question_id
       * This occurs, since there are questions that are the same
       * but different focus_id
       */
      maxQuestionDict = maxQuestionItems.reduce(
        (prev: IterableObject<QuestionJSON[]>, curr: QuestionJSON) => {
          prev[curr.question] = prev[curr.question] ?? [];
          prev[curr.question].push(curr);
          return prev;
        },
        {} as IterableObject<QuestionJSON[]>,
      );

      // attach the questions into the header so it will be dynamic
      headers.question = Array.from(
        new Set(maxQuestionItems.map((q) => q.question)),
      ).map((label) => {
        const qdItems = maxQuestionDict[label];
        // NOTE: Make sure the key are joined identify later correct
        // answer for which question/answer belong
        const key = qdItems.map((q) => q.question_id).join(',');
        return {
          key,
          label,
        };
      });

      /**
       * Attach the question/answer to each item so that it will have
       * the correct number of columns
       */
      items.forEach((item) => {
        item.item.question = headers.question!.map((hq) => {
          const hqKeys = hq.key.split(',');
          const q = item.data.json!.find(
            (q) => hqKeys.indexOf(q.question_id) > -1,
          );
          if (q) {
            return {
              key: q.question_id,
              label: DOMPurify.sanitize(q.answer, { ALLOWED_TAGS: [] }),
              html: DOMPurify.sanitize(q.answer),
            };
          } else {
            /**
             * When there's no question found for this item, it means this is not
             * belong to the same question of the header, must be belong to another focus
             */
            return {
              key: hq.key,
              label: '',
            };
          }
        });
      });

      return {
        headers,
        items,
        members,
      };
    } else {
      return {
        headers,
        items,
        members,
      };
    }
  };

  const getRawDataToAiAssistData = ({
    items = [],
  }: ParseRawDataResult): RawDataToAiAssistDataProps => {
    return {
      data: items.map((item) => ({
        date: item.data.date,
        member: item.data.member,
        json: isWinQuestion
          ? ([
              {
                answer: DOMPurify.sanitize(item.data.today_win || '', {
                  ALLOWED_TAGS: [],
                }),
                question: 'What will make today a win?',
                question_id: 'today_win',
              },
              {
                answer: item.data.did_get_win,
                question: 'Did you get your win?',
                question_id: 'did_get_win',
              },
              {
                answer: DOMPurify.sanitize(item.data.did_get_win_about || '', {
                  ALLOWED_TAGS: [],
                }),
                question: 'Tell us a little something about it',
                question_id: 'did_get_win_about',
              },
            ] as QuestionJSON[])
          : item.data.json,
        more_info:
          parsedKpiData?.[item.data.member_id]?.filter(
            (kpi) => kpi.date === item.data.date,
          ) ?? [],
      })),
      focusArea: isWinQuestion
        ? 'Manifest'
        : items.find((item) => item.data.focus)?.data.focus,
    };
  };

  const renderRowItem = (
    item: TableHeaders | TableRowItem['item'],
    { index, header, width: rowWidth, onClick }: RowItemOptions,
  ) => {
    const isEven = !(index & 1);
    let stackSx: SxProps = isDarkMode
      ? {
          borderBottom: '1px solid var(--md-ref-palette-neutral-variant30)',
          color: header
            ? 'var(--md-sys-color-on-surface-dark)'
            : isEven
            ? undefined
            : undefined,
          background: header
            ? 'var(--md-ref-palette-primary20)'
            : isEven
            ? `var(--md-sys-color-background-dark)`
            : `var(--md-ref-palette-neutral-variant20)`,
        }
      : {
          borderBottom: '1px solid var(--md-ref-palette-neutral-variant80)',
          color: header
            ? 'var(--md-sys-color-on-surface-light)'
            : isEven
            ? undefined
            : undefined,
          background: header
            ? 'var(--md-ref-palette-primary90)'
            : isEven
            ? `var(--md-sys-color-background-light)`
            : `var(--md-ref-palette-neutral-variant95)`,
        };
    stackSx = {
      ...stackSx,
      ...(header
        ? null
        : {
            cursor: 'pointer',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              opacity: 0,
              pointerEvents: 'none',
              background: 'var(--md-ref-palette-primary40)',
            },
            '&:hover,&.active': {
              '&:before': {
                opacity: 0.16,
              },
            },
          }),
    };

    const renderColumn = (
      { key, label, html, element }: TableHeader,
      {
        flex,
        width,
        minWidth,
        sx,
        style,
      }: {
        flex?: number | undefined;
        width?: number | undefined;
        minWidth?: number | undefined;
        sx?: SxProps;
        style?: CSSProperties;
      },
    ) => {
      return (
        <Typography
          key={key}
          component='div'
          flex={flex}
          width={width}
          minWidth={minWidth}
          fontWeight={header ? 700 : 400}
          sx={{
            ...itemColSx,
            ...sx,
          }}
          style={style}
        >
          {element ? (
            element
          ) : !!html && isNotNullAndUndefined(html) ? (
            <RichTextContent content={html} />
          ) : !!label && isNotNullAndUndefined(label) ? (
            label
          ) : (
            <span style={{ opacity: 0.2 }}>–</span>
          )}
        </Typography>
      );
    };

    return (
      <Stack
        direction='row'
        justifyContent='flex-start'
        sx={{
          ...stackSx,
          minWidth: rowWidth,
        }}
        onClick={onClick}
        className={clsx({
          active: isTaskDetailOpen && item === csvRowItem?.item,
        })}
      >
        {item.date.map((itm) => {
          return renderColumn(itm, {
            width: rowColumnsMinWidth.date,
            minWidth: rowColumnsMinWidth.date,
            sx: {
              borderLeft: 0,
              whiteSpace: 'nowrap',
            },
          });
        })}
        {item.member.map((itm) => {
          return renderColumn(itm, {
            width: rowColumnsMinWidth.member,
            minWidth: rowColumnsMinWidth.member,
          });
        })}
        {item.focus?.map((itm) => {
          return renderColumn(itm, {
            width: rowColumnsMinWidth.focus,
            minWidth: rowColumnsMinWidth.focus,
          });
        })}
        {item.today_win?.map((itm) => {
          return renderColumn(itm, {
            width: rowColumnsMinWidth.today_win,
            minWidth: rowColumnsMinWidth.today_win,
          });
        })}
        {item.did_get_win?.map((itm) => {
          return renderColumn(itm, {
            width: rowColumnsMinWidth.did_get_win,
            minWidth: rowColumnsMinWidth.did_get_win,
          });
        })}
        {item.question?.map((itm) => {
          return renderColumn(itm, {
            flex: 2,
            minWidth: questionMinWidth,
          });
        })}
      </Stack>
    );
  };

  const renderTable = ({
    headers,
    items,
  }: {
    headers: TableHeaders;
    items: TableRowItem[];
  }) => {
    const heightSX = `calc(100vh - ${toolbarHeight + subToolbarHeight}px - ${
      (topPanelHeaderRef.current?.clientHeight ?? 0) + 20
    }px)`;

    // NOTE: (8 * 3 * 2) is the padding for both sides (8:1 is a conversion on sx)
    let paddingSide = 8 * 3;
    let rowMinWidth =
      windowDimension.width -
      drawerWidth -
      paddingSide * 2 -
      (aiAssist.is_open ? aiAssist.width - paddingSide * 0.5 : 0);
    // set the maximum container width
    let containerWidth = rowMinWidth;

    /**
     * Add the dynamic column width for question
     * calculate how much total question columns are
     */
    rowColumnsMinWidth.question =
      questionMinWidth * (headers.question?.length ?? 0);

    const totalMinWidth = Object.values(rowColumnsMinWidth).reduce(
      (p, c) => p + c,
      0,
    );

    if (rowMinWidth <= totalMinWidth) {
      rowMinWidth = totalMinWidth;
    }

    return (
      <Box
        flex={1}
        sx={{
          zIndex: 1,
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : '#fff',
          position: 'relative',
          height: heightSX,
        }}
      >
        <Box
          sx={{
            pb: 3,
          }}
          style={{
            marginLeft: paddingSide,
            marginRight: paddingSide,
          }}
        >
          <Box
            sx={{
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
              background: isDarkMode
                ? `var(--md-sys-color-background-dark)`
                : `var(--md-ref-palette-primary99)`,
              boxShadow: isDarkMode
                ? '0 0 0 1px var(--md-ref-palette-neutral-variant30)'
                : '0 0 0 1px var(--md-ref-palette-neutral-variant80)',
            }}
          >
            <GroupedVirtuoso
              style={{
                width: containerWidth,
                height: heightSX,
              }}
              groupCounts={[items.length]}
              groupContent={() =>
                renderRowItem(headers, {
                  index: 0,
                  header: true,
                  width: rowMinWidth,
                })
              }
              itemContent={(index: number) => {
                const item = items[index];

                if (!item) {
                  return <Box style={{ height: itemMinHeight }} />;
                }

                return renderRowItem(item.item, {
                  index,
                  first: index === 0,
                  last: index === items.length - 1,
                  width: rowMinWidth,
                  onClick: () => {
                    posthog.capture(
                      'reports focus individual answers clicked',
                      item.data,
                    );
                    setCsvRowItem(item);
                    setIsTaskDetailOpen(true);
                  },
                });
              }}
            />
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                position: 'absolute',
                pointerEvents: 'none',
              }}
            >
              {rawSearchAnswers.isLoading && !items.length && (
                <CircularProgress />
              )}
              {!rawSearchAnswers.isLoading && !items.length && (
                <Typography>0 Result Found</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderChipFilters = (
    label: string,
    form: FormStateRet<AutocompleteFormState>,
  ) => {
    const selected = Object.keys(form.formState.checked || {}).filter((key) => {
      return form.formState.checked[key];
    });
    return selected.map((id) => {
      const option = form.formState.optionById[id];
      return (
        <Chip
          key={option.id}
          size='small'
          label={
            <>
              <span style={{ opacity: 0.4 }}>{label}:</span>{' '}
              <strong>{option.value}</strong>
            </>
          }
          sx={{
            fontSize: 12,
            background: 'transparent',
          }}
          onDelete={() => {
            form.updateState((state: AutocompleteFormState) => {
              const stateChecked = state.checked || {};
              const stateOptionById = state.optionById || {};
              const checked = !stateChecked[option.id];
              const newState = {
                ...state,
                checked: {
                  ...stateChecked,
                  [option.id]: checked,
                },
                optionById: {
                  ...stateOptionById,
                  [option.id]: option,
                },
              };
              delete newState.checked[option.id];
              delete newState.optionById[option.id];

              return newState;
            });
          }}
        />
      );
    });
  };

  const renderFilterResults = () => {
    if (!hasActiveFilters) return null;

    return (
      <Stack
        gap={1}
        direction='column'
        alignItems='flex-start'
        sx={{ p: 3, pt: 2, pb: 1.5 }}
      >
        <Stack
          gap={1}
          flex={1}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          flexWrap='wrap'
          style={{ marginLeft: -8 }}
        >
          {renderChipFilters('Member', memberForm)}
        </Stack>
        <Divider sx={{ opacity: 0.8, flex: 1, width: '100%' }} />
        <M3Button
          variant='outlined'
          size='small'
          onClick={() => {
            memberForm.resetState();
          }}
          sx={{
            mt: 0.6,
            height: 30,
          }}
        >
          <Typography fontWeight={500} fontSize={12}>
            Clear Filters
          </Typography>
        </M3Button>
      </Stack>
    );
  };

  const renderHeadline = (questionGroup?: QuestionGroup) => {
    const question = questionGroup?.questions[0];
    return (
      <Box
        sx={{
          p: 2,
          pl: 3,
        }}
      >
        {!!question && (
          <>
            <Typography component='h6' variant='h6' fontSize={18}>
              {question.question}
            </Typography>
            <Typography
              component='div'
              variant='body1'
              fontSize={14}
              sx={{ opacity: 0.8 }}
            >
              {question.label}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  /**
   * Convert the raw data into a json with dynamic headers and columns
   */
  const csvResults: {
    headers: TableHeaders;
    items: TableRowItem[];
    members: IterableObject<string>;
  } = useMemo(() => {
    return parseRawData(
      isWinQuestion ? rawDidGetWin.data : rawSearchAnswers.data,
    );
    // eslint-disable-next-line
  }, [isWinQuestion, users, rawSearchAnswers.data, rawDidGetWin.data]);
  // set the data of the csv results so we have some context
  const rawDataToAiAssistData = getRawDataToAiAssistData(csvResults);
  aiAssist.systemPromptRef.current = getSearchAnswerSystemPrompt(
    rawDataToAiAssistData,
  );

  /**
   * When changing workspaces or selected question is changed
   * reset the members selected and all the members stored
   */
  useEffect(() => {
    memberForm.resetState();
    setMembersDict({});
    aiAssist.reset();
    aiAssist.set({
      is_open: true,
      data: {
        model: OpenAIModelID.GPT_4_O,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current!.queryName || current!.name, selectedQuestion]);

  /** setting the assist is_open to true on first load of the SearchAnswers page */
  useEffect(() => {
    aiAssist.set({
      is_open: true,
      data: {
        model: OpenAIModelID.GPT_4_O,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMembersDict((members) => ({ ...members, ...csvResults.members }));
  }, [csvResults, setMembersDict]);

  useEffect(() => {
    const startDownloadLink = (data: string) => {
      const { headers, items } = parseRawData(data);
      const headerKeys = Object.values(headers).flat();
      const exportItems: IterableObject[] = [];
      /**
       * Get all the items to be exported into a normalize json array
       */
      items.forEach((item) => {
        let itemValues = Object.values(item.item).flat();
        let exportItem: IterableObject = {};

        headerKeys.forEach((hk, hkIdx) => {
          exportItem[hk.key] = itemValues[hkIdx].label;
        });

        exportItems.push(exportItem);
      });
      /**
       * Convert the json array to csv format with keys and headers
       */
      const csvHeaders = headerKeys.map((hk) => hk.label);
      const csvHeaderKeys = headerKeys.map((hk) => hk.key);

      exportLinkToCSV({
        csvHeaders,
        csvHeaderKeys,
        data: exportItems,
        filename: `answers-${current!.name}-${moment().format('YYYY-MM-DD')}`,
      });
    };

    if (downloadExportSearchAnswers.isSuccess) {
      startDownloadLink(downloadExportSearchAnswers.data);
      downloadExportSearchAnswers.remove();
    }

    if (downloadDidGetWin.isSuccess) {
      startDownloadLink(downloadDidGetWin.data);
      downloadDidGetWin.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWinQuestion,
    current!.queryName || current!.name, // eslint-disable-line react-hooks/exhaustive-deps
    downloadExportSearchAnswers.isSuccess,
    downloadDidGetWin.isSuccess,
  ]);

  useEffect(() => {
    setQuestionsParams({
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState]);

  useEffect(() => {
    let result = csvToJson<CSVDidWinItemResponse>(rawDidGetWin.data as string);
    setUniqueIdsToFetch({
      user_ids: result.map((item) => item.user_id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawDidGetWin.data]);

  return (
    <>
      <DocumentTitle title='Words' trailingTitle='Focus' />
      <MainContainer
        sx={{
          maxWidth: null,
          p: 0,
          pt: 0,
          pb: 0,
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : '#fff',
          position: 'relative',
          minHeight: `calc(100vh - ${subToolbarHeight}px)`,
          overflow: 'hidden',
        }}
      >
        <AppActionToolbar>
          <FilterColumnPanel
            name='date'
            label='Date'
            displayValue={`${moment(dateForm.formState.start).format(
              'MMM D, YYYY',
            )} - ${moment(dateForm.formState.end).format('MMM D, YYYY')}`}
            sx={{ ml: -1 }}
            paperSx={{ ml: 1 }}
            textFieldSx={{ width: 240 }}
          >
            <DateRangeSelectionPopover
              startDate={dateForm.formState.start}
              endDate={dateForm.formState.end}
              onChange={({ start, end }) => {
                posthog.capture('reports focus date filter clicked');
                dateForm.updateState((state) => ({ ...state, start, end }));
              }}
            />
          </FilterColumnPanel>
          <AutocompleteSelectionPopover
            width={240}
            title='Members'
            placeholder='Select members'
            options={membersOptions}
            form={memberForm}
            onEventClick={() =>
              posthog.capture('reports focus members filter clicked')
            }
          />
          <M3IconButton
            size='large'
            sx={{ ml: 1.3, width: 50, height: 50 }}
            disabled={
              downloadExportSearchAnswers.isFetching ||
              downloadDidGetWin.isFetching
            }
            onClick={() => {
              posthog.capture('reports focus export clicked');

              isWinQuestion
                ? downloadDidGetWin.refetch()
                : downloadExportSearchAnswers.refetch();
            }}
          >
            {downloadExportSearchAnswers.isLoading ||
            downloadDidGetWin.isFetching ? (
              <CircularProgress size={24} />
            ) : (
              <FileDownloadOutlinedIcon />
            )}
          </M3IconButton>
          <Box display='flex' justifyContent='flex-end' flex={1}>
            <M3Button
              onClick={() => {
                posthog.capture('reports focus assist clicked');
                aiAssist.open();
              }}
            >
              <EditOutlinedIcon />
              Askly
            </M3Button>
          </Box>
        </AppActionToolbar>
        <AIChatSideSheet
          key={selectedQuestion?.id}
          inPage
          withToolbar
          withSubToolbar
          title={aiAssist.title}
          headerSx={{
            height: 'initial',
          }}
          aiAssist={aiAssist}
          onClose={aiAssist.close}
        >
          <Box
            sx={{
              width: aiAssist.is_open
                ? `calc(100% - ${aiAssist.width - 24}px)`
                : undefined,
            }}
          >
            <Box ref={topPanelHeaderRef}>
              {renderFilterResults()}
              {renderHeadline(selectedQuestion)}
            </Box>
            {renderTable(csvResults)}
          </Box>
        </AIChatSideSheet>
      </MainContainer>
      <Modal open={isTaskDetailOpen}>
        {csvRowItem ? (
          <TaskDetailFetcher
            isWinQuestion={isWinQuestion}
            item={csvRowItem.data!}
            close={() => {
              setIsTaskDetailOpen(false);
            }}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default SearchAnswers;

/**
 * Task Detail Component
 * TODO: Need to fix when all fetched done and there's an error
 * the page blocked with modal with no way to close it
 */
const TaskDetailFetcher = forwardRef(
  (
    {
      item,
      close,
      isWinQuestion,
    }: {
      item: CSVItemResponse;
      isWinQuestion?: boolean;
    } & ModalCardViewCloseProps,
    ref,
  ) => {
    const { getUser, setUniqueIdsToFetch } = useUserProvider();
    const eodReporting = useReportingReport({ id: +item.report_id });
    const sodId = eodReporting.data?.plan ?? eodReporting.data?.id;
    const teamManifestDailyReport = useTeamManifestDailyReportById(
      { id: sodId },
      { enabled: !!sodId },
    );
    const dailyReport = parseDailyReportItemResponse(
      teamManifestDailyReport.data,
    );
    const eod = dailyReport?.reports.find((report) => report.type === 'EOD');
    const task = (eod?.data as DailyReportEODData)?.kpis.find((kpi) => {
      let taskId = kpi.task_id || kpi.taskId;
      let kpiTaskIdNum = +taskId;
      let itemFocusIdNum = +item.focus_id;
      // Matching using Time Tracker ID
      if (kpiTaskIdNum && itemFocusIdNum && kpiTaskIdNum === itemFocusIdNum) {
        return true;
      }
      // matching by using Time Doctor ID
      return taskId === item.focus_id;
    });

    const user = getUser('employee_id', item.member_id);
    const renderLoading = (
      isLoading?: boolean,
      dailyReport?: DailyReportItemExpanded | null,
      task?: DailyReportEODDataKPI | null,
    ) => {
      return (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            position: 'absolute',
            pointerEvents: 'none',
          }}
        >
          {isLoading && !dailyReport && (
            <CircularProgress sx={{ color: '#fff' }} />
          )}
          {!isLoading && (!dailyReport || (!task && !isWinQuestion)) && (
            <Typography>0 Result Found</Typography>
          )}
        </Box>
      );
    };

    const renderError = () => {
      return (
        <ModalCardViewError title='Task Not Found' close={close}>
          <Error
            title='Task Not Found'
            message={<>We are unable to view the requested page.</>}
            sx={{ pt: '8vh' }}
          />
        </ModalCardViewError>
      );
    };

    useEffect(() => {
      setUniqueIdsToFetch({
        employee_ids: [item.member_id],
      });
    }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

    if (teamManifestDailyReport.isLoading || eodReporting.isLoading) {
      return renderLoading(
        teamManifestDailyReport.isLoading || eodReporting.isLoading,
        dailyReport,
        task,
      );
    }

    if (!item || !dailyReport || !user || (!task && !isWinQuestion)) {
      return renderError();
    }

    if (isWinQuestion) {
      return <TeamManifestModalView dailyReport={dailyReport} close={close} />;
    }

    return (
      <TaskDetailModalViewBase
        sodId={eodReporting.data?.plan}
        date={dailyReport.shift_date}
        user={user}
        task={task!}
        close={close}
      />
    );
  },
);

type RawDataToAiAssistDataProps = {
  data?: {
    date: string;
    member: string;
    json?: QuestionJSON[];
    more_info?: KPIResource[];
  }[];
  focusArea?: string;
};
function getSearchAnswerSystemPrompt({
  data = [],
  focusArea,
}: RawDataToAiAssistDataProps) {
  /**
   * Format the data reducing duplicate words especially long question
   *  [{
   *    question: '',
   *    answers: [{
   *      date:
   *      member:
   *      answer:
   *    }]
   *  }]
   */
  const questionDataMap: IterableObject<{
    question: string;
    answers: {
      date: string;
      member: string;
      answer: string;
      more_info?: KPIResource[];
    }[];
  }> = {};

  data.forEach((item) => {
    (item.json ?? []).forEach((q) => {
      const questionEntity = questionDataMap[q.question] || {
        question: q.question,
        answers: [],
      };

      const modifiedMoreInfo = item.more_info?.map((obj) => {
        const newObj = { ...obj };
        delete newObj.date;
        delete newObj.go2_member_id;
        delete newObj.sod_id;
        delete newObj.task_id;
        delete newObj.type;

        return newObj;
      });

      questionEntity.answers.push({
        date: item.date,
        member: item.member,
        answer: q.answer,
        more_info: modifiedMoreInfo,
      });

      questionDataMap[q.question] = questionEntity;
    });
  });

  let dataText = JSON.stringify(Object.values(questionDataMap));

  return `
  As an AI, your task is to analyze and summarize this data: ${dataText} from support team member's end-of-day reports, organized by date and focused on ${focusArea}. This data may include various types of information such as notes from 1:1s, meetings, call outs, areas of concern, and individual team member's performance. When creating the report, consider the following adaptable guidelines to make the information most actionable for a manager:

  1. Identify trends and patterns within the data, taking into account the unique characteristics of the focus area and any significant changes or anomalies.
  2. Focus on the most relevant indicators for the focus area, both quantitative and qualitative, adjusting your analysis as needed based on the context.
  3. Prioritize actionable insights that can lead to improvements or adjustments in the team's work, providing specific recommendations where possible.
  4. Be prepared to adapt your analysis to the specific context of the focus area, using your best judgment to make the information more relevant and useful for the manager.
  5. Review the quality of individual team member's reports, adapting your approach to call out exceptional insights or areas where guidance may be needed.
  6. Summarize key takeaways from various types of communication, emphasizing any areas of concern or items that require immediate attention, and adjusting your focus based on the information provided.

  After generating the summarized report, always remember to prompt the user for any follow-up questions or additional information they may need. This allows them to dive deeper into the data and gain a better understanding of their team's performance in the specific focus area.

  Additionally, if you would like some more context before sending your initial findings, ask them actionable questions that will help you better interpret the data. 

  Always provide your responses in markdown.
  `;
}
