import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import ContentCollections from './ContentCollections';
import ContentCollectionDetail from './ContentCollectionDetail';
import ContentArticles from './ContentArticles';
import ContentArticleDetail from './ContentArticleDetail';
import ContentAssign from './ContentAssign';
import ContentAssignDetail from './ContentAssignDetail';
import ContentAssignDetailCollection from './ContentAssignDetailCollection';

import { useIntercomProvider } from '../providers/intercom/intercom';
import {
  useIntercomAllData,
  useIntercomCollectionItems,
} from '../providers/intercom';
import { useIntercomAdmins, useIntercomCollections } from '../hooks/intercom';
import { useHasViewPermission } from '../components/HasViewPermission';
import { useWorkspaceProvider } from '../providers/workspace/workspace';

type Props = {};

const Content = (props: Props) => {
  const { pathname } = useLocation();
  const segments = pathname.split('/').filter((s) => !!s);
  const [, segment, id, nextSegment, nextId] = segments;

  const { current: currentWorkspace } = useWorkspaceProvider();
  const hasViewPermission = useHasViewPermission({ roles: ['developer'] });

  const { collections, admins } = useIntercomProvider();
  const collectionItems = useIntercomCollectionItems({ collections, admins });

  useIntercomAllData({
    useIntercomAdminsHook: useIntercomAdmins,
    useIntercomCollectionsHook: useIntercomCollections,
  });

  if (!hasViewPermission) {
    return <Navigate to={`/manifests/${currentWorkspace!.id}`} />;
  }

  if (segment === 'collection') {
    if (id) {
      return <ContentCollectionDetail key={id} />;
    } else {
      return <ContentCollections items={collectionItems} />;
    }
  }

  if (segment === 'article') {
    if (nextSegment === 'collection') {
      if (nextId) {
        return <ContentCollectionDetail key={`${id}_${nextId}`} />;
      } else {
        return <Navigate to={`/content/article/${id}`} />;
      }
    }
    if (id) {
      return <ContentArticleDetail key={id} />;
    } else {
      return <ContentArticles />;
    }
  }

  if (segment === 'target-audience') {
    if (nextSegment === 'collection') {
      if (nextId) {
        return <ContentAssignDetailCollection />;
      } else {
        return <Navigate to={`/content/target-audience/${id}`} />;
      }
    }
    if (id) {
      return <ContentAssignDetail key={id} />;
    } else {
      return <ContentAssign />;
    }
  }

  return <Navigate to='/content/collection' />;
};

export default Content;
