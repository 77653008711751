import React, { useEffect } from 'react';

import MyDayCard from '../MyDayCard';

import { useMetadataProvider } from '../../providers/metadata/metadata';
import { useCurrentProfile } from '../../hooks/profile';
import { DailyReportItemExpanded } from '../../utils/report';
import { useUserProvider } from '../../providers/user/user';

type Props = {
  dailyReport: DailyReportItemExpanded;
};

const InboxDailyReportItemDetail = ({ dailyReport }: Props) => {
  const { contentTypes } = useMetadataProvider();
  const { data: currentProfile } = useCurrentProfile();
  const { setUniqueIdsToFetch } = useUserProvider();

  useEffect(() => {
    if (dailyReport?.submitted_by?.id) {
      setUniqueIdsToFetch({
        employee_ids: [dailyReport.submitted_by.id],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyReport?.submitted_by?.id]);

  return (
    <>
      <MyDayCard
        openDefault
        withToggle={false}
        item={dailyReport}
        contentType={contentTypes.report}
        currentProfile={currentProfile}
      />
      <br />
      <br />
      <br />
    </>
  );
};

export default InboxDailyReportItemDetail;
