import { Stack, Typography } from '@mui/material';
import React, { forwardRef, PropsWithChildren } from 'react';

import ModalCardView, { ModalCardViewCloseProps } from './ModalCardView';

type Props = ModalCardViewCloseProps &
  PropsWithChildren & {
    title?: string;
  };

export function ModalCardViewErrorBase({ title, close, children }: Props) {
  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            {title}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
      sx={{ maxWidth: 620 }}
    >
      {children}
    </ModalCardView>
  );
}

const ModalCardViewError = forwardRef((props: Props, ref) => (
  <ModalCardViewErrorBase {...props} />
));

export default ModalCardViewError;
