import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './Intercom.css';
import * as intercom from '../../services/intercom';
import { IntercomAdmin, IntercomCollection } from '../../types/intercom';
import { IntercomAllDataProps } from '.';
import { useCurrentProfile } from '../../hooks/profile';
import { ReactRenderElement } from '../../types/types';

type IntercomProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type IntercomInitialState = {
  search: string;
  collections: IntercomCollection[];
  admins: IntercomAdmin[];
  supportUnreadCount: number;
  intercomLoaded: boolean;
};
/**
 * Reducers that mutate the state
 */
type IntercomReducers = {
  setSearch: (search: string) => void;
  setAllData: (data: IntercomAllDataProps) => void;
};
/**
 * Single store
 */
type IntercomStore = IntercomInitialState & IntercomReducers;
/**
 * Initial state / store
 */
const initialStore: IntercomStore = {
  search:
    new URLSearchParams(window.location.search.replace('?', '')).get(
      'search',
    ) ?? '',
  collections: [],
  admins: [],
  supportUnreadCount: 0,
  intercomLoaded: false,
  setSearch: (search: string) => {
    throw new Error('Implementation required');
  },
  setAllData: (data: IntercomAllDataProps) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const IntercomContext = createContext<IntercomStore>(initialStore);

export function useIntercomProvider(): IntercomStore {
  return useContext(IntercomContext);
}

export function IntercomProvider({ children }: IntercomProviderProps) {
  const [state, setState] = useState<IntercomStore>(initialStore);
  const { pathname } = useLocation();
  const { data: userProfile } = useCurrentProfile({});

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function setSearch(search: string = '') {
    setState((state) => ({ ...state, search }));
  }

  function setAllData(data: IntercomAllDataProps) {
    setState((state) => ({ ...state, ...data }));
  }

  /**
   * Add a listener for when there's a new message comes in from the intercom
   */
  function showHideMessagesInit(
    s: IntercomStore,
    init?: boolean,
    show?: boolean,
  ) {
    /**
     * NOTE: Always show the messages in background, this prevents intercom to
     * read immediately the unread messages when loaded
     */
    if ((!s.intercomLoaded && init) || show) {
      intercom.emit('showMessages');
    }
    /**
     * NOTE: Intended to use window.location here to check route
     */
    if (window.location.pathname.includes('/inbox/support')) {
      intercom.emit('show');
    } else {
      intercom.emit('hide');
    }
  }

  function onUnreadCountChange(unreadCount: number) {
    setState((state) => {
      showHideMessagesInit(state, true);
      return {
        ...state,
        intercomLoaded: true,
        supportUnreadCount: unreadCount,
      };
    });
  }

  /**
   * Define all side effects here...
   */
  useEffect(() => {
    if (!!userProfile) {
      intercom.toggleIntercomNoAnimation('show');
      intercom.init(userProfile);
      intercom.emit('onUnreadCountChange', onUnreadCountChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!userProfile]);

  /**
   * Intercom product tour don't have any listener when the tour ended, we need
   * to listen when it's being removed to the DOM
   */
  useEffect(() => {
    let observer: MutationObserver = new MutationObserver(
      (mutations: MutationRecord[], observer: MutationObserver) => {
        /**
         * Listen for elements that's being added / removed from body
         * since intercom doesn't provide callback when tour ended
         */
        for (let mutation of mutations) {
          let nodes: string[] = (
            [
              ...Array.from(mutation.addedNodes),
              ...Array.from(mutation.removedNodes),
            ] as HTMLElement[]
          ).map((node) => node.id);
          nodes = Array.from(new Set(nodes));

          if (nodes.indexOf('intercom-positioner-tree') > -1) {
            showHideMessagesInit(state, false, true);
          }
        }
      },
    );
    observer.observe(document.body, { childList: true });

    return () => {
      observer && observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, userProfile]);

  /**
   * This hides the intercom immediately when in not in support to avoid the
   * UI glitch when route changes
   */
  useEffect(() => {
    if (state.intercomLoaded) {
      showHideMessagesInit(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.intercomLoaded, pathname]);

  return (
    <IntercomContext.Provider
      value={{
        ...state,
        setSearch,
        setAllData,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
}
