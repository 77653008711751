import React from 'react';
import { AppBar, Badge, Box, Stack, SxProps } from '@mui/material';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import AppBarControlPanel from '../AppBarControlPanel';
import AppToolbar from '../AppToolbar';
import { M3Button, M3IconButton } from '../M3/M3Button';
import { workspaceWidth } from '../WorkspaceMenuList';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import { useAuthForm } from '../../providers/auth/auth';

type Props = {
  children?: any;
};

const menuIconButtonSx: SxProps = {};

function AppNavigationBar({ children }: Props) {
  let { logout } = useAuthForm();
  const { pathname } = useLocation();
  const {
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
    isTopBannerVisible,
    mediaQuery,
    setDarkMode,
    isDrawerOpen,
    setIsDrawerOpen,
    updateActionKeyCounter,
  } = useAppProvider();
  const { supportUnreadCount } = useIntercomProvider();

  const menuBadgeActive = !!supportUnreadCount;

  const onLogout = () => {
    logout();
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const standaloneMenus: MenuItem[] = [
    {
      id: 'dark-mode-switch',
      navigate: false,
      name: isDarkMode ? 'Light Mode' : 'Dark Mode',
      icon: isDarkMode ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />,
      onClick: () => {
        setDarkMode(!isDarkMode);
        updateActionKeyCounter('toggle_switch_dark_mode');
      },
    },
  ];

  return (
    <>
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          top: isTopBannerVisible ? toolbarHeight : 0,
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <AppToolbar
          style={{
            paddingLeft: 0,
            background: applyOpacityOnColor(
              isDarkMode
                ? palette['md.ref.palette.primary80']
                : palette['md.ref.palette.primary40'],
              getSurfaceOpacityByElevation(2),
            ),
          }}
        >
          <Box flex={1} display='flex' alignItems='center'>
            <Box
              sx={{
                height: toolbarHeight,
                width: workspaceWidth,
                minWidth: workspaceWidth,
                background: isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : 'var(--md-sys-color-background-light)',
                mr: 3,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: applyOpacityOnColor(
                    isDarkMode
                      ? palette['md.ref.palette.primary80']
                      : palette['md.ref.palette.primary40'],
                    getSurfaceOpacityByElevation(2),
                  ),
                }}
              >
                {mediaQuery.mobile && (
                  <Box position='relative'>
                    <M3IconButton onClick={toggleDrawer}>
                      <MenuOutlinedIcon />
                    </M3IconButton>
                    {menuBadgeActive && (
                      <Badge
                        color='primary'
                        variant='dot'
                        sx={{
                          top: 8,
                          right: 8,
                          position: 'absolute',
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Stack
            gap={1}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            <M3Button
              color='secondary'
              variant='outlined'
              sx={{ mr: 1.5 }}
              onClick={() =>
                window.open(process.env.REACT_APP_GO2_CHATGPT_URL!)
              }
            >
              &nbsp;Go2bots&nbsp;
            </M3Button>
            {standaloneMenus.map((menu: MenuItem) => {
              return (
                <AppBarControlPanel
                  key={menu.id}
                  tooltip={{
                    title: menu.name as string,
                    placement: 'bottom',
                  }}
                  active={pathname.includes(menu.basePath!)}
                  redirectTo={menu.path}
                  icon={menu.icon}
                  onClick={menu.onClick}
                  iconButtonSx={menuIconButtonSx}
                />
              );
            })}
          </Stack>
          {mediaQuery.mobile ? (
            <M3IconButton onClick={onLogout}>
              <LogoutIcon />
            </M3IconButton>
          ) : (
            <M3Button
              sx={{
                pl: 2,
                pr: 2,
              }}
              onClick={onLogout}
            >
              <LogoutIcon />
              Log out
            </M3Button>
          )}
        </AppToolbar>
      </AppBar>
      <AppToolbar />
    </>
  );
}

export default AppNavigationBar;
