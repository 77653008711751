import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Modal, Stack, Typography } from '@mui/material';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import { useForm } from '../components/BasicForm';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import ScheduleUserList from '../components/Schedule/ScheduleUserList';
import TemporaryScheduleModalView from '../components/Schedule/TemporaryScheduleModalView';
import JobBreakPolicy from '../components/TeamSettings/JobDescription/JobBreakPolicy';
import Timezone from '../components/Util/Timezone';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useGo2Members } from '../hooks/member';
import { useUserProvider } from '../providers/user/user';
import { UserMetadata } from '../types/profile';
import { getWeekRange } from '../utils/date';
import { useCurrentProfile } from '../hooks/profile';

type Props = {};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

const TeamSchedule = (props: Props) => {
  const { current } = useWorkspaceProvider();
  const { teamScheduleParams, setTeamScheduleParams } = useMetadataProvider();
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();

  const { data: currentProfile } = useCurrentProfile();

  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const [isTemporaryScheduleOpen, setIsTemporaryScheduleOpen] = useState(false);
  const [changeRequestCounter, setChangeRequestCounter] = useState(0);

  const currentUser = getUser('user_id', currentProfile?.id);

  const dateForm = useForm<DateFormState>({
    start: teamScheduleParams.date_start,
    end: teamScheduleParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });

  const divisionGo2Members = useGo2Members({
    division: current!.id === 'my-squad' ? undefined : +current!.id,
    employee_ids: current!.id === 'my-squad' ? current!.memberId : undefined,
    limit: 300,
    is_active: true,
  });
  const userList = useMemo(() => {
    let usersMetadata: UserMetadata[] = [];
    divisionGo2Members.data?.results.forEach((item) => {
      usersMetadata.push(getUser('staff_id', +item.id)!);
    });
    return usersMetadata.filter((u) => !!u);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, divisionGo2Members.data]);

  const [range, setRange] = useState(() => [
    getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
      'YYYY-MM-DD',
    ),
    getWeekRange(moment().endOf('week').add(1, 'day')).end.format('YYYY-MM-DD'),
  ]);

  const isToday = moment();
  const isCurrentWeek =
    isToday.isSameOrAfter(range[0]) && isToday.isSameOrBefore(range[1]);

  const handlePrevWeek = () => {
    setRange((range) =>
      range.map((d) => moment(d).subtract(1, 'week').format('YYYY-MM-DD')),
    );
  };

  const handleNextWeek = () => {
    setRange((range) =>
      range.map((d) => moment(d).add(1, 'week').format('YYYY-MM-DD')),
    );
  };

  const handleOnResetDateToThisWeek = () => {
    setRange(() => [
      getWeekRange(moment().endOf('week').add(1, 'day')).start.format(
        'YYYY-MM-DD',
      ),
      getWeekRange(moment().endOf('week').add(1, 'day')).end.format(
        'YYYY-MM-DD',
      ),
    ]);
  };

  useEffect(() => {
    setTeamScheduleParams({
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState.start, dateForm.formState.end]);

  useEffect(() => {
    if (divisionGo2Members.data) {
      let user_ids: number[] = [];
      let staff_ids: number[] = [];
      divisionGo2Members.data.results.forEach((item) => {
        staff_ids.push(+item.id);
        user_ids.push(+item.reports_to);
      });
      setUniqueIdsToFetch({ staff_ids, user_ids });
    }
    // eslint-disable-next-line
  }, [divisionGo2Members.data]);

  return (
    <>
      <DocumentTitle title='Schedule' trailingTitle='Team' />
      <MainContainer
        ref={mainContainerRef}
        sx={{
          maxWidth: null,
        }}
      >
        <AppActionToolbar>
          <Stack direction='row' gap={2} alignItems={'center'}>
            <M3IconButton
              onClick={handlePrevWeek}
              sx={{
                marginLeft: -2,
              }}
            >
              <ArrowLeftOutlinedIcon sx={{ left: -1, position: 'relative' }} />
            </M3IconButton>
            <FilterColumnPanel
              name='date'
              label='Date'
              displayValue={`${moment(range[0]).format(
                'MMM D, YYYY',
              )} - ${moment(range[1]).format('MMM D, YYYY')}`}
              // sx={{ mx: 0.4 }}
              paperSx={{ ml: 1 }}
              textFieldSx={{ width: 240, pointerEvents: 'none' }}
            >
              {/* <DateRangeSelectionPopover
                startDate={dateForm.formState.start}
                endDate={dateForm.formState.end}
                onChange={({ start, end }) => {
                  dateForm.updateState((state) => ({ ...state, start, end }));
                }}
              /> */}
            </FilterColumnPanel>
            <M3IconButton onClick={handleNextWeek}>
              <ArrowLeftOutlinedIcon
                sx={{
                  left: 1,
                  transform: 'rotateZ(180deg)',
                  position: 'relative',
                }}
              />
            </M3IconButton>
            {!isCurrentWeek && (
              <M3Button onClick={handleOnResetDateToThisWeek}>
                This week
              </M3Button>
            )}
          </Stack>
          <Typography flex={1}></Typography>
          <Stack direction='row' gap={2} alignItems={'center'}>
            {!!current && current.id !== 'my-squad' && (
              <JobBreakPolicy divisionId={+current.id} />
            )}
            <M3Button
              color='secondary'
              variant='outlined'
              sx={{ mr: 1.5 }}
              onClick={() => setIsTemporaryScheduleOpen(true)}
            >
              &nbsp;Request Temporary Schedule&nbsp;
            </M3Button>
          </Stack>
        </AppActionToolbar>
        <Timezone />
        <br />
        <ScheduleUserList
          editable={false}
          onlyOwnUserEditable
          users={userList}
          range={range}
          changeRequestCounter={changeRequestCounter}
        />
      </MainContainer>
      <Modal open={isTemporaryScheduleOpen}>
        <TemporaryScheduleModalView
          isUserLock
          user={currentUser}
          close={() => {
            setIsTemporaryScheduleOpen(false);
            setChangeRequestCounter((c) => ++c);
          }}
        />
      </Modal>
    </>
  );
};

export default TeamSchedule;
