import React, { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import PageLoader from './components/PageLoader';
import Error from './pages/Error';
import AppNavigationBar from './components/UserJobseekers/AppNavigationBar';
import JobSeekerDrawer from './components/UserJobseekers/JobSeekerDrawer';
import StagePipelineBanner from './components/StagePipelineBanner';
import ImpersonationPanel from './components/ImpersonationPanel';
import { useHasViewPermission } from './components/HasViewPermission';
import FileVersioningNotice from './components/FileVersioningNotice';

import { useCurrentProfile } from './hooks/profile';
import { useMediaQuery } from './components/Util/MediaQueryListener';
import { useAppProvider } from './providers/app/app';

type Props = {
  children?: any;
};

/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function UserJobSeekerHome({ children }: Props) {
  const { isLoading, error, data: userProfile } = useCurrentProfile();
  const {
    isDrawerOpen,
    setMediaQuery,
    isTopBannerVisible,
    setIsTopBannerVisible,
  } = useAppProvider();
  const mediaQuery = useMediaQuery(['jobseekers']);
  const hasViewPermission = useHasViewPermission({ roles: ['developer'] });

  /**
   * TODO: Hide for now the jobseekers pizza tracker for now, open only to developers
   */
  useLayoutEffect(() => {
    if (hasViewPermission) {
      setIsTopBannerVisible(true);
      document.body.classList.add('jobseekers-body-top-banner-visible');
    } else {
      setIsTopBannerVisible(false);
      document.body.classList.remove('jobseekers-body-top-banner-visible');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasViewPermission]);

  useLayoutEffect(() => {
    document.body.classList.add('jobseekers-body');
    return () => {
      document.body.classList.remove('jobseekers-body');
    };
  }, []);

  useLayoutEffect(() => {
    if (isDrawerOpen) {
      document.body.classList.add('is-drawer-open');
    } else {
      document.body.classList.remove('is-drawer-open');
    }
    return () => {
      document.body.classList.remove('is-drawer-open');
    };
  }, [isDrawerOpen]);

  useLayoutEffect(() => {
    setMediaQuery(mediaQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaQuery]);

  const renderDrawer = () => {
    return <JobSeekerDrawer />;
  };

  if (!!error) {
    return (
      <Error
        title='User not found'
        message={<>This user no longer exist. Contact your administrator.</>}
      />
    );
  }

  /**
   * Show some loading screen when fetching current employee's profile
   */
  if (isLoading && !userProfile) {
    return <PageLoader />;
  }

  return (
    <>
      <FileVersioningNotice />
      {isTopBannerVisible && <StagePipelineBanner />}
      <AppNavigationBar />
      <Box display='flex' sx={{ flex: 1 }}>
        {renderDrawer()}
        <Outlet />
      </Box>
      <ImpersonationPanel />
    </>
  );
}

export default UserJobSeekerHome;
