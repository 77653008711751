const url = `https://go2cosmosbucket.s3.amazonaws.com`;

export const randomBotTotal = 60;

export function getRandomBotUrl(
  id?: number,
  position: 'left' | 'right' = 'left',
) {
  let x = Math.ceil(Math.random() * randomBotTotal);
  return `${url}/users-upload/images/image${id ?? x}_${position}.png`;
}
