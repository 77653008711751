import { jsonToCsv } from './csv';

type ExportLinkToCSVProps<T> = {
  data: T[];
  filename: string;
  csvHeaders: string[];
  csvHeaderKeys: string[];
};
export function exportLinkToCSV<T = any>({
  data,
  filename,
  csvHeaders,
  csvHeaderKeys,
}: ExportLinkToCSVProps<T>) {
  const csvRaw = jsonToCsv<T>(data, csvHeaders, csvHeaderKeys);
  const url = window.URL.createObjectURL(new Blob([csvRaw]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${filename}.csv`);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
