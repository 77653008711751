import React, { useRef } from 'react';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import { useAppProvider } from '../providers/app/app';
import { Box } from '@mui/material';
import BasicIframe from '../components/BasicIframe';

type Props = {};

const PathBeta = (props: Props) => {
  const { subToolbarHeight } = useAppProvider();
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <DocumentTitle title='Path' />
      <MainContainer
        ref={mainContainerRef}
        sx={{
          maxWidth: null,
          p: 2,
          pt: 1.5,
          position: 'relative',
          height: `calc(100vh - ${subToolbarHeight}px)`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          width='100%'
          sx={{
            height: 0,
            flexGrow: 2,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              top: -12,
              left: -16,
              right: -16,
              bottom: -16,
              position: 'absolute',
            }}
          >
            <BasicIframe
              src={`${process.env.REACT_APP_PATH_BETA_URL}?embedded=true`}
            />
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default PathBeta;
