import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { Moment } from 'moment';

import {
  getUserInitials,
  getUserPhotoUrl,
  getUserProfileDisplayName,
} from '../../../utils/user';
import { UserMetadata } from '../../../types/profile';

type UserAvatarProps = {
  firstColWidth: number;
  firstColHeight: number;
  user: UserMetadata;
  days: Moment[];
};

const UserAvatar: React.FC<UserAvatarProps> = ({
  firstColWidth,
  firstColHeight,
  user,
  days,
}) => {
  return (
    <Box
      style={{
        height: firstColHeight ? firstColHeight : undefined,
      }}
      sx={{
        py: 1,
        pl: 1,
        pb: 0.5,
        width: '100%',
        height: '100%',
        borderRadius: 2,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Typography
        component='div'
        gap={2}
        fontSize={18}
        minWidth={firstColWidth}
        fontWeight={500}
        display='flex'
        alignItems='center'
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
          }}
          src={getUserPhotoUrl(user?.photo_url)}
        >
          {getUserInitials(user?.first_name).initial}
        </Avatar>
        <Box>
          <Box>{getUserProfileDisplayName(user!).fullName}</Box>
        </Box>
      </Typography>
    </Box>
  );
};

export default UserAvatar;
