import React, { useState } from 'react';
import { Box, Input, Link, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import qs from 'qs';
import { useMatch, useNavigate } from 'react-router-dom';

import MainContainer from '../MainContainer';
import BasicForm, { useForm } from '../BasicForm';

import { useIntercomProvider } from '../../providers/intercom/intercom';

type IntercomHeaderProps = {
  withTitle?: boolean;
  title?: string;
  basePath: string;
  withGo2HelpLink?: boolean;
  go2HelpText?: string;
  go2HelpLink?: string;
};

const IntercomHeader = ({
  withTitle = true,
  title = 'Advice and answers from the Go2 Team',
  basePath,
  withGo2HelpLink = true,
  go2HelpText = 'Go2 Help Center',
  go2HelpLink = 'https://support.go2.io/en/',
}: IntercomHeaderProps) => {
  const [isFocus, setIsFocus] = useState<{ [key: string]: boolean }>({});
  const isIntercom = useMatch(basePath);
  const navigate = useNavigate();
  const { search, setSearch } = useIntercomProvider();

  const { formState, handleChange, handleSubmit } = useForm({
    search,
  });

  const onSearchSubmit = handleSubmit(() => {
    setSearch(formState.search);
    navigate(
      `${basePath}?${qs.stringify({
        search: formState.search,
      })}`,
    );
  });

  return (
    <Box
      sx={{
        paddingTop: isIntercom && (withTitle || withGo2HelpLink) ? 2.5 : 0,
        background: 'var(--md-ref-palette-primary40)',
      }}
    >
      <MainContainer
        flex={false}
        sx={{
          maxWidth: 980,
          margin: 'auto',
          color: 'var(--md-ref-palette-primary10)',
          pb: 4,
        }}
      >
        <Stack direction='row' justifyContent='space-between'>
          {withGo2HelpLink && (
            <Link
              underline='none'
              sx={{
                color: 'var(--md-ref-palette-primary100)',
                fontSize: 16,
                fontWeight: 700,
              }}
              target='_blank'
              href={go2HelpLink}
            >
              {go2HelpText}
            </Link>
          )}
        </Stack>
        {withTitle && !!isIntercom && (
          <Typography
            fontSize={30}
            fontWeight={300}
            sx={{
              pt: withGo2HelpLink ? 1 : 0,
              color: 'var(--md-ref-palette-primary100)',
            }}
          >
            {title}
          </Typography>
        )}
        <Box sx={{ position: 'relative', mt: 3 }}>
          <BasicForm onSubmit={onSearchSubmit}>
            <Input
              fullWidth
              disableUnderline
              name='search'
              placeholder='Search for articles...'
              className={clsx('intercom-search-input', {
                'inner-page': !isIntercom,
                active: !!(isFocus.search || formState.search),
              })}
              value={formState.search}
              onChange={handleChange}
              onFocus={() => setIsFocus((s) => ({ ...s, search: true }))}
              onBlur={() => setIsFocus((s) => ({ ...s, search: false }))}
            />
            <div className='intercom-search-icon'></div>
          </BasicForm>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default IntercomHeader;
