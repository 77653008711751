import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, Modal, Stack, Typography } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import { M3Button } from '../components/M3/M3Button';
import InboxNewChatForm from '../components/AIAIAI/InboxNewChatForm';
import AIAIAIUsersList, {
  width as usersListWidth,
} from '../components/AIAIAI/AIAIAIUsersList';
import AIAIAIUserChat from '../components/AIAIAI/AIAIAIUserChat';
import { useHasViewPermission } from '../components/HasViewPermission';

import { useInfinite } from '../hooks/global/useInfinite';
import { useUserProvider } from '../providers/user/user';
import { toURL } from '../utils/url';
import {
  UseGo2BotsMessagesProps,
  useGo2BotsMessageById,
  useGo2BotsMessages,
} from '../hooks/go2bots/messages';
import { Go2BotsMessageItem } from '../types/go2bots';
import * as posthog from '../services/posthog';
import AutocompleteUserSearch, {
  M3UserOptionItem,
} from '../components/Filters/AutocompleteUserSearch';
import { useAppProvider } from '../providers/app/app';

type Props = {};

const InboxChat = (props: Props) => {
  const { isDarkMode } = useAppProvider();
  const { setUniqueIdsToFetch } = useUserProvider();
  const [isNewChatOpen, setIsNewChatOpen] = useState(false);
  const navigate = useNavigate();

  const hasViewPermission = useHasViewPermission({
    roles: ['superuser', 'developer', 'go2bots_admin'],
  });

  const [searchUserId, setSearchUserId] = useState<number | null>(null);

  const infiniteGo2BotsMessages = useInfinite<
    Go2BotsMessageItem,
    UseGo2BotsMessagesProps
  >({
    useQuery: useGo2BotsMessages,
    queryParams: {
      key: 'go2bots_chat_list',
      limit: 30,
      user: searchUserId,
    },
  });
  const { id } = useParams();
  const [chatKeyCounter, setChatKeyCounter] = useState(0);
  const [selectedGo2BotsMessageItem, setSelectedGo2BotsMessageItem] =
    useState<Go2BotsMessageItem | null>(() => {
      return (
        infiniteGo2BotsMessages.data.find((item) => item.id === +id!) ?? null
      );
    });

  const { data: go2botsMessageData } = useGo2BotsMessageById(
    {
      id: +id!,
    },
    {
      enabled: !!id,
    },
  );

  const handleOnSelectGo2BotsMessage = useCallback(
    (item: Go2BotsMessageItem) => {
      setSelectedGo2BotsMessageItem(item);
    },
    [setSelectedGo2BotsMessageItem],
  );

  const handleOnCommentSuccess = useCallback(
    () => {
      infiniteGo2BotsMessages.reset();
    },
    // eslint-disable-next-line
    [],
  );

  const handleOnCreateMessageSuccess = useCallback(
    (data: Go2BotsMessageItem) => {
      setChatKeyCounter((s) => ++s);
      setSelectedGo2BotsMessageItem({ ...data });
      navigate(toURL(`/chat/message/${data.id}`));
      infiniteGo2BotsMessages.reset();
    },
    // eslint-disable-next-line
    [],
  );

  const handleOnSelectUser = useCallback(
    (option?: M3UserOptionItem | null) => {
      setSearchUserId(+option?.id! || null);
      infiniteGo2BotsMessages.reset({
        emptyList: true,
      });
    },
    [setSearchUserId],
  );

  useEffect(() => {
    const user_ids: any[] = [];
    const uniqueUserMapper = (item: Go2BotsMessageItem) => {
      user_ids.push(item.user);
      user_ids.push(item.created_by);
    };

    infiniteGo2BotsMessages.data.map(uniqueUserMapper);

    setUniqueIdsToFetch({ user_ids });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infiniteGo2BotsMessages.data]);

  /**
   * When loading from a chat with already the path with id,
   * let's set as the selected
   */
  useEffect(() => {
    if (!selectedGo2BotsMessageItem && go2botsMessageData) {
      setChatKeyCounter((s) => ++s);
      setSelectedGo2BotsMessageItem({
        ...go2botsMessageData,
      });
    } else if (
      selectedGo2BotsMessageItem &&
      go2botsMessageData &&
      selectedGo2BotsMessageItem?.id === go2botsMessageData?.id
    ) {
      setSelectedGo2BotsMessageItem({
        ...selectedGo2BotsMessageItem,
        ...go2botsMessageData,
      });
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    !selectedGo2BotsMessageItem,
    go2botsMessageData,
    setSelectedGo2BotsMessageItem,
  ]);

  /**
   * When nothing is selected when it first navigated to the inbox chat
   */
  useEffect(() => {
    if (
      !id &&
      !selectedGo2BotsMessageItem &&
      infiniteGo2BotsMessages.data.length
    ) {
      const go2BotsMessageItem = infiniteGo2BotsMessages.data[0];
      navigate(toURL(`/chat/message/${go2BotsMessageItem.id}`));
    }
    // eslint-disable-next-line
  }, [
    id,
    infiniteGo2BotsMessages.data,
    selectedGo2BotsMessageItem,
    setSelectedGo2BotsMessageItem,
  ]);

  useEffect(() => {
    if (selectedGo2BotsMessageItem) {
      posthog.capture('inbox chat message viewed', {
        id: selectedGo2BotsMessageItem.id,
        title: selectedGo2BotsMessageItem.title,
        user: selectedGo2BotsMessageItem.user,
      });
    }
    // eslint-disable-next-line
  }, [selectedGo2BotsMessageItem]);

  if (!hasViewPermission) {
    return <Navigate replace to='/' />;
  }

  return (
    <>
      <DocumentTitle title='Message' trailingTitle='Chat' />
      <MainContainer
        sx={{
          maxWidth: null,
          pt: 0,
          pb: 0,
          pl: 0,
          pr: 0,
        }}
      >
        <AppActionToolbar>
          <Box display='flex' alignItems='center' flex={1}>
            <Typography
              component='div'
              fontSize={22}
              width={usersListWidth - 4 * 3}
              minWidth={usersListWidth - 4 * 3}
              display='flex'
              flexDirection='column'
              ml={-1.5}
            >
              <AutocompleteUserSearch onSelect={handleOnSelectUser} />
            </Typography>
            {selectedGo2BotsMessageItem && (
              <Typography pl={2} component='div' fontSize={18} flex={1}>
                <span style={{ opacity: 0.5, fontSize: 14, fontWeight: 400 }}>
                  Title:
                </span>{' '}
                {selectedGo2BotsMessageItem.title}
              </Typography>
            )}
          </Box>
          <M3Button
            variant='contained'
            color='primary'
            onClick={() => {
              setIsNewChatOpen(true);
              posthog.capture('inbox new chat clicked');
            }}
          >
            <ChatBubbleOutlineOutlinedIcon style={{ fontSize: 20 }} />
            New Chat
          </M3Button>
        </AppActionToolbar>
        <Stack direction='row' justifyContent='flex-start' alignItems='stretch'>
          <AIAIAIUsersList
            scrollCounter={chatKeyCounter}
            items={infiniteGo2BotsMessages.data}
            isLoadingMore={infiniteGo2BotsMessages.isLoadingMore}
            hasReachEnd={infiniteGo2BotsMessages.hasReachEnd}
            onSelect={handleOnSelectGo2BotsMessage}
            onEndReached={infiniteGo2BotsMessages.loadNext}
          />
          {!!selectedGo2BotsMessageItem && (
            <AIAIAIUserChat
              key={`${chatKeyCounter}_${selectedGo2BotsMessageItem.id}`}
              withSentBy
              isAdmin
              go2BotsMessage={selectedGo2BotsMessageItem}
              onCommentSuccess={handleOnCommentSuccess}
            />
          )}
        </Stack>
      </MainContainer>
      <Modal open={isNewChatOpen}>
        <InboxNewChatForm
          close={() => {
            setIsNewChatOpen(false);
            infiniteGo2BotsMessages.reset();
          }}
          onSuccess={handleOnCreateMessageSuccess}
        />
      </Modal>
    </>
  );
};

export default InboxChat;
