import { Fragment, useEffect, useMemo, SyntheticEvent, useState } from 'react';
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Stack,
  SxProps,
  Tooltip,
  FormHelperText,
  Skeleton,
} from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { M3IconButton } from './M3/M3Button';
import M3AlertDialog from './M3/M3Dialogs';
import RichEditor, { RichEditorProps } from './RichEditor/RichEditor';
import RichTextContent from './RichEditor/RichTextContent';

import {
  useComments,
  useDeleteComment,
  usePostComment,
  useUpdateComment,
} from '../hooks/comment';
import { ContentTypeID } from '../types/content-type';
import { getUserInitials } from '../utils/user';
import { useForm } from './BasicForm';
import { CommentItem } from '../types/comment';
import { UserMetadata, UserProfile } from '../types/profile';
import { parseErrorData } from '../utils/response';
import { useUserProvider } from '../providers/user/user';
import { useAppProvider } from '../providers/app/app';
import * as posthog from '../services/posthog';
import * as pusher from '../services/pusher';

type CommentsListProps = {
  currentProfile?: UserProfile;
  objectId: number | string;
  contentType: ContentTypeID;
  formSx?: {
    inputSx?: SxProps;
    submitSx?: SxProps;
    boxSx?: SxProps;
  };
  commentSx?: {
    boxSx?: SxProps;
  };
  placeholder?: string;
  onFocus?: (evt: SyntheticEvent) => void;
  onBlur?: (evt: SyntheticEvent) => void;
  onSuccess?: () => void;
  onCommentSuccess?: () => void;
};

type CommentFormState = {
  content: string;
};
type CommentItemActiveState = {
  [key: string]: boolean;
};
type CommentItemContentState = {
  [key: string]: string;
};
type CommentDeletedItems = {
  [key: string]: boolean;
};

const CommentsList = ({
  currentProfile,
  objectId,
  contentType,
  formSx,
  commentSx,
  onFocus,
  onBlur,
  onSuccess,
  onCommentSuccess,
  placeholder = 'Comment here...',
}: CommentsListProps) => {
  const { isDarkMode } = useAppProvider();
  const { getUser, setUniqueIdsToFetch } = useUserProvider();
  const comments = useComments(
    {
      content_type: contentType,
      object_id: objectId,
      page_size: 100,
    },
    {
      refetchInterval: 300 * 1000,
      enabled: !!(contentType && objectId),
    },
  );
  const { formState, handleChange, resetState, handleSubmit } =
    useForm<CommentFormState>({
      content: '',
    });

  const [editorRef, setEditorRef] = useState<ReactQuill | null>(null);

  const [itemIsActive, setItemIsActive] = useState<CommentItemActiveState>({});
  const [itemContent, setItemContent] = useState<CommentItemContentState>({});
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<CommentItem | null>(null);
  const [deletedItems, setDeletedItems] = useState<CommentDeletedItems>({});

  const postComment = usePostComment();
  const deleteComment = useDeleteComment(currentItem?.id);

  const parsedError = parseErrorData(postComment.error);

  const onSubmit = handleSubmit((data) => {
    if (!data.content) return;

    postComment.mutate({
      content_type: contentType,
      object_id: objectId,
      content: data.content,
    });
  });

  const clearEditor = ({ focus }: { focus?: boolean }) => {
    if (editorRef?.editor) {
      editorRef.editor.setText('');
      focus && editorRef.focus();
    }
  };

  const renderError = (message?: string) => {
    if (!message) return null;

    return (
      <FormHelperText error sx={{ mt: 1.5 }}>
        {message?.replace(/\.+$/g, '.')}
      </FormHelperText>
    );
  };

  const renderForm = () => {
    return (
      <Box
        sx={{ pt: 1, ...formSx?.boxSx }}
        className='rich-editor-comment-form'
      >
        <RichEditor
          withSubmit
          submitOnEnter
          onSetEditorRef={setEditorRef}
          value={formState.content}
          placeholder={placeholder}
          submitDisabled={postComment.isLoading}
          onFocus={() => onFocus?.({} as SyntheticEvent)}
          onBlur={() => onBlur?.({} as SyntheticEvent)}
          onValueChange={(html: string, sanitized: string) => {
            handleChange({
              target: {
                name: 'content',
                value: html,
              },
            });
          }}
          onSubmit={() => {
            posthog.capture('manifest comment submitted', {
              content_type: contentType,
              object_id: objectId,
            });
            onSubmit();
          }}
        />
        {renderError(
          typeof parsedError?.message === 'object'
            ? (parsedError?.message?.message as string)
            : parsedError?.message,
        )}
      </Box>
    );
  };

  const renderEditForm = (id: number, initialValue: string) => {
    return (
      <CommentEditForm
        id={id}
        value={initialValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onCancel={() => setItemIsActive((state) => ({ ...state, [id]: false }))}
        placeholder={placeholder}
        onSuccess={(value) => {
          setItemContent((state) => ({ ...state, [id]: value }));
          setItemIsActive((state) => ({ ...state, [id]: false }));

          comments.refetch();
        }}
      />
    );
  };

  const renderListItemActionButtons = (
    onTakeAction: (action: 'edit' | 'delete') => void,
  ) => {
    const iconButtonSx = {
      width: 28,
      height: 28,
    };
    const iconSx = {
      fontSize: 19,
    };
    return (
      <Stack
        gap={0.5}
        direction='row'
        sx={{
          borderRadius: 10,
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : '#fff',
          boxShadow: `0 1px 2px 0 rgba(60,64,67,.30),0 2px 6px 2px rgba(60,64,67,.15)`,
        }}
      >
        <Tooltip
          title='Edit'
          placement='bottom'
          enterDelay={1000}
          enterNextDelay={1000}
          disableInteractive
        >
          <Box>
            <M3IconButton
              sx={iconButtonSx}
              onClick={() => onTakeAction('edit')}
            >
              <EditOutlinedIcon sx={iconSx} />
            </M3IconButton>
          </Box>
        </Tooltip>
        <Tooltip
          title='Delete'
          placement='bottom'
          enterDelay={1000}
          enterNextDelay={1000}
          disableInteractive
        >
          <Box>
            <M3IconButton
              sx={iconButtonSx}
              onClick={() => onTakeAction('delete')}
            >
              <DeleteOutlineOutlinedIcon sx={iconSx} />
            </M3IconButton>
          </Box>
        </Tooltip>
      </Stack>
    );
  };

  const renderItemSkeleton = () => {
    return (
      <ListItem
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <ListItemAvatar
          sx={{
            minWidth: 46,
          }}
        >
          <Skeleton variant='circular' sx={{ width: 32, height: 32 }} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant='text' sx={{ width: 200 }} />}
          secondary={
            <Skeleton variant='text' sx={{ width: 400, lineHeight: 1.54 }} />
          }
          sx={{
            mt: -0.1,
          }}
        />
      </ListItem>
    );
  };

  const renderList = (items: CommentItem[]) => {
    return (
      <List sx={{ m: 0, p: 0 }}>
        {items.map((userComment, index, arr) => {
          const user =
            getUser('employee_id', userComment.user_employee_id) ??
            getUser('user_id', userComment.user) ??
            ({
              id: userComment.user,
              employee_id: userComment.user_employee_id,
              first_name: userComment.user_first_name,
              last_name: userComment.user_last_name,
            } as Partial<UserMetadata>);
          const itemId = userComment.id;
          const isAuthor = currentProfile?.id === user.id;
          const active = itemIsActive[itemId] && isAuthor;
          const commentValue = itemContent[itemId] ?? userComment.content;

          return (
            <Fragment key={index}>
              <ListItem
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  '.MuiListItemSecondaryAction-root': {
                    top: 20,
                    opacity: 0,
                    pointerEvents: 'none',
                  },
                  '&:not(.active)&:hover': {
                    '.MuiListItemSecondaryAction-root': {
                      opacity: 1,
                      pointerEvents: 'auto',
                    },
                  },
                }}
                secondaryAction={
                  isAuthor &&
                  renderListItemActionButtons((action) => {
                    if (action === 'edit') {
                      setItemIsActive((state) => ({
                        ...state,
                        [itemId]: true,
                      }));
                      setItemContent((state) => ({
                        ...state,
                        [itemId]: commentValue,
                      }));
                    } else if (action === 'delete') {
                      setAlertIsOpen(true);
                      setCurrentItem(userComment);
                    }
                  })
                }
                className={clsx({ active })}
              >
                <ListItemAvatar
                  sx={{
                    minWidth: 46,
                  }}
                >
                  <Avatar
                    src={user.photo_url}
                    sx={{
                      width: 32,
                      height: 32,
                    }}
                  >
                    {
                      getUserInitials(
                        `${user.preferred_name ?? user.first_name}`.trim(),
                      ).initial
                    }
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      mb={0.2}
                      display='flex'
                      alignItems='center'
                      component='div'
                    >
                      <Typography fontSize={14} fontWeight={500}>
                        {user.preferred_name ?? user.first_name}
                      </Typography>
                      &nbsp;
                      <span style={{ opacity: 0.5, fontSize: 12 }}>
                        -{' '}
                        {moment
                          .utc(userComment.modified)
                          .local()
                          .format('MMM D, YYYY')}
                      </span>
                    </Typography>
                  }
                  secondaryTypographyProps={{
                    component: 'div',
                  }}
                  secondary={
                    active ? (
                      renderEditForm(itemId, commentValue)
                    ) : (
                      <RichTextContent content={commentValue} />
                    )
                  }
                  sx={{
                    mt: -0.1,
                    '.secondary-comment': {
                      '& > p': {
                        marginTop: 0.3,
                      },
                    },
                  }}
                />
              </ListItem>
            </Fragment>
          );
        })}
        {comments.isLoading && !items.length && renderItemSkeleton()}
      </List>
    );
  };

  const renderDeleteDialog = () => {
    return (
      <M3AlertDialog
        open={alertIsOpen}
        dialog={{
          title: 'Delete this message permanently?',
          content: (
            <Box
              sx={{
                p: 1,
                pt: 0.2,
                pb: 0.2,
                background: isDarkMode
                  ? 'rgba(255, 255, 255, 0.05)'
                  : '#f5f5f5',
              }}
            >
              <RichTextContent content={currentItem?.content ?? ''} />
            </Box>
          ),
        }}
        actions={{
          cancel: {
            label: 'Cancel',
            onClick: () => setAlertIsOpen(false),
          },
          confirm: {
            label: 'Delete',
            onClick: () => {
              setDeletedItems((state) => ({
                ...state,
                [`${currentItem?.id ?? ''}`]: true,
              }));
              setAlertIsOpen(false);
              deleteComment.mutate(undefined);
            },
          },
        }}
        onClose={() => setAlertIsOpen(false)}
      />
    );
  };

  const commentItems: CommentItem[] = useMemo(() => {
    if (comments.data) {
      return comments.data.results
        .slice(0)
        .filter((item) => !deletedItems[item.id])
        .reverse();
    }

    return [];
  }, [comments.data, deletedItems]);

  useEffect(() => {
    const eventCallback = (data: any) => {
      comments.refetch();
    };
    const events = {
      'comment-created': eventCallback,
      'comment-updated': eventCallback,
      'comment-deleted': eventCallback,
    };

    let unsubscribe = pusher.subscribe(
      `comment_${contentType}_${objectId}`,
      events,
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, objectId]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: commentItems.map((item) => item.user),
      employee_ids: commentItems.map((item) => item.user_employee_id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentItems]);

  useEffect(() => {
    if (comments.isSuccess) {
      onSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments.isSuccess]);

  /**
   * When posting a comment is done
   */
  useEffect(() => {
    if (postComment.isSuccess) {
      comments.refetch();
      resetState();
      postComment.reset();
      onCommentSuccess?.();
      clearEditor({ focus: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postComment.isSuccess]);

  useEffect(() => {
    if (deleteComment.isSuccess) {
      setAlertIsOpen(false);
      setCurrentItem(null);
      deleteComment.reset();
      comments.refetch();
      onCommentSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteComment.isSuccess]);

  return (
    <Box sx={{ p: 2, ...commentSx?.boxSx }}>
      {renderList(commentItems)}
      {renderForm()}
      {renderDeleteDialog()}
    </Box>
  );
};

export default CommentsList;

type CommentEditFormProps = {
  id: number;
  value: string;
  placeholder?: string;
  richEditProps?: RichEditorProps;
  onChange?: (html: string) => void;
  onFocus?: (evt: SyntheticEvent) => void;
  onBlur?: (evt: SyntheticEvent) => void;
  onCancel?: () => void;
  onSuccess?: (value: string) => void;
};
type UpdateCommentParams = {
  content: string;
};
export function CommentEditForm({
  id,
  value: initialValue,
  placeholder,
  onFocus,
  onBlur,
  onCancel,
  onSuccess,
  onChange,
  richEditProps,
}: CommentEditFormProps) {
  const [value, setValue] = useState(initialValue);
  const [changed, setChanged] = useState(false);
  const updateComment = useUpdateComment<UpdateCommentParams>(id);

  const isSubmitDisabled =
    !value || value === initialValue || !changed || updateComment.isLoading;

  const parsedError = parseErrorData(updateComment.error);

  const [editorRef, setEditorRef] = useState<ReactQuill | null>(null);

  const clearEditor = ({ focus }: { focus?: boolean }) => {
    if (editorRef?.editor) {
      editorRef.editor.setText('');
      focus && editorRef.focus();
    }
  };

  const onSubmit = (evt?: SyntheticEvent) => {
    if (isSubmitDisabled) return;

    updateComment.mutate({ content: value });
  };

  const renderError = (message?: string) => {
    if (!message) return null;

    return (
      <FormHelperText error sx={{ mt: 1.5 }}>
        {message?.replace(/\.+$/g, '.')}
      </FormHelperText>
    );
  };

  useEffect(() => {
    if (updateComment.isSuccess) {
      onSuccess?.(value);
      clearEditor({ focus: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateComment.isSuccess]);

  return (
    <Box sx={{ pt: 0 }}>
      <Box className='rich-editor-comment-form'>
        <RichEditor
          withSaveAndCancel
          submitOnEnter
          onSetEditorRef={setEditorRef}
          value={value}
          placeholder={placeholder ?? 'Write here...'}
          submitDisabled={updateComment.isLoading || isSubmitDisabled}
          onFocus={() => onFocus?.({} as SyntheticEvent)}
          onBlur={() => onBlur?.({} as SyntheticEvent)}
          onValueChange={(html: string, sanitized: string) => {
            setValue(html);
            onChange?.(html);
            setChanged(true);
          }}
          onSubmit={() => onSubmit()}
          onCancel={onCancel}
          {...richEditProps}
        />
      </Box>
      {renderError(
        typeof parsedError?.message === 'object'
          ? (parsedError?.message?.message as string)
          : parsedError?.message,
      )}
    </Box>
  );
}
