// round up number to nearest quantifier
export function roundUpNearest(
  num: number | string,
  nearest: number | string = 10,
) {
  nearest = Number(nearest);
  return Math.ceil(Number(num) / nearest) * nearest;
}

export function getOrdinalSuffix(number: number, suffixOnly: boolean = false) {
  let suffix = '';

  if (number % 100 >= 11 && number % 100 <= 13) {
    suffix = 'th';
  } else if (number % 10 === 1) {
    suffix = 'st';
  } else if (number % 10 === 2) {
    suffix = 'nd';
  } else if (number % 10 === 3) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }

  return suffixOnly ? suffix : number + suffix;
}
