import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MainContainer from '../components/MainContainer';
import InboxPTOItemDetail from '../components/Inbox/InboxPTOItemDetail';
import WindowScrollTop from '../components/WindowScrollTop';
import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import InboxPTOItemDetailSkeleton from '../components/Inbox/InboxPTOItemDetailSkeleton';
import Error from './Error';

import { usePTOByID, usePTORequestAction } from '../hooks/pto';
import { parseURL } from '../utils/url';
import { PTOAction } from '../types/pto';
import { ReactRenderElement } from '../types/types';
import { useUserProvider } from '../providers/user/user';
import { UserMetadata } from '../types/profile';

type Props = {
  ptoIdSrc?: string;
  isFromAllPage?: boolean;
  nextPrevNavigation?: ReactRenderElement;
};
type PTORouteParams = {
  ptoId: string;
};
export type PTOActionPayload = {
  action: PTOAction | null;
  note?: string;
};

const InboxRequestDetail = ({ ptoIdSrc, nextPrevNavigation }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ptoId } = useParams<PTORouteParams>();
  const { getUser, setUniqueIdsToFetch } = useUserProvider();

  const currentPtoId = ptoIdSrc ?? ptoId!;
  const ptoById = usePTOByID(
    { id: currentPtoId },
    { refetchInterval: 60 * 1000 },
  );
  const pto = ptoById.data;
  const ptoUser = pto?.user || pto?.metadata?.user;

  const user =
    getUser('staff_id', ptoUser?.id) ??
    getUser('employee_id', ptoUser?.employee_id) ??
    ({
      employee_id: ptoUser?.employee_id,
      first_name: ptoUser?.first_name,
      last_name: ptoUser?.last_name,
    } as Partial<UserMetadata>);

  const [actionPayload, setActionPayload] = useState<PTOActionPayload | null>(
    null,
  );

  const ptoRequestAction = usePTORequestAction({
    id: currentPtoId,
    action: actionPayload?.action,
  });

  const renderContent = () => {
    if (ptoById.isLoading && !ptoById.data) {
      return <InboxPTOItemDetailSkeleton />;
    }

    if (!pto) return <Error title='Time Off Request Not Found' />;

    return (
      <InboxPTOItemDetail
        pto={pto!}
        user={user}
        action={actionPayload?.action}
        isTakingAction={ptoRequestAction.isLoading}
        isActionSuccess={ptoRequestAction.isSuccess}
        onTakeAction={setActionPayload}
      />
    );
  };

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: [pto?.metadata?.approver?.id, pto?.metadata?.rejector?.id],
      staff_ids: [
        pto?.user?.id,
        pto?.metadata?.user?.id,
        // NOTE: employeeId here is go2_staff_id
        ...(pto?.metadata?.approvers?.map((u) => u.employeeId) ?? []),
        ...(pto?.metadata?.rejectors?.map((u) => u.employeeId) ?? []),
      ],
      employee_ids: [
        pto?.user?.employee_id,
        pto?.metadata?.user?.employee_id,
        pto?.metadata?.approver?.employee_id,
        pto?.metadata?.rejector?.employee_id,
      ],
    });
  }, [pto]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * New action has changed,
   * update the server on the action
   */
  useEffect(() => {
    if (
      actionPayload &&
      !ptoRequestAction.isLoading &&
      !ptoRequestAction.isSuccess &&
      !ptoRequestAction.error
    ) {
      ptoRequestAction.mutate({
        note: actionPayload.note,
      });
    }
  }, [actionPayload]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * After approving / rejecting request, refetch again the updated data of PTO
   */
  useEffect(() => {
    if (ptoRequestAction.isSuccess) {
      ptoById.refetch();
    }
  }, [ptoRequestAction.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Reset the action taken after successfully fetching new updated PTO data
   */
  useEffect(() => {
    if (ptoRequestAction.isSuccess && ptoById.isSuccess) {
      setActionPayload(null);
      ptoRequestAction.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptoRequestAction.isSuccess, ptoById.isSuccess]);

  return (
    <Fragment key={currentPtoId}>
      <WindowScrollTop />
      <MainContainer>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              const { t } = parseURL(location.search);
              const p = location.pathname
                .split('/')
                .filter((s) => !!s)
                .slice(0, 2)
                .join('/');
              navigate(`/${p}/${t ?? 0}`);
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box flex={1} />
          {nextPrevNavigation}
        </AppActionToolbar>
        {renderContent()}
      </MainContainer>
    </Fragment>
  );
};

export default InboxRequestDetail;
