import { useLocation } from 'react-router-dom';

import SettingsTeamJobs from './SettingsTeamJobs';
import MainContainer from '../components/MainContainer';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import SettingsTeamFocusAreas from './SettingsTeamFocusAreas';

type Props = {};

const Builder = (props: Props) => {
  const { pathname } = useLocation();
  const segments = pathname.split('/').filter((s) => !!s);
  const [, segment] = segments;

  if (segment === 'jobs') {
    return <SettingsTeamJobs />;
  }

  if (segment === 'focus') {
    return <SettingsTeamFocusAreas />;
  }

  return (
    <MainContainer>
      <ErrorUnauthorizedAccess />
    </MainContainer>
  );
};

export default Builder;
