import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';

import IntercomArticleItem from './IntercomArticleItem';
import IntercomArticleSkeleton from './IntercomArticleSkeleton';

import { getArticleItemProps } from '../../providers/intercom';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import { mutateFieldEscapeString, mutateIdToString } from '../../utils/object';
import { ListQuery } from '../../types/request';
import { QueryParams } from '../../hooks/global/useApi';
import { IntercomArticle } from '../../types/intercom';
import { ListResult } from '../../types/response';

type IntercomSearchResultsProps = {
  useIntercomArticlesHook: <R = IntercomArticle>(
    params: ListQuery & {
      parent_id?: string;
      search?: string;
      state?: 'published';
    },
    reactQueryParams?: Partial<QueryParams>,
  ) => UseQueryResult<ListResult<R>>;
};

const IntercomSearchResults = ({
  useIntercomArticlesHook,
}: IntercomSearchResultsProps) => {
  const { search } = useIntercomProvider();

  const params: ListQuery = {
    page: 1,
    offset: 0,
    limit: 200,
  };
  const articlesQuery = useIntercomArticlesHook({
    ...params,
    search,
    state: 'published',
    omit: 'body,author,translated_content,statistics',
  });

  const articles = useMemo(() => {
    if (!articlesQuery.data?.results.length) {
      return [];
    }

    let articles: IntercomArticle[] = articlesQuery.data
      .results as IntercomArticle[];

    mutateIdToString(articles, ['id', 'parent_id', 'author_id']);
    mutateFieldEscapeString(articles, ['title', 'description']);

    return articles.sort((a, b) => b.updated_at - a.updated_at);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, articlesQuery.isSuccess]);

  const renderHeadline = ({ label = 'Search results for:' }) => {
    return (
      <Typography
        fontWeight={700}
        fontSize={18}
        sx={{ pt: 2, pb: 3, color: '#8f919d' }}
      >
        {label} <span style={{ color: '#3a3c4c' }}>{search}</span>
      </Typography>
    );
  };

  if (!articlesQuery.isLoading && !articlesQuery.data?.results.length) {
    return renderHeadline({
      label: `We couldn't find any articles for:`,
    });
  }

  return (
    <>
      {renderHeadline({
        label: 'Search results for:',
      })}
      {articlesQuery.isLoading && !articles.length && (
        <IntercomArticleSkeleton boxStyle={{ margin: 0 }} />
      )}
      {articles.map((article, index, arr) => {
        const item = getArticleItemProps(article);
        const first = index === 0;
        const last = arr.length - 1 === index;
        return (
          <IntercomArticleItem
            key={item.id}
            item={item}
            first={first}
            last={last}
          />
        );
      })}
    </>
  );
};

export default IntercomSearchResults;
