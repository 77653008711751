import React, { useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { UseQueryOptions } from '@tanstack/react-query';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import AbsoluteCenterBox from '../AbsoluteCenterBox';

import { ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../../providers/app/app';
import { usePTOBalanceByEmployee } from '../../hooks/pto';
import { HrTimeOffTypeId } from '../../types/hr';
import { PTOBalanceItemResponse } from '../../types/pto';
import { hrTimeOffTypes } from '../../utils/pto';

type TimeOffAccrualListProps = {
  staffId?: string | null;
  requestTypeId?: HrTimeOffTypeId | null;
  popupVersion?: boolean;
  title?: ReactRenderElement;
};
type AccrualPanel = {
  id: TimeOffAccrualListProps['requestTypeId'];
  title: ReactRenderElement;
  used: number;
  available: number;
  withAvailable?: boolean;
  icon: ReactRenderElement;
};

export function useUserTimeOffBalance({
  staffId,
}: Partial<TimeOffAccrualListProps>): {
  pto?: PTOBalanceItemResponse;
  flexHoliday?: PTOBalanceItemResponse;
  uto?: PTOBalanceItemResponse;
  unlimited?: boolean;
} {
  const queryParams: UseQueryOptions = {
    enabled: false,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
  };
  const { data: typesBalance, refetch: refetchTypesBalance } =
    usePTOBalanceByEmployee({ eeid: staffId! }, queryParams);

  const { ptoBalance, flexHolidayBalance, utoBalance, unlimited } =
    useMemo(() => {
      return {
        ptoBalance: typesBalance?.balance?.find(
          (t) => t.time_off_type === hrTimeOffTypes.pto,
        ),
        flexHolidayBalance: typesBalance?.balance?.find(
          (t) => t.time_off_type === hrTimeOffTypes.flex_holiday,
        ),
        utoBalance: typesBalance?.balance?.find(
          (t) => t.time_off_type === hrTimeOffTypes.uto,
        ),
        unlimited: typesBalance?.unlimited,
      };
    }, [typesBalance]);

  useEffect(() => {
    if (!!staffId) {
      refetchTypesBalance();
    }
  }, [staffId]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    pto: ptoBalance,
    flexHoliday: flexHolidayBalance,
    uto: utoBalance,
    unlimited,
  };
}

const TimeOffAccrualList = ({
  title,
  staffId,
  requestTypeId,
  popupVersion,
}: TimeOffAccrualListProps) => {
  const { isDarkMode } = useAppProvider();

  const {
    pto: ptoBalance,
    flexHoliday: flexHolidayBalance,
    uto: utoBalance,
    unlimited,
  } = useUserTimeOffBalance({
    staffId,
  });

  const renderAccrualPanel = ({
    id,
    title,
    icon,
    used,
    available,
    withAvailable = true,
  }: AccrualPanel) => {
    return (
      <Box
        display='flex'
        sx={{
          flex: 1,
          width: 0,
          minWidth: popupVersion ? 200 : 240,
        }}
      >
        <M3Card noHover sx={{ flex: 1 }}>
          <M3SurfaceContainer
            elevation={id === requestTypeId ? 2 : 1}
            wrapperSx={{
              height: '100%',
            }}
            sx={{
              p: popupVersion ? 2 : 3,
              pb: 0,
              pt: 2,
              height: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <AbsoluteCenterBox
              sx={{
                left: 0,
                top: undefined,
                right: undefined,
                pointerEvents: 'none',
                transform: 'translate3d(-22%, 20%, 0)',
              }}
            >
              {icon}
            </AbsoluteCenterBox>
            <Typography
              component='div'
              fontSize={popupVersion ? 18 : 22}
              lineHeight={1.2}
              fontWeight={500}
              sx={{
                height: 55,
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Typography>
            <Box
              display='flex'
              justifyContent='space-between'
              pb={popupVersion ? 1.5 : 2}
            >
              {withAvailable && (
                <Box>
                  <Typography
                    component='div'
                    fontSize={popupVersion ? 12 : 14}
                    lineHeight={1.3}
                    sx={{ opacity: 0.8 }}
                  >
                    Hours Available
                  </Typography>
                  {unlimited ? (
                    <Box>
                      <AllInclusiveIcon
                        sx={{
                          top: 4,
                          position: 'relative',
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography component='div' fontSize={22} fontWeight={500}>
                      {available}
                    </Typography>
                  )}
                </Box>
              )}
              <Box>
                <Typography
                  component='div'
                  fontSize={popupVersion ? 12 : 14}
                  lineHeight={1.3}
                  sx={{ opacity: 0.8 }}
                >
                  Hours Used
                </Typography>
                <Typography component='div' fontSize={22} fontWeight={500}>
                  {used}
                </Typography>
              </Box>
            </Box>
          </M3SurfaceContainer>
        </M3Card>
      </Box>
    );
  };

  return (
    <>
      {!!title && (
        <Stack direction='row' alignItems='center'>
          <CalendarMonthOutlinedIcon
            sx={{
              mr: 0.2,
              mb: 0.1,
              fontSize: 20,
            }}
          />
          &nbsp;
          <Typography component='div' fontSize={16} fontWeight={500}>
            {title}
          </Typography>
        </Stack>
      )}
      <Stack direction='row' gap={2} pt={2}>
        {renderAccrualPanel({
          id: hrTimeOffTypes.pto,
          icon: (
            <CalendarMonthOutlinedIcon
              sx={{
                fontSize: 240,
                opacity: isDarkMode ? 0.015 : 0.025,
                transform: 'translate3d(0, 16%, 0)',
              }}
            />
          ),
          title: 'Paid Time Off (PTO)',
          used: parseFloat(ptoBalance?.used_year_to_date ?? '0'),
          available: parseFloat(ptoBalance?.balance ?? '0'),
        })}
        {renderAccrualPanel({
          id: hrTimeOffTypes.flex_holiday,
          icon: (
            <GrassOutlinedIcon
              sx={{
                fontSize: 240,
                opacity: isDarkMode ? 0.015 : 0.025,
              }}
            />
          ),
          title: 'Flexible Holidays',
          used: parseFloat(flexHolidayBalance?.used_year_to_date ?? '0'),
          available: parseFloat(flexHolidayBalance?.balance ?? '0'),
        })}
        {renderAccrualPanel({
          id: hrTimeOffTypes.uto,
          icon: (
            <EventOutlinedIcon
              sx={{
                fontSize: 240,
                opacity: isDarkMode ? 0.015 : 0.025,
                transform: 'translate3d(0, 16%, 0)',
              }}
            />
          ),
          title: 'UTO',
          used: parseFloat(utoBalance?.used_year_to_date ?? '0'),
          available: parseFloat(utoBalance?.balance ?? '0'),
          withAvailable: false,
        })}
      </Stack>
    </>
  );
};

export default TimeOffAccrualList;
