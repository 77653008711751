import { UseQueryOptions } from '@tanstack/react-query';

import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

import { CircleModel } from '../types/circle';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';

type UseCirclesProps = ListQuery & {
  name: string;
};

export function useCircles<R = CircleModel>(
  params: UseCirclesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseCirclesProps>({
    params,
    baseIdentifier: 'useCircles',
    identifierKeys: ['name'],
    queryParamKeys: ['name'],
  });

  return useQueryApi<UseCirclesProps, ListResult<R>>(
    identifiers,
    '/admin/circles/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
