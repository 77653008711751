import React, { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import AppNavigationBar from './components/UserNumbers/AppNavigationBar';
import PageLoader from './components/PageLoader';
import UserNumbersDrawer from './components/UserNumbers/UserNumbersDrawer';
import Error from './pages/Error';
import FileVersioningNotice from './components/FileVersioningNotice';

import { useCurrentProfile } from './hooks/profile';

type Props = {
  children?: any;
};

/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function UserNumbersHome({ children }: Props) {
  const { isLoading, error, data: userProfile } = useCurrentProfile();

  useLayoutEffect(() => {
    document.body.classList.add('numbers-body');
    return () => {
      document.body.classList.remove('numbers-body');
    };
  }, []);

  const renderDrawer = () => {
    return <UserNumbersDrawer />;
  };

  if (!!error) {
    return (
      <Error
        title='User not found'
        message={<>This user no longer exist. Contact your administrator.</>}
      />
    );
  }

  /**
   * Show some loading screen when fetching current employee's profile
   */
  if (isLoading && !userProfile) {
    return <PageLoader />;
  }

  return (
    <>
      <FileVersioningNotice />
      <AppNavigationBar />
      <Box display='flex' sx={{ flex: 1 }}>
        {renderDrawer()}
        <Outlet />
      </Box>
    </>
  );
}

export default UserNumbersHome;
