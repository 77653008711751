import { Box, Stack, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';

import ModalCardView, { ModalCardViewCloseProps } from './ModalCardView';
import MyDayCard from './MyDayCard';

import { DailyReportItemExpanded } from '../utils/report';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useCurrentProfile } from '../hooks/profile';

type Props = ModalCardViewCloseProps & {
  dailyReport: DailyReportItemExpanded;
};

export function TeamManifestModalViewBase({ dailyReport, close }: Props) {
  const { contentTypes } = useMetadataProvider();
  const { data: currentProfile } = useCurrentProfile();

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <WysiwygOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Box display='flex' flexWrap='nowrap'>
            <Typography
              display='flex'
              alignItems='center'
              flexWrap='nowrap'
              fontSize={20}
              fontWeight={500}
              component='div'
            >
              Manifest
            </Typography>
          </Box>
        </Typography>
      </Stack>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
      sx={{
        maxWidth: 1200,
      }}
    >
      <Box sx={{ mt: 2, pb: 6, minHeight: 400 }}>
        <MyDayCard
          openDefault
          withToggle={false}
          item={dailyReport}
          contentType={contentTypes.report}
          currentProfile={currentProfile}
        />
      </Box>
    </ModalCardView>
  );
}

const TeamManifestModalView = forwardRef((props: Props, ref) => (
  <TeamManifestModalViewBase {...props} />
));

export default TeamManifestModalView;
