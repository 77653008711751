import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { WorkspaceProvider } from './providers/workspace/workspace';
import { ReactRenderElement } from './types/types';
import { DivisionProvider } from './providers/division/division';
import { MetadataProvider } from './providers/metadata/metadata';
import { NotificationProvider } from './providers/notification/notification';
import { UserProvider } from './providers/user/user';
import { TeamProvider } from './providers/team/team';
import { TimeTrackerProvider } from './providers/time-tracker/time-tracker';
import { useCurrentProfile } from './hooks/profile';
import { useAuthForm, useAuthProvider } from './providers/auth/auth';
import ErrorPage from './pages/Error';
import { M3Button } from './components/M3/M3Button';

type Props = {
  children?: any;
};

export type ProviderWrapper = any;

function DataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  /**
   * Define all your providers that needed to be accessed throughout the app.
   * Order matters here, first provider will be at the top of hierarchy...
   */
  const providers: ProviderWrapper[] = [
    WorkspaceProvider,
    DivisionProvider,
    NotificationProvider,
    UserProvider,
    TeamProvider,
    TimeTrackerProvider,
    /**
     * Make sure to metadata provider will be called last
     */
    MetadataProvider,
  ];

  return (
    providers.length
      ? providers.reduceRight(
          (children, Provider: ProviderWrapper) => (
            <Provider>{children}</Provider>
          ),
          children,
        )
      : children
  ) as ReactElement;
}

function UserNumbersDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  /**
   * Define all your providers that needed to be accessed throughout the app.
   * Order matters here, first provider will be at the top of hierarchy...
   */
  const providers: ProviderWrapper[] = [
    UserProvider,
    /**
     * Make sure to metadata provider will be called last
     */
    MetadataProvider,
  ];

  return (
    providers.length
      ? providers.reduceRight(
          (children, Provider: ProviderWrapper) => (
            <Provider>{children}</Provider>
          ),
          children,
        )
      : children
  ) as ReactElement;
}

function UserJobseekersDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  /**
   * Define all your providers that needed to be accessed throughout the app.
   * Order matters here, first provider will be at the top of hierarchy...
   */
  const providers: ProviderWrapper[] = [
    UserProvider,
    /**
     * Make sure to metadata provider will be called last
     */
    MetadataProvider,
  ];

  return (
    providers.length
      ? providers.reduceRight(
          (children, Provider: ProviderWrapper) => (
            <Provider>{children}</Provider>
          ),
          children,
        )
      : children
  ) as ReactElement;
}

function App({ children }: Props) {
  const { logout } = useAuthForm();
  const { error: authError } = useAuthProvider();
  const { data: userProfile } = useCurrentProfile();

  if (authError) {
    return (
      <ErrorPage
        title='Authentication Error'
        message={
          <>
            Sorry, we're having trouble authenticating your account. Please try
            logging out and logging back in again using the same browser to
            resolve this issue. Thank you for your patience.
            <br />
            <br />
            <M3Button
              color='primary'
              variant='contained'
              sx={{ width: 120 }}
              onClick={logout}
            >
              Log out
            </M3Button>
          </>
        }
      />
    );
  }

  /**
   * For 'numbers' user we need to have another set of data provider
   */
  if (userProfile?.roles.indexOf('numbers')! > -1) {
    return (
      <UserNumbersDataProviders>
        <Outlet />
      </UserNumbersDataProviders>
    );
  }

  /**
   * For 'jobseekers' user we need to have another set of data provider
   */
  if (userProfile?.roles.indexOf('jobseekers')! > -1) {
    return (
      <UserJobseekersDataProviders>
        <Outlet />
      </UserJobseekersDataProviders>
    );
  }

  /**
   * Auth has successfully authenticated the user.
   * Wrapped the Outlet with all the providers defined.
   */
  return (
    <DataProviders>
      <Outlet />
    </DataProviders>
  );
}

export default App;
