import {
  CSSProperties,
  Fragment,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import BasicCard from '../BasicCard';
import CommentsList from '../CommentsList';
import { M3Card } from '../M3/M3Card';
import { M3Button } from '../M3/M3Button';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import M3AlertDialog, { M3AlertDialogProps } from '../M3/M3Dialogs';
import { M3TextField } from '../M3/M3TextField';
import TimeOffWhosOut from '../Request/TimeOffWhosOut';
import UserProfilePopover from '../UserProfilePopover';
import TimeOffProfileModalView from '../UserProfile/TimeOffProfileModalView';

import { getUserInitials } from '../../utils/user';
import { PTOAction, PTOItemResponse, PTOStatus } from '../../types/pto';
import { useMetadataProvider } from '../../providers/metadata/metadata';
import { useCurrentProfile } from '../../hooks/profile';
import { useIsCardFocused } from '../../hooks/utils/card';
import { useAppProvider } from '../../providers/app/app';
import { parseDateWithoutTimezone } from '../../utils/date';
import { UserMetadata } from '../../types/profile';
import { useUserProvider } from '../../providers/user/user';
import { PTOActionPayload } from '../../pages/InboxRequestDetail';
import { ReactRenderElement } from '../../types/types';

type InboxPTOItemDetailProps = {
  user: Partial<UserMetadata>;
  pto: PTOItemResponse;
  action?: PTOAction | null;
  isTakingAction?: boolean;
  isActionSuccess?: boolean;
  onTakeAction?: (payload: PTOActionPayload) => void;
};

type PTOStatusExtended = PTOStatus | 'error' | null;

const InboxPTOItemDetail = ({
  user,
  pto,
  action,
  isTakingAction,
  isActionSuccess,
  onTakeAction,
}: InboxPTOItemDetailProps) => {
  const { contentTypes } = useMetadataProvider();
  const isCardFocused = useIsCardFocused();
  const { data: currentProfile } = useCurrentProfile();
  const {
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const { getUser } = useUserProvider();

  const [isTimeOffViewOpen, setIsTimeOffViewOpen] = useState(false);
  const [isRejectCancelOpen, setIsRejectCancelOpen] = useState(false);

  const createdDate = pto.created;
  const statusLastChangedDate = pto.status_last_changed;

  const generateRequestDates = (start: string, end: string): Moment[] => {
    return [start, start === end ? null : end]
      .filter((date) => !!date)
      .map((date) => moment(parseDateWithoutTimezone(date!).date));
  };

  const requestedDates: Moment[] = generateRequestDates(pto.start, pto.end);

  const renderMiniCalender = (date: Moment) => {
    return (
      <Box
        sx={{
          width: 55,
          minWidth: 55,
          textAlign: 'center',
          border: '1px solid #686868',
        }}
      >
        <Typography
          component='div'
          fontSize={12}
          fontWeight='bold'
          sx={{ p: 0.2, color: '#fff', backgroundColor: '#686868' }}
        >
          {date.format('MMM').toUpperCase()}
        </Typography>
        <Typography
          fontSize={16}
          fontWeight='bold'
          component='div'
          sx={{ p: 0.4, color: '#686868' }}
        >
          {date.date()}
        </Typography>
      </Box>
    );
  };

  const renderMiniCalenderDateRequests = (dates: Moment[]) => {
    return dates.map((date, i: number, arr: Moment[]) => {
      return (
        <Fragment key={i + 1}>
          {renderMiniCalender(date)}
          {arr.length > 1 && arr.length - 1 !== i && (
            <TrendingFlatIcon sx={{ mt: 1.3, opacity: 0.5 }} />
          )}
        </Fragment>
      );
    });
  };

  const renderAwaitingStatus = () => {
    let supercededStatus: PTOStatusExtended = null;

    let metadata = pto.metadata;
    let metadataStatus: PTOStatusExtended = null;

    if (typeof metadata.status === 'string') {
      metadataStatus = metadata.status as PTOStatus;
    }
    // In here it's gonna be an object
    else {
      metadataStatus = metadata.status.status as PTOStatus;
    }
    let approverRejector =
      pto.status === 'approved' ||
      /**
       * we need to double-check this as the new auto-approve feature updates
       * the pto.status to superceded, so check if the approver exists and the
       * metadata status is approved
       *  */
      (pto.status === 'superceded' &&
        pto.metadata.approver &&
        metadataStatus === 'approved')
        ? pto.metadata.approver
        : pto.status === 'denied'
        ? pto.metadata.rejector
        : pto.status === 'canceled'
        ? pto.metadata.rejector || pto.metadata.approver
        : null;

    let actor = approverRejector
      ? getUser('user_id', approverRejector.id) ||
        getUser('employee_id', approverRejector.employee_id)
      : null;

    /**
     * NOTE: Checking metadata approvers/rejectors if there's inserted.
     * There are cases that it's being denied/cancelled
     */
    const getMetadataApproverRejector = () => {
      if (metadata.approvers?.length) {
        return metadata.approvers[0].employeeId
          ? getUser('staff_id', metadata.approvers[0].employeeId)
          : null;
      }

      if (metadata.rejectors?.length) {
        return metadata.rejectors[0].employeeId
          ? getUser('staff_id', metadata.rejectors[0].employeeId)
          : null;
      }

      return null;
    };

    /**
     * Check for superceded status, usually this is coming from BHR,
     * which it overrides the request of requester.
     * ie. canceled, denied, approved
     */
    if (pto.status === 'superceded') {
      if (metadataStatus === 'approved') {
        supercededStatus = metadataStatus;
        actor = actor || getMetadataApproverRejector();
      } else if (metadataStatus === 'denied') {
        supercededStatus = metadataStatus;
        actor = actor || getMetadataApproverRejector();
      } else if (metadataStatus === 'requested') {
        supercededStatus = 'canceled';
      }
      // What to do with this status, main status = 'superceded'
      // and the metadata status = 'superceded'
      else if (metadataStatus === 'superceded') {
        supercededStatus = 'superceded';
      }
    } else if (metadataStatus === 'approved' || metadataStatus === 'denied') {
      actor = actor || getMetadataApproverRejector();
    }

    const isCanceled = (): boolean => {
      if (pto.status === 'canceled' || supercededStatus === 'canceled') {
        return true;
      } else {
        return false;
      }
    };

    return (
      <Box
        display='flex'
        alignItems='center'
        sx={{
          height: 50,
          minWidth: 145,
          borderRadius: 1,
          overflow: 'hidden',
          border: '1px solid #d3d4d3',
          backgroundColor: '#d3d4d3',
          ...(pto.status === 'approved' || supercededStatus === 'approved'
            ? {
                color: '#fff',
                borderColor: '#0f840d',
                backgroundColor: '#0f840d',
              }
            : pto.status === 'denied' ||
              pto.status === 'canceled' ||
              supercededStatus === 'denied' ||
              supercededStatus === 'canceled' ||
              supercededStatus === 'superceded'
            ? {
                color: '#fff',
                borderColor: '#f34722',
                backgroundColor: '#f34722',
              }
            : null),
        }}
      >
        <Typography
          fontSize={12}
          sx={{
            pl: 1.5,
            pr: 1.5,
          }}
          component='div'
          style={{
            ...(isDarkMode
              ? {
                  color: pto.status === 'requested' ? '#000' : '#fff',
                }
              : {
                  color: pto.status === 'requested' ? undefined : '#fff',
                }),
          }}
        >
          {pto.status === 'approved' || supercededStatus === 'approved' ? (
            <>
              Approved
              {actor
                ? ` by ${actor.first_name || ''} ${actor.last_name || ''}`
                : ''}
              <br />
              {!statusLastChangedDate ? (
                <Skeleton variant='text' width={70} />
              ) : (
                moment.utc(statusLastChangedDate).local().format('MMM D, YYYY')
              )}
            </>
          ) : pto.status === 'denied' ||
            pto.status === 'canceled' ||
            supercededStatus === 'denied' ||
            supercededStatus === 'canceled' ||
            supercededStatus === 'superceded' ? (
            <>
              {supercededStatus === 'superceded'
                ? 'Superceded'
                : pto.status === 'denied' || supercededStatus === 'denied'
                ? 'Denied'
                : 'Cancelled'}
              {actor &&
              pto.status !== 'canceled' &&
              supercededStatus !== 'canceled'
                ? ` by ${actor.first_name || ''} ${actor.last_name || ''}`
                : ''}
              <br />
              {!statusLastChangedDate ? (
                <Skeleton variant='text' width={70} />
              ) : (
                moment.utc(statusLastChangedDate).local().format('MMM D, YYYY')
              )}
            </>
          ) : (
            <>
              Awaiting Approval
              <br />1 pending approval
            </>
          )}
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: 50,
            width: 55,
            background: '#fff',
            borderLeft: '1px solid #d3d4d3',
          }}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
            }}
            src={isCanceled() ? undefined : actor?.photo_url}
            style={{
              ...(pto.status === 'approved' || supercededStatus === 'approved'
                ? {
                    color: '#fff',
                    borderColor: '#0f840d',
                    backgroundColor: '#0f840d',
                  }
                : pto.status === 'denied' ||
                  pto.status === 'canceled' ||
                  supercededStatus === 'denied' ||
                  supercededStatus === 'canceled' ||
                  supercededStatus === 'superceded'
                ? {
                    color: '#fff',
                    borderColor: 'red',
                    backgroundColor: 'red',
                  }
                : null),
            }}
          >
            {pto.status === 'approved' || supercededStatus === 'approved' ? (
              <DoneOutlinedIcon
                sx={{ color: '#fff', opacity: '1 !important' }}
              />
            ) : pto.status === 'denied' ||
              pto.status === 'canceled' ||
              supercededStatus === 'denied' ||
              supercededStatus === 'canceled' ||
              supercededStatus === 'superceded' ? (
              <CloseOutlinedIcon
                sx={{ color: '#fff', opacity: '1 !important' }}
              />
            ) : (
              <TimerOutlinedIcon />
            )}
          </Avatar>
        </Box>
      </Box>
    );
  };

  const renderTriangleBubble = (style?: CSSProperties) => {
    return (
      <div
        style={{
          width: 0,
          height: 0,
          top: -14,
          position: 'absolute',
          borderStyle: 'solid',
          borderWidth: '0px 15px 18px',
          borderColor: `transparent transparent ${
            isDarkMode
              ? 'var(--md-ref-palette-neutral20)'
              : 'var(--md-ref-palette-neutral90)'
          } transparent`,
          ...style,
        }}
      ></div>
    );
  };

  const renderSpeechBubble = ({
    content,
    position = 'left',
    caretStyle,
  }: {
    content: ReactRenderElement;
    position: 'left' | 'right';
    caretStyle?: CSSProperties;
  }) => {
    if (!content) return null;

    return (
      <Box display='flex' sx={{ mt: 2 }}>
        <Box position='relative'>
          {renderTriangleBubble({ [position]: 15, ...caretStyle })}
          <Typography
            p={1}
            pl={2}
            pr={2}
            mt={0.5}
            minWidth={200}
            borderRadius={1}
            component='div'
            fontSize={14}
            sx={{
              background: isDarkMode
                ? 'var(--md-ref-palette-neutral20)'
                : 'var(--md-ref-palette-neutral90)',
            }}
          >
            {content}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderActionButtons = () => {
    if (pto.status !== 'requested') return null;

    /**
     * Only allow the approve / deny of PTO to selected roles
     */
    if (
      !currentProfile!.roles.some(
        (role) =>
          role === 'partner_member' ||
          role === 'teammate' ||
          role === 'squad_lead' ||
          role === 'escalation_manager',
      )
    ) {
      return null;
    }

    /**
     * Do not allow the approve / deny of PTO for Teammate and Escalation Manager
     * who requested the PTO. ie. no self approve/deny of PTO
     */
    if (currentProfile!.employee_id === pto.user.employee_id) {
      return null;
    }

    return (
      <Stack direction='row' gap={2} alignItems='center'>
        <M3Button
          color='primary'
          variant='contained'
          disableRipple={isTakingAction}
          sx={{
            color: '#fff',
            width: 100,
            pointerEvents: isTakingAction ? 'none' : undefined,
          }}
          onClick={() => onTakeAction?.({ action: 'approve' })}
        >
          {action === 'approve' && isTakingAction ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            'Approve'
          )}
        </M3Button>
        <M3Button
          color='primary'
          variant='outlined'
          disableRipple={isTakingAction}
          sx={{
            width: 100,
            pointerEvents: isTakingAction ? 'none' : undefined,
          }}
          onClick={() => setIsRejectCancelOpen(true)}
        >
          {action === 'reject' && isTakingAction ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            'Deny'
          )}
        </M3Button>
      </Stack>
    );
  };

  const renderTopPanelHeader = () => {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          height: 53,
        }}
      >
        <Typography flex={1} fontWeight={300} fontSize={24} sx={{ pl: 1 }}>
          Time Off Request
        </Typography>
        {renderActionButtons()}
      </Box>
    );
  };

  const renderUserInfo = () => {
    return (
      <Stack
        gap={2}
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Box sx={{ maxWidth: 60 }}>
          <UserProfilePopover user={user as UserMetadata}>
            <Avatar sx={{ width: 60, height: 60 }} src={user.photo_url}>
              {getUserInitials(`${user.first_name} ${user.last_name}`).initial}
            </Avatar>
          </UserProfilePopover>
          <Box sx={{ height: 30 }} />
          <Box
            sx={{
              width: {
                md: 280,
                lg: 390,
                xl: 450,
              },
            }}
          >
            {renderSpeechBubble({
              position: 'left',
              content: pto.notes?.employee,
            })}
          </Box>
        </Box>
        <Box flex={1}>
          <Typography fontSize={22} fontWeight={300} sx={{ mb: 0.4 }}>
            {user.first_name} {user.last_name}
          </Typography>
          <Stack
            gap={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Stack
              gap={1}
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              {renderMiniCalenderDateRequests(requestedDates)}
            </Stack>
            <Box sx={{ mt: -0.6 }} minHeight={66}>
              <Typography fontSize={14} fontWeight={500} color='#686868'>
                {pto.amount} Hour{pto.amount > 1 ? 's' : ''} of {pto.type_name}
              </Typography>
              {typeof pto.metadata.balanceOnDateOfRequest === 'number' && (
                <Typography fontSize={14} color='#686868'>
                  Balance on date of request:{' '}
                  {pto.metadata.balanceOnDateOfRequest ?? 0} hour
                  {pto.metadata.balanceOnDateOfRequest > 1 ? 's' : ''}
                </Typography>
              )}
              <M3Button
                sx={{
                  p: 0,
                  pl: 1,
                  pr: 1,
                  ml: -1,
                  mt: -0.5,
                  fontSize: 13,
                }}
                style={{
                  minWidth: 'initial',
                  minHeight: 'initial',
                  height: 'initial',
                  width: 'initial',
                  color: isDarkMode
                    ? 'var(--md-ref-palette-primary80)'
                    : 'var(--md-ref-palette-primary40)',
                }}
                onClick={() => {
                  setIsTimeOffViewOpen(true);
                }}
              >
                View Available Balance
              </M3Button>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Typography
            fontSize={12}
            display='flex'
            justifyContent='flex-end'
            sx={{ mb: 0.5, textAlign: 'right' }}
          >
            <span style={{ opacity: 0.5 }}>Date Requested:</span>&nbsp;
            {!createdDate ? (
              <Skeleton variant='text' width={70} />
            ) : (
              moment(createdDate).local().format('MMM D, YYYY')
            )}
          </Typography>
          <Box display='flex' justifyContent='flex-end' sx={{ mb: 2 }}>
            {renderAwaitingStatus()}
          </Box>
          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{
              paddingTop: '2px',
              width: {
                md: 280,
                lg: 390,
                xl: 450,
              },
            }}
          >
            {renderSpeechBubble({
              position: 'right',
              content: pto.notes.manager,
              caretStyle: { right: 13 },
            })}
          </Box>
        </Box>
      </Stack>
    );
  };

  const renderBigCalendar = () => {
    return (
      <Box
        sx={{
          overflow: 'hidden',
          border: isDarkMode ? '1px solid transparent' : '1px solid #d3d4d3',
          borderRadius: 1,
          '.Mui-disabled': {
            color: '#686868',
          },
          '.MuiPickersArrowSwitcher-root': {
            display: 'none',
          },
          '.MuiTypography-caption': {
            fontWeight: 'bold',
          },
          '.MuiPickersDay-dayWithMargin': {
            fontSize: 14,
            '&.Mui-selected': {
              color: '#fff',
              fontWeight: 'bold',
              background: '#2aa8d7',
            },
          },
          '.MuiPickersDay-dayOutsideMonth': {
            opacity: 0.3,
          },
          '.MuiCalendarPicker-viewTransitionContainer': {
            backgroundColor: '#fff',
          },
          '.MuiCalendarPicker-root': {
            backgroundColor: '#dfdfdf',
            '& > :not(.MuiCalendarPicker-viewTransitionContainer)': {
              margin: '0 auto',
              textAlign: 'center',
              padding: '10px 20px',
              maxHeight: 'initial',
              '.PrivatePickersFadeTransitionGroup-root': {
                fontWeight: 'bold',
                textAlign: 'center',
              },
            },
          },
          '.PrivatePickersSlideTransition-root': {
            minHeight: 230,
          },
          '.m3-datepicker-big-calendar-paper': {
            '& > div': {
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              '&:first-of-type': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                background: isDarkMode
                  ? 'var(--md-ref-palette-tertiary40)'
                  : undefined,
              },
              '& > div': {
                margin: 'auto',
              },
            },
          },
        }}
      >
        <CalendarPicker
          disabled
          showDaysOutsideCurrentMonth
          date={requestedDates[0]}
          dayOfWeekFormatter={(day) => day}
          renderDay={(
            day: any,
            selectedDays: any[],
            pickersDayProps: PickersDayProps<any>,
          ) => {
            const ymd = day.format('YYYY-MM-DD');
            const amount: number = parseFloat(pto.dates?.[ymd] ?? '0');
            const selected = amount > 0;

            /**
             * TODO: Do we need to have a fallback compatibility for older PTO?
             * There's a caveat on using this, it will cause confusion about sun/sat
             *
             */
            /*
            let [startDate, endDate] = requestedDates;
            endDate = endDate || startDate.clone();
            const selected =
              moment(startDate.format('YYYY-MM-DD')).isSameOrBefore(
                moment(day.format('YYYY-MM-DD')),
              ) &&
              moment(endDate.format('YYYY-MM-DD')).isSameOrAfter(
                moment(day.format('YYYY-MM-DD')),
              );
            */

            return <PickersDay {...pickersDayProps} selected={selected} />;
          }}
          onChange={() => null}
          className='m3-datepicker-calendar-paper m3-datepicker-big-calendar-paper'
        />
      </Box>
    );
  };

  useEffect(() => {
    if (isActionSuccess) {
      setIsRejectCancelOpen(false);
    }
  }, [isActionSuccess]);

  return (
    <>
      <M3Card
        focused={isCardFocused.focused}
        sx={{
          border: isDarkMode
            ? '1px solid var(--md-sys-color-surface-variant-dark)'
            : '1px solid var(--md-sys-color-surface-variant-light)',
        }}
      >
        <Box>
          <BasicCard
            withHeaderDivider={false}
            header={renderTopPanelHeader()}
            headerSx={{
              p: 1,
              pl: 2,
              pr: 2,
              borderRadius: '10px 10px 0 0',
              background: isDarkMode
                ? palette['md.ref.palette.neutral.variant10']
                : palette['md.ref.palette.neutral.variant90'],
            }}
          >
            <M3SurfaceContainer
              elevation={1}
              borderRadius={0}
              sx={{
                p: 4,
              }}
            >
              {renderUserInfo()}
              <br />
              <Divider />
              <br />
              <br />
              <Stack
                direction='row'
                gap={4}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                {renderBigCalendar()}
                <Box flex={1}>
                  <TimeOffWhosOut
                    isWhoElse
                    start={pto.start}
                    end={pto.end || pto.start}
                    userId={user.id}
                    user={user}
                    maxAllowedRowDate={3}
                  />
                </Box>
              </Stack>
            </M3SurfaceContainer>
          </BasicCard>
        </Box>
        <Box sx={{ pl: 1.4 }}>
          <CommentsList
            currentProfile={currentProfile}
            contentType={contentTypes.bhrtimeoffrequests}
            objectId={pto.id}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
          />
        </Box>
      </M3Card>
      <br />
      <br />
      <br />
      <Modal open={isTimeOffViewOpen}>
        <TimeOffProfileModalView
          user={user as UserMetadata}
          close={() => {
            setIsTimeOffViewOpen(false);
          }}
        />
      </Modal>
      <RejectCancelDialog
        isOpen={isRejectCancelOpen}
        user={user}
        action={action}
        isTakingAction={isTakingAction}
        onTakeAction={onTakeAction}
        setIsOpen={(isOpen: boolean) => setIsRejectCancelOpen(isOpen)}
      />
    </>
  );
};

export default InboxPTOItemDetail;

type RejectCancelDialogProps = {
  user: InboxPTOItemDetailProps['user'];
  action?: InboxPTOItemDetailProps['action'];
  isTakingAction?: InboxPTOItemDetailProps['isTakingAction'];
  onTakeAction?: InboxPTOItemDetailProps['onTakeAction'];
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  dialogProps?: Partial<M3AlertDialogProps>;
};

export function RejectCancelDialog({
  user,
  isOpen,
  action,
  setIsOpen,
  isTakingAction,
  onTakeAction,
  dialogProps,
}: RejectCancelDialogProps) {
  const [note, setNote] = useState('');

  return (
    <M3AlertDialog
      open={isOpen}
      dialog={{
        title:
          dialogProps?.dialog?.title ??
          `Send ${user.first_name} a note about why it's being denied`,
        content: (
          <Box pt={0.5}>
            <M3TextField
              name='note'
              fullWidth
              multiline
              minRows={3}
              label='Note'
              value={note}
              helperText='This field is optional'
              FormHelperTextProps={{
                sx: {
                  pt: 0.1,
                  opacity: 0.5,
                },
              }}
              onChange={(evt: SyntheticEvent) =>
                setNote((evt.target as HTMLInputElement).value)
              }
            />
          </Box>
        ),
      }}
      actions={{
        cancel: {
          label: dialogProps?.actions?.cancel?.label ?? 'Cancel',
          disabled: action === 'reject' && isTakingAction,
          onClick: () => {
            if (isTakingAction) return;

            setIsOpen?.(false);
          },
        },
        confirm: {
          disabled: action === 'reject' && isTakingAction,
          label:
            action === 'reject' && isTakingAction ? (
              <Box
                width={dialogProps?.actions?.confirm?.width ?? 122}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#fff',
                  }}
                />
              </Box>
            ) : (
              dialogProps?.actions?.confirm?.label ?? 'Continue to Deny'
            ),
          onClick: () => {
            if (isTakingAction) return;

            onTakeAction?.({
              action: 'reject',
              note,
            });
          },
        },
      }}
    />
  );
}
