import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MainContainer from '../components/MainContainer';
import { M3IconButton } from '../components/M3/M3Button';
import AppActionToolbar from '../components/AppActionToolbar';
import IntercomBreadcrumbs, {
  BreadcrumbItem,
} from '../components/Intercom/IntercomBreadcrumbs';
import JobDescriptionDetail from '../components/TeamSettings/JobDescription/JobDescriptionDetail';
import WindowScrollTop from '../components/WindowScrollTop';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import DocumentTitle from '../components/DocumentTitle';

import { usePathnameSegments } from '../hooks/utils/url';
import { useJobDescriptionById } from '../hooks/jobs';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import AIAssistSideSheet, {
  useAIAssistSideSheet,
} from '../components/SideSheet/AIAssistSideSheet';
import { useCurrentProfile } from '../hooks/profile';

type SettingsTeamJobDetailProps = {
  editable?: boolean;
  routeKey: 'builder' | 'team';
};

const SettingsTeamJobDetail = ({
  editable = true,
  routeKey,
}: SettingsTeamJobDetailProps) => {
  const navigate = useNavigate();
  const { data: userProfile } = useCurrentProfile();
  const { items: workspaces, current: currentWorkspace } =
    useWorkspaceProvider();
  const [,] = usePathnameSegments();
  const { jobDescriptionId } = useParams();

  const jobDescription = useJobDescriptionById({ id: jobDescriptionId! });
  const jdData = jobDescription.data;

  const aiAssist = useAIAssistSideSheet();

  const getRoutes = () => {
    let breadCrumbs: BreadcrumbItem[] = [];
    let basePath = '';

    if (routeKey === 'builder') {
      basePath = `/builder/jobs`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Jobs',
          path: basePath,
        },
        {
          id: jobDescriptionId!,
          label: jdData?.title ?? jobDescriptionId,
          path: `${basePath}/${jobDescriptionId}`,
        },
      ];
    } else if (routeKey === 'team') {
      basePath = `/team/jobs/${currentWorkspace?.id}`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Jobs',
          path: basePath,
        },
        {
          id: jobDescriptionId!,
          label: jdData?.title ?? jobDescriptionId,
          path: `${basePath}/${jobDescriptionId}`,
        },
      ];
    }

    return {
      breadCrumbs,
      basePath,
    };
  };

  const hasInternalAccess = userProfile?.roles.some(
    (role) => role === 'internal',
  );

  /**
   * NOTE:
   * Do not allow viewing the detailed page when user does not have any
   * divisions associated to it, it might be possible they will go directly
   */
  if (
    jdData &&
    !workspaces.some((ws) => +ws.id === +jdData.division!) &&
    !hasInternalAccess
  ) {
    return (
      <MainContainer>
        <ErrorUnauthorizedAccess />
      </MainContainer>
    );
  }

  return (
    <>
      <DocumentTitle
        title={jdData?.title ?? jobDescriptionId!}
        trailingTitle='Jobs | Team Settings'
      />
      <AIAssistSideSheet
        inPage
        withToolbar
        withSubToolbar
        title={aiAssist.title}
        headerSx={{
          height: 'initial',
        }}
        aiAssist={aiAssist}
        onClose={aiAssist.close}
      >
        <MainContainer flex sx={{ maxWidth: null, pb: 0 }}>
          <AppActionToolbar>
            <M3IconButton
              onClick={() => {
                navigate(getRoutes().basePath);
              }}
              className='m3-icon-button-back-detail-button'
            >
              <ArrowBackIcon />
            </M3IconButton>
          </AppActionToolbar>
          <IntercomBreadcrumbs items={getRoutes().breadCrumbs} />
          <br />
          <JobDescriptionDetail
            editable={editable}
            withDownloadPDF={editable}
            withDelete={editable}
            aiAssist={aiAssist}
            jobDescriptionId={jobDescriptionId}
          />
        </MainContainer>
      </AIAssistSideSheet>
      <WindowScrollTop />
    </>
  );
};

export default SettingsTeamJobDetail;
