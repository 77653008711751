import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { SearchOutlined } from '@mui/icons-material';
import { Box, Divider, List, Stack, useMediaQuery } from '@mui/material';

import { SearchType, SearchTypeOptions } from './PromoteDemoteUserFormModal';
import TypeDropdown from './TypeDropdown';

import { useForm } from '../../BasicForm';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';
import { M3TextField } from '../../M3/M3TextField';
import PageWait from '../../PageWait';
import SearchPreviewUserResult, {
  SearchPreviewUserResultSkeleton,
} from '../../SearchPreviewUserResult';

import {
  UseJobseekersProps,
  useJobseekers,
} from '../../../hooks/career-center';
import { useInfinite } from '../../../hooks/global/useInfinite';
import { useDepartments } from '../../../hooks/department';
import { usePagination } from '../../../hooks/global/usePagination';
import { UseMembersProps, useMembers } from '../../../hooks/member';
import { usePostUserProvisionSetUserType } from '../../../hooks/user-provisioning';
import { useDebounce } from '../../../hooks/utils/misc';
import { JobSeekerItemResponse } from '../../../types/jobseeker';
import { MemberModel } from '../../../types/member';
import JobSeekerPreviewAsItemResult from '../../UserJobseekers/JobSeekerPreviewAsItemResult';
import { defaultListResult } from '../../../types/response';
import { getRawCodeMessage } from '../../../utils/response';

type FormState = {
  search: string;
};

type PromoteDemoteUserFormProps = {
  searchType: SearchType;
  searchTypes: SearchTypeOptions;
  setSearchType: Dispatch<SetStateAction<SearchType>>;
};

const PromoteDemoteUserForm: React.FC<PromoteDemoteUserFormProps> = ({
  searchType,
  searchTypes,
  setSearchType,
}) => {
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const maxDesktopWidth = useMediaQuery(`(min-width: 1400px)`);
  const maxDesktopHeight = useMediaQuery(`(min-height: 1024px)`);

  const { enqueueSnackbar } = useSnackbar();
  const { params } = usePagination(defaultListResult);

  const initialMembersCount = maxDesktopWidth || maxDesktopHeight ? 30 : 20;

  const { formState, handleChange } = useForm<FormState>({
    search: '',
  });
  const search = useDebounce(formState.search);
  const confirmationDialog = useConfirmationDialog();
  const [first_name, last_name] = search.split(' ');

  const { data: departmentData } = useDepartments({
    ...params,
    name: 'Path Beta',
  });

  const departmentId = departmentData?.results?.[0].id ?? undefined;

  const members = useInfinite<MemberModel, UseMembersProps>({
    useQuery: useMembers,
    queryParams: {
      department: departmentId,
      limit: initialMembersCount,
      first_name,
      last_name,
      existing: true,
      type: 'go2_member',
    },
    skipFetchOnInit: true,
  });

  const jobseekers = useInfinite<JobSeekerItemResponse, UseJobseekersProps>({
    useQuery: useJobseekers,
    queryParams: {
      limit: initialMembersCount,
      search,
      is_active_user: true,
    },
    skipFetchOnInit: true,
  });

  const postUserProvisionSetUserType = usePostUserProvisionSetUserType();

  const rawCodeMessage = getRawCodeMessage(postUserProvisionSetUserType.error);

  const users = searchType === 'jobseeker' ? jobseekers : members;

  const handlePromoteDemoteClick = (email: string) => {
    setSelectedEmail(email);
    confirmationDialog.confirm.setIsOpen(true);
  };

  const onSubmitConfirm = () => {
    if (selectedEmail && postUserProvisionSetUserType.isLoading) return;

    postUserProvisionSetUserType.mutate({
      email: selectedEmail,
      user_type: searchType === 'jobseeker' ? 'path_beta' : 'jobseeker',
    });
  };

  useEffect(() => {
    if (searchType === 'jobseeker') {
      jobseekers.reset({
        emptyList: true,
      });
    } else if (departmentId) {
      members.reset({
        emptyList: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, search, departmentId]);

  useEffect(() => {
    if (postUserProvisionSetUserType.isSuccess) {
      if (searchType === 'jobseeker') {
        jobseekers.reset({
          emptyList: true,
        });
      } else {
        members.reset({
          emptyList: true,
        });
      }

      confirmationDialog.confirm.setIsOpen(false);

      enqueueSnackbar(
        `The selected user has been successfully ${
          searchType === 'jobseeker'
            ? 'promoted to Path Beta User.'
            : 'demoted to Jobseeker.'
        }`,
      );

      postUserProvisionSetUserType.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postUserProvisionSetUserType.isSuccess, searchType]);

  useEffect(() => {
    if (rawCodeMessage.message) {
      confirmationDialog.confirm.setIsOpen(false);

      enqueueSnackbar(rawCodeMessage.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawCodeMessage.message]);

  return (
    <Fragment>
      <Stack
        direction='row'
        gap={2}
        mt={3}
        mb={2}
        px={3}
        zIndex={300}
        width='100%'
      >
        <TypeDropdown
          searchType={searchType}
          searchTypes={searchTypes}
          setSearchType={setSearchType}
        />
        <Box
          flex={1}
          display='flex'
          alignItems='center'
          sx={{ position: 'relative', ml: -1, mr: -1.5 }}
        >
          <M3TextField
            fullWidth
            variant='filled'
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            autoSave='off'
            placeholder={`Search for user's name...`}
            name='search'
            value={formState.search}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              '.MuiFilledInput-root,.MuiOutlinedInput-notchedOutline': {
                borderRadius: 20,
              },
              '.MuiInputBase-input': {
                pl: 5.4,
              },
              '.MuiInputLabel-shrink': {
                transform: 'translate(20px, -8px) scale(0.74)',
              },
            }}
            onChange={handleChange}
          />
          <SearchOutlined
            sx={{
              top: 13,
              left: 15,
              opacity: 0.5,
              position: 'absolute',
              pointerEvents: 'none',
              color: `var(--md-sys-color-on-surface-light)`,
            }}
          />
        </Box>
      </Stack>
      <Divider />
      <Stack height='calc(60vh)' overflow='auto'>
        <List sx={{ pt: 2, pb: 2, px: 1 }}>
          {!users.isLoading && !users.data.length && (
            <PageWait
              title='No users found'
              message={
                formState.search ? (
                  <>
                    We could not find any user for{' '}
                    <strong style={{ fontWeight: 500 }}>
                      {formState.search}
                    </strong>
                    .
                  </>
                ) : (
                  <>We could not find any user.</>
                )
              }
            />
          )}
          {users.isLoading &&
            !users.data.length &&
            new Array(initialMembersCount)
              .fill(null)
              .map((_, i) => <SearchPreviewUserResultSkeleton key={i} />)}
          {users.data.map((item) => {
            if (searchType === 'jobseeker') {
              return (
                <JobSeekerPreviewAsItemResult
                  key={item.id}
                  item={item as JobSeekerItemResponse}
                  isPromoteDemote
                  onPromoteClick={handlePromoteDemoteClick}
                />
              );
            }

            return (
              <SearchPreviewUserResult
                key={item.id}
                isPromoteDemote
                item={item as MemberModel}
                onDemoteClick={handlePromoteDemoteClick}
              />
            );
          })}
        </List>
      </Stack>
      <ConfirmationDialog
        {...confirmationDialog.confirm}
        title={
          searchType === 'jobseeker'
            ? 'Promote User Confirmation'
            : 'Demote User Confirmation'
        }
        message={`Are you sure you want to ${
          searchType === 'jobseeker' ? 'promote' : 'demote'
        } the selected user?`}
        isLoading={postUserProvisionSetUserType.isLoading}
        onConfirm={onSubmitConfirm}
      />
    </Fragment>
  );
};

export default PromoteDemoteUserForm;
