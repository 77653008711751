import { AxiosRequestConfig } from 'axios';
import {
  IntercomAdmin,
  IntercomArticle,
  IntercomCollection,
  IntercomSection,
} from '../types/intercom';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { QueryParams, useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

type UseIntercomCollectionProps = ListQuery;
export function useIntercomCollections<R = IntercomCollection>(
  params: UseIntercomCollectionProps,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseIntercomCollectionProps>({
      params,
      baseIdentifier: 'useIntercomCollections',
    });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/intercom-auth0/helpcenter-collections/',
    queryParams,
    defaultReactQueryParams,
  );
}

type UseIntercomCollectionByIdProps = {
  id: string;
};
export function useIntercomCollectionById<R = IntercomCollection>(
  params: UseIntercomCollectionByIdProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const { identifiers, queryParams } =
    useIdentifier<UseIntercomCollectionByIdProps>({
      params,
      baseIdentifier: 'useIntercomCollectionById',
      identifierKeys: ['id'],
    });

  return useQueryApi<any, R>(
    identifiers,
    `/intercom-auth0/helpcenter-collections/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseIntercomSectionProps = ListQuery & {
  parent_id?: string;
};
export function useIntercomSections<R = IntercomSection>(
  params: UseIntercomSectionProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const { identifiers, queryParams } = useIdentifier<UseIntercomSectionProps>({
    params,
    baseIdentifier: 'useIntercomSections',
    identifierKeys: ['parent_id'],
    queryParamKeys: ['parent_id'],
  });

  return useQueryApi<UseIntercomSectionProps, ListResult<R>>(
    identifiers,
    '/intercom-auth0/helpcenter-sections/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseIntercomSectionByIdProps = {
  id: string;
};
export function useIntercomSectionById<R = IntercomSection>(
  params: UseIntercomSectionByIdProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const { identifiers, queryParams } =
    useIdentifier<UseIntercomSectionByIdProps>({
      params,
      baseIdentifier: 'useIntercomSectionById',
      identifierKeys: ['id'],
    });

  return useQueryApi<any, R>(
    identifiers,
    `/intercom-auth0/helpcenter-sections/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseIntercomArticlesProps = ListQuery & {
  parent_id?: string;
  state?: string;
};
export function useIntercomArticles<R = IntercomArticle>(
  params: UseIntercomArticlesProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const { identifiers, queryParams } = useIdentifier<UseIntercomArticlesProps>({
    params,
    baseIdentifier: 'useIntercomArticles',
    identifierKeys: ['parent_id', 'state'],
    queryParamKeys: ['parent_id', 'state'],
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/intercom-auth0/articles/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseIntercomArticleByIdProps = {
  id: string;
};
export function useIntercomArticleById<R = IntercomArticle>(
  params: UseIntercomArticleByIdProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const { identifiers, queryParams } =
    useIdentifier<UseIntercomArticleByIdProps>({
      params,
      baseIdentifier: 'useIntercomArticleById',
      identifierKeys: ['id'],
    });

  return useQueryApi<any, R>(
    identifiers,
    `/intercom-auth0/articles/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseIntercomAdminsProps = ListQuery & {};
export function useIntercomAdmins<R = IntercomAdmin>(
  params: UseIntercomAdminsProps,
  reactQueryParams: Partial<QueryParams> = {},
) {
  const { identifiers, queryParams } = useIdentifier<UseIntercomAdminsProps>({
    params,
    baseIdentifier: 'useIntercomAdmins',
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/intercom-auth0/admins/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseIntercomArticlesInCollectionProps = ListQuery & {
  collectionId?: string;
};
export function useIntercomArticlesInCollection<R = IntercomArticle>(
  params: UseIntercomArticlesInCollectionProps,
  reactQueryParams?: Partial<QueryParams>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseIntercomArticlesInCollectionProps>({
      params: {
        ...params,
        key: params.key ? params.key : 'articlesInCollections',
      },
      baseIdentifier: 'useIntercomArticlesInCollection',
      identifierKeys: ['collectionId'],
      queryParamKeys: ['collectionId'],
    });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    `/intercom-auth0/helpcenter-collections/${params.collectionId}/articles/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseIntercomFieldSearchParams = ListQuery & {
  field: 'division' | 'teammate';
  q?: string;
};
export function useIntercomFieldSearch<R>(
  params: UseIntercomFieldSearchParams,
  reactQueryParams?: Partial<QueryParams>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseIntercomFieldSearchParams>({
      params,
      baseIdentifier: 'useIntercomFieldSearch',
      identifierKeys: ['field', 'q'],
      queryParamKeys: ['q'],
    });

  return useQueryApi<UseIntercomFieldSearchParams, R[]>(
    identifiers,
    `/intercom-auth0/${params.field}-search/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UsePostHelpEngageProps = {
  division_id: number | null;
  teammate_id?: number | null;
  level_of_urgency: 'Low' | 'Medium' | 'High' | 'Urgent' | string;
  issue_details: string;
  resource_urls: string;
  proposed_resolutions: string;
  intercom_user_id: string;
};
export function usePostHelpEngage(
  data?: any,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UsePostHelpEngageProps>(
    `/intercom-auth0/create-help-engage/`,
    {},
    axiosConfig,
  );
}

export type UsePostHelpBookkeepingProps = {
  division_id: number | null;
  teammate_ids: string[];
  amount: string;
  priority_request: string;
};
export function usePostHelpBookkeeping(
  data?: any,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UsePostHelpBookkeepingProps>(
    `/intercom-auth0/create-help-bk/`,
    {},
    axiosConfig,
  );
}
