import React, { CSSProperties } from 'react';
import { Box } from '@mui/material';

import winIconPath from '../assets/svgs/win-merit-badge.svg';
import shipIconPath from '../assets/svgs/ship-merit-badge.svg';
import suckItIconPath from '../assets/svgs/suck-it-merit-badge.svg';

type MeritBadgeProps = {
  type: 'win' | 'ship' | 'suck-it';
  size?: 'small' | 'medium' | 'large';
};

const MeritBadge = ({ type = 'win', size = 'medium' }: MeritBadgeProps) => {
  let img: string | null = null;
  let dim: CSSProperties = {};

  if (type === 'win') {
    img = winIconPath;
  } else if (type === 'ship') {
    img = shipIconPath;
  } else if (type === 'suck-it') {
    img = suckItIconPath;
  }

  if (size === 'small') {
    dim.width = 40;
    dim.height = 40;
  } else if (size === 'medium') {
    dim.width = 56;
    dim.height = 56;
  } else if (size === 'large') {
    dim.width = 60;
    dim.height = 60;
  }

  return (
    <Box
      sx={{
        ...dim,
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <Box
        component='img'
        src={img!}
        sx={{
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      />
    </Box>
  );
};

export default MeritBadge;
