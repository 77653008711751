/** dependencies */
import { FC, ReactElement, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'moment-timezone';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

/** components */
import { getMediaQueryState } from './components/Util/MediaQueryListener';
import PageLazyLoader, {
  PageLazyLoaderOptions,
  PageLazyLoaderProps,
} from './components/PageLazyLoader';
import AppLoader from './components/Loaders/AppLoader';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import HtmlBodyListener from './components/Util/HtmlBodyListener';
import M3ThemePalette from './components/M3/M3ThemePalette';
import PushNotificationListener from './components/PushNotificationListener/PushNotificationListener';

/** hooks */
import { useCurrentProfile } from './hooks/profile';

/** pages */
import Content from './pages/Content';
import DataRoom from './pages/DataRoom';
import DataRoomEmbed from './pages/DataRoomEmbed';
import DomoEmbed from './pages/DomoEmbed';
import HelpFAQ from './pages/HelpFAQ';
import Inbox from './pages/Inbox';
import InboxAll from './pages/InboxAll';
import InboxAllDetail from './pages/InboxAllDetail';
import InboxChat from './pages/InboxChat';
import InboxSupport from './pages/InboxSupport';
import Intercom from './pages/Intercom';
import Login from './pages/Login';
import RequestTimeOff from './pages/RequestTimeOff';
import SearchAnswers from './pages/SearchAnswers';
import SearchAnswersNumbers from './pages/SearchAnswersNumbers';
import Settings from './pages/Settings';
import SettingsAdminForms from './pages/SettingsAdminForms';
import SignUpJobseekers from './pages/SignUpJobseekers';
import SignUpNumbers from './pages/SignUpNumbers';
import SlackConnect from './pages/SlackConnect';
import Team from './pages/Team';
import TeamManifests from './pages/TeamManifests';
import Builder from './pages/Builder';
import PathBeta from './pages/PathBeta';
import Sidecar from './pages/Sidecar';

/** providers */
import { AppProvider } from './providers/app/app';
import { AuthProvider, useAuthProvider } from './providers/auth/auth';
import { IntercomProvider } from './providers/intercom/intercom';

/** services */
import * as hotjar from './services/hotjar';
import * as posthog from './services/posthog';
import * as pusher from './services/pusher';

/** types */
import { ReactRenderElement } from './types/types';

/** utils */
import { gtagPush } from './utils/gtag';
import { setToLocalStorage } from './utils/localstorage';
import { parseURL } from './utils/url';

/** app */
import App, { ProviderWrapper } from './App';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import UserJobSeekerHome from './UserJobSeekerHome';
import UserNumbersHome from './UserNumbersHome';

/** styles */
import './styles';
import theme from './assets/theme';

/**
 * Initialize the sentry the start of everything
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN!,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['*'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: +process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE!,
  });
}

const queryClient = new QueryClient();
/**
 * Wrapper for inner pages component for lazy loading
 */
const loadInnerPage = (
  Component: FC,
  pageLoaderProps?: PageLazyLoaderProps,
  { CustomLoader }: PageLazyLoaderOptions = {} as PageLazyLoaderOptions,
) => (
  <Suspense
    fallback={
      CustomLoader ? <CustomLoader /> : <PageLazyLoader {...pageLoaderProps} />
    }
  >
    <Component />
  </Suspense>
);

const RenderRoutes = () => {
  const { isAuthenticated, isDoneAuthenticating, getTokenSilently } =
    useAuthProvider();
  const { isLoading, data: userProfile } = useCurrentProfile(
    {},
    { enabled: isAuthenticated },
  );
  let content: ReactRenderElement = null;
  let loader: ReactRenderElement = null;
  const parsedURL = parseURL(window.location.search);

  if (!isDoneAuthenticating || (!userProfile && isLoading)) {
    loader = <AppLoader />;
  }

  if (!isDoneAuthenticating || (!userProfile && isLoading)) {
    content = null;
  } else if (userProfile?.roles.indexOf('jobseekers')! > -1) {
    content = renderUserJobseekerRoutes();
  } else if (userProfile?.roles.indexOf('numbers')! > -1) {
    content = renderUserNumberRoutes();
  } else {
    content = renderMainRoutes();
  }

  useEffect(() => {
    if (userProfile) {
      /**
       * Set the sentry to attach user on all calls
       */
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: `${userProfile.id}`,
          email: userProfile.email,
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
        });
      });

      /**
       * Save the type(role) of user who last logged in the storage
       */
      setToLocalStorage(
        'last_user_login_role',
        userProfile.roles.join(','),
        true,
      );
      /**
       * Save the viewing in mobile for logged in user in the storage
       */
      setToLocalStorage(
        'last_user_is_in_mobile',
        getMediaQueryState(window.innerWidth).mobile,
        true,
      );

      /**
       * Initialize hotjar for job seekers, go2 members already have FIN so we don't need it
       */
      if (
        process.env.NODE_ENV === 'production' &&
        userProfile.roles.indexOf('jobseekers') > -1
      ) {
        hotjar.init();
        hotjar.identify(userProfile);
      }

      /**
       * Trigger the config once we have the user id
       */
      gtagPush('config', { user_id: userProfile.id });

      /**
       * Initialize the PostHog to attach the user to all actions
       */
      posthog.identify(userProfile.id, {
        email: userProfile.email,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        roles: userProfile.roles,
      });

      /**
       * Initialize pusher on init, passing token silently
       */
      pusher
        .init({
          getTokenSilently,
        })
        .signin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, getTokenSilently]);

  return (
    <>
      <M3ThemePalette />
      <HtmlBodyListener />
      {parsedURL.report_type_platform !== 'desktop' && loader}
      {isDoneAuthenticating && (!isLoading || !!userProfile) && (
        <>
          <Routes>{content}</Routes>
          <PushNotificationListener />
        </>
      )}
    </>
  );
};

const renderUnauthorizedRoutes = () => {
  return (
    <>
      <Route path='/login' element={<Login />} />
      <Route path='/signup/numbers' element={<SignUpNumbers />} />
      <Route path='/signup' element={<SignUpJobseekers />} />
    </>
  );
};

/**
 * List of routes that need authentication, should passed children
 * that requires authentication
 */
const renderAuthorizedRoutes = (children: ReactRenderElement) => {
  return (
    <>
      <Route path='/' element={<App />}>
        <Route path='/slack/connect' element={<SlackConnect />} />
        <Route path='/embed/pages/private/:embedId' element={<DomoEmbed />} />
        {children}
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </>
  );
};

const renderMainRoutes = () => {
  return (
    <>
      {renderUnauthorizedRoutes()}
      {renderAuthorizedRoutes(
        <Route path='' element={<Home />}>
          <Route
            path='/request/time-off'
            element={loadInnerPage(RequestTimeOff)}
          />
          <Route path='/chat/message/:id' element={<InboxChat />} />
          <Route path='/chat/message' element={<InboxChat />} />
          <Route path='/content' element={loadInnerPage(Content)}>
            <Route
              path='/content/target-audience/:audienceSegmentId/collection/:collectionId'
              element={null}
            />
            <Route
              path='/content/target-audience/:audienceSegmentId'
              element={null}
            />
            <Route path='/content/target-audience' element={null} />
            <Route path='/content/collection/:collectionId' element={null} />
            <Route path='/content/collection' element={null} />
            <Route path='/content/section/:sectionId' element={null} />
            <Route path='/content/section' element={null} />
            <Route
              path='/content/article/:articleId/collection/:collectionId'
              element={null}
            />
            <Route
              path='/content/article/:articleId/collection'
              element={null}
            />
            <Route path='/content/article/:articleId' element={null} />
            <Route path='/content/article' element={null} />
          </Route>

          <Route path='/builder' element={loadInnerPage(Builder)}>
            <Route path='/builder/jobs/create' element={null} />
            <Route path='/builder/jobs/:jobDescriptionId' element={null} />
            <Route path='/builder/jobs' element={null} />
            <Route path='/builder/focus/create' element={null} />
            <Route path='/builder/focus/:focusId' element={null} />
            <Route path='/builder/focus' element={null} />
          </Route>

          <Route path='/sidecar' element={loadInnerPage(Sidecar)}>
            <Route path='/sidecar/go2-desktop' element={null} />
          </Route>

          <Route path='/inbox' element={loadInnerPage(Inbox)}>
            <Route
              path='/inbox/forms/request/time-off'
              element={loadInnerPage(RequestTimeOff)}
            />
            <Route
              path='/inbox/forms/additional-teammate'
              element={
                <SettingsAdminForms basePath='/forms/additional-teammate' />
              }
            />
            <Route
              path='/inbox/forms/bonus'
              element={<SettingsAdminForms basePath='/forms/give-a-bonus' />}
            />
            <Route
              path='/inbox/forms/new-hire-setup'
              element={<SettingsAdminForms basePath='/help/new-hire-setup' />}
            />
            <Route
              path='/inbox/forms/issue/attendance'
              element={<SettingsAdminForms basePath='/help/attendance' />}
            />
            <Route
              path='/inbox/forms/issue/tools'
              element={<SettingsAdminForms basePath='/help/tools' />}
            />
            <Route
              path='/inbox/forms/issue/bk'
              element={<SettingsAdminForms basePath='/help/bk' />}
            />
            <Route
              path='/inbox/forms/issue/engage'
              element={<SettingsAdminForms basePath='/help/engage' />}
            />
            <Route path='/inbox/support' element={<InboxSupport />} />
            <Route
              path='/inbox/all/:type/:id/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/request/:ptoId/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/schedule-change-request/:scheduleBatchId/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/go2bots/:go2botsId/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/help-needed/:sodEodId/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/daily-report/:sodEodId/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/job-description/:jobDescriptionId/read'
              element={loadInnerPage(InboxAllDetail)}
            />
            <Route
              path='/inbox/all/:tabIndex'
              element={loadInnerPage(InboxAll)}
            />
            <Route
              path='/inbox/go2bots/:tabIndex'
              element={loadInnerPage(InboxAll)}
            />
            <Route
              path='/inbox/help-needed/:tabIndex'
              element={loadInnerPage(InboxAll)}
            />
            <Route path='' element={<Navigate to='/inbox/all/0' />} />
          </Route>
          <Route
            path='/manifest/:workspaceId'
            element={loadInnerPage(TeamManifests)}
          />
          <Route
            path='/answers/numbers/:workspaceId'
            element={loadInnerPage(SearchAnswersNumbers)}
          />
          <Route
            path='/answers/words/:workspaceId'
            element={loadInnerPage(SearchAnswers)}
          />
          <Route path='/team' element={loadInnerPage(Team)}>
            <Route path='/team/calendar/:workspaceId' element={null} />
            <Route path='/team/time/:workspaceId' element={null} />
            <Route path='/team/schedule/:workspaceId' element={null} />
            <Route path='/team/utilization/:workspaceId' element={null} />
            <Route
              path='/team/jobs/:workspaceId/:jobDescriptionId'
              element={null}
            />
            <Route path='/team/jobs/:workspaceId' element={null} />
            <Route path='/team/focus/:workspaceId/:focusId' element={null} />
            <Route path='/team/focus/:workspaceId' element={null} />
            <Route path='/team/resources/:workspaceId' element={null} />
            <Route path='/team/1-1s/team-1-1s/:workspaceId' element={null} />
            <Route path='/team/1-1s/my-1-1s/:workspaceId' element={null} />
            <Route
              path='/team/sprints/team-sprints/:workspaceId'
              element={null}
            />
            <Route
              path='/team/sprints/my-sprints/:workspaceId'
              element={null}
            />
          </Route>

          <Route
            path='/settings'
            element={<Navigate to='/settings/profile' />}
          />
          <Route path='/settings' element={loadInnerPage(Settings)}>
            <Route path='/settings/personal/profile' element={null} />
            <Route path='/settings/personal/edit-time' element={null} />
            <Route path='/settings/admin/schedule' element={null} />
            <Route path='/settings/admin/utilization' element={null} />
            <Route path='/settings/admin/preview-as' element={null} />
            <Route path='/settings/admin/user-provisioning' element={null} />
            <Route path='/settings/admin/edit-time' element={null} />
            <Route path='/settings/admin/forms' element={null}>
              <Route path='/settings/admin/forms/:segment' element={null} />
            </Route>
            <Route path='/settings/admin' element={null} />
            <Route path='/settings/system-admin/custom' element={null} />
            <Route path='/settings/system-admin' element={null} />
          </Route>
          <Route
            path='/help/faq/*'
            element={loadInnerPage(HelpFAQ, {
              withDrawer: false,
            })}
          />
          <Route path='/help/center/*' element={loadInnerPage(Intercom)} />
          <Route path='/help' element={<Navigate to='/help/center' />} />
        </Route>,
      )}
    </>
  );
};

/**
 * Routes for user having role 'numbers'
 */
const renderUserNumberRoutes = () => {
  return (
    <>
      {renderUnauthorizedRoutes()}
      {renderAuthorizedRoutes(
        <Route path='' element={<UserNumbersHome />}>
          <Route path='/embed/:segment' element={<DataRoomEmbed />} />
          <Route path='/embed' element={<DataRoomEmbed />} />
          <Route
            path='/help/dataroom'
            element={<DataRoom basePath='/help/dataroom' />}
          >
            <Route
              path='/help/dataroom/collection/:collectionId'
              element={null}
            />
            <Route path='/help/dataroom' element={null} />
          </Route>
          <Route path='/help' element={<Navigate to='/help/dataroom' />} />
          <Route path='/' element={<Navigate to='/embed' />} />
        </Route>,
      )}
    </>
  );
};

/**
 * Routes for user having role 'jobseekers'
 */
const renderUserJobseekerRoutes = () => {
  return (
    <>
      {renderUnauthorizedRoutes()}
      {renderAuthorizedRoutes(
        <Route path='' element={<UserJobSeekerHome />}>
          <Route path='/path' element={<PathBeta />} />
          <Route path='/inbox/support' element={<InboxSupport />} />
          <Route path='/inbox' element={<Navigate to='/inbox/support' />} />
          <Route path='/help/*' element={<Navigate to='/' />} />
          <Route path='/*' element={<Intercom basePath='/' />} />
          <Route path='/' element={<Intercom basePath='/' />} />
        </Route>,
      )}
    </>
  );
};

/**
 * List of providers that doesn't need authorized user
 */
function RootDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  const providers: ProviderWrapper[] = [AppProvider, IntercomProvider];

  return providers.reduceRight(
    (children, Provider: ProviderWrapper) => <Provider>{children}</Provider>,
    children,
  ) as ReactElement;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <RootDataProviders>
                    <RenderRoutes />
                  </RootDataProviders>
                </SnackbarProvider>
              </LocalizationProvider>
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </StyledEngineProvider>
    </ThemeProvider>
    <ErrorBoundary>
      <Analytics />
    </ErrorBoundary>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
 * Disabling react beautiful warning
 */
(window as any)['__react-beautiful-dnd-disable-dev-warnings'] = true;
