import React from 'react';
import { Box, Typography } from '@mui/material';

import { NotificationPayload } from '../../types/notification';

type NotificationProps = {
  notification: NotificationPayload | null;
};

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  return (
    <Box sx={{ cursor: 'pointer' }} display='flex' flexDirection='column'>
      <Typography fontWeight={600} variant='subtitle2'>
        {notification?.title}
      </Typography>
      <Typography variant='subtitle2'>
        {notification?.body?.split('.')?.[0] + '.'}
      </Typography>
    </Box>
  );
};

export default Notification;
