import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  PaperProps,
  Popover,
  PopoverProps,
  Typography,
} from '@mui/material';

import { M3Autocomplete, M3AutocompleteProps } from '../M3/M3Autocomplete';
import { M3TextField } from '../M3/M3TextField';
import { M3MenuItem } from '../M3/M3MenuItem';

import { useAppProvider } from '../../providers/app/app';

type BasicPopoverWithSearchProps = {
  open: boolean;
  withSearch?: boolean;
  loading?: boolean;
  placeholder?: string;
  anchorEl: HTMLElement | null;
  options: M3OptionItem[];
  selected?: M3OptionItem | null;
  onClose?: () => void;
  onSelect?: (item: M3OptionItem) => void;
  onChange?: M3AutocompleteProps<M3OptionItem>['onChange'];
  renderOption?: M3AutocompleteProps<M3OptionItem>['renderOption'];
  popoverProps?: {
    anchorOrigin?: PopoverProps['anchorOrigin'];
    transformOrigin?: PopoverProps['transformOrigin'];
  };
  paperProps?: PaperProps;
  disablePortal?: boolean;
  maxRowVisible?: number;
  itemHeight?: number;
};
export type M3OptionItem<T = any> = {
  id: number | string;
  label: string;
  props?: T;
};

type BasicPopoverProps = {
  isOpen: boolean;
};
export type UseBasicPopoverRet = {
  isOpen: boolean;
  ref: MutableRefObject<HTMLElement | null> | RefObject<HTMLElement | null>;
  open: () => void;
  close: () => void;
};
export function useBasicPopover(): UseBasicPopoverRet {
  const ref = useRef<HTMLElement | null>(null);
  const [state, setState] = useState<BasicPopoverProps>({
    isOpen: false,
  });

  const openPopover = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: true,
    }));
  }, []);

  const closePopover = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: false,
    }));
  }, []);

  const ret = useMemo(() => {
    return {
      ...state,
      ref,
      open: openPopover,
      close: closePopover,
    };
  }, [state, openPopover, closePopover, ref]);

  return ret;
}

const menuInputHeight = 50;
const BasicPopoverWithSearch = ({
  open,
  loading,
  anchorEl,
  placeholder,
  onClose,
  options,
  selected,
  renderOption,
  popoverProps,
  onSelect,
  paperProps,
  withSearch = true,
  disablePortal = false,
  maxRowVisible = 5,
  itemHeight = 52,
}: BasicPopoverWithSearchProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      transitionDuration={0}
      onClose={onClose}
      anchorOrigin={{
        vertical: 0,
        horizontal: -8,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disablePortal={disablePortal}
      PaperProps={{
        ...paperProps,
        style: {
          width: 240,
          overflow: 'hidden',
          ...paperProps?.style,
        },
      }}
      {...popoverProps}
    >
      <Box
        sx={{
          height:
            itemHeight * options.length +
            itemHeight -
            (withSearch ? 0 : menuInputHeight),
          maxHeight:
            itemHeight * maxRowVisible +
            itemHeight -
            (withSearch ? 0 : menuInputHeight),
          '.MuiAutocomplete-listbox': {
            padding: 0,
            width: '100%',
            overflow: options.length > maxRowVisible ? 'hidden auto' : 'hidden',
            maxHeight: itemHeight * maxRowVisible,
          },
          '.MuiPaper-root': {
            borderRadius: 0,
            boxShadow: 'none !important',
          },
          ...(!withSearch
            ? {
                '.MuiAutocomplete-root': {
                  left: 0,
                  right: 0,
                  position: 'absolute',
                },
                '.MuiAutocomplete-popper': {
                  // opacity: 0,
                  transform: 'none !important',
                },
              }
            : null),
        }}
      >
        <M3Autocomplete
          open
          disablePortal
          options={options}
          renderInput={(params) => {
            return (
              <M3TextField
                {...params}
                fullWidth
                autoFocus
                placeholder={placeholder ?? 'Search...'}
                InputLabelProps={{
                  focused: true,
                }}
                sx={{
                  height: withSearch ? menuInputHeight : 0,
                  opacity: withSearch ? 1 : 0,
                  visibility: withSearch ? undefined : 'hidden',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderRadius: 0,
                    border: '0 !important',
                  },
                  '.MuiInputBase-root': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    background: `${
                      isDarkMode
                        ? 'var(--md-ref-palette-neutral20)'
                        : 'var(--md-ref-palette-neutral90)'
                    } !important`,
                  },
                }}
              />
            );
          }}
          renderOption={
            renderOption ??
            ((_, option: M3OptionItem) => {
              return (
                <BasicItemOption
                  key={option.id}
                  option={option}
                  active={option.id === selected?.id}
                  onSelect={onSelect}
                  height={itemHeight}
                />
              );
            })
          }
          loading={loading}
          loadingText={<BasicItemNotFound label='Loading...' />}
          noOptionsText={<BasicItemNotFound />}
          sx={{
            '.MuiInputBase-formControl': {
              pt: 0,
              pb: 0,
            },
          }}
        />
      </Box>
    </Popover>
  );
};

export default BasicPopoverWithSearch;

type BasicItemOptionProps = {
  option: M3OptionItem;
  active?: boolean;
  height?: number;
  onSelect?: BasicPopoverWithSearchProps['onSelect'];
};
export function BasicItemOption({
  option,
  active,
  onSelect,
  height,
}: BasicItemOptionProps) {
  return (
    <M3MenuItem
      data-id={option.id}
      active={active}
      sx={{ height }}
      onClick={() => onSelect?.(option)}
    >
      <Typography
        flex={1}
        width={0}
        fontSize={14}
        component='span'
        lineHeight={1.3}
        title={option.label}
        whiteSpace='nowrap'
        className='text-truncate'
      >
        {option.label}
      </Typography>
    </M3MenuItem>
  );
}

type BasicItemNotFoundProps = {
  label?: string;
};
export function BasicItemNotFound({ label }: BasicItemNotFoundProps) {
  return (
    <Typography
      top={-6}
      fontSize={14}
      component='span'
      lineHeight={1.3}
      position='relative'
    >
      {label ?? 'No Matches Found'}
    </Typography>
  );
}
