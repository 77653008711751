import React, { CSSProperties } from 'react';
import { List, Box, Chip } from '@mui/material';
import DOMPurify from 'dompurify';
import moment from 'moment';

import InboxCardListItemSkeleton from './InboxCardListItemSkeleton';
import LoadMorePlaceholder from '../LoadMorePlaceholder';
import InboxListItem from './InboxListItem';

import {
  PTOItemUser,
  PTONotificationApproveRejectItemMetadata,
  PTONotificationCommentItem,
  PTONotificationItem,
  PTONotificationItemMetadata,
} from '../../types/pto';
import {
  DailyReportNotificationItem,
  EODReportData,
  HelpNeededReportNotificationItem,
  ReportingReportItemResponse,
  SODReportData,
} from '../../types/report';
import { useUserProvider } from '../../providers/user/user';
import { toURL } from '../../utils/url';
import { UserMetadata } from '../../types/profile';
import { UseToggleSelectionRet } from '../../hooks/global/useToggleSelection';
import { useNotificationProvider } from '../../providers/notification/notification';
import {
  InboxNotificationItem,
  InboxPageParamsRet,
} from '../../pages/InboxAll';
import { toTitleCase } from '../../utils/string';
import { IterableObject } from '../../types/types';
import { hrTimeOffTypes } from '../../utils/pto';
import { useAppProvider } from '../../providers/app/app';
import { JobDescriptionNotificationItem } from '../../types/job-description';
import * as posthog from '../../services/posthog';
import { Go2BotsNotificationItem } from '../../types/go2bots';
import { NotificationVerb } from '../../types/notification';
import { ScheduleChangeRequestNotificationItem } from '../../types/schedules';
import { getTemporaryFormattedSchedules } from '../Schedule/TemporaryChangeRequestScheduleListItem';

type InboxAllListProps = {
  isLoading?: boolean;
  isLoadingMore?: boolean;
  hasReachEnd?: boolean;
  items: InboxNotificationItem[];
  toggleSelection?: UseToggleSelectionRet;
  paths: InboxAllPaths;
  listQuery: InboxPageParamsRet['listQuery'];
};

export type InboxAllPaths = {
  tabIndex: number;
  base?: string;
  dailyReport?: string;
  request?: string;
  jobDescription?: string;
  helpNeeded?: string;
  go2bots?: string;
  scheduleRequest?: string;
};

type PTOExtract = {
  id: number | string;
  employeeId: string;
  notes: {
    employee: string;
    [key: string]: string;
  };
};

const InboxAllList = ({
  toggleSelection,
  isLoading,
  isLoadingMore,
  hasReachEnd,
  items,
  paths,
  listQuery,
}: InboxAllListProps) => {
  const { isDarkMode } = useAppProvider();
  const { getUser } = useUserProvider();
  const { setInboxItemState } = useNotificationProvider();
  const minSkeletonSize = 10;

  const extractDailyReportData = (item: DailyReportNotificationItem) => {
    if (item.items[0].verb === 'mentioned_report') {
      let content: string = '';
      let user =
        getUser('user_id', item.items[0].actor_object_id) ??
        ({
          email: item.items[0].metadata.user_email,
        } as Partial<UserMetadata>);
      // metadata here is plan/reality data
      let metadata = item.items[0]
        .metadata as unknown as ReportingReportItemResponse<
        SODReportData | EODReportData
      >;
      let objectId =
        metadata.type === 'SOD' ? metadata.id : metadata.plan || metadata.id;

      content = `Mentioned you in the report`;

      return {
        user,
        content,
        object_id: objectId,
      };
    }

    return {
      user:
        getUser('user_id', item.items[0].metadata.user) ??
        ({
          email: item.items[0].metadata.user_email,
          employee_id: item.items[0].metadata.user_employee_id,
          first_name: item.items[0].metadata.user_first_name,
          last_name: item.items[0].metadata.user_last_name,
        } as Partial<UserMetadata>),
      content: DOMPurify.sanitize(item.items[0].metadata.content, {
        ALLOWED_TAGS: [],
      }),
      object_id: item.items[0].metadata.object_id,
    };
  };

  const renderDailyReportItem = (
    item: DailyReportNotificationItem,
    index: number,
  ) => {
    let { user, content, object_id } = extractDailyReportData(item);
    const url = toURL(`${paths.dailyReport}/${object_id}/read`, {
      t: paths.tabIndex,
      notification_id: item.id,
    });
    let tag = renderTagTopic('Daily Report', {});

    return (
      <InboxListItem
        key={item.items[0].id}
        url={url}
        user={user}
        item={{
          id: item.items[0].id,
          unread: item.items[0].unread,
          created: item.items[0].metadata.created,
          modified: item.items[0].metadata.modified,
          content: content,
          object_id,
        }}
        withCheckbox={false}
        tag={tag}
        selected={toggleSelection?.selected[item.items[0].id]}
        onSelect={(selected: boolean, data) => {
          toggleSelection?.setSelected(data.id, selected);
          toggleSelection?.deselectAll();
        }}
        onClick={() => {
          if (item.items[0].unread) {
            posthog.capture('inbox unread message clicked', {
              item_id: item.items[0].id,
            });
          } else {
            posthog.capture('inbox read message clicked', {
              item_id: item.items[0].id,
            });
          }

          setInboxItemState({
            item,
            paths,
            itemIndex: index,
            tabIndex: paths.tabIndex,
            listQuery,
          });
        }}
      />
    );
  };

  const extractJobDescriptionData = (item: JobDescriptionNotificationItem) => {
    const { id, metadata } = item.items[0];
    const {
      content: metadata_content,
      object_id,
      user: metadata_user,
      user_email,
      user_employee_id,
      user_first_name,
      user_last_name,
    } = metadata;

    const jobDescriptionId = object_id;
    const tag = renderTagTopic('Job Description', {});
    const url = toURL(`${paths.jobDescription}/${jobDescriptionId}/read`, {
      t: paths.tabIndex,
      notification_id: id,
    });
    const user =
      getUser('user_id', metadata_user) ??
      ({
        email: user_email,
        employee_id: user_employee_id,
        first_name: user_first_name,
        last_name: user_last_name,
      } as Partial<UserMetadata>);
    const content = DOMPurify.sanitize(metadata_content, { ALLOWED_TAGS: [] });

    return {
      id,
      user,
      content,
      jobDescriptionId,
      tag,
      url,
    };
  };
  const renderJobDescriptionItem = (
    item: JobDescriptionNotificationItem,
    index: number,
  ) => {
    const { unread, metadata } = item.items[0];
    const { id, user, content, tag, url, jobDescriptionId } =
      extractJobDescriptionData(item);
    return (
      <InboxListItem
        key={id}
        url={url}
        user={user}
        item={{
          id,
          unread,
          content,
          created: metadata.created,
          modified: metadata.modified,
          object_id: jobDescriptionId,
        }}
        withCheckbox={false}
        tag={tag}
        selected={toggleSelection?.selected[item.items[0].id]}
        onSelect={(selected: boolean, data) => {
          toggleSelection?.setSelected(data.id, selected);
          toggleSelection?.deselectAll();
        }}
        onClick={() => {
          setInboxItemState({
            item,
            paths,
            itemIndex: index,
            tabIndex: paths.tabIndex,
            listQuery,
          });
        }}
      />
    );
  };

  const extractPTOData = (
    item: PTONotificationItem | PTONotificationCommentItem,
  ): {
    user: UserMetadata | PTOItemUser | null;
    pto: PTOExtract;
  } => {
    if (item.topic === 'comments') {
      item = item as PTONotificationCommentItem;
      return {
        pto: {
          id: item.items[0].metadata.object_id,
          employeeId: item.items[0].metadata.user_employee_id,
          notes: {
            employee: DOMPurify.sanitize(item.items[0].metadata.content, {
              ALLOWED_TAGS: [],
            }),
          },
        },
        user:
          getUser('employee_id', item.items[0].metadata.user_employee_id) ??
          getUser('user_id', item.items[0].metadata.user),
      };
    } else {
      let user: any = null;
      item = item as PTONotificationItem;

      // NOTE: employeeId here is go2_staff_id
      if (item.items[0].metadata.employeeId) {
        user = getUser('staff_id', item.items[0].metadata.employeeId);
      }
      if (item.items[0].metadata.user?.employee_id) {
        user =
          user ??
          getUser('employee_id', item.items[0].metadata.user?.employee_id);
      }
      // NOTE: id here is go2_staff_id
      if (item.items[0].metadata.user?.id) {
        user = user ?? getUser('staff_id', item.items[0].metadata.user?.id);
      }

      user =
        user ??
        ({
          id: item.items[0].metadata.user?.id,
          employee_id: item.items[0].metadata.user?.employee_id,
          first_name: item.items[0].metadata.user?.first_name,
          last_name: item.items[0].metadata.user?.last_name,
        } as PTOItemUser);

      return {
        pto: {
          id: item.items[0].metadata.id,
          employeeId:
            item.items[0].metadata.employeeId ??
            item.items[0].metadata?.user?.id,
          notes: item.items[0].metadata.notes,
        },
        user,
      };
    }
  };

  const hrTimeOffTypesValues = Object.values(hrTimeOffTypes);
  const getDateRequestedText = ({
    start,
    end,
  }: Partial<
    PTONotificationItemMetadata | PTONotificationApproveRejectItemMetadata
  >) => {
    const startM = moment.utc(start).local();
    const endM = moment.utc(end || start).local();
    let dateRequestText: string = '';

    if (startM.isSame(endM, 'date')) {
      dateRequestText = ` ${startM.format('MMM D, YYYY')}`;
    } else {
      const isSameYear = startM.year() === endM.year();
      dateRequestText =
        startM.month() !== endM.month()
          ? [
              startM.format(`MMM D${isSameYear ? '' : ', YYYY'}`),
              endM.format(`MMM D${isSameYear ? '' : ', YYYY'}`),
            ].join(' - ') + (isSameYear ? `, ${startM.year()}` : '')
          : startM.format('MMM ') +
            [
              startM.format(`D${isSameYear ? '' : ', YYYY'}`),
              endM.format(`D${isSameYear ? '' : ', YYYY'}`),
            ].join(' - ') +
            (isSameYear ? `, ${startM.year()}` : '');
    }

    if (dateRequestText) {
      dateRequestText = ` for ${dateRequestText}`;
    }

    return dateRequestText;
  };
  const renderTimeOffMessage = (
    {
      amount,
      approver,
      type_id,
      type_name,
      status,
      start,
      end,
      rejector,
    }: Partial<PTONotificationApproveRejectItemMetadata>,
    color: IterableObject,
  ) => {
    const rejectorApprover = getUser(
      'employee_id',
      approver?.employee_id ?? rejector?.employee_id ?? '',
    );

    if (hrTimeOffTypesValues.find((typeId) => +typeId === +type_id!)) {
      return (
        <span style={{ color: color[status!] }}>
          {amount} hour{amount! > 1 ? 's' : ''} of {type_name} has been{' '}
          {status === 'canceled' ? 'cancelled' : status} by{' '}
          {rejectorApprover ? rejectorApprover.first_name : 'you '}
          {getDateRequestedText({ start, end })}
        </span>
      );
    }

    return `Time Off Request ${toTitleCase(status)}`;
  };

  const getPTOMessage = (
    item: PTONotificationItem | PTONotificationCommentItem,
    pto: PTOExtract,
  ) => {
    let metadata:
      | PTONotificationApproveRejectItemMetadata
      | PTONotificationItemMetadata = item.items[0]
      .metadata as PTONotificationApproveRejectItemMetadata;

    if (
      item.items[0].verb === 'timeoff_canceled' ||
      item.items[0].verb === 'timeoff_approved' ||
      item.items[0].verb === 'timeoff_denied'
    ) {
      return renderTimeOffMessage(
        metadata as PTONotificationApproveRejectItemMetadata,
        {
          approved: isDarkMode ? '#03aa00' : '#0f840d',
          denied: isDarkMode
            ? 'var(--md-ref-palette-error60)'
            : 'var(--md-ref-palette-error40)',
          canceled: isDarkMode
            ? 'var(--md-ref-palette-error60)'
            : 'var(--md-ref-palette-error40)',
        },
      );
    }

    if (item.items[0].verb === 'timeoff_requested') {
      if (typeof metadata.amount === 'object') {
        metadata = metadata as PTONotificationItemMetadata;

        return [
          `${metadata.amount!.amount} hour${
            +metadata.amount!.amount! > 1 ? 's' : ''
          } of ${metadata.type.name} has been requested
          ${getDateRequestedText(metadata)}`,
          pto.notes?.employee,
        ]
          .filter((v) => !!v)
          .join('; ');
      } else {
        metadata = metadata as PTONotificationApproveRejectItemMetadata;

        return [
          `${metadata.amount} hour${+metadata.amount! > 1 ? 's' : ''} of ${
            (metadata as PTONotificationApproveRejectItemMetadata).type_name
          } has been requested
          ${getDateRequestedText(metadata)}`,
          pto.notes?.employee,
        ]
          .filter((v) => !!v)
          .join('; ');
      }
    }

    return (
      pto.notes?.employee ?? (
        <span style={{ fontStyle: 'italic', opacity: 0.5 }}>No notes</span>
      )
    );
  };
  const renderPTOItem = (
    item: PTONotificationItem | PTONotificationCommentItem,
    index: number,
  ) => {
    let { pto, user } = extractPTOData(item);
    const url = toURL(`${paths.request!}/${pto.id}/read`, {
      t: paths.tabIndex,
      notification_id: item.items[0].id,
    });

    return (
      <InboxListItem
        key={item.items[0].id}
        url={url}
        user={user as Partial<UserMetadata>}
        item={{
          id: item.items[0].id,
          unread: item.items[0].unread,
          created: item.items[0].metadata.created,
          modified: item.items[0].metadata.modified,
          content: getPTOMessage(item, pto),
          object_id: pto.id as number,
        }}
        tag={renderTagTopic('Request', {}, item.items[0].verb)}
        withCheckbox={false}
        selected={toggleSelection?.selected[item.items[0].id]}
        onSelect={(selected: boolean, data) => {
          toggleSelection?.setSelected(data.id, selected);
          toggleSelection?.deselectAll();
        }}
        onClick={() => {
          setInboxItemState({
            item,
            paths,
            itemIndex: index,
            tabIndex: paths.tabIndex,
            listQuery,
          });
        }}
      />
    );
  };

  const extractHelpNeedeDData = (item: HelpNeededReportNotificationItem) => {
    return {
      user: getUser('user_id', item.items[0].metadata.submitted_by) ?? {
        id: item.items[0].metadata.submitted_by,
      },
    };
  };
  const renderHelpNeededItem = (
    item: HelpNeededReportNotificationItem,
    index: number,
  ) => {
    let { user } = extractHelpNeedeDData(item);
    const objectId =
      item.items[0].metadata.data.sod?.id ?? item.items[0].metadata.id;
    const url = toURL(`${paths.helpNeeded}/${objectId}/read`, {
      t: paths.tabIndex,
      notification_id: item.items[0].id,
    });

    return (
      <InboxListItem
        key={item.items[0].id}
        url={url}
        user={user}
        item={{
          id: item.items[0].id,
          unread: item.items[0].unread,
          created: item.items[0].metadata.created,
          modified: item.items[0].metadata.modified,
          content: DOMPurify.sanitize(
            item.items[0].metadata.data.overview.need_help_about,
            { ALLOWED_TAGS: [] },
          ),
          object_id: objectId,
        }}
        tag={renderTagTopic('Need Help', {
          background: isDarkMode
            ? 'var(--md-ref-palette-error50)'
            : 'var(--md-ref-palette-error90)',
        })}
        withCheckbox={false}
        selected={toggleSelection?.selected[item.items[0].id]}
        onSelect={(selected: boolean, data) => {
          toggleSelection?.setSelected(data.id, selected);
          toggleSelection?.deselectAll();
        }}
        onClick={() => {
          setInboxItemState({
            item,
            paths,
            itemIndex: index,
            tabIndex: paths.tabIndex,
            listQuery,
          });
        }}
      />
    );
  };

  const extractGo2BotsData = (item: Go2BotsNotificationItem) => {
    const { id, metadata } = item.items[0];
    const {
      object_id,
      content: metadata_content,
      user: metadata_user,
      user_email,
      user_employee_id,
      user_first_name,
      user_last_name,
      metadata: metadata_metadata,
    } = metadata;

    const tag = renderTagTopic('Go2Bots', {});
    const url = toURL(`${paths.go2bots}/${object_id}/read`, {
      t: paths.tabIndex,
      notification_id: id,
    });
    let user =
      getUser('user_id', metadata_user) ??
      ({
        email: user_email,
        employee_id: user_employee_id,
        first_name: user_first_name,
        last_name: user_last_name,
      } as Partial<UserMetadata>);
    const content = DOMPurify.sanitize(metadata_content, { ALLOWED_TAGS: [] });

    if (
      metadata_metadata?.is_admin ||
      !metadata_metadata ||
      !('is_admin' in (metadata_metadata || {}))
    ) {
      user = {
        id: 0,
        first_name: 'Path',
        last_name: '',
        photo_url:
          'https://go2cosmosbucket.s3.amazonaws.com/bots-upload/images/bbdd06ce1823ea7f028c77b00.svg',
      };
    }

    return {
      id,
      user,
      content,
      object_id,
      tag,
      url,
    };
  };
  const renderGo2BotsItem = (item: Go2BotsNotificationItem, index: number) => {
    const { unread, metadata } = item.items[0];
    const { id, user, content, tag, url, object_id } = extractGo2BotsData(item);

    return (
      <InboxListItem
        key={id}
        url={url}
        user={user}
        item={{
          id,
          unread,
          content,
          created: metadata.created,
          modified: metadata.modified,
          object_id: object_id,
        }}
        withCheckbox={false}
        tag={tag}
        selected={toggleSelection?.selected[item.items[0].id]}
        onSelect={(selected: boolean, data) => {
          toggleSelection?.setSelected(data.id, selected);
          toggleSelection?.deselectAll();
        }}
        onClick={() => {
          setInboxItemState({
            item,
            paths,
            itemIndex: index,
            tabIndex: paths.tabIndex,
            listQuery,
          });
        }}
      />
    );
  };

  const renderScheduleChangeRequestMessage = (
    item: ScheduleChangeRequestNotificationItem,
    color: IterableObject & {
      approved: string;
      rejected: string;
      canceled: string;
    },
  ) => {
    const {
      requests,
      status,
      reason,
      delete: metadataDelete,
    } = item.items[0].metadata;
    const request = requests[0];
    const lastRequest = requests[requests.length - 1];
    const { is_temporary } = request;

    if (is_temporary) {
      let startTemporaryFormatted = getTemporaryFormattedSchedules({
        request,
        requests,
        isDelete: metadataDelete,
        returnTextOnly: true,
      });
      let endTemporaryFormatted = getTemporaryFormattedSchedules({
        request: lastRequest,
        requests,
        isDelete: metadataDelete,
        returnTextOnly: true,
      });

      if (status !== 'pending') {
        return (
          <span style={{ color: color[status!] }}>
            {`${
              metadataDelete ? 'Delete temporary' : 'Temporary'
            } schedule change request has been ${status} ${getDateRequestedText(
              {
                start: moment(
                  startTemporaryFormatted.getCurrentDayFormattedSchedule() as string,
                )
                  .utc()
                  .format(),
                end: moment(
                  endTemporaryFormatted.getCurrentDayFormattedSchedule() as string,
                )
                  .utc()
                  .format(),
              },
            )}`}
          </span>
        );
      }

      return `${
        metadataDelete ? 'Delete temporary' : 'Temporary'
      } schedule change request has been submitted ${getDateRequestedText({
        start: moment(
          startTemporaryFormatted.getCurrentDayFormattedSchedule() as string,
        )
          .utc()
          .format(),
        end: moment(
          endTemporaryFormatted.getCurrentDayFormattedSchedule() as string,
        )
          .utc()
          .format(),
      })}; ${reason}`;
    }
    // Permanent Schedule
    else {
      if (status !== 'pending') {
        return (
          <span style={{ color: color[status!] }}>
            {`${
              metadataDelete ? 'Delete permanent' : 'Permanent'
            } schedule change request has been ${status}`}
          </span>
        );
      }

      return `${
        metadataDelete ? 'Delete permanent' : 'Permanent'
      } schedule change request has been submitted; ${reason}`;
    }
  };
  const extractScheduleChangeRequestData = (
    item: ScheduleChangeRequestNotificationItem,
  ) => {
    const { actor_object_id, id, metadata } = item.items[0];
    const { batch_id: batchId, requests } = metadata;
    const request = requests[0];
    const objectId = request.id;

    const tag = renderTagTopic('Schedule Change Request', {});
    const url = toURL(`${paths.scheduleRequest}/${batchId}/read`, {
      t: paths.tabIndex,
      notification_id: id,
    });
    let user = getUser('user_id', actor_object_id);
    let content = renderScheduleChangeRequestMessage(item, {
      approved: isDarkMode ? '#03aa00' : '#0f840d',
      rejected: isDarkMode
        ? 'var(--md-ref-palette-error60)'
        : 'var(--md-ref-palette-error40)',
      canceled: isDarkMode
        ? 'var(--md-ref-palette-error60)'
        : 'var(--md-ref-palette-error40)',
    });

    return {
      id,
      user,
      content,
      object_id: objectId,
      tag,
      url,
    };
  };
  const renderScheduleChangeRequestItem = (
    item: ScheduleChangeRequestNotificationItem,
    index: number,
  ) => {
    const { unread, metadata } = item.items[0];
    const { id, user, content, tag, url, object_id } =
      extractScheduleChangeRequestData(item);

    return (
      <InboxListItem
        key={id}
        url={url}
        user={user as Partial<UserMetadata>}
        item={{
          id,
          unread,
          content,
          created: metadata.requested_date!,
          modified: metadata.approved_date!,
          object_id: object_id,
        }}
        withCheckbox={false}
        tag={tag}
        selected={toggleSelection?.selected[item.items[0].id]}
        onSelect={(selected: boolean, data) => {
          toggleSelection?.setSelected(data.id, selected);
          toggleSelection?.deselectAll();
        }}
        onClick={() => {
          setInboxItemState({
            item,
            paths,
            itemIndex: index,
            tabIndex: paths.tabIndex,
            listQuery,
          });
        }}
      />
    );
  };

  const renderTagTopic = (
    label: string,
    style: CSSProperties,
    verb?: NotificationVerb,
  ) => {
    let bgcolor: string = '';
    let modifiedColor: string = 'inherit';
    let modifiedLabel: string = label;

    if (verb === 'timeoff_approved') {
      bgcolor = '#2e7d32 !important';
      modifiedColor = 'white';
      modifiedLabel = 'Approved';
    } else if (verb === 'timeoff_canceled') {
      bgcolor = '#d32f2f !important';
      modifiedColor = 'white';
      modifiedLabel = 'Cancelled';
    } else if (verb === 'timeoff_denied') {
      bgcolor = '#d32f2f !important';
      modifiedColor = 'white';
      modifiedLabel = 'Denied';
    }

    return (
      <Chip
        label={modifiedLabel}
        sx={{
          bgcolor,
          color: modifiedColor,
          mr: 1,
          height: 'initial',
          display: 'block',
          borderRadius: '4px',
          '.MuiChip-label': {
            p: 0.2,
            pl: 0.8,
            pr: 0.8,
            fontSize: 10,
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
        style={style}
      />
    );
  };

  return (
    <Box>
      <List sx={{ m: 0, p: 0 }}>
        {isLoading &&
          !items.length &&
          new Array(minSkeletonSize).fill(null).map((_, i) => {
            return <InboxCardListItemSkeleton key={i} />;
          })}
        {items.map((item, index) => {
          if (item.items[0].verb === 'commented_report') {
            return renderDailyReportItem(
              item as DailyReportNotificationItem,
              index,
            );
          }

          // didn't remove this one in order to avoid breakage when we deploy this to prod. maybe there are previous notifications associated
          // with this verb
          if (item.items[0].verb === 'commented_jobdescription') {
            return renderJobDescriptionItem(
              item as JobDescriptionNotificationItem,
              index,
            );
          }

          if (item.items[0].verb === 'commented_project') {
            return renderJobDescriptionItem(
              item as JobDescriptionNotificationItem,
              index,
            );
          }

          if (
            item.topic === 'timeoff' ||
            item.items[0].verb === 'commented_bhrtimeoffrequests'
          ) {
            return renderPTOItem(
              item as PTONotificationItem | PTONotificationCommentItem,
              index,
            );
          }

          if (item.topic === 'help_needed') {
            return renderHelpNeededItem(
              item as HelpNeededReportNotificationItem,
              index,
            );
          }

          if (item.topic === 'report') {
            return renderDailyReportItem(
              item as DailyReportNotificationItem,
              index,
            );
          }

          if (item.items[0].verb === 'commented_go2botmessage') {
            return renderGo2BotsItem(item as Go2BotsNotificationItem, index);
          }

          if (item.topic === 'schedule_change_request') {
            return renderScheduleChangeRequestItem(
              item as ScheduleChangeRequestNotificationItem,
              index,
            );
          }

          return null;
        })}
        <LoadMorePlaceholder
          hasReachEnd={hasReachEnd}
          isLoading={isLoadingMore}
        />
      </List>
    </Box>
  );
};

export default InboxAllList;
