import React from 'react';

import SignUp from '../components/SignUp/SignUp';

type Props = {};

const SignUpNumbers = (props: Props) => {
  return <SignUp user_type='numbers' introText='' />;
};

export default SignUpNumbers;
