import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Moment } from 'moment';
import React, { ReactNode, useMemo, useEffect } from 'react';

import BasicDatePicker from '../Pickers/BasicDatePicker';
import { useForm } from '../BasicForm';
import { M3MenuItem } from '../M3/M3MenuItem';
import EODDivisionChip from './EODDivisionChip';
import RichEditor from '../RichEditor/RichEditor';
import EODOverviewTooltip from './EODOverviewTooltip';

import { UserWorkLogItem } from '../../types/profile';
import { EODFieldParams } from '../../types/report';
import { IterableObject } from '../../types/types';
import { isNotNullAndUndefined } from '../../utils/object';
import { UseAIAssistSideSheetRet } from '../SideSheet/AIAssistSideSheet';
import { useHasViewPermission } from '../HasViewPermission';
import { stringToKey } from '../../utils/string';

type Props = {
  initialData?: IterableObject;
  workLog: UserWorkLogItem;
  aiAssist?: UseAIAssistSideSheetRet<any>;
  onDataChange?: (data: IterableObject, passed: boolean, total: number) => void;
};

type HeadTitle = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
};

const dateFieldSx: SxProps = {
  width: '100%',
  maxWidth: 420,
};

const autoFormOffProps: { [key: string]: 'off' | undefined } = {
  autoSave: 'off',
  autoCorrect: 'off',
  autoComplete: 'off',
  autoCapitalize: 'off',
};

type EODKPIViewProps = Props & {
  onFocus?: () => void;
  onBlur?: () => void;
};
const EODKPIView = ({
  initialData,
  workLog,
  onDataChange,
  onFocus,
  onBlur,
  aiAssist,
}: EODKPIViewProps) => {
  const isPromptEngineer = useHasViewPermission({
    roles: ['superuser', 'prompt_engineer'],
  });

  const initialState = useMemo<IterableObject>(() => {
    if (!workLog.eod_form) return {};

    return workLog.eod_form.fields.reduce((state, field: EODFieldParams) => {
      const initialValue = initialData?.[field.id];
      const hasInitialValue = isNotNullAndUndefined(initialValue);

      if (hasInitialValue) {
        state[field.id] = initialValue;
      } else {
        state[field.id] = '';
        if (field.type === 'datepicker') {
          state[field.id] = null;
        }
      }
      return state as IterableObject;
    }, {} as IterableObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { formState, handleChange } = useForm<IterableObject>(initialState);

  function areAllRequiredFilled(state: IterableObject) {
    if (!workLog.eod_form) return true;

    const requiredFields = workLog.eod_form!.fields.filter(
      (field) => field.required,
    );
    // If no required fields, then allow to pass
    if (!requiredFields.length) {
      return true;
    }
    return requiredFields.every((field) => {
      const value = formState[field.id];
      const hasValue = isNotNullAndUndefined(value);
      return hasValue && !!value;
    });
  }

  function getTotalOfRequiredHavingValue(state: IterableObject) {
    if (!workLog.eod_form) return 0;

    const requiredFields = workLog.eod_form!.fields.filter(
      (field) => field.required,
    );
    // If no required fields, then allow to pass
    if (!requiredFields.length) {
      return 0;
    }
    return requiredFields.filter((field) => {
      const value = formState[field.id];
      const hasValue = isNotNullAndUndefined(value);
      return hasValue && !!value;
    }).length;
  }

  const renderHeadline = ({ title, subtitle }: HeadTitle) => {
    return (
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          component='div'
          fontWeight={500}
          fontSize={20}
          sx={{ mb: 0 }}
        >
          {title}
        </Typography>
        {!!subtitle && (
          <Typography
            component='div'
            fontSize={14}
            fontWeight={500}
            sx={{ mb: 2, mt: 1 }}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
    );
  };

  const renderFormElement = (item: EODFieldParams) => {
    const inputTextProps: TextFieldProps = {
      type:
        item.type === 'number'
          ? 'number'
          : item.type === 'input'
          ? 'text'
          : undefined,
      placeholder: item.caption || undefined,
    };

    if (item.type === 'select') {
      return (
        <FormControl fullWidth>
          <Select
            fullWidth
            {...autoFormOffProps}
            name={item.id}
            value={formState[item.id]}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {item.select_options?.map((value, index) => (
              <M3MenuItem key={index} value={value}>
                {value}
              </M3MenuItem>
            ))}
          </Select>
          {!!item.caption && !formState[item.id] && (
            <FormHelperText
              sx={{
                top: 12,
                left: 0,
                bottom: 0,
                fontSize: 16,
                opacity: 0.58,
                position: 'absolute',
                pointerEvents: 'none',
              }}
            >
              {item.caption}
            </FormHelperText>
          )}
        </FormControl>
      );
    }

    if (item.type === 'datepicker') {
      return (
        <Box sx={dateFieldSx}>
          <BasicDatePicker
            format='YYYY-MM-DD'
            defaultValue={formState[item.id]}
            textFieldProps={{
              onFocus,
              onBlur,
            }}
            onChange={(value: Moment | null) => {
              handleChange({
                target: {
                  name: item.id,
                  value: value ? value.format('YYYY-MM-DD') : null,
                },
              });
            }}
          />
        </Box>
      );
    }

    if (item.type === 'input' || item.type === 'number') {
      return (
        <TextField
          fullWidth
          {...autoFormOffProps}
          {...inputTextProps}
          name={item.id}
          value={formState[item.id]}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );
    }

    /**
     * By default, let's use its task name and title to convert to key to be used as prompt identifier
     * This creates a namespaces belong to its task only
     */
    let taskPromptIdentifier = stringToKey(
      `eod-kpi-focus-area-${[workLog.task_name, item.label].join('-')}`,
      '-',
    );

    /**
     * Meeting and 1:1 are global, so we need to use the shared key using its label
     */
    if (
      item.label.toLowerCase() === 'meeting' ||
      item.label.search('1:1') > -1
    ) {
      taskPromptIdentifier = stringToKey(
        `eod-kpi-focus-area-${item.label}`,
        '-',
      );
    }

    // attach the title to its task name
    let title = `${workLog.task_name}: ${item.label}`;

    // default to textarea/richtext
    return (
      <RichEditor
        withAIAssist
        withAIEditPrompt={isPromptEngineer}
        value={formState[item.id]}
        placeholder={item.caption || 'Write here...'}
        onValueChange={(html: string) => {
          handleChange({
            target: {
              name: item.id,
              value: html,
            },
          });
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        chatGPTPlaygroundProps={{
          title: title,
          prompt_identifier: taskPromptIdentifier,
        }}
        onAssistClick={() => {
          aiAssist?.start({
            title: title,
            prompt_identifier: taskPromptIdentifier,
            data: {
              prompt_text: formState[item.id],
            },
          });
        }}
      />
    );
  };

  const renderFormElementError = (message: string) => {
    return (
      <Typography
        component='div'
        fontSize={12}
        sx={{
          mt: 1,
          color: 'red',
        }}
        className='model-view-help-text'
      >
        {message}
      </Typography>
    );
  };

  const renderField = (item: EODFieldParams) => {
    let errorMsg = false;

    return (
      <Stack id={`eod_${item.id}`} key={item.id} sx={{ mb: 1 }}>
        <Typography
          component='div'
          display='flex'
          alignItems='center'
          justifyContent='space-evenly'
          fontWeight={500}
          className='model-view-typography'
        >
          <span
            style={{
              flex: 1,
              color: errorMsg ? 'red' : undefined,
            }}
          >
            {item.label}
          </span>
          <br />
          {item.required && (
            <Typography
              component='div'
              textTransform='uppercase'
              sx={{
                fontSize: 9,
                opacity: 0.5,
                fontWeight: 'bold',
              }}
              className='model-view-typography'
            >
              (Required)
            </Typography>
          )}
        </Typography>
        <Box
          sx={{
            pt: 1,
            pb: 1.4,
            flex: 1,
          }}
        >
          {renderFormElement(item)}
          {errorMsg
            ? renderFormElementError(errorMsg)
            : item.help_text && (
                <Typography
                  fontSize={12}
                  sx={{
                    mt: 1,
                    opacity: 0.5,
                  }}
                  className='model-view-help-text'
                >
                  {item.help_text}
                </Typography>
              )}
        </Box>
      </Stack>
    );
  };

  useEffect(() => {
    const state = { ...formState };
    onDataChange?.(
      state,
      areAllRequiredFilled(state),
      getTotalOfRequiredHavingValue(state),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <>
      {renderHeadline({
        title: (
          <>
            <Stack direction='row' position='relative'>
              {workLog.task_name}{' '}
              <Box position='absolute' top={2} right={-30}>
                <EODOverviewTooltip
                  iconAsLabel
                  overview={workLog.eod_form?.overview}
                  desiredOutcome={workLog.eod_form?.desired_outcome}
                  successMeasurement={workLog.eod_form?.success_measurement}
                />
              </Box>
            </Stack>
            {!!workLog.division_name && (
              <EODDivisionChip label={workLog.division_name} />
            )}
          </>
        ),
        // subtitle: (
        //   <span style={{ position: 'relative', top: -2 }}>
        //     <span style={{ opacity: 0.4, fontWeight: 400 }}>
        //       Total hours work:{' '}
        //     </span>
        //     {moment.duration(workLog.duration, 'seconds').asHours().toFixed(2)}h
        //   </span>
        // ),
      })}
      <br />
      {workLog.eod_form?.fields.map(renderField)}
    </>
  );
};

export default EODKPIView;
