import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ArrowRightAltOutlined } from '@mui/icons-material';
import moment from 'moment';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../../ModalCardView';

import { useCurrentProfile } from '../../../hooks/profile';
import { useRefAssign } from '../../../hooks/global/useRefAlwaysUpdated';
import { useTimeTrackerDailySummary } from '../../../hooks/worklogs';
import { UserMetadata } from '../../../types/profile';
import { useTimeTrackerProvider } from '../../../providers/time-tracker/time-tracker';
import UserLogs from './UserLogs';
import { TimeTrackerDailySummaryItem } from '../../../types/worklogs';
import {
  getWeekRange,
  HHMMSSOptionsJSONRet,
  toHHMMSS,
} from '../../../utils/date';
import { M3IconButton } from '../../M3/M3Button';
import M3SurfaceContainer from '../../M3/M3SurfaceContainer';
import TopPanel from './TopPanel';
import UserProfile from './UserProfile';
import { useUserProvider } from '../../../providers/user/user';
import TaskDetailView from '../../TaskDetails/TaskDetailView';
import { TaskData } from '../../../types/task';

type ViewLogsModalViewProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    user?: UserMetadata;
  };

const ViewLogsModalViewBase: React.FC<ViewLogsModalViewProps> = ({
  user,
  close,
}) => {
  const [prevWeekLogsCounter, setPrevWeekLogsCounter] = useState(0);
  const [selectedTimeLogUser, setSelectedTimeLogUser] = useState<
    UserMetadata | undefined
  >(undefined);
  const [isTaskOpen, setIsTaskOpen] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<TaskData>({
    date: '',
    user: null,
    task: null,
  });
  const [scrollElement, setScrollElement] = useState<
    HTMLDivElement | null | undefined
  >(null);

  const {
    activeTimeTrackerTaskWorklog,
    fetchCurrentWorkedLogDailySummary,
    fetchMorePreviousWorkedLogDailySummary,
    shiftDate,
    workedLogDailySummary,
  } = useTimeTrackerProvider();
  const { getUser } = useUserProvider();
  const { data: currentProfile } = useCurrentProfile();

  const startEndDate = shiftDate.clone();

  const { start: timeTrackerStartDate, end: timeTrackerEndDate } = getWeekRange(
    prevWeekLogsCounter
      ? startEndDate
          .clone()
          .subtract(prevWeekLogsCounter, 'week')
          .endOf('week')
          .add(1, 'day')
      : startEndDate,
  );

  const timeTrackerDefaultParamsRef = useRefAssign({
    start_date: timeTrackerStartDate.utc().format(),
    end_date: timeTrackerEndDate.utc().format(),
    timezone: moment.tz.guess(true),
  });
  const timeTrackerDefaultParams = timeTrackerDefaultParamsRef.current;

  const otherTimeTrackerDailySummary = useTimeTrackerDailySummary(
    {
      ...timeTrackerDefaultParams,
      exclude_active: false,
      users:
        selectedTimeLogUser && selectedTimeLogUser.id !== currentProfile!.id
          ? selectedTimeLogUser.id
          : undefined,
    },
    { enabled: false },
  );

  const weekStartMoment = moment
    .utc(timeTrackerDefaultParams.start_date)
    .local();
  const weekEndMoment = moment.utc(timeTrackerDefaultParams.end_date).local();

  const isCurrentWeek =
    weekStartMoment.isSameOrAfter(moment().startOf('week')) &&
    weekStartMoment.isSameOrBefore(moment().endOf('week'));

  const timeTrackedByGrouped: {
    date: string;
    data: (TimeTrackerDailySummaryItem & { active: boolean })[];
  }[] = useMemo(() => {
    /**
     * Get current user's logged for 1 week
     */
    let grouped: {
      date: string;
      data: (TimeTrackerDailySummaryItem & { active: boolean })[];
    }[] = [];
    const grouping = (wl: TimeTrackerDailySummaryItem) => {
      const g = grouped.find((g) => g.date === wl.day);
      const data = {
        user: wl.user,
        task: wl.task,
        task_name: wl.task_name,
        day: wl.day,
        total_duration: wl.total_duration,
        active:
          selectedTimeLogUser?.id !== currentProfile!.id &&
          activeTimeTrackerTaskWorklog?.task_id === wl.task &&
          activeTimeTrackerTaskWorklog.day === wl.day,
      };
      /**
       * Check if it's the active task being clocked to, add the total
       * of current logged hours
       */
      if (
        selectedTimeLogUser?.id !== currentProfile!.id &&
        activeTimeTrackerTaskWorklog?.task_id === wl.task &&
        activeTimeTrackerTaskWorklog.day === wl.day
      ) {
        data.total_duration += activeTimeTrackerTaskWorklog.total || 0;
      }

      if (g) {
        g.data.push(data);
      } else {
        grouped.push({
          date: wl.day,
          data: [data],
        });
      }
    };

    /**
     * Check if it's viewing other users worklogs
     */
    if (selectedTimeLogUser && selectedTimeLogUser.id !== currentProfile!.id) {
      (otherTimeTrackerDailySummary.data ?? []).forEach(grouping);
    } else {
      /**
       * Otherwise, it's current logged in's worklog
       */
      const sd = moment
        .utc(timeTrackerDefaultParamsRef.current.start_date)
        .local();
      const ed = moment
        .utc(timeTrackerDefaultParamsRef.current.end_date)
        .local();
      workedLogDailySummary
        .filter((item) => {
          return (
            moment(item.day).isSameOrAfter(sd) &&
            moment(item.day).isSameOrBefore(ed)
          );
        })
        .forEach(grouping);
    }

    return grouped;
  }, [
    timeTrackerDefaultParamsRef,
    selectedTimeLogUser,
    workedLogDailySummary,
    otherTimeTrackerDailySummary.data,
    currentProfile,
    activeTimeTrackerTaskWorklog,
  ]);

  const totalWeek = timeTrackedByGrouped
    .map((group) => {
      const total = group.data
        .map((wl) => wl.total_duration)
        .reduce((a, b) => a + b, 0);
      return total;
    })
    .reduce((a, b) => a + b, 0);

  const totalWeekHMS = toHHMMSS(totalWeek, {
    json: true,
    hrDigit: 1,
    minDigit: 1,
  }) as HHMMSSOptionsJSONRet;

  const weekDisplay = {
    date:
      weekStartMoment.month() === weekEndMoment.month()
        ? `${weekStartMoment.format('MMM')} ${weekStartMoment.format('D')}${
            weekStartMoment.date() !== weekEndMoment.date()
              ? ` – ${weekEndMoment.date()}`
              : ''
          }`
        : [weekStartMoment.format('MMM D'), weekEndMoment.format('MMM D')].join(
            ' – ',
          ),
  };

  const handleLogsOnClick = (next: boolean = true) => {
    let counter: number;

    if (next) {
      counter = prevWeekLogsCounter - 1;
    } else {
      counter = prevWeekLogsCounter + 1;
    }

    setPrevWeekLogsCounter(counter);
    fetchMorePreviousWorkedLogDailySummary(true, undefined, counter);
  };

  const handleBackClick = () => {
    setIsTaskOpen(false);
    setTaskData((state) => ({
      ...state,
      date: '',
      task: null,
      user: null,
    }));
  };

  useEffect(() => {
    fetchCurrentWorkedLogDailySummary(true, moment().startOf('day'))
      .then()
      .catch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const selectedUser = getUser('user_id', user.id);

      setSelectedTimeLogUser(() => {
        return selectedUser ? { ...selectedUser } : undefined;
      });
      setPrevWeekLogsCounter(0);
    }
  }, [getUser, user]);

  useEffect(() => {
    otherTimeTrackerDailySummary.refetch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDate, selectedTimeLogUser, prevWeekLogsCounter]);

  const hms = toHHMMSS(taskData.task?.total_duration ?? 0, {
    json: true,
    hrDigit: 1,
    minDigit: 1,
  }) as HHMMSSOptionsJSONRet;

  useEffect(() => {
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }, [scrollElement, isTaskOpen, prevWeekLogsCounter]);

  return (
    <ModalCardView
      sx={{
        minHeight: 600,
      }}
      header={<TopPanel />}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
      willUpdateLayout={timeTrackedByGrouped}
      onSetScrollElementRef={setScrollElement}
    >
      <UserProfile user={user || selectedTimeLogUser} />
      <Box sx={{ pt: 1, pb: 1 }}>
        {isTaskOpen ? (
          <Box
            mb={4}
            mx={-2}
            style={{
              marginTop: 4,
            }}
          >
            <Box display='flex' gap={1} alignItems='center' mb={1}>
              <M3IconButton size='small' onClick={handleBackClick}>
                <ArrowRightAltOutlined
                  sx={{
                    transform: 'rotateZ(180deg)',
                  }}
                />
              </M3IconButton>
              <Typography component='div' fontSize={14} fontWeight={500}>
                <span>
                  {moment(taskData.date).format('MMM D, YYYY')}
                  <span
                    style={{
                      opacity: 0.3,
                      fontSize: 12,
                      paddingLeft: 4,
                      fontWeight: 400,
                    }}
                  >
                    ({moment(taskData.date).format('ddd')})
                  </span>
                </span>
              </Typography>
            </Box>
            <M3SurfaceContainer
              elevation={1}
              sx={{
                p: 2,
                py: 2.6,
                mx: 0,
              }}
              wrapperSx={{
                flex: 1,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box display='flex' gap={2}>
                  <Typography
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    component='div'
                    fontSize={18}
                    fontWeight={500}
                    textAlign='center'
                  >
                    <WysiwygOutlinedIcon />
                    &nbsp;&nbsp;
                    {taskData.task?.task_name}
                  </Typography>
                </Box>
                <Typography>
                  <span
                    style={{
                      fontSize: 18,
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    {hms.hasHours ? `${hms.hours}h` : ''} {hms.minutes}m
                  </span>
                </Typography>
              </Stack>
            </M3SurfaceContainer>
          </Box>
        ) : (
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              mx: -2,
              mt: 0.5,
              mb: 1,
            }}
          >
            <M3IconButton size='small' onClick={() => handleLogsOnClick(false)}>
              <ArrowRightAltOutlined
                sx={{
                  transform: 'rotateZ(180deg)',
                }}
              />
            </M3IconButton>
            {!isCurrentWeek && (
              <M3IconButton
                size='small'
                onClick={() => handleLogsOnClick(true)}
              >
                <ArrowRightAltOutlined />
              </M3IconButton>
            )}
          </Stack>
        )}
        {!isTaskOpen && (
          <M3SurfaceContainer
            elevation={1}
            sx={{
              p: 2,
              mx: -2,
            }}
          >
            <Typography
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              component='div'
              fontSize={18}
              fontWeight={500}
              textAlign='center'
              sx={{
                pb: 0.4,
              }}
            >
              <div style={{ flex: 1, width: 0, textAlign: 'left' }}>
                {isCurrentWeek ? 'This week' : weekDisplay.date}
                <Typography
                  component='div'
                  style={{
                    top: -3,
                    fontSize: 11,
                    opacity: 0.5,
                    marginBottom: -10,
                    position: 'relative',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {isCurrentWeek ? weekDisplay.date : 'Past logs'}
                </Typography>
              </div>
              <span
                style={{
                  fontSize: 18,
                  paddingTop: 2,
                  whiteSpace: 'nowrap',
                }}
              >
                {totalWeekHMS.hasHours ? `${totalWeekHMS.hours}h` : ''}{' '}
                {totalWeekHMS.minutes}m
              </span>
            </Typography>
          </M3SurfaceContainer>
        )}
        {isTaskOpen && taskData.task && taskData.user ? (
          <TaskDetailView
            date={taskData.date}
            task={taskData.task}
            user={taskData.user}
          />
        ) : (
          <Box>
            {timeTrackedByGrouped.map((group) => {
              return (
                <UserLogs
                  key={group.date}
                  date={group.date}
                  data={group.data}
                  setTaskData={setTaskData}
                  setIsTaskOpen={setIsTaskOpen}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </ModalCardView>
  );
};

const ViewLogsModalView = forwardRef((props: ViewLogsModalViewProps, ref) => (
  <ViewLogsModalViewBase {...props} />
));

export default ViewLogsModalView;
