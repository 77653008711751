import { AxiosRequestConfig } from 'axios';
import { useMutationApi } from './global/useApi';

export type UsePostUserProvisionPathBetaData = {
  email: string;
  first_name: string;
  job_title?: string;
  last_name: string;
  setup_slack_account?: boolean;
};

export function usePostUserProvisionPathBeta(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(`/users/provision/path-beta/`, {}, axiosConfig);
}

export type UsePostUserProvisionPartnerMemberData = {
  circle_id: number | null;
  division_id: number | null;
  email: string;
  first_name: string;
  job_title: string;
  last_name: string;
  partner_id: number | null;
  phone_number?: string;
};

export function usePostUserProvisionPartnerMember(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(`/users/provision/partner-member/`, {}, axiosConfig);
}

export type UsePostUserProvisionRegularData = {
  email: string;
  first_name: string;
  job_title?: string;
  last_name: string;
  setup_slack_account?: boolean;
};

export function usePostUserProvisionRegular(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(`/users/provision/regular/`, {}, axiosConfig);
}

export function usePostUserProvisionSetUserType(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(`/users/provision/set-user-type/`, {}, axiosConfig);
}
