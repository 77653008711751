import React from 'react';
import { withWrapper } from './index';

type Props = {};

const SlackSvgIcon = (props: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 622.3 254.4'
    xmlSpace='preserve'
    width='622.3'
    height='254.4'
    {...props}
  >
    <path d='m221.5 161.5 6.2-14.4c6.7 5 15.6 7.6 24.4 7.6 6.5 0 10.6-2.5 10.6-6.3-.1-10.6-38.9-2.3-39.2-28.9-.1-13.5 11.9-23.9 28.9-23.9 10.1 0 20.2 2.5 27.4 8.2l-5.8 14.7c-6.6-4.2-14.8-7.2-22.6-7.2-5.3 0-8.8 2.5-8.8 5.7.1 10.4 39.2 4.7 39.6 30.1 0 13.8-11.7 23.5-28.5 23.5-12.3 0-23.6-2.9-32.2-9.1m237.9-19.6c-3.1 5.4-8.9 9.1-15.6 9.1-9.9 0-17.9-8-17.9-17.9s8-17.9 17.9-17.9c6.7 0 12.5 3.7 15.6 9.1l17.1-9.5c-6.4-11.4-18.7-19.2-32.7-19.2-20.7 0-37.5 16.8-37.5 37.5s16.8 37.5 37.5 37.5c14.1 0 26.3-7.7 32.7-19.2l-17.1-9.5zM290.8 64.5h21.4v104.7h-21.4zm194.1 0v104.7h21.4v-31.4l25.4 31.4h27.4l-32.3-37.3 29.9-34.8h-26.2L506.3 126V64.5zm-109.1 77.6c-3.1 5.1-9.5 8.9-16.7 8.9-9.9 0-17.9-8-17.9-17.9s8-17.9 17.9-17.9c7.2 0 13.6 4 16.7 9.2v17.7zm0-45v8.5c-3.5-5.9-12.2-10-21.3-10-18.8 0-33.6 16.6-33.6 37.4 0 20.8 14.8 37.6 33.6 37.6 9.1 0 17.8-4.1 21.3-10v8.5h21.4v-72h-21.4z' />
    <path
      d='M89.2 142c0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2 5.9-13.2 13.2-13.2h13.2V142zm6.6 0c0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2v-33z'
      style={{
        fill: '#e01e5a',
      }}
    />
    <path
      d='M109 89c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2V89H109zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H75.9c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2H109z'
      style={{
        fill: '#36c5f0',
      }}
    />
    <path
      d='M161.9 108.9c0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2c0 7.3-5.9 13.2-13.2 13.2h-13.2v-13.2zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2V75.8c0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2v33.1z'
      style={{
        fill: '#2eb67d',
      }}
    />
    <path
      d='M142.1 161.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2v-13.2h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2s-5.9 13.2-13.2 13.2h-33.1z'
      style={{
        fill: '#ecb22e',
      }}
    />
  </svg>
);

export default withWrapper(SlackSvgIcon);
