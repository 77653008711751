import { UseQueryOptions } from '@tanstack/react-query';

import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

import { PartnerModel } from '../types/partner';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';

type UsePartnersProps = ListQuery & {
  name: string;
  status: 'active' | 'inactive';
};
export function usePartners<R = PartnerModel>(
  params: UsePartnersProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UsePartnersProps>({
    params,
    baseIdentifier: 'usePartners',
    identifierKeys: ['name', 'status'],
    queryParamKeys: ['name', 'status'],
  });

  return useQueryApi<UsePartnersProps, ListResult<R>>(
    identifiers,
    '/admin/partners/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
