import React, { useMemo, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import TimelineCanvas, { TimeLog } from './TimelineCanvas';

import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';
import { getSeconds } from '../../../utils/date';
import { getColorByTaskName } from '../../EditTime/components/Timeline/TimelineColorMap';

type TimelineProps = {
  data?: UseFetchTimeDetailsResponse[];
};
const Timeline: React.FC<TimelineProps> = ({ data: timeDetailsData = [] }) => {
  const timelineRef = useRef<HTMLDivElement>(null);

  const groupTimeLogs: TimeLog[] = useMemo(() => {
    return timeDetailsData?.map((item, index) => ({
      id: index + 1,
      color: getColorByTaskName(item.task_name),
      start: getSeconds(item.start_time_local.format('HH:mm')),
      end: getSeconds(item.end_time_local!.format('HH:mm')),
      info: item,
    }));
  }, [timeDetailsData]);

  return (
    <Box
      id='timeline'
      ref={timelineRef}
      justifyContent='space-evenly'
      component='section'
      flexWrap='nowrap'
      sx={{
        marginBottom: '10px',
        marginTop: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        opacity: '0.5',
        position: 'relative',
        height: '30px',
      }}
      className='timeline-grid-container'
    >
      <Grid
        container
        className='timeline-time-slots'
        sx={{
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
        }}
      >
        <TimelineCanvas timeLogs={groupTimeLogs} />
      </Grid>
    </Box>
  );
};

export default React.memo(Timeline);
