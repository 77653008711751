import { GroupTypes } from './profile';
import { DateInUTC } from './types';

export type PromptConfigModel = {
  prompt?: string;
  prompt_identifier: string;
  prompt_text: string;
  model: string | OpenAIModelID;
  max_tokens: number;
  temperature: number;
  top_p: number;
  frequency_penalty: number;
  presence_penalty: number;
  best_of: number;
  stop?: string[];
};

export const DEFAULT_GO2BOT_TEMPERATURE = 0.6;

export type PromptConfigItemResponse = PromptConfigModel & {
  created: DateInUTC;
  modified: DateInUTC;
};

const DEFAULT_TOKEN_LIMIT_RATIO = 1;
const CHAR_LIMIT_TOKEN_RATIO_REDUCTION = 0.1;

const CHAR_LIMIT_TOKEN_RATIO =
  DEFAULT_TOKEN_LIMIT_RATIO - CHAR_LIMIT_TOKEN_RATIO_REDUCTION;

export interface OpenAIModel {
  id: string;
  name: string;
  alias: string;
  available: boolean;
  roles: GroupTypes[];
  restrict: GroupTypes[];
  source: 'openai' | 'anthropic' | 'vertex' | 'custom';
  tokenLimit: number;
  characterLimit: number;
  aliases: string[];
}

export enum OpenAIModelID {
  GPT_4_O = 'gpt-4o',
  GPT_4_O_MINI = 'gpt-4o-mini',
  CLAUDE_3_OPUS = 'claude-3-opus-20240229',
  CLAUDE_3_5_SONNET = 'claude-3-5-sonnet-20240620',
  CLAUDE_3_HAIKU = 'claude-3-haiku-20240307',
  // GEMINI_PRO_1_5 = 'models/gemini-pro-latest',
}

enum OpenAIModelTokenLimit {
  GPT_4_O = 128000,
  GPT_4_O_MINI = 128000,
  CLAUDE_3_OPUS = 200000,
  CLAUDE_3_5_SONNET = 200000,
  CLAUDE_3_HAIKU = 200000,
  // GEMINI_PRO_1_5 = 1000000,
}

function calculateTokenCharLimit(limit: OpenAIModelTokenLimit) {
  return {
    tokenLimit: Math.floor(limit * DEFAULT_TOKEN_LIMIT_RATIO),
    characterLimit: Math.floor(limit * CHAR_LIMIT_TOKEN_RATIO),
  };
}

export const OpenAIModels: Record<OpenAIModelID, OpenAIModel> = {
  [OpenAIModelID.GPT_4_O_MINI]: {
    id: OpenAIModelID.GPT_4_O_MINI,
    name: 'Default GPT-4o-mini (128k)',
    alias: 'Default GPT-4o-mini (128k)',
    available: false,
    roles: [],
    restrict: [],
    source: 'openai',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GPT_4_O_MINI),
    aliases: [],
  },
  [OpenAIModelID.GPT_4_O]: {
    id: OpenAIModelID.GPT_4_O,
    name: 'GPT-4o (128k)',
    alias: 'gpt-4o',
    available: false,
    roles: [],
    restrict: [],
    source: 'openai',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.GPT_4_O),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_3_OPUS]: {
    id: OpenAIModelID.CLAUDE_3_OPUS,
    name: 'Claude 3 Opus (180k)',
    alias: OpenAIModelID.CLAUDE_3_OPUS,
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_3_OPUS),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_3_5_SONNET]: {
    id: OpenAIModelID.CLAUDE_3_5_SONNET,
    name: 'Claude 3.5 Sonnet (200k)',
    alias: OpenAIModelID.CLAUDE_3_5_SONNET,
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_3_5_SONNET),
    aliases: [],
  },
  [OpenAIModelID.CLAUDE_3_HAIKU]: {
    id: OpenAIModelID.CLAUDE_3_HAIKU,
    name: 'Claude 3 Haiku (180k)',
    alias: OpenAIModelID.CLAUDE_3_HAIKU,
    available: true,
    roles: ['superuser', 'developer', 'claude_access'],
    restrict: [],
    source: 'anthropic',
    ...calculateTokenCharLimit(OpenAIModelTokenLimit.CLAUDE_3_HAIKU),
    aliases: [],
  },
};
