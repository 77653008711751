import feature, { FeatureConfigKeys } from '../feature.config';
import { useCurrentProfile } from '../hooks/profile';
import { GroupTypes } from '../types/profile';
import { ReactRenderElement } from '../types/types';

type Props = HasViewPermissionProps & {
  children: ReactRenderElement | null;
};

type HasViewPermissionProps = {
  roles?: GroupTypes[];
  restrict?: GroupTypes[];
  featureKey?: FeatureConfigKeys;
};

export function useHasViewPermission({
  roles = [],
  restrict = [],
  featureKey,
}: HasViewPermissionProps): boolean {
  const { data: userProfile, error } = useCurrentProfile();

  if (!!error) return false;

  if (featureKey && !feature[featureKey]) {
    return false;
  }

  if (
    userProfile?.roles.some((role: GroupTypes) => restrict.indexOf(role) > -1)
  ) {
    return false;
  }

  if (
    userProfile &&
    (!roles.length ||
      (!!roles.length &&
        userProfile?.roles.some(
          (role: GroupTypes) => roles.indexOf(role) > -1,
        )))
  ) {
    return true;
  }

  return false;
}

const HasViewPermission = ({
  roles = [],
  restrict = [],
  featureKey,
  children,
}: Props) => {
  const hasPermission = useHasViewPermission({ roles, restrict, featureKey });

  return hasPermission ? (children as any) : null;
};

export default HasViewPermission;
