import { UseQueryOptions } from '@tanstack/react-query';
import { ContentType } from '../types/content-type';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

type UseContentTypesProps = ListQuery & {
  model: string;
};
export function useContentTypes<R = ContentType>(
  params: UseContentTypesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseContentTypesProps>({
    params,
    baseIdentifier: 'useContentTypes',
    identifierKeys: ['model'],
    queryParamKeys: ['model'],
  });

  return useQueryApi<UseContentTypesProps, ListResult<R>>(
    identifiers,
    '/core/content-types/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
