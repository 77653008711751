import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Chip, Stack, TextFieldProps } from '@mui/material';

import { M3Autocomplete, M3AutocompleteProps } from '../M3/M3Autocomplete';
import { M3TextField } from '../M3/M3TextField';
import { useAppProvider } from '../../providers/app/app';

export type TagItem = {
  id: string | number;
  value: any;
};
export type TagOptions = {};
type AutocompleteTagsProps = {
  name?: string;
  placeholder?: string;
  options?: any[];
  value?: any;
  loading?: boolean;
  getOptionLabel?: (option: any) => any;
  onChange?: (evt: SyntheticEvent) => any;
  onSelect?: (evt: SyntheticEvent, option: any) => void;
  onDeleteTag?: (tag: TagItem) => void;
  tags?: TagItem[];
  tagsOnly?: boolean;
  textFieldProps?: TextFieldProps;
  autocompleteProps?: Partial<M3AutocompleteProps>;
};

const AutocompleteTags = ({
  name,
  placeholder,
  value,
  loading,
  options = [],
  tags = [],
  getOptionLabel,
  onSelect,
  onChange,
  onDeleteTag,
  textFieldProps,
  autocompleteProps,
  tagsOnly,
}: AutocompleteTagsProps) => {
  const { isDarkMode } = useAppProvider();
  const [keyCount, setKeyCounter] = useState(1);

  const _options = useMemo(() => {
    return options.filter((tag) => !tags.some((t) => t.id === tag.id));
  }, [options, tags]);

  return (
    <Stack
      flex={1}
      flexDirection='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      flexWrap='wrap'
      sx={{
        borderRadius: 1,
        boxShadow: `0 0 0 1px ${
          isDarkMode
            ? 'var(--md-ref-palette-neutral20)'
            : 'var(--md-ref-palette-neutral-variant70)'
        }`,
      }}
    >
      {tags.map((tag, index) => {
        return (
          <Chip
            key={index}
            label={tag.value}
            sx={{
              ml: 1,
              mt: 1,
              mb: tagsOnly ? 1 : 0,
            }}
            onDelete={
              !tagsOnly && onDeleteTag
                ? () => {
                    onDeleteTag?.(tag);
                  }
                : undefined
            }
          />
        );
      })}
      {!tagsOnly && (
        <M3Autocomplete
          key={keyCount}
          options={_options}
          getOptionLabel={getOptionLabel}
          {...autocompleteProps}
          loading={loading}
          onChange={
            autocompleteProps?.onChange ??
            ((evt: SyntheticEvent, option: any) => {
              onSelect?.(evt, option);
              setKeyCounter((state) => state + 1);
            })
          }
          renderInput={
            autocompleteProps?.renderInput ??
            ((params) => (
              <M3TextField
                {...params}
                name={name}
                placeholder={placeholder}
                fullWidth
                value={value}
                onChange={onChange}
                {...textFieldProps}
                sx={{
                  minWidth: 300,
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                    outline: 'none !important',
                  },
                  ...textFieldProps?.sx,
                }}
              />
            ))
          }
          sx={{
            flex: 1,
            ...autocompleteProps?.sx,
          }}
        />
      )}
    </Stack>
  );
};

export default AutocompleteTags;
