import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';
import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../utils/date';

type FormatterFunctions = {
  [K in keyof UseFetchTimeDetailsResponse]?: (
    value: UseFetchTimeDetailsResponse[K],
  ) => string | JSX.Element;
};

export const timeDetailsCellValueFormatter: FormatterFunctions = {
  start_time_local: (value) => value.format('h:mm A'),
  end_time_local: (value) => (!value ? '–' : value.format('h:mm A')),
  duration: (value) => {
    const hms = toHHMMSS(value, {
      minDigit: 1,
      hrDigit: 1,
      secDigit: 1,
      json: true,
    }) as HHMMSSOptionsJSONRet;

    return `${hms.hours}h ${hms.minutes}m`.trim();
  },
};
