import { useState } from 'react';
import { Avatar, Box, Modal, Stack, Typography } from '@mui/material';
import moment from 'moment';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import RichTextContent from '../RichEditor/RichTextContent';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import AIAIAIChatEditFormView from './AIAIAIChatEditForm';
import { M3IconButton } from '../M3/M3Button';

import { CommentItem } from '../../types/comment';
import { useUserProvider } from '../../providers/user/user';
import { getUserPhotoUrl, getUserProfileDisplayName } from '../../utils/user';
import { ReactRenderElement } from '../../types/types';

type AIAIAIChatMessageProps = {
  index: number;
  message: CommentItem;
  isAdmin?: boolean;
  withSentBy?: boolean;
  isFlipped?: boolean;
  onEditSuccess?: () => void;
  onDeleteSuccess?: (id: number) => void;
};

export default function AIAIAIChatMessage({
  message,
  index,
  isAdmin,
  withSentBy = true,
  isFlipped = false,
  onEditSuccess,
  onDeleteSuccess,
}: AIAIAIChatMessageProps) {
  const { getUser } = useUserProvider();
  const [isEditOpen, setIsEditOpen] = useState(false);

  let position = isAdmin ? 'right' : 'left';
  let user = getUser('user_id', message.user) ?? {
    first_name: message.user_first_name,
    last_name: message.user_last_name,
    photo_url: message.user_photo,
  };

  if (isFlipped) {
    position = position === 'left' ? 'right' : 'left';
  }

  const renderActionButton = ({
    icon,
    onClick,
    active,
  }: {
    icon: ReactRenderElement;
    active?: boolean;
    onClick?: () => void;
  }) => {
    return (
      <M3IconButton
        style={{
          height: 30,
          width: 30,
        }}
        active={active}
        onClick={onClick}
      >
        {icon}
      </M3IconButton>
    );
  };

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
      }}
      display='flex'
      justifyContent={position === 'left' ? 'flex-start' : 'flex-end'}
    >
      <Box
        sx={{
          maxWidth: '60%',
        }}
      >
        <Box
          gap={1}
          display='flex'
          justifyContent={position === 'left' ? 'flex-start' : 'flex-end'}
          sx={{
            pb: 1,
          }}
        >
          <Avatar
            sx={{
              width: 30,
              height: 30,
            }}
            src={
              isAdmin
                ? 'https://go2cosmosbucket.s3.amazonaws.com/bots-upload/images/bbdd06ce1823ea7f028c77b00.svg'
                : getUserPhotoUrl(user.photo_url)
            }
          />
          <Box>
            <Typography component='div' fontSize={13}>
              {isAdmin ? 'Path' : getUserProfileDisplayName(user!).fullName}
            </Typography>
            <Typography component='div' fontSize={10} sx={{ opacity: 0.5 }}>
              {moment.utc(message.created).local().fromNow()}
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent={position === 'left' ? 'flex-start' : 'flex-end'}
        >
          <M3SurfaceContainer elevation={1}>
            <Typography
              component='div'
              fontSize={14}
              maxWidth='100%'
              sx={{
                p: 1,
                px: 2,
                borderRadius: 2,
                pre: {
                  whiteSpace: 'inherit',
                },
              }}
            >
              <RichTextContent content={message.content} />
            </Typography>
            {withSentBy && isAdmin && (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='flex-start'
                sx={{
                  mt: 1,
                  ml: 1.2,
                  pb: 0.5,
                }}
              >
                <Stack direction='row' gap={1}>
                  {renderActionButton({
                    active: isEditOpen,
                    icon: <EditOutlinedIcon style={{ fontSize: 18 }} />,
                    onClick: () => setIsEditOpen(true),
                  })}
                </Stack>
                <Typography
                  textAlign='right'
                  component='div'
                  fontSize={12}
                  pt={1}
                  px={2}
                  pb={1}
                >
                  <span style={{ opacity: 0.5 }}>Sent by:&nbsp;</span>
                  {getUserProfileDisplayName(user!).fullName}
                </Typography>
              </Box>
            )}
          </M3SurfaceContainer>
        </Box>
      </Box>
      <Modal open={isEditOpen}>
        <AIAIAIChatEditFormView
          message={message}
          close={() => {
            setIsEditOpen(false);
          }}
          onEditSuccess={onEditSuccess}
          onDeleteSuccess={onDeleteSuccess}
        />
      </Modal>
    </Box>
  );
}
