import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

import { M3Button, M3IconButton } from '../M3/M3Button';
import UserProfilePopover from '../UserProfilePopover';
import { RejectCancelDialog } from '../Inbox/InboxPTOItemDetail';

import { useAppProvider } from '../../providers/app/app';
import {
  BatchChangeScheduleItem,
  ScheduleChangeRequestStatus,
} from '../../types/schedules';
import { toTitleCase } from '../../utils/string';
import { useUserProvider } from '../../providers/user/user';
import { getUserInitials, getUserProfileDisplayName } from '../../utils/user';
import { useBatchChangeRequestRejectCancel } from '../../hooks/go2-schedules';
import {
  extractScheduleWithDateStartEndTime,
  getFormattedLocalScheduleFromStartDateTime,
} from '../../utils/schedule';
import { UserMetadata } from '../../types/profile';

type Props = {
  isLast?: boolean;
  isFirst?: boolean;
  editable?: boolean;
  withCancelButton?: boolean;
  isRequestsDropdownOpen?: boolean;
  withToggleRequestDropdown?: boolean;
  item: BatchChangeScheduleItem;
  onTakeActionSuccess?: (item: BatchChangeScheduleItem) => void;
};

export default function PermanentChangeRequestScheduleListItem({
  editable = true,
  item,
  isLast,
  withCancelButton,
  onTakeActionSuccess,
  withToggleRequestDropdown = true,
  isRequestsDropdownOpen: defaultIsRequestsDropdownOpen = false,
}: Props) {
  const { isDarkMode } = useAppProvider();
  const { getUser } = useUserProvider();
  const [isRequestsDropdownOpen, setIsRequestsDropdownOpen] = useState(
    defaultIsRequestsDropdownOpen,
  );
  const user = getUser('user_id', item.user);
  const userSubmitted = getUser('user_id', item.submitted_by);

  const [isRejectCancelOpen, setIsRejectCancelOpen] = useState(false);

  const requestedDate = item.requested_date;
  const approvedDate = item.approved_date;

  const cancelChangeRequest = useBatchChangeRequestRejectCancel({
    batchId: item.batch_id,
    action: 'cancel',
  });

  const handleOnCancel = ({ action, note }: any) => {
    cancelChangeRequest.mutate({
      notes: note,
    });
  };

  const renderRejectionNotes = () => {
    if (!item.notes) return null;

    return (
      <Tooltip title={item.notes}>
        <Box
          sx={{
            top: 12,
            left: -40,
            position: 'absolute',
          }}
        >
          <NotesOutlinedIcon
            style={{
              fontSize: 20,
              color: isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          />
        </Box>
      </Tooltip>
    );
  };

  const renderAwaitingStatus = () => {
    let status: ScheduleChangeRequestStatus = item.status;
    let actor = getUser('user_id', item.approved_by);

    return (
      <Box position='relative'>
        {renderRejectionNotes()}
        <Box
          display='flex'
          alignItems='center'
          sx={{
            height: 50,
            minWidth: 145,
            borderRadius: 1,
            overflow: 'hidden',
            border: '1px solid #d3d4d3',
            backgroundColor: '#d3d4d3',
            ...(status === 'approved'
              ? {
                  color: '#fff',
                  borderColor: '#0f840d',
                  backgroundColor: '#0f840d',
                }
              : status === 'rejected' || status === 'canceled'
              ? {
                  color: '#fff',
                  borderColor: '#f34722',
                  backgroundColor: '#f34722',
                }
              : null),
          }}
        >
          <Typography
            fontSize={12}
            sx={{
              pl: 1.5,
              pr: 1.5,
            }}
            component='div'
            style={{
              ...(isDarkMode
                ? {
                    color: status === 'pending' ? '#000' : '#fff',
                  }
                : {
                    color: status === 'pending' ? undefined : '#fff',
                  }),
            }}
          >
            {status === 'approved' ? (
              <>
                Approved
                {actor
                  ? ` by ${actor.first_name || ''} ${actor.last_name || ''}`
                  : ''}
                <br />
                {!approvedDate ? (
                  <Skeleton variant='text' width={70} />
                ) : (
                  moment.utc(approvedDate).local().format('MMM D, YYYY')
                )}
              </>
            ) : status === 'rejected' || status === 'canceled' ? (
              <>
                {status === 'rejected' ? 'Rejected' : 'Cancelled'}
                {actor
                  ? ` by ${actor.first_name || ''} ${actor.last_name || ''}`
                  : ''}
                <br />
                {!approvedDate ? (
                  <Skeleton variant='text' width={70} />
                ) : (
                  moment.utc(approvedDate).local().format('MMM D, YYYY')
                )}
              </>
            ) : (
              <>
                Awaiting Approval
                <br />1 pending approval
              </>
            )}
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
              height: 50,
              width: 55,
              background: '#fff',
              borderLeft: '1px solid #d3d4d3',
            }}
          >
            <Avatar
              sx={{
                width: 40,
                height: 40,
              }}
              src={actor?.photo_url}
              style={{
                ...(status === 'approved'
                  ? {
                      color: '#fff',
                      borderColor: '#0f840d',
                      backgroundColor: '#0f840d',
                    }
                  : status === 'rejected' || status === 'canceled'
                  ? {
                      color: '#fff',
                      borderColor: 'red',
                      backgroundColor: 'red',
                    }
                  : null),
              }}
            >
              {status === 'approved' ? (
                <DoneOutlinedIcon
                  sx={{ color: '#fff', opacity: '1 !important' }}
                />
              ) : status === 'rejected' || status === 'canceled' ? (
                <CloseOutlinedIcon
                  sx={{ color: '#fff', opacity: '1 !important' }}
                />
              ) : (
                <TimerOutlinedIcon />
              )}
            </Avatar>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (cancelChangeRequest.isSuccess) {
      onTakeActionSuccess?.(item);
    }
  }, [item, cancelChangeRequest.isSuccess, onTakeActionSuccess]);

  return (
    <>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Box flex={2} pt={0.5} pl={1}>
          <Typography
            component='div'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            <Typography
              component='div'
              // pt={2.5}
              gap={2}
              flex={2}
              fontSize={18}
              fontWeight={500}
              display='flex'
              alignItems='center'
            >
              <UserProfilePopover user={user as UserMetadata}>
                <Avatar sx={{ width: 60, height: 60 }} src={user?.photo_url}>
                  {
                    getUserInitials(`${user?.first_name} ${user?.last_name}`)
                      .initial
                  }
                </Avatar>
              </UserProfilePopover>
              <Box>
                <Typography component='div' fontSize={22} fontWeight={300}>
                  {getUserProfileDisplayName(user!).fullName}
                </Typography>
                <Stack
                  direction='row'
                  alignItems='flex-start'
                  justifyContent='space-between'
                >
                  <Typography
                    component='div'
                    fontSize={12}
                    sx={{ opacity: 0.8 }}
                  >
                    <span
                      style={{
                        opacity: 0.5,
                      }}
                    >
                      Requested By:&nbsp;
                    </span>
                    {toTitleCase(item.requested_by_role)}
                  </Typography>
                </Stack>
              </Box>
            </Typography>
            <Box mt={-1}>
              {withCancelButton ? (
                <M3Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => setIsRejectCancelOpen(true)}
                >
                  &nbsp;Cancel&nbsp;
                </M3Button>
              ) : (
                <>
                  <Typography
                    component='div'
                    fontSize={12}
                    display='flex'
                    justifyContent='flex-end'
                    sx={{ mb: 0.5, textAlign: 'right' }}
                  >
                    <div style={{ opacity: 0.5 }}>Date Requested:</div>&nbsp;
                    {!requestedDate ? (
                      <Skeleton variant='text' width={70} />
                    ) : (
                      moment.utc(requestedDate).local().format('MMM D, YYYY')
                    )}
                  </Typography>
                  {renderAwaitingStatus()}
                </>
              )}
            </Box>
          </Typography>
          {!!item.reason && (
            <Typography
              p={1}
              pl={2}
              pr={2}
              mt={1}
              borderRadius={1}
              component='div'
              fontSize={14}
              fontStyle='italic'
              sx={{
                opacity: 0.5,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              {item.reason}
            </Typography>
          )}
        </Box>
      </Box>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent='flex-end'
        mt={-1}
        mb={1}
      >
        <Typography component='div' fontSize={12} sx={{ opacity: 0.8 }}>
          <span
            style={{
              opacity: 0.5,
            }}
          >
            Submitted By:&nbsp;
          </span>
          {getUserProfileDisplayName(userSubmitted).fullName}
        </Typography>
      </Stack>
      {isRequestsDropdownOpen && (
        <Box pb={1}>
          <Box px={2} display='flex' gap={6}>
            <Box width={100} minWidth={100} />
            <Typography
              component='div'
              fontSize={12}
              fontWeight={500}
              flex={1}
              width={0}
              sx={{ opacity: 0.5 }}
            >
              Current:{' '}
            </Typography>
            <Box width={50} minWidth={50} />
            <Box width={100} minWidth={100} />
            <Typography
              component='div'
              fontSize={12}
              fontWeight={500}
              flex={1}
              width={0}
              sx={{ opacity: 0.5 }}
            >
              New:{' '}
            </Typography>
          </Box>
          <Divider sx={{ opacity: 0.5, mt: 0.5 }} />
          {item.requests.map((request) => {
            return (
              <PermanentCurrentNewScheduleBlock
                key={request.id}
                request={request}
                isDelete={item.delete}
              />
            );
          })}
        </Box>
      )}
      {withToggleRequestDropdown && (
        <Box
          mt={-1}
          mb={0.5}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <M3IconButton
            style={{
              width: 30,
              height: 30,
              minWidth: 30,
              minHeight: 30,
            }}
            onClick={() => setIsRequestsDropdownOpen(!isRequestsDropdownOpen)}
          >
            <ExpandMoreOutlinedIcon
              sx={{
                transform: `rotateZ(${isRequestsDropdownOpen ? 180 : 0}deg)`,
              }}
              style={{
                fontSize: 18,
              }}
            />
          </M3IconButton>
        </Box>
      )}
      {!isLast && <Divider sx={{ opacity: 0.5 }} />}
      <RejectCancelDialog
        isOpen={isRejectCancelOpen}
        user={user!}
        action='reject'
        isTakingAction={cancelChangeRequest.isLoading}
        onTakeAction={handleOnCancel}
        setIsOpen={(isOpen: boolean) => setIsRejectCancelOpen(isOpen)}
        dialogProps={{
          dialog: {
            title: `Are you sure you want to cancel the schedule change request?`,
          },
          actions: {
            confirm: {
              label: 'Confirm',
              width: 50,
            },
          },
        }}
      />
    </>
  );
}

type PermanentCurrentNewScheduleBlockProps = {
  request: BatchChangeScheduleItem['requests'][0];
  isDelete?: boolean;
};
function PermanentCurrentNewScheduleBlock({
  request,
  isDelete,
}: PermanentCurrentNewScheduleBlockProps) {
  const currentSchedule = getFormattedLocalScheduleFromStartDateTime(
    (() => {
      if (!request.metadata) return null;
      return {
        ...request.metadata,
        ...extractScheduleWithDateStartEndTime({
          weekday: request.metadata.weekday!,
          start_time: request.metadata.start_time!,
          end_time: request.metadata.end_time!,
          tzinfo: request.metadata.tzinfo!,
        }),
      };
    })(),
  );

  const newSchedule = getFormattedLocalScheduleFromStartDateTime(
    (() => {
      return {
        ...request,
        ...extractScheduleWithDateStartEndTime({
          weekday: request.weekday!,
          tzinfo: request.tzinfo,
          start_time: request.new_start_time,
          end_time: request.new_end_time,
        }),
      };
    })(),
  );

  return (
    <Box
      flex={1}
      display='flex'
      gap={6}
      alignItems='center'
      minWidth={250}
      py={1}
      px={2}
    >
      <Typography fontSize={14} fontWeight={500} minWidth={100} width={100}>
        {currentSchedule.dayTime.day ? (
          currentSchedule.dayTime.day
        ) : (
          <Skeleton variant='text' sx={{ flex: 1 }} />
        )}
      </Typography>
      <Box flex={1} width={0}>
        <Typography component='div' display='flex' alignItems='center'>
          {currentSchedule.timeDiff.overlap && (
            <NightsStayOutlinedIcon style={{ fontSize: 18 }} />
          )}
          <>
            {currentSchedule.dayTime?.start_time_local} –{' '}
            {currentSchedule.dayTime?.end_time_local}
          </>
          <span
            style={{
              flex: 1,
              fontSize: 12,
              opacity: 0.5,
              textAlign: 'right',
            }}
          >
            ({currentSchedule.timeDiff.hours}h)
          </span>
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center' width={50} minWidth={50}>
        <ArrowRightAltOutlinedIcon sx={{ opacity: 0.1 }} />
      </Box>
      <Typography fontSize={14} fontWeight={500} minWidth={100} width={100}>
        {newSchedule.dayTime.day}
      </Typography>
      <Box flex={1} width={0}>
        <Typography component='div' display='flex' alignItems='center'>
          {isDelete ? (
            <span style={{ opacity: 0.5 }}>–</span>
          ) : (
            <>
              {newSchedule.timeDiff.overlap && (
                <NightsStayOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {newSchedule.dayTime.start_time_local} –{' '}
              {newSchedule.dayTime.end_time_local}
              <span
                style={{
                  flex: 1,
                  fontSize: 12,
                  opacity: 0.5,
                  textAlign: 'right',
                }}
              >
                ({newSchedule.timeDiff.hours}h)
              </span>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
}
