import { Stack, Typography, Box } from '@mui/material';
import React, { forwardRef, PropsWithChildren } from 'react';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import JobDescriptionDetail from '../TeamSettings/JobDescription/JobDescriptionDetail';
import ScriptSvgIcon from '../SvgIcons/ScriptSvgIcon';

import { UserMetadata } from '../../types/profile';
import { useJobDescriptionById } from '../../hooks/jobs';
import { useAppProvider } from '../../providers/app/app';

type JobDescriptionDetailModalViewProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    user?: UserMetadata;
  };

export function JobDescriptionDetailModalViewBase({
  user,
  close,
}: JobDescriptionDetailModalViewProps) {
  const { isDarkMode } = useAppProvider();
  const jobDescriptionId = user?.job_description_id;
  const jobDescription = useJobDescriptionById(
    { id: jobDescriptionId! },
    { enabled: !!jobDescriptionId },
  );
  const jdData = jobDescription.data;

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <ScriptSvgIcon
          height={20}
          style={{
            top: 4,
            position: 'relative',
          }}
          wrapperStyle={{
            marginRight: 8,
          }}
          fillColor={
            isDarkMode
              ? 'var(--md-sys-color-on-surface-dark)'
              : 'var(--md-sys-color-on-surface-light)'
          }
        />
        <Typography flex={1} component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            Job Description
          </Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
      willUpdateLayout={jdData}
    >
      <Box sx={{ pt: 4, pb: 5 }}>
        <JobDescriptionDetail
          userView
          editable={false}
          jobDescriptionId={jobDescriptionId}
        />
      </Box>
    </ModalCardView>
  );
}

const JobDescriptionDetailModalView = forwardRef(
  (props: JobDescriptionDetailModalViewProps, ref) => (
    <JobDescriptionDetailModalViewBase {...props} />
  ),
);

export default JobDescriptionDetailModalView;
