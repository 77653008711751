import posthog from 'posthog-js';

type EventName =
  // global
  | 'help center clicked'
  | 'view go2 portal updates clicked'
  | 'request clicked'
  | 'submit issue clicked'
  | 'circles clicked'
  | 'go2bots clicked'

  // manifest
  | 'manifest clicked'
  | 'manifest comment submitted'
  | 'manifest date filter clicked'
  | 'manifest members filter clicked'
  | 'manifest SOD clicked'
  | 'manifest EOD clicked'
  | 'manifest SOD_EOD clicked'
  | 'manifest submit clicked'
  | 'manifest assist clicked'
  | 'manifest regenerate response clicked'

  // reports focus
  | 'reports focus clicked'
  | 'reports focus answers clicked'
  | 'reports focus numbers clicked'
  | 'reports focus date filter clicked'
  | 'reports focus members filter clicked'
  | 'reports focus individual answers clicked'
  | 'reports focus export clicked'
  | 'reports focus assist clicked'

  // inbox
  | 'inbox clicked'
  | 'inbox all clicked'
  | 'inbox unread tab clicked'
  | 'inbox all messages tab clicked'
  | 'inbox unread message clicked'
  | 'inbox read message clicked'
  | 'inbox help needed clicked'
  | 'inbox support clicked'
  | 'inbox go2bots clicked'

  // chat
  | 'inbox chat clicked'
  | 'inbox new chat clicked'
  | 'inbox new chat submitted'
  | 'inbox chat message viewed'

  // team
  | 'team clicked'
  | 'team calendar clicked'
  | 'team time clicked'
  | 'team schedule clicked'

  // settings
  | 'settings personal clicked'
  | 'settings team clicked'
  | 'settings admin clicked'

  // edit time
  | 'edit time clicked'
  | 'edit time edit clicked'
  | 'edit time profile clicked'
  | 'edit time edit submit clicked'
  | 'edit time delete clicked'
  | 'edit time add time clicked';

posthog.init(process.env.REACT_APP_POST_HOG_ID!, {
  api_host: 'https://app.posthog.com',
  autocapture: false,
});

export function capture(event: EventName, metadata?: any) {
  posthog.capture(event, metadata);
}

export function identify(userId: number, metadata?: any) {
  posthog.identify(`${userId}`, metadata);
}
