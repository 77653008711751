import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { HrTimeOffTypeId } from '../types/hr';
import {
  PTOAction,
  PTOBalanceResponse,
  PTOItemResponse,
  PTOListItemResponse,
} from '../types/pto';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { DateYYYYMMDD } from '../types/types';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

type UsePTOListProps = ListQuery & {
  staff: string | number | undefined;
  division?: string | number | null;
};
export function usePTOList<R = PTOListItemResponse>(
  params: UsePTOListProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UsePTOListProps>({
    params,
    baseIdentifier: 'usePTOList',
    identifierKeys: ['staff', 'division'],
    queryParamKeys: ['staff', 'division'],
  });

  return useQueryApi<UsePTOListProps, ListResult<R>>(
    identifiers,
    '/hr/pto/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UsePTOByIDProps = {
  id: string;
};
export function usePTOByID<R = PTOItemResponse>(
  params: UsePTOByIDProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UsePTOByIDProps>({
    params,
    baseIdentifier: 'usePTOByID',
    identifierKeys: ['id'],
  });

  return useQueryApi<UsePTOByIDProps, R>(
    identifiers,
    `/hr/pto/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UsePTOBalanceByEmployeeProps = {
  eeid: string;
};
export function usePTOBalanceByEmployee<R = PTOBalanceResponse>(
  params: UsePTOBalanceByEmployeeProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UsePTOBalanceByEmployeeProps>({
      params,
      baseIdentifier: 'usePTOBalanceByEmployee',
      identifierKeys: ['eeid'],
      queryParamKeys: [],
    });

  return useQueryApi<UsePTOBalanceByEmployeeProps, R>(
    identifiers,
    `/hr/pto/available-balance/${params.eeid}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UsePTORequestActionProps = {
  id: number | string;
  action?: PTOAction | null;
};
export function usePTORequestAction(
  { id, action }: UsePTORequestActionProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(
    `/hr/pto/${id}/${action ? `${action}/` : ''}`,
    {},
    axiosConfig,
  );
}

export type UseRequestPTOProps = {
  start: DateYYYYMMDD;
  end: DateYYYYMMDD;
  note: string;
  amount: string;
  time_off_type_id: HrTimeOffTypeId;
};
export function useRequestPTO(
  props?: UseRequestPTOProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UseRequestPTOProps>(
    `/hr/pto/request/`,
    {},
    axiosConfig,
  );
}
