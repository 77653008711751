import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import DocumentTitle from '../components/DocumentTitle';

import { useNotificationProvider } from '../providers/notification/notification';
import { stripToFormatterWord, toTitleCase } from '../utils/string';

type Props = {};

const Inbox = (props: Props) => {
  const { pathname } = useLocation();
  const { setInboxItemState, setFilterById } = useNotificationProvider();
  const [page] =
    pathname.match(
      /\/(all|daily-report|request|job-description|help-needed|support|go2bots)/i,
    ) || [];
  let title: string | null = null;

  useEffect(() => {
    setInboxItemState(null);

    return () => {
      setFilterById('all', 'all');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (page) {
    if (page === '/go2bots') {
      title = 'Go2Bots';
    } else {
      title = toTitleCase(stripToFormatterWord(page.replace('/', '')));
    }
  }

  return (
    <>
      {title && <DocumentTitle title={title} trailingTitle='Inbox' />}
      <Outlet />
    </>
  );
};

export default Inbox;
