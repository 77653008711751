import React, { KeyboardEvent, useEffect, useRef } from 'react';
import {
  Stack,
  Box,
  Typography,
  Slider,
  SliderProps,
  SxProps,
  Chip,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { M3TextField } from '../M3/M3TextField';
import { useForm } from '../BasicForm';
import BasicPopoverWithSearch, {
  M3OptionItem,
  useBasicPopover,
} from '../Popover/BasicPopoverWithSearch';

import { useAppProvider } from '../../providers/app/app';
import { M3Button } from '../M3/M3Button';
import { M3AutocompleteProps } from '../M3/M3Autocomplete';
import { ReactRenderElement } from '../../types/types';
import { spliceFromArray } from '../../utils/array';
import {
  useOpenAiPromptConfigurationByNameIdentifier,
  useOpenAiSavePromptConfiguration,
} from '../../hooks/openai';
import { defaultPromptConfig } from '../SideSheet/AIAssistSideSheet';
import {
  OpenAIModelID,
  OpenAIModels,
  PromptConfigItemResponse,
  PromptConfigModel,
} from '../../types/openai';
import { pickKeys } from '../../utils/object';

export type ChatGPTPlaygroundProps = {
  title?: string;
  prompt_identifier?: string;
};

type ChatGPTPlaygroundState = {
  prompt_text: string;
  model: string;
  max_tokens: number; // 256,
  temperature: number; // 0.7,
  top_p: number; // 1,
  frequency_penalty: number; //0,
  presence_penalty: number; //0,
  best_of: number; //1,
  // "echo": boolean, //true,
  // "stream": boolean, //true
  stop: string[];
  stop_value: string;
};

/**
 * Add all the models here:
 */
export const modelOptions: (M3OptionItem & { id: OpenAIModelID })[] =
  Object.values(OpenAIModels)
    .filter((m) => m.available)
    .map((m) => ({
      id: m.id as OpenAIModelID,
      label: m.name,
    }));

const configKeys: (keyof PromptConfigModel)[] = [
  'prompt_text',
  'model',
  'max_tokens',
  'temperature',
  'top_p',
  'frequency_penalty',
  'presence_penalty',
  'best_of',
  'stop',
];

const ChatGPTPlayground = ({
  title,
  prompt_identifier,
}: ChatGPTPlaygroundProps) => {
  const { isDarkMode } = useAppProvider();
  const { enqueueSnackbar } = useSnackbar();

  const promptConfig = useOpenAiPromptConfigurationByNameIdentifier({
    prompt_identifier: prompt_identifier!,
  });
  const configData = promptConfig?.data;
  // saves the configuration of the prompt
  const savePrompt = useOpenAiSavePromptConfiguration(
    {
      prompt_identifier: configData ? prompt_identifier : undefined,
    },
    {
      method: configData ? 'PATCH' : 'POST',
    },
  );

  const getDefaultPromptConfig = (data?: PromptConfigItemResponse) => {
    if (data) {
      return {
        ...pickKeys(data, configKeys),
        stop_value: '',
      } as ChatGPTPlaygroundState;
    }

    return {
      ...pickKeys(defaultPromptConfig, configKeys),
      stop_value: '',
    } as ChatGPTPlaygroundState;
  };

  const {
    formState,
    hasChanged,
    handleChange,
    updateState,
    resetState,
    setFormState,
  } = useForm<ChatGPTPlaygroundState>(() =>
    getDefaultPromptConfig(promptConfig.data),
  );
  const stopSeqInputRef = useRef<HTMLInputElement | null>(null);

  const isConfigNotSet =
    !(promptConfig.isSuccess && promptConfig.data) || !!promptConfig.error;

  const onSaveConfig = () => {
    savePrompt.mutate({
      ...pickKeys(formState as unknown as PromptConfigModel, configKeys),
      prompt_identifier,
    });
  };

  /**
   * Set new default coming from server
   */
  useEffect(() => {
    if (promptConfig.isSuccess && promptConfig.data) {
      setFormState(getDefaultPromptConfig(promptConfig.data));
    }
    // eslint-disable-next-line
  }, [promptConfig.isSuccess]);

  useEffect(() => {
    if (savePrompt.isSuccess) {
      resetState(getDefaultPromptConfig(savePrompt.data));
      promptConfig.refetch();
      savePrompt.reset();
      enqueueSnackbar('Prompt configuration was successfully updated.');
    }
    // eslint-disable-next-line
  }, [savePrompt.isSuccess]);

  return (
    <Box
      p={2}
      sx={{
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        background: isDarkMode
          ? 'var(--md-ref-palette-neutral20)'
          : 'var(--md-ref-palette-neutral95)',
      }}
    >
      {isConfigNotSet && (
        <Typography
          pb={1}
          component='div'
          fontSize={12}
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-error80)'
              : 'var(--md-ref-palette-error40)',
          }}
        >
          This prompt is currently using the default configuration and has not
          been customized yet.
        </Typography>
      )}
      <Stack
        gap={3}
        direction='row'
        marginTop='-2px'
        alignItems='stretch'
        justifyContent='space-between'
      >
        <Box display='flex' flexDirection='column' flex={1}>
          <Typography
            mb={2}
            flex={1}
            component='div'
            position='relative'
            sx={{
              borderRadius: 2,
              ...(isDarkMode
                ? {
                    background: 'var(--md-ref-palette-neutral-variant30)',
                    boxShadow:
                      '0 0 0 1px var(--md-ref-palette-neutral-variant40)',
                  }
                : {
                    background: 'var(--md-ref-palette-neutral-variant90)',
                    boxShadow:
                      '0 0 0 1px var(--md-ref-palette-neutral-variant90)',
                  }),
            }}
          >
            <textarea
              name='prompt_text'
              autoSave='off'
              autoCorrect='off'
              autoComplete='off'
              autoCapitalize='off'
              placeholder={`Create your prompt here${
                title ? ` for "${title}"` : '...'
              }`}
              value={formState.prompt_text}
              onChange={handleChange}
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: 0,
                fontSize: 14,
                resize: 'none',
                fontFamily: 'inherit',
                outline: 'none',
                color: 'inherit',
                display: 'block',
                background: 'none',
                position: 'absolute',
                padding: '20px 20px',
              }}
            ></textarea>
          </Typography>
          <Stack direction='row' justifyContent='space-between'>
            <M3Button
              color='primary'
              variant='outlined'
              sx={{ width: 90 }}
              disabled={
                savePrompt.isLoading || promptConfig.isLoading || !hasChanged
              }
              onClick={onSaveConfig}
            >
              {savePrompt.isLoading ? <CircularProgress size={18} /> : 'Save'}
            </M3Button>
            <Tooltip title='This identifier will be used to match existing configuration. Any prompt that uses the same identifier will share the same configuration.'>
              <Typography
                flexDirection='column'
                display='flex'
                component='div'
                fontSize={11}
              >
                <span style={{ opacity: 0.5, fontWeight: 500 }}>
                  Identifier:&nbsp;
                </span>
                <span style={{ fontSize: 12 }}>{prompt_identifier}</span>
              </Typography>
            </Tooltip>
          </Stack>
        </Box>
        <Box
          mb={-2}
          sx={{
            width: 210,
            minWidth: 210,
          }}
        >
          <ControlFormRow
            name='model'
            label='Model'
            value={formState.model}
            options={modelOptions}
            onSelect={(item: M3OptionItem) => {
              handleChange({ target: { name: 'model', value: item.id } });
            }}
          />
          <ControlFormRow
            name='temperature'
            label='Temperature'
            slider={{
              value: formState.temperature,
              props: {
                min: 0,
                max: 1,
                step: 0.01,
                onChange: (event: any, value: number | number[]) => {
                  handleChange({ target: { name: 'temperature', value } });
                },
              },
            }}
          />
          <ControlFormRow
            name='max_tokens'
            label='Max Length'
            slider={{
              value: formState.max_tokens,
              props: {
                min: 1,
                max: 4000,
                onChange: (event: any, value: number | number[]) => {
                  handleChange({ target: { name: 'max_tokens', value } });
                },
              },
            }}
          />
          <ControlFormRow
            name='stop'
            label='Stop Sequences'
            caption='Enter sequence and press Enter'
            autocompleteProps={{
              freeSolo: true,
              disableClearable: true,
              noOptionsText: 'Enter a sequences',
            }}
            input={
              <Stack
                direction='row'
                flexWrap='wrap'
                sx={{
                  borderRadius: 1,
                  border: `1px solid ${
                    isDarkMode
                      ? 'var(--md-ref-palette-neutral30)'
                      : 'var(--md-ref-palette-neutral80)'
                  }`,
                }}
              >
                {(formState.stop ?? []).map((tag, index) => {
                  return (
                    <Chip
                      key={index}
                      label={tag}
                      size='small'
                      sx={{
                        mt: 1,
                        ml: 1,
                        fontSize: 12,
                      }}
                      onDelete={() => {
                        const stops = [...formState.stop];
                        spliceFromArray(stops, tag);
                        handleChange({
                          target: {
                            name: 'stop',
                            value: stops,
                          },
                        });
                      }}
                    />
                  );
                })}
                <M3TextField
                  fullWidth
                  stripAllAutoProps
                  name='stop_value'
                  value={formState.stop_value}
                  placeholder='Enter sequence...'
                  inputRef={stopSeqInputRef}
                  sx={{
                    '.MuiInputBase-root': {
                      background: 'transparent !important',
                    },
                    '.MuiInputBase-input': {
                      pl: 1.4,
                      pr: 1.4,
                      height: 38,
                      maxHeight: 38,
                      fontSize: 14,
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none',
                    },
                  }}
                  onChange={handleChange}
                  onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) => {
                    const value = evt.target.value;
                    if ((evt.key === 'Tab' || evt.key === 'Enter') && value) {
                      updateState((state: ChatGPTPlaygroundState) => {
                        return {
                          ...state,
                          stop_value: '',
                          stop: [...(state.stop ?? []), value],
                        };
                      });
                      if (evt.key === 'Tab') {
                        setTimeout(() => stopSeqInputRef.current?.focus());
                      }
                    }
                  }}
                />
              </Stack>
            }
          />
          <ControlFormRow
            name='top_p'
            label='Top P'
            slider={{
              value: formState.top_p,
              props: {
                min: 0,
                max: 1,
                step: 0.01,
                onChange: (event: any, value: number | number[]) => {
                  handleChange({ target: { name: 'top_p', value } });
                },
              },
            }}
          />
          <ControlFormRow
            name='frequency_penalty'
            label='Frequency penalty'
            slider={{
              value: formState.frequency_penalty,
              props: {
                min: 0,
                max: 2,
                step: 0.01,
                onChange: (event: any, value: number | number[]) => {
                  handleChange({
                    target: { name: 'frequency_penalty', value },
                  });
                },
              },
            }}
          />
          <ControlFormRow
            name='best_of'
            label='Best Of'
            slider={{
              value: formState.best_of,
              props: {
                min: 1,
                max: 20,
                step: 1,
                onChange: (event: any, value: number | number[]) => {
                  handleChange({ target: { name: 'best_of', value } });
                },
              },
            }}
          />
          {/* <ControlFormRow
            name='logprobs'
            label='Show Probabilities'
            value={formState.logprobs}
            options={logPropsOptions}
            onSelect={(item: M3OptionItem) => {
              handleChange({ target: { name: 'logprobs', value: item.id } });
            }}
          /> */}
        </Box>
      </Stack>
    </Box>
  );
};

export default ChatGPTPlayground;

type ControlFormSliderProps = {
  value: number;
  props: SliderProps;
};
type ControlFormRowProps = {
  name: string;
  label: string;
  caption?: string;
  value?: string | number;
  slider?: ControlFormSliderProps;
  options?: M3OptionItem[];
  onSelect?: (item: M3OptionItem) => void;
  autocompleteProps?: Partial<M3AutocompleteProps>;
  input?: ReactRenderElement;
};
function ControlFormRow({
  name,
  value,
  label,
  caption,
  slider,
  options,
  onSelect,
  input,
  autocompleteProps,
}: ControlFormRowProps) {
  const { isDarkMode } = useAppProvider();
  const basicPopover = useBasicPopover();

  const inputSx: SxProps = {
    pl: 1.4,
    pr: 1.4,
    height: 38,
    minHeight: 38,
    fontSize: 14,
    display: 'flex',
    borderRadius: 1,
    cursor: 'pointer',
    userSelect: 'none',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    border: `1px solid ${
      isDarkMode
        ? 'var(--md-ref-palette-neutral30)'
        : 'var(--md-ref-palette-neutral80)'
    }`,
  };

  const renderRow = () => {
    if (slider) {
      return (
        <Slider
          {...slider.props}
          size='small'
          value={slider.value ?? ''}
          sx={{
            mb: -1,
          }}
        />
      );
    }

    if (options) {
      return renderSelectFormField();
    }

    if (input) {
      return input;
    }

    return <M3TextField variant='outlined' fullWidth stripAllAutoProps />;
  };

  const renderSelectFormField = () => {
    const selected = options?.find((opt) => opt.id === value);
    const displayValue = selected?.label;

    return (
      <Box ref={basicPopover.ref}>
        <Typography component='div' sx={inputSx} onClick={basicPopover.open}>
          {displayValue ?? <>&nbsp;</>}
          <ArrowDropDownIcon sx={{ mr: -0.8 }} />
        </Typography>
        <BasicPopoverWithSearch
          withSearch={false}
          open={basicPopover.isOpen}
          anchorEl={basicPopover.ref.current}
          options={options!}
          selected={selected}
          paperProps={{
            style: {
              width: basicPopover.ref.current?.clientWidth,
            },
          }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          onClose={() => {
            basicPopover.close();
          }}
          onSelect={(item: M3OptionItem) => {
            onSelect?.(item);
            basicPopover.close();
          }}
        />
      </Box>
    );
  };

  return (
    <Box mb={2}>
      <Typography
        pb={0.5}
        fontSize={13}
        minHeight={25}
        display='flex'
        fontWeight={500}
        justifyContent='space-between'
        component='div'
      >
        {label}
        {!!slider && <span style={{ fontSize: 14 }}>{slider.value}</span>}
      </Typography>
      {!!caption && (
        <Typography
          fontSize={12}
          component='div'
          pb={0.5}
          mt={-0.5}
          sx={{ opacity: 0.5 }}
        >
          {caption}
        </Typography>
      )}
      {renderRow()}
    </Box>
  );
}
