import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  Input,
  Divider,
  Avatar,
  CircularProgress,
  Modal,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import BasicForm, { useForm } from '../BasicForm';
import Cropper, { CropOptions, CropResult } from '../Cropper';
import { M3Button } from '../M3/M3Button';
import HasViewPermission from '../HasViewPermission';
import { M3TextField } from '../M3/M3TextField';

import { useCurrentProfile, usePostUserSettings } from '../../hooks/profile';
import { prefixUrl } from '../../utils/url';
import { useAppProvider } from '../../providers/app/app';

type Props = {};

type UserStateSettings = {
  has_profile_image: boolean;
  profile_image: string | null;
  profile_image_file: File | null;
  has_report_background_image: boolean;
  is_report_background_removed: boolean;
  report_background_image: string | null;
  report_background_file: File | null;
  report_background_color: string;
  user_color_scheme: string | null;
};

type ModalState = {
  name: 'report' | 'profile' | string;
  file?: File | null;
};

type CropperModalProps = {
  name: 'report' | 'profile' | string;
  file?: File | null;
  cropOptions?: CropOptions;
  onCrop?: (result: CropResult) => void;
};

const stackSx = {
  mt: 2,
  mb: 2,
};

const SettingsForm = (props: Props) => {
  const { isDarkMode, updateActionKeyCounter } = useAppProvider();
  const { data: userProfile } = useCurrentProfile();
  const userSettings = userProfile?.settings;

  const { hasChanged, formState, handleChange, handleSubmit, updateState } =
    useForm<UserStateSettings>(
      {
        has_profile_image: false,
        profile_image: null,
        profile_image_file: null,
        has_report_background_image: false,
        is_report_background_removed: false,
        report_background_image: null,
        report_background_file: null,
        report_background_color:
          userSettings?.misc?.report_background_color ?? '',
        user_color_scheme: userSettings?.misc.user_color_scheme ?? null,
      },
      {
        onStateUpdated: () => {
          postUserSettings.reset();
        },
      },
    );

  const postUserSettings = usePostUserSettings(userSettings?.id, {
    method: userSettings ? 'PATCH' : 'POST',
  });

  const [modalState, setModalState] = useState<ModalState>({
    name: '',
    file: null,
  });
  const [isCoverPhotoExpanded, setIsCoverPhotoExpanded] = useState(false);
  const coverPhotoRef = useRef<HTMLDivElement | null>(null);

  const dropzoneConfig = {
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
    },
  };
  const userDropzone = useDropzone({
    ...dropzoneConfig,
    onDrop: (acceptedFiles) => {
      if (formState.profile_image) {
        URL.revokeObjectURL(formState.profile_image);
      }
      updateState((state) => ({
        ...state,
        has_profile_image: true,
        profile_image_file: acceptedFiles[0],
        profile_image: URL.createObjectURL(acceptedFiles[0]),
      }));
      setModalState((state) => ({
        ...state,
        name: 'profile',
      }));
    },
  });
  const myDayDropzone = useDropzone({
    ...dropzoneConfig,
    onDrop: (acceptedFiles) => {
      if (formState.report_background_image) {
        URL.revokeObjectURL(formState.report_background_image);
      }
      updateState((state) => ({
        ...state,
        is_report_background_removed: false,
        has_report_background_image: true,
        report_background_file: acceptedFiles[0],
        report_background_image: URL.createObjectURL(acceptedFiles[0]),
      }));
      setModalState((state) => ({
        ...state,
        name: 'report',
      }));
    },
  });

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();

    const misc = { ...userSettings?.misc };
    let hasMisc = false;

    if (data.profile_image_file) {
      formData.append('profile_image', data.profile_image_file);
    }

    if (data.is_report_background_removed) {
      formData.append('report_background_image', '');
    } else if (data.report_background_file) {
      formData.append('report_background_image', data.report_background_file);
    }

    if (data.report_background_color) {
      hasMisc = true;
      misc.report_background_color = data.report_background_color;
    }

    if (
      typeof data.user_color_scheme !== 'undefined' ||
      data.user_color_scheme !== null
    ) {
      hasMisc = true;
      misc.user_color_scheme = data.user_color_scheme || '';
    }

    if (hasMisc) {
      formData.append('misc', JSON.stringify(misc));
    }

    postUserSettings.mutate(formData);
  });

  function getPicturePath(path: string = '') {
    if (path?.search(/^\//) > -1) {
      return `${process.env.REACT_APP_AWS_S3_BUCKET}${path}`;
    }
    if (path?.indexOf('http') > -1 || path?.indexOf('blob:') > -1) {
      return path;
    }
    return prefixUrl(
      path,
      process.env.NODE_ENV === 'production'
        ? `${process.env.REACT_APP_AWS_S3_BUCKET}/users-upload`
        : process.env.REACT_APP_AWS_S3_BUCKET,
    );
  }

  function onRemoveReportBackgroundPhoto(evt: SyntheticEvent) {
    evt.stopPropagation();

    if (formState.report_background_image) {
      URL.revokeObjectURL(formState.report_background_image);
    }

    setIsCoverPhotoExpanded(false);
    updateState((state) => ({
      ...state,
      is_report_background_removed: true,
      has_report_background_image: false,
      report_background_image: null,
    }));
  }

  const renderCropperImage = ({
    name,
    file,
    cropOptions,
    onCrop,
  }: CropperModalProps) => {
    return (
      <Modal open={modalState.name === name}>
        <Cropper
          file={file}
          cropOptions={cropOptions}
          onCrop={onCrop}
          close={() => {
            setModalState((state) => ({
              ...state,
              name: '',
              file: null,
            }));
          }}
        />
      </Modal>
    );
  };

  useEffect(() => {
    if (postUserSettings.isSuccess) {
      updateActionKeyCounter('user_save_settings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postUserSettings.isSuccess]);

  useEffect(() => {
    return () => {
      if (formState.profile_image) {
        URL.revokeObjectURL(formState.profile_image);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.profile_image]);

  useEffect(() => {
    return () => {
      if (formState.report_background_image) {
        URL.revokeObjectURL(formState.report_background_image);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.report_background_image]);

  return (
    <Box display='flex'>
      <BasicForm onSubmit={onSubmit}>
        <Stack gap={3} direction='row' sx={stackSx}>
          <Box sx={{ width: 300 }}>
            <Typography component='div' fontWeight={500} fontSize={16}>
              Profile
            </Typography>
            <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
              Configure your profile
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography
              component='h6'
              fontWeight={500}
              sx={{ fontSize: 14, mb: 1 }}
            >
              Upload Profile Picture
            </Typography>
            <Box
              {...userDropzone.getRootProps({ className: 'dropzone' })}
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{
                p: 4,
                width: 140,
                height: 140,
                cursor: 'pointer',
                borderRadius: 100,
                background: isDarkMode
                  ? 'rgba(255, 255, 255, 0.05)'
                  : '#f5f5f5',
                border: isDarkMode
                  ? '1px solid var(--md-ref-palette-neutral20)'
                  : '1px solid rgb(232, 232, 232)',
              }}
            >
              <input {...userDropzone.getInputProps()} />
              {formState.has_profile_image || userSettings?.profile_image ? (
                <Avatar
                  src={
                    formState.profile_image! ||
                    getPicturePath(userSettings?.profile_image)!
                  }
                  sx={{ width: 140, height: 140 }}
                />
              ) : (
                <FileUploadOutlinedIcon sx={{ opacity: 0.5 }} />
              )}
            </Box>
            {renderCropperImage({
              name: 'profile',
              file: userDropzone.acceptedFiles[0],
              cropOptions: {
                aspect: 1,
              },
              onCrop: (result: CropResult) => {
                if (formState.profile_image) {
                  URL.revokeObjectURL(formState.profile_image);
                }
                updateState((state) => ({
                  ...state,
                  has_profile_image: true,
                  profile_image_file: result.file,
                  profile_image: URL.createObjectURL(result.file),
                }));
                setModalState((state) => ({
                  ...state,
                  name: '',
                }));
              },
            })}
          </Box>
        </Stack>
        <HasViewPermission restrict={['partner_member']}>
          <br />
          <Divider />
          <br />
          <Stack gap={3} direction='row' sx={stackSx}>
            <Box sx={{ width: 300 }}>
              <Typography component='div' fontWeight={500} fontSize={16}>
                My Day
              </Typography>
              <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
                Configure your My Day profile
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography
                component='h6'
                fontWeight={500}
                sx={{ fontSize: 14, mb: 1 }}
              >
                Upload Cover Photo
              </Typography>
              <Box
                ref={coverPhotoRef}
                {...myDayDropzone.getRootProps({ className: 'dropzone' })}
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  p: isCoverPhotoExpanded ? 0 : 4,
                  height: isCoverPhotoExpanded ? undefined : 200,
                  minHeight: 200,
                  cursor: 'pointer',
                  position: 'relative',
                  background: isDarkMode
                    ? 'rgba(255, 255, 255, 0.05)'
                    : '#f5f5f5',
                  border: isDarkMode
                    ? '1px solid var(--md-ref-palette-neutral20)'
                    : '1px solid rgb(232, 232, 232)',
                }}
              >
                <input {...myDayDropzone.getInputProps()} />
                {!formState.is_report_background_removed &&
                (formState.has_report_background_image ||
                  userSettings?.report_background_image) ? (
                  <>
                    <Box
                      component='img'
                      src={
                        formState.report_background_image! ||
                        getPicturePath(userSettings?.report_background_image)!
                      }
                      sx={{
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        display: 'block',
                        position: isCoverPhotoExpanded ? undefined : 'absolute',
                        objectFit: 'cover',
                        objectPosition: 'top center',
                      }}
                    />
                    <M3Button
                      variant='contained'
                      sx={{
                        top: 0,
                        right: 0,
                        width: 44,
                        height: 44,
                        zIndex: 3,
                        borderRadius: 0,
                        minWidth: 'initial',
                        position: 'absolute',
                        backgroundColor: '#f00c0c !important',
                        '&:hover': {
                          backgroundColor: '#f61212 !important',
                        },
                      }}
                      onClick={onRemoveReportBackgroundPhoto}
                    >
                      <CloseOutlinedIcon
                        style={{
                          marginRight: 0,
                          color: '#ffffff',
                        }}
                      />
                    </M3Button>
                    <M3Button
                      sx={{
                        bottom: -48,
                        right: 0,
                        width: 44,
                        height: 44,
                        minWidth: 'initial',
                        position: 'absolute',
                      }}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setIsCoverPhotoExpanded((s) => !s);
                      }}
                    >
                      <KeyboardDoubleArrowDownOutlinedIcon
                        style={{
                          marginRight: 0,
                          opacity: 0.5,
                          transform: `rotateZ(${
                            isCoverPhotoExpanded ? 180 : 0
                          }deg)`,
                        }}
                      />
                    </M3Button>
                  </>
                ) : (
                  <>
                    <FileUploadOutlinedIcon sx={{ opacity: 0.5, mr: 1 }} />
                    <p
                      style={{
                        opacity: 0.5,
                        color: isDarkMode ? '#fff' : undefined,
                      }}
                    >
                      Drag &amp; drop your file here or click to select a file
                    </p>
                  </>
                )}
              </Box>
              {renderCropperImage({
                name: 'report',
                file: myDayDropzone.acceptedFiles[0],
                cropOptions: {
                  aspect: 3 / 4,
                },
                onCrop: (result: CropResult) => {
                  if (formState.report_background_image) {
                    URL.revokeObjectURL(formState.report_background_image);
                  }
                  updateState((state) => ({
                    ...state,
                    is_report_background_removed: false,
                    has_report_background_image: true,
                    report_background_file: result.file,
                    report_background_image: URL.createObjectURL(result.file),
                  }));
                  setModalState((state) => ({
                    ...state,
                    name: '',
                  }));
                },
              })}
              <br />
              <Typography component='h6' fontWeight={500} sx={{ fontSize: 14 }}>
                Default Background Color
              </Typography>
              <Stack
                flexDirection='row'
                alignItems='center'
                sx={{
                  '.MuiInput-root': {
                    background: 'transparent !important',
                  },
                }}
              >
                <Input
                  type='color'
                  name='report_background_color'
                  disableUnderline
                  value={
                    formState.report_background_color ||
                    userSettings?.misc?.report_background_color ||
                    '#c2e7fe'
                  }
                  onChange={handleChange}
                  inputProps={{
                    style: {
                      border: 0,
                      height: 40,
                      width: 100,
                      outline: 'none',
                      boxShadow: 'none',
                    },
                    sx: {
                      '::-webkit-color-swatch': {
                        border: 0,
                      },
                    },
                  }}
                />
                <Typography sx={{ ml: 1, opacity: 0.5 }}>
                  {formState.report_background_color ||
                    userSettings?.misc?.report_background_color ||
                    '#c2e7fe'}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </HasViewPermission>
        <br />
        <Divider />
        <br />
        <Stack gap={3} direction='row' sx={stackSx}>
          <Box sx={{ width: 300 }}>
            <Typography component='div' fontWeight={500} fontSize={16}>
              Theme
            </Typography>
            <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
              Configure the theme here
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography component='h6' fontWeight={500} sx={{ fontSize: 14 }}>
              How to Set Custom Color Scheme
            </Typography>
            <Typography
              component='div'
              fontSize={14}
              sx={{ opacity: 0.8, mb: 2 }}
            >
              <ul style={{ margin: 0 }}>
                <li>
                  Generate it here:&nbsp;
                  <a
                    href='https://m3.material.io/theme-builder#/custom'
                    target='_blank'
                    rel='noreferrer'
                  >
                    MUI 3 Theme Builder
                  </a>
                </li>
                <li>
                  Download and copy the content of <strong>tokens.css</strong>{' '}
                  and paste it below
                </li>
                <li>
                  Here's a quick video on how to do it:{' '}
                  <a
                    href='https://www.loom.com/share/7d00b271f8854202a752fa330055ff50'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Link
                  </a>
                </li>
              </ul>
            </Typography>
            <M3TextField
              fullWidth
              multiline
              rows={10}
              name='user_color_scheme'
              label='Paste content of tokens.css here'
              defaultValue={userSettings?.misc.user_color_scheme}
              onChange={handleChange}
              InputLabelProps={{
                sx: {
                  background: 'transparent !important',
                },
              }}
            />
          </Box>
        </Stack>

        {/* <br />
        <Divider />
        <br />
        <Stack gap={3} direction='row' sx={stackSx}>
          <Box sx={{ width: 300 }}>
            <Typography component='div' fontWeight={500} fontSize={16}>
              Feature Flag
            </Typography>
            <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
              Configure your Feature Flags
            </Typography>
          </Box>
          <Box flex={1}>
            <Stack direction='row' alignItems='center'>
              <Typography
                component='div'
                flex={1}
                fontWeight={500}
                fontSize={16}
              >
                Feature 1
                <Typography fontSize={14} sx={{ opacity: 0.8 }}>
                  Feature 1 Description
                </Typography>
              </Typography>
              <Switch />
            </Stack>
            <br />
            <Stack direction='row' alignItems='center'>
              <Typography
                component='div'
                flex={1}
                fontWeight={500}
                fontSize={16}
              >
                Feature 2
                <Typography fontSize={14} sx={{ opacity: 0.8 }}>
                  Feature 2 Description
                </Typography>
              </Typography>
              <Switch />
            </Stack>
            <br />
            <Stack direction='row' alignItems='center'>
              <Typography
                component='div'
                flex={1}
                fontWeight={500}
                fontSize={16}
              >
                Feature 3
                <Typography fontSize={14} sx={{ opacity: 0.8 }}>
                  Feature 3 Description
                </Typography>
              </Typography>
              <Switch />
            </Stack>
          </Box>
        </Stack> */}
        <br />
        <br />
        <Stack direction='row'>
          <Box sx={{ width: 325 }} />
          <M3Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={
              postUserSettings.isLoading || postUserSettings.isSuccess
                ? true
                : !hasChanged
            }
            sx={{
              pr: 2,
            }}
          >
            {postUserSettings.isLoading ? (
              <CircularProgress color='inherit' size={20} sx={{ mr: 1.25 }} />
            ) : postUserSettings.isSuccess ? (
              <DoneOutlinedIcon />
            ) : (
              <EditOutlinedIcon />
            )}
            Save Settings
          </M3Button>
        </Stack>
      </BasicForm>
    </Box>
  );
};

export default SettingsForm;
