import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';
import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../utils/date';

type TotalHourProps = {
  columnSx: SxProps;
  firstColWidth: number;
  isLoading: boolean;
  worklogsUserData: UseFetchTimeDetailsResponse[];
};

const TotalHours: React.FC<TotalHourProps> = ({
  columnSx,
  firstColWidth,
  isLoading,
  worklogsUserData,
}) => {
  const totalTime = worklogsUserData?.reduce(
    (total, curr) => total + curr.duration,
    0,
  );
  const hms = toHHMMSS(totalTime!, {
    json: true,
  }) as HHMMSSOptionsJSONRet;
  const { hours, minutes } = hms;

  return (
    <Box
      sx={{
        ...columnSx,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        align='center'
        gap={2}
        fontSize={18}
        minWidth={firstColWidth}
        fontWeight={500}
        lineHeight={1.2}
      >
        {hours}h {minutes}m
      </Typography>
    </Box>
  );
};

export default TotalHours;
