import React, {
  FC,
  ForwardedRef,
  ReactElement,
  ReactNode,
  forwardRef,
} from 'react';
import { Box, SxProps } from '@mui/material';

type Props = {
  flex?: boolean;
  children?: ReactNode | ReactElement | ReactElement[] | ReactNode[];
  sx?: SxProps;
  ref?: ForwardedRef<any>;
};

const MainContainer: FC<Props> = forwardRef(
  ({ flex = true, children, sx }, ref) => {
    return (
      <Box
        flex={1}
        ref={ref}
        sx={{
          p: 4,
          pt: 3,
          flex: 1,
          maxWidth: 1068,
          position: 'relative',
          width: flex ? 0 : null,
          ...sx,
        }}
      >
        {children}
      </Box>
    );
  },
);

export default MainContainer;
