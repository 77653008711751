import { Stack, Typography, AvatarGroup, Tooltip, Avatar } from '@mui/material';
import moment from 'moment';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';

import { WhoIsOutItemResponse } from '../../types/hr';
import { useUserProvider } from '../../providers/user/user';
import { useAppProvider } from '../../providers/app/app';
import {
  DateYYYYMMDD,
  IterableObject,
  ReactRenderElement,
} from '../../types/types';
import { UserMetadata, UserProfile } from '../../types/profile';
import { getUserInitials } from '../../utils/user';
import { parseDateWithoutTimezone } from '../../utils/date';
import { useWhoIsOutMySquad } from '../../hooks/hr';

type TimeOffWhosOutProps = PropsWithChildren & {
  title?: ReactRenderElement;
  userId?: number;
  start?: DateYYYYMMDD | null;
  end?: DateYYYYMMDD | null;
  user?: UserMetadata | UserProfile | Partial<UserMetadata>;
  exactDate?: boolean;
  isWhoElse?: boolean;
  maxAllowedRowDate?: number;
};

const TimeOffWhosOut = ({
  title = 'Who else will be out?',
  start,
  end,
  userId,
  user,
  exactDate,
  maxAllowedRowDate = Infinity,
}: TimeOffWhosOutProps) => {
  const { isDarkMode } = useAppProvider();
  const { getUser, setUniqueIdsToFetch } = useUserProvider();

  /**
   * Get who's is out also, right now, it yesterday and tomorrow of the day
   * of the request
   */
  const whoIsOutStartDate = start
    ? moment(parseDateWithoutTimezone(start).date).subtract(
        exactDate ? 0 : 1,
        'day',
      )
    : null;
  const whoIsOutEndDate = start
    ? moment(parseDateWithoutTimezone(end || start).date).add(
        exactDate ? 0 : 1,
        'day',
      )
    : null;
  const { isLoading, data: whoIsOut } = useWhoIsOutMySquad(
    {
      key: `timeoff_request_whos_out`,
      start: whoIsOutStartDate ? whoIsOutStartDate.format('YYYY-MM-DD') : '',
      end: whoIsOutEndDate ? whoIsOutEndDate.format('YYYY-MM-DD') : '',
      user_id: userId,
      limit: 100,
    },
    {
      enabled: !!userId && !!(whoIsOutStartDate || whoIsOutEndDate),
    },
  );

  const renderGroupedOtherPTORequest = (groupedPTO: {
    [key: string]: IterableObject<WhoIsOutItemResponse>;
  }) => {
    const keys = Object.keys(groupedPTO).sort();

    return keys.map((date, i) => {
      const ptos = Object.values(groupedPTO[date]);
      const momentDate = moment(date);

      return (
        <Fragment key={date}>
          <Typography
            component='div'
            fontSize={12}
            fontWeight='bold'
            sx={{
              p: 0.5,
              pl: 1.5,
              mb: 1.5,
            }}
            style={{
              color: isDarkMode ? undefined : '#1c1d1c',
              background: isDarkMode
                ? 'var(--md-ref-palette-neutral20)'
                : '#dfdfdf',
            }}
          >
            {momentDate.format('MMM D, YYYY')}
          </Typography>
          <Stack
            direction='row'
            gap={2}
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{
              mt: 1,
              mb: i !== keys.length - 1 ? 3.5 : 0,
            }}
          >
            <AvatarGroup
              max={10}
              sx={{
                '.MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  fontSize: 10,
                  fontWeight: 'bold',
                  borderColor: '#f5f5f5',
                },
              }}
            >
              {ptos.map((otherPTO, index) => {
                let user =
                  getUser('employee_id', otherPTO.staff.employee_id) ??
                  ({
                    first_name: otherPTO.staff.first_name,
                    last_name: otherPTO.staff.last_name,
                  } as Partial<UserMetadata>);

                return (
                  <Tooltip
                    key={index}
                    title={`${user.first_name || ''} ${
                      user.last_name || ''
                    }`.trim()}
                  >
                    <Avatar
                      src={user.photo_url}
                      sx={
                        isDarkMode
                          ? {
                              borderColor:
                                'var(--md-ref-palette-neutral20) !important',
                            }
                          : null
                      }
                    >
                      {
                        getUserInitials(
                          `${user.first_name || ''} ${
                            user.last_name || ''
                          }`.trim(),
                        ).initial
                      }
                    </Avatar>
                  </Tooltip>
                );
              })}
            </AvatarGroup>
          </Stack>
        </Fragment>
      );
    });
  };

  const whoIsOutReducer = (
    map: { [key: string]: IterableObject<WhoIsOutItemResponse> },
    item: WhoIsOutItemResponse,
  ) => {
    let dates: IterableObject<string | number> = item.pto.dates ?? {};

    if (!Object.keys(dates).length) {
      dates = { [item.pto.start]: item.pto.amount };
    }
    /**
     * Include the other days when a user has a range of multiple days
     */
    const insertDateToMap = (date: string) => {
      map[date] = map[date] ?? ({} as IterableObject<WhoIsOutItemResponse>);
      map[date][item.staff.employee_id] = item;
    };
    const isAllowedToInsertDate = (date: string) => {
      const datesYmd = Object.keys(map);
      // check if the date is already inserted then no worries to allow the date to be inserted
      if (map[date]) {
        return true;
      }
      return datesYmd.length < maxAllowedRowDate;
    };
    /**
     * Check if the current start date is included in the main start/end date
     * ie. start <= date <= end, and there's an amount being set
     */
    const checkDateIfToBeInserted = (date: string) => {
      const amount = +dates[date];
      const d = moment(date);

      if (
        amount &&
        d.isSameOrAfter(whoIsOutStartDate) &&
        d.isSameOrBefore(whoIsOutEndDate)
      ) {
        /**
         * Check if the date is allowed to be inserted.
         * TODO: Whether we need to show only future dates
         */
        if (isAllowedToInsertDate(date)) {
          insertDateToMap(date);
        }
      }
    };

    Object.keys(dates).forEach(checkDateIfToBeInserted);

    return map;
  };
  let filteredWhoIsOut = whoIsOut?.results ?? [];
  const allOthers = filteredWhoIsOut.reduce(whoIsOutReducer, {});
  const total = Object.values(allOthers)
    .map((p) => Object.values(p).length)
    .reduce((p, c) => p + c, 0);

  useEffect(() => {
    const employee_ids: any[] = [];
    Object.values(allOthers).forEach((others) => {
      Object.values(others).forEach((other) => {
        employee_ids.push(other.staff.employee_id);
      });
    });
    setUniqueIdsToFetch({
      employee_ids,
      user_ids: [userId],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, whoIsOut]);

  return (
    <>
      <Typography
        fontWeight={500}
        component='div'
        sx={{
          mb: 1,
        }}
      >
        {title}
      </Typography>
      {(!filteredWhoIsOut.length || !total) && (
        <Typography sx={{ opacity: 0.5 }}>
          {isLoading ? 'Please wait...' : 'None'}
        </Typography>
      )}
      {!!filteredWhoIsOut.length && (
        <>{renderGroupedOtherPTORequest(allOthers)}</>
      )}
    </>
  );
};

export default TimeOffWhosOut;
