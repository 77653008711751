import React from 'react';
import { Paper } from '@mui/material';

import WorkspaceMenuList from '../WorkspaceMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { applyOpacityOnColor } from '../../utils/color';

type Props = {};

const ChatGPTDrawer = (props: Props) => {
  const {
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: 12,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      ></Paper>
    </DrawerPermanent>
  );
};

export default ChatGPTDrawer;
