import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AutocompleteValue,
  Avatar,
  Box,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { M3Autocomplete } from '../M3/M3Autocomplete';
import { M3TextField } from '../M3/M3TextField';
import { M3OptionItem } from '../Popover/BasicPopoverWithSearch';

import { useDebounce } from '../../hooks/utils/misc';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  UserMetadata,
  UserProfile,
  UserSearchItemResponse,
} from '../../types/profile';
import {
  UseUserSearchProps,
  useCurrentProfile,
  useUserSearch,
} from '../../hooks/profile';
import {
  getUserInitials,
  getUserPhotoUrl,
  getUserProfileDisplayName,
} from '../../utils/user';
import { useUserProvider } from '../../providers/user/user';
import { sortBy } from '../../utils/array';
import { escapeRegExp } from '../../utils/string';
import { useAppProvider } from '../../providers/app/app';

type Props = {
  onSelect?: (option?: M3UserOptionItem | null) => void;
};

export type M3UserOptionItem = M3OptionItem<
  UserSearchItemResponse | UserProfile | UserMetadata
>;

const AutocompleteUserSearch = ({ onSelect }: Props) => {
  const { isDarkMode } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();
  const { setUniqueIdsToFetch } = useUserProvider();

  const [userText, setUserText] = useState('');
  const [userSelected, setUserSelected] = useState<M3UserOptionItem | null>(
    null,
  );

  const search = useDebounce(userText);
  const searchInfinite = useInfinite<
    UserSearchItemResponse,
    UseUserSearchProps
  >({
    useQuery: useUserSearch,
    isFlatResult: true,
    skipFetchOnInit: true,
    queryParams: {
      s: search || 'a',
    } as any,
  });

  const userOptions: M3UserOptionItem[] = useMemo(() => {
    let options: M3UserOptionItem[] = searchInfinite.data.map((user) => {
      return {
        id: user.id,
        label: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
        props: user,
      };
    });

    if (search) {
      const regex = new RegExp(escapeRegExp(search), 'i');
      const fullName = getUserProfileDisplayName(currentProfile).fullName;
      if (
        regex.test(fullName ?? '') ||
        regex.test(currentProfile?.email ?? '')
      ) {
        options.push({
          id: currentProfile!.id,
          label: fullName!,
          props: currentProfile,
        });
      }
    }

    options = sortBy<M3UserOptionItem>(options, 'label', true, 'ASC');

    return options;
  }, [searchInfinite.data, search, currentProfile]);

  const handleOnUserTextChange = useCallback(
    (evt: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const input = evt.currentTarget;
      setUserText(input.value);
      setUserSelected(null);
    },
    [setUserText],
  );

  const handleOnUserSelect = useCallback(
    (
      evt: SyntheticEvent,
      option: AutocompleteValue<M3UserOptionItem, any, any, any>,
    ) => {
      setUserText(((option as M3UserOptionItem) || null)?.label ?? '');
      setUserSelected((option as M3UserOptionItem) || null);
      onSelect?.((option as M3UserOptionItem) || null);
    },
    [setUserSelected, onSelect],
  );

  useEffect(() => {
    searchInfinite.reset({
      emptyList: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: searchInfinite.data.map((m) => m.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInfinite.data]);

  return (
    <Box
      position='relative'
      sx={{
        '.MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: `${
              isDarkMode
                ? 'var(--md-ref-palette-neutral40)'
                : 'var(--md-ref-palette-neutral80)'
            } !important`,
          },
      }}
    >
      {userSelected ? (
        <UserSearchSelected userId={userSelected.id as number} />
      ) : (
        <SearchOutlinedIcon
          sx={{
            top: 13,
            left: 14,
            zIndex: 2,
            opacity: 0.4,
            position: 'absolute',
          }}
        />
      )}
      <M3Autocomplete
        options={userOptions}
        loading={searchInfinite.isLoading}
        onChange={handleOnUserSelect}
        value={userText}
        clearIcon={userText ? undefined : null}
        renderInput={(params) => (
          <M3TextField
            {...params}
            label='User'
            fullWidth
            focused
            value={userText}
            placeholder='Search user...'
            onChange={handleOnUserTextChange}
          />
        )}
        renderOption={(props, option: M3UserOptionItem) => (
          <li {...props} key={option.id}>
            <UserSearchResult option={option} />
          </li>
        )}
        sx={{
          flex: 1,
          '.MuiInputBase-input': {
            paddingLeft: '38px !important',
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
        }}
      />
    </Box>
  );
};

export default AutocompleteUserSearch;

type UserSearchResultProps = {
  option: M3UserOptionItem;
};
function UserSearchResult({ option }: UserSearchResultProps) {
  const { getUser } = useUserProvider();
  const user = getUser('user_id', option.id);
  const email = option.props?.email;
  const fullName = getUserProfileDisplayName(user || option.props).fullName;

  return (
    <Stack
      gap={1}
      py={0.5}
      flexDirection='row'
      alignItems='center'
      position='relative'
    >
      <Avatar
        sx={{
          width: 25,
          height: 25,
        }}
        src={getUserPhotoUrl(user?.photo_url)}
      >
        {getUserInitials(user?.first_name).initial}
      </Avatar>
      <Box>
        <Typography
          component='div'
          fontSize={14}
          fontWeight={500}
          minWidth={70}
          lineHeight={1.2}
        >
          {fullName}
        </Typography>
        <Typography
          component='div'
          fontSize={12}
          fontWeight={500}
          lineHeight={1.2}
          sx={{
            opacity: 0.5,
          }}
        >
          {email}
        </Typography>
      </Box>
    </Stack>
  );
}

type UserSearchSelectedProps = {
  userId: number;
};
function UserSearchSelected({ userId }: UserSearchSelectedProps) {
  const { getUser } = useUserProvider();
  const user = getUser('user_id', userId);
  const fullName = getUserProfileDisplayName(user).fullName;

  return (
    <Tooltip title={fullName!}>
      <Box
        sx={{
          top: 12,
          left: 12,
          zIndex: 2,
          position: 'absolute',
        }}
      >
        <Avatar
          sx={{
            width: 25,
            height: 25,
          }}
          src={getUserPhotoUrl(user?.photo_url)}
        >
          {getUserInitials(user?.first_name).initial}
        </Avatar>
      </Box>
    </Tooltip>
  );
}
