import React, { Fragment, useState } from 'react';
import { Box, Modal, Stack, Typography } from '@mui/material';

import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';

import PromoteDemoteUserFormModalView from '../components/AdminSettings/PromoteDemoteUserForms/PromoteDemoteUserFormModal';
import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import { M3SelectGroup } from '../components/M3/M3Select';
import PathBetaForm from '../components/AdminSettings/UserProvisioningForms/PathBetaForm';
import PartnerMemberForm from '../components/AdminSettings/UserProvisioningForms/PartnerMemberForm';
import RegularForm from '../components/AdminSettings/UserProvisioningForms/RegularForm';
import { useHasViewPermission } from '../components/HasViewPermission';
import { UserProvisioningUserType } from '../types/user-provisioning';
import { M3Button } from '../components/M3/M3Button';

type SettingsAdminUserProvisioningProps = {};

const SettingsAdminUserProvisioning: React.FC<
  SettingsAdminUserProvisioningProps
> = () => {
  const hasViewPermission = useHasViewPermission({
    roles: ['developer', 'it', 'onboarding', 'superuser'],
  });

  const [isPromoteDemoteOpen, setIsPromoteDemoteOpen] =
    useState<boolean>(false);
  const [userType, setUserType] =
    useState<UserProvisioningUserType>('Path Beta User');

  const userTypeOptions = [
    'Path Beta User',
    'Partner/Member User',
    'Regular User (Guest)',
  ].map((level) => ({
    id: level,
    label: level,
  }));

  const onClose = () => {
    setIsPromoteDemoteOpen(false);
  };

  const onOpen = () => {
    setIsPromoteDemoteOpen(true);
  };

  if (!hasViewPermission) {
    return (
      <MainContainer>
        <ErrorUnauthorizedAccess />
      </MainContainer>
    );
  }

  return (
    <Fragment>
      <DocumentTitle title='User Provisioning' trailingTitle='Admin Settings' />
      <MainContainer>
        <Box sx={{ p: 2, pt: 1 }}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' flexDirection='column'>
              <Typography component='h6' variant='h6' fontWeight={500}>
                User Provisioning
              </Typography>
              <Typography fontSize={14} sx={{ mb: 2, opacity: 0.8 }}>
                Please ensure that all fields are filled out with the required
                information
              </Typography>
            </Box>
            <M3Button color='secondary' variant='outlined' onClick={onOpen}>
              Promote/Demote User
            </M3Button>
          </Stack>
          <Box>
            <M3SelectGroup
              label='User type'
              options={userTypeOptions}
              selectProps={{
                fullWidth: true,
                name: 'user_type',
                value: userType,
                onChange: (e) =>
                  setUserType(e.target.value as UserProvisioningUserType),
              }}
              formControlSx={{ width: '100%' }}
            />
          </Box>
          {userType === 'Path Beta User' && <PathBetaForm />}
          {userType === 'Partner/Member User' && <PartnerMemberForm />}
          {userType === 'Regular User (Guest)' && <RegularForm />}
        </Box>
        <br />
      </MainContainer>
      <Modal open={isPromoteDemoteOpen}>
        <PromoteDemoteUserFormModalView close={onClose} />
      </Modal>
    </Fragment>
  );
};

export default SettingsAdminUserProvisioning;
