export enum AI3ChatSocketEvent {
  CHAT = 'chat',
}

export type AI3ChatSocketResponse = {
  channel: string;
  event: AI3ChatSocketEvent;
  task_id: string;
};

export type AI3ChatSocketData = {
  status: 'in_progress' | 'done' | 'error' | 'retrying';
  message: string;
};
