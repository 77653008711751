import React, { createContext, useContext, useEffect, useState } from 'react';

import Error from '../../pages/Error';

import { useDivisions } from '../../hooks/division';
import { usePagination } from '../../hooks/global/usePagination';
import { useCurrentProfile } from '../../hooks/profile';
import { DivisionModel } from '../../types/division';
import { defaultListResult } from '../../types/response';
import { ReactRenderElement } from '../../types/types';
import { useWorkspaceProvider } from '../workspace/workspace';
import { sortBy } from '../../utils/array';

type DivisionProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type DivisionInitialState = {
  isFetching: boolean;
  items: DivisionModel[];
};
/**
 * Reducers that mutate the state
 */
type DivisionReducers = {};
/**
 * Single store
 */
type DivisionStore = DivisionInitialState & DivisionReducers;
/**
 * Initial state / store
 */
const initialStore: DivisionStore = {
  isFetching: false,
  items: [],
};
/**
 * Context Instance
 */
const DivisionContext = createContext<DivisionStore>(initialStore);

export function useDivisionProvider(): DivisionStore {
  return useContext(DivisionContext);
}

export function DivisionProvider({ children }: DivisionProviderProps) {
  const [state, setState] = useState<DivisionStore>(initialStore);
  const { current } = useWorkspaceProvider();
  const { data: userProfile } = useCurrentProfile();

  /**
   * Define all the handlers here how you want to mutate the state
   */
  const { params } = usePagination(defaultListResult);
  const { isFetching, data: divisions } = useDivisions(
    {
      ...params,
      status: 'active',
      limit: 1000,
    },
    /**
     * NOTE: Enabled the jumper for certain user's only, this should match
     * the permission on the jumper nav rail button
     */
    {
      enabled:
        !!userProfile &&
        userProfile.roles.some(
          (r) =>
            r === 'superuser' || r === 'internal' || r === 'escalation_manager',
        ),
    },
  );
  /**
   * Define all side effects here...
   */
  useEffect(() => {
    if (divisions) {
      setState((state) => ({
        ...state,
        items: sortBy(divisions.results, 'name', true, 'ASC'),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!divisions]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      isFetching,
    }));
  }, [isFetching]);

  if (!isFetching && !current) {
    return (
      <Error
        title='No workspace found'
        message="It looks like this account doesn't belong to any division. Contact your administrator."
      />
    );
  }

  /**
   * Define all side effects here...
   */
  return (
    <DivisionContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </DivisionContext.Provider>
  );
}
