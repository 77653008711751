import { FC, SVGProps, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { ReactComponent as IntercomUserGroupIcon } from '../../assets/intercom/user-group.svg';
import { ReactComponent as IntercomChatQuestionIcon } from '../../assets/intercom/chat-question.svg';
import { ReactComponent as IntercomBookBookmarkIcon } from '../../assets/intercom/book-bookmark.svg';
import {
  IntercomAdmin,
  IntercomArticle,
  IntercomCollection,
  IntercomIcon,
} from '../../types/intercom';
import { usePagination } from '../../hooks/global/usePagination';
import { defaultListResult, ListResult } from '../../types/response';
import { mutateFieldEscapeString, mutateIdToString } from '../../utils/object';
import { ListQuery } from '../../types/request';
import { QueryParams } from '../../hooks/global/useApi';
import { useIntercomProvider } from './intercom';
import { useCurrentProfile } from '../../hooks/profile';
import { escapeRegExp } from '../../utils/string';

export function getIcon(icon: IntercomIcon): FC<SVGProps<SVGSVGElement>> {
  switch (icon) {
    case 'user-group':
      return IntercomUserGroupIcon;
    case 'chat-question':
      return IntercomChatQuestionIcon;
    default:
      return IntercomBookBookmarkIcon;
  }
}

export type CollectionItemProps = {
  id: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  totalArticles: number;
  admins: IntercomAdmin[];
  collection: IntercomCollection;
};
export function getCollectionItemProps(
  collection: IntercomCollection,
  admins: IntercomAdmin[],
): CollectionItemProps {
  const Icon = getIcon(collection.icon);
  // Total articles in the collection
  const totalArticles = collection.article_count;

  // admins wrote in the collection
  const colAdmins = admins.filter(
    (admin) => collection.author_ids.indexOf(admin.id) > -1,
  );

  return {
    id: collection.id,
    Icon,
    totalArticles,
    admins: colAdmins,
    collection,
  };
}

export type ArticleItemProps = {
  id: string;
  article: IntercomArticle;
};
export function getArticleItemProps(
  article: IntercomArticle,
): ArticleItemProps {
  return {
    id: article.id,
    article,
  };
}

export type IntercomAllDataProps = {
  collections: IntercomCollection[];
  admins: IntercomAdmin[];
};
type useIntercomAllDataProps = {
  useIntercomCollectionsHook: <R = IntercomCollection>(
    params: ListQuery,
    reactQueryParams?: Partial<QueryParams>,
  ) => UseQueryResult<ListResult<R>>;
  useIntercomAdminsHook: <R = IntercomAdmin>(
    params: ListQuery,
    reactQueryParams?: Partial<QueryParams>,
  ) => UseQueryResult<ListResult<R>>;
};
type AllDataState = {
  ok: boolean;
  data: IntercomAllDataProps;
};
type AllDataRet = AllDataState & {
  clear: () => void;
};
export function useIntercomAllData({
  useIntercomCollectionsHook,
  useIntercomAdminsHook,
}: useIntercomAllDataProps): AllDataRet {
  const { data: currentProfile } = useCurrentProfile();
  const { setAllData } = useIntercomProvider();
  const [state, setState] = useState<AllDataState>({
    ok: false,
    data: {
      admins: [],
      collections: [],
    },
  });

  /**
   * Collections
   */
  const collectionPagination = usePagination(defaultListResult);
  const { data: collectionResult } = useIntercomCollectionsHook(
    {
      ...collectionPagination.params,
      page: `${collectionPagination.params.page}`,
      limit: 200,
    },
    {
      select(data: ListResult<IntercomCollection>) {
        data.results.forEach((collection) => {
          collection.type = collection.type ?? 'collection';
        });
        return data;
      },
    },
  );

  /**
   * Admins
   */
  const adminPagination = usePagination(defaultListResult);
  const { data: adminResult } = useIntercomAdminsHook({
    ...adminPagination.params,
    page: `${adminPagination.params.page}`,
    limit: 200,
  });

  const allData: AllDataRet = {
    ...state,
    clear,
  };

  function clear() {
    setState({
      ok: false,
      data: {
        admins: [],
        collections: [],
      },
    });
  }

  useEffect(() => {
    if (!collectionResult?.results?.length || !adminResult?.results?.length) {
      return;
    }

    let collectionList = collectionResult.results;
    let adminList = adminResult.results;

    mutateIdToString(collectionList, ['id']);
    mutateIdToString(adminList, ['id']);

    mutateFieldEscapeString(collectionList, ['name', 'description']);

    /**
     * Order the collection by order to have same result on support.go2.io
     * Filter the collection list only with having articles or sections
     */
    collectionList.sort((a, b) => a.order - b.order);
    collectionList = collectionList.filter(
      (collection) => !!collection.article_count,
    );

    /**
     * NOTE: Temporarily make the collection 'Career Help' to be the top for 'jobseekers'
     */
    if (currentProfile?.roles.indexOf('jobseekers')! > -1) {
      const careerHelpRegex = new RegExp(escapeRegExp('Career Help'), 'i');
      const careerHelpCollection = collectionList.find((collection) =>
        careerHelpRegex.test(collection.name),
      );
      collectionList = collectionList.filter(
        (collection) => !careerHelpRegex.test(collection.name),
      );
      if (careerHelpCollection) {
        collectionList.unshift(careerHelpCollection);
      }
    }

    setState((state) => ({
      ...state,
      ok: true,
      data: {
        admins: adminList,
        collections: collectionList,
      },
    }));
  }, [collectionResult?.results, adminResult?.results, currentProfile]);

  useEffect(() => {
    if (allData.ok) {
      setAllData(allData.data);
      allData.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData.ok]);

  return allData;
}

type UseIntercomCollectionItemsProps = {
  collections: IntercomCollection[];
  admins: IntercomAdmin[];
};
export function useIntercomCollectionItems({
  collections,
  admins,
}: UseIntercomCollectionItemsProps) {
  const collectionItems: CollectionItemProps[] = useMemo(() => {
    const items: CollectionItemProps[] = [];
    collections.map((collection) =>
      items.push(getCollectionItemProps(collection, admins)),
    );
    return items;
  }, [collections, admins]);

  return collectionItems;
}
