import React, { createContext, useContext, useState } from 'react';
import { ReactRenderElement } from '../../types/types';

type TeamProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type TeamInitialState = {
  lastDivisionIdOpened: string | number | null;
};
/**
 * Reducers that mutate the state
 */
type TeamReducers = {
  setLastDivisionIdOpened: (
    id: TeamInitialState['lastDivisionIdOpened'],
  ) => void;
};
/**
 * Single store
 */
type TeamStore = TeamInitialState & TeamReducers;
/**
 * Initial state / store
 */
const initialStore: TeamStore = {
  lastDivisionIdOpened: null,
  setLastDivisionIdOpened: (id: TeamInitialState['lastDivisionIdOpened']) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const TeamContext = createContext<TeamStore>(initialStore);

export function useTeamProvider(): TeamStore {
  return useContext(TeamContext);
}

export function TeamProvider({ children }: TeamProviderProps) {
  const [state, setState] = useState<TeamStore>(initialStore);

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function setLastDivisionIdOpened(
    id: TeamInitialState['lastDivisionIdOpened'],
  ) {
    setState((state) => ({ ...state, lastDivisionIdOpened: id }));
  }

  /**
   * Define all side effects here...
   */
  return (
    <TeamContext.Provider
      value={{
        ...state,
        setLastDivisionIdOpened,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}
