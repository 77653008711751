import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

import { NotificationItem, NotificationStatus } from '../types/notification';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { toURL } from '../utils/url';

export type UseNotificationsProps = ListQuery & {
  status: NotificationStatus;
  topic?: string;
  verb?: string;
};
export function useNotifications<R = NotificationItem>(
  params: UseNotificationsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseNotificationsProps>({
    params,
    baseIdentifier: 'useNotifications',
    identifierKeys: ['status', 'topic', 'verb'],
    queryParamKeys: ['status', 'topic', 'verb'],
  });

  return useQueryApi<UseNotificationsProps, ListResult<R>>(
    identifiers,
    '/notifications/',
    queryParams,
    {
      ...defaultReactQueryParams,
      // need to add id field for useInfinite hook item result dependency
      select(data: ListResult<NotificationItem>) {
        data.results.forEach((item) => {
          item.id = item.id ?? item.items[0].id;
          /**
           * In order for a single list to be pointing to the same notification
           * item in group, let's created a reference id using its topic and target object
           */
          item.refId = `${item.topic}_${item.items[0].verb}_${item.target_object_id}`;
        });

        return data;
      },
      ...reactQueryParams,
    },
  );
}

type UseNotificationByIdProps = {
  id: string;
};
export function useNotificationById<R = NotificationItem>(
  params: UseNotificationByIdProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseNotificationByIdProps>({
    params,
    baseIdentifier: 'useNotificationById',
    identifierKeys: ['id'],
  });

  return useQueryApi<UseNotificationByIdProps, R>(
    identifiers,
    `/notifications/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseNotificationsCountProps = Partial<UseNotificationsProps> & {
  status: NotificationStatus;
};
export function useNotificationsCount<R = NotificationItem>(
  params: UseNotificationsCountProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseNotificationsCountProps>({
      params,
      baseIdentifier: 'useNotificationsCount',
      identifierKeys: ['status', 'topic', 'verb'],
      queryParamKeys: ['status', 'topic', 'verb'],
    });

  return useQueryApi<UseNotificationsCountProps, ListResult<R>>(
    identifiers,
    `/notifications/`,
    { ...queryParams, limit: 1 },
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseMarkNotificationByIdProps = {
  id: number | string;
};
export function useMarkNotificationById(
  { id }: UseMarkNotificationByIdProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(
    `/notifications/${id}/mark-read/`,
    {},
    { method: 'PATCH', ...axiosConfig },
  );
}

type UseMarkReadAllNotificationsProps = { ids?: string };

export function useMarkReadAllNotifications(
  { ids }: UseMarkReadAllNotificationsProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(
    toURL(`/notifications/mark-read-all/`, { ids }),
    {},
    { method: 'PATCH', ...axiosConfig },
  );
}
