import { Box, CircularProgress, Stack } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect } from 'react';

import BasicForm, { BasicFormEventTarget, useForm } from '../../BasicForm';
import { M3TextField } from '../../M3/M3TextField';
import MainContainer from '../../MainContainer';
import { M3Button } from '../../M3/M3Button';
import AutocompleteUserSearch, {
  M3UserOptionItem,
} from '../../Filters/AutocompleteUserSearch';

import {
  usePatchTimeTrackerProfileByUser,
  useTimeTrackerProfileByUser,
} from '../../../hooks/time-tracker';

type Props = {};

type SidecarUserGo2DesktopFormFormState = {
  user: number | null;
  idle_threshold: number | null;
};

const SidecarUserGo2DesktopForm = (props: Props) => {
  const {
    formState,
    formStateRef,
    handleChange,
    handleSubmit,
    updateState,
    hasChanged,
    resetState,
  } = useForm<SidecarUserGo2DesktopFormFormState>({
    user: null,
    idle_threshold: null,
  });

  const { data: timeTrackerProfile } = useTimeTrackerProfileByUser(
    {
      userId: formState.user!,
    },
    {
      enabled: !!formState.user,
    },
  );
  const patchTimeTrackerProfileByUser = usePatchTimeTrackerProfileByUser({
    userId: formState.user!,
  });

  const handleOnInputChange = useCallback(
    (evt: BasicFormEventTarget | SyntheticEvent) => {
      handleChange(evt as BasicFormEventTarget | SyntheticEvent);
      patchTimeTrackerProfileByUser.reset();
    },
    // eslint-disable-next-line
    [],
  );

  const handleOnUserSelectChange = useCallback(
    (option?: M3UserOptionItem | null) => {
      handleChange({
        target: {
          name: 'user',
          value: option?.id ?? null,
        },
      });
      patchTimeTrackerProfileByUser.reset();
    },
    // eslint-disable-next-line
    [],
  );

  const onSubmit = handleSubmit((data) => {
    patchTimeTrackerProfileByUser.mutate({
      idle_threshold: data.idle_threshold ?? 900,
    });
  });

  const submitDisabled =
    patchTimeTrackerProfileByUser.isLoading ||
    !hasChanged ||
    !formState.idle_threshold;

  useEffect(() => {
    if (timeTrackerProfile) {
      updateState((state) => ({
        ...state,
        idle_threshold: timeTrackerProfile.idle_threshold,
      }));
    }
    // eslint-disable-next-line
  }, [timeTrackerProfile]);

  useEffect(() => {
    if (patchTimeTrackerProfileByUser.isSuccess) {
      resetState({
        ...formStateRef.current,
      });
    }
    // eslint-disable-next-line
  }, [formStateRef, patchTimeTrackerProfileByUser.isSuccess]);

  return (
    <>
      <MainContainer
        flex={false}
        sx={{
          p: 0,
          px: 2,
        }}
      >
        <Box>
          <BasicForm onSubmit={onSubmit}>
            <Stack direction='row' gap={2}>
              <Box flex={1}>
                <AutocompleteUserSearch onSelect={handleOnUserSelectChange} />
              </Box>
              <Box flex={1}></Box>
            </Stack>
            <br />
            <Stack direction='row' gap={2}>
              <Box flex={1}>
                <M3TextField
                  fullWidth
                  type='number'
                  label='Threshold'
                  name='idle_threshold'
                  value={formState.idle_threshold || ''}
                  onChange={handleOnInputChange}
                  helperText='Set the time in seconds when to idle starts'
                />
              </Box>
              <Box flex={1}></Box>
            </Stack>
            <br />
            <Stack direction='row'>
              <M3Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={submitDisabled}
                sx={{ minWidth: 110 }}
              >
                {patchTimeTrackerProfileByUser.isLoading ? (
                  <CircularProgress size={18} />
                ) : patchTimeTrackerProfileByUser.isSuccess ? (
                  'Saved'
                ) : (
                  'Save'
                )}
              </M3Button>
            </Stack>
          </BasicForm>
        </Box>
      </MainContainer>
    </>
  );
};

export default SidecarUserGo2DesktopForm;
