import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
} from '@mui/material';

import BasicForm, { useForm } from '../../BasicForm';
import { M3TextField } from '../../M3/M3TextField';
import { M3Button } from '../../M3/M3Button';

import {
  UsePostUserProvisionPathBetaData,
  usePostUserProvisionPathBeta,
} from '../../../hooks/user-provisioning';
import { getRawCodeMessage } from '../../../utils/response';

type FormState = UsePostUserProvisionPathBetaData;

type PathBetaFormProps = {};

const PathBetaForm: React.FC<PathBetaFormProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const postPathBetaUser = usePostUserProvisionPathBeta();
  const rawCodeMessage = getRawCodeMessage(postPathBetaUser.error);

  const { formState, hasChanged, handleChange, handleSubmit, resetState } =
    useForm<FormState>({
      email: '',
      first_name: '',
      job_title: '',
      last_name: '',
      setup_slack_account: false,
    });

  const allRequiredAreFilled = !!(
    formState.email &&
    formState.first_name &&
    formState.last_name
  );

  const onSubmit = handleSubmit((data) => {
    if (postPathBetaUser.isLoading) return;

    if (!formState.job_title) {
      delete data.job_title;
    }

    postPathBetaUser.mutate(data);
  });

  useEffect(() => {
    if (postPathBetaUser.isSuccess) {
      resetState();
      enqueueSnackbar(
        'Your submission for Path Beta User has been successful.',
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postPathBetaUser.isSuccess]);

  return (
    <BasicForm onSubmit={onSubmit}>
      <Stack gap={2} mt={2}>
        <Stack direction='row' gap={2}>
          <M3TextField
            label='First name'
            name='first_name'
            fullWidth
            value={formState.first_name}
            onChange={handleChange}
          />
          <M3TextField
            label='Last name'
            name='last_name'
            fullWidth
            value={formState.last_name}
            onChange={handleChange}
          />
        </Stack>
        <Stack direction='row' gap={2}>
          <M3TextField
            label='Email'
            name='email'
            type='email'
            fullWidth
            value={formState.email}
            onChange={handleChange}
          />
          <M3TextField
            label='Job title (optional)'
            name='job_title'
            fullWidth
            value={formState.job_title}
            onChange={handleChange}
          />
        </Stack>
        <FormControlLabel
          sx={{ width: 280 }}
          control={
            <Checkbox
              name='setup_slack_account'
              checked={formState.setup_slack_account}
              onChange={(evt) =>
                handleChange({
                  target: {
                    name: 'setup_slack_account',
                    value: evt.currentTarget.checked,
                  },
                })
              }
            />
          }
          label='Setup slack account? (optional)'
        />
        {rawCodeMessage.error && (
          <FormHelperText error>{rawCodeMessage.message}</FormHelperText>
        )}
        <Stack direction='row'>
          <M3Button
            sx={{ minWidth: 110 }}
            color='primary'
            disabled={
              postPathBetaUser.isLoading || hasChanged
                ? !allRequiredAreFilled
                : true
            }
            type='submit'
            variant='contained'
          >
            Submit
          </M3Button>
        </Stack>
      </Stack>
    </BasicForm>
  );
};

export default PathBetaForm;
