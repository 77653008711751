import qs from 'qs';

type Data = {
  [key: string]: any;
};

export function parseURL<T = Data>(
  search: string = '',
  options?: qs.IParseOptions,
): T {
  search = search.split('?').slice(1).join('');
  const data: Data = qs.parse(search, options);
  return Object.keys(data).reduce((obj: Data, key: string) => {
    try {
      obj[key] = JSON.parse(data[key]);
    } catch (e) {
      obj[key] = data[key];
    }
    return obj;
  }, {}) as T;
}

export function toURL<T = Data>(path: string = '', data: T = {} as T): string {
  const str = qs.stringify(data);
  return `${path}${str ? '?' : ''}${str}`;
}

type CleanURLProps = {
  stripLeadingDoubleSlash?: boolean;
  stripTrailingSlash?: boolean;
};
export function cleanURL(
  url: string = '',
  { stripLeadingDoubleSlash, stripTrailingSlash }: CleanURLProps = {},
) {
  url = url.replace(/([^:]\/)\/+/g, '$1');
  url = url.replace(/^\/\//, '/');
  if (stripTrailingSlash) {
    url = url.replace(/\/$/, '');
  }
  return url;
}

export function prefixUrl(
  url?: string,
  domain: string = process.env.REACT_APP_API_BASE_URL!,
): string | null {
  if (!url) return null;

  const newUrl = cleanURL(url, {
    stripLeadingDoubleSlash: true,
    stripTrailingSlash: true,
  });

  if (newUrl.search(/^http/) > -1) {
    return newUrl;
  }

  if (!domain) {
    domain = window.location.origin;
  }

  return prefixUrl(`${domain}/${newUrl}/`, domain);
}
