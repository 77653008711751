import React, { CSSProperties } from 'react';
import moment from 'moment-timezone';
import { Box } from '@mui/material';
import clsx from 'clsx';
import {
  Calendar as ReactBigCalendar,
  CalendarProps as ReactBigCalendarProps,
  momentLocalizer,
  Event,
  Formats,
  DateRange,
  EventProps,
  EventWrapperProps,
} from 'react-big-calendar';

// import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

type CalendarProps = Partial<ReactBigCalendarProps> & {
  events?: CalendarEvent[];
  style?: CSSProperties;
  noTimeSlot?: boolean;
};

export interface CalendarEvent<R = any> extends Event {
  resource?: R;
}

export interface CalendarEventPropHandler
  extends EventProps,
    EventWrapperProps {}

const _localizer = momentLocalizer(moment);
const dayRangeHeaderFormat = (range: DateRange) => {
  return `${moment(range.start).format('MMM D')} – ${moment(range.end).format(
    'MMM D',
  )}`;
};
const _formats: Formats = {
  dateFormat: 'D',
  dayHeaderFormat: 'dddd, MMM D, YYYY',
  dayFormat: 'ddd, MMM D',
  dayRangeHeaderFormat: dayRangeHeaderFormat,
  agendaHeaderFormat: dayRangeHeaderFormat,
  agendaDateFormat: 'ddd, MMM D, YYYY',
  agendaTimeFormat: 'h:mma',
};

const Calendar = ({
  events = [],
  formats = _formats,
  localizer = _localizer,
  style,
  className,
  noTimeSlot,
  ...restProps
}: CalendarProps) => {
  return (
    <Box className='react-big-calendar'>
      <ReactBigCalendar
        localizer={localizer}
        events={events}
        formats={{
          ...formats,
          ..._formats,
        }}
        style={style}
        className={clsx(
          {
            'no-time-slot-calendar': noTimeSlot,
          },
          className,
        )}
        {...restProps}
      />
    </Box>
  );
};

export default Calendar;
