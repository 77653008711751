import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import React, {
  forwardRef,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import CachedIcon from '@mui/icons-material/Cached';

import PerfectScrollbar, { PerfectScrollbarRef } from '../PerfectScrollbar';
import ChatGPTTextResponse from '../ChatGPT/ChatGPTTextResponse';
import { M3Button } from '../M3/M3Button';
import SideSheet from './SideSheet';

import './AIAssistSideSheet.css';
import { useAppProvider } from '../../providers/app/app';
import {
  DateInUTC,
  IterableObject,
  ReactRenderElement,
} from '../../types/types';
import {
  DEFAULT_GO2BOT_TEMPERATURE,
  OpenAIModelID,
  OpenAIModels,
  PromptConfigItemResponse,
  PromptConfigModel,
} from '../../types/openai';
import * as posthog from '../../services/posthog';
import {
  UsePusherChatSubRefRet,
  usePusherChatSubRef,
} from '../../hooks/utils/pusher';
import * as cosmos from '../../services/cosmos';
import {
  AI3ChatSocketData,
  AI3ChatSocketEvent,
  AI3ChatSocketResponse,
} from '../../types/socket';
import { getConfigWithAuthorization } from '../../services/base';
import { useAuthProvider } from '../../providers/auth/auth';
import * as pusher from '../../services/pusher';

type AIAssistSideSheetProps = PropsWithChildren & {
  fixed?: boolean;
  inPage?: boolean;
  headerSx?: SxProps;
  title?: ReactRenderElement;
  containerSelector?: string;
  parentSelector?: string;
  aiAssist: UseAIAssistSideSheetRet<PromptData>;
  withToolbar?: boolean;
  withSubToolbar?: boolean;
  className?: string;
  offsetTop?: number;
  onClose?: () => void;
};

type UseAIAssistSideSheetOptions = {
  stream?: boolean;
  openOnInt?: boolean;
  assistOnInit?: boolean;
  promptIdentifier?: string;
};
export type AIMessage = {
  id?: string | number;
  role: 'assistant' | 'user' | 'system' | 'intro';
  content: string;
  // contents?: (ImageContentTypeText | ImageContentTypeImage)[];
  error?: boolean;
  user?: number;
  bot?: number;
  modified?: DateInUTC;
  prompt_id?: number;
  is_prompt_generated_hidden?: boolean;
  variables_metadata?: {
    keys: string[];
    values: IterableObject;
  } | null;
  visible?: boolean;
  is_ai3_data_prompt?: boolean;
  is_ai_loading?: boolean;
  ai_error?: Error | null;
};

type PromptData = IterableObject & {
  prompt?: string;
  prompt_text?: string;
  messages?: AIMessage[];
  model?: OpenAIModelID;
};
type UseAIAssistSideSheetState<D> = {
  title: string;
  count: number;
  is_open: boolean;
  data: PromptData & D;
  prompt_identifier?: string;
};
export type UseAIAssistSideSheetRet<D = PromptData> =
  UseAIAssistSideSheetState<D> & {
    error: Error | null;
    assisting: boolean;
    content: string;
    is_open: boolean;
    width: number;
    set: (s: Partial<UseAIAssistSideSheetState<D>>) => void;
    regenerate: () => void;
    open: () => void;
    close: () => void;
    setWidth: (width: number) => void;
    reset: () => void;
    start: (params: Partial<UseAIAssistSideSheetState<D>>) => void;
    systemPromptRef: MutableRefObject<any>;
    onStreamRef: MutableRefObject<any>;
    chatRequestState: UsePusherChatSubRefRet['current']['chatRequestState'];
  };
type TabViewProps = {
  containerSelector?: AIAssistSideSheetProps['containerSelector'];
  parentSelector?: AIAssistSideSheetProps['parentSelector'];
  aiAssist: AIAssistSideSheetProps['aiAssist'];
};

type PromptStateRef = {
  promptIdentifier: string;
  isFetching: boolean;
  error: Error | null;
  promptConfig?: PromptConfigModel | null;
};

/**
 * Default configuration of the prompt
 */
export const defaultPromptConfig: PromptConfigModel & {
  prompt?: string;
  messages?: AIMessage[];
} = {
  prompt_identifier: 'default',
  prompt_text: '',
  prompt:
    'I understand that you would like to have the text you provided cleaned up, summarized, and formatted with a focus on outcomes. Please share the original text below, and I will work on creating a concise and outcome-oriented summary for you',
  model: OpenAIModelID.GPT_4_O_MINI,
  max_tokens: 900,
  temperature: 0.7,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  best_of: 1,
  stop: [],
  messages: [],
};

type StreamRefFunc =
  | undefined
  | null
  | ((
      content: string,
      done?: boolean,
      error?: boolean,
      stopped?: boolean,
    ) => void);

export function getAIRenderModel(modelId?: OpenAIModelID) {
  const models = Object.values(OpenAIModels);

  let renderModel =
    models.find(
      (model) => model.id === modelId && model.aliases.includes(modelId),
    ) ?? models[0];

  /**
   * NOTE: Hijacking temporarily openai to sonnet. Scott's request.
   * We don't have openai atm, we will return/remove this back a couple
   * of days.
   */
  if (renderModel.source === 'openai') {
    renderModel = models.find((m) => m.id.includes('sonnet')) ?? renderModel;
  }

  return renderModel;
}

export function useAIAssistSideSheet<D = PromptData>(
  {
    stream = false,
    openOnInt = false,
    assistOnInit = false,
    promptIdentifier = 'default',
  }: UseAIAssistSideSheetOptions = {} as UseAIAssistSideSheetOptions,
): UseAIAssistSideSheetRet<D> {
  const { getTokenSilently } = useAuthProvider();
  const [state, setState] = useState<UseAIAssistSideSheetState<D>>({
    title: 'Askly',
    count: 0,
    is_open: openOnInt,
    prompt_identifier: promptIdentifier ?? 'default',
    data: {} as UseAIAssistSideSheetState<D>['data'],
  });
  const stateRef = useRef(state);
  stateRef.current = state;

  const [content, setContent] = useState('');
  const [width, setWidth] = useState(292);
  const systemPromptRef = useRef<any>();
  const onStreamRef = useRef<StreamRefFunc>();

  const promptStateRef = useRef<PromptStateRef>({
    promptIdentifier: state.prompt_identifier!,
    isFetching: false,
    error: null,
    promptConfig: null,
  });

  const ai3ChatErrorHandler = useCallback(
    (error: Error | null, chatSubscribeRef: UsePusherChatSubRefRet) => {
      chatSubscribeRef.current.setChatRequestState({
        stream: false,
        isLoading: false,
        isStreaming: false,
        error,
      });
    },
    [],
  );
  const ai3ChatOnStopHandler = useCallback(
    (chatSubscribeRef: UsePusherChatSubRefRet) =>
      ai3ChatErrorHandler(null, chatSubscribeRef),
    [ai3ChatErrorHandler],
  );
  const ai3ChatSubscribeRef = usePusherChatSubRef({
    onError: ai3ChatErrorHandler,
    onStop: ai3ChatOnStopHandler,
  });

  const set = useCallback(
    (s: Partial<UseAIAssistSideSheetState<D>>) => {
      setState((state) => {
        state = {
          ...state,
          ...s,
          count: state.count + 1,
        };
        promptStateRef.current.promptIdentifier = state.prompt_identifier!;
        promptStateRef.current.isFetching = false;
        promptStateRef.current.error = null;
        return state;
      });
    },
    [promptStateRef, setState],
  );

  const getPromptConfiguration = useCallback(
    async (params: {
      prompt_identifier: string;
    }): Promise<PromptConfigItemResponse> => {
      try {
        if (
          params.prompt_identifier === 'default' ||
          !params.prompt_identifier
        ) {
          throw new Error('No prompt configuration');
        }

        let response = await cosmos.request.get(
          `/api/openai/prompt-configurations/${params.prompt_identifier}/`,
          {
            ...getConfigWithAuthorization(await getTokenSilently()),
          },
        );
        return response as unknown as PromptConfigItemResponse;
      } catch (e) {
        return defaultPromptConfig as unknown as PromptConfigItemResponse;
      }
    },
    [getTokenSilently],
  );

  const open = useCallback(() => {
    setState((state) => ({ ...state, is_open: true }));
  }, []);

  const close = useCallback(() => {
    setState((state) => ({ ...state, is_open: false }));
  }, []);

  const setSideSheetWidth = useCallback(
    (width: number) => {
      setWidth(width);
    },
    [setWidth],
  );

  const previousDataTextRef = useRef('');

  const stop = useCallback(() => {
    ai3ChatSubscribeRef.current.reset();
    ai3ChatSubscribeRef.current.setChatRequestState({
      stream: false,
      isLoading: false,
      isStreaming: false,
      error: null,
    });
    onStreamRef.current?.(previousDataTextRef.current, true, false, true);

    setContent('');
    previousDataTextRef.current = '';
  }, [ai3ChatSubscribeRef, onStreamRef, previousDataTextRef, setContent]);

  const start = useCallback(
    async (params: Partial<UseAIAssistSideSheetState<D>>) => {
      try {
        stop();

        set(params);

        const { setChatRequestState } = ai3ChatSubscribeRef.current;

        setChatRequestState({
          stream: false,
          isLoading: true,
          isStreaming: false,
          error: null,
        });

        const config = await getPromptConfiguration({
          prompt_identifier: (params.prompt_identifier ??
            state.prompt_identifier)!,
        });
        let renderModel = getAIRenderModel(
          (params.data?.model ?? config.model) as OpenAIModelID,
        );

        let response = (await cosmos.request.post(
          '/api/v2/ai3/chat/',
          {
            message:
              params.data?.prompt_text ??
              params.data?.messages ??
              state.data.messages,
            // conversation_id: chatGPTConversationId,
            platform: renderModel.source,
            model: renderModel.alias,
            system_prompt: config.prompt_text || config.prompt,
            // go2_bot_id: selectedGo2bot?.id,
            // context_data_id: ,
            // attachments: attachments,
            temperature: config.temperature ?? DEFAULT_GO2BOT_TEMPERATURE,
          },
          {
            ...getConfigWithAuthorization(await getTokenSilently()),
            cancelToken:
              ai3ChatSubscribeRef.current.getCancelTokenSource().token,
          },
        )) as AI3ChatSocketResponse;

        /**
         * Event callback when receiving the data response
         */
        let eventContentData = '';

        const chatEventCallback = async (data: AI3ChatSocketData) => {
          ai3ChatSubscribeRef.current.hasEventFired = true;
          ai3ChatSubscribeRef.current.clear();

          // TODO: What if it's error?
          if (data.status === 'error') {
            setChatRequestState({
              stream: false,
              isLoading: false,
              isStreaming: false,
              error: new Error(data.message),
            });
            onStreamRef.current?.(data.message, true, true);
            setContent(data.message);
            return;
          }

          /**
           * Concat the message coming from the event data
           */
          eventContentData += data.message || '';
          previousDataTextRef.current = eventContentData;

          setChatRequestState({
            isLoading: false,
            isStreaming: true,
            error: null,
          });
          onStreamRef.current?.(eventContentData, false);
          setContent(eventContentData);

          /**
           * Check if the streaming is already done, let's update the conversation
           */
          if (data.status === 'done') {
            setChatRequestState({
              stream: false,
              isLoading: false,
              isStreaming: false,
              error: null,
            });
            onStreamRef.current?.(eventContentData, true);
            setContent(eventContentData);

            previousDataTextRef.current = '';
          }
        };

        // Add a reference on the response of chat, with its unsubscribe method
        ai3ChatSubscribeRef.current.config = response;
        ai3ChatSubscribeRef.current.unsubscribe = pusher.subscribe(
          response.channel,
          {
            [AI3ChatSocketEvent.CHAT]: chatEventCallback,
          },
        );

        ai3ChatSubscribeRef.current.start();
      } catch (e) {
        onStreamRef.current?.((e as Error).message, true, true);
      }
    },
    [
      set,
      stop,
      state,
      getTokenSilently,
      ai3ChatSubscribeRef,
      onStreamRef,
      previousDataTextRef,
      setContent,
      getPromptConfiguration,
    ],
  );

  const regenerate = () => {
    start(state);
  };

  /**
   * Check when new count changes, then we trigger to regenerate
   */
  useEffect(() => {
    if (assistOnInit) {
      regenerate();
    }
    // eslint-disable-next-line
  }, [assistOnInit, state.count]);

  const reset = useCallback(() => {
    setState({
      title: 'Askly',
      count: 0,
      is_open: openOnInt,
      prompt_identifier: promptIdentifier ?? 'default',
      data: {} as UseAIAssistSideSheetState<D>['data'],
    });
  }, [openOnInt, promptIdentifier, setState]);

  /**
   * When it has unmounted stop any ongoing stream
   */
  useEffect(() => {
    return () => stop();
  }, [stop]);

  return {
    ...state,
    set,
    error: ai3ChatSubscribeRef.current.chatRequestState.error,
    assisting: ai3ChatSubscribeRef.current.chatRequestState.isLoading,
    content: content,
    regenerate,
    open,
    close,
    width,
    setWidth: setSideSheetWidth,
    systemPromptRef,
    onStreamRef,
    reset,
    start,
    chatRequestState: ai3ChatSubscribeRef.current.chatRequestState,
  };
}

const AIAssistSideSheet = ({
  fixed = true,
  headerSx,
  title,
  inPage,
  containerSelector = 'side-sheet-root-container',
  parentSelector = 'side-sheet-root-container',
  aiAssist,
  children,
  withToolbar = false,
  withSubToolbar = false,
  className,
  onClose,
  offsetTop,
}: AIAssistSideSheetProps) => {
  const { toolbarHeight, subToolbarHeight } = useAppProvider();

  const renderTabContent = () => {
    const tabsProps: Partial<TabViewProps> & {
      containerSelector: TabViewProps['containerSelector'];
      parentSelector: TabViewProps['parentSelector'];
      aiAssist: TabViewProps['aiAssist'];
    } = {
      containerSelector,
      parentSelector,
      aiAssist,
    };

    return <DetailsTab {...tabsProps} />;
  };

  const renderFooterContent = () => {
    return (
      <Footer>
        <M3Button
          disabled={aiAssist.assisting}
          variant='outlined'
          onClick={() => {
            posthog.capture('manifest regenerate response clicked');
            aiAssist.regenerate();
          }}
        >
          <CachedIcon />
          Regenerate response
        </M3Button>
      </Footer>
    );
  };

  return (
    <SideSheet
      fixed={fixed}
      width={aiAssist.width}
      isOpen={aiAssist.is_open}
      perfectScrollbarProps={{
        options: {
          suppressScrollX: true,
          wheelPropagation: true,
        },
      }}
      title={
        <Stack
          className='sidesheet-header-title'
          pl={2}
          pr={1}
          pt={2}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack flex={1} gap={1} direction='row' alignItems='center'>
            <Typography component='div' fontSize={14} fontWeight={500}>
              {title}
            </Typography>
          </Stack>
        </Stack>
      }
      headerSx={headerSx}
      content={renderTabContent()}
      footer={renderFooterContent()}
      onClose={onClose}
      sideSheetContentSx={
        inPage
          ? {
              top:
                8 +
                (withToolbar ? toolbarHeight : 0) +
                (withSubToolbar ? subToolbarHeight : 0) +
                (offsetTop ?? 0),
              bottom: 8,
            }
          : undefined
      }
      sx={{ height: '100%' }}
      className={className}
    >
      {children}
    </SideSheet>
  );
};

export default AIAssistSideSheet;

type DetailsTabProps = TabViewProps;
function DetailsTab({
  containerSelector,
  parentSelector,
  aiAssist,
}: DetailsTabProps) {
  const { isDarkMode } = useAppProvider();
  const fixedSideSheetContent = useFixedSideSheetContent({
    containerSelector,
    parentSelector,
  });
  const perfectScrollbarRef = useRef<PerfectScrollbarRef>(null);

  useEffect(() => {
    if (perfectScrollbarRef.current) {
      const ps = perfectScrollbarRef.current!;
      const container = ps!._container;
      // scrolled at top
      container.scrollTop = 0;
    }
  }, [perfectScrollbarRef, aiAssist.assisting]);

  return (
    <BoxContent ref={fixedSideSheetContent.ref}>
      <Box
        ref={fixedSideSheetContent.boxRef}
        style={{
          borderRadius: 4,
          ...(isDarkMode
            ? {
                background: 'var(--md-ref-palette-neutral-variant30)',
                boxShadow: '0 0 0 1px var(--md-ref-palette-neutral-variant40)',
              }
            : {
                background: 'var(--md-ref-palette-neutral-variant95)',
                boxShadow: '0 0 0 1px var(--md-ref-palette-neutral-variant98)',
              }),
        }}
      >
        <PerfectScrollbar
          onSetRef={perfectScrollbarRef}
          options={{
            suppressScrollX: true,
          }}
        >
          <Typography
            p={2}
            component='div'
            fontSize={13}
            fontWeight={400}
            style={{
              color: aiAssist.assisting
                ? isDarkMode
                  ? 'var(--md-ref-palette-neutral50)'
                  : 'var(--md-ref-palette-neutral60)'
                : aiAssist.error
                ? isDarkMode
                  ? 'var(--md-ref-palette-error80)'
                  : 'var(--md-ref-palette-error40)'
                : undefined,
            }}
          >
            <ChatGPTTextResponse
              text={
                aiAssist.assisting
                  ? 'Please wait...'
                  : aiAssist.error
                  ? aiAssist.error.message
                  : aiAssist.content
              }
            />
          </Typography>
        </PerfectScrollbar>
      </Box>
    </BoxContent>
  );
}

type FooterProps = PropsWithChildren;
function Footer({ children }: FooterProps) {
  return (
    <Box className='sidesheet-content-footer'>
      <Divider sx={{ opacity: 0.5 }} />
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ height: 54, minHeight: 54 }}
      >
        {children}
      </Box>
    </Box>
  );
}

type BoxContentProps = PropsWithChildren & {
  sx?: SxProps;
};
const BoxContent = forwardRef(({ children, sx }: BoxContentProps, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        p: 2,
        pl: 2,
        pr: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});

type UseFixedSideSheetContentProps = {
  containerSelector?: AIAssistSideSheetProps['containerSelector'];
  parentSelector?: AIAssistSideSheetProps['parentSelector'];
};
export function useFixedSideSheetContent({
  containerSelector,
  parentSelector,
}: UseFixedSideSheetContentProps) {
  // wrapper element which has a fixed height
  const wrapperRef = useRef<HTMLElement | null>(null);
  // element which we will need to set dynamically the height to response changes
  // to outer parents container height
  const boxRef = useRef<HTMLElement | null>(null);
  const [boxStyle, setBoxStyle] = useState({});

  /**
   * Calculates the fixed side sheet content box
   */
  useEffect(() => {
    // current element where the side sheet contained being rendered
    const wrapperEl = wrapperRef.current! as HTMLElement;

    // side sheet elements
    const sideSheetParentContent = wrapperEl.closest('.sidesheet-content');
    const sideSheetContentBodyEl = wrapperEl.closest('.sidesheet-content-body');
    const sideSheetHeaderEl = sideSheetParentContent?.querySelector(
      '.sidesheet-header-title',
    );
    const sideSheetFooterEl = sideSheetParentContent?.querySelector(
      '.sidesheet-content-footer',
    );

    // parent container where the containerEl possibly reside
    const parentContainerEl = parentSelector
      ? wrapperEl.closest(parentSelector)
      : null;
    // container element which have the dynamic content changes overt time
    const containerEl = containerSelector
      ? parentContainerEl?.querySelector(containerSelector) ??
        wrapperEl.closest(containerSelector)
      : null;

    const updateLayout = () => {
      const callback = () => {
        if (sideSheetContentBodyEl) {
          const gap = 8;
          const height = sideSheetContentBodyEl.clientHeight - gap * 4;
          const boxEl = boxRef.current;

          if (boxEl) {
            boxEl.style.height = `${height}px`;
          }
        }
      };
      callback();
      setTimeout(callback);
    };
    const containerResizeObserver = new ResizeObserver(updateLayout);
    const sideSheetHeaderResizeObserver = new ResizeObserver(updateLayout);
    const sideSheetFooterResizeObserver = new ResizeObserver(updateLayout);

    window.addEventListener('resize', updateLayout, false);
    containerEl && containerResizeObserver.observe(containerEl);
    sideSheetHeaderEl &&
      sideSheetHeaderResizeObserver.observe(sideSheetHeaderEl);
    sideSheetFooterEl &&
      sideSheetFooterResizeObserver.observe(sideSheetFooterEl);

    updateLayout();

    return () => {
      window.removeEventListener('resize', updateLayout, false);
      containerEl && containerResizeObserver.unobserve(containerEl);
      sideSheetHeaderEl &&
        sideSheetHeaderResizeObserver.unobserve(sideSheetHeaderEl);
      sideSheetFooterEl &&
        sideSheetFooterResizeObserver.unobserve(sideSheetFooterEl);
    };
  }, [wrapperRef, boxRef, setBoxStyle, parentSelector, containerSelector]);

  return {
    ref: wrapperRef,
    boxRef,
    style: boxStyle,
  };
}
