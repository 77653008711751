import { useEffect, useMemo, useRef, useState } from 'react';
import { MetabaseProps } from '../../types/metabase';
import { toURL } from '../../utils/url';
import { request as portalRequest } from '../../services/portal';
import { getConfigWithAuthorization } from '../../services/base';
import { useAuthProvider } from '../../providers/auth/auth';
import axios, { CancelTokenSource } from 'axios';

export type UseMetabaseProps<P> = {
  url?: string;
  key?: 'dashboard' | 'question';
  value?: number;
  params: P;
  endpoint?: string;
};
export type UseMetabaseRet<P> = {
  props: MetabaseProps<P>;
  iframe?: string;
  result: any;
};
export function useMetabaseIFrame<P>({
  endpoint,
  url = '/embed/dashboard/',
  key = 'dashboard',
  value,
  params,
}: UseMetabaseProps<P>) {
  const { getTokenSilently } = useAuthProvider();
  const [metabaseProps, setMetabaseProps] = useState<null | UseMetabaseRet<P>>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const cancelTokenSourceRef = useRef<null | CancelTokenSource>(null);

  useEffect(() => {
    let cb = async () => {
      let mbProps: MetabaseProps<P> = {
        url: url,
        data: {
          resource: { [key]: value },
          params: params,
          // 10 minute expiration
          exp: Math.round(Date.now() / 1000) + 10 * 60,
        },
      };
      try {
        // Cancel previously token source
        if (cancelTokenSourceRef.current) {
          cancelTokenSourceRef.current.cancel('cancelled');
        }
        cancelTokenSourceRef.current = axios.CancelToken.source();

        setIsLoading(true);

        let result: any = await portalRequest.post(
          toURL(`/metabase${endpoint ? endpoint : ''}`),
          mbProps,
          {
            cancelToken: cancelTokenSourceRef.current.token,
            ...getConfigWithAuthorization(await getTokenSilently()),
          },
        );

        setMetabaseProps({
          iframe: result.url,
          props: mbProps,
          result,
        });
        setIsLoading(false);
      } catch (e) {
        if ((e as Error).message !== 'cancelled') {
          setIsLoading(false);
        }
      }
    };
    // let refreshTimer = setTimeout(cb, 9 * 60 * 1000);

    cb();

    return () => {
      // refreshTimer && clearTimeout(refreshTimer);
    };
  }, [
    url,
    endpoint,
    key,
    value,
    params,
    setMetabaseProps,
    getTokenSilently,
    setIsLoading,
    cancelTokenSourceRef,
  ]);

  return useMemo(
    () => ({
      isLoading,
      ...metabaseProps,
    }),
    [isLoading, metabaseProps],
  );
}
