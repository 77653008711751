import React, { useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import MainContainer from '../components/MainContainer';
import IntercomBreadcrumbs from '../components/Intercom/IntercomBreadcrumbs';
import WindowScrollTop from '../components/WindowScrollTop';
import ContentPreviewCollections from '../components/Content/ContentPreviewCollections';

import { useAppProvider } from '../providers/app/app';
import { audienceSegments, audienceUserTypes } from '../types/audience';

type Props = {};

const ContentAssignDetail = (props: Props) => {
  const navigate = useNavigate();
  const { isDarkMode } = useAppProvider();
  const { audienceSegmentId } = useParams();

  const audienceSegment = useMemo(() => {
    return (
      audienceUserTypes.find((as) => as.id === audienceSegmentId!) ??
      audienceSegments.find((as) => as.id === audienceSegmentId!)
    );
  }, [audienceSegmentId]);

  const [tabIndex] = useState(0);

  const renderHeadline = () => {
    return (
      <>
        <Stack
          direction='row'
          gap={2}
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Box flex={1}>
            <Typography
              component='div'
              fontSize={33}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)',
              }}
            >
              {audienceSegment?.name}
            </Typography>
          </Box>
        </Stack>
      </>
    );
  };

  const renderDetailTab = () => {
    return null;
    // if (!articleData) return null;

    // return (
    //   <Typography component='div'>
    //     <div
    //       className='intercom-detail-body'
    //       dangerouslySetInnerHTML={{
    //         __html: DOMPurify.sanitize(articleData.body, {}),
    //       }}
    //     ></div>
    //   </Typography>
    // );
  };

  const renderCollectionTab = () => {
    return null;
  };

  return (
    <>
      <WindowScrollTop />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              navigate('/content/target-audience');
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box
            flex={1}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            {/* {!!articleData && (
              <M3Button
                onClick={() => {
                  window.open(articleData.url, '_blank');
                }}
              >
                <OpenInNewOutlinedIcon />
                Visit this article
              </M3Button>
            )} */}
          </Box>
        </AppActionToolbar>
        <IntercomBreadcrumbs
          items={[
            {
              id: 'all',
              label: 'Target Audience',
              path: '/content/target-audience',
            },
            {
              id: audienceSegment ? audienceSegment.id : audienceSegmentId!,
              label: audienceSegment ? audienceSegment.name : audienceSegmentId,
              path: `/content/target-audience/${audienceSegmentId}`,
            },
          ]}
        />
        <Stack direction='row' justifyContent='stretch'>
          <Box flex={1}>
            <br />
            {renderHeadline()}
            <br />
            {/* {renderTabNavigation()} */}
          </Box>
        </Stack>
        <ContentPreviewCollections />
        <br />
        {tabIndex === 0 && renderDetailTab()}
        {tabIndex === 1 && renderCollectionTab()}
      </MainContainer>
    </>
  );
};

export default ContentAssignDetail;
