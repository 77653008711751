import React, {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import moment from 'moment';

import UserAvatar from '../Schedule/ScheduleUserList/UserAvatar';
import DayColumns from '../Schedule/ScheduleUserList/DayColumns';
import ScrollArrow from '../Schedule/ScheduleUserList/ScrollArrow';
import { useAppProvider } from '../../providers/app/app';
import { useTick } from '../../hooks/utils/tick';
import {
  UseFetchTimeDetailsResponse,
  useFetchTimeDetails,
} from '../../hooks/edit-time';
import { applyOpacityOnColor } from '../../utils/color';
import { useTimezone } from '../Util/Timezone';
import { UserMetadata } from '../../types/profile';
import { IterableObject } from '../../types/types';

import '../Schedule/ScheduleUserList.css';

import TotalHours from './Timeline/TotalHours';
import TimelineContainer from './Timeline/TimelineContainer';
import TimelineColorMap from '../EditTime/components/Timeline/TimelineColorMap';
import { useWindowDimension } from '../../hooks/utils/window';

type Props = {
  range: string[];
  users?: UserMetadata[];
  teamTimeUserListDataRef: MutableRefObject<
    IterableObject<UseFetchTimeDetailsResponse[]>
  >;
};

const TeamTimeUserList: React.FC<Props> = ({
  range,
  users: userListProps,
  teamTimeUserListDataRef,
}) => {
  const {
    isDarkMode,
    palettes: { main: palette },
  } = useAppProvider();
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const arrowContainerRef = useRef<HTMLDivElement>(null);
  const arrowInnerRef = useRef<HTMLDivElement>(null);

  const [days] = useState(() =>
    [1, 2, 3, 4, 5, 6, 7].map((d) => moment().day(d)),
  );

  const timezone = useTimezone();
  const momentStartDate = moment(range[0]);
  const momentEndDate = moment(range[1]);

  useTick();
  useWindowDimension();

  const firstColWidth = 260;
  const columnSx: SxProps = {
    minHeight: 80,
    minWidth: 160,
  };

  const currentScrollRef = scrollRef.current;
  const currentScrollWrapperEl = scrollWrapperRef.current;
  const arrowContainerEl = arrowContainerRef.current;
  const arrowInnerEl = arrowInnerRef.current;
  const { innerWidth } = window;
  const isLgScreen = innerWidth > 1920;

  const { data: timeDetailsData, isLoading } = useFetchTimeDetails(
    {
      end_date: momentEndDate.clone().endOf('day').utc().format(),
      start_date: momentStartDate.clone().startOf('day').utc().format(),
      timezone,
      limit: 10000,
      users: userListProps?.map(({ id }) => id).join(','),
    },
    {
      select(data) {
        const newResults: UseFetchTimeDetailsResponse[] = [];

        data.results.forEach(({ start, end, ...timeDetails }, index) => {
          let endTimeLocal = moment.utc(end).local();
          let startTimeLocal = moment.utc(start).local();

          if (startTimeLocal.isBefore(momentStartDate.clone().startOf('day'))) {
            startTimeLocal = momentStartDate.clone().startOf('day');
          }

          if (endTimeLocal.isAfter(momentEndDate.clone().endOf('day'))) {
            endTimeLocal = momentEndDate.clone().endOf('day');
          }

          const startLocalDay = startTimeLocal.day();
          const endLocalDay = endTimeLocal!.day();

          // If the current worklog start and day
          if (startLocalDay === endLocalDay) {
            newResults.push({
              ...timeDetails,
              start,
              end,
              day: startLocalDay,
              start_time_local: startTimeLocal,
              end_time_local: endTimeLocal,
              duration: endTimeLocal.diff(startTimeLocal, 'ms') / 1000,
            });
          }
          // else, they are not the same, day covered the next day
          // ie. Tuesday (11pm) -> Wednesday (2am)
          else {
            // end day of the start time
            const endOfDayOfStartTimeLocal = startTimeLocal
              .clone()
              .endOf('day');
            // start day of the end time
            const startOfDayOfEndTimeLocal = endTimeLocal!
              .clone()
              .startOf('day');

            // An entry from start time to 12pm
            // ie. 11pm - 12pm
            newResults.push({
              ...timeDetails,
              start: startTimeLocal.clone().format(),
              end: endOfDayOfStartTimeLocal.clone().format(),
              duration: Math.abs(
                endOfDayOfStartTimeLocal.diff(startTimeLocal, 'ms') / 1000,
              ),
              day: startTimeLocal.clone().day(),
              start_time_local: startTimeLocal,
              end_time_local: endOfDayOfStartTimeLocal,
            });

            // An entry from 12am (next day) to end time
            // ie. next day - 12am - 2am
            newResults.push({
              ...timeDetails,
              start: startOfDayOfEndTimeLocal.clone().format(),
              end: endTimeLocal!.clone().format(),
              duration: Math.abs(
                endTimeLocal.diff(startOfDayOfEndTimeLocal, 'ms') / 1000,
              ),
              day: startOfDayOfEndTimeLocal.clone().day(),
              start_time_local: startOfDayOfEndTimeLocal,
              end_time_local: endTimeLocal,
            });
          }
        });

        data.results = newResults;

        return data;
      },
    },
  );

  const groupWorklogsByUser = useMemo(() => {
    let userGroup: IterableObject<UseFetchTimeDetailsResponse[]> = {};

    if (!timeDetailsData?.results.length) return {};

    timeDetailsData.results.forEach((result) => {
      if (result.user) {
        if (!userGroup[result.user]) {
          userGroup[result.user] = [];
        }

        userGroup[result.user].push(result);
      }
    });

    return userGroup;
  }, [timeDetailsData?.results]);
  /**
   * NOTE: Assigning always the current of group worklogs by user so that it will
   * assign always the current data we used to download
   */
  teamTimeUserListDataRef.current = groupWorklogsByUser;

  const handleScroll = useCallback(() => {
    if (currentScrollRef && currentScrollWrapperEl) {
      const { scrollLeft, scrollWidth, clientWidth } = currentScrollRef;
      const atRightEnd = scrollLeft + clientWidth >= scrollWidth;
      const atLeftEnd = scrollLeft === 0;

      if (atRightEnd || isLgScreen) {
        currentScrollWrapperEl.classList.remove('after-active');
      } else {
        currentScrollWrapperEl.classList.add('after-active');
      }

      if (atLeftEnd || isLgScreen) {
        currentScrollWrapperEl.classList.remove('before-active');
      } else {
        currentScrollWrapperEl.classList.add('before-active');
      }
    }
  }, [currentScrollRef, currentScrollWrapperEl, isLgScreen]);

  const handleClickScroll = (direction: 'left' | 'right') => {
    if (currentScrollRef) {
      const offsetLeft = currentScrollRef.clientWidth * 0.5;
      if (direction === 'left') {
        currentScrollRef.scrollLeft -= offsetLeft;
      } else {
        currentScrollRef.scrollLeft += offsetLeft;
      }
    }
  };

  useEffect(() => {
    const windowScrollHandler = () => {
      if (arrowContainerEl && arrowInnerEl) {
        const windowPositionY = window.scrollY * 0.98;

        arrowInnerEl.style.top = `${windowPositionY}px`;
      }
    };

    if (currentScrollRef && currentScrollWrapperEl) {
      currentScrollRef.addEventListener('scroll', handleScroll);
      currentScrollRef.addEventListener('resize', handleScroll);

      window.addEventListener('resize', handleScroll, false);
      window.addEventListener('scroll', windowScrollHandler, false);

      if (isLgScreen) {
        currentScrollWrapperEl.classList.remove(
          'before-active',
          'after-active',
        );
      }

      handleScroll();
      windowScrollHandler();
    }

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
        currentScrollRef.removeEventListener('resize', handleScroll);

        window.removeEventListener('scroll', windowScrollHandler, false);
        window.removeEventListener('resize', windowScrollHandler, false);
      }
    };
  }, [
    currentScrollRef,
    currentScrollWrapperEl,
    arrowContainerEl,
    arrowInnerEl,
    handleScroll,
    isLgScreen,
    groupWorklogsByUser,
  ]);

  return (
    <Box>
      <TimelineColorMap data={timeDetailsData?.results} />
      <Box display='flex' width='100%' position='relative'>
        <Stack direction='column' mt={0}>
          <Box minHeight={30} />
          <Divider />
          {!!userListProps?.length && (
            <>
              {userListProps?.map((user, i) => {
                return (
                  <Fragment key={i}>
                    {i !== 0 && <Divider />}
                    <UserAvatar firstColWidth={firstColWidth} user={user} />
                  </Fragment>
                );
              })}
              <Divider />
            </>
          )}
          {!userListProps?.length && (
            <Box width={firstColWidth} minWidth={firstColWidth} />
          )}
        </Stack>
        <Box
          ref={scrollWrapperRef}
          flex={1}
          width={0}
          className='schedule-days-list-container'
        >
          <div
            className='schedule-days-list-container-before'
            style={{
              background: `linear-gradient(
                to right,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  1,
                )} 0%,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  0,
                )} 100%
              )`,
            }}
          ></div>
          <Box
            ref={scrollRef}
            sx={
              !isLgScreen
                ? {
                    overflow: 'scroll',
                    scrollBehavior: 'smooth',
                    width: isLgScreen ? '100%' : 'auto',
                    '&::-webkit-scrollbar': {
                      outline: 'none',
                      height: 0,
                      width: 0,
                    },
                  }
                : null
            }
          >
            <Box width={isLgScreen ? '100%' : 'max-content'}>
              <Stack direction='column'>
                <DayColumns columnSx={columnSx} days={days} range={range} />
                <Divider />
                {userListProps?.map((user, i) => {
                  const worklogsUserData = groupWorklogsByUser[user.id] ?? [];

                  return (
                    <Fragment key={i}>
                      <TimelineContainer
                        days={days}
                        columnSx={columnSx}
                        isLoading={isLoading}
                        worklogsUserData={worklogsUserData}
                      />
                      <Divider />
                    </Fragment>
                  );
                })}
              </Stack>
            </Box>
          </Box>
          <div
            className='schedule-days-list-container-after'
            style={{
              background: `linear-gradient(
                to right,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  0,
                )} 0%,
                ${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.sys.color.background.dark']
                    : palette['md.sys.color.background.light'],
                  1,
                )} 100%
              )`,
            }}
          ></div>
          <Box
            ref={arrowContainerRef}
            top={0}
            left={0}
            right={0}
            position='absolute'
          >
            <Box
              ref={arrowInnerRef}
              px={1}
              top={0}
              left={0}
              right={0}
              zIndex={2}
              display='flex'
              position='absolute'
              alignItems='center'
              justifyContent='space-between'
              sx={{
                minHeight: `110px`,
                pointerEvents: 'none',
              }}
            >
              <ScrollArrow
                direction='left'
                isLgScreen={isLgScreen}
                onClick={() => handleClickScroll('left')}
              />
              <ScrollArrow
                direction='right'
                isLgScreen={isLgScreen}
                onClick={() => handleClickScroll('right')}
              />
            </Box>
          </Box>
        </Box>
        <Stack direction='column' mt={0} width='15%'>
          <Box minHeight={30}>
            <Typography align='center' fontWeight={500}>
              Total Hours
            </Typography>
          </Box>
          <Divider />
          {userListProps?.map((user, i) => {
            const worklogsUserData = groupWorklogsByUser[user.id] ?? [];

            return (
              <Fragment key={i}>
                {i !== 0 && <Divider />}
                <TotalHours
                  columnSx={columnSx}
                  firstColWidth={firstColWidth}
                  isLoading={isLoading}
                  worklogsUserData={worklogsUserData}
                />
              </Fragment>
            );
          })}
          {!!userListProps?.length && <Divider />}
        </Stack>
        {!!userListProps?.length && <Divider />}
      </Box>
    </Box>
  );
};

export default TeamTimeUserList;
