import React from 'react';

import SettingsTeamAllFocus from './SettingsTeamAllFocus';
import SettingsTeamFocusDetail from './SettingsTeamFocusDetail';

import { usePathnameSegments } from '../hooks/utils/url';

type TeamFocusProps = {};

export type TeamFocusSegments = 'create';

const TeamFocus = (props: TeamFocusProps) => {
  const [, , , thirdSegment] = usePathnameSegments<TeamFocusSegments[]>();

  if (!!+thirdSegment) {
    return <SettingsTeamFocusDetail routeKey='team' editable={false} />;
  }

  return <SettingsTeamAllFocus routeKey='team' editable={false} />;
};

export default TeamFocus;
