import {
  Box,
  Checkbox,
  Divider,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import React, {
  SyntheticEvent,
  forwardRef,
  useMemo,
  KeyboardEvent,
  useState,
  useEffect,
} from 'react';
import moment from 'moment';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

import FormQuestionFieldType from './FormQuestionFieldType';
import ModalCardView, { ModalCardViewCloseProps } from '../../ModalCardView';
import { M3Button, M3IconButton } from '../../M3/M3Button';
import { useForm } from '../../BasicForm';
import { M3TextField } from '../../M3/M3TextField';
import { M3Autocomplete } from '../../M3/M3Autocomplete';
import { M3OptionItem } from '../../Popover/BasicPopoverWithSearch';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';

import { useAppProvider } from '../../../providers/app/app';
import { EODFieldParams, EODFieldType } from '../../../types/report';
import { IterableObject, ReactRenderElement } from '../../../types/types';
import { useFormRequestStatus } from '../../../hooks/global/useFormRequestStatus';
import { request as cosmosRequest } from '../../../services/cosmos';
import { useAuthProvider } from '../../../providers/auth/auth';
import { getConfigWithAuthorization } from '../../../services/base';
import { EODFormItem } from '../../../types/task';
import { transformEODFieldParams } from '../../../utils/task';
import { FocusEODFormPayload } from '../../../hooks/eod-forms';
import { TimeTrackerTaskItemDetail } from '../../../types/time-tracker';

type Props = ModalCardViewCloseProps & {
  taskFocusData?: TimeTrackerTaskItemDetail;
  eodFormId?: number;
  initialData?: Partial<EODFieldParams>;
  questions?: EODFieldParams[];
  onSave?: (data: EODFieldParams, questions: EODFieldParams[]) => void;
};

type FormState = Partial<EODFieldParams> & {
  type_text: string;
  tools_text: string;
  tools_selected_text: string;
  select_text: string;
};

export const EOD_TOOLS_OPTIONS = [
  'Zendesk',
  'Gorgias',
  'FreshDesk',
  'Hubspot',
  'HelpScout',
  'Zoho Desk',
  'Kayako',
  'Intercom',
  'Kustomer',
  'Front App',
  'Jobber',
  'Salesforce',
  'Others',
];

const DISPLAY_NAME_CHAR_LIMIT = 19;

export function FocusQuestionFormModalViewBase({
  initialData,
  close,
  onSave,
  questions = [],
  eodFormId,
  taskFocusData,
}: Props) {
  const { isDarkMode } = useAppProvider();
  const { getTokenSilently } = useAuthProvider();

  const unsetParentConfirmationDialog = useConfirmationDialog();
  const saveWithPreviouslyParentConfirmationDialog = useConfirmationDialog();

  const { formState, handleChange, updateState, hasChanged } =
    useForm<FormState>(() => {
      const state: FormState = {
        id: initialData?.id ?? uuid(),
        question_type: initialData?.question_type ?? 'qualitative',
        type: initialData?.type ?? 'textarea',
        label: initialData?.label ?? '',
        caption: initialData?.caption ?? '',
        min: initialData?.min ?? null,
        max: initialData?.max ?? null,
        required: initialData?.required ?? true,
        select_options: initialData?.select_options ?? [],
        is_parent: initialData?.is_parent ?? null,
        is_there_a_parent: initialData?.is_there_a_parent ?? null,
        tools: initialData?.tools ?? [],
        priority: initialData?.priority ?? 0,
        disable_paste: initialData?.disable_paste ?? false,
        help_text: initialData?.help_text ?? '',
        // local state
        type_text: '',
        tools_text: '',
        tools_selected_text: initialData?.tools?.[0] ?? '',
        select_text: '',
      };

      return state;
    });
  const [isParentBeforeAndGetsRemoved, setIsParentBeforeAndGetsRemoved] =
    useState(false);
  const [
    isParentQuantitativeBeforeNowQualitative,
    setIsParentQuantitativeBeforeNowQualitative,
  ] = useState(false);
  const [
    isChildQuantitativeBeforeNowQualitative,
    setIsChildQuantitativeBeforeNowQualitative,
  ] = useState(false);

  const fieldTypeOptions = useMemo(() => {
    const opts: (M3OptionItem & { id: EODFieldType })[] = [
      {
        id: 'textarea',
        label: 'Text',
      },
      {
        id: 'select',
        label: 'Multiple Choice',
      },
    ];

    return opts;
  }, []);

  /*
  const toolsOptions = useMemo(() => {
    const opts: M3OptionItem[] = EOD_TOOLS_OPTIONS.map((tool) => ({
      id: tool,
      label: tool,
    }));

    return opts;
  }, []);
  */

  const [formRequestStatus, setFormRequestStatus] = useFormRequestStatus();

  /**
   * Caching questions so we can alter if there's a change for this current
   * question. ie. Removing parent
   */
  const [updatedQuestions, setUpdatedQuestions] = useState(questions);
  /**
   * Find the quantitative parent id
   */
  const quantitativeParentId =
    updatedQuestions.find(
      (q) => q.question_type === 'quantitative' && q.is_parent,
    )?.id ?? (formState.is_parent ? formState.id : null);
  /**
   * Find the quantitative parent question
   */
  const quantitativeParent =
    updatedQuestions.find((q) => q.id === quantitativeParentId) ??
    (formState.is_parent ? formState : null);

  const isCurrentQuestionParent = !!(
    quantitativeParentId && quantitativeParentId === formState.id
  );
  const isAChildQuestion = !!(
    quantitativeParentId && quantitativeParentId !== formState.id
  );

  /**
   * Get all the quantitative children that's not of current question being opened
   */
  const childrenQuantitativeQuestions = updatedQuestions.filter(
    (q) =>
      q.question_type === 'quantitative' &&
      !q.is_parent &&
      q.is_there_a_parent &&
      q.id !== formState.id,
  );
  // check the current question is a child set
  if (!formState.is_parent && formState.is_there_a_parent) {
    childrenQuantitativeQuestions.push({ ...formState } as EODFieldParams);
  }

  const handleOnKeyDownSelectOption = (
    evt: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (evt.key === 'Enter' && formState.select_text) {
      updateState((state) => ({
        ...state,
        select_text: '',
        select_options: [...(state.select_options ?? []), state.select_text],
      }));
    }
  };

  const onAutocompleteChangeHandler =
    (name: string) => (evt: SyntheticEvent, option: any) => {
      handleChange({
        target: {
          name,
          value: option ? (option as M3OptionItem).id : null,
        },
      });
    };

  const getPayloadData = () => {
    const fields: (keyof EODFieldParams)[] = [
      'id',
      'type',
      'label',
      'caption',
      'priority',
      'min',
      'max',
      'required',
      'disable_paste',
      'select_options',
      'help_text',
      'tools',
      'question_type',
      'is_parent',
      'is_there_a_parent',
    ];
    const payload = {
      ...initialData,
      ...fields
        .filter((key) => key in formState)
        .reduce((prev, key) => {
          prev[key] = formState[key];
          return prev;
        }, {} as IterableObject),
      modified: moment.utc().format(),
    } as EODFieldParams;
    return transformEODFieldParams(payload);
  };

  const handleOnSave = async () => {
    const payload = getPayloadData();
    let dataUpdatedPayload =
      isParentQuantitativeBeforeNowQualitative ||
      isChildQuantitativeBeforeNowQualitative
        ? (setParentQuantitativeNoParent(
            payload as FormState,
          ) as EODFieldParams)
        : payload;
    let dataUpdatedQuestions = isParentQuantitativeBeforeNowQualitative
      ? setUpdatedQuestionsToQuantitativeNoParent(updatedQuestions)
      : updatedQuestions;

    /**
     * Updating existing question which having an eod already
     */
    if (eodFormId || taskFocusData) {
      try {
        let eodFormData: EODFormItem | null = null;

        setFormRequestStatus({
          status: 'processing',
          error: null,
        });

        /**
         * Check if there's no eod form, this is for a task/focus built
         * previously with old form where it doesn't have an attached EOD yet
         */
        if (!eodFormId) {
          const eodFormPayload: Partial<FocusEODFormPayload> = {
            fields: [dataUpdatedPayload],
            tasks: [taskFocusData!.id],
            name: taskFocusData!.name,
          };

          eodFormData = await cosmosRequest.post(
            `/api/forms/eod-forms/`,
            eodFormPayload,
            getConfigWithAuthorization(await getTokenSilently()),
          );

          onSave?.(dataUpdatedPayload, eodFormPayload.fields!);
          close?.();
        }
        // Adding a question from a existing focus/task and existing eod form
        else {
          eodFormData = await cosmosRequest.get(
            `/api/forms/eod-forms/${eodFormId}/`,
            getConfigWithAuthorization(await getTokenSilently()),
          );
          const fields = [...eodFormData!.fields];

          /**
           * Let's mapped the eod questions coming from the server and match up
           * the changes we need to make when something
           */
          if (
            isParentBeforeAndGetsRemoved ||
            isParentQuantitativeBeforeNowQualitative
          ) {
            const fieldsToCheck: (keyof EODFieldParams)[] = [
              'is_parent',
              'is_there_a_parent',
            ];
            fields.forEach((q) => {
              const uq = dataUpdatedQuestions.find((uq) => uq.id === q.id);
              if (uq) {
                const updatedField = {
                  ...fieldsToCheck
                    .filter((key) => key in uq)
                    .reduce((prev, key) => {
                      prev[key] = uq[key];
                      return prev;
                    }, {} as IterableObject),
                  modified: moment.utc().format(),
                } as EODFieldParams;
                Object.assign(q, updatedField);
              }
            });
          }

          const index = fields.findIndex((f) => f.id === dataUpdatedPayload.id);
          if (index === -1) {
            fields.push(dataUpdatedPayload);
          } else {
            fields[index] = { ...fields[index], ...dataUpdatedPayload };
          }

          await cosmosRequest.patch(
            `/api/forms/eod-forms/${eodFormId}/`,
            { fields },
            getConfigWithAuthorization(await getTokenSilently()),
          );
          onSave?.(dataUpdatedPayload, fields);
          close?.();
        }
      } catch (e) {
        setFormRequestStatus({
          status: 'error',
          error: e as Error,
        });
      }
    }
    // No EOD form associated, so nothing can be updated to the server
    // This is mostly used for creating new focus
    else {
      onSave?.(dataUpdatedPayload, dataUpdatedQuestions);
      close?.();
    }
  };

  /**
   * Sets the current question to a parent
   */
  const handleOnSetParent = () => {
    updateState((state) => ({
      ...state,
      is_parent: true,
      is_there_a_parent: true,
    }));
  };

  /**
   * Unsetting a parent prompts the user if they want to continue to remove
   * current question as a parent
   */
  const handleOnSetUnsetParent = () => {
    if (formState.is_parent) {
      unsetParentConfirmationDialog.confirm.setIsOpen(true);
    } else {
      handleOnConfirmUnsetAsParent();
    }
  };

  /**
   * Handle to update the current question opened, and all other quantitative
   * children they are attached to
   */
  const handleOnConfirmUnsetAsParent = () => {
    updateState(setParentQuantitativeNoParent);
    setUpdatedQuestions(setUpdatedQuestionsToQuantitativeNoParent);
    setIsParentBeforeAndGetsRemoved(true);
    unsetParentConfirmationDialog.confirm.setIsOpen(false);
    saveWithPreviouslyParentConfirmationDialog.confirm.setIsOpen(false);
  };

  const setUpdatedQuestionsToQuantitativeNoParent = (
    questions: EODFieldParams[],
  ) =>
    questions.map((q) => ({
      ...q,
      ...(q.question_type === 'quantitative'
        ? {
            is_parent: false,
            is_there_a_parent: false,
          }
        : null),
    }));

  const setParentQuantitativeNoParent = (state: FormState) => ({
    ...state,
    is_parent: false,
    is_there_a_parent: false,
  });

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <QuestionAnswerOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Box display='flex' flexWrap='nowrap'>
            <Typography
              display='flex'
              alignItems='center'
              flexWrap='nowrap'
              fontSize={20}
              fontWeight={500}
              component='div'
            >
              <span className='text-truncate' style={{ maxWidth: 420 }}>
                Question
              </span>
            </Typography>
          </Box>
        </Typography>
        {/* <Typography
          component='div'
          sx={{
            right: 22,
            bottom: 18,
            fontSize: 12,
            fontWeight: 500,
            position: 'absolute',
          }}
        >
          <span
            style={{
              opacity: 0.5,
              fontWeight: 400,
            }}
          >
            Date:{' '}
          </span>
          {moment(date).format('MMM D, YYYY')}
        </Typography> */}
      </Stack>
    );
  };

  const renderQuantitativeQualitySelection = () => {
    return (
      <Stack direction='row' gap={2} alignItems='center'>
        <Box flex={1}>
          <Typography
            component='div'
            fontSize={14}
            pb={2}
            sx={{ opacity: 0.8 }}
          >
            Choose what type of question:
          </Typography>
          <Stack flex={1} direction='row' gap={2}>
            <M3Button
              disableRipple
              active={formState.question_type === 'qualitative'}
              onClick={() => {
                if (
                  (initialData?.question_type === 'quantitative' &&
                    initialData?.is_parent) ||
                  formState.is_parent ||
                  (initialData?.question_type === 'quantitative' &&
                    initialData?.is_there_a_parent) ||
                  formState.is_there_a_parent
                ) {
                  saveWithPreviouslyParentConfirmationDialog.confirm.setIsOpen(
                    true,
                  );
                }
                updateState((state) => ({
                  ...state,
                  question_type: 'qualitative',
                  type: 'textarea',
                }));
              }}
            >
              <QuestionAnswerOutlinedIcon />
              Qualitative
            </M3Button>
            <M3Button
              disableRipple
              active={formState.question_type === 'quantitative'}
              onClick={() => {
                setIsParentQuantitativeBeforeNowQualitative(false);
                updateState((state) => ({
                  ...state,
                  question_type: 'quantitative',
                  type: 'number',
                }));
              }}
            >
              <NumbersOutlinedIcon />
              Quantitative
            </M3Button>
          </Stack>
        </Box>
        {formState.question_type === 'qualitative' &&
          renderQualitativeDescriptions()}
        {formState.question_type === 'quantitative' &&
          renderQuantitativeParentChildButtonContent()}
      </Stack>
    );
  };

  const renderPreview = () => {
    return (
      <Box mt={-5} minWidth={300} flex={1}>
        <Typography component='div' fontWeight={500} fontSize={18} pb={1}>
          Preview
        </Typography>
        <Divider />
        <br />
        <Box mt={2}>
          {!!formState.type && (
            <Box
              sx={{
                maxWidth: formState.type === 'datepicker' ? 400 : undefined,
              }}
            >
              <FormQuestionFieldType
                item={
                  {
                    id: uuid(),
                    ...formState,
                    label: formState.label || 'Display Name',
                    caption: formState.caption || 'Instructions',
                  } as EODFieldParams
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderMakeRequiredField = () => {
    return (
      <Typography
        component='div'
        alignItems='center'
        display='flex'
        fontSize={14}
        ml={-1}
        mb={1}
        mt={-6}
      >
        <Checkbox
          size='small'
          checked={formState.required}
          onChange={(evt) => {
            handleChange({
              target: {
                name: 'required',
                value: evt.currentTarget.checked,
              },
            });
          }}
        />
        Make this field required
      </Typography>
    );
  };

  const renderMinMaxFields = () => {
    return (
      <Stack direction='row' gap={2}>
        <M3TextField
          fullWidth
          stripAllAutoProps
          name='min'
          type='number'
          label='Minimum characters'
          value={formState.min}
          onChange={handleChange}
          sx={textFieldSx}
        />
        {/* <M3TextField
          fullWidth
          stripAllAutoProps
          name='max'
          type='number'
          label='Maximum characters'
          value={formState.max}
          onChange={handleChange}
          sx={textFieldSx}
        /> */}
      </Stack>
    );
  };

  const renderSelectedItem = ({
    key,
    title,
    subtitle,
    onClick,
  }: {
    key: any;
    title?: ReactRenderElement;
    subtitle?: ReactRenderElement;
    onClick?: () => void;
  }): ReactRenderElement => {
    return (
      <Box
        pr={0.8}
        py={0.2}
        pl={1}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        key={key}
      >
        <Box flex={1}>
          <Typography component='div'>{title}</Typography>
          {!!subtitle && <Typography component='div'>{subtitle}</Typography>}
        </Box>
        {!!onClick && (
          <M3IconButton
            sx={{
              width: 30,
              height: 30,
            }}
            onClick={onClick}
          >
            <CloseOutlinedIcon
              style={{
                fontSize: 18,
              }}
            />
          </M3IconButton>
        )}
      </Box>
    );
  };

  const renderSelectOptions = () => {
    return (
      <Box sx={textFieldSx}>
        <Typography component='div' pb={1} fontSize={14}>
          Add options
        </Typography>
        <Stack position='relative'>
          <M3TextField
            fullWidth
            stripAllAutoProps
            name='select_text'
            placeholder='Press "Enter" to add the option...'
            value={formState.select_text}
            onChange={handleChange}
            InputProps={{
              sx: {
                paddingRight: 5,
              },
            }}
            onKeyDown={handleOnKeyDownSelectOption}
          />
          <M3IconButton
            sx={{
              top: 0,
              width: 30,
              height: 30,
              right: 8,
              bottom: 0,
              margin: 'auto',
              position: 'absolute',
            }}
            disabled={!formState.select_text}
          >
            <AddOutlinedIcon />
          </M3IconButton>
        </Stack>
        <Box pt={1}>
          {(formState.select_options ?? []).map((opt, index) => {
            return renderSelectedItem({
              key: index,
              title: opt,
              onClick: () =>
                updateState((state) => ({
                  ...state,
                  select_options: [...(state.select_options ?? [])].filter(
                    (_, i) => i !== index,
                  ),
                })),
            });
          })}
        </Box>
      </Box>
    );
  };

  const renderDisplayName = () => {
    return (
      <Box position='relative'>
        <M3TextField
          fullWidth
          stripAllAutoProps
          name='label'
          label='Display Name*'
          placeholder='Will be displayed in menu and reports'
          value={formState.label}
          onChange={(evt: SyntheticEvent) => {
            const value = (evt.currentTarget as HTMLInputElement).value
              .split('')
              .slice(0, DISPLAY_NAME_CHAR_LIMIT)
              .join('');
            handleChange({
              target: {
                name: 'label',
                value,
              },
            });
          }}
          sx={textFieldSx}
        />
        <Typography
          component='div'
          fontSize={13}
          position='absolute'
          top={0}
          right={15}
          bottom={12}
          lineHeight={1}
          fontWeight={500}
          display='flex'
          alignItems='center'
          justifyContent='center'
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-error80)'
              : 'var(--md-ref-palette-error40)',
          }}
        >
          {DISPLAY_NAME_CHAR_LIMIT - (formState.label?.length ?? 0)}
        </Typography>
      </Box>
    );
  };

  const textFieldSx: SxProps = {
    marginBottom: 2,
  };
  const renderQualitativeForm = () => {
    return (
      <Box>
        {renderMakeRequiredField()}
        {renderDisplayName()}
        <M3TextField
          fullWidth
          stripAllAutoProps
          name='caption'
          label='Instructions*'
          placeholder='Explicating prompt user on how to fill in question'
          multiline
          minRows={3}
          value={formState.caption}
          onChange={handleChange}
          sx={textFieldSx}
        />
        <Box sx={textFieldSx}>
          <M3Autocomplete
            options={fieldTypeOptions}
            value={
              fieldTypeOptions.find((opt) => opt.id === formState.type) ?? null
            }
            clearIcon={null}
            getOptionLabel={(option: any) => option.label}
            onChange={onAutocompleteChangeHandler('type')}
            renderInput={(params) => (
              <M3TextField
                {...params}
                name='type_text'
                label='Type*'
                fullWidth
                value={formState.type_text}
                onChange={handleChange}
              />
            )}
          />
        </Box>
        {['input', 'number', 'textarea', 'richtext'].includes(
          formState.type ?? '',
        ) && renderMinMaxFields()}
        {['select'].includes(formState.type ?? '') && renderSelectOptions()}
      </Box>
    );
  };

  const renderQuantitativeForm = () => {
    return (
      <Box>
        {renderMakeRequiredField()}
        {renderDisplayName()}
        <M3TextField
          fullWidth
          stripAllAutoProps
          name='caption'
          label='Instructions*'
          placeholder='Explicating prompt user on how to fill in question'
          multiline
          minRows={3}
          value={formState.caption}
          onChange={handleChange}
          sx={textFieldSx}
        />
        <Stack direction='row' gap={2}>
          <M3TextField
            fullWidth
            stripAllAutoProps
            name='min'
            type='number'
            label='Minimum value'
            value={formState.min}
            onChange={handleChange}
            sx={textFieldSx}
          />
          <M3TextField
            fullWidth
            stripAllAutoProps
            name='max'
            type='number'
            label='Maximum value'
            value={formState.max}
            onChange={handleChange}
            sx={textFieldSx}
          />
        </Stack>
        {/* {renderTools()} */}
      </Box>
    );
  };

  /*
  const renderTools = () => {
    return (
      <Box sx={textFieldSx}>
        <M3Autocomplete
          options={toolsOptions}
          value={
            toolsOptions.find(
              (opt) => opt.id === formState.tools_selected_text!,
            ) ?? null
          }
          clearIcon={null}
          getOptionLabel={(option: any) => option.label}
          onChange={onAutocompleteChangeHandler('tools_selected_text')}
          renderInput={(params) => (
            <M3TextField
              {...params}
              name='tools_text'
              label='Tool(s)'
              fullWidth
              value={formState.tools_text}
              onChange={handleChange}
            />
          )}
        />
      </Box>
    );
  };
  */

  const renderBottomPanel = () => {
    const disableSubmit =
      formRequestStatus.status === 'processing' ||
      (hasChanged && !(formState.type && formState.label && formState.caption));

    return (
      <Stack
        direction='row'
        sx={{ p: 2, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 90,
          }}
          disabled={disableSubmit}
          onClick={handleOnSave}
        >
          Save
        </M3Button>
      </Stack>
    );
  };

  const renderSetAsParentButton = () => {
    return (
      formState.question_type === 'quantitative' && (
        <M3Button
          active={!!formState.is_parent}
          variant='outlined'
          color='secondary'
          onClick={
            formState.is_parent ? handleOnSetUnsetParent : handleOnSetParent
          }
        >
          {formState.is_parent && <DoneOutlinedIcon />}
          {formState.is_parent ? 'Remove as parent' : 'Set as parent'}
        </M3Button>
      )
    );
  };

  const renderSetAsChildButton = () => {
    return (
      formState.question_type === 'quantitative' && (
        <M3Button
          active={!!formState.is_there_a_parent}
          variant='outlined'
          color='secondary'
          onClick={() => {
            updateState((state) => ({
              ...state,
              is_parent: false,
              is_there_a_parent: !state.is_there_a_parent,
            }));
          }}
        >
          {formState.is_there_a_parent && <DoneOutlinedIcon />}
          {formState.is_there_a_parent ? 'Remove as child' : 'Set as child'}
        </M3Button>
      )
    );
  };

  const renderCurrentParentQuantitative = () => {
    if (
      !quantitativeParent ||
      (isCurrentQuestionParent && !formState.is_parent)
    ) {
      return null;
    }

    return (
      <Box mt={0}>
        <Typography
          component='div'
          fontSize={13}
          sx={{
            opacity: 0.5,
          }}
        >
          Current Parent:
        </Typography>
        <Typography component='div' fontSize={20} fontWeight={500}>
          {quantitativeParent.label}
        </Typography>
      </Box>
    );
  };

  const renderQuantitativeChildren = () => {
    return (
      <Box sx={{ pl: 0.5, pt: 0.5 }}>
        {childrenQuantitativeQuestions.map((q) => {
          return renderSelectedItem({
            key: q.id,
            title: (
              <Typography component='div' fontSize={14}>
                <span style={{ opacity: 0.5, marginRight: 8 }}>–</span>
                {q.label}
              </Typography>
            ),
            // onClick: () => {
            //   // setSelectedChildQuestions((state) =>
            //   //   [...state].filter((q) => q.id !== q.id),
            //   // );
            // },
          });
        })}
      </Box>
    );
  };

  const renderQualitativeDescriptions = () => {
    return (
      <Box gap={1} display='flex' alignItems='center' justifyContent='flex-end'>
        <Tooltip
          title={
            <>
              Open-ended text responses allow users to freely express their
              thoughts.
              <div style={{ height: 8 }}></div>
              Example:
              <br />
              Users can provide detailed explanations of their specific needs,
              share their opinions on the user interface, or provide subjective
              feedback on their overall experience.
            </>
          }
        >
          <Box>
            <HelpOutlinedIcon
              sx={{
                opacity: 0.5,
              }}
            />
          </Box>
        </Tooltip>
        <Box>
          <Typography component='div' fontSize={14}>
            Qualitative questions capture user detailed insights
          </Typography>
          <Typography
            component='div'
            fontSize={12}
            fontWeight={500}
            style={{
              opacity: 0.8,
              color: isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          >
            Good for gathering descriptive information
          </Typography>
        </Box>
        <Box flex={1}>{/* <Divider flexItem /> */}</Box>
        {isAChildQuestion
          ? renderSetAsChildButton()
          : renderSetAsParentButton()}
      </Box>
    );
  };
  const renderQuantitativeParentChildButtonContent = () => {
    return (
      <Box gap={1} display='flex' alignItems='center' justifyContent='flex-end'>
        <Tooltip
          title={
            <>
              Subnumbers are included in the parent total.
              <div style={{ height: 8 }}></div>
              Example:
              <br />
              Parent - Total emails handled
              <br />
              Sub - emails resulting in refund
            </>
          }
        >
          <Box>
            <HelpOutlinedIcon
              sx={{
                opacity: 0.5,
              }}
            />
          </Box>
        </Tooltip>
        <Box>
          <Typography component='div' fontSize={14}>
            Quantitative questions can be stack into a parent/child relation
          </Typography>
          <Typography
            component='div'
            fontSize={12}
            fontWeight={500}
            style={{
              opacity: 0.8,
              color: isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          >
            There can only one parent per focus that can be set at a time
          </Typography>
        </Box>
        <Box flex={1}>{/* <Divider flexItem /> */}</Box>
        {isAChildQuestion
          ? renderSetAsChildButton()
          : renderSetAsParentButton()}
      </Box>
    );
  };

  const renderParentChildRelations = () => {
    return (
      <Box pt={4}>
        {renderCurrentParentQuantitative()}
        {renderQuantitativeChildren()}
        {/* <br />
        <Autocomplete
          key={`${selectedChildQuestions.length}`}
          fullWidth
          options={questionsOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <M3TextField
              {...params}
              fullWidth
              stripAllAutoProps
              placeholder='Search question to add as a child'
            />
          )}
          onChange={(_, option) => {
            if (option) {
              setSelectedChildQuestions((state) => [...state, { ...option }]);
            }
          }}
        />
        <Box pt={1}>
          {selectedChildQuestions.map((scq) => {
            return renderSelectedItem({
              key: scq.id,
              title: scq.label,
              onClick: () => {
                setSelectedChildQuestions((state) =>
                  [...state].filter((q) => q.id !== scq.id),
                );
              },
            });
          })}
        </Box> */}
      </Box>
    );
  };

  useEffect(() => {
    setUpdatedQuestions(questions);
  }, [questions, setUpdatedQuestions]);

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={renderBottomPanel()}
      close={close}
      sx={{
        maxWidth: 1200,
      }}
    >
      <Box sx={{ pt: 3, pb: 3, minHeight: 400 }}>
        {renderQuantitativeQualitySelection()}
        <br />
        <Divider />
        <br />
        <br />
        <br />
        <Stack direction='row' gap={10} alignItems='stretch'>
          <Box flex={1} width={0}>
            <Box
              display={
                formState.question_type === 'qualitative' ? 'block' : 'none'
              }
            >
              {renderQualitativeForm()}
            </Box>
            <Box
              display={
                formState.question_type === 'quantitative' ? 'block' : 'none'
              }
            >
              {renderQuantitativeForm()}
            </Box>
          </Box>
          {renderPreview()}
        </Stack>
        {formState.question_type === 'quantitative' &&
          renderParentChildRelations()}
      </Box>
      <ConfirmationDialog
        {...unsetParentConfirmationDialog.confirm}
        title='Remove as parent'
        message={
          <>
            Are you sure you want to remove this as parent?
            <br />
            This action will break the relationship with any associated child.
          </>
        }
        // isLoading={deleteFocusItem.isLoading}
        onConfirm={handleOnConfirmUnsetAsParent}
      />
      <ConfirmationDialog
        {...saveWithPreviouslyParentConfirmationDialog.confirm}
        title='Question Type Changed'
        message={
          isAChildQuestion ? (
            <>
              This question is associated to a parent. Saving with different
              type of question will break the relationship to its parent.
            </>
          ) : (
            <>
              This question is actively set as parent. Saving with different
              type of question will break the relationship with any associated
              child.
            </>
          )
        }
        dialogActions={{
          cancel: {
            hidden: true,
          },
          confirm: {
            label: 'Close',
          },
        }}
        // isLoading={deleteFocusItem.isLoading}
        onConfirm={() => {
          isCurrentQuestionParent &&
            setIsParentQuantitativeBeforeNowQualitative(true);
          isAChildQuestion && setIsChildQuantitativeBeforeNowQualitative(true);
          saveWithPreviouslyParentConfirmationDialog.confirm.setIsOpen(false);
        }}
      />
    </ModalCardView>
  );
}

const FocusQuestionFormModalView = forwardRef((props: Props, ref) => (
  <FocusQuestionFormModalViewBase {...props} />
));

export default FocusQuestionFormModalView;
