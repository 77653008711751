import React from 'react';
import { IconProps, withWrapper } from './index';

type Props = IconProps;

const PromptPencilSvgIcon = ({ fillColor = '#fff', ...restProps }: Props) => (
  <svg
    fill='none'
    height={25}
    width={25}
    viewBox={`0 0 25 25`}
    xmlns='http://www.w3.org/2000/svg'
    {...restProps}
  >
    <mask
      x='0'
      y='0'
      width='25'
      height='25'
      id='mask0_449_9074'
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'alpha',
      }}
    >
      <rect x='0.736816' y='0.5' width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_449_9074)'>
      <path
        d='M12.7368 21.5V19.375L18.0368 14.075L20.1618 16.2L14.8618 21.5H12.7368ZM3.73682 16.5V14.5H10.7368V16.5H3.73682ZM20.8618 15.5L18.7368 13.375L19.4618 12.65C19.6452 12.4667 19.8785 12.375 20.1618 12.375C20.4452 12.375 20.6785 12.4667 20.8618 12.65L21.5868 13.375C21.7701 13.5583 21.8618 13.7917 21.8618 14.075C21.8618 14.3583 21.7701 14.5917 21.5868 14.775L20.8618 15.5ZM3.73682 12.5V10.5H14.7368V12.5H3.73682ZM3.73682 8.5V6.5H14.7368V8.5H3.73682Z'
        fill={fillColor}
      />
    </g>
  </svg>
);

export default withWrapper(PromptPencilSvgIcon);
