import React from 'react';
import { IconProps, withWrapper } from './index';

type Props = IconProps;

const IntercomSvgIcon = ({ fillColor = '#fff', ...restProps }: Props) => (
  <svg
    fill='none'
    height={32}
    width={32}
    viewBox={`0 0 32 32`}
    xmlns='http://www.w3.org/2000/svg'
    {...restProps}
  >
    <path
      d='M27.733 17.594a1.067 1.067 0 1 1-2.133 0V8a1.067 1.067 0 1 1 2.133 0v9.594zm-.372 6.676c-.165.141-4.116 3.457-11.361 3.457-7.245 0-11.196-3.316-11.361-3.457a1.067 1.067 0 0 1 1.386-1.622c.063.052 3.591 2.946 9.975 2.946 6.464 0 9.938-2.914 9.972-2.944a1.067 1.067 0 0 1 1.39 1.62zM4.267 8A1.067 1.067 0 0 1 6.4 8v9.594a1.067 1.067 0 1 1-2.133 0V8zM9.6 5.867a1.067 1.067 0 0 1 2.133 0v14.25a1.067 1.067 0 0 1-2.133 0V5.867zm5.333-.54a1.067 1.067 0 1 1 2.134 0v15.467a1.067 1.067 0 1 1-2.134 0V5.327zm5.334.54a1.067 1.067 0 1 1 2.133 0v14.25a1.067 1.067 0 0 1-2.133 0V5.867zM28 0H4a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z'
      fill={fillColor}
    />
  </svg>
);

export default withWrapper(IntercomSvgIcon);
