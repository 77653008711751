import {
  Box,
  Button,
  FormHelperText,
  SxProps,
  Typography,
} from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import BasicCard from '../BasicCard';
import BasicForm, { useForm } from '../BasicForm';
import { M3Button } from '../M3/M3Button';
import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer, {
  getSurfaceOpacityByElevation,
} from '../M3/M3SurfaceContainer';
import { M3TextField } from '../M3/M3TextField';
import MainContainer from '../MainContainer';

import { useSignup } from '../../hooks/services/signup';
import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';
import { parseErrorData } from '../../utils/response';
import { parseURL } from '../../utils/url';
import { useAuthProvider } from '../../providers/auth/auth';

type SignUpProps = {
  user_type: 'numbers' | 'jobseekers';
  introText?: string;
};

type SignUpData = {
  email: string;
  password: string;
  user_type: SignUpProps['user_type'];
};
type SignUpParams = {
  email: string;
  password: string;
  password2: string;
};

const SignUp = ({
  user_type,
  introText = 'Please use the email you provided in the application form',
}: SignUpProps) => {
  let { isAuthenticated } = useAuthProvider();
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const parsedUrl = parseURL<{ email: string }>(search, {
    decoder: (str, defaultDecoder, charset, type) => {
      if (str.indexOf('+') > -1) return str;
      return defaultDecoder(str, null, charset);
    },
  });

  const { formState, error, handleChange, handleSubmit } =
    useForm<SignUpParams>({
      email: parsedUrl.email ?? '',
      password: '',
      password2: '',
    });

  const signup = useSignup<SignUpData>();

  const buttonDisabled =
    signup.isLoading ||
    !(formState.email && formState.password && formState.password2);

  const parsedError = parseErrorData(signup.error);

  const onSubmit = handleSubmit((data: SignUpParams) => {
    if (data.password !== data.password2) {
      throw new Error('Password did not match');
    }
    signup.mutate({
      email: formState.email,
      password: data.password,
      user_type,
    });
  });

  const textFieldSx: SxProps = {
    '.MuiOutlinedInput-input': {},
  };

  const renderError = (message?: string) => {
    if (!message) return null;

    return (
      <FormHelperText error sx={{ mb: 2, mt: -2 }}>
        {message}
      </FormHelperText>
    );
  };

  const renderWrapper = (children: ReactRenderElement) => {
    return (
      <BasicForm onSubmit={onSubmit}>
        {/* <Stack
          sx={{ mb: 2 }}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <img
            src='/icon-128.png'
            height={44}
            width={44}
            style={{
              borderRadius: 5,
              objectFit: 'cover',
            }}
          />
        </Stack> */}
        <Typography component='h5' variant='h5'>
          Signup
        </Typography>
        <FormHelperText
          sx={{ mb: 3, lineHeight: 1.4, fontSize: 14, opacity: 0.8 }}
        >
          {signup.isSuccess ? 'Thank you for signing up' : introText}
        </FormHelperText>
        <Box>{children}</Box>
        {renderError(
          typeof parsedError?.message === 'object'
            ? (parsedError?.message?.message as string)
            : parsedError?.message,
        )}
        {renderError(error?.message)}
        {!signup.isSuccess && (
          <M3Button
            type='submit'
            color='primary'
            size='large'
            variant='contained'
            fullWidth
            disabled={buttonDisabled}
            sx={{ borderRadius: 20, height: 44, fontWeight: 500 }}
          >
            Signup
          </M3Button>
        )}
      </BasicForm>
    );
  };

  const renderForm = () => {
    return renderWrapper(
      <>
        <M3TextField
          fullWidth
          label='Email'
          name='email'
          value={formState.email}
          placeholder='Enter your email address'
          onChange={handleChange}
          sx={textFieldSx}
        />
        <br />
        <br />
        <M3TextField
          fullWidth
          label='Password'
          type='password'
          name='password'
          value={formState.password}
          placeholder='Enter your password'
          onChange={handleChange}
          sx={textFieldSx}
        />
        <br />
        <br />
        <M3TextField
          fullWidth
          label='Confirm Password'
          type='password'
          name='password2'
          value={formState.password2}
          placeholder='Confirm your password'
          onChange={handleChange}
          sx={textFieldSx}
        />
        <br />
        <br />
      </>,
    );
  };

  const renderSuccess = () => {
    return renderWrapper(
      <>
        <Typography>
          Please use your username and password to login into the next page.
        </Typography>
        <br />
        <Button
          color='primary'
          variant='contained'
          size='medium'
          sx={{ borderRadius: 20, width: 100 }}
          onClick={() => navigate('/login')}
        >
          Login
        </Button>
      </>,
    );
  };

  useLayoutEffect(() => {
    try {
      const rootEl = document.getElementById('root')!;
      rootEl.style.background = `${applyOpacityOnColor(
        palette['md.ref.palette.primary40'],
        getSurfaceOpacityByElevation(2),
      )}`;
    } catch (e) {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <MainContainer
        flex={false}
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <BasicCard
          sx={{
            maxWidth: 420,
            margin: '14vh auto 5vh',
          }}
        >
          <M3Card>
            <M3SurfaceContainer
              elevation={1}
              sx={{
                p: 4,
                background: isDarkMode ? undefined : '#fff',
              }}
            >
              {signup.isSuccess ? renderSuccess() : renderForm()}
            </M3SurfaceContainer>
          </M3Card>
        </BasicCard>
      </MainContainer>
    </>
  );
};

export default SignUp;
