import React, { CSSProperties, PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  visible?: boolean;
  steady?: boolean;
  style?: CSSProperties;
};

const MyDayCardPulsating = ({
  visible = true,
  steady,
  children,
  style,
}: Props) => {
  return (
    <>
      {visible && (
        <div
          style={{
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: 12,
            position: 'absolute',
            ...(steady
              ? {
                  animation: 'none',
                }
              : null),
            ...style,
          }}
          className='pulsating-my-day-card'
        ></div>
      )}
      {children}
    </>
  );
};

export default MyDayCardPulsating;
