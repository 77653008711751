import {
  UserMetadata,
  UserProfile,
  UserSearchItemResponse,
} from '../types/profile';
import { IterableObject } from '../types/types';
import { prefixUrl } from './url';

/**
 * NOTE: Employee IDs and their respective names
 */
export const employeeIdUsers: IterableObject = {
  '10000001': 'Scott Moran',
  '10000002': 'Darien Varner',
  '10000008': 'Rustan Corpuz',
  '10000066': 'Mj Lansangan',
  '10000611': 'Mike Buenaventura',
  '10001086': 'Bon Baga',
  '10001539': 'Sat Murthy',
};

type UserInitialAliasDisplay = {
  initial?: string | null;
  initials?: string | null;
  fullName?: string | null;
  nickname?: string | null;
  alias?: string | null;
};
type UserDisplayName = UserProfile & UserInitialAliasDisplay;

type UserInitialsOptions = {
  joinDelimiter: string;
};
export function getUserInitials(
  name?: string,
  charCount: number = 1,
  limit: number = 2,
  { joinDelimiter = '' }: UserInitialsOptions = {} as UserInitialsOptions,
) {
  let fullName = (name || '').trim();
  let fullNameSplit = fullName.split(' ');
  let initials = fullNameSplit.slice(0, limit);
  initials = initials.map((s) => {
    return s.split('').slice(0, charCount).join('').toUpperCase();
  });
  return {
    initial: initials[0],
    initials: initials.join(joinDelimiter),
  };
}

export function getUserProfileDisplayName(
  user?: Partial<UserProfile | UserMetadata | UserSearchItemResponse> | null,
): UserInitialAliasDisplay {
  let fullName =
    `${user?.first_name || ''} ${user?.last_name || ''}`.trim() || '';
  return {
    ...getUserInitials(fullName),
    fullName,
  };
}

export function getUserDisplayName(user: UserProfile): UserDisplayName {
  let fullName =
    `${user.first_name || ''} ${user.last_name || ''}`.trim() || '';
  let fullNameSplit = fullName.split(' ');
  return {
    ...getUserInitials(fullName),
    fullName,
    alias: fullNameSplit[0] ?? null,
    ...user,
  };
}

export function getUserPhotoUrl(url: string | null = '') {
  url = url ?? '';
  return prefixUrl(
    url,
    url.search(/^\//) > -1
      ? process.env.REACT_APP_AWS_S3_BUCKET
      : process.env.NODE_ENV === 'production'
      ? `${process.env.REACT_APP_AWS_S3_BUCKET}/users-upload`
      : `${process.env.REACT_APP_AWS_S3_BUCKET}/media/`,
  )!;
}

export function getUserReportBgUrl(url: string | null = '') {
  url = url ?? '';
  return prefixUrl(
    url,
    url.search(/^\//) > -1
      ? process.env.REACT_APP_AWS_S3_BUCKET
      : process.env.NODE_ENV === 'production'
      ? `${process.env.REACT_APP_AWS_S3_BUCKET}/users-upload`
      : `${process.env.REACT_APP_AWS_S3_BUCKET}/media/`,
  )!;
}

export function getCurrentUserJobDescription(
  user?: UserMetadata | UserProfile | null,
) {
  return {
    job_title: user?.job_title ?? null,
  };
}
