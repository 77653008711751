import { Box, Stack, Typography, SxProps } from '@mui/material';
import moment, { Moment } from 'moment';
import React, {
  ClipboardEvent,
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import BasicDatePicker from '../Pickers/BasicDatePicker';
import BasicForm, { useForm, FormOptions } from '../BasicForm';
import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3TextField } from '../M3/M3TextField';
import { M3Button } from '../M3/M3Button';
import { M3SelectGroup } from '../M3/M3Select';
import PerfectScrollbar from '../PerfectScrollbar';
import AbsoluteCenterBox from '../AbsoluteCenterBox';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../Dialogs/ConfirmationDialog';

import { usePTORequestAction, useRequestPTO } from '../../hooks/pto';
import { HrTimeOffTypeId } from '../../types/hr';
import { hrTimeOffTypes } from '../../utils/pto';
import { useAppProvider } from '../../providers/app/app';
import { DateYYYYMMDD, IterableObject } from '../../types/types';
import { dgs } from '../../utils/string';
import { isNotNullAndUndefined } from '../../utils/object';
import { PTOAction, PTOStatus } from '../../types/pto';
import { getRawCodeMessage } from '../../utils/response';

export type PTORequestFormProps<FS = RequestFormParams> = PropsWithChildren &
  ModalCardViewCloseProps & {
    edit?: boolean;
    id?: number | string;
    status?: PTOStatus;
    staffId?: string | null;
    modal?: boolean;
    params?: RequestFormParams;
    onSuccess?: () => void;
    onStateUpdated?: FormOptions<FS>['onStateUpdated'];
  };
type DateProps = {
  name: string;
  label?: string;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  defaultValue: string | null;
  onChange?: (date: string) => void;
};

export type RequestFormParams = {
  start: string | null;
  end: string | null;
  type: HrTimeOffTypeId | null;
  amount: string | null;
  note: string | null;
  dates: IterableObject<DateItemObject>;
};
type DateItemObject = {
  date: DateYYYYMMDD;
  amount: string | null;
};
type DateItemPayload = {
  ymd: DateYYYYMMDD;
  amount: string | null;
};
type RequestFormPayload = {
  start: RequestFormParams['start'];
  end: RequestFormParams['end'];
  time_off_type_id: RequestFormParams['type'];
  note: RequestFormParams['note'];
  dates: DateItemPayload[];
};

const dateFieldSx: SxProps = {
  width: '100%',
};

const fieldSx: SxProps = {};

const ptoTypes: {
  id: HrTimeOffTypeId;
  label: string;
}[] = [
  {
    id: hrTimeOffTypes.pto,
    label: 'Paid Time Off (PTO)',
  },
  {
    id: hrTimeOffTypes.flex_holiday,
    label: 'Flexible holidays',
  },
  {
    id: hrTimeOffTypes.uto,
    label: 'UTO',
  },
];

const PTORequestForm = forwardRef(
  (
    {
      id,
      edit,
      modal,
      params,
      status,
      staffId,
      close,
      onSuccess,
      onStateUpdated,
    }: PTORequestFormProps<RequestFormParams>,
    ref,
  ) => {
    const { isDarkMode } = useAppProvider();

    const {
      formRef,
      formState,
      hasChanged,
      handleChange,
      handleSubmit,
      updateState,
    } = useForm<RequestFormParams>(
      edit
        ? params
        : {
            start: null,
            end: null,
            type: null,
            amount: '',
            note: '',
            dates: {},
          },
      {
        onStateUpdated,
      },
    );
    const [action, setAction] = useState<PTOAction>('update');
    const confirmationDialog = useConfirmationDialog();

    const requestPTO = useRequestPTO();
    const requestAction = usePTORequestAction(
      {
        id: id!,
        action,
      },
      {
        method: action === 'cancel' ? 'POST' : 'PATCH',
      },
    );

    const rawCodeMessage = getRawCodeMessage(
      requestPTO.error || requestAction.error,
    );

    const datePickerState = useMemo(() => {
      const start = formState.start ? moment(formState.start) : null;
      const end =
        formState.end || formState.start
          ? moment(formState.end || formState.start)
          : null;
      const generateDates = () => {
        if (start) {
          const diff = Math.abs(start.diff(end, 'day')) + 1;
          return new Array(diff).fill(null).map((d, i) => {
            return start.clone().add(i, 'day');
          });
        }
        return [];
      };

      return {
        multiple: start ? Math.abs(start.diff(end, 'day')) + 1 > 1 : false,
        dates: generateDates(),
      };
    }, [formState.start, formState.end]);

    const getPayload = (data: RequestFormParams) => {
      let dates: DateItemPayload[] = [];

      if (datePickerState.multiple) {
        dates = datePickerState.dates.map((d) => {
          const ymd = d.format('YYYY-MM-DD');
          const value = formState.dates[ymd];
          const date = moment(ymd);
          const defaultAmount = date.day() === 0 || date.day() === 6 ? 0 : 8;
          return {
            ymd: ymd,
            amount: value ? value.amount : `${defaultAmount}`,
          } as DateItemPayload;
        });
      } else {
        dates = [
          {
            ymd: formState.start!,
            amount: formState.amount,
          } as DateItemPayload,
        ];
      }

      return {
        start: data.start,
        end: data.end,
        time_off_type_id: data.type,
        note: data.note,
        dates,
      } as RequestFormPayload;
    };

    const onCreateSubmit = handleSubmit((data) => {
      if (requestPTO.isLoading) return;

      requestPTO.mutate(getPayload(data));
    });

    const onUpdateSubmit = handleSubmit((data) => {
      if (requestAction.isLoading) return;

      requestAction.mutate(getPayload(data));
    });

    const onCancelRequest = () => {
      requestAction.mutate({});
    };

    const getTotalAmount = () => {
      const { dates } = getPayload(formState);
      return dates
        .map(({ amount }) => +(amount ?? 0))
        .reduce((p, c) => p + c, 0);
    };

    const onPreventClipboardEvent = (
      event: ClipboardEvent<HTMLDivElement>,
    ): void => {
      event.preventDefault();
    };

    const onAmountKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === '-' || event.key === '+') {
        event.preventDefault();
      }
    };

    /*
    const checkBalanceIsAvailable = () => {
      const { pto, flexHoliday, uto } = userPtoBalance;
      let balance: number = 0;

      if (pto && formState.type === hrTimeOffTypes.pto) {
        balance = +pto.balance;
      } else if (
        flexHoliday &&
        formState.type === hrTimeOffTypes.flex_holiday
      ) {
        balance = +flexHoliday.balance;
      } else if (uto && formState.type === hrTimeOffTypes.uto) {
        balance = +uto.balance;
      }

      return balance;
    };
    */

    const isAllFormFilled = () => {
      const { start, end, type, amount, note } = formState;

      return !!(
        start &&
        end &&
        moment(end).isSameOrAfter(start) &&
        type &&
        amount &&
        note
      );
    };

    const renderTopPanel = () => {
      return (
        <>
          <Typography
            component='div'
            variant='h6'
            fontWeight={500}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            Time Off Request
          </Typography>
        </>
      );
    };

    const renderBottomPanel = () => {
      return (
        <Stack
          gap={2}
          direction='row'
          sx={
            modal
              ? {
                  p: 2,
                  flex: 1,
                }
              : { p: 2, flex: 1, pl: 0, pt: 3 }
          }
          alignItems='center'
          justifyContent={modal ? 'center' : 'flex-start'}
        >
          <M3Button
            color='primary'
            variant='contained'
            sx={{
              width: edit ? 100 : 110,
            }}
            disabled={
              requestPTO.isLoading ||
              (requestAction.isLoading && action === 'update') ||
              !isAllFormFilled() ||
              !hasChanged
            }
            onClick={() => confirmationDialog.confirm.setIsOpen(true)}
          >
            {edit ? 'Save' : 'Submit'}
          </M3Button>
          {edit && (
            <M3Button
              onClick={() => {
                setAction('cancel');
                confirmationDialog.cancel.setIsOpen(true);
              }}
              sx={{
                right: 10,
                position: 'absolute',
              }}
              disabled={requestPTO.isLoading || !isAllFormFilled()}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-error70)'
                  : 'var(--md-ref-palette-error40)',
              }}
            >
              Cancel Request&nbsp;
            </M3Button>
          )}
        </Stack>
      );
    };

    const renderDate = ({
      name,
      label,
      defaultValue,
      minDate,
      maxDate,
    }: DateProps) => {
      return (
        <Box sx={dateFieldSx}>
          <BasicDatePicker
            format='YYYY-MM-DD'
            label={label}
            defaultValue={defaultValue}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(value: Moment | null) => {
              handleChange({
                target: {
                  name,
                  value: value ? value.format('YYYY-MM-DD') : null,
                },
              });
            }}
          />
        </Box>
      );
    };

    const renderSingleDayFormFields = () => {
      return (
        <Stack
          direction='row'
          alignItems='center'
          flex={1}
          sx={{ position: 'relative' }}
        >
          <M3TextField
            label='Amount'
            name='amount'
            sx={{
              ...fieldSx,
              flex: 1,
              '& .MuiInputBase-input': {
                pr: 10,
              },
            }}
            type='number'
            value={formState.amount}
            onChange={handleChange}
            onCopy={onPreventClipboardEvent}
            onPaste={onPreventClipboardEvent}
            onKeyDown={onAmountKeyDown}
          />
          <Typography
            display='flex'
            alignItems='center'
            sx={{
              ml: 2,
              top: 0,
              right: 20,
              bottom: 0,
              position: 'absolute',
            }}
          >
            hours
          </Typography>
        </Stack>
      );
    };

    const renderMultipleDaysFormFields = () => {
      return (
        <Box flex={1}>
          <Box
            position='relative'
            sx={{
              height: datePickerState.dates.length >= 5 ? 260 : undefined,
            }}
          >
            <AbsoluteCenterBox
              sx={{
                opacity: isDarkMode ? 0.2 : 0.2,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            />
            <PerfectScrollbar
              style={{
                padding: 20,
                paddingBottom: 8,
              }}
            >
              {datePickerState.dates.map((date: Moment, i) => {
                const ymd = date.format('YYYY-MM-DD');
                const value: DateItemObject = formState.dates[ymd] ?? {
                  date: ymd,
                  amount: date.day() === 0 || date.day() === 6 ? 0 : 8,
                };
                return (
                  <Stack
                    key={i}
                    mb={1}
                    direction='row'
                    alignItems='center'
                    position='relative'
                    sx={{
                      '.MuiInputLabel-root': {
                        background: 'transparent !important',
                      },
                    }}
                  >
                    <Typography
                      component='div'
                      mr={2}
                      width={80}
                      minWidth={80}
                      fontSize={13}
                      fontWeight={500}
                      whiteSpace='nowrap'
                      style={{
                        color:
                          date.day() === 0 || date.day() === 6
                            ? isDarkMode
                              ? 'var(--md-ref-palette-secondary60)'
                              : 'var(--md-ref-palette-secondary40)'
                            : undefined,
                      }}
                    >
                      {date.format('MMM D, ddd')}
                    </Typography>
                    <M3TextField
                      label='Amount'
                      name='amount'
                      sx={{
                        ...fieldSx,
                        flex: 1,
                        '& .MuiInputBase-input': {
                          pr: 10,
                        },
                      }}
                      value={value.amount ?? ''}
                      onChange={(evt: SyntheticEvent) => {
                        updateState((state: RequestFormParams) => {
                          return {
                            ...state,
                            dates: {
                              ...state.dates,
                              [ymd]: {
                                date: ymd,
                                amount: (evt.target as HTMLInputElement).value,
                              },
                            } as RequestFormParams['dates'],
                          } as RequestFormParams;
                        });
                      }}
                    />
                    <Typography
                      display='flex'
                      alignItems='center'
                      sx={{
                        ml: 2,
                        top: 0,
                        right: 20,
                        bottom: 0,
                        position: 'absolute',
                      }}
                    >
                      hours
                    </Typography>
                  </Stack>
                );
              })}
            </PerfectScrollbar>
          </Box>
          <Stack
            direction='row'
            alignItems='flex-end'
            sx={{
              pt: 1,
              borderTop: `2px solid ${
                isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)'
              }`,
            }}
          >
            <Typography
              component='div'
              mr={2}
              ml={2}
              pb={0.4}
              width={80}
              minWidth={80}
              fontSize={13}
              fontWeight={500}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-secondary60)'
                  : 'var(--md-ref-palette-secondary40)',
              }}
            >
              Total hours
            </Typography>
            <Typography component='div' fontWeight={500} fontSize={20} pl={1.6}>
              {dgs(
                datePickerState.dates
                  .map(
                    (date) =>
                      +(
                        formState.dates[date.format('YYYY-MM-DD')]?.amount ??
                        (date.day() === 0 || date.day() === 6 ? 0 : 8)
                      ),
                  )
                  .reduce((p, c) => p + c, 0),
              )}
            </Typography>
          </Stack>
        </Box>
      );
    };

    const renderFormContent = () => {
      return (
        <>
          <Box
            flex={1}
            sx={{
              mt: modal ? 4 : 2,
              mb: modal ? 4 : 0,
            }}
          >
            <BasicForm setRef={formRef} onSubmit={onCreateSubmit}>
              <Box>
                <Stack direction='row' gap={2} alignItems='center'>
                  {renderDate({
                    name: 'start',
                    label: 'From',
                    defaultValue: formState.start,
                    minDate: moment(),
                    maxDate: formState.end ? moment(formState.end) : null,
                  })}
                  <Typography fontSize={30} sx={{ opacity: 0.2 }}>
                    -
                  </Typography>
                  {renderDate({
                    name: 'end',
                    label: 'To',
                    minDate: formState.start
                      ? moment(formState.start)
                      : moment(),
                    defaultValue: formState.end,
                  })}
                </Stack>
                <br />
                <Stack
                  gap={2}
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  {datePickerState.multiple
                    ? renderMultipleDaysFormFields()
                    : renderSingleDayFormFields()}
                  <Typography fontSize={30} sx={{ opacity: 0 }}>
                    -
                  </Typography>
                  <M3SelectGroup
                    label='Type'
                    labelId='pto-type-select-label'
                    options={ptoTypes}
                    selectProps={{
                      fullWidth: true,
                      name: 'type',
                      value: formState.type ?? '',
                      placeholder: 'Select type...',
                      onChange: (evt) => handleChange(evt as SyntheticEvent),
                    }}
                    formControlSx={{ flex: 1 }}
                  />
                </Stack>
                <br />
                <M3TextField
                  name='note'
                  fullWidth
                  multiline
                  minRows={3}
                  label='Note'
                  value={formState.note}
                  onChange={handleChange}
                />
              </Box>
              {!modal && renderBottomPanel()}
            </BasicForm>
          </Box>
          <ConfirmationDialog
            {...confirmationDialog.cancel}
            title='Cancel Time Off Request'
            message='Are you sure you want to cancel the following time off request?'
            content={renderConfirmDialogContent()}
            isLoading={action === 'cancel' && requestAction.isLoading}
            onConfirm={onCancelRequest}
            onClose={() => {
              requestAction.reset();
              requestPTO.reset();
              setAction('update');
            }}
          />
          <ConfirmationDialog
            {...confirmationDialog.confirm}
            title='Confirm Time Off Request'
            content={renderConfirmDialogContent()}
            message={
              edit && status === 'approved' ? (
                <>
                  Please note that editing an approved time off request will
                  cancel the previous request and create a new one. The new
                  request will require approval from your manager. Are you sure
                  you want to proceed with editing the request?
                </>
              ) : (
                <>
                  Please confirm that you would like to submit the following
                  time off request. Once submitted, your request will be sent to
                  your manager for approval. Are you sure you want to proceed?
                </>
              )
            }
            isLoading={requestPTO.isLoading || requestAction.isLoading}
            onConfirm={edit ? onUpdateSubmit : onCreateSubmit}
            onClose={() => {
              requestAction.reset();
              requestPTO.reset();
              setAction('update');
            }}
          />
        </>
      );
    };

    const renderConfirmDialogContent = () => {
      const {
        start: dateStart,
        end: dateEnd,
        note,
        time_off_type_id,
      } = getPayload(edit ? params! : formState);
      const total = getTotalAmount();
      const start = moment(dateStart);
      const end = dateEnd ? moment(dateEnd) : start.clone();
      const date =
        start.month() === end.month()
          ? `${start.format('MMM')} ${start.format('D')}${
              start.date() !== end.date() ? ` – ${end.date()}` : ''
            }`
          : [start.format('MMM D'), end.format('MMM D')].join(' – ');
      const year =
        start.year() === end.year()
          ? start.year()
          : [start.year(), end.year()].join(' – ');

      return (
        <Box>
          <Typography
            component='div'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            {date}
            <span
              style={{
                fontSize: 9,
                opacity: isDarkMode ? 0.5 : 0.4,
                marginLeft: 8,
                borderRadius: 3,
                fontWeight: 500,
                padding: '1px 4px',
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              {year}
            </span>
          </Typography>
          <Stack
            direction='row'
            alignItems='flex-start'
            justifyContent='space-between'
          >
            <Typography component='div' fontSize={14} sx={{ opacity: 0.8 }}>
              {dgs(total)} hour{total > 1 ? 's' : ''} of{' '}
              {
                ptoTypes.find((ptoType) => ptoType.id === time_off_type_id)
                  ?.label
              }
            </Typography>
          </Stack>
          {!!note && (
            <Typography
              p={1}
              pl={2}
              pr={2}
              mt={0.5}
              borderRadius={1}
              component='div'
              fontSize={14}
              fontStyle='italic'
              sx={{
                opacity: 0.5,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              {note}
            </Typography>
          )}
          {rawCodeMessage.error && (
            <Box>
              <Typography
                mt={2}
                fontSize={14}
                sx={{
                  color: isDarkMode
                    ? 'var(--md-ref-palette-error80) !important'
                    : 'var(--md-ref-palette-error40) !important',
                }}
              >
                {rawCodeMessage.message}
              </Typography>
            </Box>
          )}
        </Box>
      );
    };

    /**
     * Set the default amount on the selected dates
     */
    useEffect(() => {
      if (datePickerState.multiple) {
        updateState((state) => ({
          ...state,
          dates: datePickerState.dates.reduce((prev, curr) => {
            const ymd = curr.format('YYYY-MM-DD');
            const dates = state.dates || {};
            const initAmount = dates[ymd];
            let amount: string = `${
              curr.day() === 0 || curr.day() === 6 ? 0 : 8
            }`;
            // check if there's a default amount store then use it as the amount
            if (isNotNullAndUndefined(initAmount?.amount)) {
              amount = `${initAmount?.amount ?? amount}`;
            }

            prev[ymd] = {
              amount,
              date: ymd,
            };
            return prev;
          }, {} as IterableObject<DateItemObject>),
        }));
      } else {
        updateState((state) => ({
          ...state,
          amount: edit ? state.amount : '8',
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit, datePickerState.multiple]);

    useEffect(() => {
      if (requestPTO.isSuccess || requestAction.isSuccess) {
        close?.();
        onSuccess?.();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestPTO.isSuccess, requestAction.isSuccess]);

    if (modal) {
      return (
        <ModalCardView
          header={renderTopPanel()}
          headerSx={{ pt: 2, pb: 2 }}
          footer={renderBottomPanel()}
          close={close}
        >
          {renderFormContent()}
        </ModalCardView>
      );
    }

    return renderFormContent();
  },
);

export default PTORequestForm;
