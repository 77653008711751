import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import { useAppProvider } from '../../providers/app/app';

type Props = Partial<TooltipProps> & { title: TooltipProps['title'] };

const ContentDragDropUnsaved = ({ ...tooltipProps }: Props) => {
  const { isDarkMode } = useAppProvider();

  return (
    <Tooltip {...tooltipProps}>
      <ErrorOutlinedIcon
        sx={{
          top: 0,
          left: -20,
          bottom: 5,
          fontSize: 18,
          margin: 'auto',
          position: 'absolute',
        }}
        style={{
          color: isDarkMode
            ? 'var(--md-ref-palette-error80)'
            : 'var(--md-ref-palette-error40)',
        }}
      />
    </Tooltip>
  );
};

export default ContentDragDropUnsaved;
