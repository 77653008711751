import { useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';

import DocumentTitle from '../components/DocumentTitle';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import { FormStateRet, useForm } from '../components/BasicForm';
import AutocompleteSelectionPopover, {
  AutocompleteFormState,
} from '../components/Filters/AutocompleteSelectionPopover';
import AbsoluteCenterBox from '../components/AbsoluteCenterBox';
import BasicIframe from '../components/BasicIframe';
import { M3Button } from '../components/M3/M3Button';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useAppProvider } from '../providers/app/app';
import { useDomoEmbedDashboard } from '../hooks/services/domo';
import { ReactRenderElement } from '../types/types';
import { useGo2Members } from '../hooks/member';
import ErrorPage from './Error';
import DateRangeSelectionPopover from '../components/Filters/DateRangeSelectionPopover';
import { useIsPathBetaUser } from '../hooks/utils/user';
import { useCurrentProfile } from '../hooks/profile';
import { useMetabaseIFrame } from '../hooks/utils/metabase';

type Props = {};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

type RenderCardItemProps = {
  title?: ReactRenderElement;
  html?: string;
  isLoading: boolean;
  sx?: SxProps;
};

const TeamResources = (props: Props) => {
  const { current: currentWorkspace } = useWorkspaceProvider();
  const { resourcesParams, setResourcesParams } = useMetadataProvider();
  const { subToolbarHeight, isDarkMode } = useAppProvider();

  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const isPathBetaUser = useIsPathBetaUser();
  const { data: currentProfile } = useCurrentProfile();

  const dateForm = useForm<DateFormState>({
    start: resourcesParams.date_start,
    end: resourcesParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });
  const memberForm = useForm<AutocompleteFormState>({
    checked: {},
    optionById: {},
  });

  const divisionGo2Members = useGo2Members(
    {
      division:
        currentWorkspace?.id === 'my-squad'
          ? undefined
          : +(currentWorkspace?.id ?? 0),
      employee_ids:
        currentWorkspace?.id === 'my-squad'
          ? currentWorkspace?.memberId
          : undefined,
      limit: 300,
      is_active: true,
    },
    {
      enabled: !!currentWorkspace,
    },
  );
  const divisionGo2MembersData = (divisionGo2Members.data?.results ?? []).map(
    (member) => ({
      id: member.employee_id,
      name: `${member.full_name}`,
    }),
  );

  /**
   * NOTE: Previously used for domo, now it's going to be used to metabase
   */
  const dashboardParams = useMemo(
    () => ({
      key: `admin_settings_resources_html_${
        currentWorkspace?.id ?? currentWorkspace?.queryId ?? ''
      }`,
      embed_type: 'stories',
      embed_id: '66lnl',
      // week: weekSelected,
      date_range: [dateForm.formState.start!, dateForm.formState.end!],
      division:
        currentWorkspace?.id === 'my-squad'
          ? undefined
          : currentWorkspace?.name,
      go2_member_id:
        getAutocompleteSelectedByForm(memberForm, currentWorkspace?.memberId) ||
        (isPathBetaUser ? currentProfile?.employee_id : undefined),
    }),
    [
      currentWorkspace,
      dateForm.formState.start,
      dateForm.formState.end,
      memberForm.formState,
      isPathBetaUser,
      currentProfile,
    ],
  );
  const { isLoading: dashboardIsLoading, data: dashboardHTML } =
    useDomoEmbedDashboard(dashboardParams as any, {
      enabled: !!currentWorkspace,
    });

  const metabaseIFrameParams = useMemo(
    () => ({
      date: `${dateForm.formState.start!}~${dateForm.formState.end!}`,
      division: dashboardParams.division || null,
      member_id: dashboardParams.go2_member_id
        ? dashboardParams.go2_member_id.split(',')
        : [],
    }),
    [dashboardParams],
  );
  const metabaseIFrame = useMetabaseIFrame({
    value: 75,
    params: metabaseIFrameParams,
  });

  function getAutocompleteSelectedByForm(
    form: FormStateRet<AutocompleteFormState>,
    members?: string,
  ) {
    let values: string[] = Object.keys(form.formState.checked || {}).filter(
      (key) => {
        return form.formState.checked[key];
      },
    );
    return values.length ? values.join(',') : members ? members : undefined;
  }

  const renderChipFilters = (
    label: string,
    form: FormStateRet<AutocompleteFormState>,
  ) => {
    const selected = Object.keys(form.formState.checked || {}).filter((key) => {
      return form.formState.checked[key];
    });
    return selected.map((id) => {
      const option = form.formState.optionById[id];
      return (
        <Chip
          key={option.id}
          size='small'
          label={
            <>
              <span style={{ opacity: 0.4 }}>{label}:</span>{' '}
              <strong>{option.value}</strong>
            </>
          }
          sx={{
            fontSize: 12,
            background: 'transparent',
          }}
          onDelete={() => {
            form.updateState((state: AutocompleteFormState) => {
              const stateChecked = state.checked || {};
              const stateOptionById = state.optionById || {};
              const checked = !stateChecked[option.id];
              const newState = {
                ...state,
                checked: {
                  ...stateChecked,
                  [option.id]: checked,
                },
                optionById: {
                  ...stateOptionById,
                  [option.id]: option,
                },
              };
              delete newState.checked[option.id];
              delete newState.optionById[option.id];

              return newState;
            });
          }}
        />
      );
    });
  };

  const renderFilterResults = () => {
    let hasFilters = [
      ...Object.values(memberForm.formState.checked || {}),
    ].some((v) => !!v);

    if (!hasFilters) return null;

    return (
      <Stack
        gap={1}
        direction='column'
        alignItems='flex-start'
        sx={{ p: 3, pt: 2 }}
      >
        <Stack
          gap={1}
          flex={1}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          flexWrap='wrap'
          style={{ marginLeft: -8 }}
        >
          {renderChipFilters('Member', memberForm)}
        </Stack>
        <Divider sx={{ opacity: 0.8, flex: 1, width: '100%' }} />
        <M3Button
          variant='outlined'
          size='small'
          onClick={() => {
            memberForm.resetState();
          }}
          sx={{
            mt: 0.6,
            height: 30,
          }}
        >
          <Typography fontWeight={500} fontSize={12}>
            Clear Filters
          </Typography>
        </M3Button>
      </Stack>
    );
  };

  const renderCardEmbed = ({
    title,
    html,
    isLoading,
    sx,
  }: RenderCardItemProps) => {
    const wrapperSX: SxProps = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      overflow: 'hidden',
      background: isDarkMode
        ? 'var(--md-sys-color-background-dark)'
        : '#f5f5f5',
    };
    return (
      <>
        {!!title && (
          <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
            {title}
          </Typography>
        )}
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            boxShadow: isDarkMode
              ? '0 0 0 1px rgba(255, 255, 255, 0.1)'
              : '0 0 0 1px #e0e0e0',
          }}
        >
          <Box
            sx={{
              ...wrapperSX,
              '&:before': {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
                position: 'absolute',
                background: 'rgba(255, 255, 255, 0.05)',
              },
            }}
          >
            <AbsoluteCenterBox>
              <CircularProgress size={24} />
            </AbsoluteCenterBox>
            {!!metabaseIFrame && (
              <Box sx={{ ...wrapperSX, ...sx }}>
                <BasicIframe src={metabaseIFrame.iframe} />
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    memberForm.resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace]);

  useEffect(() => {
    setResourcesParams({
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState.start, dateForm.formState.end]);

  if (
    !currentWorkspace ||
    currentWorkspace.name.startsWith('-') ||
    currentWorkspace.id === 'my-squad'
  ) {
    return (
      <>
        <DocumentTitle title='Resources' trailingTitle='Team' />
        <MainContainer
          ref={mainContainerRef}
          sx={{
            maxWidth: null,
            p: 2,
            pt: 1.5,
            position: 'relative',
            height: `calc(100vh - ${subToolbarHeight}px)`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ErrorPage
            title='Not Found'
            message='This view is not available for Go2 members that are not in partner circle'
          />
        </MainContainer>
      </>
    );
  }

  return (
    <>
      <DocumentTitle title='Resources' trailingTitle='Team' />
      <MainContainer
        ref={mainContainerRef}
        sx={{
          maxWidth: null,
          p: 2,
          pt: 1.5,
          position: 'relative',
          height: `calc(100vh - ${subToolbarHeight}px)`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box width='100%'>
          <AppActionToolbar>
            <FilterColumnPanel
              name='date'
              label='Date'
              displayValue={`${moment(dateForm.formState.start).format(
                'MMM D, YYYY',
              )} - ${moment(dateForm.formState.end).format('MMM D, YYYY')}`}
              sx={{ ml: -1 }}
              paperSx={{ ml: 1 }}
              textFieldSx={{ width: 240 }}
            >
              <DateRangeSelectionPopover
                startDate={dateForm.formState.start}
                endDate={dateForm.formState.end}
                onChange={({ start, end }) => {
                  dateForm.updateState((state) => ({ ...state, start, end }));
                }}
              />
            </FilterColumnPanel>
            <AutocompleteSelectionPopover
              width={240}
              title='Members'
              placeholder='Select members'
              options={
                currentWorkspace?.id === 'my-squad'
                  ? currentWorkspace?.members!.map((member) => ({
                      id: member.id,
                      value: member.name,
                    }))
                  : divisionGo2MembersData.map((m) => ({
                      id: m.id,
                      value: m.name,
                    }))
              }
              form={memberForm}
            />
          </AppActionToolbar>
          {renderFilterResults()}
        </Box>
        <Box
          sx={{
            height: 0,
            flexGrow: 2,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              top: -10,
              left: -16,
              right: -16,
              bottom: -16,
              position: 'absolute',
            }}
          >
            {renderCardEmbed({
              html: dashboardHTML,
              isLoading: dashboardIsLoading,
            })}
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default TeamResources;
