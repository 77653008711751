import React from 'react';
import { Box, Modal, Typography } from '@mui/material';

import ModalCardView from '../ModalCardView';

type TimezoneModalProps = {
  onClose: () => void;
  open: boolean;
};

const TimezoneChangeModal: React.FC<TimezoneModalProps> = ({
  onClose,
  open,
}) => {
  const renderTopPanel = () => {
    return (
      <Typography
        component='div'
        variant='h6'
        fontWeight={500}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box display='flex' alignItems='center'>
          Timezone Change
        </Box>
      </Typography>
    );
  };

  return (
    <Modal open={open}>
      <ModalCardView
        sx={{ maxWidth: 720 }}
        header={renderTopPanel()}
        headerSx={{ pt: 2, pb: 2 }}
      >
        <Typography component='div' fontSize={18} pt={3}>
          We've noticed a modification in your timezone settings. To avoid
          potential data discrepancies, we generally advise against altering
          these settings. However, if the updated timezone is not displaying
          accurately, please close and reopen your browser to apply the changes.
          This will help ensure an optimal user experience.
        </Typography>
      </ModalCardView>
    </Modal>
  );
};

export default TimezoneChangeModal;
