import React from 'react';

import SettingsTeamAllJobs from './SettingsTeamAllJobs';
import SettingsTeamJobsCreateJob from './SettingsTeamJobsCreateJob';
import SettingsTeamJobDetail from './SettingsTeamJobDetail';
import MainContainer from '../components/MainContainer';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import { useHasViewPermission } from '../components/HasViewPermission';

import { usePathnameSegments } from '../hooks/utils/url';

type SettingsTeamJobsProps = {};

export type SettingsTeamJobsSegments = 'create';

const SettingsTeamJobs = (props: SettingsTeamJobsProps) => {
  const [, , secondSegment, thirdSegment] =
    usePathnameSegments<SettingsTeamJobsSegments[]>();

  const hasViewPermission = useHasViewPermission({
    roles: ['superuser', 'internal', 'partner_member', 'path_beta', 'guest'],
  });

  if (!hasViewPermission) {
    return (
      <MainContainer>
        <ErrorUnauthorizedAccess />
      </MainContainer>
    );
  }

  if (secondSegment === 'create' || thirdSegment === 'create') {
    return <SettingsTeamJobsCreateJob routeKey='builder' />;
  } else if (!!(secondSegment as string)) {
    return <SettingsTeamJobDetail routeKey='builder' />;
  }

  return <SettingsTeamAllJobs routeKey='builder' />;
};

export default SettingsTeamJobs;
