import React from 'react';
import { Navigate } from 'react-router-dom';

import SystemAdministratorCustom from './SystemAdministratorCustom';

import { usePathnameSegments } from '../hooks/utils/url';

type SystemAdministratorProps = {};

export type SystemAdministratorSegments = 'custom';

const SystemAdministrator = (props: SystemAdministratorProps) => {
  const [, , nextSegment] =
    usePathnameSegments<SystemAdministratorSegments[]>();

  if (nextSegment === 'custom') {
    return <SystemAdministratorCustom />;
  }

  return <Navigate to='/settings/system-admin/custom' />;
};

export default SystemAdministrator;
