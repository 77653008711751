import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Link,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
  SxProps,
  Skeleton,
} from '@mui/material';
import moment, { Moment } from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

import CommentsList from './CommentsList';
import { feelingSelection } from './SodEodForm/EODForm';
import { M3Card } from './M3/M3Card';
import { M3Fab } from './M3/M3Fab';
import { M3ButtonToned } from './M3/M3Button';
import TaskDetailModalView from './TaskDetailModalView';
import MyDayCardPulsating from './MyDayCardPulsating';
import M3SurfaceContainer from './M3/M3SurfaceContainer';
import UserProfilePopover from './UserProfilePopover';
import UserChipStatus from './UserProfile/UserChipStatus';
import { ShipmentLinkItem } from './SodEodForm/EODShipLinkForm';
import MeritBadge from './MeritBadge';
import RichTextContent from './RichEditor/RichTextContent';

import {
  DailyReportEODData,
  DailyReportEODDataKPI,
  DailyReportSODData,
} from '../types/report';
import { dgs, toTitleCase } from '../utils/string';
import { ContentTypeID } from '../types/content-type';
import { useIsCardFocused } from '../hooks/utils/card';
import { UserProfile } from '../types/profile';
import { useUserProvider } from '../providers/user/user';
import {
  getUserInitials,
  getUserPhotoUrl,
  getUserReportBgUrl,
} from '../utils/user';
import { useImageIsLoaded } from '../hooks/utils/img';
import { DailyReportItemExpanded } from '../utils/report';
import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';
import { useComments } from '../hooks/comment';
import { useOnScreen } from '../hooks/global/useOnScreen';
import { useHasElapsed } from '../hooks/utils/misc';
import { isNotNullAndUndefined } from '../utils/object';

type MyDayCardProps = {
  index?: number;
  openDefault?: boolean;
  withToggle?: boolean;
  withComments?: boolean;
  contentType: ContentTypeID;
  item: DailyReportItemExpanded;
  currentProfile?: UserProfile;
};

type QuestionAnswerRow = {
  question: ReactRenderElement;
  answer: ReactRenderElement;
  error?: boolean;
};
type CardContentParams = {
  visible?: boolean;
  time?: ReactRenderElement;
  title: ReactRenderElement;
  question: ReactRenderElement;
  answer: ReactRenderElement;
  questionAnswer: (QuestionAnswerRow | null)[];
};

const MyDayCard = ({
  index,
  openDefault,
  withToggle = true,
  withComments = true,
  contentType,
  item,
  currentProfile,
}: MyDayCardProps) => {
  const [open, setOpen] = useState(openDefault ?? false);
  const isCardFocused = useIsCardFocused();
  const { getUser } = useUserProvider();
  const imageIsLoaded = useImageIsLoaded();
  const { isDarkMode } = useAppProvider();
  const [isTaskDetailOpen, setIsTaskDetailOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState<DailyReportEODDataKPI | null>(
    null,
  );

  const { sod, eod } = useMemo(() => {
    return {
      sod: item.reports.find((report) => report.type === 'SOD'),
      eod: item.reports.find((report) => report.type === 'EOD'),
    };
  }, [item]);
  const sodData = sod?.data as DailyReportSODData | null;
  const eodData = eod?.data as DailyReportEODData | null;

  const cardWrapperRef = useRef(null);
  const refetchInterval = 120 * 1000;
  const hasElapsed = useHasElapsed(refetchInterval);
  /**
   * Need to only fetch comments when it's near on the visible viewport
   * to maximize performance and preventing calls not needed
   */
  const isNearVisibleViewport = useOnScreen(cardWrapperRef);
  const comments = useComments(
    {
      limit: 1,
      page_size: 1,
      content_type: contentType,
      object_id: item.object_id,
      key: `team_manifest_comments_counter_${item.object_id}`,
    },
    {
      refetchInterval,
      enabled: false,
    },
  );
  const totalComments = comments?.data?.count ?? 0;

  const user = getUser('employee_id', item.submitted_by.id);

  const reportBackgroundImage =
    user?.settings?.report_background_image ??
    item.submitted_by.report_background_image;
  const hasReportBackgroundImage = !!reportBackgroundImage;
  const reportBackgroundColor =
    user?.settings?.misc?.report_background_color ??
    item.submitted_by.report_background_color;

  const needHelp = eodData?.overview?.need_help === 'yes';
  const ditGetWin = eodData?.overview?.did_get_win === 'yes';
  const didYouShip = eodData?.overview.did_you_ship_anything === 'yes';

  const sodSubmitted = sod ? moment.utc(sod.created).local() : null;
  const eodSubmitted = eod ? moment.utc(eod.created).local() : null;
  const reportDate: Moment = (sodSubmitted || eodSubmitted)!.clone();

  let didMissReport: { message: string } | null = null;
  if (!sodSubmitted || !eodSubmitted) {
    const now = moment();
    if (user) {
      /**
       * Check if plan was submitted but no reality, and it passed more than 20 hours
       * https://github.com/go2impact/s-delegation/issues/12
       */
      if (
        sodSubmitted &&
        !eodSubmitted &&
        now.diff(sodSubmitted, 'hours') >= 20
      ) {
        didMissReport = {
          message: `${user.first_name} missed the Reality`,
        };
      } else if (
        !sodSubmitted &&
        eodSubmitted &&
        now.diff(eodSubmitted, 'hours') >= 20
      ) {
        didMissReport = {
          message: `${user.first_name} missed the Plan`,
        };
      }
    }
  }

  const sodQuestions = isSodNewQuestions(sodData)
    ? getSodNewQuestions(sodData)
    : getSodOldQuestions(sodData);
  const eodQuestions = isEodNewQuestions(eodData)
    ? getEodNewQuestions(eodData)
    : getEodOldQuestions(eodData);

  function onFetchingCommentsCount() {
    if (
      isNearVisibleViewport &&
      !comments.isLoading &&
      !!item.object_id &&
      withComments
    ) {
      hasElapsed.reset();
      comments.refetch();
    }
  }

  const renderMeritIcon = ({
    tooltip,
    icon,
  }: {
    tooltip: string;
    icon: ReactRenderElement;
  }) => {
    return (
      <Tooltip title={tooltip}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          pl={0.3}
          pr={0.3}
        >
          {icon}
        </Box>
      </Tooltip>
    );
  };

  const renderTopPanel = () => {
    return (
      <Stack
        gap={1}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mb: 2, position: 'relative' }}
      >
        <Paper
          sx={{
            pl: 2,
            pr: 2,
            mr: 1,
            height: 80,
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            boxShadow:
              hasReportBackgroundImage && !imageIsLoaded.isLoaded
                ? isDarkMode
                  ? `inset 0 0 0px 1px #0f0f0f`
                  : `inset 0 0 0px 1px #f0f0f0`
                : undefined,
            background: hasReportBackgroundImage
              ? isDarkMode
                ? 'rgba(0, 0, 0, 0.8)'
                : 'rgba(255, 255, 255, 0.8)'
              : isDarkMode
              ? 'rgba(0, 0, 0, 0.8)'
              : 'rgba(255, 255, 255, 1)',
            backdropFilter: hasReportBackgroundImage ? `blur(5px)` : undefined,
          }}
        >
          <Box
            sx={{
              ml: 1,
              mr: 2,
              width: 40,
              height: 40,
            }}
          >
            <UserProfilePopover user={user!}>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                }}
                src={getUserPhotoUrl(user?.photo_url)}
              >
                {
                  getUserInitials(user?.preferred_name ?? user?.first_name)
                    .initial
                }
              </Avatar>
            </UserProfilePopover>
          </Box>
          <Box sx={{ mr: 2 }}>
            <Typography
              component='div'
              fontSize={20}
              fontWeight={500}
              minWidth={70}
              minHeight={30}
            >
              {user ? (
                user.preferred_name || user.first_name
              ) : (
                <Skeleton variant='text' sx={{ height: 26 }} />
              )}
            </Typography>
            <Stack
              gap={1}
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
            >
              <UserChipStatus
                status={
                  didMissReport
                    ? 'Missed'
                    : item.user_health.visible
                    ? item.user_health.status
                    : null
                }
                background={
                  didMissReport
                    ? isDarkMode
                      ? 'var(--md-ref-palette-error60)'
                      : 'var(--md-ref-palette-error40)'
                    : item.user_health.color
                }
              />
              {item.user_health.showHours && (
                <Typography component='div' fontSize={11} fontWeight={700}>
                  {item.user_health.hours}h
                </Typography>
              )}
            </Stack>
          </Box>
          <Divider
            flexItem
            orientation='vertical'
            variant='middle'
            sx={{ opacity: 0.5, mt: 1.5, mb: 1.5 }}
          />
          <Typography
            noWrap
            component='h4'
            fontSize={20}
            fontWeight={500}
            sx={{ pl: 4, pr: 4 }}
          >
            My Day: {reportDate.format('MMMM Do')}
          </Typography>
        </Paper>
        <Stack direction='row' gap={1} alignItems='center' flexWrap='nowrap'>
          {ditGetWin &&
            renderMeritIcon({
              tooltip: `${user?.first_name} got the Win`,
              icon: <MeritBadge type='win' />,
            })}
          {didYouShip &&
            renderMeritIcon({
              tooltip: `${user?.first_name} shipped something`,
              icon: <MeritBadge type='ship' />,
            })}
        </Stack>
        <Box flex={1} display='flex' justifyContent='flex-end'>
          <Typography
            display='flex'
            fontSize={16}
            fontWeight={500}
            alignItems='center'
            component='div'
            sx={{
              p: 2,
              mr: withToggle ? 4 : 0,
              borderRadius: 2,
              whiteSpace: 'nowrap',
              color: 'var(--md-ref-palette-neutral30)',
              background: hasReportBackgroundImage
                ? isDarkMode
                  ? 'rgba(0, 0, 0, 0.8)'
                  : 'rgba(255, 255, 255, 0.8)'
                : isDarkMode
                ? 'rgba(0, 0, 0, 0.8)'
                : 'rgba(255, 255, 255, 1)',
              backdropFilter: hasReportBackgroundImage
                ? `blur(5px)`
                : undefined,
            }}
          >
            <CommentOutlinedIcon sx={{ fontSize: 20, mr: 1, opacity: 0.5 }} />
            {dgs(totalComments)} Comment{totalComments > 1 ? 's' : ''}
          </Typography>
          {withToggle && (
            <M3Fab
              elevated={false}
              sx={{
                backgroundColor: isDarkMode
                  ? 'rgba(0, 0, 0, 0.8) !important'
                  : '#fff',
              }}
              onClick={() => setOpen(!open)}
            >
              <KeyboardArrowDownIcon
                sx={{
                  transform: `rotateZ(${open ? '180deg' : '0deg'})`,
                }}
                style={{
                  fontSize: 32,
                }}
              />
            </M3Fab>
          )}
        </Box>
      </Stack>
    );
  };

  const renderCardContent = ({
    visible = true,
    time,
    title,
    question,
    answer,
    questionAnswer,
  }: CardContentParams) => {
    if (!visible) return null;

    const answerSx: SxProps = {
      pt: 0.2,
    };

    return (
      <Box
        sx={{
          background: hasReportBackgroundImage
            ? isDarkMode
              ? 'rgba(0, 0, 0, 0.8)'
              : 'rgba(255, 255, 255, 0.8)'
            : isDarkMode
            ? 'rgba(0, 0, 0, 0.8)'
            : 'rgba(255, 255, 255, 1)',
          position: 'relative',
          backdropFilter: hasReportBackgroundImage ? `blur(5px)` : undefined,
        }}
        style={{
          maxHeight: open ? undefined : 70,
          overflow: 'hidden',
          borderRadius: '10px',
          boxShadow:
            hasReportBackgroundImage && !imageIsLoaded.isLoaded
              ? isDarkMode
                ? `inset 0 0 0px 1px #0f0f0f`
                : `inset 0 0 0px 1px #f0f0f0`
              : undefined,
        }}
        className='my-day-card-content'
      >
        <Stack
          direction='row'
          alignItems='flex-start'
          justifyContent='flex-start'
        >
          <Typography
            display='flex'
            alignItems='center'
            justifyContent='center'
            component='div'
            variant='h4'
            fontSize={22}
            fontWeight={500}
            sx={{
              height: 70,
              width: 140,
              minWidth: 140,
              color: '#fff',
              background: 'var(--md-ref-palette-tertiary40)',
              borderBottomRightRadius: open ? 10 : 0,
            }}
          >
            {title}
          </Typography>
          <Box
            flex={1}
            display={open ? undefined : 'flex'}
            alignItems='flex-start'
            justifyContent='flex-start'
            sx={{ pl: 3, pr: 3, pt: 2.6 }}
          >
            <Typography
              component='div'
              fontWeight={500}
              fontSize={16}
              sx={{
                pr: 1,
                pb: 0.5,
                whiteSpace: 'nowrap',
              }}
            >
              {question}
            </Typography>
            <Typography
              component='div'
              fontSize={16}
              color={isDarkMode ? '#9a9a9a !important' : '#43474E'}
              sx={open ? answerSx : null}
            >
              {typeof answer === 'string' && answer ? (
                <RichTextContent
                  content={answer}
                  domPurifyOptions={open ? {} : { ALLOWED_TAGS: [] }}
                />
              ) : answer ? (
                answer
              ) : (
                <div style={{ opacity: 0.3 }}>—</div>
              )}
            </Typography>
          </Box>
          <Typography
            component='span'
            fontWeight={500}
            fontSize={14}
            textAlign='right'
            lineHeight={1.3}
            sx={{ pr: 3, mt: 3.2, opacity: 0.6 }}
          >
            {time}
          </Typography>
        </Stack>
        {open && (
          <Box style={{ paddingLeft: 140 }}>
            {(
              (questionAnswer ?? []).filter(
                (qa: QuestionAnswerRow | null) => !!qa,
              ) as QuestionAnswerRow[]
            ).map((qa: QuestionAnswerRow, i: number) => {
              return (
                <Box position='relative' key={i}>
                  {qa.error && (
                    <Box
                      sx={{
                        top: 0,
                        right: 0,
                        bottom: -1,
                        left: -140,
                        position: 'absolute',
                        pointerEvents: 'none',
                        background: isDarkMode
                          ? 'var(--md-ref-palette-error10)'
                          : 'var(--md-ref-palette-error90)',
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      pl: 3,
                      pr: 3,
                      pb: 2.5,
                    }}
                    position='relative'
                  >
                    <Divider sx={{ opacity: 0.5, mt: i === 0 ? 2 : 0 }} />
                    <Typography
                      component='div'
                      fontWeight={500}
                      fontSize={16}
                      sx={{ pr: 1, pt: 2 }}
                    >
                      {qa.question}
                    </Typography>
                    <Typography
                      component='div'
                      fontSize={16}
                      color={isDarkMode ? '#9a9a9a !important' : '#43474E'}
                      sx={answerSx}
                    >
                      {typeof qa.answer === 'string' && qa.answer ? (
                        <RichTextContent content={qa.answer} />
                      ) : qa.answer ? (
                        qa.answer
                      ) : (
                        <div style={{ opacity: 0.3 }}>—</div>
                      )}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    );
  };

  const renderComment = () => {
    const tasks: DailyReportEODDataKPI[] = eodData?.kpis ?? [];

    return (
      <Box
        sx={{
          p: 3,
          pb: withComments ? 3 : 1,
          position: 'relative',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
          border: `1px solid ${
            isDarkMode
              ? 'var(--md-ref-palette-neutral20)'
              : 'var(--md-ref-palette-neutral90)'
          }`,
          borderTop: 0,
        }}
      >
        {!!tasks.length && (
          <Stack
            direction='row'
            flexWrap='wrap'
            gap={1}
            sx={{ pl: 1, pr: 1, pb: 2 }}
          >
            {tasks.map((task: DailyReportEODDataKPI, i) => {
              const pct = `${((task.pct_time_spent || 0) * 100).toFixed(2)}`;
              if (pct === '0.00') {
                return null;
              }
              return (
                <M3ButtonToned
                  key={i}
                  onClick={() => {
                    setCurrentTask(task);
                    setIsTaskDetailOpen(true);
                  }}
                >
                  <WysiwygOutlinedIcon />
                  {task.task_name || task.taskName}
                  <span style={{ opacity: 0.5, padding: '0 8px' }}>-</span>
                  <span>{pct}%</span>
                </M3ButtonToned>
              );
            })}
          </Stack>
        )}
        {withComments && (
          <CommentsList
            currentProfile={currentProfile}
            contentType={contentType}
            objectId={item.object_id}
            commentSx={{
              boxSx: {
                pt: 0,
              },
            }}
            formSx={{
              boxSx: {
                ml: -3,
                mr: -3,
                mb: -3,
                pt: 0,
                pb: 0,
              },
              inputSx: {
                backgroundColor: '#fff',
              },
            }}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
            onSuccess={onFetchingCommentsCount}
            onCommentSuccess={onFetchingCommentsCount}
          />
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (isNearVisibleViewport && hasElapsed.elapsed) {
      onFetchingCommentsCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearVisibleViewport, hasElapsed.elapsed]);

  return (
    <Box
      ref={cardWrapperRef}
      sx={{
        mb: 2,
        position: 'relative',
      }}
    >
      {needHelp && <MyDayCardPulsating />}
      <M3Card
        focused={isCardFocused.focused}
        sx={{
          overflow: 'initial',
          position: 'relative',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        <M3SurfaceContainer
          elevation={3}
          sx={{
            overflow: 'hidden',
            borderRadius: 3,
            ...(open
              ? {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }
              : null),
          }}
          wrapperSx={{
            background: needHelp ? 'transparent' : undefined,
          }}
        >
          <Box
            sx={{
              p: 4,
              position: 'relative',
            }}
          >
            {(hasReportBackgroundImage || !!reportBackgroundColor) && (
              <Box
                component={hasReportBackgroundImage ? 'img' : 'div'}
                src={getUserReportBgUrl(reportBackgroundImage)}
                sx={{
                  top: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: `100%`,
                  position: 'absolute',
                  objectFit: 'cover',
                  objectPosition: 'top center',
                  pointerEvents: 'none',
                  background: reportBackgroundColor,
                }}
                onLoad={imageIsLoaded.onLoad}
              />
            )}
            {renderTopPanel()}
            <Box
              sx={{
                '.my-day-card-content': {
                  mb: 2,
                  '&:last-child': {
                    mb: 0,
                  },
                },
              }}
            >
              {renderCardContent({
                visible: !!sodSubmitted,
                time: sodSubmitted
                  ? sodSubmitted.format('MMM Do, h:mma')
                  : null,
                title: 'Plan',
                question: sodQuestions?.question,
                answer: sodQuestions?.answer,
                questionAnswer: sodQuestions?.questionAnswer,
              })}
              {renderCardContent({
                visible: !!eodSubmitted,
                time: eodSubmitted
                  ? eodSubmitted.format('MMM Do, h:mma')
                  : null,
                title: 'Reality',
                question: eodQuestions?.question,
                answer: eodQuestions?.answer,
                questionAnswer: eodQuestions?.questionAnswer,
              })}
            </Box>
          </Box>
        </M3SurfaceContainer>
        {open && renderComment()}
        <Modal open={isTaskDetailOpen}>
          <TaskDetailModalView
            sodId={sod?.id}
            date={reportDate.format('YYYY-MM-DD')}
            user={user!}
            task={currentTask!}
            close={() => {
              setIsTaskDetailOpen(false);
            }}
          />
        </Modal>
      </M3Card>
    </Box>
  );
};

export default MyDayCard;

/**
 * SOD/EOD old questions handlers
 */
function isSodNewQuestions(sod: DailyReportSODData | null) {
  return typeof sod?.today_win !== 'undefined';
}

function getSodOldQuestions(sod: DailyReportSODData | null) {
  return {
    question: 'Top Priority:',
    answer: sod?.topPriority,
    questionAnswer: [
      {
        question: 'Tasks for Today:',
        answer: sod?.tasksForToday,
      },
    ],
  };
}

function getEodOldQuestions(eod: DailyReportEODData | null) {
  return {
    question: 'Headline: ',
    answer: eod?.overview.todayOverview,
    questionAnswer: [
      {
        question: `How did you feel about your day?`,
        answer: eod?.overview.howsToday && (
          <Typography fontSize={34} sx={{ mb: -1 }}>
            {
              feelingSelection.find((fs) => fs.id === eod?.overview.howsToday)
                ?.value
            }
          </Typography>
        ),
      },
      {
        question: 'Worth noting?',
        answer: eod?.overview.mostImportant,
      },
      {
        question: `Do you need help from your client, coworkers or SM to make tomorrow a productive day?*`,
        answer: toTitleCase(eod?.overview.instructions),
      },
      {
        question: `Next shift?`,
        answer: eod?.overview.nextShift,
      },
    ],
  };
}

/**
 * SOD/EOD new questions handlers
 */
function isEodNewQuestions(eod: DailyReportEODData | null) {
  return typeof eod?.overview?.today_headline !== 'undefined';
}

function getSodNewQuestions(sod?: DailyReportSODData | null) {
  return {
    question: 'What will make today a win?',
    answer: sod?.today_win,
    questionAnswer: [
      {
        question: 'Today’s area of focus and important targets?',
        answer: sod?.area_of_focus,
      },
      {
        question: 'Worth noting?',
        answer: sod?.worth_noting,
      },
    ],
  };
}

function getEodNewQuestions(eod?: DailyReportEODData | null) {
  const questionAnswer = [
    {
      question: `How did you feel about your day?`,
      answer: eod?.overview.feeling_selected && (
        <Typography fontSize={34} sx={{ mb: -1 }}>
          {
            feelingSelection.find(
              (fs) => fs.id === eod?.overview.feeling_selected,
            )?.value
          }
        </Typography>
      ),
    },
    {
      question: `Did you get your win?`,
      answer: toTitleCase(eod?.overview.did_get_win),
    },
    {
      question: `Tell us a little something about it`,
      answer: eod?.overview.did_get_win_about,
    },
    {
      question: `What else about today was newsworthy? Any accomplishments, learnings or escalations others should be aware of?`,
      answer: eod?.overview.news_worthy,
    },
    {
      question: `Whats a key focus for next shift?`,
      answer: eod?.overview.next_shift,
    },
    {
      question: `Do you need help from your manager to make the most of your workday tomorrow?`,
      answer: toTitleCase(eod?.overview.need_help),
      error: eod?.overview.need_help === 'yes',
    },
    eod?.overview.need_help === 'yes'
      ? {
          question: `Can you provide a little info on what you need help with?`,
          answer: eod?.overview.need_help_about,
          error: eod?.overview.need_help === 'yes',
        }
      : null,
  ];

  if (typeof eod?.overview.did_you_ship_anything !== 'undefined') {
    questionAnswer.push({
      question: `Did you ship anything today?`,
      answer: toTitleCase(eod?.overview.did_you_ship_anything),
    });

    if (eod?.overview.did_you_ship_anything === 'yes') {
      questionAnswer.push({
        question: `Tell us more about what did you ship today`,
        answer: eod?.overview.did_you_ship_anything_about,
      });
    }

    if (
      isNotNullAndUndefined(eod?.overview.shipment_links) &&
      eod?.overview.shipment_links?.length
    ) {
      questionAnswer.push({
        question: `Links of the shipment`,
        answer: (
          <>
            {eod?.overview.shipment_links?.map((shipment: ShipmentLinkItem) => {
              return (
                <Box pt={0.5} pb={0.5} key={shipment.id}>
                  <Link
                    target='_blank'
                    href={shipment.url}
                    className='m3-button-link'
                  >
                    {shipment.title}
                  </Link>
                </Box>
              );
            })}
          </>
        ),
      });
    }
  }

  return {
    question: 'Headline: ',
    answer: eod?.overview.today_headline,
    questionAnswer,
  };
}
