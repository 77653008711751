import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import MainContainer from '../components/MainContainer';
import RichEditor from '../components/RichEditor/RichEditor';
import BasicForm, { useForm } from '../components/BasicForm';
import { M3Button, M3IconButton } from '../components/M3/M3Button';
import IntercomBreadcrumbs, {
  BreadcrumbItem,
} from '../components/Intercom/IntercomBreadcrumbs';
import AppActionToolbar from '../components/AppActionToolbar';
import JobDescriptionMembers from '../components/TeamSettings/JobDescription/JobDescriptionMembers';
import JobDescriptionOSSelection from '../components/TeamSettings/JobDescription/JobDescriptionOSSelection';
import JobDescriptionTitleForm from '../components/TeamSettings/JobDescription/JobDescriptionTitleForm';
import WindowScrollTop from '../components/WindowScrollTop';
import DocumentTitle from '../components/DocumentTitle';

import { ReactRenderElement, ValueOf } from '../types/types';
import {
  useJobDescriptionAddRemoveMembers,
  useJobDescriptionById,
  usePostJobDescription,
  UsePostJobDescriptionParams,
} from '../hooks/jobs';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useUserProvider } from '../providers/user/user';
import { JobDescriptionItemResult } from '../types/job-description';
import { UserMetadata } from '../types/profile';
import { useHasViewPermission } from '../components/HasViewPermission';
import { stringToKey } from '../utils/string';
import AIAssistSideSheet, {
  useAIAssistSideSheet,
} from '../components/SideSheet/AIAssistSideSheet';
import { useCurrentProfile } from '../hooks/profile';

type SettingsTeamJobsCreateJobProps = {
  routeKey: 'builder' | 'team';
};

type JobDescriptionState = {
  title: string;
  division?: string | number;
  position_summary: string;
  principal_duties: string;
  other_responsibilities: string;
  success_indicator: string;
  members: MemberItem[];
};
type MemberItem = {
  id: number | string;
  label: string;
};

const SettingsTeamJobsCreateJob = ({
  routeKey,
}: SettingsTeamJobsCreateJobProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { items: workspaces } = useWorkspaceProvider();
  const { getUser } = useUserProvider();

  const { data: currentProfile } = useCurrentProfile();
  const isRestricted = currentProfile?.roles.some((role) => role === 'guest');

  // When creating a new job description, make the first item selected
  const defaultOS = workspaces.filter(
    (ws) => ws.type === 'division_partner',
  )[0];

  const { formState, handleChange, handleSubmit } =
    useForm<JobDescriptionState>({
      title: '',
      division: defaultOS?.id,
      position_summary: '',
      principal_duties: '',
      other_responsibilities: '',
      success_indicator: '',
      members: [],
    });

  // find the selected OS
  const selectedOS = workspaces.find((ws) => +ws.id === +formState.division!);

  const isPromptEngineer = useHasViewPermission({
    roles: ['superuser', 'prompt_engineer'],
  });
  const aiAssist = useAIAssistSideSheet();

  const postJobDescription = usePostJobDescription();

  const jobDescription = useJobDescriptionById(
    { id: postJobDescription.data?.id! },
    { enabled: false },
  );
  const addJDMembers = useJobDescriptionAddRemoveMembers({
    id: postJobDescription.data?.id!,
    segment: 'add-members',
  });

  const disableSubmit =
    postJobDescription.isLoading ||
    jobDescription.isLoading ||
    addJDMembers.isLoading ||
    !!!(
      formState.title &&
      formState.position_summary &&
      formState.principal_duties &&
      formState.other_responsibilities &&
      formState.success_indicator
    );

  const onEditorValueChange = (name: keyof JobDescriptionState) => {
    return (value: ValueOf<JobDescriptionState>) => {
      handleChange({
        target: {
          name,
          value,
        },
      });
    };
  };

  const onSubmit = handleSubmit((data: JobDescriptionState) => {
    const payload: UsePostJobDescriptionParams = {
      title: data.title,
      division: data.division,
      position_summary: data.position_summary,
      principal_duties: data.principal_duties,
      other_responsibilities: data.other_responsibilities,
      success_indicator: data.success_indicator,
    };
    postJobDescription.mutate(payload);
  });

  const onCreateSuccess = () => {
    const item = jobDescription.data as JobDescriptionItemResult;
    navigate(`${getRoutes().basePath}/${item.id}`, {
      replace: true,
    });
    enqueueSnackbar('New job description has been created!');
  };

  const getRoutes = () => {
    let breadCrumbs: BreadcrumbItem[] = [];
    let basePath = '';

    if (routeKey === 'builder') {
      basePath = `/builder/jobs`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Jobs',
          path: basePath,
        },
        {
          id: 'create',
          label: 'Create A Job',
          path: `${basePath}/create`,
        },
      ];
    } else if (routeKey === 'team') {
    }

    return {
      breadCrumbs,
      basePath,
    };
  };

  const renderRichEditor = ({
    icon,
    name,
    value,
    title,
    subtitle,
    placeholder,
  }: {
    icon: ReactRenderElement;
    name: keyof JobDescriptionState;
    value: ValueOf<JobDescriptionState>;
    title?: ReactRenderElement;
    subtitle?: ReactRenderElement;
    placeholder?: string;
  }) => {
    let promptIdentifier = stringToKey(`job-description-${name}`, '-');

    return (
      <Box mb={5}>
        {!!title && (
          <Typography
            display='flex'
            alignItems='center'
            component='div'
            fontSize={22}
          >
            <Box position='relative' top={4} pr={1}>
              {icon}
            </Box>
            {title}
          </Typography>
        )}
        {!!subtitle && (
          <Typography component='div' sx={{ opacity: 0.5 }}>
            {subtitle}
          </Typography>
        )}
        <RichEditor
          withAIAssist
          withAIEditPrompt={isPromptEngineer}
          value={value as string}
          onValueChange={onEditorValueChange(name)}
          placeholder={placeholder ?? 'Write here...'}
          sx={{
            marginTop: 2,
          }}
          chatGPTPlaygroundProps={{
            title: title as string,
            prompt_identifier: promptIdentifier,
          }}
          onAssistClick={() => {
            aiAssist?.open();
            aiAssist?.start({
              title: title as string,
              prompt_identifier: promptIdentifier,
              data: {
                prompt_text: value as string,
              },
            });
          }}
        />
      </Box>
    );
  };

  const renderJobDescriptionDetailForm = () => {
    return (
      <>
        <Stack gap={2} direction='row' justifyContent='space-between' mb={1}>
          <Box flex={1}>
            <JobDescriptionTitleForm
              editable
              withSave={false}
              onChange={(value: string) => {
                handleChange({
                  target: {
                    name: 'title',
                    value,
                  },
                });
              }}
            />
            <JobDescriptionOSSelection
              editable
              showWarning
              divisionId={selectedOS?.id}
              onSelect={(opt) => {
                handleChange({
                  target: {
                    name: 'division',
                    value: opt.id,
                  },
                });
              }}
            />
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <JobDescriptionMembers
              editable
              divisionId={formState.division}
              onMembersChanged={(members) => {
                handleChange({
                  target: {
                    name: 'members',
                    value: members as unknown as MemberItem,
                  },
                });
              }}
            />
          </Box>
        </Stack>
        {/* <Typography component='div' fontSize={13} sx={{ opacity: 0.5 }}>
          Last Edited: Mar 21, 2023
        </Typography> */}
      </>
    );
  };

  /**
   * After successfully posting and fetching the latest job description and
   * no new members added. Navigate to the newly created item.
   */
  useEffect(() => {
    if (postJobDescription.isSuccess) {
      const users: UserMetadata[] = formState.members
        .map((member) => getUser('user_id', member.id) as UserMetadata)
        .filter((user) => !!user);

      // check if there's members to be added before we navigate them
      if (users.length) {
        addJDMembers.mutate({
          staff_ids: users.map((user) => user.staff_id),
        });
      } else {
        jobDescription.refetch();
      }
    }
    // eslint-disable-next-line
  }, [formState, postJobDescription.isSuccess, enqueueSnackbar]);

  useEffect(() => {
    if (addJDMembers.isSuccess) {
      jobDescription.refetch();
    }
    // eslint-disable-next-line
  }, [addJDMembers.isSuccess]);
  /**
   * After adding the members into the newly created job description
   * execute to redirect the user
   */
  useEffect(() => {
    if (jobDescription.isSuccess) {
      onCreateSuccess();
    }
    // eslint-disable-next-line
  }, [jobDescription.isSuccess]);

  if (isRestricted) {
    return <Navigate to='/builder/jobs' />;
  }

  return (
    <>
      <DocumentTitle
        title='Create a Job'
        trailingTitle='Jobs | Team Settings'
      />
      <AIAssistSideSheet
        inPage
        withToolbar
        withSubToolbar
        title={aiAssist.title}
        parentSelector='body'
        containerSelector='body'
        headerSx={{
          height: 'initial',
        }}
        aiAssist={aiAssist}
        onClose={aiAssist.close}
      >
        <MainContainer flex sx={{ maxWidth: null }}>
          <AppActionToolbar>
            <M3IconButton
              onClick={() => {
                navigate(getRoutes().basePath);
              }}
              className='m3-icon-button-back-detail-button'
            >
              <ArrowBackIcon />
            </M3IconButton>
          </AppActionToolbar>
          <IntercomBreadcrumbs items={getRoutes().breadCrumbs} />
          <br />
          <BasicForm onSubmit={onSubmit} disabled={disableSubmit}>
            {renderJobDescriptionDetailForm()}
            <br />
            <br />
            {renderRichEditor({
              icon: <BookmarkBorderOutlinedIcon />,
              name: 'position_summary',
              value: formState.position_summary,
              title: 'Position Summary:',
              subtitle:
                'This is a brief, 2-to-3 sentence section that summarizes position.',
            })}
            {renderRichEditor({
              icon: <PlaylistAddCheckOutlinedIcon />,
              name: 'principal_duties',
              value: formState.principal_duties,
              title: 'Principal Duties and Responsibilities:',
              subtitle:
                'Key tasks and obligations that define the success of this role.',
            })}
            {renderRichEditor({
              icon: <ExtensionOutlinedIcon />,
              name: 'other_responsibilities',
              value: formState.other_responsibilities,
              title: 'Other Duties and Responsibilities:',
              subtitle:
                'This section contains responsibilities and important duties performed occasionally or in addition to the essential functions of the position. The above statement is on all job descriptions in this section.',
            })}
            {renderRichEditor({
              icon: <AutoAwesomeOutlinedIcon />,
              name: 'success_indicator',
              value: formState.success_indicator,
              title: 'What Does Success Look Like:',
              subtitle:
                'Write a few sentences describing the performance, attributes, and attitude of an imaginary person who is a successful person in this role.',
            })}
            <Stack direction='row' justifyContent='space-between'>
              <M3Button
                color='primary'
                variant='contained'
                onClick={onSubmit}
                disabled={disableSubmit}
                sx={{ minWidth: 110 }}
              >
                Submit
              </M3Button>
            </Stack>
          </BasicForm>
        </MainContainer>
      </AIAssistSideSheet>
      <WindowScrollTop />
    </>
  );
};

export default SettingsTeamJobsCreateJob;
