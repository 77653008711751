import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import TemporaryChangeRequestScheduleListItem from './TemporaryChangeRequestScheduleListItem';

import {
  UseBatchChangeRequestSchedulesProps,
  useBatchChangeRequestSchedules,
} from '../../hooks/go2-schedules';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  BatchChangeScheduleItem,
  ChangeRequestScheduleItem,
} from '../../types/schedules';
import { IterableObject } from '../../types/types';
import { useUserProvider } from '../../providers/user/user';

type Props = {};

const TemporaryChangeRequestScheduleList = (props: Props) => {
  const { setUniqueIdsToFetch } = useUserProvider();
  const [actionTaken, setActionTaken] = useState<IterableObject<boolean>>({});

  const infinite = useInfinite<
    BatchChangeScheduleItem,
    UseBatchChangeRequestSchedulesProps
  >({
    useQuery: useBatchChangeRequestSchedules,
    queryParams: {
      key: 'temporary_batch_change_schedules',
      is_temporary: true,
      status: 'pending',
      limit: 100,
    },
    isFlatResult: true,
    filterIterator(item: ChangeRequestScheduleItem) {
      return !(item.id in actionTaken);
    },
  });

  const handleOnTakeActionSuccess = useCallback(
    (item: BatchChangeScheduleItem) => {
      setActionTaken((state) => ({ ...state, [item.id]: true }));
    },
    [setActionTaken],
  );

  useEffect(() => {
    infinite.reset();
    // eslint-disable-next-line
  }, [actionTaken]);

  useEffect(() => {
    const user_ids = infinite.data
      .map((item) => [item.user, item.submitted_by])
      .flat();

    setUniqueIdsToFetch({ user_ids });
    // eslint-disable-next-line
  }, [infinite.data]);

  return (
    <Box pt={1}>
      {infinite.data.map((item, index) => {
        return (
          <TemporaryChangeRequestScheduleListItem
            key={item.id}
            withCancelButton
            item={item}
            isFirst={index === 0}
            isLast={index === infinite.data.length - 1}
            onTakeActionSuccess={handleOnTakeActionSuccess}
          />
        );
      })}
    </Box>
  );
};

export default TemporaryChangeRequestScheduleList;
