import React, { useState } from 'react';
import moment from 'moment';
import { Box, Stack, Typography } from '@mui/material';

import DocumentTitle from '../components/DocumentTitle';
import WindowScrollTop from '../components/WindowScrollTop';
import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import { useHasViewPermission } from '../components/HasViewPermission';
import PTORequestForm, {
  RequestFormParams,
} from '../components/Request/PTORequestForm';
import InfiniteScrollListener from '../components/InfiniteScrollListener';
import AutoApprove from '../components/Request/AutoApprove';
import TimeOffAccrualList from '../components/Request/TimeOffAccrualList';
import TimeOffWhosOut from '../components/Request/TimeOffWhosOut';
import TimeOffRequestListItems from '../components/Request/TimeOffRequestListItems';

import { useAppProvider } from '../providers/app/app';
import { useCurrentProfile } from '../hooks/profile';
import { useUserProvider } from '../providers/user/user';
import { useIsPartnerMember } from '../hooks/utils/user';
import { Navigate } from 'react-router-dom';
import { useWorkspaceProvider } from '../providers/workspace/workspace';

type Props = {};

const RequestTimeOff = (props: Props) => {
  const { getActionKeyCounter, updateActionKeyCounter } = useAppProvider();
  const { getUser } = useUserProvider();
  const { current: currentWorkspace } = useWorkspaceProvider();

  const [formState, setFormState] = useState<Partial<RequestFormParams>>({
    start: null,
    end: null,
    type: null,
  });

  const { data: currentProfile } = useCurrentProfile();
  const currentUser = getUser('user_id', currentProfile!.id) ?? currentProfile;
  const staffId = `${currentProfile!.staff_id ?? currentUser?.staff_id ?? ''}`;
  const userId = currentProfile!.id;

  const isPartnerMember = useIsPartnerMember();

  const hasAutoApproveRights = useHasViewPermission({
    roles: ['superuser', 'developer', 'partner_member', 'escalation_manager'],
  });

  if (isPartnerMember) {
    return <Navigate to={`/manifest/${currentWorkspace?.id}`} />;
  }

  return (
    <>
      <DocumentTitle title='Time Off' trailingTitle='Request' />
      <WindowScrollTop />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppActionToolbar>
          <Stack direction='row' gap={2} alignItems={'center'}>
            <Typography component='span' fontSize={22}>
              Time Off
            </Typography>
          </Stack>
          <Typography flex={1}></Typography>
          {/* Let's render this only if there's a staff id and the user has auto-approve rights */}
          {hasAutoApproveRights && staffId && (
            <Stack direction='row' gap={2} alignItems={'center'}>
              <AutoApprove staffId={staffId} />
            </Stack>
          )}
        </AppActionToolbar>
        <Box>
          <Box>
            <TimeOffAccrualList
              staffId={staffId}
              requestTypeId={formState.type}
            />
          </Box>
          <Box>
            <PTORequestForm
              staffId={staffId}
              key={getActionKeyCounter('pto_request_submission')}
              onStateUpdated={(fs: Partial<RequestFormParams>) => {
                setFormState(fs);
              }}
              onSuccess={() => {
                setFormState({
                  start: null,
                  end: null,
                });
                updateActionKeyCounter('pto_request_submission');
              }}
            />
            <br />
            <TimeOffWhosOut
              exactDate
              title={
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                  height={24}
                >
                  <Box style={{ color: 'inherit' }}>Who's out when?</Box>
                  <Typography fontSize={14} pt={0.5} sx={{ opacity: 0.8 }}>
                    {[
                      formState.start
                        ? moment(formState.start).format('MMM D')
                        : null,
                      formState.end || formState.start
                        ? moment(formState.end || formState.start).format(
                            'MMM D',
                          )
                        : null,
                    ]
                      .filter((v) => !!v)
                      .join(' - ')}
                  </Typography>
                </Stack>
              }
              start={formState.start}
              end={formState.end || formState.start}
              userId={userId}
              user={currentUser}
            />
            <br />
          </Box>
        </Box>
        <TimeOffRequestListItems
          editable
          staffId={staffId}
          title='Upcoming Time Off'
        />
        <br />
        <br />
        <br />
        <br />
      </MainContainer>
      <InfiniteScrollListener onReachBottom={() => {}} />
    </>
  );
};

export default RequestTimeOff;
