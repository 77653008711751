import { Box, Divider, Stack, Tabs, Typography } from '@mui/material';
import React, { SyntheticEvent, forwardRef, useEffect, useState } from 'react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3Button } from '../M3/M3Button';
import { useForm } from '../BasicForm';
import RichTextContent from '../RichEditor/RichTextContent';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../Dialogs/ConfirmationDialog';
import { M3TabButton } from '../M3/M3TabButton';

import { CommentEditForm } from '../CommentsList';
import { CommentItem } from '../../types/comment';
import { useDeleteComment, useUpdateComment } from '../../hooks/comment';
import { useAppProvider } from '../../providers/app/app';

type Props = ModalCardViewCloseProps & {
  message: CommentItem;
  onEditSuccess?: () => void;
  onDeleteSuccess?: (id: number) => void;
};

type EditChatFormState = {
  content: string;
};

export function AIAIAIChatEditFormViewBase({
  message,
  close,
  onEditSuccess,
  onDeleteSuccess,
}: Props) {
  const { isDarkMode } = useAppProvider();

  const confirmationDialog = useConfirmationDialog();

  const { formState, hasChanged, handleSubmit, handleChange } =
    useForm<EditChatFormState>({
      content: message.content,
    });
  const [tabIndex, setTabIndex] = useState(0);

  const onTabIndexHandleChange = (event: SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  const updateComment = useUpdateComment(message.id);
  const deleteComment = useDeleteComment(message.id);

  const onSubmit = handleSubmit((data) => {
    if (!data.content) return;

    updateComment.mutate({
      content: data.content,
    });
  });

  const handleOnDeleteMessage = () => {
    deleteComment.mutate({});
  };

  const submitDisabled =
    updateComment.isLoading || !hasChanged || !formState.content;

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <ChatBubbleOutlineOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Box display='flex' flexWrap='nowrap'>
            <Typography
              display='flex'
              alignItems='center'
              flexWrap='nowrap'
              fontSize={20}
              fontWeight={500}
              component='div'
            >
              <span className='text-truncate' style={{ maxWidth: 420 }}>
                Edit Message
              </span>
            </Typography>
          </Box>
        </Typography>
      </Stack>
    );
  };

  const renderBottomPanel = () => {
    return (
      <Stack
        direction='row'
        sx={{ p: 2, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 100,
          }}
          disabled={submitDisabled}
          onClick={onSubmit}
        >
          Send
        </M3Button>
      </Stack>
    );
  };

  const renderTabNavigation = () => {
    return (
      <Box position='relative'>
        <Tabs
          value={tabIndex}
          onChange={onTabIndexHandleChange}
          style={{
            height: 56,
          }}
        >
          <M3TabButton disableRipple label='Write' />
          <M3TabButton disableRipple label='Preview' />
        </Tabs>
        <Divider flexItem sx={{ borderWidth: 1, opacity: 0.5, mb: 1.5 }} />

        <M3Button
          sx={{
            right: 0,
            bottom: 6,
            position: 'absolute',
          }}
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-error80)'
              : 'var(--md-ref-palette-error40)',
          }}
          active={confirmationDialog.confirm.isOpen}
          onClick={() => confirmationDialog.confirm.setIsOpen(true)}
        >
          <DeleteOutlineOutlinedIcon
            style={{
              color: isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          />
          Delete Message
        </M3Button>
      </Box>
    );
  };

  const renderForm = () => {
    return (
      <>
        <Typography component='div' fontSize={13} mb={1} sx={{ opacity: 0.5 }}>
          Write your message below:
        </Typography>
        <CommentEditForm
          id={message.id}
          value={formState.content}
          richEditProps={{
            withMentions: false,
            withSaveAndCancel: false,
            submitOnEnter: false,
          }}
          onChange={(html) => {
            handleChange({
              target: {
                name: 'content',
                value: html,
              },
            });
          }}
        />
      </>
    );
  };

  const renderPreview = () => {
    return (
      <>
        <Typography>
          <RichTextContent content={formState.content} />
        </Typography>
      </>
    );
  };

  useEffect(() => {
    if (updateComment.isSuccess) {
      onEditSuccess?.();
      close?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, updateComment.isSuccess]);

  useEffect(() => {
    if (deleteComment.isSuccess) {
      onDeleteSuccess?.(message.id);
      close?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.id, deleteComment.isSuccess]);

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={renderBottomPanel()}
      close={close}
    >
      <Box sx={{ pt: 1, pb: 2 }}>
        {renderTabNavigation()}
        {tabIndex === 0 && renderForm()}
        {tabIndex === 1 && renderPreview()}
      </Box>
      <ConfirmationDialog
        {...confirmationDialog.confirm}
        title='Delete Message'
        message={<>Are you sure you want to delete this message?</>}
        onConfirm={handleOnDeleteMessage}
        isLoading={deleteComment.isLoading}
      />
    </ModalCardView>
  );
}

const AIAIAIChatEditFormView = forwardRef((props: Props, ref) => (
  <AIAIAIChatEditFormViewBase {...props} />
));

export default AIAIAIChatEditFormView;
