import React from 'react';
import { Divider, Stack, Typography, Box } from '@mui/material';

import SettingsForm from '../components/PersonalSettings/SettingsForm';
import MainContainer from '../components/MainContainer';
import { M3Card } from '../components/M3/M3Card';
import DocumentTitle from '../components/DocumentTitle';
import M3SurfaceContainer from '../components/M3/M3SurfaceContainer';

import { useIsCardFocused } from '../hooks/utils/card';

type Props = {};

const SettingsPersonalProfile = (props: Props) => {
  const isCardFocused = useIsCardFocused();

  return (
    <>
      <DocumentTitle title='Profile' trailingTitle='Personal Settings' />
      <MainContainer>
        <M3Card focused={isCardFocused.focused}>
          <M3SurfaceContainer elevation={1} sx={{ p: 4 }}>
            <Stack
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <Box flex={1}>
                <Typography component='h6' variant='h6' fontWeight={500}>
                  Settings
                </Typography>
                <Typography fontSize={14} sx={{ mb: 2, opacity: 0.8 }}>
                  Manage your preferences here
                </Typography>
              </Box>
            </Stack>
            <Divider />
            <br />
            <SettingsForm />
          </M3SurfaceContainer>
        </M3Card>
      </MainContainer>
    </>
  );
};

export default SettingsPersonalProfile;
