import { Stack, Typography, Box, Divider, Tabs } from '@mui/material';
import React, {
  forwardRef,
  PropsWithChildren,
  SyntheticEvent,
  useState,
} from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3TabButton } from '../M3/M3TabButton';
import PermanentChangeRequestScheduleList from './PermanentChangeRequestScheduleList';
import TemporaryChangeRequestScheduleList from './TemporaryChangeRequestScheduleList';

type ChangeRequestsModalViewProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    allowToEditPastDates?: boolean;
    isInactiveMembersVisible?: boolean;
  };

export function ChangeRequestsModalViewBase({
  close,
}: ChangeRequestsModalViewProps) {
  const [tabIndex, setTabIndex] = useState(0);

  const onTabIndexHandleChange = (evt: SyntheticEvent, index: number) =>
    setTabIndex(index);

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <CalendarMonthOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            Pending Change Requests
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderTabs = () => {
    return (
      <Tabs value={tabIndex} onChange={onTabIndexHandleChange}>
        <M3TabButton
          disableRipple
          sx={{ width: 200 }}
          label='Permanent Schedule'
        />
        <M3TabButton
          disableRipple
          sx={{ width: 200 }}
          label='Temporary Schedule'
        />
      </Tabs>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
    >
      <Box sx={{ pt: 1, pb: 0 }}>
        {renderTabs()}
        <Divider />
        <Box
          sx={{
            minHeight: '50vh',
          }}
        >
          {tabIndex === 0 && <PermanentChangeRequestScheduleList />}
          {tabIndex === 1 && <TemporaryChangeRequestScheduleList />}
        </Box>
      </Box>
    </ModalCardView>
  );
}

const ChangeRequestsModalView = forwardRef(
  (props: ChangeRequestsModalViewProps, ref) => (
    <ChangeRequestsModalViewBase {...props} />
  ),
);

export default ChangeRequestsModalView;
