import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MainContainer from '../components/MainContainer';
import InboxHelpNeededItemDetail from '../components/Inbox/InboxHelpNeededItemDetail';
import WindowScrollTop from '../components/WindowScrollTop';
import AppActionToolbar from '../components/AppActionToolbar';
import { M3IconButton } from '../components/M3/M3Button';
import Error from './Error';
import MyDayCardSkeleton from '../components/MyDayCardSkeleton';

import { parseURL } from '../utils/url';
import { ReactRenderElement } from '../types/types';
import { useTeamManifestDailyReportById } from '../hooks/report';
import { parseDailyReportItemResponse } from '../utils/report';

type Props = {
  objectId?: string;
  isFromAllPage?: boolean;
  nextPrevNavigation?: ReactRenderElement;
};
type DailyReportRouteParams = {
  sodEodId: string;
};

const InboxDailyReportDetail = ({ objectId, nextPrevNavigation }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sodEodId } = useParams<DailyReportRouteParams>();

  const teamManifestDailyReport = useTeamManifestDailyReportById({
    id: objectId ?? sodEodId!,
  });
  let dailyReport = parseDailyReportItemResponse(teamManifestDailyReport.data);

  const renderContent = () => {
    if (teamManifestDailyReport.isLoading && !dailyReport) {
      return <MyDayCardSkeleton withToggle={false} />;
    }

    if (!dailyReport || !dailyReport.id || !dailyReport.object_id) {
      return <Error title='Not Found' />;
    }

    return <InboxHelpNeededItemDetail dailyReport={dailyReport} />;
  };

  return (
    <>
      <WindowScrollTop />
      <MainContainer
        sx={{
          maxWidth: null,
        }}
      >
        <AppActionToolbar>
          <M3IconButton
            onClick={() => {
              const { t } = parseURL(location.search);
              const p = location.pathname
                .split('/')
                .filter((s) => !!s)
                .slice(0, 2)
                .join('/');
              navigate(`/${p}/${t ?? 0}`);
            }}
            className='m3-icon-button-back-detail-button'
          >
            <ArrowBackIcon />
          </M3IconButton>
          <Box flex={1} />
          {nextPrevNavigation}
        </AppActionToolbar>
        {renderContent()}
      </MainContainer>
    </>
  );
};

export default InboxDailyReportDetail;
