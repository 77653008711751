import { Box, ClickAwayListener, Paper, SxProps } from '@mui/material';
import React, { useState, Children, cloneElement } from 'react';
import { useAppProvider } from '../../providers/app/app';

import { M3TextField } from '../M3/M3TextField';

type IsOpenByField =
  | 'date'
  | 'members'
  | 'focus'
  | 'questions'
  | 'division'
  | 'month'
  | 'week'
  | null;

type FilterColumnPanelProps = {
  name: IsOpenByField;
  label: string;
  displayValue: any;
  placeholder?: string;
  children?: any;
  sx?: SxProps;
  textFieldSx?: SxProps;
  paperSx?: SxProps;
};

const FilterColumnPanel = ({
  label,
  displayValue,
  children,
  placeholder,
  sx,
  textFieldSx,
  paperSx,
}: FilterColumnPanelProps) => {
  const { isDarkMode } = useAppProvider();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        height: '100%',
        position: 'relative',
        ...sx,
      }}
    >
      <M3TextField
        label={label}
        placeholder={placeholder}
        value={displayValue}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          focused: true,
        }}
        sx={textFieldSx}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Box
            style={{
              marginTop: -8,
            }}
            sx={{ position: 'absolute', top: '100%', left: 0, right: 0, ...sx }}
          >
            <Paper
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                background: isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : undefined,
                ...paperSx,
              }}
            >
              {Children.map(children, (child) => {
                return cloneElement(child, {
                  close: () => setIsOpen(false),
                });
              })}
            </Paper>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default FilterColumnPanel;
