import React, { PropsWithChildren, useCallback, useState } from 'react';
import clsx from 'clsx';
import { Box, SxProps } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { M3IconButton } from '../M3/M3Button';
import PerfectScrollbar, { PerfectScrollbarProps } from '../PerfectScrollbar';

import { ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../../providers/app/app';

type SideSheetProps = PropsWithChildren & {
  fixed?: boolean;
  isOpen?: boolean;
  width?: number;
  gap?: number;
  actions?: ReactRenderElement;
  title: ReactRenderElement;
  footer?: ReactRenderElement;
  sx?: SxProps;
  content?: ReactRenderElement;
  headerSx?: SxProps;
  perfectScrollbarProps?: PerfectScrollbarProps;
  sideSheetContentSx?: SxProps;
  className?: string;
  onClose?: () => void;
};

const SideSheet = ({
  isOpen,
  children,
  actions,
  title,
  gap = 1,
  width = 292,
  footer = false,
  fixed = true,
  sx,
  headerSx,
  content,
  perfectScrollbarProps,
  sideSheetContentSx,
  onClose,
  className,
}: SideSheetProps) => {
  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='row'
      alignItems='stretch'
      justifyContent='flex-start'
      sx={sx}
      className={clsx('side-sheet-root-container', className)}
    >
      {children}
      <SideSheetContent
        gap={gap}
        width={width}
        actions={actions}
        title={title}
        footer={footer}
        fixed={fixed}
        content={content}
        headerSx={headerSx}
        isOpen={isOpen}
        perfectScrollbarProps={perfectScrollbarProps}
        sideSheetContentSx={sideSheetContentSx}
        onClose={onClose}
        className='side-sheet-parent-container'
      />
    </Box>
  );
};

export default SideSheet;

type SideSheetContentProps = Omit<SideSheetProps, 'sx'> & {};

const sxGap = 8;
function SideSheetContent({
  width,
  isOpen,
  title,
  actions,
  footer,
  fixed,
  gap,
  content,
  headerSx,
  perfectScrollbarProps,
  sideSheetContentSx,
  onClose,
  className,
}: SideSheetContentProps) {
  const { toolbarHeight, isDarkMode } = useAppProvider();
  const contentWidth = width! - gap! * sxGap;

  return (
    <>
      <Box
        minWidth={isOpen ? width : 0}
        width={isOpen ? width : 0}
        position='relative'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        className={className}
      >
        <Box
          sx={{
            width: contentWidth,
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: 2,
            minWidth: contentWidth,
            transition: 'transform 200ms',
            zIndex: fixed ? (theme) => theme.zIndex.appBar + 1 : undefined,
            position: fixed ? 'fixed' : undefined,
            height: fixed ? undefined : '100%',
            boxShadow: isDarkMode
              ? '0 0 10px 10px rgba(0, 0, 0, 0.2)'
              : '0 0 10px 10px rgba(0, 0, 0, 0.05)',
            background: isDarkMode
              ? 'var(--md-ref-palette-neutral-variant20)'
              : 'var(--md-ref-palette-neutral-variant90)',
            transform: isOpen
              ? `translate3d(0, 0, 0)`
              : `translate3d(100%, 0, 0)`,
            ...sideSheetContentSx,
          }}
          className='sidesheet-content'
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              width: contentWidth,
              flexDirection: 'column',
              minWidth: contentWidth,
            }}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              sx={{
                height: toolbarHeight,
                ...headerSx,
              }}
              className='sidesheet-content-header'
            >
              {title}
              {!!onClose && (
                <M3IconButton
                  onClick={onClose}
                  sx={{
                    mr: 1,
                    top: 6,
                    mt: 0.5,
                  }}
                >
                  <CloseOutlinedIcon />
                </M3IconButton>
              )}
            </Box>
            {actions}
            <Box
              sx={{
                height: 0,
                flex: 1,
              }}
              className='sidesheet-content-body'
            >
              <PerfectScrollbar
                key={`${
                  perfectScrollbarProps?.options?.wheelPropagation ?? true
                }`}
                {...perfectScrollbarProps}
              >
                {content}
              </PerfectScrollbar>
            </Box>
            {footer}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export function useSideSheet() {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    isOpen,
    open,
    close,
  };
}
