import { MutableRefObject, useCallback, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { AI3ChatSocketResponse } from '../../types/socket';

type UsePusherChatSubRefParams = {
  config: AI3ChatSocketResponse | null;
  timer: ReturnType<typeof setTimeout> | null;
  hasEventFired: boolean;
  hasStarted: boolean;
  hasStopped: boolean;
  chatRequestState: UseRequestStateParams;
  cancelTokenSource: null | CancelTokenSource;
  setChatRequestState: (payload: Partial<UseRequestStateParams>) => void;
  unsubscribe: () => void;
  clear: () => void;
  reset: () => void;
  start: () => void;
  stop: () => void;
  getCancelTokenSource: () => CancelTokenSource;
};
type UsePusherChatSubRefProps = {
  timeout?: number;
  onError?: (e: Error, ret: UsePusherChatSubRefRet) => void;
  onStop?: (ret: UsePusherChatSubRefRet) => void;
};
type UseRequestStateParams = {
  stream: boolean;
  isLoading: boolean;
  isStreaming: boolean;
  error: null | Error;
};
export type UsePusherChatSubRefRet =
  MutableRefObject<UsePusherChatSubRefParams>;

export function usePusherChatSubRef(
  props: UsePusherChatSubRefProps = {},
): UsePusherChatSubRefRet {
  const { timeout = 60000, onError, onStop } = props ?? {};

  const [chatRequestState, setChatRequestState] =
    useState<UseRequestStateParams>({
      stream: false,
      isLoading: false,
      isStreaming: false,
      error: null,
    });
  const setChatRequestStateHandler = useCallback(
    (payload: Partial<UseRequestStateParams>) => {
      setChatRequestState((state) => ({
        ...state,
        ...payload,
      }));
    },
    [setChatRequestState],
  );
  const pusherChatSubRef = useRef<UsePusherChatSubRefParams>({
    config: null,
    timer: null,
    hasEventFired: false,
    hasStarted: false,
    hasStopped: false,
    chatRequestState,
    setChatRequestState: setChatRequestStateHandler,
    cancelTokenSource: null,
    unsubscribe: () => Promise.resolve(),
    clear: () => {
      pusherChatSubRef.current.timer &&
        clearTimeout(pusherChatSubRef.current.timer);
    },
    reset: () => {
      pusherChatSubRef.current.unsubscribe();
      pusherChatSubRef.current.clear();
      pusherChatSubRef.current.config = null;
    },
    stop: () => {
      pusherChatSubRef.current.hasStopped = true;
      pusherChatSubRef.current.reset();
      pusherChatSubRef.current.cancelTokenSource?.cancel('cancelled');
      setChatRequestStateHandler({
        stream: false,
        isLoading: false,
        isStreaming: false,
        error: null,
      });
      onStop?.(pusherChatSubRef);
    },
    start: () => {
      pusherChatSubRef.current.hasStopped = false;
      pusherChatSubRef.current.clear();

      /**
       * TODO: Add a timer, that it's not firing the event for quite some time,
       * cancel the request and show some error (30seconds)
       */
      pusherChatSubRef.current.timer = setTimeout(() => {
        if (!pusherChatSubRef.current.hasEventFired) {
          pusherChatSubRef.current.unsubscribe();

          const error = new Error(
            `Sorry, we encountered an issue fetching your data. \nPlease try again later`,
          );

          onError?.(error, pusherChatSubRef);
        }
      }, timeout * 2);
    },
    getCancelTokenSource: () => {
      pusherChatSubRef.current.cancelTokenSource = axios.CancelToken.source();
      return pusherChatSubRef.current.cancelTokenSource;
    },
  }) as UsePusherChatSubRefRet;

  pusherChatSubRef.current.chatRequestState = chatRequestState;
  pusherChatSubRef.current.setChatRequestState = setChatRequestStateHandler;

  return pusherChatSubRef;
}
