import React from 'react';
import { Paper, Box } from '@mui/material';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';

import WorkspaceMenuList, { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';

type Props = {};

const SidecarDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const menus: MenuItem[] = [
    {
      id: 'headline-sidecar',
      name: 'Sidecar',
      icon: <TerminalOutlinedIcon />,
      headline: true,
    },
    /*
    {
      id: 'users',
      name: 'Users',
      basePath: '/sidecar/users',
      path: '/sidecar/users',
    },
    */
    {
      id: 'go2-desktop',
      name: 'Go2 Desktop',
      basePath: '/sidecar/go2-desktop',
      path: '/sidecar/go2-desktop',
    },
  ];

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1}>
          <AppDrawerMenuList menus={menus} />
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default SidecarDrawer;
