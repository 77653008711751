import React from 'react';
import { CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import { M3IconButton } from '../M3/M3Button';
import { dgs, numberFormatter } from '../../utils/string';

type InboxNextPrevNavigationProps = {
  current?: number;
  total: number;
  isLoading?: boolean;
  onNewerClick?: () => void;
  onOlderClick?: () => void;
};

const btnIconSx: SxProps = {
  width: 40,
  height: 40,
};

const InboxNextPrevNavigation = ({
  current,
  total,
  isLoading,
  onNewerClick,
  onOlderClick,
}: InboxNextPrevNavigationProps) => {
  let newerDisabled: boolean = true;
  let olderDisabled: boolean = true;

  if (total > 1) {
    if (current && current !== 1) {
      newerDisabled = false;
    }
    if (current && current !== total) {
      olderDisabled = false;
    }
  }

  return (
    <Stack direction='row' gap={2} alignItems='center'>
      {!!total && !!current && (
        <Typography
          pr={3}
          display='flex'
          alignItems='center'
          component='div'
          fontWeight={500}
        >
          {dgs(current || 0)}&nbsp;
          <span style={{ opacity: 0.5 }}>
            {' '}
            of {total > 100000 ? numberFormatter(total) : dgs(total)}
          </span>
        </Typography>
      )}
      <M3IconButton
        disabled={isLoading || newerDisabled}
        sx={btnIconSx}
        onClick={onNewerClick}
      >
        <NavigateBeforeOutlinedIcon style={{ fontSize: 28 }} />
      </M3IconButton>
      <M3IconButton
        disabled={isLoading || olderDisabled}
        sx={btnIconSx}
        onClick={onOlderClick}
      >
        {isLoading ? (
          <CircularProgress size={18} />
        ) : (
          <NavigateNextOutlinedIcon style={{ fontSize: 28 }} />
        )}
      </M3IconButton>
    </Stack>
  );
};

export default InboxNextPrevNavigation;
