import React from 'react';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';

type ChatGPTTextResponseProps = {
  text: string;
  className?: string;
  onUpdate?: (text: string) => void;
};

const ChatGPTTextResponse = ({
  text: content,
  className,
  onUpdate,
}: ChatGPTTextResponseProps) => {
  // const [text, setText] = useState('');
  // const refText = useRef('');

  /*
  useEffect(() => {
    let requestId: any = null;
    let index = 0;

    const animateText = () => {
      refText.current += content.charAt(index);
      setText(refText.current);
      onUpdate?.(refText.current);

      index++;

      if (index < content.length) {
        requestId = window.requestAnimationFrame(animateText);
      }
    };

    refText.current = '';
    onUpdate?.(refText.current);
    animateText();

    return () => {
      requestId && window.cancelAnimationFrame(requestId);
    };
    // eslint-disable-next-line
  }, [refText, content]);
  */

  return (
    <ReactMarkdown className={clsx('rich-text-content', className)}>
      {content}
    </ReactMarkdown>
  );
};

export default ChatGPTTextResponse;
