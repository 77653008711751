import React, { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import AppNavigationBar from './components/AppNavigationBar';
import Error from './pages/Error';
import AppDrawer from './components/AppDrawer';
import InboxDrawer from './components/Inbox/InboxDrawer';
import HelpCenterDrawer from './components/HelpCenterDrawer';
import ImpersonationPanel from './components/ImpersonationPanel';
import ContentDrawer from './components/Content/ContentDrawer';
import RequestDrawer from './components/Request/RequestDrawer';
import BuilderDrawer from './components/Builder/BuilderDrawer';
import MainSettingsDrawer from './components/Settings/SettingsDrawer';
import ChatDrawer from './components/Chat/ChatDrawer';
import IssueFormsDrawer from './components/AdminSettings/Forms/FormsDrawer';
import ChatGPTDrawer from './components/ChatGPT/ChatGPTDrawer';
import SidecarDrawer from './components/Sidecar/SidecarDrawer';
import FileVersioningNotice from './components/FileVersioningNotice';

import { useCurrentProfile } from './hooks/profile';
import { usePathnameSegments } from './hooks/utils/url';
import { SettingsDrawerSegments } from './pages/Settings';
import { SettingsAdminFormsParamsSegments } from './pages/SettingsAdminForms';
import { parseURL } from './utils/url';
import { getFromSessionStorage } from './utils/sessionstorage';

type Props = {
  children?: any;
};
type DrawerPage =
  | 'inbox'
  | 'help'
  | 'settings'
  | 'content'
  | 'request'
  | 'chatgpt'
  | 'aiaiai'
  | 'sidecar';

type PathSegments = (
  | DrawerPage
  | SettingsDrawerSegments
  | SettingsAdminFormsParamsSegments['segment']
)[];
/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function Home({ children }: Props) {
  const location = useLocation();
  const { error, data: userProfile } = useCurrentProfile();
  const noAppDrawer =
    userProfile?.roles.indexOf('internal')! > -1 &&
    location.pathname.includes('/help/faq');

  const [page, segment] = usePathnameSegments<PathSegments>();

  const parsedURL = parseURL(location.search);

  const impersonation = useMemo(
    () => getFromSessionStorage('impersonation'),
    [],
  );

  const renderDrawer = () => {
    if (noAppDrawer) return null;

    if (page === 'chatgpt') {
      return <ChatGPTDrawer />;
    }

    if (page === 'inbox') {
      return <InboxDrawer />;
    }

    if (page === 'chat') {
      return <ChatDrawer />;
    }

    if (page === 'help') {
      if (
        segment === 'attendance' ||
        segment === 'tools' ||
        segment === 'bk' ||
        segment === 'time' ||
        segment === 'desktop' ||
        segment === 'engage'
      ) {
        return <IssueFormsDrawer />;
      }

      return <HelpCenterDrawer />;
    }

    if (page === 'settings') {
      return <MainSettingsDrawer />;
    }

    if (page === 'content') {
      return <ContentDrawer />;
    }

    if (page === 'request') {
      return <RequestDrawer />;
    }

    if (page === 'builder') {
      return <BuilderDrawer />;
    }

    if (page === 'sidecar') {
      return <SidecarDrawer />;
    }

    /*
    if (page === 'aiaiai') {
      return <AIAIAIDrawer />;
    }
    */

    return <AppDrawer />;
  };

  if (!!error) {
    return (
      <Error
        title='User not found'
        message={<>This user no longer exist. Contact your administrator.</>}
      />
    );
  }

  /**
   * Redirect users to slack connect since the app requires for a slack connection
   * Exclude users that don't need the slack connect
   * */
  if (
    userProfile &&
    !userProfile.roles?.some(
      (role) =>
        role === 'partner_member' || role === 'numbers' || role === 'path_beta',
    ) &&
    !userProfile?.is_slack_configured &&
    !impersonation
  ) {
    return <Navigate to='/slack/connect' />;
  }

  if (parsedURL.report_type_platform === 'desktop') {
    return (
      <>
        <AppNavigationBar />
      </>
    );
  }

  return (
    <>
      <FileVersioningNotice />
      <AppNavigationBar />
      <Box display='flex' sx={{ flex: 1 }}>
        {renderDrawer()}
        <Outlet />
      </Box>
      <ImpersonationPanel />
    </>
  );
}

export default Home;
