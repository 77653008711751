import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import MainContainer from '../components/MainContainer';
import InboxAllList, { InboxAllPaths } from '../components/Inbox/InboxAllList';
import InfiniteScrollListener from '../components/InfiniteScrollListener';
import WindowScrollTop from '../components/WindowScrollTop';
import Error from './Error';
import InboxAppActionToolbar from '../components/Inbox/InboxAppActionToolbar';
import { M3Button } from '../components/M3/M3Button';
import BasicPopoverWithSearch, {
  M3OptionItem,
  useBasicPopover,
} from '../components/Popover/BasicPopoverWithSearch';

import {
  InboxFilterTypeId,
  useNotificationProvider,
} from '../providers/notification/notification';
import { useUserProvider } from '../providers/user/user';
import { ListQuery } from '../types/request';
import { useNotifications, UseNotificationsProps } from '../hooks/notification';
import {
  DailyReportNotificationItem,
  HelpNeededReportNotificationItem,
} from '../types/report';
import { PTONotificationCommentItem, PTONotificationItem } from '../types/pto';
import { JobDescriptionNotificationItem } from '../types/job-description';
import {
  UseInfiniteResetOptions,
  useInfinite,
} from '../hooks/global/useInfinite';
import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';
import { useToggleSelection } from '../hooks/global/useToggleSelection';
import { roundUpNearest } from '../utils/number';
import { usePrevious } from '../hooks/utils/misc';
import { Go2BotsNotificationItem } from '../types/go2bots';
import { ScheduleChangeRequestNotificationItem } from '../types/schedules';

type InboxAllProps = {};

export type InboxPageParamsRet = {
  pattern: string;
  paths: InboxAllPaths;
  tabIndex: number;
  listQuery: ListQuery & {
    topic?: string;
    verb?: string;
  };
  unreadCount: number;
  rightActions?: ReactRenderElement;
};

export type InboxNotificationItem =
  | PTONotificationItem
  | PTONotificationCommentItem
  | DailyReportNotificationItem
  | HelpNeededReportNotificationItem
  | JobDescriptionNotificationItem
  | Go2BotsNotificationItem
  | ScheduleChangeRequestNotificationItem;

/**
 * NOTE: List item height
 */
const inboxItemHeight = 72;

export const InboxAllBase = (props: InboxAllProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    verbs: notificationVerbs,
    topics: notificationTopics,
    unreadDailyReportCount,
    unreadPTOCount,
    unreadHelpNeededCount,
    unreadJobDescriptionCount,
    unreadGo2BotsCount,
    unreadScheduleRequestCount,
    setFilterById,
    filters: inboxFilters,
    getAllCount,
    pusherCounter: notificationPusherCounter,
  } = useNotificationProvider();
  const { getActionKeyCounter } = useAppProvider();
  const { setUniqueIdsToFetch } = useUserProvider();

  const pageParams = getPageParams();
  const [tabIndex, setTabIndex] = useState(pageParams.tabIndex);

  const unreadInfinite = useInfinite<
    InboxNotificationItem,
    UseNotificationsProps
  >({
    useQuery: useNotifications,
    queryParams: {
      ...pageParams.listQuery,
      key: `unread_all_list`,
      status: 'unread',
    },
    keyFieldName: 'refId',
    skipFetchOnInit: true,
    filterIterator: unreadFilterIterator,
  });

  const allInfinite = useInfinite<InboxNotificationItem, UseNotificationsProps>(
    {
      useQuery: useNotifications,
      queryParams: {
        ...pageParams.listQuery,
        key: 'all_list',
        status: 'all',
      },
      skipFetchOnInit: true,
    },
  );

  const allUnreadItemsOnly = useMemo(() => {
    if (tabIndex === 0) {
      return unreadInfinite.data.filter(unreadFilterIterator);
    }

    return allInfinite.data.filter(unreadFilterIterator);
  }, [tabIndex, unreadInfinite.data, allInfinite.data]);

  const toggleSelection = useToggleSelection(allUnreadItemsOnly);

  const handleChange = (event: SyntheticEvent, index: number) => {
    navigate(`${pageParams.paths.base!}/${index}`);
  };

  function unreadFilterIterator(item: InboxNotificationItem): boolean {
    return item.items[0].unread;
  }

  function getPageParams(): InboxPageParamsRet {
    let localTabIndex: number = 0;
    let paths: InboxAllPaths = {
      tabIndex: localTabIndex,
    };
    let pattern: string = '';
    let listQuery: InboxPageParamsRet['listQuery'] = {
      verb: '',
      limit: roundUpNearest(window.innerHeight / inboxItemHeight),
    };
    let unreadCount: number = 0;
    let rightActions: ReactRenderElement = null;

    if (+listQuery.limit! <= 10) {
      listQuery.limit = 10;
    }

    /**
     * NOTE: The "verb" must match what's in notification provider in order
     * for the count to be the same on the list being queried here
     */

    // path: /inbox/all
    if (location.pathname.search(/^\/inbox\/all/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/all',
        dailyReport: '/inbox/all/daily-report',
        request: '/inbox/all/request',
        jobDescription: '/inbox/all/job-description',
        helpNeeded: '/inbox/all/help-needed',
        go2bots: '/inbox/all/go2bots',
        scheduleRequest: '/inbox/all/schedule-change-request',
      };
      pattern = '/inbox/all/:tabIndex';
      rightActions = (
        <InboxFilters onFilterChanged={(id) => setFilterById(id, 'all')} />
      );

      if (inboxFilters.all === 'all') {
        listQuery.topic = [
          notificationTopics.comments,
          notificationTopics.report,
          notificationTopics.time_off,
          notificationTopics.help_needed,
          notificationTopics.go2bots,
          notificationTopics.schedule_change_request,
        ].join(',');
        unreadCount = getAllCount();
      }
      // report / daily-report
      else if (inboxFilters.all === 'report') {
        listQuery.topic = [
          notificationTopics.comments,
          notificationTopics.report,
        ].join(',');
        listQuery.verb = [
          notificationVerbs.daily_reports,
          notificationVerbs.report,
        ].join(',');
        unreadCount = unreadDailyReportCount;
      }
      // job-description
      else if (inboxFilters.all === 'job_description') {
        listQuery.topic = notificationTopics.comments;
        listQuery.verb = notificationVerbs.job_description;
        unreadCount = unreadJobDescriptionCount;
      }
      // time-off / request
      else if (inboxFilters.all === 'time_off') {
        listQuery.verb = notificationVerbs.time_off;
        unreadCount = unreadPTOCount;
      }
      // go2bots
      else if (inboxFilters.all === 'go2bots') {
        listQuery.topic = notificationTopics.go2bots;
        unreadCount = unreadGo2BotsCount;
      }
      // help needed
      else if (inboxFilters.all === 'help_needed') {
        listQuery.topic = notificationTopics.help_needed;
        unreadCount = unreadHelpNeededCount;
      }
      // help needed
      else if (inboxFilters.all === 'schedule_change_request') {
        listQuery.topic = notificationTopics.schedule_change_request;
        unreadCount = unreadScheduleRequestCount;
      }
    }
    // path: /inbox/daily-report
    else if (location.pathname.search(/^\/inbox\/daily-report/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/daily-report',
        dailyReport: '/inbox/daily-report',
      };
      pattern = '/inbox/daily-report/:tabIndex';
      listQuery.topic = [
        notificationTopics.comments,
        notificationTopics.report,
      ].join(',');
      listQuery.verb = [
        notificationVerbs.daily_reports,
        notificationVerbs.report,
      ].join(',');
      unreadCount = unreadDailyReportCount;
    }
    // path: /inbox/job-description
    else if (location.pathname.search(/^\/inbox\/job-description/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/job-description',
        jobDescription: '/inbox/job-description',
      };
      pattern = '/inbox/job-description/:tabIndex';
      listQuery.topic = notificationTopics.comments;
      listQuery.verb = notificationVerbs.job_description;
      unreadCount = unreadJobDescriptionCount;
    }
    // path: /inbox/request
    else if (location.pathname.search(/^\/inbox\/request/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/request',
        request: '/inbox/request',
      };
      pattern = '/inbox/request/:tabIndex';
      listQuery.verb = notificationVerbs.time_off;
      unreadCount = unreadPTOCount;
    }
    // path: /inbox/help-needed
    else if (location.pathname.search(/^\/inbox\/help-needed/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/help-needed',
        helpNeeded: '/inbox/help-needed',
      };
      pattern = '/inbox/help-needed/:tabIndex';
      listQuery.topic = notificationTopics.help_needed;
      unreadCount = unreadHelpNeededCount;
    }
    // path: /inbox/go2bots
    else if (location.pathname.search(/^\/inbox\/go2bots/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/go2bots',
        go2bots: '/inbox/go2bots',
      };
      pattern = '/inbox/go2bots/:tabIndex';
      listQuery.topic = notificationTopics.go2bots;
      unreadCount = unreadGo2BotsCount;
    }
    // path: /inbox/go2bots
    else if (location.pathname.search(/^\/inbox\/go2bots/) > -1) {
      paths = {
        ...paths,
        base: '/inbox/go2bots',
        scheduleRequest: '/inbox/schedule-change-request',
      };
      pattern = '/inbox/schedule-change-request/:tabIndex';
      listQuery.topic = notificationTopics.schedule_change_request;
      unreadCount = unreadScheduleRequestCount;
    }

    localTabIndex = paths.tabIndex = parseInt(
      location.pathname.replace(paths.base!, '').replace('/', ''),
    );

    return {
      paths,
      pattern,
      tabIndex: localTabIndex,
      listQuery,
      unreadCount,
      rightActions,
    };
  }

  function getItems(index: number): InboxNotificationItem[] {
    if (index === 0) {
      return unreadInfinite.data;
    }
    return allInfinite.data;
  }

  /**
   * Save previous Filters
   */
  const previousInboxFilter = usePrevious(inboxFilters);
  /**
   * Refetch the unread count when there's new unread count changes
   */
  useEffect(() => {
    const opt: UseInfiniteResetOptions = {};

    if (previousInboxFilter !== inboxFilters) {
      opt.emptyList = true;
      allInfinite.reset(opt);
      unreadInfinite.reset(opt);
    } else if (tabIndex === 0) {
      unreadInfinite.reset(opt);
    } else if (tabIndex === 1) {
      allInfinite.reset(opt);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previousInboxFilter,
    inboxFilters,
    tabIndex,
    unreadDailyReportCount,
    unreadPTOCount,
    unreadJobDescriptionCount,
    unreadHelpNeededCount,
    unreadGo2BotsCount,
    unreadScheduleRequestCount,
    notificationPusherCounter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getActionKeyCounter('pto_request_submission'),
  ]);

  useEffect(() => {
    if (tabIndex !== pageParams.tabIndex) {
      setTabIndex(pageParams.tabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, pageParams.tabIndex]);

  useEffect(() => {
    const user_ids: any[] = [];
    const staff_ids: any[] = [];
    const employee_ids: any[] = [];
    const uniqueUserMapper = (item: InboxNotificationItem) => {
      if (item.items[0].verb === 'commented_jobdescription') {
        item = item as JobDescriptionNotificationItem;
        user_ids.push(item.items[0].metadata.user);
      }

      if (item.items[0].verb === 'commented_report') {
        item = item as DailyReportNotificationItem;
        user_ids.push(item.items[0].metadata.user);
      }

      if (item.items[0].verb === 'mentioned_report') {
        user_ids.push(item.items[0].actor_object_id);
      }

      if (item.items[0].verb === 'commented_bhrtimeoffrequests') {
        item = item as PTONotificationCommentItem;
        user_ids.push(item.items[0].metadata.user);
      }

      if (item.items[0].topic === 'help_needed') {
        item = item as HelpNeededReportNotificationItem;
        user_ids.push(item.items[0].metadata.submitted_by);
      }

      if (item.items[0].topic === 'go2botsmessage') {
        item = item as Go2BotsNotificationItem;
        user_ids.push(item.items[0].metadata.user);
      }

      if (item.items[0].topic === 'timeoff') {
        item = item as PTONotificationItem;
        // NOTE: employeeId here is go2_staff_id
        if (item.items[0].metadata.employeeId) {
          staff_ids.push(item.items[0].metadata.employeeId);
        }
        if (item.items[0].metadata.user?.employee_id) {
          employee_ids.push(item.items[0].metadata.user?.employee_id);
        }
        // NOTE: id here is go2_staff_id
        if (item.items[0].metadata.user?.id) {
          staff_ids.push(item.items[0].metadata.user?.id);
        }
      }

      if (item.items[0].topic === 'schedule_change_request') {
        item = item as ScheduleChangeRequestNotificationItem;
        user_ids.push(item.items[0].actor_object_id);
      }
    };

    unreadInfinite.data.map(uniqueUserMapper);
    allInfinite.data.map(uniqueUserMapper);

    setUniqueIdsToFetch({ user_ids, staff_ids, employee_ids });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadInfinite.data, allInfinite.data]);

  useEffect(() => {
    toggleSelection.reset();
  }, [tabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MainContainer>
        <InboxAppActionToolbar
          rightActions={pageParams.rightActions}
          totalUnread={pageParams.unreadCount}
          tabIndex={tabIndex}
          onTabIndexHandleChange={handleChange}
          toggleSelection={toggleSelection}
        />
        {!unreadInfinite.isLoading && !getItems(0).length && tabIndex === 0 && (
          <Error
            title={`Unread messages`}
            message={`You have 0 unread messages`}
          />
        )}
        {!allInfinite.isLoading && !getItems(1).length && tabIndex === 1 && (
          <Error title={`All messages`} message={`You have 0 messages`} />
        )}
        <InboxAllList
          isLoading={
            (tabIndex === 0 && unreadInfinite.isLoading) ||
            (tabIndex === 1 && allInfinite.isLoading)
          }
          isLoadingMore={
            (tabIndex === 0 && unreadInfinite.isLoadingMore) ||
            (tabIndex === 1 && allInfinite.isLoadingMore)
          }
          hasReachEnd={
            (tabIndex === 0 && unreadInfinite.hasReachEnd) ||
            (tabIndex === 1 && allInfinite.hasReachEnd)
          }
          items={getItems(tabIndex)}
          toggleSelection={toggleSelection}
          paths={pageParams.paths}
          listQuery={pageParams.listQuery}
        />
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => {
          tabIndex === 0 && unreadInfinite.loadNext();
          tabIndex === 1 && allInfinite.loadNext();
        }}
      />
      <WindowScrollTop deps={[tabIndex]} />
    </>
  );
};

const InboxAll = (props: any) => {
  let { pathname } = useLocation();
  let regex =
    /^(all|daily-report|request|help-needed|job-description|chat|go2bots|schedule-change-request)/;
  let [action] = pathname.replace('/inbox/', '').match(regex) ?? [];
  return <InboxAllBase key={action} {...props} />;
};

export default InboxAll;

type InboxFiltersProps = {
  onFilterChanged?: (id: InboxFilterTypeId) => void;
};
function InboxFilters({ onFilterChanged }: InboxFiltersProps) {
  const { filters } = useNotificationProvider();
  const basicPopover = useBasicPopover();

  const [selected, setSelected] = useState<null | M3OptionItem>(null);

  const options: (M3OptionItem & { id: InboxFilterTypeId })[] = useMemo(
    () => [
      {
        id: 'all',
        label: 'All',
      },
      {
        id: 'report',
        label: 'Daily Report',
      },
      {
        id: 'job_description',
        label: 'Job Description',
      },
      {
        id: 'time_off',
        label: 'Request',
      },
      {
        id: 'schedule_change_request',
        label: 'Schedule Change Request',
      },
      {
        id: 'go2bots',
        label: 'Go2Bots',
      },
      {
        id: 'help_needed',
        label: 'Help Needed',
      },
    ],
    [],
  );

  useEffect(() => {
    if (selected || filters.all === 'all') return;

    setSelected(options.find((opt) => opt.id === filters.all)!);
  }, [selected, options, filters, setSelected]);

  return (
    <Stack direction='row' gap={2} alignItems='center' ref={basicPopover.ref}>
      <M3Button
        active={basicPopover.isOpen || !!selected}
        onClick={basicPopover.open}
      >
        &nbsp;&nbsp;{selected?.label ?? 'Filter By'}&nbsp;&nbsp;&nbsp;&nbsp;
        <ExpandMoreOutlinedIcon
          style={{
            marginRight: -2,
          }}
        />
      </M3Button>
      <BasicPopoverWithSearch
        withSearch={false}
        open={basicPopover.isOpen}
        anchorEl={basicPopover.ref.current}
        options={options!}
        selected={selected}
        maxRowVisible={8}
        paperProps={{
          style: {
            width: 200,
          },
        }}
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        onClose={() => {
          basicPopover.close();
        }}
        onSelect={(item: M3OptionItem) => {
          setSelected(item.id === 'all' ? null : item);
          onFilterChanged?.(item.id as InboxFilterTypeId);
          basicPopover.close();
        }}
      />
    </Stack>
  );
}
