import DOMPurify, { Config } from 'dompurify';
import React, { useMemo } from 'react';
import clsx from 'clsx';

type RichTextContentProps = {
  content: string;
  className?: string;
  domPurifyOptions?: Partial<Config>;
};
/**
 * We are wrapping the quill editor, because we use a different back then.
 * And the whole app depends on this wrapper component
 */
const RichTextContent = ({
  content,
  className,
  domPurifyOptions,
}: RichTextContentProps) => {
  const __html = useMemo(() => {
    let html = DOMPurify.sanitize(content, domPurifyOptions ?? {}) as string;

    /**
     * URL Regex
     */
    let urlRegex =
      /\b((http|https):\/\/?)[^\s()<>[\]]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/g;

    /**
     * Highlighted text regex
     */
    let highlightedRegex =
      /style="background-color: var\(--md-sys-color-on-primary-(light|dark)\);"/gi;

    /**
     * Stripping any leading/trailing empty paragraphs and
     * trailing bullet list
     */
    html = html
      .replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/gi, '')
      .replace(/(<li><br><\/li><\/ul>)+/gi, '</ul>')
      .replace(/(<li><br><\/li><\/ol>)+/gi, '</ol>')
      .replace(/(<p><br><\/p>)+/gi, '<p style="height: 5px"><br></p>')
      .replace(highlightedRegex, '')
      .replace(urlRegex, (text) => {
        return `<a href="${text}" target="blank" class="linkify-text">${text}</a>`;
      });
    return html;
  }, [content, domPurifyOptions]);

  return (
    <div
      className={clsx('rich-text-content', className)}
      dangerouslySetInnerHTML={{
        __html: __html as string,
      }}
    ></div>
  );
};

export default RichTextContent;
