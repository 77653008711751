import { useRef } from 'react';
import { IterableObject } from '../../types/types';

export function useRefAssign<T = any>(value: T) {
  const ref = useRef<T>(value);

  if (typeof value === 'object' && value) {
    Object.assign(ref.current as unknown as IterableObject<any>, value);
  } else {
    ref.current = value;
  }

  return ref;
}
