import { UseQueryOptions } from '@tanstack/react-query';
import { DivisionModel } from '../types/division';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';
import { AxiosRequestConfig } from 'axios';

type UseDivisionsProps = ListQuery & {
  status: 'active' | 'inactive';
};
export function useDivisions<R = DivisionModel>(
  params: UseDivisionsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseDivisionsProps>({
    params,
    baseIdentifier: 'useDivisions',
    identifierKeys: ['status'],
    queryParamKeys: ['status'],
  });

  return useQueryApi<UseDivisionsProps, ListResult<R>>(
    identifiers,
    '/admin/divisions/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseDivisionByIdProps = {
  id: number | string | null;
};
export function useDivisionById<R = DivisionModel>(
  params: UseDivisionByIdProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseDivisionByIdProps>({
    params,
    baseIdentifier: 'useDivisionById',
    identifierKeys: ['id'],
  });

  return useQueryApi<UseDivisionByIdProps, R>(
    identifiers,
    `/admin/divisions/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UsePostDivisionSetPaidBreakLimitParams = {
  id?: number;
};
export function usePostDivisionSetPaidBreakLimit(
  params: UsePostDivisionSetPaidBreakLimitParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<any, any>(
    `/admin/divisions/${params.id}/set-paid-break-limit/`,
    {},
    axiosConfig,
  );
}
