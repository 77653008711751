import React, { CSSProperties } from 'react';
import { Box, Paper, Skeleton } from '@mui/material';

type Props = {
  boxStyle?: CSSProperties;
};

const IntercomArticleSkeleton = ({ boxStyle }: Props) => {
  return (
    <Box
      style={{
        margin: '0 30px 8px',
        ...boxStyle,
      }}
    >
      <Paper
        className='collection__paper'
        style={{
          paddingTop: 11,
          paddingBottom: 11,
        }}
      >
        <Skeleton variant='text' sx={{ fontSize: 18, maxWidth: 400 }} />
        <Skeleton
          variant='text'
          sx={{ fontSize: 16 }}
          style={{
            maxWidth: 630,
            margin: '5px 0 11px',
          }}
        />
      </Paper>
    </Box>
  );
};

export default IntercomArticleSkeleton;
