import React, { Fragment, useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';

import WindowScrollTop from '../WindowScrollTop';
import IntercomArticleItem from './IntercomArticleItem';
import IntercomArticleSkeleton from './IntercomArticleSkeleton';
import IntercomBreadcrumbs from './IntercomBreadcrumbs';
import IntercomHeadlineSkeleton from './IntercomHeadlineSkeleton';

import { ListQuery } from '../../types/request';
import { mutateFieldEscapeString, mutateIdToString } from '../../utils/object';
import {
  getArticleItemProps,
  getCollectionItemProps,
} from '../../providers/intercom';
import { useIntercomProvider } from '../../providers/intercom/intercom';
import {
  IntercomArticle,
  IntercomCollection,
  IntercomSection,
} from '../../types/intercom';
import { QueryParams } from '../../hooks/global/useApi';
import { ListResult } from '../../types/response';
import { cleanURL } from '../../utils/url';
import { useAppProvider } from '../../providers/app/app';

type IntercomCollectionDetailProps = {
  basePath: string;
  useIntercomSectionsHook: <R = IntercomSection>(
    params: ListQuery & {
      parent_id?: string;
    },
    reactQueryParams?: Partial<QueryParams>,
  ) => UseQueryResult<ListResult<R>>;
  useIntercomArticlesInCollectionHook: <R = IntercomArticle>(
    params: ListQuery & {
      collectionId: string;
    },
    reactQueryParams?: Partial<QueryParams>,
  ) => UseQueryResult<ListResult<R>>;
};

const IntercomCollectionDetail = ({
  basePath,
  useIntercomSectionsHook,
  useIntercomArticlesInCollectionHook,
}: IntercomCollectionDetailProps) => {
  const { isDarkMode } = useAppProvider();
  const { collections, admins } = useIntercomProvider();
  const match = useMatch(cleanURL(`${basePath}/collection/:collectionId`));

  const collectionId = match?.params.collectionId ?? '';

  const params: ListQuery = {
    page: 1,
    offset: 0,
    limit: 200,
  };

  const collection = collections.find(
    (collection) => collection.id === collectionId,
  );

  const sectionsQuery = useIntercomSectionsHook(
    {
      ...params,
      parent_id: collection?.id,
      omit: 'translated_content',
    },
    {
      enabled: !!collection,
    },
  );

  const articlesQuery = useIntercomArticlesInCollectionHook(
    {
      ...params,
      collectionId,
      omit: 'body,author',
    },
    {
      enabled: !!collection,
    },
  );

  const collectionHash = useIntercomCollectionDetail({
    collection,
    sectionsQuery,
    articlesQuery,
  });

  let item = collection ? getCollectionItemProps(collection, admins) : null;

  const renderHeadline = () => {
    if (!item) return null;

    return (
      <Paper
        className='collection__paper'
        sx={{
          display: 'flex',
          position: 'relative',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
        style={{
          border: 0,
          boxShadow: 'none',
          background: 'transparent',
        }}
      >
        <Box className='collection__photo'>
          <Box
            style={{
              height: 48,
              width: 48,
              margin: 'auto',
            }}
          >
            <item.Icon />
          </Box>
        </Box>
        <Box flex={1} className='collection__content'>
          <Typography
            fontSize={33}
            sx={{
              color: isDarkMode
                ? 'var(--md-ref-palette-primary80) !important'
                : 'var(--md-ref-palette-primary40)',
            }}
          >
            {item.collection.name}
          </Typography>
          {!!item.collection.description && (
            <Typography
              fontSize={16}
              sx={{
                opacity: 0.8,
                color: 'var(--md-sys-color-on-surface-light)',
              }}
              style={{
                maxWidth: 630,
                padding: '5px 0 0',
              }}
            >
              {item.collection.description}
            </Typography>
          )}
        </Box>
      </Paper>
    );
  };

  const renderSection = (section: IntercomSection) => {
    return (
      <Fragment key={section.id}>
        <Typography
          fontSize={18}
          fontWeight={700}
          style={{
            padding: '16px 30px 11px',
            color: isDarkMode
              ? 'var(--md-ref-palette-primary80)'
              : 'var(--md-ref-palette-primary40)',
          }}
        >
          {section.name}
        </Typography>
        <Box
          style={{
            margin: '0 30px',
          }}
        >
          {section.articles.map((article, index, arr) => {
            const item = getArticleItemProps(article);
            const first = index === 0;
            const last = arr.length - 1 === index;
            return (
              <IntercomArticleItem
                key={item.id}
                item={item}
                first={first}
                last={last}
              />
            );
          })}
        </Box>
      </Fragment>
    );
  };

  const renderArticles = (
    articles: IntercomArticle[] = [],
    sections: IntercomSection[] = [],
  ) => {
    if (!articles.length) return null;

    return (
      <Box
        style={{
          margin: '0 30px 0',
          marginTop: sections.length ? 30 : 0,
        }}
      >
        {articles.map((article, index, arr) => {
          const item = getArticleItemProps(article);
          const first = index === 0;
          const last = arr.length - 1 === index;
          return (
            <IntercomArticleItem
              key={item.id}
              item={item}
              first={first}
              last={last}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <WindowScrollTop deps={[item]} />
      <IntercomBreadcrumbs
        items={[
          {
            id: 'all',
            label: 'All Collections',
            path: basePath,
          },
          {
            id: collection ? collection.id : match?.params.collectionId ?? '',
            label: collection
              ? collection.name
              : match?.params.collectionId ?? '',
            path: collection
              ? cleanURL(`${basePath}/collection/${collection.id}`)
              : '',
          },
        ]}
      />
      <Box sx={{ minHeight: 477 }}>
        <Box className='intercom-section'>
          {collection ? renderHeadline() : <IntercomHeadlineSkeleton />}
        </Box>
        <br />
        <Box sx={{ ml: -4, mr: -4 }}>
          {((sectionsQuery.isLoading && !collectionHash.sections.length) ||
            (articlesQuery.isLoading && !collectionHash.articles.length)) && (
            <IntercomArticleSkeleton />
          )}
          {!!collectionHash.articles.length &&
            renderArticles(collectionHash.articles, collectionHash.sections)}
          {!!collectionHash.sections.length &&
            collectionHash.sections.map(renderSection)}
        </Box>
      </Box>
    </>
  );
};

type UseIntercomCollectionDetailProps = {
  // admins: IntercomAdmin[];
  collection?: IntercomCollection;
  sectionsQuery: UseQueryResult<ListResult<IntercomSection>>;
  articlesQuery: UseQueryResult<ListResult<IntercomArticle>>;
};
type UseIntercomCollectionDetailRet = {
  articles: IntercomArticle[];
  sections: IntercomSection[];
};
export function useIntercomCollectionDetail({
  // admins,
  collection,
  sectionsQuery,
  articlesQuery,
}: UseIntercomCollectionDetailProps): UseIntercomCollectionDetailRet {
  const collectionHash = useMemo(() => {
    if (!collection || !sectionsQuery.isSuccess || !articlesQuery.isSuccess) {
      return {
        articles: [],
        sections: [],
      };
    }

    let sections = sectionsQuery.data.results;
    let articles = articlesQuery.data.results;

    mutateIdToString(sections, ['id', 'parent_id']);
    mutateIdToString(articles, ['id', 'parent_id', 'author_id']);

    mutateFieldEscapeString(sections, ['name', 'description']);
    mutateFieldEscapeString(articles, ['title', 'description']);

    const filterArticle = (
      type: 'collection' | 'section',
      article: IntercomArticle,
      parent: IntercomCollection | IntercomSection,
    ) => {
      if (article.parent_type === type) {
        return article.parent_id === parent.id;
      }
      return false;
    };

    /**
     * Filter out sections having articles, and order them by "order" in ascending
     */
    sections = sections
      .map((section) => {
        return {
          ...section,
          articles: articles.filter((article) =>
            filterArticle('section', article, section),
          ),
        };
      })
      .filter((section) => !!section.articles.length)
      .sort((a, b) => a.order - b.order);

    /**
     * Filter out articles directly child of collection.
     * Order the articles by updated date
     */
    articles = articles
      .filter((article) => filterArticle('collection', article, collection))
      .sort((a, b) => b.updated_at - a.updated_at);

    return {
      articles,
      sections,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, sectionsQuery.isSuccess, articlesQuery.isSuccess]);

  return collectionHash;
}

export default IntercomCollectionDetail;
