import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import MainContainer from '../components/MainContainer';
import BasicIframe from '../components/BasicIframe';
import EngageForm from '../components/AdminSettings/Forms/EngageForm';
import BookkeepingForm from '../components/AdminSettings/Forms/BookkeepingForm';
import DocumentTitle from '../components/DocumentTitle';

import { useAppProvider } from '../providers/app/app';
import { toURL } from '../utils/url';
import { useHasViewPermission } from '../components/HasViewPermission';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useCurrentProfile } from '../hooks/profile';
import { UserProfile } from '../types/profile';
import { usePathnameSegments } from '../hooks/utils/url';
import { stripToFormatterWord, toTitleCase } from '../utils/string';
import { getBooleanValue } from '../utils/json';

type SettingsAdminFormsProps = {
  basePath?: string;
};

export type SettingsAdminFormsParamsSegments = {
  segment:
    | 'attendance'
    | 'tools'
    | 'new-hire-setup'
    | 'new-account-creation'
    | 'engage'
    | 'bookkeeping'
    | 'bk'
    | string;
};

const getIntercomLink = (
  segment: SettingsAdminFormsParamsSegments['segment'],
  userProfile: UserProfile,
) => {
  let path: string = `/help/${segment}`;

  if (segment === 'new-hire-setup') {
    path = '/setup';
  }

  if (segment === 'additional-teammate' || segment === 'give-a-bonus') {
    path = `/forms/${segment}`;
  }

  return toURL(`${process.env.REACT_APP_INTERCOM_FORMS_URL}${path}`, {
    email: userProfile.email,
    employeeId: userProfile.employee_id,
  });
};

const SettingsAdminForms = ({ basePath }: SettingsAdminFormsProps) => {
  const { toolbarHeight } = useAppProvider();
  const { current: currentWorkspace } = useWorkspaceProvider();
  const { data: currentProfile } = useCurrentProfile();

  let { segment } = useParams<SettingsAdminFormsParamsSegments>();
  let [, basePathSegment] = basePath?.split('/')?.filter((s) => !!s) ?? [];

  const isIntercomDisabled = useMemo(
    () => getBooleanValue(process.env.REACT_APP_INTERCOM_DISABLED),
    [],
  );

  /**
   * Check if there's a base path and have a segment,
   * since we use this whole component to render outside of settings form
   */
  segment = basePathSegment || segment;

  const hasViewPermission = useHasViewPermission({
    roles: ['internal', 'teammate'],
  });
  const hasBkViewPermission = useHasViewPermission({
    roles: ['superuser', 'developer', 'internal', 'teammate'],
  });
  const hasPartnerViewPermission = useHasViewPermission({
    roles: ['partner_member'],
  });

  if (
    segment === 'tools' ||
    segment === 'bookkeeping' ||
    segment === 'bk' ||
    segment === 'engage' ||
    segment === 'new-hire-setup'
  ) {
    if (isIntercomDisabled) {
      window.location.href = process.env.REACT_APP_INTERCOM_BACK_UP_FORM!;
      return;
    }
  }

  if (segment === 'attendance') {
    if (isIntercomDisabled) {
      window.location.href = process.env.REACT_APP_INTERCOM_ATTENDANCE_FORM!;
      return;
    }
  }

  if (hasPartnerViewPermission) {
    if (segment !== 'additional-teammate' && segment !== 'give-a-bonus') {
      return <Navigate to={`/manifest/${currentWorkspace!.id}`} />;
    }
  } else {
    /**
     * Since the issue forms are exposed to the outside, we need to check it here
     */
    if (!hasViewPermission) {
      if (segment !== 'attendance' && segment !== 'tools') {
        return <Navigate to={`/manifest/${currentWorkspace!.id}`} />;
      }
    }

    if (segment === 'engage') {
      return <EngageForm />;
    }

    if (segment === 'bookkeeping' || segment === 'bk') {
      if (hasBkViewPermission) {
        return <BookkeepingForm />;
      }
      return <Navigate to={`/manifest/${currentWorkspace!.id}`} />;
    }
  }

  /**
   * Below here are intercom forms
   */
  return (
    <MainContainer
      sx={{
        p: 0,
        pt: 0,
        overflow: 'hidden',
        maxWidth: 'initial',
        height: `calc(100vh - ${toolbarHeight}px)`,
      }}
    >
      <IntercomForm segment={segment} userProfile={currentProfile} />
    </MainContainer>
  );
};

export default SettingsAdminForms;

type IntercomFormProps = {
  segment?: SettingsAdminFormsParamsSegments['segment'];
  userProfile?: UserProfile;
};

function IntercomForm({ segment, userProfile }: IntercomFormProps) {
  let [pathSegment] = usePathnameSegments();
  let title: string = toTitleCase(stripToFormatterWord(segment!));
  let trailingTitle: string = '';

  if (segment === 'tools') {
    title = 'IT Tech';
  } else if (segment === 'desktop') {
    title = 'Go2 Desktop';
  }

  if (pathSegment === 'help') {
    trailingTitle = 'Help';
  } else {
    trailingTitle = 'Forms';
  }

  return (
    <>
      <DocumentTitle title={title} trailingTitle={trailingTitle} />
      <BasicIframe
        key={segment}
        src={getIntercomLink(segment!, userProfile!)}
      />
    </>
  );
}
