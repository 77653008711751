import { Stack, Typography, Box } from '@mui/material';
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';

import { useHasViewPermission } from '../../HasViewPermission';
import ModalCardView, { ModalCardViewCloseProps } from '../../ModalCardView';
import { M3Button } from '../../M3/M3Button';
import { M3OptionItem } from '../../Popover/BasicPopoverWithSearch';
import { M3Dropdown } from '../../M3/M3Select';

import { JobDescriptionItemResult } from '../../../types/job-description';
import {
  useDivisionById,
  usePostDivisionSetPaidBreakLimit,
} from '../../../hooks/division';

type JobBreakPolicyModalProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    divisionId?: number;
    onSubmit?: (data: SubmitPayload) => void;
  };

type SubmitPayload = {};

export function JobBreakPolicyModalBase({
  divisionId,
  close,
}: JobBreakPolicyModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { data: divisionData, refetch: refetchDivisionById } = useDivisionById(
    { id: divisionId! },
    { enabled: !!divisionId },
  );

  const postDivisionSetPaidBreakLimit = usePostDivisionSetPaidBreakLimit({
    id: divisionId,
  });

  const hasBreakPolicyRights = useHasViewPermission({
    roles: ['superuser', 'developer', 'partner_member', 'escalation_manager'],
  });

  const [hasChanged, setHasChanged] = useState(false);

  const [allowPaidBreakOption, setAllowPaidBreakOption] =
    useState<M3OptionItem>({
      id: 'no',
      label: 'No',
    });
  const yesNoOptions: M3OptionItem[] = useMemo(
    () => [
      {
        id: 'no',
        label: 'No',
      },
      {
        id: 'yes',
        label: 'Yes',
      },
    ],
    [],
  );

  const [selectedMinuteOption, setSelectedMinuteOption] =
    useState<M3OptionItem>({
      id: 30,
      label: '30 min',
    });
  const minutesOptions: M3OptionItem[] = useMemo(
    () => [
      {
        id: 30,
        label: '30 min',
      },
      {
        id: 45,
        label: '45 min',
      },
      {
        id: 60,
        label: '60 min',
      },
    ],
    [],
  );

  const handleOnSubmit = () => {
    const payload: Partial<JobDescriptionItemResult> = {};

    if (allowPaidBreakOption.id === 'yes') {
      // converting the minutes to seconds
      payload.paid_break_limit = +selectedMinuteOption.id * 60;
    } else {
      payload.paid_break_limit = 0;
    }

    postDivisionSetPaidBreakLimit.mutate(payload);
  };

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <PolicyOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Typography fontSize={20} fontWeight={500} component='div'>
            Break Policy
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderBottomPanel = () => {
    let buttonDisabled = postDivisionSetPaidBreakLimit.isLoading || !hasChanged;

    return (
      <Stack
        direction='row'
        sx={{ p: 1, flex: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <M3Button
          color='primary'
          variant='contained'
          sx={{
            width: 90,
          }}
          disabled={buttonDisabled}
          onClick={handleOnSubmit}
        >
          Save
        </M3Button>
      </Stack>
    );
  };

  /**
   * Setting the initial value after fetching the job description paid break limit
   * in seconds, converting it to minutes to match the options
   */
  useEffect(() => {
    if (!divisionData) return;

    const { paid_break_limit = 0 } = divisionData;
    const paidBreakInMinutes = paid_break_limit / 60;

    if (paidBreakInMinutes) {
      const minOption =
        minutesOptions.find((opt) => opt.id === paidBreakInMinutes) ??
        minutesOptions[0];
      setSelectedMinuteOption(minOption);
      setAllowPaidBreakOption(yesNoOptions[1]);
    } else {
      setAllowPaidBreakOption(yesNoOptions[0]);
    }
  }, [
    divisionData,
    yesNoOptions,
    minutesOptions,
    setAllowPaidBreakOption,
    setSelectedMinuteOption,
  ]);

  useEffect(() => {
    if (postDivisionSetPaidBreakLimit.isSuccess) {
      refetchDivisionById();
      enqueueSnackbar('Break has been successfully updated.');
      close?.();
    }
    // eslint-disable-next-line
  }, [postDivisionSetPaidBreakLimit.isSuccess]);

  useEffect(() => {
    refetchDivisionById();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      footer={hasBreakPolicyRights ? renderBottomPanel() : null}
      close={close}
      sx={{
        maxWidth: 680,
      }}
    >
      <Box sx={{ pt: 3, pb: 0 }}>
        {/* <Typography
          fontSize={14}
          component='div'
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-tertiary80)'
              : 'var(--md-ref-palette-tertiary40)',
          }}
        >
          Break policy refers to the guidelines set by an organization that
          stipulate the circumstances under which employees are entitled to
          breaks during their work hours without loss of pay. This policy is
          designed to ensure that employees have adequate rest periods within
          their working day to maintain productivity and well-being.
        </Typography> */}
        <Typography
          display='flex'
          component='div'
          alignItems='center'
          justifyContent='space-between'
        >
          Do you have a paid break policy?
          <M3Dropdown
            withIcon={hasBreakPolicyRights}
            disabled={!hasBreakPolicyRights}
            selected={allowPaidBreakOption}
            options={yesNoOptions}
            paperProps={{
              style: {
                width: 70,
              },
            }}
            onSelect={(opt) => {
              setHasChanged(true);
              setAllowPaidBreakOption(opt);
            }}
          />
        </Typography>
        <br />
        <Typography
          display='flex'
          component='div'
          alignItems='center'
          justifyContent='space-between'
        >
          How long is the paid break?
          <M3Dropdown
            withIcon={hasBreakPolicyRights}
            disabled={allowPaidBreakOption.id === 'no' || !hasBreakPolicyRights}
            selected={selectedMinuteOption}
            options={minutesOptions}
            paperProps={{
              style: {
                width: 90,
              },
            }}
            onSelect={(opt) => {
              setHasChanged(true);
              setSelectedMinuteOption(opt);
            }}
          />
        </Typography>
      </Box>
    </ModalCardView>
  );
}

const JobBreakPolicyModal = forwardRef(
  (props: JobBreakPolicyModalProps, ref) => (
    <JobBreakPolicyModalBase {...props} />
  ),
);

export default JobBreakPolicyModal;
