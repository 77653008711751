import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import AppActionToolbar from '../components/AppActionToolbar';
import MainContainer from '../components/MainContainer';
import ContentCollectionItem from '../components/Content/ContentCollectionItem';
import IntercomBreadcrumbs from '../components/Intercom/IntercomBreadcrumbs';
import SearchForm from '../components/Filters/SearchForm';
import PageWait from '../components/PageWait';
import { M3Button } from '../components/M3/M3Button';
import ContentDragDropUnsaved from '../components/Content/ContentDragDropUnsaved';

import { CollectionItemProps } from '../providers/intercom';
import { escapeRegExp } from '../utils/string';
import { useDragAndDrop } from '../hooks/global/useDragAndDrop';
import { IterableObject } from '../types/types';

type ContentCollectionsProps = {
  items: CollectionItemProps[];
};

const ContentCollections = ({ items }: ContentCollectionsProps) => {
  const [search, setSearch] = useState('');
  const [changed, setChanged] = useState(false);

  const [unsaved, setUnsaved] = useState<IterableObject<boolean>>({});

  const rgx = new RegExp(escapeRegExp(search), 'gi');

  const { items: orderedItems, onDragEnd } = useDragAndDrop({
    key: 'id',
    items: search
      ? items.filter((item) => rgx.test(item.collection.name))
      : items,
    onChange: ({ draggableId }) => {
      setChanged(true);
      setUnsaved((state) => ({ ...state, [draggableId]: true }));
    },
  });

  const onSaveCollections = () => {
    setChanged(false);
    setUnsaved({});
  };

  return (
    <>
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <SearchForm
            placeholder='Search collections...'
            onStateUpdated={(state) => {
              setSearch(state.search);
            }}
          />
          <Box
            flex={1}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <M3Button
              disabled={!changed}
              color='primary'
              variant='contained'
              onClick={onSaveCollections}
            >
              <ModeEditOutlineOutlinedIcon />
              Save Collections
            </M3Button>
          </Box>
        </AppActionToolbar>
        <IntercomBreadcrumbs
          items={[
            {
              id: 'all',
              label: 'All Collections',
              path: '/content/collection',
            },
          ]}
        />
        <br />
        {!orderedItems.length && !!search && (
          <PageWait
            title={`No collections found`}
            message={
              <>
                We could not find any collection:{' '}
                <strong style={{ fontWeight: 500 }}>{search}</strong>.
              </>
            }
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='collections' type='collection'>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {orderedItems.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          key={item.id}
                          ref={provided.innerRef}
                          className='content-collection-draggable-wrapper'
                          {...provided.draggableProps}
                        >
                          <div
                            style={{ paddingBottom: 12, position: 'relative' }}
                          >
                            <ContentCollectionItem
                              key={item.id}
                              item={item}
                              dragDisabled={!!search}
                              dragHandleProps={provided.dragHandleProps}
                              snapshot={snapshot}
                              to={`/content/collection/${item.id}`}
                              sx={{ mb: 0 }}
                            />
                            {unsaved[item.id] && (
                              <ContentDragDropUnsaved title='This collection has not been saved' />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </MainContainer>
    </>
  );
};

export default ContentCollections;
