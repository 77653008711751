import React from 'react';

import SignUp from '../components/SignUp/SignUp';

type Props = {};

const SignUpJobseekers = (props: Props) => {
  return <SignUp user_type='jobseekers' />;
};

export default SignUpJobseekers;
