import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, Chip, Stack } from '@mui/material';
import moment from 'moment';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';

import MainContainer from '../components/MainContainer';
import AppActionToolbar from '../components/AppActionToolbar';
import { FormStateRet, useForm } from '../components/BasicForm';
import MyDayCard from '../components/MyDayCard';
import MyDayCardSkeleton from '../components/MyDayCardSkeleton';
import PageWait from '../components/PageWait';
import { M3Button } from '../components/M3/M3Button';
import DocumentTitle from '../components/DocumentTitle';
import InfiniteScrollListener from '../components/InfiniteScrollListener';
import WindowScrollTop from '../components/WindowScrollTop';
import LoadMorePlaceholder from '../components/LoadMorePlaceholder';
import FilterColumnPanel from '../components/Filters/FilterColumnPanel';
import DateRangeSelectionPopover from '../components/Filters/DateRangeSelectionPopover';
import AutocompleteSelectionPopover from '../components/Filters/AutocompleteSelectionPopover';

import { MenuOption } from '../types/app';
import { useDomoFilterMember } from '../hooks/domo';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useTeamManifests, UseTeamManifestsParams } from '../hooks/report';
import { DailyReportItem, TeamManifestsResponse } from '../types/report';
import { useMetadataProvider } from '../providers/metadata/metadata';
import { useCurrentProfile } from '../hooks/profile';
import { useUserProvider } from '../providers/user/user';
import {
  DailyReportItemExpanded,
  parseDailyReportItemResponse,
} from '../utils/report';
import { useInfinite } from '../hooks/global/useInfinite';
import { useAppProvider } from '../providers/app/app';
import * as posthog from '../services/posthog';

type Props = {};

type DateFormState = {
  start: string | null;
  end: string | null;
  customStart: string | null;
  customEnd: string | null;
};

type AutocompleteFormState = {
  checked: {
    [key: string]: boolean;
  };
  optionById: {
    [key: string]: MenuOption;
  };
};

const TeamManifests = (props: Props) => {
  const { current } = useWorkspaceProvider();
  const { contentTypes, teamManifestsParams, setTeamManifestsParams } =
    useMetadataProvider();
  const { data: currentProfile } = useCurrentProfile();
  const { setUniqueIdsToFetch } = useUserProvider();
  const { subToolbarHeight, isDarkMode, getActionKeyCounter } =
    useAppProvider();
  const [isNeedHelpActive, setIsNeedHelpActive] = useState(
    teamManifestsParams.need_help,
  );

  const dateForm = useForm<DateFormState>({
    start: teamManifestsParams.date_start,
    end: teamManifestsParams.date_end,
    customStart: moment().format('YYYY-MM-DD'),
    customEnd: moment().format('YYYY-MM-DD'),
  });
  const memberForm = useForm<AutocompleteFormState>({
    checked: {},
    optionById: {},
  });

  const filterMember = useDomoFilterMember(
    {
      divisions:
        current!.id === 'my-squad' ? current!.queryName! : current!.name!,
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    },
    {
      enabled: current!.id !== 'my-squad',
    },
  );

  const teamManifestsQueryParams: UseTeamManifestsParams = {
    limit: 10,
    divisions: current!.id === 'my-squad' ? current!.queryId! : current!.id!,
    date_start: moment(dateForm.formState.start).format('YYYY-MM-DD'),
    date_end: moment(dateForm.formState.end).format('YYYY-MM-DD'),
    members: getAutocompleteSelectedByForm(memberForm, current!.memberId),
    help_needed: teamManifestsParams.need_help ? 'yes' : undefined,
  };
  const teamManifests = useInfinite<DailyReportItem, UseTeamManifestsParams>({
    keyFieldName: 'object_id',
    useQuery: useTeamManifests,
    queryParams: teamManifestsQueryParams,
    transformData: parseDailyReportItemResponse,
  });
  const hasNeedHelp = !!(
    teamManifests.metadata as TeamManifestsResponse['meta']
  )?.need_help_count;

  function getAutocompleteSelectedByForm(
    form: FormStateRet<AutocompleteFormState>,
    members?: string,
  ) {
    let values: string[] = Object.keys(form.formState.checked || {}).filter(
      (key) => {
        return form.formState.checked[key];
      },
    );
    return values.length ? values.join(',') : members ? members : undefined;
  }

  const renderChipFilters = (
    label: string,
    form: FormStateRet<AutocompleteFormState>,
  ) => {
    const selected = Object.keys(form.formState.checked || {}).filter((key) => {
      return form.formState.checked[key];
    });
    return selected.map((id) => {
      const option = form.formState.optionById[id];
      return (
        <Chip
          key={option.id}
          size='small'
          label={
            <>
              <span style={{ opacity: 0.4 }}>{label}:</span>{' '}
              <strong>{option.value}</strong>
            </>
          }
          sx={{
            fontSize: 12,
            background: 'transparent',
          }}
          onDelete={() => {
            form.updateState((state: AutocompleteFormState) => {
              const stateChecked = state.checked || {};
              const stateOptionById = state.optionById || {};
              const checked = !stateChecked[option.id];
              const newState = {
                ...state,
                checked: {
                  ...stateChecked,
                  [option.id]: checked,
                },
                optionById: {
                  ...stateOptionById,
                  [option.id]: option,
                },
              };
              delete newState.checked[option.id];
              delete newState.optionById[option.id];

              return newState;
            });
          }}
        />
      );
    });
  };

  const renderFilterResults = () => {
    let hasFilters = [
      ...Object.values(memberForm.formState.checked || {}),
    ].some((v) => !!v);

    if (!hasFilters) return null;

    return (
      <Stack
        gap={1}
        direction='column'
        alignItems='flex-start'
        sx={{ p: 3, pt: 2, pb: 1.5 }}
      >
        <Stack
          gap={1}
          flex={1}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          flexWrap='wrap'
          style={{ marginLeft: -8 }}
        >
          {renderChipFilters('Member', memberForm)}
        </Stack>
        <Divider sx={{ opacity: 0.8, flex: 1, width: '100%' }} />
        <M3Button
          variant='outlined'
          size='small'
          onClick={() => {
            memberForm.resetState();
          }}
          sx={{
            mt: 0.6,
            height: 30,
          }}
        >
          <Typography fontWeight={500} fontSize={12}>
            Clear Filters
          </Typography>
        </M3Button>
      </Stack>
    );
  };

  const dailyReportItems: DailyReportItemExpanded[] =
    teamManifests.data as DailyReportItemExpanded[];

  useEffect(() => {
    setUniqueIdsToFetch({
      employee_ids: dailyReportItems.map((dr) => dr.submitted_by.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyReportItems]);

  useEffect(() => {
    memberForm.resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    setTeamManifestsParams({
      need_help: isNeedHelpActive,
      date_start: dateForm.formState.start!,
      date_end: dateForm.formState.end!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForm.formState.start, dateForm.formState.end, isNeedHelpActive]);

  useEffect(() => {
    teamManifests.reset({
      emptyList: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    current,
    dateForm.formState.start,
    dateForm.formState.end,
    getAutocompleteSelectedByForm(memberForm) || null, // eslint-disable-line react-hooks/exhaustive-deps
    teamManifestsParams.need_help,
  ]);

  /**
   * Listen when sod/eod was submitted by current user
   */
  useEffect(() => {
    teamManifests.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActionKeyCounter('sod_eod_submission')]);

  return (
    <>
      <DocumentTitle title='Manifest' />
      <MainContainer
        sx={{
          maxWidth: null,
          p: 2,
          pt: 1.5,
          position: 'relative',
          minHeight: `calc(100vh - ${subToolbarHeight}px)`,
        }}
      >
        <AppActionToolbar>
          <FilterColumnPanel
            name='date'
            label='Date'
            displayValue={`${moment(dateForm.formState.start).format(
              'MMM D, YYYY',
            )} - ${moment(dateForm.formState.end).format('MMM D, YYYY')}`}
            sx={{ ml: -1 }}
            paperSx={{ ml: 1 }}
            textFieldSx={{ width: 240 }}
          >
            <DateRangeSelectionPopover
              startDate={dateForm.formState.start}
              endDate={dateForm.formState.end}
              onChange={({ start, end }) => {
                posthog.capture('manifest date filter clicked');
                dateForm.updateState((state) => ({ ...state, start, end }));
              }}
            />
          </FilterColumnPanel>
          <AutocompleteSelectionPopover
            width={240}
            title='Members'
            placeholder='Select members'
            options={
              current!.id === 'my-squad'
                ? current!.members!.map((member) => ({
                    id: member.id,
                    value: member.name,
                  }))
                : (filterMember.data || []).map((m) => ({
                    id: m.employee_id,
                    value: m.member_name,
                  }))
            }
            onEventClick={() =>
              posthog.capture('manifest members filter clicked', {
                id: current?.id,
              })
            }
            form={memberForm}
          />
          <Box
            flex={1}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            {hasNeedHelp && (
              <Box position='relative'>
                <M3Button
                  disableRipple
                  onClick={() => setIsNeedHelpActive((s) => !s)}
                  active={isNeedHelpActive}
                  sx={{
                    '&.active': {
                      color: isDarkMode
                        ? 'var(--md-sys-color-on-background-light) !important'
                        : undefined,
                      background: 'var(--md-ref-palette-tertiary80)',
                      '.MuiSvgIcon-root': {
                        color: isDarkMode
                          ? 'var(--md-sys-color-on-background-light) !important'
                          : undefined,
                      },
                      '&:hover': {
                        background: 'var(--md-ref-palette-tertiary90)',
                      },
                    },
                  }}
                >
                  <AssistantPhotoOutlinedIcon />
                  Need Help
                </M3Button>
              </Box>
            )}
          </Box>
        </AppActionToolbar>
        {renderFilterResults()}
        <Box sx={{ p: 2 }}>
          {!dailyReportItems.length &&
            teamManifests.isLoading &&
            new Array(3)
              .fill(null)
              .map((_, i) => <MyDayCardSkeleton key={i} />)}
          {!teamManifests.isLoading && !dailyReportItems.length && (
            <PageWait
              title={`No logs found`}
              message={`Sorry, we could not find any logs on the date specified.`}
            />
          )}
          {dailyReportItems.map((item, i) => {
            return (
              <MyDayCard
                key={item.object_id}
                index={i}
                item={item}
                contentType={contentTypes.report}
                currentProfile={currentProfile}
              />
            );
          })}
          <LoadMorePlaceholder
            hasReachEnd={teamManifests.hasReachEnd}
            isLoading={teamManifests.isLoadingMore}
          />
        </Box>
      </MainContainer>
      <InfiniteScrollListener onReachBottom={() => teamManifests.loadNext()} />
      <WindowScrollTop
        deps={[
          current,
          dateForm.formState.start,
          dateForm.formState.end,
          getAutocompleteSelectedByForm(memberForm) || null,
          teamManifestsParams.need_help,
        ]}
      />
    </>
  );
};

export default TeamManifests;
