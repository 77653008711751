import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import SettingsPersonalProfile from './SettingsPersonalProfile';
import EditTime from './EditTime';

import { usePathnameSegments } from '../hooks/utils/url';
import { getFromSessionStorage } from '../utils/sessionstorage';
import { useIsPathBetaUser } from '../hooks/utils/user';

type SettingsPersonalProps = {};

export type SettingsPersonalSegments = 'profile';

const SettingsPersonal = (props: SettingsPersonalProps) => {
  const [, , nextSegment] = usePathnameSegments<SettingsPersonalSegments[]>();

  const isPathBetaUser = useIsPathBetaUser();

  const impersonation = useMemo(
    () => getFromSessionStorage('impersonation'),
    [],
  );

  if (nextSegment === 'profile') {
    return <SettingsPersonalProfile />;
  }

  if (nextSegment === 'edit-time' && !isPathBetaUser && !impersonation) {
    return <EditTime />;
  }

  return <Navigate to='/settings/personal/profile' />;
};

export default SettingsPersonal;
