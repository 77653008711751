import { useMutationApi } from './global/useApi';

export type UseObtainImpersonateTokenResult = {
  email: string;
  employee_id: string;
  token: string;
  user_id: number;
};
export function useObtainImpersonateToken<D>() {
  return useMutationApi<D, UseObtainImpersonateTokenResult>(
    `/obtain-impersonate-token/`,
  );
}
