import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import InboxDailyReportDetail from './InboxDailyReportDetail';
import InboxRequestDetail from './InboxRequestDetail';
import InboxHelpNeededDetail from './InboxHelpNeededDetail';
import InboxJobDescriptionDetail from './InboxJobDescriptionDetail';
import Error from './Error';
import MainContainer from '../components/MainContainer';
import InboxNextUnread from '../components/Inbox/InboxNextUnread';
import InboxNextPrevNavigation from '../components/Inbox/InboxNextPrevNavigation';
import { InboxNotificationItem } from './InboxAll';
import InboxChatDetail from './InboxChatDetail';
import InboxScheduleRequestDetail from './InboxScheduleRequestDetail';

import { useNotificationProvider } from '../providers/notification/notification';
import { roundUpNearest } from '../utils/number';
import { useInfinite } from '../hooks/global/useInfinite';
import { PTONotificationCommentItem } from '../types/pto';
import { JobDescriptionNotificationItem } from '../types/job-description';
import {
  DailyReportNotificationItem,
  HelpNeededReportNotificationItem,
} from '../types/report';
import {
  useMarkReadAllNotifications,
  useNotifications,
  UseNotificationsProps,
} from '../hooks/notification';
import { parseURL, toURL } from '../utils/url';
import { useAppProvider } from '../providers/app/app';
import { Go2BotsNotificationItem } from '../types/go2bots';
import { ScheduleChangeRequestNotificationItem } from '../types/schedules';

type Props = {};

type TopicParams = {
  type:
    | 'daily-report'
    | 'request'
    | 'job-description'
    | 'go2bots'
    | 'help-needed'
    | 'schedule-change-request';
  ptoId?: string;
  sodEodId?: string;
  jobDescriptionId?: string;
  go2botsId?: string;
  scheduleBatchId?: string;
  id: string;
};

type ParseUrlParams = {
  notification_id?: string;
};

const InboxAllDetail = (props: Props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { inboxItemState } = useNotificationProvider();
  const { updateActionKeyCounter } = useAppProvider();

  const {
    type,
    id,
    ptoId,
    sodEodId,
    jobDescriptionId,
    go2botsId,
    scheduleBatchId,
  } = useParams<TopicParams>();
  const { notification_id } = parseURL<ParseUrlParams>(search);

  const [, segment] = pathname.split('/').filter((v) => !!v);
  const isFromAllPage = segment === 'all';

  const getIdBySegmentType = () => {
    if ((segment === 'request' && ptoId) || type === 'request') {
      return segment === 'request' && ptoId ? ptoId : id;
    }

    if ((segment === 'daily-report' && sodEodId) || type === 'daily-report') {
      return segment === 'daily-report' && sodEodId ? sodEodId : id;
    }

    if (
      (segment === 'job-description' && jobDescriptionId) ||
      type === 'job-description'
    ) {
      return segment === 'job-description' && jobDescriptionId
        ? jobDescriptionId
        : id;
    }

    if ((segment === 'help-needed' && sodEodId) || type === 'help-needed') {
      return segment === 'help-needed' && sodEodId ? sodEodId : id;
    }

    if ((segment === 'go2bots' && go2botsId) || type === 'go2bots') {
      return segment === 'go2bots' && go2botsId ? go2botsId : id;
    }

    if (
      (segment === 'schedule-change-request' && scheduleBatchId) ||
      type === 'schedule-change-request'
    ) {
      return segment === 'schedule-change-request' && scheduleBatchId
        ? scheduleBatchId
        : id;
    }

    /**
     * These are just fallbacks just for any value, ideally, it should not reach here.
     * TODO: Maybe throw an exception if it did reach here
     */
    return (
      ptoId ||
      sodEodId ||
      jobDescriptionId ||
      go2botsId ||
      scheduleBatchId ||
      id
    );
  };

  /**
   * Currently the truth source id for either pto or sod/eod
   */
  const srcId = getIdBySegmentType();

  /**
   * TODO: initial query list we might have to change later on to set back
   * limit if it reaches more than 10
   */
  const [initialQueryLimit] = useState(() => {
    const initial = inboxItemState?.listQuery.limit;
    const itemIndex = inboxItemState?.itemIndex!;
    return {
      initial: initial,
      current: roundUpNearest(
        (itemIndex < +initial! ? initial : itemIndex) ?? 10,
        initial ?? 10,
      ),
    };
  });

  /**
   * Fetch list of notifications / messages of inbox to be used for next/prev message
   */
  const infinite = useInfinite<InboxNotificationItem, UseNotificationsProps>({
    useQuery: useNotifications,
    queryParams: {
      ...inboxItemState?.listQuery,
      limit: initialQueryLimit.current,
      key: `all_list_detailed_${srcId}_${
        inboxItemState?.tabIndex === 0 ? 'unread' : 'all'
      }`,
      status: inboxItemState?.tabIndex === 0 ? 'unread' : 'all',
    },
    skipFetchOnInit: true,
    keyFieldName: 'refId',
  });

  /**
   * State of current notification, newer or/and older notification
   */
  const notificationState = useMemo(() => {
    const index = infinite.data.findIndex(
      (item) => +item.items[0].id === +notification_id!,
    );
    if (inboxItemState) {
      /**
       * When coming from unread messages tag, let's find the first notification
       * that doesn't have read yet
       */
      if (inboxItemState.tabIndex === 0) {
        const newer = infinite.data.find(
          (item) => +item.id !== +notification_id! && item.items[0].unread,
        );
        return {
          index,
          count: index + 1,
          item: infinite.data[index],
          newerItem: newer,
          olderItem: null,
        };
      }
      // When coming from all read messages tab
      else if (index > -1) {
        const newer = infinite.data[index - 1];
        const older = infinite.data[index + 1];
        return {
          index,
          count: index + 1,
          item: infinite.data[index],
          newerItem: newer,
          olderItem: older,
        };
      }
    }
    return {
      index: -1,
      count: 0,
      item: null,
      newerItem: null,
      olderItem: null,
    };
  }, [inboxItemState, infinite.data, notification_id]);

  const notificationIds = notificationState.item?.items
    .map((item) => item.id)
    .join(',')!;

  /**
   * Hook handler for marking the notification as read
   */
  const markNotification = useMarkReadAllNotifications({
    ids: notificationIds,
  });

  const getNotificationItemURLParams = (item: InboxNotificationItem) => {
    if (
      item.topic === 'timeoff' ||
      item.items[0].verb === 'commented_bhrtimeoffrequests'
    ) {
      return {
        segment: 'request',
        id:
          item.items[0].verb === 'commented_bhrtimeoffrequests'
            ? (item as PTONotificationCommentItem).items[0].metadata.object_id
            : item.items[0].metadata.id,
        notificationId: item.id,
      };
    }
    // help needed
    else if (item.topic === 'help_needed') {
      return {
        segment: 'help-needed',
        id: (item as HelpNeededReportNotificationItem).items[0].metadata.data
          .sod?.id,
        notificationId: item.id,
      };
    }

    // job-description
    else if (item.items[0].verb === 'commented_jobdescription') {
      return {
        segment: 'job-description',
        id: (item as JobDescriptionNotificationItem).items[0].metadata
          .object_id,
        notificationId: item.id,
      };
    }
    // go2bots
    else if (item.topic === 'go2botsmessage') {
      return {
        segment: 'go2bots',
        id: (item as Go2BotsNotificationItem).items[0].metadata.object_id,
        notificationId: item.id,
      };
    }
    // schedule request
    else if (item.topic === 'schedule_change_request') {
      return {
        segment: 'schedule-change-request',
        id: (item as ScheduleChangeRequestNotificationItem).items[0].metadata
          .batch_id,
        notificationId: item.id,
      };
    }
    // daily report comment
    else {
      return {
        segment: 'daily-report',
        id:
          item.items[0].verb === 'commented_report'
            ? (item as DailyReportNotificationItem).items[0].metadata.object_id
            : item.items[0].metadata.id,
        notificationId: item.id,
      };
    }
  };

  const onNewerClick = () => {
    if (!inboxItemState || !notificationState.newerItem) return;

    const { segment, id, notificationId } = getNotificationItemURLParams(
      notificationState.newerItem,
    );
    const url = toURL(
      `/inbox${isFromAllPage ? '/all' : ''}/${segment}/${id}/read`,
      {
        t: inboxItemState.tabIndex,
        notification_id: notificationId,
      },
    );
    navigate(url, { replace: true });
  };

  const onOlderClick = () => {
    if (!inboxItemState) return;

    /**
     * Check if there's no older item yet, try to fetch more of the
     * old messages if it doesn't reach the count yet
     */
    if (!notificationState.olderItem) {
      if (infinite.data.length < infinite.count) {
        infinite.loadNext();
      }
      return;
    }

    const { segment, id, notificationId } = getNotificationItemURLParams(
      notificationState.olderItem,
    );
    const url = toURL(
      `/inbox/${isFromAllPage ? 'all/' : ''}${segment}/${id}/read`,
      {
        t: inboxItemState.tabIndex,
        notification_id: notificationId,
      },
    );
    navigate(url, { replace: true });
  };

  /**
   * When coming from unread messages, we don't allow navigating through
   * its messages since it will break the counting and the navigation messes up
   */
  const onNextUnreadClick = () => {
    if (!inboxItemState) return;

    /**
     * Check if there's no newer item yet
     */
    if (!notificationState.newerItem) {
      return;
    }

    const { segment, id, notificationId } = getNotificationItemURLParams(
      notificationState.newerItem,
    );
    const url = toURL(
      `/inbox/${isFromAllPage ? 'all/' : ''}${segment}/${id}/read`,
      {
        t: inboxItemState.tabIndex,
        notification_id: notificationId,
      },
    );
    navigate(url, { replace: true });
  };

  const renderInboxNextPrevNavigation = () => {
    if (!inboxItemState) return null;

    if (inboxItemState.tabIndex === 0) {
      return (
        <InboxNextUnread
          total={infinite.count}
          isLoading={infinite.isLoadingMore}
          onNextUnreadClick={onNextUnreadClick}
        />
      );
    }

    return (
      <InboxNextPrevNavigation
        current={notificationState.count}
        total={infinite.count}
        onNewerClick={onNewerClick}
        onOlderClick={onOlderClick}
        isLoading={infinite.isLoadingMore}
      />
    );
  };

  /**
   * On initial mount, fetch only if there's aan inbox item state,
   * This will ensure the user has been navigating on one of the item in the list
   * before going into the detail page
   */
  useEffect(() => {
    if (inboxItemState) {
      infinite.reset({
        emptyList: true,
      });
    }
  }, [inboxItemState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Check if it's loading more successfully, then redirect to an older
   * notification item after successfully fetch new items
   */
  useEffect(() => {
    if (infinite.savedMoreCounter > 0) {
      onOlderClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infinite.savedMoreCounter]);

  /**
   * Make message as read when viewing the detailed page of inbox
   */
  useEffect(() => {
    if (notificationIds) {
      markNotification.mutate(notificationIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationIds]);

  /**
   * When marking the notification as read,
   * dispatch a call to update notifications count
   */
  useEffect(() => {
    if (markNotification.isSuccess) {
      updateActionKeyCounter('fetch_all_notification_count');

      /**
       * This will ensure that we get the latest unread messages to navigate
       */
      if (inboxItemState?.tabIndex === 0) {
        infinite.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxItemState, markNotification.isSuccess]);

  if ((segment === 'request' && ptoId) || type === 'request') {
    return (
      <InboxRequestDetail
        key={getIdBySegmentType()}
        isFromAllPage={isFromAllPage}
        ptoIdSrc={getIdBySegmentType()}
        nextPrevNavigation={renderInboxNextPrevNavigation()}
      />
    );
  }

  if ((segment === 'daily-report' && sodEodId) || type === 'daily-report') {
    return (
      <InboxDailyReportDetail
        key={getIdBySegmentType()}
        isFromAllPage={isFromAllPage}
        objectId={getIdBySegmentType()}
        nextPrevNavigation={renderInboxNextPrevNavigation()}
      />
    );
  }

  if (
    (segment === 'job-description' && jobDescriptionId) ||
    type === 'job-description'
  ) {
    return (
      <InboxJobDescriptionDetail
        key={getIdBySegmentType()}
        isFromAllPage={isFromAllPage}
        objectId={getIdBySegmentType()}
        nextPrevNavigation={renderInboxNextPrevNavigation()}
      />
    );
  }

  if ((segment === 'help-needed' && sodEodId) || type === 'help-needed') {
    return (
      <InboxHelpNeededDetail
        key={getIdBySegmentType()}
        isFromAllPage={isFromAllPage}
        objectId={getIdBySegmentType()}
        nextPrevNavigation={renderInboxNextPrevNavigation()}
      />
    );
  }

  if ((segment === 'go2bots' && go2botsId) || type === 'go2bots') {
    return (
      <InboxChatDetail
        key={getIdBySegmentType()}
        isFromAllPage={isFromAllPage}
        objectId={getIdBySegmentType()}
        nextPrevNavigation={renderInboxNextPrevNavigation()}
      />
    );
  }

  if (
    (segment === 'schedule-change-request' && go2botsId) ||
    type === 'schedule-change-request'
  ) {
    return (
      <InboxScheduleRequestDetail
        key={getIdBySegmentType()}
        isFromAllPage={isFromAllPage}
        scheduleBatchId={getIdBySegmentType()}
        nextPrevNavigation={renderInboxNextPrevNavigation()}
      />
    );
  }

  return (
    <MainContainer sx={{ maxWidth: null }}>
      <Error title='Not Found' />
    </MainContainer>
  );
};

export default InboxAllDetail;
