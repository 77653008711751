import { Divider, SxProps } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';
import React, {
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { M3IconButton } from './M3/M3Button';
import { IterableObject, ReactRenderElement } from '../types/types';
import { useAppProvider } from '../providers/app/app';

type Props = {
  children?: ReactRenderElement;
  afterChildren?: ReactRenderElement;
  header?: ReactRenderElement;
  headerSx?: SxProps;
  footer?: ReactRenderElement;
  footerSx?: SxProps;
  willUpdateLayout?: boolean | any;
  style?: CSSProperties;
  sx?: SxProps;
  bodySx?: SxProps;
  bodyStyle?: CSSProperties;
  winHeightFactor?: number;
  fullView?: boolean;
  onSetScrollElementRef?: (element: HTMLDivElement | null | undefined) => void;
  onLayoutChange?: (layoutStyle: IterableObject<CSSProperties>) => void;
} & ModalCardViewCloseProps;

type ForwardProps = PropsWithChildren &
  ModalCardViewCloseProps & {
    sx?: SxProps;
    isDarkMode: boolean;
  };

export type ModalCardViewCloseProps = {
  animateOnMount?: boolean;
  close?: () => void;
};

const modalSx: SxProps = {
  top: '50%',
  left: '50%',
  minWidth: 600,
  maxWidth: 900,
  bgcolor: '#fff',
  overflow: 'hidden',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100vw - 20%)',
  maxHeight: 'calc(100vh - 40px)',
};

const Header = forwardRef(
  ({ sx, close, children, isDarkMode }: ForwardProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          zIndex: 2,
          position: 'relative',
          background: isDarkMode
            ? 'var(--md-ref-palette-primary20)'
            : 'var(--md-ref-palette-primary95)',
        }}
        className='modal-card-view-header-wrapper'
      >
        <Box
          sx={{ p: 4, pl: 2.8, pr: 2.8, pb: 0, ...sx }}
          style={{
            paddingRight: 68,
          }}
        >
          {children}
        </Box>
        {!!close && (
          <M3IconButton
            onClick={close}
            sx={{
              top: 8,
              right: 0,
              height: 48,
              width: 48,
              margin: '0 10px',
              position: 'absolute',
            }}
          >
            <CloseOutlinedIcon />
          </M3IconButton>
        )}
        <Divider sx={{ borderWidth: 2 }} />
      </Box>
    );
  },
);

const Footer = forwardRef(
  ({ sx, children, isDarkMode }: ForwardProps, ref: any) => {
    return (
      <>
        <Box
          ref={ref}
          sx={{
            zIndex: 2,
            background: isDarkMode
              ? 'var(--md-ref-palette-primary20)'
              : 'var(--md-ref-palette-primary95)',
            ...sx,
          }}
          className='modal-card-view-footer-wrapper'
        >
          <Divider />
          {children}
        </Box>
      </>
    );
  },
);

const localBodySx = { p: 4, pt: 0, overflow: 'hidden auto' };

const ModalCardView = ({
  header,
  headerSx,
  children,
  afterChildren,
  footer,
  footerSx,
  willUpdateLayout,
  style,
  close,
  sx,
  bodySx,
  winHeightFactor = 1,
  bodyStyle,
  onSetScrollElementRef,
  animateOnMount,
  onLayoutChange,
  fullView,
}: Props) => {
  const { isDarkMode } = useAppProvider();
  const modalRef = useRef<HTMLDivElement | null>();
  const headerRef = useRef<HTMLDivElement | null>();
  const bodyRef = useRef<HTMLDivElement | null>();
  const footerRef = useRef<HTMLDivElement | null>();
  const [localBodyStyle, setBodyStyle] = useState<CSSProperties>({});
  const [layoutStyle, setLayoutStyle] = useState<IterableObject<CSSProperties>>(
    {},
  );
  const [modalAnimationStyle, setModalAnimationStyle] = useState<CSSProperties>(
    {
      opacity: animateOnMount ? 0 : 1,
    },
  );

  function onCheckLayout() {
    function updateLayout() {
      let winHeight = window.innerHeight;
      let modalHeight = winHeight * winHeightFactor;
      let headerHeight = headerRef.current?.clientHeight ?? 0;
      let footerHeight = footerRef.current?.clientHeight ?? 0;
      let bodyHeight = bodyRef.current?.clientHeight;

      if (!modalHeight) return;

      // NOTE: Top and Bottom gap matches the `calc(100vh - 40px)` in css
      const topBottomGap = fullView ? 0 : 40;
      const bodyStyle = {
        maxHeight: modalHeight - topBottomGap - headerHeight - footerHeight,
      };

      setBodyStyle((css: CSSProperties) => ({
        ...css,
        ...bodyStyle,
      }));

      setLayoutStyle((layout) => ({
        ...layout,
        body: {
          ...layout.body,
          ...bodyStyle,
          height: bodyHeight,
        },
      }));
    }

    const resizeObserver = new ResizeObserver(updateLayout);
    const bodyEl = bodyRef.current as unknown as Element;

    updateLayout();

    window.addEventListener('resize', updateLayout, false);
    resizeObserver.observe(bodyEl);

    return () => {
      window.removeEventListener('resize', updateLayout, false);
      resizeObserver.unobserve(bodyEl);
    };
  }

  useEffect(() => {
    onSetScrollElementRef?.(bodyRef.current);
  }, [onSetScrollElementRef, bodyRef]);

  useLayoutEffect(onCheckLayout, [
    modalRef,
    headerRef,
    bodyRef,
    footerRef,
    winHeightFactor,
    willUpdateLayout,
    fullView,
  ]);

  // eslint-disable-next-line
  useLayoutEffect(onCheckLayout, [willUpdateLayout, winHeightFactor]);

  useLayoutEffect(() => {
    onLayoutChange?.(layoutStyle);
  }, [onLayoutChange, layoutStyle]);

  useEffect(() => {
    if (animateOnMount) {
      setModalAnimationStyle((state) => ({
        ...state,
        opacity: 1,
        transition: '100ms',
      }));
    }

    return () => {
      setModalAnimationStyle((state) => ({
        ...state,
        transition: 'none',
      }));
    };
  }, [animateOnMount]);

  return (
    <Box
      sx={{
        ...modalSx,
        bgcolor: isDarkMode ? 'var(--md-sys-color-background-dark)' : '#fff',
        ...(fullView
          ? {
              maxWidth: '100%',
              height: '100%',
              width: '100%',
              maxHeight: '100%',
            }
          : {}),
        ...sx,
      }}
      style={{
        borderRadius: '8px 8px 8px 8px',
        boxShadow:
          '0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%)',
        ...modalAnimationStyle,
        ...style,
      }}
      ref={modalRef}
      className='modal-card-view-wrapper'
    >
      {!!header && (
        <Header
          ref={headerRef}
          sx={headerSx}
          close={close}
          isDarkMode={isDarkMode}
        >
          {header}
        </Header>
      )}
      <Box
        className='modal-card-view-body-container'
        sx={{ position: 'relative' }}
      >
        <Box
          ref={bodyRef}
          sx={{ ...localBodySx, ...bodySx }}
          style={{ ...localBodyStyle, ...bodyStyle }}
          className={clsx('modal-card-view-body-wrapper', {
            'dark-mode-card-view-body': isDarkMode,
          })}
        >
          <Box className='modal-card-view-body-content'>{children}</Box>
        </Box>
        {!!afterChildren && (
          <Box
            style={{
              ...localBodyStyle,
              ...bodyStyle,
            }}
            className='modal-card-view-body-after'
          >
            {afterChildren}
          </Box>
        )}
      </Box>
      {!!footer && (
        <Footer ref={footerRef} sx={footerSx} isDarkMode={isDarkMode}>
          {footer}
        </Footer>
      )}
    </Box>
  );
};

export default ModalCardView;
