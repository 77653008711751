import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { useForm } from '../../BasicForm';
import { M3TextField } from '../../M3/M3TextField';
import { M3IconButton } from '../../M3/M3Button';
import { useAppProvider } from '../../../providers/app/app';
import { usePatchJobDescription } from '../../../hooks/jobs';

type Props = {
  jobDescriptionId?: number | null;
  title?: string;
  editable?: boolean;
  withSave?: boolean;
  onChange?: (value: string) => void;
  onSaved?: () => void;
};

const JobDescriptionTitleForm = ({
  jobDescriptionId,
  title: initialTitle,
  withSave,
  editable,
  onChange,
  onSaved,
}: Props) => {
  const { isDarkMode } = useAppProvider();

  const { formState, handleChange, hasChanged, resetState } = useForm(
    { title: initialTitle ?? '' },
    {
      onStateUpdated: (state) => {
        onChange?.(state.title);
      },
    },
  );

  const patchJobDescription = usePatchJobDescription({ id: jobDescriptionId! });
  const disableSubmit = patchJobDescription.isLoading;

  const onSave = () => {
    if (
      !editable ||
      !withSave ||
      !jobDescriptionId ||
      patchJobDescription.isLoading
    ) {
      return;
    }
    patchJobDescription.mutate({ title: formState.title });
  };

  /**
   * After successfully updated the job description title, set the flag that
   * the job description should refetch the new updated data
   */
  useEffect(() => {
    if (patchJobDescription.isSuccess) {
      onSaved?.();
      resetState({ title: patchJobDescription.data.title });
      patchJobDescription.reset();
    }
    // eslint-disable-next-line
  }, [patchJobDescription.isSuccess]);

  return (
    <Stack
      mb={1}
      gap={3}
      flex={1}
      direction='row'
      minWidth={450}
      maxWidth='80%'
    >
      {editable ? (
        <M3TextField
          fullWidth
          name='title'
          placeholder='Type Job Title Here'
          value={formState.title}
          onChange={handleChange}
          sx={{
            '.MuiInputBase-input': {
              fontSize: 33,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 1,
              fontWeight: 500,
            },
            '.MuiOutlinedInput-notchedOutline': {
              fontSize: 36,
              borderTop: '0 !important',
              borderLeft: '0 !important',
              borderRight: '0 !important',
              borderRadius: 0,
            },
          }}
        />
      ) : (
        <Typography
          top={-4}
          component='h5'
          fontSize={33}
          minHeight={50}
          fontWeight={500}
          whiteSpace='nowrap'
          position='relative'
          sx={{
            ':before': {
              left: 0,
              bottom: 0,
              height: 3,
              width: '100%',
              content: '""',
              opacity: 0.5,
              borderRadius: 1,
              position: 'absolute',
              backgroundColor: isDarkMode
                ? 'var(--md-ref-palette-tertiary30)'
                : 'var(--md-ref-palette-tertiary80)',
            },
          }}
        >
          {initialTitle || formState.title}
        </Typography>
      )}
      {(hasChanged || withSave || editable) && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: 46,
            height: 46,
            minWidth: 46,
            maxWidth: 46,
          }}
        >
          {hasChanged && withSave ? (
            <M3IconButton
              color='primary'
              sx={{
                width: 46,
                height: 46,
                background: isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)',
                '&:hover': {
                  background: isDarkMode
                    ? 'var(--md-ref-palette-primary90) !important'
                    : 'var(--md-ref-palette-primary50) !important',
                },
              }}
              disabled={disableSubmit}
              onClick={onSave}
            >
              <DoneOutlinedIcon
                style={{
                  fontSize: 30,
                  color: isDarkMode
                    ? 'var(--md-ref-palette-primary10)'
                    : 'var(--md-ref-palette-primary90)',
                }}
              />
            </M3IconButton>
          ) : (
            editable && (
              <EditOutlinedIcon
                style={{
                  fontSize: 30,
                  opacity: 0.8,
                }}
              />
            )
          )}
        </Box>
      )}
    </Stack>
  );
};

export default JobDescriptionTitleForm;
