import React, { Fragment, useState } from 'react';
import {
  Modal,
  Chip,
  Stack,
  SxProps,
  Typography,
  ChipProps,
} from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { M3Button } from '../M3/M3Button';
import ContentTargetAudienceForm from './ContentTargetAudienceForm';

export type ContentTargetAudiencesProps = {
  withTitle?: boolean;
  items: string[];
  textOnly?: boolean;
  sx?: SxProps;
  chipSx?: SxProps;
  withAddButton?: boolean;
  withCloseButton?: boolean;
  articleId?: string;
};
type RenderChipProps = ChipProps & {
  item: string;
  sx?: SxProps;
  withCloseButton?: boolean;
};

const defaultChipSx: SxProps = {
  borderRadius: 1.2,
  opacity: 0.7,
  '.MuiChip-label': {
    fontWeight: 500,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
};

const renderChip = ({
  item,
  sx,
  withCloseButton,
  ...restProps
}: RenderChipProps) => {
  return (
    <Chip
      label={
        <>
          <GroupsOutlinedIcon
            sx={{
              mr: 1,
              top: -1,
              opacity: 0.7,
              fontSize: 20,
              position: 'relative',
            }}
          />
          {item}
        </>
      }
      size='small'
      sx={{
        ...defaultChipSx,
        ...sx,
      }}
      {...restProps}
    />
  );
};

const ContentTargetAudiences = ({
  withTitle = true,
  items,
  sx,
  chipSx,
  textOnly,
  withAddButton,
  withCloseButton,
  articleId,
}: ContentTargetAudiencesProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderAddButton = () => {
    return (
      <>
        <M3Button
          style={{
            fontSize: 14,
            height: 'initial',
            minHeight: 'initial',
            padding: '0 8px 0 4px',
          }}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <AddOutlinedIcon style={{ fontSize: 18, marginRight: 5 }} />
          Assign Target Audience
        </M3Button>
        <Modal open={isModalOpen}>
          <ContentTargetAudienceForm
            articleId={articleId!}
            close={() => {
              setIsModalOpen(false);
            }}
          />
        </Modal>
      </>
    );
  };

  return (
    <>
      {withTitle && (
        <Typography component='div' fontWeight={500} pb={1}>
          Target audience
        </Typography>
      )}
      <Stack
        pb={2}
        gap={1}
        flexWrap='wrap'
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        sx={sx}
      >
        {textOnly ? (
          <Typography
            component='div'
            fontSize={14}
            sx={{
              opacity: 0.7,
            }}
          >
            {items.join(', ')}
          </Typography>
        ) : (
          items.map((item: string, index) => {
            return (
              <Fragment key={index}>
                {renderChip({ item, sx: chipSx, withCloseButton })}
              </Fragment>
            );
          })
        )}
        {withAddButton && renderAddButton()}
      </Stack>
    </>
  );
};

export default ContentTargetAudiences;
