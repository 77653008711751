import React, { useRef, useState } from 'react';
import { Paper, Box } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import WorkspaceMenuList, { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';
import PerfectScrollbar, { PerfectScrollbarRef } from '../PerfectScrollbar';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';

type Props = {};

const ChatDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const perfectScrollbarRef = useRef<null | PerfectScrollbarRef>(null);
  const [, setScrollYMenuList] = useState(0);

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const menus: MenuItem[] = [
    {
      id: 'headline-chat',
      name: 'Chat',
      icon: <ChatBubbleOutlineOutlinedIcon />,
      headline: true,
    },
    {
      id: 'chat',
      name: 'Send a New Chat',
      basePath: '/chat/message',
      path: '/chat/message',
    },
  ];

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1} sx={{ height: 0 }}>
          <PerfectScrollbar onSetRef={perfectScrollbarRef}>
            <AppDrawerMenuList
              menus={menus}
              onExpanded={() => setScrollYMenuList((s) => ++s)}
            />
          </PerfectScrollbar>
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default ChatDrawer;
