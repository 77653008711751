import React, { forwardRef } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';

import ModalCardView, { ModalCardViewCloseProps } from './ModalCardView';
import UserProfilePopover from './UserProfilePopover';
import EODOverviewTooltip from './SodEodForm/EODOverviewTooltip';

import { UserMetadata } from '../types/profile';
import { DailyReportEODDataKPI } from '../types/report';
import { useTaskById } from '../hooks/task';
import { getUserInitials, getUserPhotoUrl } from '../utils/user';
import moment from 'moment';
import TaskDetailView from './TaskDetails/TaskDetailView';

type Props = ModalCardViewCloseProps & {
  sodId?: number | null;
  date: string;
  user: UserMetadata;
  task: Partial<DailyReportEODDataKPI>;
  questions?: { id: string; question: string; answer: string }[];
};

export const TaskDetailModalViewBase: React.FC<Props> = ({
  sodId,
  date,
  user,
  task,
  close,
}) => {
  const taskId = task.task_id || task.taskId;

  const { data: taskData } = useTaskById(
    { id: taskId! },
    { enabled: !!taskId },
  );
  const eodForm = taskData?.eod_form;

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <WysiwygOutlinedIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Box display='flex' flexWrap='nowrap'>
            <Typography
              display='flex'
              alignItems='center'
              flexWrap='nowrap'
              fontSize={20}
              fontWeight={500}
              component='div'
            >
              <span className='text-truncate' style={{ maxWidth: 420 }}>
                {task.task_name || task.taskName}
              </span>
              {typeof task.pct_time_spent !== 'undefined' && (
                <>
                  <span style={{ opacity: 0.5, padding: '0 8px' }}>-</span>
                  <span>{`${((task.pct_time_spent || 0) * 100).toFixed(
                    2,
                  )}%`}</span>
                </>
              )}
            </Typography>
            <EODOverviewTooltip
              overview={eodForm?.overview}
              desiredOutcome={eodForm?.desired_outcome}
              successMeasurement={eodForm?.success_measurement}
            />
          </Box>
          <Box display='flex' ml={-3.2} pt={0.5} mb={-1}>
            <Typography
              flex={1}
              fontSize={16}
              component='div'
              display='flex'
              fontWeight={500}
              alignItems='center'
            >
              <span style={{ fontSize: 12, opacity: 0.5 }}>By:</span>
              <UserProfilePopover user={user}>
                <Typography
                  fontSize={16}
                  component='div'
                  display='flex'
                  fontWeight={500}
                  alignItems='center'
                >
                  <Avatar
                    sx={{
                      mr: 1,
                      ml: 1,
                      width: 30,
                      height: 30,
                      fontSize: 14,
                    }}
                    src={getUserPhotoUrl(user?.photo_url)}
                  >
                    {
                      getUserInitials(user?.preferred_name ?? user?.first_name)
                        .initial
                    }
                  </Avatar>
                </Typography>
              </UserProfilePopover>
              {user.preferred_name || user.first_name}
            </Typography>
          </Box>
        </Typography>
        <Typography
          component='div'
          sx={{
            right: 22,
            bottom: 18,
            fontSize: 12,
            fontWeight: 500,
            position: 'absolute',
          }}
        >
          <span
            style={{
              opacity: 0.5,
              fontWeight: 400,
            }}
          >
            Date:{' '}
          </span>
          {moment(date).format('MMM D, YYYY')}
        </Typography>
      </Stack>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      close={close}
    >
      <TaskDetailView sodId={sodId} date={date} task={task} user={user} />
    </ModalCardView>
  );
};

const TaskDetailModalView = forwardRef((props: Props, ref) => (
  <TaskDetailModalViewBase {...props} />
));

export default TaskDetailModalView;
