import React from 'react';
import { styled, SxProps } from '@mui/material';
import clsx from 'clsx';

import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import { M3ListItem, M3ListItemProps } from '../M3/M3ListItem';

import { PaletteTypeButtonProps } from '../../types/app';
import { useAppProvider } from '../../providers/app/app';

const InboxCardListItemBase = styled(M3ListItem)`
  transition: 50ms;
  border-radius: 12px;
  background: transparent;

  &:not(.no-hover) {
    box-shadow: 0 0 0 1px var(--md-ref-palette-neutral-variant90);
    &:hover {
      box-shadow: 0 0 0 1px var(--md-ref-palette-neutral-variant90),
        0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &.dark-mode-inbox-card {
    &:not(.no-hover) {
      box-shadow: 0 0 0 1px var(--md-ref-palette-neutral-variant30);
      &:hover {
        box-shadow: 0 0 0 1px var(--md-ref-palette-neutral-variant30),
          0px 2px 1px -1px rgba(255, 255, 255, 0.2),
          0px 1px 1px 0px rgba(255, 255, 255, 0.14),
          0px 1px 3px 0px rgba(255, 255, 255, 0.12);
      }
    }
  }
`;

type InboxCardListItemProps = PaletteTypeButtonProps &
  M3ListItemProps & {
    unread?: boolean;
    active?: boolean;
    surfaceSx?: SxProps;
    borderRadius?: number;
  };
const InboxCardListItem = ({
  palette: paletteKey = 'main',
  sx,
  children,
  unread,
  active,
  surfaceSx,
  className,
  borderRadius = 3,
  ...restProps
}: InboxCardListItemProps) => {
  const { isDarkMode } = useAppProvider();
  return (
    <M3SurfaceContainer
      elevation={active ? 5 : unread ? 1 : 0}
      borderRadius={borderRadius}
      wrapperSx={{
        flex: 1,
        background: null,
      }}
      sx={surfaceSx}
    >
      <InboxCardListItemBase
        sx={{ ...sx }}
        className={clsx(
          {
            'dark-mode-inbox-card': isDarkMode,
          },
          className,
        )}
        {...restProps}
      >
        {children}
      </InboxCardListItemBase>
    </M3SurfaceContainer>
  );
};

export default InboxCardListItem;
