export function gtagEvent(eventName: string, data?: any) {
  const gtag = (window as any).gtag;

  gtag?.(eventName, (window as any).__GA_TRACKING_ID, data);
}

export function gtagPush(eventName: string, data?: any, config: any = {}) {
  const dataLayer = (window as any).dataLayer;

  dataLayer?.push({
    event: eventName,
    ...data,
    config,
  });
}
