import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Delete, Edit } from '@mui/icons-material';
import { M3Card } from '../../../M3/M3Card';
import { M3IconButton } from '../../../M3/M3Button';
import M3SurfaceContainer, { Elevation } from '../../../M3/M3SurfaceContainer';
import { KeysMapType, keysMap } from '../../constants/header-keys';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../../Dialogs/ConfirmationDialog';
import {
  UseFetchTimeDetailsResponse,
  useDeleteWorklogsData,
} from '../../../../hooks/edit-time';
import { timeDetailsCellValueFormatter } from '../../constants/time-details-formatter';
import { styled } from '@mui/material';

import '../TimeDetailsTable/time-details-table.css';
import './time-details-table-row.css';
import { useAppProvider } from '../../../../providers/app/app';
import * as posthog from '../../../../services/posthog';
import moment from 'moment';
import { useCurrentProfile } from '../../../../hooks/profile';

type RowContextType = {
  row: UseFetchTimeDetailsResponse;
  onEditRow: (row: UseFetchTimeDetailsResponse) => void;
  onOpenDialog: () => void;
};
const RowContext = createContext<RowContextType | undefined>(undefined);

/**
 * To check if it's still the current worklog, check if it's the latest entry
 * and the last end it updated hasn't passed the time to the next sync
 */
export function isActiveWorklog(
  row: UseFetchTimeDetailsResponse,
  index: number,
) {
  // Default active worklog sync time in desktop app is now 1 minute
  // so we adjust the time for user to edit about 5 minutes
  const defaultSecondsIntervalInDesktopApp = 300;

  return !row.end
    ? false
    : index === 0 &&
        !!row._id &&
        Math.abs(moment.utc().diff(row.end, 'second')) <
          defaultSecondsIntervalInDesktopApp;
}

/**
 * Calculates the total duration of current worklog, the new logic has now  always an end.
 */
export function getTotalCurrentActiveWorklog(row: UseFetchTimeDetailsResponse) {
  const total = moment().diff(moment.utc(row.start).local(), 'second');

  return {
    value: total,
    formattedValue: timeDetailsCellValueFormatter.duration?.(total),
  };
}

type TimeDetailsTableRowProps = {
  row: UseFetchTimeDetailsResponse;
  index: number;
  prevRow?: UseFetchTimeDetailsResponse | null;
  hasLatestWorklogEntry?: boolean;
  onEditRow: (row: UseFetchTimeDetailsResponse) => void;
  onDeleteSuccess?: () => void;
};
const TimeDetailsTableRow: FC<TimeDetailsTableRowProps> = ({
  row,
  index,
  prevRow,
  hasLatestWorklogEntry,
  onEditRow,
  onDeleteSuccess,
}) => {
  const [rowElevation, setRowElevation] = useState<Elevation>(1);
  const confirmationDialog = useConfirmationDialog();
  const deleteWorklogData = useDeleteWorklogsData(row.id, { method: 'DELETE' });

  const handleOnDeleteRow = () => {
    posthog.capture('edit time delete clicked', { id: row.id });
    deleteWorklogData.mutate();
  };

  const handleOpenConfirmationDialog = () =>
    confirmationDialog.confirm.setIsOpen(true);

  const contextValue = {
    row,
    onEditRow,
    onOpenDialog: handleOpenConfirmationDialog,
  };

  const isActiveRow = isActiveWorklog(row, index);

  const isPotentialIssueWorklog = useMemo(() => {
    if (!row || !prevRow) return false;

    return !row.end;
  }, [row, prevRow]);

  useEffect(() => {
    if (deleteWorklogData.isSuccess) {
      onDeleteSuccess?.();
    }
  }, [deleteWorklogData.isSuccess, onDeleteSuccess]);

  return (
    <RowContext.Provider value={contextValue}>
      <M3Card
        sx={{ mb: '12px' }}
        onMouseEnter={() => setRowElevation(2)}
        onMouseLeave={() => setRowElevation(1)}
      >
        <M3SurfaceContainer
          error={isPotentialIssueWorklog}
          elevation={rowElevation}
          borderRadius={3}
          sx={{
            minHeight: '45px',
            height: '45px',
            p: 2,
            pl: 3,
            pr: 3,
            pt: '10px',
          }}
        >
          <Grid container spacing={1} columns={20}>
            {Object.entries(keysMap).map(([columnKey]) => (
              <TimeDetailsTableRowCell
                key={columnKey}
                isActiveRow={isActiveRow}
                hasLatestWorklogEntry={hasLatestWorklogEntry}
                columnKey={columnKey as keyof KeysMapType}
                potentialIssue={isPotentialIssueWorklog}
              />
            ))}
          </Grid>
        </M3SurfaceContainer>
        <ConfirmationDialog
          {...confirmationDialog.confirm}
          title='Delete Time'
          message={
            <>
              Are you sure you want to delete this Time Entry?
              <br />
              This action cannot be undone and all associated data will be
              permanently lost.
            </>
          }
          onConfirm={handleOnDeleteRow}
          isLoading={deleteWorklogData.isLoading}
        />
      </M3Card>
    </RowContext.Provider>
  );
};

export default TimeDetailsTableRow;

type TimeDetailsTableRowCellProps = {
  columnKey: keyof KeysMapType;
  potentialIssue?: boolean;
  isActiveRow?: boolean;
  hasLatestWorklogEntry?: boolean;
};
const defaultFormatter = (value: any) => value;
const TimeDetailsTableRowCell: FC<TimeDetailsTableRowCellProps> = ({
  columnKey,
  potentialIssue,
  isActiveRow,
  hasLatestWorklogEntry,
}) => {
  const { isDarkMode } = useAppProvider();
  const { row, onEditRow, onOpenDialog } = useContext(RowContext)!;
  const mapItem = keysMap[columnKey];
  const { data: currentProfile } = useCurrentProfile();

  const isEditBreakAllowed = currentProfile?.roles.some(
    (role) => role === 'superuser' || role === 'developer' || role === 'it',
  );

  if (!mapItem) return null;

  const { itemProps } = mapItem;

  const value = row[columnKey as keyof UseFetchTimeDetailsResponse];
  const formatter =
    timeDetailsCellValueFormatter[
      columnKey as keyof UseFetchTimeDetailsResponse
    ] || defaultFormatter;
  let formattedValue = formatter(value!);
  let actionIconButtonStyles = {};

  if (potentialIssue) {
    actionIconButtonStyles = {
      color: isDarkMode
        ? 'var(--md-ref-palette-error80)'
        : 'var(--md-ref-palette-error40)',
    };

    if (columnKey === 'duration' || columnKey === 'end_time_local') {
      formattedValue = '–';
    }
  }

  if (isActiveRow) {
    if (columnKey === 'end_time_local') {
      formattedValue = '–';
    }
  }

  return (
    <>
      <Grid
        item
        key={columnKey}
        className={clsx({
          timeDetailsTableHeaderCell: columnKey === 'notes',
          actionMaxWidth70: columnKey === 'action',
        })}
        {...itemProps}
      >
        {/* disable the action buttons if the task_name is Break */}
        {columnKey === 'action' &&
        (row.task_name === 'Break' ? isEditBreakAllowed : true) ? (
          <Stack direction='row' mt={-0.3}>
            <ActionIconButton
              size='small'
              onClick={() => onEditRow(row)}
              disabled={potentialIssue ? false : isActiveRow}
            >
              <Edit className='actionIconSize' style={actionIconButtonStyles} />
            </ActionIconButton>
            <ActionIconButton
              size='small'
              onClick={onOpenDialog}
              disabled={potentialIssue ? false : isActiveRow}
            >
              <Delete
                className='actionIconSize'
                style={actionIconButtonStyles}
              />
            </ActionIconButton>
          </Stack>
        ) : (
          <Typography
            component='div'
            className={clsx('tableRowCellText', {
              reasonTruncate: columnKey === 'notes',
            })}
            sx={{
              opacity: formattedValue === '–' ? 0.3 : 1,
            }}
            style={{
              color: !potentialIssue
                ? undefined
                : isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          >
            {formattedValue}
          </Typography>
        )}
      </Grid>
    </>
  );
};

const ActionIconButton = styled(M3IconButton)`
  width: 30px;
  height: 30px;
`;
