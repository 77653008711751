import React, { forwardRef } from 'react';
import { Box, Stack, Typography, SxProps } from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import { M3Button } from '../M3/M3Button';

import { ReportType } from '../../types/report';

type SodEodSelectionViewProps = ModalCardViewCloseProps & {
  close?: (type: ReportType) => void;
};

const SodEodSelectionView = forwardRef(
  ({ close }: SodEodSelectionViewProps, ref) => {
    const m3SCSx: SxProps = {
      pl: 4,
      pr: 4,
      height: 112,
      minWidth: 300,
      fontSize: 22,
      lineHeight: 1.3,
    };

    const renderTopPanel = () => {
      return (
        <>
          <Typography
            component='div'
            variant='h6'
            fontWeight={500}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' alignItems='center'>
              End Report Missing
            </Box>
          </Typography>
        </>
      );
    };

    return (
      <ModalCardView
        header={renderTopPanel()}
        headerSx={{ pt: 2, pb: 2 }}
        close={close}
        sx={{ maxWidth: 720 }}
      >
        <Typography component='div' fontSize={18} pt={3}>
          It looks like you missed submitting your end report for your last
          shift. Please choose one of the following options to continue:
        </Typography>
        <Stack
          flexWrap='wrap'
          direction='row'
          alignItems='center'
          justifyContent='center'
          pt={5}
          pb={6}
          gap={5}
        >
          <M3Button
            color='primary'
            variant='contained'
            sx={m3SCSx}
            onClick={() => close?.('EOD')}
          >
            <CreateOutlinedIcon style={{ fontSize: 43, marginRight: 20 }} />
            <div style={{ flex: 1, textAlign: 'left' }}>
              <div>Submit End Report</div>
            </div>
          </M3Button>
          <M3Button
            color='primary'
            variant='contained'
            sx={m3SCSx}
            onClick={() => close?.('SOD')}
          >
            <CreateOutlinedIcon style={{ fontSize: 43, marginRight: 20 }} />
            <div style={{ flex: 1, textAlign: 'left' }}>
              <div>Skip for Today and</div>
              <div>Start New Report</div>
            </div>
          </M3Button>
        </Stack>
      </ModalCardView>
    );
  },
);

export default SodEodSelectionView;
