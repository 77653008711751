import React, { CSSProperties, PropsWithChildren, useState } from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import ContentArticleMenuOptions from './ContentArticleMenuOptions';

import { ContentItemBase } from './Content';
import { useAppProvider } from '../../providers/app/app';
import { IntercomArticle } from '../../types/intercom';

type ContentArticleItemProps = PropsWithChildren &
  ContentItemBase<IntercomArticle> & {
    descriptionStyle?: CSSProperties;
  };

export const ContentArticleItem = ({
  item,
  active = false,
  draggable = true,
  clickable = true,
  withDescription = true,
  withAudience = true,
  audienceSmallVersion,
  onClick,
  descriptionStyle,
  externalLink,
  withEditLink,
  dragHandleProps,
  snapshot,
  sx,
}: ContentArticleItemProps) => {
  const { isDarkMode } = useAppProvider();
  const [isMoreOptionOpen, setIsMoreOptionOpen] = useState(false);

  const renderAudienceSmallerVersion = () => {
    return (
      <Typography
        display='flex'
        component='div'
        fontSize={13}
        sx={{
          opacity: 0.5,
        }}
      >
        <Tooltip
          title={['Leads, Users and Visitors', 'Go2 Members | Internal'].join(
            '; ',
          )}
        >
          <Box display='flex' alignItems='center'>
            <GroupsOutlinedIcon
              sx={{
                mr: 0.5,
                top: -1,
                opacity: 0.7,
                fontSize: 20,
                position: 'relative',
              }}
            />
            + 2
          </Box>
        </Tooltip>
      </Typography>
    );
  };

  const renderAudienceDefaultVersion = () => {
    return (
      <Typography
        display='flex'
        alignItems='flex-start'
        component='div'
        fontSize={13}
        sx={{
          opacity: 0.5,
        }}
      >
        <GroupsOutlinedIcon
          sx={{
            mr: 0.5,
            top: -1,
            opacity: 0.7,
            fontSize: 20,
            position: 'relative',
          }}
        />
        &nbsp;Leads, Users and Visitors + 1 more
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        marginBottom: '8px',
        ...sx,
      }}
    >
      <M3Card
        noHover={!clickable}
        className='m3-card'
        focused={snapshot?.isDragging}
        sx={{ position: 'relative' }}
      >
        <M3SurfaceContainer elevation={active ? 4 : 1}>
          <Paper
            className='collection__paper'
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              position: 'relative',
              cursor: clickable ? 'pointer' : undefined,
            }}
            style={{
              marginTop: 0,
              paddingTop: 11,
              paddingBottom: 11,
              background: 'transparent',
            }}
            onClick={isMoreOptionOpen ? undefined : onClick}
          >
            {draggable && (
              <div {...dragHandleProps}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    top: -1,
                    left: -1,
                    bottom: -1,
                    width: 32,
                    position: 'absolute',
                  }}
                >
                  <DragIndicatorOutlinedIcon sx={{ ml: 0.5, mr: 0.5 }} />
                </Box>
              </div>
            )}
            <Box
              flex={1}
              width={0}
              display='flex'
              alignItems='center'
              style={{
                paddingLeft: draggable ? 30 : 0,
              }}
            >
              <Box flex={2} pr={2} minWidth={200}>
                <Typography
                  fontSize={18}
                  style={{
                    color: isDarkMode
                      ? 'var(--md-ref-palette-primary80)'
                      : 'var(--md-ref-palette-primary40)',
                  }}
                  className='text-truncate'
                >
                  {item.title}
                </Typography>
                {withAudience &&
                  (audienceSmallVersion
                    ? renderAudienceSmallerVersion()
                    : renderAudienceDefaultVersion())}
                {withDescription && !!item.description && (
                  <Typography
                    fontSize={16}
                    sx={{
                      color: 'var(--md-ref-palette-neutral40)',
                    }}
                    style={{
                      maxWidth: 630,
                      padding: '5px 0 0',
                      ...descriptionStyle,
                    }}
                  >
                    {item.description}
                  </Typography>
                )}
              </Box>
              {(withEditLink || externalLink) && (
                <ContentArticleMenuOptions
                  article={item}
                  onOpenChange={setIsMoreOptionOpen}
                />
              )}
            </Box>
          </Paper>
        </M3SurfaceContainer>
      </M3Card>
    </Box>
  );
};

export default ContentArticleItem;
