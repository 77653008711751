import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { Go2BotsMessageItem } from '../../types/go2bots';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { defaultReactQueryParams } from '../../utils/request';
import { useMutationApi, useQueryApi } from '../global/useApi';
import { useIdentifier } from '../global/useIdentifier';

export type UseGo2BotsMessagesProps = ListQuery & {
  created_by?: number;
  user?: number | null;
};
export function useGo2BotsMessages<R = Go2BotsMessageItem>(
  params: UseGo2BotsMessagesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseGo2BotsMessagesProps>({
    params,
    baseIdentifier: 'useGo2BotsMessages',
    identifierKeys: ['created_by', 'user'],
    queryParamKeys: ['created_by', 'user'],
  });

  return useQueryApi<ListQuery, ListResult<R>>(
    identifiers,
    '/go2-bots/messages/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseGo2BotsMessageByIdProps = {
  id: number;
};
export function useGo2BotsMessageById<R = Go2BotsMessageItem>(
  params: UseGo2BotsMessageByIdProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers } = useIdentifier<UseGo2BotsMessageByIdProps>({
    params,
    baseIdentifier: 'useGo2BotsMessageById',
    identifierKeys: ['id'],
  });

  return useQueryApi<ListQuery, R>(
    identifiers,
    `/go2-bots/messages/${params.id}/`,
    {},
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export function usePostGo2BotsMessage(
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi('/go2-bots/messages/', {}, axiosConfig);
}

export function useUpdateGo2BotsMessage<D>(
  id: number,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D>(
    `/go2-bots/messages/${id}/`,
    {},
    { method: 'PATCH', ...axiosConfig },
  );
}

export function useDeleteGo2BotsMessage(
  id?: number,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi(
    `/go2-bots/messages/${id}/`,
    {},
    { method: 'DELETE', ...axiosConfig },
  );
}
