import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

const JobDescriptionDetailSkeleton = () => {
  const renderRichDisplay = () => {
    return (
      <Box mb={5}>
        <Skeleton
          variant='text'
          height={40}
          width={300}
          sx={{ mb: 1, mt: -0.2 }}
        />
        <Skeleton variant='rectangular' height={100} sx={{ borderRadius: 1 }} />
      </Box>
    );
  };

  const renderJobDescriptionDetailForm = () => {
    return (
      <>
        <Stack gap={2} direction='row' justifyContent='space-between' mb={1}>
          <Box flex={1}>
            <Stack direction='row' justifyContent='space-between'>
              <Skeleton
                variant='text'
                height={90}
                width='50%'
                sx={{ mt: -3 }}
              />
              <Box position='relative'>
                <Box
                  top={-23}
                  right={-10}
                  display='flex'
                  position='absolute'
                  flexDirection='column'
                  alignItems='flex-end'
                >
                  <Skeleton variant='text' width={100} sx={{ height: 30 }} />
                  <Skeleton
                    variant='text'
                    width={200}
                    sx={{
                      mt: -1,
                      mb: 0.5,
                      height: 60,
                    }}
                  />
                  <Skeleton variant='text' width={100} sx={{ height: 30 }} />
                </Box>
              </Box>
            </Stack>
            <Stack gap={1} direction='row' marginTop='-2px'>
              <Skeleton variant='circular' sx={{ width: 30, height: 30 }} />
              <Skeleton variant='text' width={100} sx={{ height: 30 }} />
            </Stack>
          </Box>
          <Box display='flex' justifyContent='flex-end'></Box>
        </Stack>
        <Skeleton variant='text' width={150} sx={{ height: 20, mt: 2.4 }} />
      </>
    );
  };

  return (
    <>
      <Box>
        {renderJobDescriptionDetailForm()}
        <br />
        <br />
        {renderRichDisplay()}
        {renderRichDisplay()}
        {renderRichDisplay()}
        {renderRichDisplay()}
      </Box>
    </>
  );
};

export default JobDescriptionDetailSkeleton;
