import React from 'react';
import { Box } from '@mui/material';

import EdgeCornerSvgIcon from './SvgIcons/EdgeCornerSvgIcon';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';

import { useAppProvider } from '../providers/app/app';
import { applyOpacityOnColor } from '../utils/color';

type Props = {};

const EdgeContainerCover = (props: Props) => {
  const {
    toolbarHeight,
    isTopBannerVisible,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  return (
    <Box
      sx={{
        right: -25,
        width: 25,
        height: 31,
        overflow: 'hidden',
        top: toolbarHeight + (isTopBannerVisible ? toolbarHeight : 0),
        position: 'absolute',
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={{
          right: 0,
          bottom: 0,
          width: 50,
          height: 56,
          position: 'absolute',
        }}
      >
        <EdgeCornerSvgIcon
          fillColor={
            isDarkMode
              ? palette['md.sys.color.background.dark']
              : palette['md.sys.color.background.light']
          }
        />
        <Box sx={{ position: 'absolute', left: 0, top: 0 }}>
          <EdgeCornerSvgIcon
            fillColor={applyOpacityOnColor(
              isDarkMode
                ? palette['md.ref.palette.primary80']
                : palette['md.ref.palette.primary40'],
              getSurfaceOpacityByElevation(2),
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EdgeContainerCover;
