import { useMemo } from 'react';
import { useCurrentProfile } from '../profile';

export function useIsPartnerMember() {
  const { data: userProfile } = useCurrentProfile();

  return useMemo(
    () => userProfile?.roles.some((role) => role === 'partner_member') ?? false,
    [userProfile],
  );
}

export function useIsSuperAdmin() {
  const { data: userProfile } = useCurrentProfile();

  return useMemo(
    () => userProfile?.roles.some((role) => role === 'superuser') ?? false,
    [userProfile],
  );
}

export function useIsJobseeker() {
  const { data: userProfile } = useCurrentProfile();

  return useMemo(
    () => userProfile?.roles.some((role) => role === 'jobseekers') ?? false,
    [userProfile],
  );
}

export function useIsDeveloper() {
  const { data: userProfile } = useCurrentProfile();

  return useMemo(
    () => userProfile?.roles.some((role) => role === 'developer') ?? false,
    [userProfile],
  );
}

export function useIsPathBetaUser() {
  const { data: userProfile } = useCurrentProfile();

  return useMemo(
    () => userProfile?.roles.some((role) => role === 'path_beta') ?? false,
    [userProfile],
  );
}
