import React, { useMemo } from 'react';
import { Paper, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import WorkspaceMenuList, { workspaceWidth } from './WorkspaceMenuList';
import AppDrawerMenuList from './AppDrawerMenuList';
import DrawerPermanent from './DrawerPermanent';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';

import { useAppProvider } from '../providers/app/app';
import { MenuItem } from '../types/types';
import { applyOpacityOnColor } from '../utils/color';
import {
  CollectionItemProps,
  getCollectionItemProps,
} from '../providers/intercom';
import { useIntercomProvider } from '../providers/intercom/intercom';

type Props = {};

const HelpCenterDrawer = (props: Props) => {
  const { pathname } = useLocation();
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const { collections, admins } = useIntercomProvider();

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const collectionItems: CollectionItemProps[] = useMemo(() => {
    const items: CollectionItemProps[] = [];
    collections.map((collection) =>
      items.push(getCollectionItemProps(collection, admins)),
    );
    return items;
  }, [collections, admins]);

  const menus: MenuItem[] = [
    {
      id: 'headline-help-center',
      name: 'Help Center',
      icon: <HelpOutlineOutlinedIcon />,
      headline: true,
    },
    {
      id: 'help-center-all',
      name: 'All',
      path: `/help/center`,
      active: `/help/center` === pathname,
    },
    ...collectionItems.map((collection) => {
      return {
        id: collection.id,
        name: collection.collection.name.slice(0, 26),
        path: `/help/center/collection/${collection.id}`,
        active: `/help/center/collection/${collection.id}` === pathname,
      } as MenuItem;
    }),
  ];

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1}>
          <AppDrawerMenuList menus={menus} />
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default HelpCenterDrawer;
