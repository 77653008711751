import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Box, Tooltip } from '@mui/material';
import { Moment } from 'moment';

import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../utils/date';
import { UseFetchTimeDetailsResponse } from '../../../hooks/edit-time';
import { applyOpacityOnColor } from '../../../utils/color';

export interface TimeLog {
  id: number;
  start: number;
  end: number;
  color: string;
  info: UseFetchTimeDetailsResponse;
}

interface TimelineCanvasProps {
  timeLogs: TimeLog[];
}

const TimelineCanvas: React.FC<TimelineCanvasProps> = ({ timeLogs }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [tooltip, setTooltip] = useState<{
    x: number;
    y: number;
    timeLog: TimeLog | null;
  }>({ x: 0, y: 0, timeLog: null });

  const drawTimelineCanvas = (
    ctx: CanvasRenderingContext2D,
    timeLog: TimeLog,
  ) => {
    const { start, end, color } = timeLog;
    const totalSecondsInDay = 24 * 60 * 60;
    let startX = (start / totalSecondsInDay) * ctx.canvas.width;
    let endX = (end / totalSecondsInDay) * ctx.canvas.width;
    const fillStyle =
      tooltip.timeLog?.id === timeLog.id
        ? color
        : applyOpacityOnColor(color, 0.8);

    if (start > end) {
      // If the start time is later than the end time, draw two entries
      ctx.fillStyle = fillStyle;
      ctx.fillRect(startX, 0, ctx.canvas.width - startX, ctx.canvas.height); // Before midnight
      ctx.fillRect(0, 0, endX, ctx.canvas.height); // After midnight
    } else {
      ctx.fillStyle = fillStyle;
      ctx.fillRect(startX, 0, endX - startX, ctx.canvas.height);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const timeLog = timeLogs.find((t) => {
      const totalSecondsInDay = 24 * 60 * 60;
      let startX = (t.start / totalSecondsInDay) * rect.width;
      let endX = (t.end / totalSecondsInDay) * rect.width;
      return x >= startX && x <= endX;
    });

    if (timeLog) {
      const totalSecondsInDay = 24 * 60 * 60;
      let startX = (timeLog.start / totalSecondsInDay) * rect.width;
      let endX = (timeLog.end / totalSecondsInDay) * rect.width;
      setTooltip({ x: (startX + endX) / 2, y, timeLog });
    } else {
      setTooltip({ x, y, timeLog: null });
    }
  };

  const handleOnMouseLeave = () => {
    setTooltip({ x: 0, y: 0, timeLog: null });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx && canvas) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      timeLogs.forEach((timeLog) => drawTimelineCanvas(ctx, timeLog));
    }
    // eslint-disable-next-line
  }, [timeLogs, tooltip.timeLog?.id]);

  return (
    <div
      className='relative'
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <canvas
        ref={canvasRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleOnMouseLeave}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
      {tooltip.timeLog && (
        <div
          style={{
            zIndex: 2,
            bottom: 0,
            left: tooltip.x,
            pointerEvents: 'none',
            position: 'absolute',
          }}
        >
          <div>
            <TimelineCanvasTooltip
              duration={tooltip.timeLog.info.duration}
              taskName={tooltip.timeLog.info.task_name}
              timeStarted={tooltip.timeLog.info.start_time_local}
              timeEnded={tooltip.timeLog.info.end_time_local!}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimelineCanvas;

type TimelineCanvasTooltipProps = {
  taskName: string;
  duration: number;
  timeStarted: Moment;
  timeEnded: Moment;
};
function TimelineCanvasTooltip({
  taskName,
  duration,
  timeStarted,
  timeEnded,
}: TimelineCanvasTooltipProps) {
  const getToolTipTitle = useMemo(() => {
    const hms = toHHMMSS(duration, {
      minDigit: 1,
      hrDigit: 1,
      secDigit: 1,
      json: true,
    }) as HHMMSSOptionsJSONRet;
    const { hours, minutes } = hms;

    return (
      <>
        <span className='tooltip-duration'>
          {`${hours}h ${minutes}m`.trim()}
        </span>
        <br />
        <span className='tooltip-task-name'>{taskName}</span>
        <br />
        <span className='tooltip-start-end'>
          {timeStarted.format('hh:mm A')} -{' '}
          {timeEnded ? timeEnded.format('hh:mm A') : 'ongoing'}
        </span>
      </>
    );
  }, [duration, taskName, timeStarted, timeEnded]);

  return (
    <Tooltip open title={getToolTipTitle}>
      <Box className='tooltip-box' />
    </Tooltip>
  );
}
