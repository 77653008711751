import React, { FC } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import TimeDetailsSkeleton from '../TimeDetailsSkeleton/TimeDetailsSkeleton';
import TimeDetailsTableRow from '../TimeDetailsTableRow/TimeDetailsTableRow';
import { IEditTimeDetailsForm } from '../../constants/edit-time-details-form.interface';

import { keysMap } from '../../constants/header-keys';
import { UseFetchTimeDetailsResponse } from '../../../../hooks/edit-time';
import { toTitleCase } from '../../../../utils/string';
import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../../utils/date';
import * as posthog from '../../../../services/posthog';

import './time-details-table.css';

interface TimeDetailsTableProps {
  isLoading?: boolean;
  data?: UseFetchTimeDetailsResponse[];
  updateFormData: React.Dispatch<React.SetStateAction<IEditTimeDetailsForm>>;
  hasLatestWorklogEntry?: boolean;
  toggleModalState: () => void;
  onDeleteSuccess?: () => void;
}
const TimeDetailsTable: FC<TimeDetailsTableProps> = ({
  hasLatestWorklogEntry,
  isLoading: isTimeDetailsDataLoading,
  data: timeDetailsData = [],
  updateFormData,
  toggleModalState,
  onDeleteSuccess,
}) => {
  const handleRowEdit = (row: UseFetchTimeDetailsResponse) => {
    const {
      id: worklogId,
      task: taskId,
      duration,
      start_time_local,
      end_time_local,
      notes,
      end,
    } = row;

    const hms = toHHMMSS(end ? duration : 0, {
      json: true,
      noHrs: false,
    }) as HHMMSSOptionsJSONRet;
    const newFormData: IEditTimeDetailsForm = {
      worklogId,
      taskId,
      timeStarted: start_time_local.format('hh:mm:ss A'),
      timeEnded: end_time_local
        ? end_time_local!.format('hh:mm:ss A')
        : undefined,
      totalTime: `${hms.hours}h ${hms.minutes}m`,
      notes,
    };

    updateFormData(newFormData);
    toggleModalState();

    posthog.capture('edit time edit clicked', {
      worklog_id: worklogId,
      task_id: taskId,
    });
  };

  return (
    <>
      <Box component='section'>
        <Grid container direction='column' wrap='nowrap' spacing={2}>
          <Grid item>
            <Box
              component='div'
              className='timeDetailsTableHeader tableBottomBorder'
            >
              <Grid container spacing={1} columns={20}>
                {Object.entries(keysMap).map(
                  ([columnKey, { label, itemProps }]) => (
                    <Grid
                      item
                      key={columnKey}
                      className={clsx({
                        timeDetailsTableHeaderCell: columnKey === 'notes',
                        actionMaxWidth70: columnKey === 'action',
                      })}
                      {...itemProps}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        component='div'
                        className='timeDetailsTableHeaderCellText'
                      >
                        {toTitleCase(label)}
                      </Typography>
                    </Grid>
                  ),
                )}
              </Grid>
              <Divider />
            </Box>
          </Grid>
          {isTimeDetailsDataLoading && !timeDetailsData.length ? (
            <TimeDetailsSkeleton />
          ) : (
            <Grid item container direction='column-reverse'>
              {timeDetailsData.map((row, index) => (
                <TimeDetailsTableRow
                  key={row.id}
                  row={row}
                  index={index}
                  prevRow={timeDetailsData[index - 1] ?? null}
                  hasLatestWorklogEntry={hasLatestWorklogEntry}
                  onEditRow={handleRowEdit}
                  onDeleteSuccess={onDeleteSuccess}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default TimeDetailsTable;
