import { useMemo } from 'react';
import {
  getRawCodeMessage,
  parseErrorData,
  ParseErrorDataRet,
  RawCodeMessageRet,
} from '../../utils/response';

export function useErrorData(error: any): ParseErrorDataRet {
  return useMemo(() => parseErrorData(error), [error]);
}

export function useGetRawCodeMessage(error: any): RawCodeMessageRet {
  return useMemo(() => getRawCodeMessage(error), [error]);
}
