import axios from 'axios';

import { UserProfile } from '../types/profile';

type EventName =
  | 'boot'
  | 'hide'
  | 'update'
  | 'show'
  | 'shutdown'
  | 'showMessages'
  | 'showNewMessage'
  | 'force-hide'
  | 'show-full-page'
  | 'hide-full-page'
  | 'onShow'
  | 'onHide'
  | 'onUnreadCountChange';

type Intercom = (event: EventName, ...arg: any[]) => void;

type IntercomBootProps = {
  app_id: string;
  email?: string;
  name?: string;
  user_id?: string;
  contact_type?: string;
} & IntercomUpdateProps;

type IntercomUpdateProps = {
  hide_default_launcher?: boolean;
};

function getIntercom(): Intercom {
  return (window as any).Intercom as unknown as Intercom;
}

if (!getIntercom()) {
  throw new Error('Intercom launcher is not properly setup');
}

export function init(user?: UserProfile, params?: Partial<IntercomBootProps>) {
  const config: IntercomBootProps = {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID!,
  } as IntercomBootProps;

  if (user) {
    config.email = user.email;
    if (user.employee_id) {
      config.user_id = user.employee_id;
    }
    if (user.roles.indexOf('numbers') > -1) {
      config.contact_type = 'numbers';
    }
  }

  getIntercom()('boot', config);
}

export function emit(event: EventName, ...arg: any[]) {
  if (event === 'hide' || event === 'force-hide') {
    toggleIntercom('hide');
  } else {
    toggleIntercom('show');
  }

  if (
    event === 'show' ||
    event === 'hide' ||
    event === 'show-full-page' ||
    event === 'hide-full-page'
  ) {
    return;
  }

  if (event === 'force-hide') {
    getIntercom()('hide');
  } else {
    getIntercom()(event, ...arg);
  }
}

function toggleIntercom(eventName: EventName) {
  if (eventName === 'hide') {
    document.body.classList.add('hide-intercom-launcher');
  } else if (eventName === 'show') {
    document.body.classList.remove('hide-intercom-launcher');
  }
}

export function toggleIntercomNoAnimation(action: 'show' | 'hide') {
  if (action === 'show') {
    document.body.classList.add(
      'intercom-no-animation',
      'full-page-intercom-launcher',
    );
  } else {
    document.body.classList.remove(
      'intercom-no-animation',
      'full-page-intercom-launcher',
    );
  }
}

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL!,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.response.use((config) => {
  return config.data;
});
