import { RequestError } from '../hooks/global/useApi';

export type ExtractedErrorData = {
  type: string;
  fields: { [key: string]: any };
  count: number;
  message: { message?: string; [key: string]: any } | string;
  code: number;
  originalError: any;
};
export function extractErrorData(
  errorData: { [key: string]: any } | any[],
  code: number,
): string {
  const errorKeyFields = Object.keys(errorData);
  const messages: string[] = [];
  const fields: { [key: string]: string } = {};

  errorKeyFields.forEach((key) => {
    const result = (errorData as any)[key];
    let msg = '';
    // check if it's an array, let's just joined all the error
    if (Array.isArray(result)) {
      result.forEach((res) => {
        if (typeof res === 'string') {
          msg = res;
          messages.push(msg);
        } else if (typeof res === 'object') {
          const keys = Object.keys(res).map((key) => key);

          keys.forEach((key) => {
            msg = res[key];
            messages.push(msg);
          });
        }
      });
    } else if (typeof result === 'object') {
      Object.keys(result).forEach((k) => {
        if (typeof result[k] === 'string') {
          msg = result[k];
          messages.push(msg);
        }
      });
    } else if (typeof result === 'string') {
      msg = result;
      messages.push(msg);
    }
    fields[key] = msg;
  }, {});

  return JSON.stringify({
    type: 'extracted_error_data',
    fields,
    count: Object.keys(fields).length,
    message: messages.join('. '),
    code,
    originalError: errorData,
  });
}

export type ParseErrorDataRet = ExtractedErrorData & {
  error: boolean;
};
export function parseErrorData(props: any): ParseErrorDataRet {
  try {
    if (typeof props === 'string') {
      let res = JSON.parse(props);
      return { ...res, error: !!res };
    } else if (typeof props === 'object') {
      if (props instanceof Error) {
        let res = JSON.parse(props.message);
        return { ...res, error: !!res };
      }
    }
    throw new Error('Unknown error');
  } catch (e) {
    return {
      type: 'message',
      fields: {},
      count: 0,
      message: props,
      error: !!props,
      code: 500,
      originalError: props,
    };
  }
}

function getMessage(error: any) {
  if (!error) return error;

  if (typeof error === 'string') {
    try {
      const result = JSON.parse(error);
      return getMessage(result);
    } catch (e) {
      return error;
    }
  }

  if (typeof error === 'object') {
    return getMessage(error.message);
  }

  return 'Unknown Error';
}

export type RawCodeMessageRet = {
  error: boolean;
  code: number;
  message: string;
  parsed: ParseErrorDataRet;
};
export function getRawCodeMessage(props: any): RawCodeMessageRet {
  const parsedError = parseErrorData(props);

  if (props instanceof RequestError) {
    return {
      ...props,
      error: true,
      message: getMessage(props),
      parsed: parsedError,
    } as RawCodeMessageRet;
  }

  return {
    error: !!props,
    code: parsedError.code,
    message: getMessage(parsedError),
    parsed: parsedError,
  } as RawCodeMessageRet;
}
