import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import AppActionToolbar from '../components/AppActionToolbar';
import MainContainer from '../components/MainContainer';
import ContentAudienceItem from '../components/Content/ContentAudienceItem';
import IntercomBreadcrumbs from '../components/Intercom/IntercomBreadcrumbs';
import SearchForm from '../components/Filters/SearchForm';

import { audienceSegments, audienceUserTypes } from '../types/audience';
import { escapeRegExp } from '../utils/string';

type ContentAssignProps = {};

const ContentAssign = (props: ContentAssignProps) => {
  const [search, setSearch] = useState('');

  const rgx = new RegExp(escapeRegExp(search), 'gi');

  const userTypes = search
    ? audienceUserTypes.filter((item) => rgx.test(item.name))
    : audienceUserTypes;

  const segments = search
    ? audienceSegments.filter((item) => rgx.test(item.name))
    : audienceSegments;

  return (
    <>
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <SearchForm
            placeholder='Search user types or segments...'
            onStateUpdated={(state) => {
              setSearch(state.search);
            }}
          />
        </AppActionToolbar>
        <IntercomBreadcrumbs
          items={[
            {
              id: 'all',
              label: 'Target Audience',
              path: '/content/target-audience',
            },
          ]}
        />
        <br />
        <Typography
          pb={1}
          fontSize={13}
          component='div'
          fontWeight={700}
          textTransform='uppercase'
        >
          User Types
        </Typography>
        <Box
          sx={{
            gap: 2,
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          {userTypes.map((as) => (
            <ContentAudienceItem key={as.id} item={as} />
          ))}
          {!userTypes.length && (
            <Typography component='div' sx={{ opacity: 0.5 }}>
              No user types found
            </Typography>
          )}
        </Box>
        <br />
        <br />
        <Typography
          pb={1}
          fontSize={13}
          component='div'
          fontWeight={700}
          textTransform='uppercase'
        >
          Segments
        </Typography>
        <Box
          sx={{
            gap: 2,
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          {segments.map((as) => (
            <ContentAudienceItem key={as.id} item={as} />
          ))}
          {!segments.length && (
            <Typography component='div' sx={{ opacity: 0.5 }}>
              No segments found
            </Typography>
          )}
        </Box>
      </MainContainer>
    </>
  );
};

export default ContentAssign;
