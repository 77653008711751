import React from 'react';
import { Skeleton, ListItemAvatar } from '@mui/material';

import { M3ListItemText } from '../M3/M3ListItem';
import InboxCardListItem from './InboxCardListItem';

type Props = {};

const InboxCardListItemSkeleton = (props: Props) => {
  return (
    <InboxCardListItem
      sx={{
        mt: 1,
        mb: 1,
        ml: 2,
      }}
      className='no-hover'
    >
      <ListItemAvatar>
        <Skeleton variant='circular' width={40} height={40} />
      </ListItemAvatar>
      <M3ListItemText
        sx={{
          '.MuiListItemText-primary': {
            pl: 0,
            pr: 0,
          },
        }}
      >
        <Skeleton
          variant='text'
          sx={{ fontSize: 16, maxWidth: 200, pl: 0, pr: 0 }}
        />
        <Skeleton variant='text' sx={{ fontSize: 16, pl: 0, pr: 0 }} />
      </M3ListItemText>
    </InboxCardListItem>
  );
};

export default InboxCardListItemSkeleton;
