import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import SettingsAdminPreviewAs from './SettingsAdminPreviewAs';
import SettingsAdminForms from './SettingsAdminForms';
import SettingsAdminUtilization from './SettingsAdminUtilization';
import SettingsAdminEditTime from './SettingsAdminEditTime';
import SettingsAdminSchedule from './SettingsAdminSchedule';
import SettingsAdminUserProvisioning from './SettingsAdminUserProvisioning';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import MainContainer from '../components/MainContainer';
import { useHasViewPermission } from '../components/HasViewPermission';

import { usePathnameSegments } from '../hooks/utils/url';
import { getAdminEditTimeRoles } from '../constants/roles-admin-time-edit';
import { getFromSessionStorage } from '../utils/sessionstorage';

type SettingsAdminProps = {};

export type SettingsAdminSegments =
  | 'preview-as'
  | 'forms'
  | 'edit-time'
  | 'schedule'
  | 'user-provisioning'
  | 'utilization';

const SettingsAdmin = (props: SettingsAdminProps) => {
  const [, , nextSegment] = usePathnameSegments<SettingsAdminSegments[]>();

  const previewAsPermission = useHasViewPermission({
    roles: ['superuser', 'preview'],
  });
  const internalPermission = useHasViewPermission({ roles: ['internal'] });
  const utilizationPermission = useHasViewPermission({
    roles: ['superuser', 'developer', 'partner_member', 'escalation_manager'],
  });

  const adminEditTimePermission = useHasViewPermission({
    roles: getAdminEditTimeRoles(),
  });

  const schedulePermission = useHasViewPermission({
    roles: ['superuser', 'developer', 'internal'],
  });

  const userProvisionPermission = useHasViewPermission({
    roles: ['developer', 'it', 'onboarding', 'superuser'],
  });

  const impersonation = useMemo(
    () => getFromSessionStorage('impersonation'),
    [],
  );

  if (previewAsPermission) {
    if (nextSegment === 'preview-as') {
      return <SettingsAdminPreviewAs />;
    }

    if (!nextSegment) {
      return <Navigate replace to='/settings/admin/preview-as' />;
    }
  }

  if (userProvisionPermission) {
    if (nextSegment === 'user-provisioning') {
      return <SettingsAdminUserProvisioning />;
    }

    if (!nextSegment) {
      return <Navigate replace to='/settings/admin/user-provisioning' />;
    }
  }

  if (internalPermission) {
    if (nextSegment === 'forms') {
      return <SettingsAdminForms />;
    }

    if (!nextSegment) {
      return <Navigate replace to='/settings/admin/forms' />;
    }
  }

  if (utilizationPermission) {
    if (nextSegment === 'utilization') {
      return <SettingsAdminUtilization />;
    }

    if (!nextSegment) {
      return <Navigate replace to='/settings/admin/utilization' />;
    }
  }

  if (adminEditTimePermission && !impersonation) {
    if (nextSegment === 'edit-time') {
      return <SettingsAdminEditTime />;
    }

    if (!nextSegment) {
      return <Navigate replace to='/settings/admin/edit-time' />;
    }
  }

  if (schedulePermission) {
    if (nextSegment === 'schedule') {
      return <SettingsAdminSchedule />;
    }

    if (!nextSegment) {
      return <Navigate replace to='/settings/admin/schedule' />;
    }
  }

  return (
    <MainContainer>
      <ErrorUnauthorizedAccess />
    </MainContainer>
  );
};

export default SettingsAdmin;
