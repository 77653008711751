import { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import ReactQuill from 'react-quill';

import AIAIAIChatMessage from './AIAIAIChatMessage';
import RichEditor from '../RichEditor/RichEditor';
import { M3Button } from '../M3/M3Button';

import { useInfinite } from '../../hooks/global/useInfinite';
import { useMetadataProvider } from '../../providers/metadata/metadata';
import {
  UseCommentsProps,
  useComments,
  usePostComment,
} from '../../hooks/comment';
import { CommentItem } from '../../types/comment';
import { Go2BotsMessageItem } from '../../types/go2bots';
import { useUserProvider } from '../../providers/user/user';
import { IterableObject } from '../../types/types';
import { toURL } from '../../utils/url';
import * as pusher from '../../services/pusher';

type Props = {
  isFlipped?: boolean;
  isAdmin?: boolean;
  withSentBy?: boolean;
  redirectToChatWhenAdminReply?: boolean;
  go2BotsMessage: Go2BotsMessageItem;
  onCommentSuccess?: () => void;
};

const AIAIAIUserChat = ({
  isAdmin = false,
  isFlipped = false,
  withSentBy,
  redirectToChatWhenAdminReply,
  go2BotsMessage,
  onCommentSuccess,
}: Props) => {
  const { setUniqueIdsToFetch } = useUserProvider();
  const { contentTypes } = useMetadataProvider();
  const [content, setContent] = useState('');
  const [deletedItems, setDeletedItems] = useState<IterableObject>({});
  const navigate = useNavigate();

  const [editorRef, setEditorRef] = useState<ReactQuill | null>(null);

  const clearEditor = ({ focus }: { focus?: boolean }) => {
    if (editorRef?.editor) {
      editorRef.editor.setText('');
      focus && editorRef.focus();
      setContent('');
    }
  };

  let contentType = (contentTypes as unknown as { go2botmessage: number })
    .go2botmessage;
  let objectId = go2BotsMessage.id;

  const infiniteMessages = useInfinite<CommentItem, UseCommentsProps>({
    useQuery: useComments,
    queryParams: {
      key: 'go2_bots_user_chat_comments',
      content_type: contentType,
      object_id: objectId!,
      page_size: 100,
    },
    skipFetchOnInit: !(objectId && contentType),
    filterIterator: (item: CommentItem) => !deletedItems[item.id],
  });
  const messages = useMemo(() => {
    return infiniteMessages.data.slice(0).reverse();
  }, [infiniteMessages.data]);

  const postComment = usePostComment();

  const handleOnSubmit = () => {
    postComment.mutate({
      content_type: contentType,
      object_id: objectId,
      content: content,
      metadata: {
        is_admin: isAdmin,
      },
    });
    clearEditor({ focus: true });
  };

  const handleOnReplyBackClick = () => {
    navigate(toURL(`/chat/message/${objectId}`));
  };

  const handleOnEditSuccess = () => {
    infiniteMessages.reset();
  };

  const handleOnDeleteSuccess = (id: number) => {
    setDeletedItems((state) => ({ ...state, [id]: true }));
    infiniteMessages.reset();
  };

  useEffect(() => {
    const eventCallback = (data: any) => {
      infiniteMessages.reset();
    };

    const events = {
      'comment-created': eventCallback,
      'comment-updated': eventCallback,
      'comment-deleted': eventCallback,
    };

    let unsubscribe = pusher.subscribe(
      `comment_${contentType}_${objectId}`,
      events,
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, objectId]);

  useEffect(() => {
    if (postComment.isSuccess) {
      onCommentSuccess?.();
      postComment.reset();
      infiniteMessages.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postComment.isSuccess]);

  useEffect(() => {
    const user_ids: any[] = [];
    const uniqueUserMapper = (item: CommentItem) => {
      user_ids.push(item.user);
    };

    infiniteMessages.data.map(uniqueUserMapper);

    setUniqueIdsToFetch({ user_ids });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infiniteMessages.data]);

  useEffect(() => {
    if (contentType && objectId) {
      infiniteMessages.reset({
        emptyList: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, objectId]);

  return (
    <Box display='flex' flexDirection='column' flex={1} width={0}>
      <Box flex={1} height={0}>
        <Virtuoso
          totalCount={messages.length}
          itemContent={(index) => {
            const message = messages[index];

            if (!message) return <Box />;

            return (
              <Fragment>
                {index === 0 && <br />}
                <AIAIAIChatMessage
                  index={index}
                  message={message}
                  isAdmin={index === 0 || message.metadata?.is_admin}
                  withSentBy={withSentBy}
                  isFlipped={isFlipped}
                  onEditSuccess={handleOnEditSuccess}
                  onDeleteSuccess={handleOnDeleteSuccess}
                />
                {index === messages.length - 1 && <br />}
              </Fragment>
            );
          }}
          initialTopMostItemIndex={{
            index: 'LAST',
          }}
          followOutput='smooth'
          style={{
            height: '100%',
            overflow: 'hidden auto',
          }}
        />
      </Box>
      <Divider flexItem />
      <Box
        sx={{
          px: 2,
          py: 2,
          '.react-quill-editor-wrapper .ql-editor': {
            maxHeight: `calc(100vh - 50vh)`,
            minHeight: 70,
            marginBottom: '32px',
          },
        }}
      >
        {redirectToChatWhenAdminReply ? (
          <Typography component='div'>
            You are an admin on this chat, if you want to reply, click this
            button:&nbsp;&nbsp;
            <M3Button
              variant='contained'
              color='primary'
              onClick={handleOnReplyBackClick}
            >
              <ReplyOutlinedIcon
                sx={{
                  transform: 'rotateY(180deg)',
                  position: 'relative',
                  top: -1,
                }}
              />
              Reply back&nbsp;
            </M3Button>
          </Typography>
        ) : (
          <RichEditor
            withMentions={false}
            withSubmit
            submitOnEnter
            onSetEditorRef={setEditorRef}
            value={content}
            onValueChange={(html: string, sanitized: string) => {
              setContent(html);
            }}
            onSubmit={handleOnSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default AIAIAIUserChat;
