import { useMemo, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/Inbox';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';

import WorkspaceMenuAvatarItem from './WorkspaceMenuAvatarItem';
import M3NavRailButton from './M3/M3NavRailButton';
import HasViewPermission from './HasViewPermission';
import MenuDivisionJumper from './MenuDivisionJumper';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';
import BasicPopoverWithSearch, {
  useBasicPopover,
} from './Popover/BasicPopoverWithSearch';

import { useWorkspaceProvider } from '../providers/workspace/workspace';
import { useIntercomProvider } from '../providers/intercom/intercom';
import { useNotificationProvider } from '../providers/notification/notification';
import { useAppProvider } from '../providers/app/app';
import { applyOpacityOnColor } from '../utils/color';
import { getUserInitials } from '../utils/user';
import * as posthog from '../services/posthog';
import { cleanURL, toURL } from '../utils/url';

type Props = {};

export const workspaceWidth = 68;

export function getIsInAppDrawer(pathname: string) {
  /**
   * List all the route the workspace(OS) reside
   */
  return {
    // home routes
    home: [
      '/manifest',
      '/answers/words',
      '/answers/numbers',
      '/team/calendar',
      '/team/time',
      '/team/schedule',
      '/team/resources',
      '/team/utilization',
      '/team/jobs',
      '/team/focus',
      '/team/t-1-1s',
      '/team/1-1s',
    ].some((s) => pathname.includes(s)),
    // builder routes
    builder: ['/builder/jobs', '/builder/focus'].some((s) =>
      pathname.includes(s),
    ),
  };
}

const WorkspaceMenuList = (props: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const { current: currentWorkspace } = useWorkspaceProvider();
  const { supportUnreadCount } = useIntercomProvider();
  const { getAllCount } = useNotificationProvider();
  const [isJumperOpen, setIsJumperOpen] = useState(false);

  const { home: isInHomeAppDrawer, builder: isInBuilderAppDrawer } =
    getIsInAppDrawer(pathname);
  const isInAppDrawer = isInHomeAppDrawer || isInBuilderAppDrawer;

  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  return (
    <Paper
      elevation={0}
      sx={{
        ...paperSx,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: workspaceWidth,
        minWidth: workspaceWidth,
        backgroundColor: applyOpacityOnColor(
          isDarkMode
            ? palette['md.ref.palette.primary80']
            : palette['md.ref.palette.primary40'],
          getSurfaceOpacityByElevation(5),
        ),
        border: 0,
      }}
    >
      <Box
        style={{
          height: toolbarHeight,
          minHeight: toolbarHeight,
        }}
      ></Box>
      {/* {renderDivider({ isBottom: false, isVisible: scrollY > 1 })} */}
      <Box
        flex={1}
        sx={{
          height: 0,
        }}
      >
        <M3NavRailButton
          icon={<HomeOutlinedIcon />}
          label='Home'
          active={isInHomeAppDrawer}
          onClick={() => {
            navigate(`/manifest/${currentWorkspace!.id}`);
          }}
        />
        <HasViewPermission
          roles={[
            'superuser',
            'internal',
            'partner_member',
            'path_beta',
            'guest',
          ]}
        >
          <M3NavRailButton
            icon={<HandymanOutlinedIcon />}
            label='Builder'
            active={pathname.includes('/builder')}
            onClick={() => {
              navigate(`/builder/jobs`);
            }}
          />
        </HasViewPermission>
        <M3NavRailButton
          icon={<CallToActionOutlinedIcon />}
          label='Go2bots'
          onClick={() => {
            window.open(process.env.REACT_APP_GO2_CHATGPT_URL!);
          }}
        />
        <M3NavRailButton
          icon={<InboxIcon />}
          label='Messages'
          badgeCount={getAllCount() + supportUnreadCount}
          active={pathname.includes('/inbox')}
          onClick={() => {
            posthog.capture('inbox clicked');
            navigate('/inbox/all/0');
          }}
        />
        {/* <HasViewPermission roles={['developer']}>
          <M3NavRailButton
            icon={<NewspaperOutlinedIcon />}
            label='Content'
            active={pathname.includes('/content')}
            onClick={() => navigate('/content')}
          />
        </HasViewPermission> */}
        {/* <M3NavRailButton
          icon={<TuneOutlinedIcon />}
          label='Manage'
          active={pathname.includes('/manage')}
          onClick={() => {}}
        /> */}
      </Box>
      {/* {renderDivider({ isBottom: true })} */}
      <Box>
        <HasViewPermission roles={['superuser', 'developer', 'go2bots_admin']}>
          <M3NavRailButton
            icon={<ChatBubbleOutlineOutlinedIcon />}
            label='Chat'
            active={pathname.includes('/chat/message')}
            onClick={() => {
              navigate('/chat/message');
            }}
          />
        </HasViewPermission>
        <HasViewPermission
          featureKey='sidecar_enabled'
          roles={['superuser', 'developer']}
        >
          <M3NavRailButton
            icon={<TerminalOutlinedIcon />}
            label='Sidecar'
            active={pathname.includes('/sidecar')}
            onClick={() => {
              navigate('/sidecar/go2-desktop');
            }}
          />
        </HasViewPermission>
        <HasViewPermission
          roles={['superuser', 'internal', 'escalation_manager', 'jumper']}
        >
          <M3NavRailButton
            active={
              isJumperOpen ||
              (isInAppDrawer &&
                currentWorkspace?.type === 'jumper_division_partner')
            }
            useBadge={currentWorkspace?.type === 'jumper_division_partner'}
            badgeCount={
              currentWorkspace?.type === 'jumper_division_partner'
                ? getUserInitials(currentWorkspace.name, 2, undefined, {
                    joinDelimiter: '-',
                  }).initials
                : undefined
            }
            icon={<ChangeCircleOutlinedIcon />}
            label='Jumper'
            onClick={() => setIsJumperOpen(true)}
          >
            <MenuDivisionJumper
              open={isJumperOpen}
              onClose={() => setIsJumperOpen(false)}
            />
          </M3NavRailButton>
        </HasViewPermission>
      </Box>
      <div style={{ height: 10 }}></div>
    </Paper>
  );
};

export default WorkspaceMenuList;

export function WorkspaceCircleMenu() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    current: currentWorkspace,
    items: workspaceItems,
    setCurrent: setCurrentWorkspace,
  } = useWorkspaceProvider();

  /**
   * Match the path where it changes the workspace
   */
  const regex =
    /^\/(manifest|focus\/answers|team\/calendar|team\/time|team\/schedule)/i;
  const [currentPath = 'manifest'] = pathname.match(regex) ?? [];
  const isInHome = regex.test(pathname);

  const basicPopover = useBasicPopover();
  const popoverOptions = useMemo(() => {
    return workspaceItems.map((ws) => ({
      id: ws.id,
      label: ws.name,
      props: ws,
    }));
  }, [workspaceItems]);

  if (!currentWorkspace) return null;

  return (
    <Box ref={basicPopover.ref}>
      <WorkspaceMenuAvatarItem
        item={currentWorkspace}
        active
        onClick={() => {
          basicPopover.open();
        }}
      />
      <BasicPopoverWithSearch
        withSearch={false}
        open={basicPopover.isOpen}
        anchorEl={basicPopover.ref.current}
        options={popoverOptions}
        maxRowVisible={6}
        // itemHeight={52}
        // placeholder={getPlaceholderText()}
        popoverProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          // transformOrigin: {
          //   vertical: asDropdown ? 'top' : -4,
          //   horizontal: asDropdown ? -10 : 0,
          // },
        }}
        paperProps={{
          sx: {
            ml: 6,
          },
          style: {
            width: 320,
            marginTop: -10,
          },
        }}
        renderOption={(_, option) => {
          const item = option.props!;
          return (
            <WorkspaceMenuAvatarItem
              key={item.id}
              showDetail
              item={option.props}
              active={item.id === currentWorkspace?.id}
              onClick={() => {
                setCurrentWorkspace(item);
                basicPopover.close();

                if (isInHome) {
                  const url = cleanURL(toURL(`/${currentPath}/${item.id}`));
                  navigate(url);
                }
              }}
            />
          );
        }}
        onClose={() => {
          basicPopover.close();
        }}
      />
    </Box>
  );
}
