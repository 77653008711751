import { Box, SxProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { useAppProvider } from '../../providers/app/app';
import { PaletteTypeButtonProps } from '../../types/app';
import { applyOpacityOnColor } from '../../utils/color';

export type Elevation = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 'max';
type Props = PropsWithChildren &
  PaletteTypeButtonProps & {
    sx?: SxProps;
    borderRadius?: number;
    wrapperSx?: SxProps;
    elevation?: Elevation;
    className?: string;
    error?: boolean;
  };

const M3SurfaceContainer = ({
  sx,
  borderRadius = 3,
  wrapperSx,
  elevation,
  palette: paletteKey = 'main',
  children,
  className,
  error,
}: Props) => {
  const {
    palettes: { [paletteKey]: palette },
    isDarkMode,
  } = useAppProvider();

  return (
    <Box
      sx={{
        borderRadius,
        background: isDarkMode
          ? 'var(--md-ref-palette-neutral10)'
          : `var(--md-ref-palette-neutral99)`,
        ...wrapperSx,
      }}
      className={clsx('m3-surface-container', className)}
    >
      <Box
        sx={{
          borderRadius,
          background: elevation
            ? applyOpacityOnColor(
                isDarkMode
                  ? palette[
                      error
                        ? 'md.ref.palette.error80'
                        : 'md.ref.palette.primary80'
                    ]
                  : palette[
                      error
                        ? 'md.ref.palette.error40'
                        : 'md.ref.palette.primary40'
                    ],
                getSurfaceOpacityByElevation(elevation),
              )
            : undefined,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default M3SurfaceContainer;

export function getSurfaceOpacityByElevation(elevation?: Elevation): number {
  if (elevation === 0) {
    return 0.02;
  } else if (elevation === 1) {
    return 0.05;
  } else if (elevation === 2) {
    return 0.08;
  } else if (elevation === 3) {
    return 0.11;
  } else if (elevation === 4) {
    return 0.12;
  } else if (elevation === 5) {
    return 0.14;
  } else if (elevation === 6) {
    return 0.16;
  } else if (elevation === 7) {
    return 0.17;
  } else if (elevation === 8) {
    return 0.18;
  } else if (elevation === 9) {
    return 0.19;
  } else {
    return 1;
  }
}
