import { Fragment, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

import { M3Button, M3IconButton } from '../M3/M3Button';
import UserProfilePopover from '../UserProfilePopover';
import { RejectCancelDialog } from '../Inbox/InboxPTOItemDetail';

import { useAppProvider } from '../../providers/app/app';
import {
  BatchChangeScheduleItem,
  DailyScheduleItem,
  ScheduleChangeRequestStatus,
  ScheduleItem,
} from '../../types/schedules';
import { toTitleCase } from '../../utils/string';
import { useUserProvider } from '../../providers/user/user';
import { getUserInitials, getUserProfileDisplayName } from '../../utils/user';
import { useBatchChangeRequestRejectCancel } from '../../hooks/go2-schedules';
import {
  GetFormattedLocalScheduleFromStartDateTimeRet,
  convertPyToJsDay,
  extractScheduleWithDateStartEndTime,
  getFormattedLocalScheduleFromStartDateTime,
  getLatestProperLocalSchedules,
  isScheduleRestDay,
} from '../../utils/schedule';
import { UserMetadata } from '../../types/profile';
import { DayScheduleLocal } from './ScheduleUserList';
import { getUserFilteredDayLocalSchedules } from './ScheduleUserList/ScheduleCard';
import { ReactRenderElement } from '../../types/types';

type Props = {
  isLast?: boolean;
  isFirst?: boolean;
  editable?: boolean;
  withCancelButton?: boolean;
  isRequestsDropdownOpen?: boolean;
  withToggleRequestDropdown?: boolean;
  item: BatchChangeScheduleItem;
  onTakeActionSuccess?: (item: BatchChangeScheduleItem) => void;
};

export default function TemporaryChangeRequestScheduleListItem({
  editable = true,
  item,
  isLast,
  withCancelButton,
  onTakeActionSuccess,
  isRequestsDropdownOpen: defaultIsRequestsDropdownOpen = false,
  withToggleRequestDropdown = true,
}: Props) {
  const { isDarkMode } = useAppProvider();
  const { getUser } = useUserProvider();
  const [isRequestsDropdownOpen, setIsRequestsDropdownOpen] = useState(
    defaultIsRequestsDropdownOpen,
  );

  const user = getUser('user_id', item.user);
  const userSubmitted = getUser('user_id', item.submitted_by);

  const [isRejectCancelOpen, setIsRejectCancelOpen] = useState(false);

  const requestedDate = item.requested_date;
  const approvedDate = item.approved_date;

  const cancelChangeRequest = useBatchChangeRequestRejectCancel({
    batchId: item.batch_id,
    action: 'cancel',
  });

  const handleOnCancel = ({ action, note }: any) => {
    cancelChangeRequest.mutate({
      notes: note,
    });
  };

  const renderRejectionNotes = () => {
    if (!item.notes) return null;

    return (
      <Tooltip title={item.notes}>
        <Box
          sx={{
            top: 12,
            left: -40,
            position: 'absolute',
          }}
        >
          <NotesOutlinedIcon
            style={{
              fontSize: 20,
              color: isDarkMode
                ? 'var(--md-ref-palette-error80)'
                : 'var(--md-ref-palette-error40)',
            }}
          />
        </Box>
      </Tooltip>
    );
  };

  const renderAwaitingStatus = () => {
    let status: ScheduleChangeRequestStatus = item.status;
    let actor = getUser('user_id', item.approved_by);

    return (
      <Box position='relative'>
        {renderRejectionNotes()}
        <Box
          display='flex'
          alignItems='center'
          sx={{
            height: 50,
            minWidth: 145,
            borderRadius: 1,
            overflow: 'hidden',
            border: '1px solid #d3d4d3',
            backgroundColor: '#d3d4d3',
            ...(status === 'approved'
              ? {
                  color: '#fff',
                  borderColor: '#0f840d',
                  backgroundColor: '#0f840d',
                }
              : status === 'rejected' || status === 'canceled'
              ? {
                  color: '#fff',
                  borderColor: '#f34722',
                  backgroundColor: '#f34722',
                }
              : null),
          }}
        >
          <Typography
            fontSize={12}
            sx={{
              pl: 1.5,
              pr: 1.5,
            }}
            component='div'
            style={{
              ...(isDarkMode
                ? {
                    color: status === 'pending' ? '#000' : '#fff',
                  }
                : {
                    color: status === 'pending' ? undefined : '#fff',
                  }),
            }}
          >
            {status === 'approved' ? (
              <>
                Approved
                {actor
                  ? ` by ${actor.first_name || ''} ${actor.last_name || ''}`
                  : ''}
                <br />
                {!approvedDate ? (
                  <Skeleton variant='text' width={70} />
                ) : (
                  moment.utc(approvedDate).local().format('MMM D, YYYY')
                )}
              </>
            ) : status === 'rejected' || status === 'canceled' ? (
              <>
                {status === 'rejected' ? 'Rejected' : 'Cancelled'}
                {actor
                  ? ` by ${actor.first_name || ''} ${actor.last_name || ''}`
                  : ''}
                <br />
                {!approvedDate ? (
                  <Skeleton variant='text' width={70} />
                ) : (
                  moment.utc(approvedDate).local().format('MMM D, YYYY')
                )}
              </>
            ) : (
              <>
                Awaiting Approval
                <br />1 pending approval
              </>
            )}
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
              height: 50,
              width: 55,
              background: '#fff',
              borderLeft: '1px solid #d3d4d3',
            }}
          >
            <Avatar
              sx={{
                width: 40,
                height: 40,
              }}
              src={actor?.photo_url}
              style={{
                ...(status === 'approved'
                  ? {
                      color: '#fff',
                      borderColor: '#0f840d',
                      backgroundColor: '#0f840d',
                    }
                  : status === 'rejected' || status === 'canceled'
                  ? {
                      color: '#fff',
                      borderColor: 'red',
                      backgroundColor: 'red',
                    }
                  : null),
              }}
            >
              {status === 'approved' ? (
                <DoneOutlinedIcon
                  sx={{ color: '#fff', opacity: '1 !important' }}
                />
              ) : status === 'rejected' || status === 'canceled' ? (
                <CloseOutlinedIcon
                  sx={{ color: '#fff', opacity: '1 !important' }}
                />
              ) : (
                <TimerOutlinedIcon />
              )}
            </Avatar>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (cancelChangeRequest.isSuccess) {
      onTakeActionSuccess?.(item);
    }
  }, [item, cancelChangeRequest.isSuccess, onTakeActionSuccess]);

  return (
    <>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Box flex={2} pt={0.5} pl={1}>
          <Typography
            component='div'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            <Typography
              component='div'
              // pt={2.5}
              gap={2}
              flex={2}
              fontSize={18}
              fontWeight={500}
              display='flex'
              alignItems='center'
            >
              <UserProfilePopover user={user as UserMetadata}>
                <Avatar sx={{ width: 60, height: 60 }} src={user?.photo_url}>
                  {
                    getUserInitials(`${user?.first_name} ${user?.last_name}`)
                      .initial
                  }
                </Avatar>
              </UserProfilePopover>
              <Box>
                <Typography component='div' fontSize={22} fontWeight={300}>
                  {getUserProfileDisplayName(user!).fullName}
                </Typography>
                <Stack
                  direction='row'
                  alignItems='flex-start'
                  justifyContent='space-between'
                >
                  <Typography
                    component='div'
                    fontSize={12}
                    sx={{ opacity: 0.8 }}
                  >
                    <span
                      style={{
                        opacity: 0.5,
                      }}
                    >
                      Requested By:&nbsp;
                    </span>
                    {toTitleCase(item.requested_by_role)}
                  </Typography>
                </Stack>
              </Box>
            </Typography>
            <Box mt={-1}>
              {withCancelButton ? (
                <M3Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => setIsRejectCancelOpen(true)}
                >
                  &nbsp;Cancel&nbsp;
                </M3Button>
              ) : (
                <>
                  <Typography
                    component='div'
                    fontSize={12}
                    display='flex'
                    justifyContent='flex-end'
                    sx={{ mb: 0.5, textAlign: 'right' }}
                  >
                    <div style={{ opacity: 0.5 }}>Date Requested:</div>&nbsp;
                    {!requestedDate ? (
                      <Skeleton variant='text' width={70} />
                    ) : (
                      moment.utc(requestedDate).local().format('MMM D, YYYY')
                    )}
                  </Typography>
                  {renderAwaitingStatus()}
                </>
              )}
            </Box>
          </Typography>
          {!!item.reason && (
            <Typography
              p={1}
              pl={2}
              pr={2}
              mt={1}
              borderRadius={1}
              component='div'
              fontSize={14}
              fontStyle='italic'
              sx={{
                opacity: 0.5,
                background: isDarkMode
                  ? 'var(--md-ref-palette-neutral20)'
                  : 'var(--md-ref-palette-neutral90)',
              }}
            >
              {item.reason}
            </Typography>
          )}
        </Box>
      </Box>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent='flex-end'
        mt={-1}
        mb={1}
      >
        <Typography component='div' fontSize={12} sx={{ opacity: 0.8 }}>
          <span
            style={{
              opacity: 0.5,
            }}
          >
            Submitted By:&nbsp;
          </span>
          {getUserProfileDisplayName(userSubmitted).fullName}
        </Typography>
      </Stack>
      {isRequestsDropdownOpen && (
        <Box pb={1}>
          <Box px={2} display='flex' gap={6}>
            <Box minWidth={125} width={125} />
            <Typography
              component='div'
              fontSize={12}
              fontWeight={500}
              flex={1}
              width={0}
              sx={{ opacity: 0.5 }}
            >
              Current:{' '}
            </Typography>
            <Box width={50} minWidth={50} />
            <Box minWidth={125} width={125} />
            <Typography
              component='div'
              fontSize={12}
              fontWeight={500}
              flex={1}
              width={0}
              sx={{ opacity: 0.5 }}
            >
              New:{' '}
            </Typography>
          </Box>
          <Divider sx={{ opacity: 0.5, mt: 0.5, mx: 1 }} />
          {item.requests.map((request, index) => {
            /**
             * NOTE: If this is a temporary delete change request, it might be possible
             * that there are multiple entries here for the same day. We need to show
             * only the latest
             */
            if ((item.delete && index !== 0) || !request.is_temporary) {
              return null;
            }

            return (
              <Fragment key={request.id}>
                <TemporaryCurrentNewScheduleBlock
                  request={request}
                  isDelete={item.delete}
                  userId={item.user}
                  requests={item.requests}
                />
                <Divider sx={{ opacity: 0.5, mx: 1 }} />
              </Fragment>
            );
          })}
        </Box>
      )}
      {withToggleRequestDropdown && (
        <Box
          mt={-1}
          mb={0.5}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <M3IconButton
            style={{
              width: 30,
              height: 30,
              minWidth: 30,
              minHeight: 30,
            }}
            onClick={() => setIsRequestsDropdownOpen(!isRequestsDropdownOpen)}
          >
            <ExpandMoreOutlinedIcon
              sx={{
                transform: `rotateZ(${isRequestsDropdownOpen ? 180 : 0}deg)`,
              }}
              style={{
                fontSize: 18,
              }}
            />
          </M3IconButton>
        </Box>
      )}
      {!isLast && <Divider sx={{ opacity: 0.5, borderWidth: 2 }} />}
      <RejectCancelDialog
        isOpen={isRejectCancelOpen}
        user={user!}
        action='reject'
        isTakingAction={cancelChangeRequest.isLoading}
        onTakeAction={handleOnCancel}
        setIsOpen={(isOpen: boolean) => setIsRejectCancelOpen(isOpen)}
        dialogProps={{
          dialog: {
            title: `Are you sure you want to cancel the schedule change request?`,
          },
          actions: {
            confirm: {
              label: 'Confirm',
              width: 50,
            },
          },
        }}
      />
    </>
  );
}

type TemporaryCurrentNewScheduleBlockProps = {
  userId: number;
  request: BatchChangeScheduleItem['requests'][0];
  isDelete?: boolean;
  requests: BatchChangeScheduleItem['requests'];
};
function TemporaryCurrentNewScheduleBlock({
  userId,
  request,
  isDelete,
  requests,
}: TemporaryCurrentNewScheduleBlockProps) {
  let {
    isCurrentNoPermanent,
    isCurrentScheduleRestDay,
    currentSchedule,
    getCurrentDayFormattedSchedule,
    isNewScheduleNoPermanent,
    isNewScheduleRestDay,
    newSchedule,
    getNewDayFormattedSchedule,
  } = getTemporaryFormattedSchedules({
    request,
    requests,
    isDelete,
  });

  return (
    <Box
      flex={1}
      display='flex'
      gap={6}
      alignItems='center'
      minWidth={250}
      py={1}
      px={2}
    >
      <Typography fontSize={14} fontWeight={500} minWidth={125} width={125}>
        {getCurrentDayFormattedSchedule()}
      </Typography>
      <Box flex={1} width={0}>
        <Typography
          component='div'
          display='flex'
          alignItems='center'
          style={{
            opacity:
              isCurrentNoPermanent || isCurrentScheduleRestDay
                ? 0.3
                : undefined,
          }}
        >
          {isCurrentScheduleRestDay || isCurrentNoPermanent ? (
            '–'
          ) : (
            <>
              {currentSchedule.timeDiff.overlap && (
                <NightsStayOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {currentSchedule.dayTime?.start_time_local} –{' '}
              {currentSchedule.dayTime?.end_time_local}
            </>
          )}
          <span
            style={{
              flex: 1,
              fontSize: 12,
              opacity: 0.5,
              textAlign: 'right',
            }}
          >
            {isCurrentScheduleRestDay || isCurrentNoPermanent
              ? null
              : `(${currentSchedule.timeDiff.hours}h)`}
          </span>
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center' width={50} minWidth={50}>
        <ArrowRightAltOutlinedIcon sx={{ opacity: 0.1 }} />
      </Box>
      <Typography fontSize={14} fontWeight={500} minWidth={125} width={125}>
        {getNewDayFormattedSchedule()}
      </Typography>
      <Box flex={1} width={0}>
        <Typography
          component='div'
          display='flex'
          alignItems='center'
          style={{
            opacity:
              isNewScheduleNoPermanent || isNewScheduleRestDay
                ? 0.3
                : undefined,
          }}
        >
          {isNewScheduleRestDay || isNewScheduleNoPermanent ? (
            '–'
          ) : (
            <>
              {newSchedule.timeDiff.overlap && (
                <NightsStayOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {newSchedule.dayTime?.start_time_local} –{' '}
              {newSchedule.dayTime?.end_time_local}
            </>
          )}
          <span
            style={{
              flex: 1,
              fontSize: 12,
              opacity: 0.5,
              textAlign: 'right',
            }}
          >
            {isNewScheduleRestDay || isNewScheduleNoPermanent
              ? null
              : `(${newSchedule.timeDiff.hours}h)`}
          </span>
        </Typography>
      </Box>
    </Box>
  );
}

type GetRequestedNextLocalDatesRet = {
  isNewVersion: boolean;
  isCurrentNoPermanent: boolean;
  isCurrentScheduleRestDay: boolean;
  isNewScheduleNoPermanent: boolean;
  isNewScheduleRestDay: boolean;
  scheduleData: ScheduleItem;
};
export function getRequestedNextLocalDates(
  request: BatchChangeScheduleItem['requests'][0],
  requests: BatchChangeScheduleItem['requests'],
  opt?: { isDelete?: boolean },
): GetRequestedNextLocalDatesRet {
  const { isDelete } = opt ?? {};
  const isNewVersion = Object.keys(request.metadata ?? {}).includes(
    'schedules',
  );

  let ret: GetRequestedNextLocalDatesRet = {
    isNewVersion,
    isCurrentNoPermanent: false,
    isCurrentScheduleRestDay: false,
    isNewScheduleNoPermanent: false,
    isNewScheduleRestDay: false,
    scheduleData: {
      ...(request as unknown as ScheduleItem),
    },
  };

  const calcIsCurrentNewRestDays = (r?: {
    start_time?: string;
    new_start_time?: string;
    end_time?: string;
    new_end_time?: string;
    metadata?: any;
  }) => {
    let isCurrentScheduleRestDay = false,
      isNewScheduleRestDay = false;

    // temporary delete request schedule
    if (isDelete) {
      isCurrentScheduleRestDay = isScheduleRestDay({
        start_time: r?.new_start_time ?? r?.start_time,
        end_time: r?.new_end_time ?? r?.end_time,
      });

      if (
        typeof r?.metadata === 'object' &&
        r?.metadata &&
        (r?.metadata?.next_schedules?.length ||
          r?.metadata?.permanent_schedule ||
          r?.metadata?.prev_schedules?.length)
      ) {
        isNewScheduleRestDay = false;
      } else if (!r) {
        isNewScheduleRestDay = true;
      }
    }
    // Temporary request change schedule
    else {
      isNewScheduleRestDay = isScheduleRestDay({
        start_time: r?.new_start_time ?? r?.start_time,
        end_time: r?.new_end_time ?? r?.end_time,
      });

      // When requesting a temporary schedule, we check metadata of the request
      // since the metadata contains the current schedule, if none. Then it's
      // a blank day
      isCurrentScheduleRestDay = r?.metadata
        ? isScheduleRestDay(r.metadata)
        : true;
    }

    return {
      isNewScheduleRestDay,
      isCurrentScheduleRestDay,
    };
  };

  // When requesting to delete a temporary schedule and no schedule is set
  // mean there's no request schedule yet. For new schedule, the metadata
  // will be empty
  if (isDelete) {
    ret.isCurrentNoPermanent = !request.schedule;

    if (!ret.isNewVersion) {
      ret.isNewScheduleNoPermanent =
        !request.schedule ||
        !request.metadata ||
        !Object.keys(request.metadata ?? {}).length;
    } else {
      if (
        typeof request?.metadata === 'object' &&
        request?.metadata &&
        !request?.metadata?.permanent_schedule
      ) {
        ret.isNewScheduleNoPermanent = true;
      }
    }
  }
  // Requesting a temporary schedule with no permanent schedule.
  // The metadata will be empty.
  else {
    ret.isCurrentNoPermanent = !Object.keys(request.metadata ?? {}).length;

    if (ret.isCurrentNoPermanent) {
      ret.scheduleData = {
        ...ret.scheduleData,
        ...extractScheduleWithDateStartEndTime({
          from_date: request.from_date!,
          to_date: request.to_date!,
          start_time: request.new_start_time,
          end_time: request.new_end_time,
          tzinfo: request.tzinfo!,
        }),
      };
    }
  }

  ret = {
    ...ret,
    ...calcIsCurrentNewRestDays(ret.scheduleData),
  };

  /**
   * In newer version. The structure of the metadata is different.
   * Especially in the temporary schedule when deleting.
   */
  if (isNewVersion && isDelete) {
    /**
     * When deleting a temporary schedule. Check first if the
     * current schedule has a schedule set.
     */
    if (ret.isCurrentNoPermanent) return ret;

    /**
     * Current local schedule is based on the temporary request schedule.
     * We are using the current schedule to filter out / match the
     * next schedules based on the date of the current locally.
     */
    const currentLocalSchedule = {
      ...request,
      ...extractScheduleWithDateStartEndTime({
        from_date: request.from_date!,
        to_date: request.to_date!,
        start_time: request.new_start_time,
        end_time: request.new_end_time,
        tzinfo: request.tzinfo!,
      }),
    };
    const localDate = moment
      .utc(currentLocalSchedule.start_date_time)
      .local()
      .format('YYYY-MM-DD');

    const mapToLocalSchedules = (s: DailyScheduleItem['schedules'][0]) => ({
      ...s,
      // Convert the date first to its local value
      date: moment.utc(s.start_date_time).local().format('YYYY-MM-DD'),
    });
    const filterLocalSchedules = (s: DailyScheduleItem['schedules'][0]) =>
      s.id !== request.schedule &&
      s.date === localDate &&
      !requests.some((r) => r.schedule === s.id);
    const filterToLatestSchedules = (
      schedules: DailyScheduleItem['schedules'],
    ) => {
      const latestSchedule = getLatestProperLocalSchedules(
        schedules as DayScheduleLocal[],
      );
      let filteredLatestSchedule = getUserFilteredDayLocalSchedules(
        { [localDate]: latestSchedule },
        {
          days: [moment(localDate)],
          range: [
            moment(localDate).subtract(1, 'day').format('YYYY-MM-DD'),
            moment(localDate).add(1, 'day').format('YYYY-MM-DD'),
          ],
          date: localDate,
        },
      );
      return filteredLatestSchedule as DailyScheduleItem['schedules'];
    };

    let currentSchedulesLocal = filterToLatestSchedules(
      (request.metadata.schedules ?? [])
        .map(mapToLocalSchedules)
        .filter(filterLocalSchedules),
    );
    let prevSchedulesLocal = filterToLatestSchedules(
      (request.metadata.prev_schedules ?? [])
        .map(mapToLocalSchedules)
        .filter(filterLocalSchedules),
    );
    let nextSchedulesLocal = filterToLatestSchedules(
      (request.metadata.next_schedules ?? [])
        .map(mapToLocalSchedules)
        .filter(filterLocalSchedules),
    );

    let nextLocalSchedules = [
      ...currentSchedulesLocal,
      ...prevSchedulesLocal,
      ...nextSchedulesLocal,
    ];

    /**
     * Check for the next local schedules. It's possible that the one
     * we deleted has next temporary schedule.
     */
    if (nextLocalSchedules.length) {
      const firstNextLocalSched = nextLocalSchedules[0];
      const startDateTimeTz = moment
        .utc(firstNextLocalSched.start_date_time)
        .tz(firstNextLocalSched.tzinfo);
      const endDateTimeTz = moment
        .utc(firstNextLocalSched.end_date_time)
        .tz(firstNextLocalSched.tzinfo);

      ret.scheduleData = {
        ...firstNextLocalSched,
        from_date: startDateTimeTz.format('YYYY-MM-DD'),
        to_date: startDateTimeTz.format('YYYY-MM-DD'),
        start_time: startDateTimeTz.format('HH:mm'),
        end_time: endDateTimeTz.format('HH:mm'),
        tzinfo: firstNextLocalSched.tzinfo!,
        metadata: firstNextLocalSched as unknown as any,
      } as ScheduleItem;
      ret.scheduleData = {
        ...ret.scheduleData,
        ...extractScheduleWithDateStartEndTime(ret.scheduleData),
      };
    } else {
      // If there's no next schedules (temporary), let's check
      // if there's permanent schedule and use the current date as the request date.
      if (request.metadata.permanent_schedule) {
        const perm = request.metadata.permanent_schedule;
        const permLocalSchedule = {
          ...request,
          ...extractScheduleWithDateStartEndTime(
            {
              from_date: request.from_date!,
              to_date: request.to_date!,
              start_time: perm.start_time,
              end_time: perm.end_time,
              tzinfo: perm.tzinfo!,
            },
            {
              isAbsoluteDate: true,
            },
          ),
        };

        ret.scheduleData = permLocalSchedule as unknown as ScheduleItem;
      }
    }

    ret = {
      ...ret,
      ...calcIsCurrentNewRestDays(ret.scheduleData),
    };
  }

  return ret;
}

type GetTemporaryFormattedSchedulesProps = {
  request: BatchChangeScheduleItem['requests'][0];
  isDelete?: boolean;
  requests: BatchChangeScheduleItem['requests'];
  returnTextOnly?: boolean;
};
type GetTemporaryFormattedSchedulesRet = GetRequestedNextLocalDatesRet & {
  currentSchedule: GetFormattedLocalScheduleFromStartDateTimeRet;
  getCurrentDayFormattedSchedule: () => ReactRenderElement;
  newSchedule: GetFormattedLocalScheduleFromStartDateTimeRet;
  getNewDayFormattedSchedule: () => ReactRenderElement;
};
/**
 * Returns a formatted display of the current schedule and new schedule.
 */
export function getTemporaryFormattedSchedules({
  request,
  isDelete,
  requests,
  returnTextOnly,
}: GetTemporaryFormattedSchedulesProps): GetTemporaryFormattedSchedulesRet {
  let nextLocalDatesRet = getRequestedNextLocalDates(request, requests, {
    isDelete,
  });
  let { isNewVersion, isCurrentNoPermanent, isNewScheduleNoPermanent } =
    nextLocalDatesRet;

  /**
   * Current schedule prior to requests are in metadata field of request
   */
  let currentSchedule = getFormattedLocalScheduleFromStartDateTime(
    (() => {
      if (isCurrentNoPermanent) return null;

      /**
       * When deleting an existing temporary schedule. The new start/end time
       * is the current schedule prior to request of deleting it.
       */
      if (isDelete) {
        return {
          ...request,
          ...extractScheduleWithDateStartEndTime({
            from_date: request.from_date!,
            to_date: request.to_date!,
            start_time: request.new_start_time,
            end_time: request.new_end_time,
            tzinfo: request.tzinfo!,
          }),
        };
      }

      return {
        ...request.metadata,
        ...extractScheduleWithDateStartEndTime({
          from_date: request.metadata.from_date!,
          to_date: request.metadata.to_date!,
          start_time: request.metadata.start_time!,
          end_time: request.metadata.end_time!,
          tzinfo: request.metadata.tzinfo!,
        }),
      };
    })(),
  );

  /**
   * New schedule request is the actual request
   */
  let newSchedule = getFormattedLocalScheduleFromStartDateTime(
    (() => {
      /**
       * When deleting an existing temporary schedule. The requested metadata
       * is the current permanent schedule of the user
       */
      if (isDelete) {
        /**
         * When deleting and there's no new schedule permanent.
         * Returns the current's date requested.
         */
        if (isNewScheduleNoPermanent) {
          return {
            start_date_time: moment(request.from_date)
              .startOf('day')
              .utc()
              .format(),
            end_date_time: moment(request.from_date)
              .startOf('day')
              .utc()
              .format(),
          };
        }

        if (isNewVersion) {
          return getRequestedNextLocalDates(request, requests, { isDelete })
            .scheduleData;
        }

        /**
         * Deleting temporary schedule will make it go back to its permanent
         * schedule. Since weekday is arbitrary, we use the date of the one
         * being requested and convert it into timezone of the permanent.
         */
        const requestedDateTz = moment
          .tz(
            `${request.from_date} ${request.new_start_time}`,
            'YYYY-MM-DD HH:mm',
            request.tzinfo,
          )
          .tz(request.metadata.tzinfo!);
        // Let's set the time and date from its timezone
        const startTime = request.metadata.start_time!.split(':');
        requestedDateTz.set({
          weekday: convertPyToJsDay(request.metadata.weekday!),
          hour: +startTime[0],
          minute: +startTime[1],
          second: 0,
        });

        const ret = {
          ...request.metadata,
          ...extractScheduleWithDateStartEndTime({
            from_date: requestedDateTz.format('YYYY-MM-DD'),
            to_date: requestedDateTz.format('YYYY-MM-DD'),
            start_time: request.metadata.start_time!,
            end_time: request.metadata.end_time!,
            tzinfo: request.metadata.tzinfo!,
          }),
        };

        return ret;
      }

      return {
        ...request,
        ...extractScheduleWithDateStartEndTime({
          from_date: request.from_date!,
          to_date: request.to_date!,
          weekday: request.weekday!,
          tzinfo: request.tzinfo,
          start_time: request.new_start_time,
          end_time: request.new_end_time,
        }),
      };
    })(),
  );

  return {
    ...nextLocalDatesRet,
    /**
     * Current schedule returned props
     */
    currentSchedule,
    getCurrentDayFormattedSchedule: () => {
      return isCurrentNoPermanent ? (
        moment
          .tz(
            `${request.from_date} ${request.new_start_time}`,
            'YYYY-MM-DD HH:mm',
            request.tzinfo,
          )
          .local()
          .format('ddd, MMM D, YYYY')
      ) : currentSchedule.dayTime.date ? (
        currentSchedule.dayTime.date
      ) : returnTextOnly ? undefined : (
        <Skeleton variant='text' sx={{ flex: 1 }} />
      );
    },
    /**
     * New schedule returned props
     */
    newSchedule,
    getNewDayFormattedSchedule: () => {
      return isNewScheduleNoPermanent
        ? moment
            .tz(
              `${request.from_date} ${request.new_start_time}`,
              'YYYY-MM-DD HH:mm',
              request.tzinfo,
            )
            .local()
            .format('ddd, MMM D, YYYY')
        : newSchedule.dayTime.date;
    },
  };
}
