import React from 'react';
import {
  Box,
  Avatar,
  ListItemAvatar,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import { M3IconButton } from '../M3/M3Button';
import { M3ListItem, M3ListItemText } from '../M3/M3ListItem';

import { JobSeekerItemResponse } from '../../types/jobseeker';
import { useCurrentProfile } from '../../hooks/profile';
import { useAppProvider } from '../../providers/app/app';

type Props = {
  isPromoteDemote?: boolean;
  item: JobSeekerItemResponse;
  onImpersonateClick?: () => void;
  onPromoteClick?: (email: string) => void;
};

const JobSeekerPreviewAsItemResult: React.FC<Props> = ({
  isPromoteDemote,
  item,
  onImpersonateClick,
  onPromoteClick,
}) => {
  const { data: currentProfile } = useCurrentProfile();
  const { isDarkMode } = useAppProvider();
  const user = item;
  const isCurrentUser = currentProfile!.id === user.id;

  return (
    <M3ListItem
      sx={{
        borderRadius: 3,
        '&:hover': {
          background: isDarkMode
            ? 'var(--md-ref-palette-neutral20)'
            : 'var(--md-ref-palette-neutral95)',
        },
      }}
    >
      <ListItemAvatar>
        <Avatar></Avatar>
      </ListItemAvatar>
      <M3ListItemText
        primary={
          <Typography display='flex' alignItems='center' component='div'>
            <Typography fontWeight={500}>{item.name}</Typography>
            &nbsp;
            <span
              style={{
                opacity: 0.5,
                fontSize: 12,
              }}
            >
              {`- registered on ${moment
                .utc(item.created)
                .local()
                .format('MMM D, YYYY')}`}
            </span>
          </Typography>
        }
        secondary={item.email || <>&nbsp;</>}
        sx={{
          '.MuiListItemText-secondary': {
            opacity: 0.9,
          },
        }}
      />
      {!isCurrentUser && !isPromoteDemote && (
        <M3IconButton
          sx={{ p: 0 }}
          disabled={!item.auth_user}
          onClick={item.auth_user ? onImpersonateClick : undefined}
        >
          <Tooltip
            title={`Preview as ${user.name.trim()}`}
            placement='left'
            disableInteractive
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{ p: 1, height: '100%', width: '100%' }}
            >
              {item.auth_user ? (
                <PeopleOutlineOutlinedIcon />
              ) : (
                <PersonOffOutlinedIcon />
              )}
            </Box>
          </Tooltip>
        </M3IconButton>
      )}
      {isPromoteDemote && (
        <M3IconButton
          sx={{ p: 0 }}
          onClick={() => onPromoteClick?.(item.email)}
        >
          <Tooltip
            title={`Promote user ${user.name.trim()} as Path Beta User`}
            placement='left'
            disableInteractive
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{ p: 1, height: '100%', width: '100%' }}
            >
              <PersonAddAltOutlinedIcon />
            </Box>
          </Tooltip>
        </M3IconButton>
      )}
    </M3ListItem>
  );
};

export function JobSeekerPreviewAsItemResultSkeleton() {
  return (
    <M3ListItem
      sx={{
        borderRadius: 3,
      }}
    >
      <ListItemAvatar>
        <Skeleton variant='circular' sx={{ width: 40, height: 40 }} />
      </ListItemAvatar>
      <M3ListItemText
        primary={
          <Skeleton variant='text' sx={{ ml: -2, mr: -2, maxWidth: 100 }} />
        }
        secondary={<Skeleton variant='text' sx={{ maxWidth: 200 }} />}
      />
    </M3ListItem>
  );
}

export default JobSeekerPreviewAsItemResult;
