import { IterableObject } from '../types/types';

export function csvToJson<T = IterableObject>(rawString: string = ''): T[] {
  const out: T[] = [];
  const rows = parseCsvToArray(rawString);
  const header: string[] = rows.shift() || [];

  // map through the array without the header
  rows.forEach((row) => {
    let data: IterableObject = {};
    // map key container object
    header!.forEach((key, index) => (data[key] = row[index]));
    // clean up the keys not including empty key
    const cleanData: T = Object.keys(data)
      .filter((key) => !!key)
      .reduce((prev: IterableObject, current: string) => {
        prev[current] = data[current] as any;
        return prev;
      }, {}) as T;
    // insert the data
    out.push(cleanData);
  });

  return out;
}

// #https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
export function parseCsvToArray(text: string) {
  let p = '',
    row = [''],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;

  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if (',' === l && s) l = row[++i] = '';
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = '')];
      i = 0;
    } else row[i] += l;
    p = l;
  }

  return ret.filter((row) => !!row.filter((v) => !!v).length);
}

export function jsonToCsv<T = IterableObject>(
  items: T[] = [],
  header: string[] = [],
  headerKey: string[] = [],
) {
  const headerString = header.join(',');

  // handle null or undefined values here
  const replacer = (key: string, value: string) => value ?? '';

  const rowItems = items.map((row) =>
    headerKey
      .map((fieldName) =>
        JSON.stringify((row as IterableObject)[fieldName], replacer),
      )
      .join(','),
  );

  // join header and body, and break into separate lines
  const csv = [headerString, ...rowItems].join('\r\n');

  return csv;
}
