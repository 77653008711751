import React from 'react';
import { Paper, Box } from '@mui/material';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';

import WorkspaceMenuList, { workspaceWidth } from '../../WorkspaceMenuList';
import AppDrawerMenuList from '../../AppDrawerMenuList';
import DrawerPermanent from '../../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../../M3/M3SurfaceContainer';

import { useAppProvider } from '../../../providers/app/app';
import { MenuItem } from '../../../types/types';
import { applyOpacityOnColor } from '../../../utils/color';

type Props = {};

const FormsDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const menuWidth = drawerWidth - workspaceWidth;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const menus: MenuItem[] = [
    {
      id: 'headline-issue',
      name: 'Submit Issue',
      icon: <BackupTableOutlinedIcon />,
      headline: true,
    },
    {
      id: 'forms-attendance',
      name: 'Attendance',
      basePath: '/help/attendance',
      path: '/help/attendance',
    },
    {
      id: 'forms-tools',
      name: 'IT Tech',
      basePath: '/help/tools',
      path: '/help/tools',
    },
    /*
    {
      id: 'forms-time',
      name: 'Time',
      basePath: '/help/time',
      path: '/help/time',
    },
    */
    {
      id: 'forms-divider',
      divider: true,
    },
    {
      id: 'forms-bk',
      name: 'Bookkeeping',
      basePath: '/help/bk',
      path: '/help/bk',
      roles: ['superuser', 'developer', 'internal', 'teammate'],
    },
    {
      id: 'forms-engage',
      name: 'Engage',
      basePath: '/help/engage',
      path: '/help/engage',
      roles: ['internal'],
    },
  ];

  return (
    <DrawerPermanent>
      <WorkspaceMenuList />
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box style={{ height: toolbarHeight }} />
        <Box flex={1}>
          <AppDrawerMenuList menus={menus} />
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default FormsDrawer;
