import { useState } from 'react';

export function useFormRequestStatus() {
  return useState<{
    status: 'processing' | 'success' | 'error' | null;
    error: null | Error;
  }>({
    status: null,
    error: null,
  });
}
