import React, { useMemo } from 'react';
import { Paper, Box } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

import { workspaceWidth } from '../WorkspaceMenuList';
import AppDrawerMenuList from '../AppDrawerMenuList';
import DrawerPermanent from '../DrawerPermanent';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { MenuItem } from '../../types/types';
import { applyOpacityOnColor } from '../../utils/color';
import {
  CollectionItemProps,
  getCollectionItemProps,
} from '../../providers/intercom';
import { useIntercomProvider } from '../../providers/intercom/intercom';

type Props = {};

const UserNumbersDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    isTopBannerVisible,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();
  const { collections, admins } = useIntercomProvider();

  const menuWidth = drawerWidth - workspaceWidth * 0.7;
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const collectionItems: CollectionItemProps[] = useMemo(() => {
    const items: CollectionItemProps[] = [];
    collections.map((collection) =>
      items.push(getCollectionItemProps(collection, admins)),
    );
    return items;
  }, [collections, admins]);

  const menus: MenuItem[] = [
    {
      id: 'profit-loss',
      name: 'Profit & Loss Statement',
      icon: <AttachMoneyOutlinedIcon />,
      basePath: '/embed/profit-and-loss-statement',
      path: '/embed/profit-and-loss-statement',
    },
    {
      id: 'wip-data-room',
      name: 'WIP Data Room',
      icon: <HelpOutlineOutlinedIcon />,
      collapsable: true,
      children: [
        {
          id: 'help-center-all',
          name: 'All',
          path: `/help/dataroom`,
          basePath: `/help/dataroom`,
          isExact: true,
        } as MenuItem,
        ...collectionItems.map((collection) => {
          return {
            id: collection.id,
            name: collection.collection.name.slice(0, 26),
            path: `/help/dataroom/collection/${collection.id}`,
            basePath: `/help/dataroom/collection/${collection.id}`,
          } as MenuItem;
        }),
      ],
    },
  ];

  return (
    <DrawerPermanent withWorkspace={false}>
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box
          style={{
            height: toolbarHeight + (isTopBannerVisible ? toolbarHeight : 0),
          }}
        />
        <Box flex={1}>
          <AppDrawerMenuList menus={menus} />
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default UserNumbersDrawer;
