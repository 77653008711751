import { AxiosRequestConfig } from 'axios';
import { useMutationApi, useQueryApi } from './global/useApi';
import { UseQueryOptions } from '@tanstack/react-query';
import { useIdentifier } from './global/useIdentifier';
import { defaultReactQueryParams } from '../utils/request';
import { PromptConfigItemResponse, PromptConfigModel } from '../types/openai';
import { BasicQuery } from '../types/request';

export function useOpenAIWhisper(
  data?: any,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<any, { session_id: string }>(
    `/openai/whisper/`,
    {},
    {
      ...axiosConfig,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}
type UseOpenAIWhisperTranscribeResponse = {
  data: string;
  status: 'in_progress' | 'done' | 'error' | 'retrying';
};
type UseOpenAIWhisperTranscribeParams = {
  session_id: string;
};
export function useOpenAIWhisperTranscribe(
  params: UseOpenAIWhisperTranscribeParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseChatOpenAIParams>({
    params,
    baseIdentifier: 'useOpenAIWhisperTranscribe',
    identifierKeys: ['session_id'],
    queryParamKeys: [],
  });

  return useQueryApi<UseChatOpenAIParams, UseOpenAIWhisperTranscribeResponse>(
    identifiers,
    `/openai/whisper/${params.session_id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type ChatOpenAISessionResponse = {
  session_id: string;
};
export function usePostChatOpenAI<T, R = ChatOpenAISessionResponse>(
  props?: any,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<T, R>(`/openai/chat/`, {}, axiosConfig);
}

type UseChatOpenAIParams = BasicQuery & {
  session_id?: string;
};
type UseChatOpenAIResponse = {
  data: string;
  status: 'in_progress' | 'done' | 'error' | 'retrying';
};

export function useChatOpenAI(
  params: UseChatOpenAIParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseChatOpenAIParams>({
    params,
    baseIdentifier: 'useChatOpenAI',
    identifierKeys: ['session_id'],
    queryParamKeys: [],
  });

  return useQueryApi<UseChatOpenAIParams, UseChatOpenAIResponse>(
    identifiers,
    `/openai/chat/${params.session_id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseOpenAiPromptConfigurationProps = BasicQuery & {
  prompt_identifier: string;
};
export function useOpenAiPromptConfigurationByNameIdentifier(
  params: UseOpenAiPromptConfigurationProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseOpenAiPromptConfigurationProps>({
      params,
      baseIdentifier: 'useOpenAiPromptConfigurationByNameIdentifier',
      identifierKeys: ['prompt_identifier'],
      queryParamKeys: ['prompt_identifier'],
    });

  return useQueryApi<
    UseOpenAiPromptConfigurationProps,
    PromptConfigItemResponse
  >(
    identifiers,
    `/openai/prompt-configurations/${queryParams.prompt_identifier}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type PromptConfigItemParams = PromptConfigModel & {};
type UseOpenAiSavePromptConfigurationProps = {
  prompt_identifier?: string;
};
export function useOpenAiSavePromptConfiguration<D = PromptConfigItemParams>(
  params: UseOpenAiSavePromptConfigurationProps = {},
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D, PromptConfigItemResponse>(
    `/openai/prompt-configurations/${
      params.prompt_identifier ? `${params.prompt_identifier}/` : ''
    }`,
    {},
    { ...axiosConfig },
  );
}

type UseOpenAIApiPromptConfigurationParams = {
  prompt_identifier: string;
  segment: 'chat-completion' | 'history';
};
export function useOpenAIApiPromptConfiguration<D>(
  params: UseOpenAIApiPromptConfigurationParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<D, ChatOpenAISessionResponse>(
    `/openai/prompt-configurations/${params.prompt_identifier}/${params.segment}/`,
    {},
    { ...axiosConfig },
  );
}
