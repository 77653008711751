import moment from 'moment';
import {
  DailyScheduleItem,
  ScheduleItem,
  UserScheduleItem,
} from '../types/schedules';
import { DayScheduleLocal } from '../components/Schedule/ScheduleUserList';
import { IterableObject } from '../types/types';
import { sortBy } from './array';
import { getTimeDifferenceBetweenStartAndEnd } from './date';

/**
 * Converts the day in javascript/moment day to Python compatibility day:
 * Python    Day       Javascript
 *  0       Monday       1
 *  1       Tuesday      2
 *  2       Wednesday    3
 *  3       Thursday     4
 *  4       Friday       5
 *  5       Saturday     6
 *  6       Sunday       0
 */
export function convertJsDayToPyDay(jsDay: number) {
  let day = jsDay - 1;
  if (day < 0) day = 6;
  return day;
}

export function convertPyToJsDay(pyDay: number) {
  let day = pyDay + 1;
  if (day > 6) day = 0;
  return day;
}

/**
 * This function accepts and manipulate is internal fields to create the
 * start_date_time, end_date_time which is in UTC. We do this since initially
 * we use `/daily` endpoint which has already have a start_date_time converted
 * into the server. After the new change, we are using raw schedule data.
 */
export function extractScheduleWithDateStartEndTime(
  schedule: Partial<UserScheduleItem | ScheduleItem>,
  config?: { isAbsoluteDate?: boolean },
) {
  const { isAbsoluteDate } = config ?? {};

  /**
   * Check if it's a schedule rest day. Rest day is a special case since
   * its time starts from 12am. The date changes depending on the local timezone
   * that's viewing it. So in order to compensate with the
   */
  if (isScheduleRestDay(schedule)) {
    let localScheduleRestDay = convertTemporaryRestDayToLocalSchedules({
      ...schedule,
    } as ScheduleItem);
    const { startDateTime, endDateTime } =
      getTemporaryScheduleStartEndLocalDates(localScheduleRestDay);
    schedule = {
      ...localScheduleRestDay,
      date: startDateTime.format('YYYY-MM-DD'),
      start_date_time: startDateTime.clone().utc().format(),
      end_date_time: endDateTime.clone().utc().format(),
    };
  }

  const jsDay = convertPyToJsDay(schedule.weekday!);
  // Creating the current time from the timezone given setting the current day
  let mTz = schedule.tzinfo
    ? moment.tz(schedule.tzinfo!).startOf('day')
    : moment().startOf('day');
  let startTime, endTime, startDateTime, endDateTime;

  if (schedule.from_date) {
    startTime = startDateTime = moment.tz(
      `${schedule.from_date} ${schedule.start_time}`,
      'YYYY-MM-DD HH:mm',
      schedule.tzinfo!,
    );
    endTime = endDateTime = moment.tz(
      `${schedule.from_date} ${schedule.end_time}`,
      'YYYY-MM-DD HH:mm',
      schedule.tzinfo!,
    );
  } else {
    mTz = moment.tz(`${jsDay}`, 'e', schedule.tzinfo!);

    startTime = moment(schedule.start_time!, 'HH:mm');
    endTime = moment(schedule.end_time!, 'HH:mm');

    startDateTime = mTz.clone().set({
      hour: startTime.hour(),
      minute: startTime.minute(),
      second: startTime.second(),
    });
    endDateTime = mTz.clone().set({
      hour: endTime.hour(),
      minute: endTime.minute(),
      second: endTime.second(),
    });
  }

  /**
   * Checking if it's absolute date, regardless for timezone. This is necessary
   * in taking only the absolute date. While the time remains the same for
   * that date only.
   */
  if (isAbsoluteDate && schedule.from_date) {
    const date = moment(schedule.from_date).startOf('day');

    startDateTime = startDateTime.clone().local().set({
      date: date.date(),
      month: date.month(),
      year: date.year(),
    });

    endDateTime = endDateTime.clone().local().set({
      date: date.date(),
      month: date.month(),
      year: date.year(),
    });
  }

  // Set the start and end time of the date with the adjusted timezone to local

  // Format the dates to UTC same as in the server
  const start_date_time =
    schedule.start_date_time || startDateTime.utc().format();
  const end_date_time = schedule.end_date_time || endDateTime.utc().format();

  // Convert the date in local
  const from_date = moment.utc(start_date_time).local().format('YYYY-MM-DD');

  return {
    start_date_time,
    end_date_time,
    from_date,
    to_date: from_date,
  };
}

/**
 * Return the schedules only that day. For temporary schedules, that
 * happens to have mixed with perm schedules. In order to hide some
 * permanent (or temporary) schedule that needs to be overwritten (hide/exclude)
 */
export function getLatestProperLocalSchedules(daySchedule: DayScheduleLocal[]) {
  let tempSchedules = daySchedule.filter((s) => s.is_temporary);
  let permSchedules = daySchedule.filter((s) => !s.is_temporary);

  /**
   * NOTE: sort the temporary schedule by id in descending order.
   * The first one now after sorting is the latest changes that have been made.
   */
  tempSchedules = sortBy(tempSchedules, 'id', true, 'DESC', 'number');

  let schedules: DayScheduleLocal[] = [];

  if (tempSchedules.length) {
    const scheduleFields: (keyof ScheduleItem)[] = ['id', 'is_temporary'];
    /**
     * Check if the temporary schedule is new version. Since its structure
     * defer greatly before the overhaul fix of schedules.
     */
    const isNewestVersion = tempSchedules.some((ts) => {
      return (
        !!ts.metadata &&
        !!ts.metadata.length &&
        ts.metadata.some((pm) => scheduleFields.every((f) => f in pm))
      );
    });

    const scheduleIdsToFilter = tempSchedules
      .map((ts) => getNestedAssociatedMetadata(ts))
      .flat(Infinity) as DayScheduleLocal[];

    if (isNewestVersion) {
      /**
       * When there's a temporary schedule, check also if there's a permanent schedule.
       * In order to override the proper permanent schedule. Let's filter the
       * permanent schedules it needs to filter because it will be possible
       * that there are multiple permanent schedules in a day due to the shifting
       * of dates to to the timezone
       */
      permSchedules = permSchedules.filter((ps) => {
        // Check the permanent schedule attached to the temporary schedule
        // Filter out the permanent schedule that needs to be overwritten
        let isExist = scheduleIdsToFilter.some((as) => as.id === ps.id);
        return !isExist;
      });
      schedules = [...permSchedules];

      schedules = [...schedules, ...tempSchedules];
    } else {
      /**
       * Old version/new version tends to have multiple schedules. In order to
       * avoid having to show multiple schedules on the same day. Assuming
       * they are just being overwritten.
       */
      let tempDupShield: IterableObject<boolean> = {};
      tempSchedules = tempSchedules.filter((ts) => {
        // Use the original from_date on what it needs to be overwritten
        let key = (ts.from_date ?? ts.date)!;
        if (tempDupShield[key]) return false;
        tempDupShield[key] = true;
        return true;
      });

      /**
       * If it's the old version before the fix (using the /daily) endpoint.
       * Right now, we are using the raw data schedule to render everything in the frontend.
       * Always attach the endpoint at the last one for temporary schedule
       */
      schedules = [...schedules, ...tempSchedules];
    }
  } else {
    schedules = permSchedules;
  }

  return schedules;
}

/**
 * Returns the schedule entries in the metadata stored as in the latest
 * temporary schedule. When working with overwriting temporary schedules.
 * The latest one gets the accumulated schedule it overwritten. ie.
 * {
 *    id: 1, // current schedule
 *    ...
 *    metadata: [{
 *       id: 2, // previous schedule that get overwritten by 1
 *       ...
 *       metadata: [{
 *          id: 3, // previous schedule that get overwritten by 2
 *          ...
 *          metadata: (...)
 *       }]
 *    }]
 * }
 */
export function getNestedAssociatedMetadata(
  ts: DayScheduleLocal,
  deep: number = 0,
): DayScheduleLocal[] {
  const metadata = ts.metadata ?? [];
  const ret = metadata
    ?.map((pm) => getNestedAssociatedMetadata(pm as DayScheduleLocal, deep + 1))
    .flat(Infinity)
    .filter((pm) => !!pm);

  /**
   * Ignore the first level of nested. It's the very top associated schedule
   * attached to the metadata. As it's the latest/current schedule.
   */
  if (deep !== 0) {
    ret.push(ts);
  }

  return ret as DayScheduleLocal[];
}

/**
 * Returns the associated metadata of the latest schedules which will be used
 * to check some conditions on the schedule. It returns a list of schedules
 * that will be used to check on the schedules to not be displayed. It
 * get loops through the list of schedules, gets its nested associated metadata.
 */
export function getLatestAssociatedMetadataSchedule(
  schedules: DayScheduleLocal[],
) {
  let latestAssociated: DayScheduleLocal[] = [];

  schedules.forEach((s) => {
    const associated = getNestedAssociatedMetadata(s);
    /*
    if (associated.length >= latestAssociated.length) {
      latestAssociated = associated;
    }
    */
    associated.forEach((aas) => {
      /**
       * NOTE: Not sure if this good to do this instead of the
       * if(associated.length >= latestAssociated.length) condition to
       * get the latest associated metadata schedule.
       */
      if (!latestAssociated.some((las) => las.id === aas.id)) {
        latestAssociated.push(aas);
      }
    });
  });

  return latestAssociated;
}

/**
 * Transforms the go2 schedules from the server into the local converting
 * its utc start date into local. Grouping them according to the original
 * structure before the new change of choosing to use temporary and permanent
 * schedule endpoints instead of the `/daily` endpoint which has the same
 * structure it returns of this function
 */
export function go2SchedulesSelectTransform(data: DailyScheduleItem[]) {
  /**
   * Container for transforming/adjusting the internal schedule data
   * converted into local
   */
  const userBySchedulesByDate: IterableObject<
    IterableObject<DailyScheduleItem['schedules']>
  > = {};
  let adjustedData: DailyScheduleItem[] = [];

  data.forEach((schedule) => {
    /**
     * Create the container for the schedule, it should be by date
     */
    userBySchedulesByDate[schedule.user] =
      userBySchedulesByDate[schedule.user] || {};

    schedule.schedules.forEach((innerSchedule) => {
      const schedulesByDate = userBySchedulesByDate[schedule.user];
      /**
       * Convert the start_date_time to local...
       */
      innerSchedule.date = moment
        .utc(innerSchedule.start_date_time)
        .local()
        .format('YYYY-MM-DD');

      /**
       * group the dates by its date scheduled per user.
       */
      schedulesByDate[innerSchedule.date] =
        schedulesByDate[innerSchedule.date] || [];
      schedulesByDate[innerSchedule.date].push(innerSchedule);
    });
  });

  Object.keys(userBySchedulesByDate).forEach((userId) => {
    const schedulesByDate: IterableObject<DailyScheduleItem['schedules']> =
      userBySchedulesByDate[userId];

    Object.keys(schedulesByDate).forEach((date) => {
      const scheduleItems = schedulesByDate[date];
      /**
       * Transform back the schedules into its original format response
       * with the adjusted data converting it to local
       */
      adjustedData.push({
        user: +userId,
        weekday: convertJsDayToPyDay(moment(date).day()),
        date: date,
        schedules: scheduleItems ?? [],
      });
    });
  });

  return adjustedData;
}

/**
 * Checks the schedule if it's a rest day schedule
 */
export function isScheduleRestDay(r: {
  start_time?: string | null;
  end_time?: string | null;
}) {
  let isRestDay =
    r.start_time === r.end_time &&
    (r.start_time === '00:00' || r.start_time === '00:00:00');

  if (!r.start_time || !r.end_time) {
    isRestDay = true;
  }

  return isRestDay;
}

/**
 * Calculates the temporary date schedule to have a local_date. Local date
 * is the adjusted date from the point where it's being plotted. Due to the
 * rest day having the time 12am-12am. Its day move different due to timezone.
 * So we add/deduct day to compensate for it.
 * @deprecated Might need to delete later on
 */
export function convertTemporaryRestDayToLocalSchedules(ts: ScheduleItem) {
  const dateTzStartTime = moment
    .tz(`${ts.from_date} ${ts.start_time}`, 'YYYY-MM-DD HH:mm', ts.tzinfo)
    .local();
  const diffDay = moment(dateTzStartTime.format('YYYY-MM-DD')).diff(
    ts.from_date,
    'day',
  );
  const adjusted = moment(dateTzStartTime.format('YYYY-MM-DD')).add(
    -diffDay,
    'day',
  );

  return {
    ...ts,
    // NOTE: Temporary avoid having local_date for now, let's not move the rest day atm
    // local_date: adjusted.format('YYYY-MM-DD'),
  };
}

/**
 * Returns start/end date local. From the local_date or from the from_date
 * of schedule. local_date is a date mostly used for rest day. In order
 * to properly move the rest day to appropriate day.
 */
export function getTemporaryScheduleStartEndLocalDates(ts: ScheduleItem) {
  const dateLocalStartTime = ts.local_date
    ? moment(ts.local_date).startOf('day')
    : moment
        .tz(`${ts.from_date} ${ts.start_time}`, 'YYYY-MM-DD HH:mm', ts.tzinfo)
        .local();
  const dateLocalEndTime = ts.local_date
    ? moment(ts.local_date).startOf('day')
    : moment
        .tz(`${ts.from_date} ${ts.end_time}`, 'YYYY-MM-DD HH:mm', ts.tzinfo)
        .local();

  return {
    startDateTime: dateLocalStartTime,
    endDateTime: dateLocalEndTime,
  };
}

export type GetFormattedLocalScheduleFromStartDateTimeRet = {
  dayTime: {
    day: null | string;
    date: null | string;
    start_time_local: null | string;
    end_time_local: null | string;
  };
  timeDiff: {
    hours: number;
    overlap: boolean;
  };
};
/**
 * Returns the formatted local schedule from the start/end date time
 * to be displayed in the page. Its hours difference or has overlapped the
 * hours from the current day to the next morning.
 */
export function getFormattedLocalScheduleFromStartDateTime(
  params?: {
    start_date_time: string | null;
    end_date_time: string | null;
  } | null,
): GetFormattedLocalScheduleFromStartDateTimeRet {
  const dayTime = {
    day: params
      ? moment.utc(params.start_date_time).local().format('dddd')
      : null,
    date: params
      ? moment.utc(params.start_date_time).local().format('ddd, MMM D, YYYY')
      : null,
    start_time_local: params
      ? moment.utc(params.start_date_time).local().format('hh:mm a')
      : null,
    end_time_local: params
      ? moment.utc(params.end_date_time).local().format('hh:mm a')
      : null,
  };
  const timeDiff = params
    ? getTimeDifferenceBetweenStartAndEnd(
        dayTime.start_time_local!,
        dayTime.end_time_local!,
      )
    : {
        hours: 0,
        overlap: false,
      };

  return {
    dayTime,
    timeDiff,
  };
}
