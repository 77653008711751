import { sortBy } from '../utils/array';

export type AudienceSegment = {
  id: string;
  name:
    | 'New'
    | 'Active'
    | 'Slipping Away'
    | 'Partner Members'
    | 'Go2 Members'
    | 'Go2 Members | Teammates'
    | 'Partner Member Leads'
    | 'Teammate Leads'
    | 'Numbers'
    | 'Go2 Members | Internal'
    | 'Content Team'
    | 'General Team Review'
    | 'Go2 Members | EM'
    | 'Jobseeker'
    | 'Active Jobseeker'
    | 'Active Jobseeker in Pool'
    | 'Failed First Filter Job Seeker';
};

/**
 * Remove this audience
 */
export const audienceSegments: AudienceSegment[] = sortBy(
  [
    {
      id: '6246058bbcf56d1134c0d62f',
      name: 'New',
    },
    {
      id: '6246058bbcf56d1134c0d630',
      name: 'Active',
    },
    {
      id: '6246058bbcf56d1134c0d631',
      name: 'Slipping Away',
    },
    {
      id: '62aca81a7b933d5a554a554a',
      name: 'Partner Members',
    },
    {
      id: '62b1c1e90f55a3769227b73b',
      name: 'Go2 Members',
    },
    {
      id: '62b1c3440f55a3e289b3867d',
      name: 'Go2 Members | Teammates',
    },
    {
      id: '62b1c2c83d34c7ae577e2b83',
      name: 'Partner Member Leads',
    },
    {
      id: '62b1c2da51d799ba89dc7f6e',
      name: 'Teammate Leads',
    },
    {
      id: '631f58b00d09ac7865cf4072',
      name: 'Numbers',
    },
    {
      id: '62b1c36197f288d42f5873d1',
      name: 'Go2 Members | Internal',
    },
    {
      id: '63348059446699cf3bfedfdd',
      name: 'Content Team',
    },
    {
      id: '633ddb558db7ddd24de02a9b',
      name: 'General Team Review',
    },
    {
      id: '636269a5754aadf738a6217b',
      name: 'Go2 Members | EM',
    },
    {
      id: '637ba5f77982a150c657a67c',
      name: 'Jobseeker',
    },
    {
      id: '637d1c04c382ed40528cbc80',
      name: 'Active Jobseeker',
    },
    {
      id: '637d1c25b3c6d059d19ab17a',
      name: 'Active Jobseeker in Pool',
    },
    {
      id: '638db3ba8927cc1b1d47eace',
      name: 'Failed First Filter Job Seeker',
    },
  ],
  'name',
  true,
  'asc',
);

export type AudienceUserType = {
  id: 'user' | 'leads' | 'visitors';
  name: 'User' | 'Leads' | 'Visitors';
};

export const audienceUserTypes: AudienceUserType[] = sortBy(
  [
    {
      id: 'user',
      name: 'User',
    },
    {
      id: 'leads',
      name: 'Leads',
    },
    {
      id: 'visitors',
      name: 'Visitors',
    },
  ],
  'name',
  true,
  'asc',
);
