import React, { PropsWithChildren } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Chip, Skeleton, Typography } from '@mui/material';

import { M3Card } from '../../M3/M3Card';
import M3SurfaceContainer from '../../M3/M3SurfaceContainer';

import { JobDescriptionItemResult } from '../../../types/job-description';
import { useWorkspaceProvider } from '../../../providers/workspace/workspace';

type JobDescriptionItemProps = PropsWithChildren & {
  item: JobDescriptionItemResult;
  path: string;
};

export const JobDescriptionItem = ({ item, path }: JobDescriptionItemProps) => {
  const { items: workspaces } = useWorkspaceProvider();
  const os = workspaces.find((ws) => +ws.id === +item.division!);

  return (
    <Link
      to={path}
      style={{
        color: 'initial',
        textDecoration: 'initial',
      }}
    >
      <M3Card
        sx={{
          cursor: 'pointer',
          marginBottom: '12px',
        }}
      >
        <M3SurfaceContainer
          elevation={1}
          borderRadius={3}
          sx={{
            p: 2,
            pl: 3,
            pr: 3,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography component='div' fontSize={22} pb={1}>
              {item.title}
            </Typography>
            {os && <Chip label={os.name} size='small' />}
          </Box>
          <Box>
            <Typography component='div' fontSize={12} sx={{ opacity: 0.5 }}>
              Last Edited:
            </Typography>
            {/* <Typography component='div' fontSize={12}>
              Antonio Saqueton
            </Typography> */}
            <Typography component='div' fontSize={12}>
              {moment.utc(item.modified).format('MMM D, YYYY')}
            </Typography>
          </Box>
        </M3SurfaceContainer>
      </M3Card>
    </Link>
  );
};

export default JobDescriptionItem;

type JobDescriptionItemSkeletonProps = PropsWithChildren;
export function JobDescriptionItemSkeleton(
  props: JobDescriptionItemSkeletonProps,
) {
  return (
    <M3Card
      sx={{
        marginBottom: '12px',
      }}
    >
      <M3SurfaceContainer
        elevation={1}
        borderRadius={3}
        sx={{
          p: 2,
          pl: 3,
          pr: 3,
          pb: 1.3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Skeleton variant='text' height={40} width='20vw' sx={{ mt: -0.5 }} />
          <Skeleton variant='text' height={36} width={80} />
        </Box>
        <Box>
          <Skeleton variant='text' height={20} width={80} sx={{ mt: -0.2 }} />
          <Skeleton variant='text' height={20} width={60} sx={{ mt: -0.2 }} />
        </Box>
      </M3SurfaceContainer>
    </M3Card>
  );
}
