import { Avatar, Box, Skeleton, Stack, Typography } from '@mui/material';
import React, { RefObject, useEffect, useMemo } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import { M3Button } from '../../M3/M3Button';
import BasicPopoverWithSearch, {
  M3OptionItem,
  useBasicPopover,
} from '../../Popover/BasicPopoverWithSearch';

import { useWorkspaceProvider } from '../../../providers/workspace/workspace';
import { getUserInitials } from '../../../utils/user';
import { useDivisionById } from '../../../hooks/division';
import { useAppProvider } from '../../../providers/app/app';

type Props = {
  allList?: boolean;
  editable?: boolean;
  showWarning?: boolean;
  asDropdown?: boolean;
  divisionId?: string | number | null;
  onSelect?: (option: M3OptionItem) => void;
};

const JobDescriptionOSSelection = ({
  allList,
  divisionId,
  editable,
  showWarning,
  asDropdown,
  onSelect,
}: Props) => {
  const { isDarkMode } = useAppProvider();
  const { items: workspaces, current: currentWorkspace } =
    useWorkspaceProvider();
  const { data: division } = useDivisionById(
    { id: divisionId! },
    { enabled: !!divisionId && divisionId !== 'all' },
  );

  const osChangePopover = useBasicPopover();
  const { options: osOptions, selected } = useMemo(() => {
    let options: M3OptionItem[] = workspaces
      .filter((ws) => ws.type === 'division_partner')
      .map((ws) => ({
        id: ws.id,
        label: ws.name,
        props: ws,
      }));

    if (allList && options.length > 1) {
      options.unshift({
        id: 'all',
        label: 'All',
      });
    }

    return {
      options,
      selected: divisionId
        ? divisionId === 'all' && options.length > 1
          ? options[0]
          : options.find((option) => +option.id === +divisionId) ||
            (division
              ? {
                  id: division.id,
                  label: division.name,
                }
              : null)
        : options[0],
    };
  }, [allList, divisionId, workspaces, division]);

  useEffect(() => {
    if (!editable || currentWorkspace?.id === 'my-squad' || !currentWorkspace)
      return;

    onSelect?.({
      id: currentWorkspace.id,
      label: currentWorkspace.name,
    });
    // eslint-disable-next-line
  }, [editable, currentWorkspace]);

  return (
    <>
      <Stack
        flex={asDropdown ? 1 : undefined}
        gap={2}
        direction='row'
        alignItems='center'
        mb={1}
        sx={
          asDropdown && osOptions.length > 1
            ? {
                borderRadius: 1,
                cursor: 'pointer',
                userSelect: 'none',
              }
            : {}
        }
        ref={osChangePopover.ref as RefObject<HTMLDivElement>}
        onClick={
          asDropdown && osOptions.length > 1 ? osChangePopover.open : undefined
        }
      >
        <Stack
          flex={asDropdown ? 1 : undefined}
          gap={1}
          direction='row'
          alignItems='center'
          height={40}
        >
          {selected ? (
            <>
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  fontSize: 14,
                }}
              >
                {getUserInitials(selected?.label?.replace(/^-+/g, '')).initial}
              </Avatar>
              <Typography
                whiteSpace='nowrap'
                component='div'
                flex={asDropdown ? 1 : undefined}
              >
                {selected?.label}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton variant='circular' sx={{ width: 30, height: 30 }} />
              <Skeleton variant='text' width={100} sx={{ height: 30 }} />
            </>
          )}
        </Stack>
        {editable &&
          osOptions.length > 1 &&
          !asDropdown &&
          currentWorkspace?.id === 'my-squad' && (
            <Box>
              <M3Button
                sx={{ fontSize: 14 }}
                active={osChangePopover.isOpen}
                onClick={osChangePopover.open}
              >
                <span style={{ padding: '0 8px' }}>Change</span>
              </M3Button>
            </Box>
          )}
        {editable && osOptions.length > 1 && asDropdown && (
          <Typography component='span'>
            <ArrowDropDownOutlinedIcon sx={{ mt: 1, opacity: 0.5 }} />
          </Typography>
        )}
        {showWarning &&
          osOptions.length > 1 &&
          currentWorkspace?.id === 'my-squad' && (
            <Typography
              ml={-1}
              component='div'
              fontSize={11}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-error80)'
                  : 'var(--md-ref-palette-error40)',
              }}
            >
              This cannot be changed once the job description is created
            </Typography>
          )}
      </Stack>
      <BasicPopoverWithSearch
        selected={selected}
        open={osChangePopover.isOpen}
        anchorEl={osChangePopover.ref.current}
        options={osOptions}
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: asDropdown ? 'top' : -4,
            horizontal: asDropdown ? -10 : 0,
          },
        }}
        onSelect={(opt) => {
          onSelect?.(opt);
          osChangePopover.close();
        }}
        onClose={osChangePopover.close}
      />
    </>
  );
};

export default JobDescriptionOSSelection;
