import React from 'react';

import MainContainer from '../components/MainContainer';
import { useHasViewPermission } from '../components/HasViewPermission';
import ErrorUnauthorizedAccess from './ErrorUnauthorizedAccess';
import SettingsTeamFocusCreateFocus from './SettingsTeamFocusCreateFocus';
import SettingsTeamAllFocus from './SettingsTeamAllFocus';
import SettingsTeamFocusDetail from './SettingsTeamFocusDetail';

import { usePathnameSegments } from '../hooks/utils/url';

type SettingsTeamFocusAreasProps = {};

export type SettingsTeamFocusSegments = 'create';

const SettingsTeamFocusAreas = (props: SettingsTeamFocusAreasProps) => {
  const [, , secondSegment, thirdSegment] =
    usePathnameSegments<SettingsTeamFocusSegments[]>();

  const hasViewPermission = useHasViewPermission({
    roles: [
      'superuser',
      'developer',
      'internal',
      'partner_member',
      'path_beta',
      'guest',
    ],
  });

  if (!hasViewPermission) {
    return (
      <MainContainer>
        <ErrorUnauthorizedAccess />
      </MainContainer>
    );
  }

  if (secondSegment === 'create' || thirdSegment === 'create') {
    return <SettingsTeamFocusCreateFocus routeKey='builder' />;
  } else if (!!(secondSegment as string)) {
    return <SettingsTeamFocusDetail routeKey='builder' />;
  }

  return <SettingsTeamAllFocus routeKey='builder' />;
};

export default SettingsTeamFocusAreas;
