import {
  Box,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import React, {
  useEffect,
  useState,
  forwardRef,
  useCallback,
  CSSProperties,
  useRef,
} from 'react';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

import RichEditor from '../RichEditor/RichEditor';
import { M3Button } from '../M3/M3Button';
import ModalCardView, { ModalCardViewCloseProps } from '../ModalCardView';
import QuoteView from './QuoteView';
import WindowScrollTop from '../WindowScrollTop';
import AIAssistSideSheet, {
  useAIAssistSideSheet,
} from '../SideSheet/AIAssistSideSheet';

import { useDailyReportQuoteToday, usePostReport } from '../../hooks/report';
import { SODReportData, SODReportParams } from '../../types/report';
import { useUserShiftReportingStatus } from '../../hooks/profile';
import { useIsCardFocused } from '../../hooks/utils/card';
import { useAppProvider } from '../../providers/app/app';
import { isNotNullAndUndefined } from '../../utils/object';
import { IterableObject } from '../../types/types';
import { useHasViewPermission } from '../HasViewPermission';
import { parseURL } from '../../utils/url';
import * as posthog from '../../services/posthog';

type HTMLStateMap = SODReportData & {
  [key: string]: any;
};

type stepName = 'Write' | 'Quote' | 'Review';
const steps = ['Write', 'Quote'] as stepName[];

export type SODFormModalViewProps = ModalCardViewCloseProps & {
  fullView?: boolean;
  close?: (...props: any) => void;
};

const SODForm = forwardRef(
  ({ close, animateOnMount, fullView }: SODFormModalViewProps, ref) => {
    const { isDarkMode, updateActionKeyCounter } = useAppProvider();
    const postReport = usePostReport<SODReportParams>();
    const [activeStep, setActiveStep] = useState(0);
    const [scrollElement, setScrollElement] = useState<
      HTMLDivElement | null | undefined
    >(null);
    const shiftReportingStatus = useUserShiftReportingStatus(
      { type: 'SOD' },
      { cacheTime: 0 },
    );

    const isPromptEngineer = useHasViewPermission({
      roles: ['superuser', 'prompt_engineer'],
    });

    const sideSheetContainerRef = useRef<null | HTMLElement>(null);
    const [, setSideSheetKey] = useState(0);
    const aiAssist = useAIAssistSideSheet({
      openOnInt: true,
    });

    const dailyReportQuoteToday = useDailyReportQuoteToday(
      { key: 'sod_quote' },
      {
        keepPreviousData: false,
        cacheTime: 0,
      },
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const parsedURL = parseURL(window.location.search);

    /**
     * Editor State mapper, with default empty
     */
    const [htmlStateMap, setHtmlStateMap] = useState<HTMLStateMap>({
      today_win: '',
      area_of_focus: '',
      worth_noting: '',
    });

    const isCardFocused = useIsCardFocused();

    const isInBetweenStep = (stepName: stepName) =>
      steps[activeStep] === stepName;

    const isFirstStep = () => activeStep === 0;

    const isLastStep = () => activeStep === steps.length - 1;

    const nextStep = () => {
      setActiveStep((step) => step + 1);
      setIsDrawerOpen(false);
    };

    const prevStep = () => {
      if (isLastStep()) {
        setIsDrawerOpen(true);
        setActiveStep((step) => step - 2);
      } else {
        setIsDrawerOpen(false);
        setActiveStep((step) => step - 1);
      }
    };

    const onEditorValueChange = (name: string) => (html: string) => {
      setHtmlStateMap((state) => ({ ...state, [name]: html }));
    };

    const isAllFormFilled = () => {
      const keys = Object.keys(htmlStateMap);
      return !!(
        keys.length &&
        keys.length === keys.filter((key) => !!htmlStateMap[key]).length
      );
    };

    const handleOnSelectFocus = (item: any) => {
      close?.(null, {
        report_focus_id: item.id,
      });
    };

    function getTotalRequiredToFill() {
      const { ...restRequiredHtmlStateMap } = htmlStateMap;

      const keys = Object.keys(restRequiredHtmlStateMap);

      // Total number of static fields to fill up
      let staticTotal = Object.keys(keys).length;
      // total number of static fields already filled up
      let staticFilled = keys.filter((k) => {
        const value = htmlStateMap[k];
        return isNotNullAndUndefined(value) && !!value;
      }).length;

      return {
        staticFields: {
          total: staticTotal,
          filled: staticFilled,
        },
        kpiFields: {
          total: 0,
          filled: 0,
        },
      };
    }

    const renderFormStep = () => {
      type HeadTitle = {
        id: string;
        title: string;
        subtitle: string;
        tooltipId?: string;
      };
      const renderHeadTitle = ({
        id,
        title,
        subtitle,
        tooltipId,
      }: HeadTitle) => {
        return (
          <Box id={id}>
            <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
              {title}
              {tooltipId && (
                <span style={{ position: 'relative' }}>
                  <HelpOutlinedIcon
                    id={tooltipId}
                    sx={{
                      ml: 1,
                      fontSize: 22,
                      opacity: 0.5,
                      cursor: 'pointer',
                      position: 'absolute',
                      ':hover': {
                        opacity: 1,
                      },
                    }}
                    style={{
                      color: isDarkMode
                        ? 'var(--md-ref-palette-neutral80)'
                        : 'var(--md-ref-palette-neutral40)',
                    }}
                  />
                </span>
              )}
            </Typography>
            <Typography
              component='div'
              fontSize={14}
              sx={{ opacity: 0.6, mb: 1, mt: -1 }}
            >
              {subtitle}
            </Typography>
          </Box>
        );
      };

      return (
        <Box
          sx={{
            pt: 3,
            pb: 8,
            ...(fullView
              ? {
                  minHeight: `calc(100vh - 64px - 94px)`,
                }
              : {}),
          }}
        >
          {renderHeadTitle({
            id: 'sod_today_win',
            title: 'What will make today a win?',
            subtitle: `Be specific and don’t mail it in. What accomplishment on a project,
          outcome, KPI, focus, or progress towards a mysprint would have you
          saying “I did it!” at the end of your day.`,
            tooltipId: 'sod-today-win-tooltip',
          })}
          <RichEditor
            withAIAssist
            withAIEditPrompt={isPromptEngineer}
            value={htmlStateMap.today_win}
            onValueChange={onEditorValueChange('today_win')}
            placeholder={`Write here...`}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
            chatGPTPlaygroundProps={{
              title: 'What will make today a win?',
              prompt_identifier: 'completions-reports-sod-today-win',
            }}
            onAssistClick={() => {
              posthog.capture('manifest assist clicked', {
                type: 'SOD',
                title: 'What will make today a win',
              });
              aiAssist.start({
                title: 'What will make today a win?',
                prompt_identifier: 'completions-reports-sod-today-win',
                data: {
                  prompt_text: htmlStateMap.today_win,
                },
              });
            }}
          />
          <br />
          {renderHeadTitle({
            id: 'sod_area_of_focus',
            title: `Today's areas of focus or important targets?`,
            subtitle: `Not your task list. Nobody needs to know you are checking emails or reading slack. What outcomes are you working towards today, how are you doing so, and why did you choose them as the most important?`,
          })}
          <RichEditor
            withAIAssist
            withAIEditPrompt={isPromptEngineer}
            value={htmlStateMap.area_of_focus}
            onValueChange={onEditorValueChange('area_of_focus')}
            placeholder={`Write here...`}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
            chatGPTPlaygroundProps={{
              title: `Today's areas of focus or important targets?`,
              prompt_identifier: 'completions-reports-sod-area-of-focus',
            }}
            onAssistClick={() => {
              posthog.capture('manifest assist clicked', {
                type: 'SOD',
                title: 'Areas of focus or important targets',
              });
              aiAssist.start({
                title: `Today's areas of focus or important targets?`,
                prompt_identifier: 'completions-reports-sod-area-of-focus',
                data: {
                  prompt_text: htmlStateMap.area_of_focus,
                },
              });
            }}
          />
          <br />
          {renderHeadTitle({
            id: 'sod_worth_noting',
            title: `Anything else worth noting about today?`,
            subtitle: `examples : a special meeting, extended break (you already informed about!) odd ad-hoc or monthly project, training you are taking or just something you are excited about.`,
          })}
          <RichEditor
            withAIAssist
            withAIEditPrompt={isPromptEngineer}
            value={htmlStateMap.worth_noting}
            onValueChange={onEditorValueChange('worth_noting')}
            placeholder={`Write here...`}
            onFocus={isCardFocused.focus}
            onBlur={isCardFocused.blur}
            chatGPTPlaygroundProps={{
              title: `Anything else worth noting about today?`,
              prompt_identifier: 'completions-reports-sod-worth-noting',
            }}
            onAssistClick={() => {
              posthog.capture('manifest assist clicked', {
                type: 'SOD',
                title: 'Worth nothing about today',
              });
              aiAssist.start({
                title: `Anything else worth noting about today?`,
                prompt_identifier: 'completions-reports-sod-worth-noting',
                data: {
                  prompt_text: htmlStateMap.worth_noting,
                },
              });
            }}
          />
        </Box>
      );
    };

    const renderQuoteStep = () => {
      return (
        <QuoteView
          reportType='SOD'
          fullView={fullView}
          quoteToday={dailyReportQuoteToday.data}
          onSelectFocus={handleOnSelectFocus}
        />
      );
    };

    /*
    const renderSummaryStep = (htmlStateMap: HTMLStateMap) => {
      const blockSx = {
        p: 0.5,
        pr: 4,
        pl: 4,
        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : '#f5f5f5',
        '.rich-text-content': {
          pt: 1.5,
          pb: 1.5,
        },
      };
      type ItemProps = {
        title: string;
        html: string;
      };
      const renderItem = ({ title, html }: ItemProps) => {
        return (
          <>
            <Typography component='div' fontWeight={500} sx={{ mb: 1 }}>
              {title}
            </Typography>
            <Typography component='div' sx={blockSx}>
              <RichTextContent content={html} />
            </Typography>
          </>
        );
      };
      return (
        <Box
          sx={{
            pt: 3,
            pb: 8,
            ...(fullView
              ? {
                  minHeight: `calc(100vh - 64px - 94px)`,
                }
              : {}),
          }}
        >
          {renderItem({
            title: `What will make today a win?`,
            html: DOMPurify.sanitize(htmlStateMap.today_win),
          })}
          <br />
          {renderItem({
            title: `Today's areas of focus or important targets?`,
            html: DOMPurify.sanitize(htmlStateMap.area_of_focus),
          })}
          <br />
          {renderItem({
            title: `Anything else worth noting about today?`,
            html: DOMPurify.sanitize(htmlStateMap.worth_noting),
          })}
        </Box>
      );
    };
    */

    const renderPrevNextButtons = () => {
      const { staticFields, kpiFields } = getTotalRequiredToFill();

      let remaining =
        staticFields.total +
        kpiFields.total -
        (staticFields.filled + kpiFields.filled);

      return (
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ p: 2, pt: 1, pb: 1, minHeight: 56 }}
        >
          <Box
            display='flex'
            justifyContent='flex-start'
            sx={{ minWidth: '20%' }}
          >
            {isInBetweenStep('Quote')
              ? null
              : !postReport.isSuccess &&
                !isFirstStep() && (
                  <M3Button
                    color='primary'
                    variant='contained'
                    onClick={prevStep}
                    sx={{ pl: 2, pr: 2, minWidth: 100 }}
                  >
                    Previous
                  </M3Button>
                )}
          </Box>
          <Box
            flex={1}
            flexDirection='column'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Typography component='div'>
              <strong>{remaining}</strong>{' '}
              <span style={{ opacity: 0.8 }}>things left</span>
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{ minWidth: '20%' }}
          >
            {isInBetweenStep('Quote') || postReport.isSuccess ? null : (
              <M3Button
                color='primary'
                variant='contained'
                disabled={
                  isFirstStep()
                    ? isAllFormFilled()
                      ? false
                      : true
                    : postReport.isLoading || postReport.isSuccess
                }
                sx={{ pl: 2, pr: 2, minWidth: 100 }}
                onClick={() => {
                  // if it's the last step, then submit the form...
                  if (isFirstStep()) {
                    const data: SODReportParams = {
                      type: 'SOD',
                      data: {
                        ...htmlStateMap,
                        metadata: {
                          report_type_platform: parsedURL.report_type_platform,
                        },
                      },
                    };
                    posthog.capture('manifest submit clicked');
                    postReport.mutate(data);
                  }
                  // else, go show next
                  else {
                    nextStep();
                  }
                }}
              >
                {isFirstStep()
                  ? postReport.isLoading
                    ? 'Please wait...'
                    : postReport.isSuccess
                    ? 'All done'
                    : 'Submit'
                  : 'Next'}
              </M3Button>
            )}
          </Box>
        </Stack>
      );
    };

    const renderQuestionsDrawerSummary = () => {
      return (
        <Box
          ref={sideSheetContainerRef}
          sx={{
            top: 8,
            right: 0,
            zIndex: 1,
            width: 300,
            opacity: 0,
            position: 'absolute',
            // transition: 'transform 200ms',
            transform: 'translate3d(100%, 0, 0)',
          }}
        >
          <AIAssistSideSheet
            fixed={false}
            title={aiAssist.title}
            parentSelector='.modal-card-view-wrapper'
            containerSelector='.modal-card-view-body-content'
            headerSx={{
              height: 'initial',
            }}
            aiAssist={aiAssist}
          />
        </Box>
      );
    };

    const onLayoutChange = useCallback(
      (layout: IterableObject<CSSProperties>) => {
        if (!sideSheetContainerRef.current) return;

        if (layout.body) {
          const gap = 8;
          let height = +layout.body.height!;
          // NOTE: Top/Bottom gap on the side sheet
          height = height - gap * 2;
          sideSheetContainerRef.current.style.height = `${height}px`;
          sideSheetContainerRef.current.style.opacity = '1';
          sideSheetContainerRef.current.style.transform =
            'translate3d(0, 0, 0)';
        }

        setSideSheetKey((key) => ++key);
      },
      [sideSheetContainerRef, setSideSheetKey],
    );

    useEffect(() => {
      if (fullView) return;

      let timer: ReturnType<typeof setTimeout> | null = null;
      if (isInBetweenStep('Quote')) {
        timer = setTimeout(() => {
          close?.();
        }, 4000);
      }

      return () => {
        timer && clearTimeout(timer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInBetweenStep('Quote'), fullView]);

    useEffect(() => {
      if (postReport.isSuccess) {
        shiftReportingStatus.refetch();
        updateActionKeyCounter('sod_eod_submission');
        nextStep();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postReport.isSuccess]);

    const renderTopPanel = () => {
      return (
        <>
          <Typography
            component='div'
            variant='h6'
            fontWeight={500}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' alignItems='center' style={{ width: 200 }}>
              Plan your attack
            </Box>
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={{ flex: 1, width: 0 }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    disabled
                    color='inherit'
                    style={{
                      paddingLeft: label === 'Quote' ? 20 : undefined,
                    }}
                    sx={{
                      '.MuiStepIcon-root.Mui-active': {
                        color: 'var(--md-ref-palette-primary40)',
                      },
                    }}
                  >
                    {label !== 'Quote' && (
                      <Typography component='div' fontWeight={500}>
                        {label}
                      </Typography>
                    )}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <span style={{ width: 120 }}></span>
          </Typography>
        </>
      );
    };

    return (
      <ModalCardView
        fullView={fullView}
        header={renderTopPanel()}
        headerSx={{ pt: 2, pb: 2 }}
        footer={isInBetweenStep('Quote') ? null : renderPrevNextButtons()}
        close={close}
        animateOnMount={animateOnMount}
        willUpdateLayout={activeStep}
        onSetScrollElementRef={setScrollElement}
        afterChildren={isFirstStep() ? renderQuestionsDrawerSummary() : null}
        sx={{
          ...(fullView ? {} : { maxWidth: 1200 }),
        }}
        bodySx={{
          paddingRight: isDrawerOpen ? '316px' : undefined,
        }}
        onLayoutChange={onLayoutChange}
      >
        {isFirstStep() && renderFormStep()}
        {isInBetweenStep('Quote') && renderQuoteStep()}
        {/* {isLastStep() && renderSummaryStep(htmlStateMap)} */}
        <WindowScrollTop handler={scrollElement} deps={[activeStep]} />
      </ModalCardView>
    );
  },
);

export default SODForm;
