import React from 'react';
import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

import { M3Button } from '../M3/M3Button';

type InboxNextUnreadProps = {
  total: number;
  isLoading?: boolean;
  onNextUnreadClick?: () => void;
};

const InboxNextUnread = ({
  total,
  isLoading,
  onNextUnreadClick,
}: InboxNextUnreadProps) => {
  let nextButtonDisabled: boolean = true;

  if (total) {
    nextButtonDisabled = false;
  }

  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <Typography
        pr={1}
        display='flex'
        alignItems='center'
        component='div'
        fontWeight={500}
      >
        <span style={{ opacity: 0.5 }}>{total} left</span>
      </Typography>
      <Divider orientation='vertical' flexItem variant='middle' />
      <M3Button
        disabled={isLoading || nextButtonDisabled}
        onClick={onNextUnreadClick}
      >
        View next unread message
        {isLoading ? (
          <CircularProgress size={18} sx={{ mr: 0, ml: 2.2 }} />
        ) : (
          <ArrowRightAltOutlinedIcon
            sx={{ ml: 1 }}
            style={{ fontSize: 28, marginRight: 0 }}
          />
        )}
      </M3Button>
    </Stack>
  );
};

export default InboxNextUnread;
