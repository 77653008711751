import { forwardRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import PromoteDemoteUserForm from './PromoteDemoteUserForm';

import ModalCardView, { ModalCardViewCloseProps } from '../../ModalCardView';

type Props = ModalCardViewCloseProps & {};

export type SearchType = 'go2_member' | 'jobseeker';
export type SearchTypeOptions = { id: SearchType; label: string }[];

export function PromoteDemoteUserFormModalBase({ close }: Props) {
  const [searchType, setSearchType] = useState<SearchType>('go2_member');

  const searchTypes: SearchTypeOptions = [
    {
      id: 'go2_member',
      label: 'Path Beta Users',
    },
    {
      id: 'jobseeker',
      label: 'Jobseekers',
    },
  ];

  const renderTopPanel = () => {
    return (
      <Stack
        sx={{ flex: 1 }}
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <PeopleAltIcon sx={{ mt: 0.3, mr: 1 }} />
        <Typography component='div' position='relative'>
          <Box display='flex' flexWrap='nowrap'>
            <Typography
              display='flex'
              alignItems='center'
              flexWrap='nowrap'
              fontSize={20}
              fontWeight={500}
              component='div'
            >
              <span className='text-truncate' style={{ maxWidth: 420 }}>
                Promote/Demote User
              </span>
            </Typography>
          </Box>
        </Typography>
      </Stack>
    );
  };

  return (
    <ModalCardView
      header={renderTopPanel()}
      headerSx={{ pt: 2, pb: 2 }}
      bodySx={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        overflow: 'hidden',
      }}
      close={close}
    >
      <PromoteDemoteUserForm
        searchType={searchType}
        searchTypes={searchTypes}
        setSearchType={setSearchType}
      />
    </ModalCardView>
  );
}

const PromoteDemoteUserFormModalView = forwardRef((props: Props, ref) => (
  <PromoteDemoteUserFormModalBase {...props} />
));

export default PromoteDemoteUserFormModalView;
