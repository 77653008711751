import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';

import { SearchType, SearchTypeOptions } from './PromoteDemoteUserFormModal';

import { M3SelectGroup } from '../../M3/M3Select';

type TypeDropdownProps = {
  searchType: SearchType;
  searchTypes: SearchTypeOptions;
  setSearchType: Dispatch<SetStateAction<SearchType>>;
};

const TypeDropdown: React.FC<TypeDropdownProps> = ({
  searchType,
  searchTypes,
  setSearchType,
}) => {
  return (
    <Box
      sx={{
        mr: 2,
        width: 240,
        minWidth: 240,
      }}
    >
      <M3SelectGroup
        label='Type'
        labelId='pto-type-select-label'
        options={searchTypes}
        selectProps={{
          autoFocus: false,
          fullWidth: true,
          name: 'type',
          value: searchType,
          placeholder: 'Select type...',
          onChange: (evt) => {
            setSearchType(evt.target.value as SearchType);
          },
        }}
        formControlSx={{
          width: '100%',
          '.MuiInputLabel-root': {
            transition: 'none',
          },
        }}
      />
    </Box>
  );
};

export default TypeDropdown;
