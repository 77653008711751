import React from 'react';

import SettingsTeamAllJobs from './SettingsTeamAllJobs';
import SettingsTeamJobDetail from './SettingsTeamJobDetail';

import { usePathnameSegments } from '../hooks/utils/url';

type TeamJobsProps = {};

export type TeamJobsSegments = 'create';

const TeamJobs = (props: TeamJobsProps) => {
  const [, , , thirdSegment] = usePathnameSegments<TeamJobsSegments[]>();

  if (!!+thirdSegment) {
    return <SettingsTeamJobDetail routeKey='team' editable={false} />;
  }

  return <SettingsTeamAllJobs routeKey='team' editable={false} />;
};

export default TeamJobs;
