import { IEditTimeDetailsForm } from './edit-time-details-form.interface';

export const defaultTimeDetailsFormValues: IEditTimeDetailsForm = {
  timeStarted: null,
  timeEnded: null,
  totalTime: '',
  taskId: null,
  taskLabel: '',
  notes: '',
};
