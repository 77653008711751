import { PropsWithChildren, useRef, useState } from 'react';
import {
  Popover,
  Stack,
  Typography,
  Box,
  Avatar,
  Tooltip,
  Modal,
} from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { M3IconButton } from './M3/M3Button';
import UserChipStatus from './UserProfile/UserChipStatus';
import TimeOffProfileModalView from './UserProfile/TimeOffProfileModalView';
import JobDescriptionDetailModalView from './UserProfile/JobDescriptionDetailModalView';
import ScriptSvgIcon from './SvgIcons/ScriptSvgIcon';

import { UserMetadata } from '../types/profile';
import {
  getCurrentUserJobDescription,
  getUserInitials,
  getUserPhotoUrl,
} from '../utils/user';
import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';
import ViewLogsModalView from './UserProfile/ViewLogsModalView';

type UserProfilePopoverProps = PropsWithChildren & {
  user: UserMetadata;
};

type ToolbarButtonProps = {
  key?: string;
  title: string;
  icon: ReactRenderElement;
  active?: boolean;
  name: CurrentTabName;
};

type CurrentTabName = 'time_off' | 'job_description' | 'view_logs' | null;

const UserProfilePopover = ({ user, children }: UserProfilePopoverProps) => {
  const { isDarkMode } = useAppProvider();

  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTabName, setCurrentTabName] = useState<CurrentTabName>(null);

  const { job_title } = getCurrentUserJobDescription(user);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const renderTopPanel = () => {
    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        sx={{
          pt: 3,
          position: 'relative',
        }}
      >
        <Stack
          gap={2}
          pl={3}
          pr={3}
          pb={2}
          flexDirection='row'
          position='relative'
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
            }}
            src={getUserPhotoUrl(user?.photo_url)}
          >
            {getUserInitials(user?.first_name).initial}
          </Avatar>
          <Box>
            <Typography
              component='div'
              fontSize={20}
              fontWeight={500}
              minWidth={70}
            >
              {user?.first_name} {user?.last_name}
            </Typography>
            <Typography
              component='div'
              fontSize={14}
              fontWeight={500}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-tertiary80)'
                  : 'var(--md-ref-palette-tertiary30)',
              }}
            >
              {job_title ?? (
                <span style={{ opacity: 0.2 }}>No Job Description</span>
              )}
            </Typography>
            <UserChipStatus />
          </Box>
        </Stack>
      </Box>
    );
  };

  const renderToolbarButton = ({
    key,
    title,
    icon,
    active,
    name,
  }: ToolbarButtonProps) => {
    return (
      <Tooltip key={key} title={title}>
        <Box>
          <M3IconButton
            active={active}
            onClick={() => {
              setCurrentTabName(name);
              setOpen(false);
              setIsModalOpen(true);
            }}
          >
            {icon}
          </M3IconButton>
        </Box>
      </Tooltip>
    );
  };

  const renderToolbar = () => {
    return (
      <Stack
        gap={1}
        p={0.4}
        pl={1.8}
        pr={1.8}
        minWidth={308}
        direction='row'
        sx={{
          borderTop: `1px solid ${
            isDarkMode
              ? 'var(--md-ref-palette-neutral30)'
              : 'var(--md-ref-palette-neutral90)'
          }`,
          background: isDarkMode
            ? 'var(--md-ref-palette-neutral20)'
            : 'var(--md-ref-palette-neutral95)',
        }}
      >
        {renderToolbarButton({
          name: 'view_logs',
          title: 'View Logs',
          active: isModalOpen && currentTabName === 'view_logs',
          icon: <AccessTimeOutlinedIcon />,
        })}
        {renderToolbarButton({
          name: 'job_description',
          title: 'Job Description',
          active: isModalOpen && currentTabName === 'job_description',
          icon: (
            <ScriptSvgIcon
              width={22}
              fillColor={
                isDarkMode
                  ? 'var(--md-sys-color-on-surface-dark)'
                  : 'var(--md-sys-color-on-surface-light)'
              }
              style={{ position: 'relative', top: 2 }}
            />
          ),
        })}
        {renderToolbarButton({
          name: 'time_off',
          title: 'Time Off',
          active: isModalOpen && currentTabName === 'time_off',
          icon: <CalendarMonthOutlinedIcon />,
        })}
      </Stack>
    );
  };

  const renderModalContent = () => {
    if (currentTabName === 'job_description') {
      return (
        <JobDescriptionDetailModalView user={user!} close={onModalClose} />
      );
    }

    if (currentTabName === 'time_off') {
      return <TimeOffProfileModalView user={user!} close={onModalClose} />;
    }

    if (currentTabName === 'view_logs') {
      return <ViewLogsModalView user={user!} close={onModalClose} />;
    }

    return <></>;
  };

  return (
    <Box ref={ref}>
      <Tooltip title={`View Profile`}>
        <Box
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            cursor: 'pointer',
          }}
          className={open ? 'active' : ''}
        >
          {children}
        </Box>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            overflow: 'hidden',
            background: isDarkMode
              ? 'var(--md-sys-color-background-dark)'
              : 'var(--md-sys-color-background-light)',
            border: isDarkMode
              ? '1px solid var(--md-ref-palette-neutral30)'
              : 'none',
          },
        }}
      >
        <Box>
          {renderTopPanel()}
          {renderToolbar()}
        </Box>
      </Popover>
      <Modal open={isModalOpen}>{renderModalContent()}</Modal>
    </Box>
  );
};

export default UserProfilePopover;
