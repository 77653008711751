/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import AppActionToolbar from '../components/AppActionToolbar';
import MainContainer from '../components/MainContainer';
import { BreadcrumbItem } from '../components/Intercom/IntercomBreadcrumbs';
import SearchForm from '../components/Filters/SearchForm';
import PageWait from '../components/PageWait';
import { M3Button } from '../components/M3/M3Button';
import FocusAreasFocusItem, {
  FocusAreasFocusItemSkeleton,
} from '../components/TeamSettings/Focus/FocusAreasFocusItem';
import DocumentTitle from '../components/DocumentTitle';
import LoadMorePlaceholder from '../components/LoadMorePlaceholder';
import { M3OptionItem } from '../components/Popover/BasicPopoverWithSearch';
import InfiniteScrollListener from '../components/InfiniteScrollListener';
import WindowScrollTop from '../components/WindowScrollTop';

import { useInfinite } from '../hooks/global/useInfinite';
import { useWorkspaceProvider } from '../providers/workspace/workspace';
import {
  useTimeTrackerTasks,
  UseTimeTrackerTasksParams,
} from '../hooks/time-tracker';
import { TimeTrackerTaskItem } from '../types/time-tracker';
import { useDebounce } from '../hooks/utils/misc';

type SettingsTeamAllFocusProps = {
  editable?: boolean;
  routeKey: 'builder' | 'team';
};
interface ISettingsTeamAllFocusLocationState {
  deletedJobId?: string;
}

const SettingsTeamAllFocus = ({
  editable = true,
  routeKey,
}: SettingsTeamAllFocusProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { items: workspaces, current: currentWorkspace } =
    useWorkspaceProvider();
  const { enqueueSnackbar } = useSnackbar();

  const workspaceOptions: M3OptionItem[] = useMemo(() => {
    const oses = workspaces.filter((ws) => ws.type === 'division_partner');
    return [
      oses.length > 1
        ? {
            id: 'all',
            label: 'All',
          }
        : null,
      ...oses.map((ws) => ({
        id: ws.id as string,
        label: ws.name,
      })),
    ].filter((m) => !!m) as M3OptionItem[];
  }, [workspaces]);

  const searchDebounce = useDebounce(search);
  const infinite = useInfinite<TimeTrackerTaskItem, UseTimeTrackerTasksParams>({
    useQuery: useTimeTrackerTasks,
    queryParams: {
      limit: 30,
      name: searchDebounce,
      divisions:
        currentWorkspace?.id === 'my-squad'
          ? workspaceOptions
              .filter((ws) => ws.id !== 'all')
              .map((ws) => ws.id)
              .join(',')
          : currentWorkspace?.id,
      // unassigned: divisionId === 'no_division' ? true : undefined,
    },
  });

  const getRoutes = () => {
    let breadCrumbs: BreadcrumbItem[] = [];
    let basePath = '';
    let trailingTitle = '';

    if (routeKey === 'builder') {
      trailingTitle = 'Builder';
      basePath = `/builder/focus`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Focus',
          path: basePath,
        },
      ];
    } else if (routeKey === 'team') {
      trailingTitle = 'Team';
      basePath = `/team/focus/${currentWorkspace?.id}`;
      breadCrumbs = [
        {
          id: 'all',
          label: 'All Focus',
          path: basePath,
        },
      ];
    }

    return {
      breadCrumbs,
      basePath,
      trailingTitle,
    };
  };

  const onCreateFocus = () => {
    navigate('/builder/focus/create');
  };

  useEffect(() => {
    infinite.reset({
      emptyList: true,
    });
  }, [searchDebounce, currentWorkspace]);

  useEffect(() => {
    if ((location.state as ISettingsTeamAllFocusLocationState)?.deletedJobId) {
      enqueueSnackbar('Focus successfully deleted.');
    }
  }, [location]);

  return (
    <>
      <DocumentTitle title='Focus' trailingTitle={getRoutes().trailingTitle} />
      <MainContainer sx={{ maxWidth: null }}>
        <AppActionToolbar>
          <SearchForm
            placeholder={`Search focus${
              currentWorkspace?.id === 'my-squad'
                ? ''
                : ` in ${currentWorkspace?.name}`
            }`}
            onStateUpdated={(state) => {
              setSearch(state.search);
            }}
          />
          <Box
            ml={3}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            {editable && (
              <M3Button
                color='primary'
                variant='contained'
                onClick={onCreateFocus}
              >
                <AddOutlinedIcon />
                Create a Focus
              </M3Button>
            )}
          </Box>
        </AppActionToolbar>
        {!infinite.data.length &&
          infinite.isLoading &&
          new Array(3)
            .fill(null)
            .map((_, i) => <FocusAreasFocusItemSkeleton key={i} />)}
        {!infinite.data.length && !infinite.isLoading && !!search && (
          <PageWait
            title={`No focus found`}
            message={
              <>
                We could not find any focus:{' '}
                <strong style={{ fontWeight: 500 }}>{search}</strong>.
              </>
            }
          />
        )}
        {infinite.data.map((item) => {
          return (
            <Box key={item.id} position='relative'>
              <FocusAreasFocusItem
                item={item}
                path={`${getRoutes().basePath}/${item.id}`}
              />
            </Box>
          );
        })}
        <LoadMorePlaceholder
          hasReachEnd={infinite.hasReachEnd}
          isLoading={infinite.isLoadingMore}
        />
      </MainContainer>
      <InfiniteScrollListener onReachBottom={() => infinite.loadNext()} />
      <WindowScrollTop deps={[searchDebounce]} />
    </>
  );
};

export default SettingsTeamAllFocus;
