import { Box, Divider, SxProps } from '@mui/material';
import { forwardRef, PropsWithChildren, useRef, CSSProperties } from 'react';
import { ReactRenderElement } from '../types/types';

type Props = {
  children?: ReactRenderElement;
  header?: ReactRenderElement;
  footer?: ReactRenderElement;
  willUpdateLayout?: boolean;
  style?: CSSProperties;
  sx?: SxProps;
  headerSx?: SxProps;
  withHeaderDivider?: boolean;
  bodySx?: SxProps;
  footerSx?: SxProps;
};

const Header = forwardRef(
  (
    {
      children,
      sx,
      withDivider = true,
    }: PropsWithChildren & { sx?: SxProps; withDivider?: boolean },
    ref,
  ) => {
    return (
      <Box ref={ref}>
        <Box sx={sx}>{children}</Box>
        {withDivider && <Divider sx={{ borderWidth: 1 }} />}
      </Box>
    );
  },
);

const Footer = forwardRef(
  ({ children, sx }: PropsWithChildren & { sx?: SxProps }, ref) => {
    return (
      <Box ref={ref} sx={sx}>
        <Divider />
        {children}
      </Box>
    );
  },
);

const BasicCard = ({
  header,
  children,
  footer,
  style,
  sx,
  headerSx,
  withHeaderDivider,
  bodySx,
  footerSx,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>();
  const headerRef = useRef<HTMLDivElement | null>();
  const bodyRef = useRef<HTMLDivElement | null>();
  const footerRef = useRef<HTMLDivElement | null>();

  return (
    <Box sx={sx} style={style} ref={wrapperRef}>
      {!!header && (
        <Header ref={headerRef} sx={headerSx} withDivider={withHeaderDivider}>
          {header}
        </Header>
      )}
      <Box ref={bodyRef} sx={bodySx}>
        {children}
      </Box>
      {!!footer && (
        <Footer ref={footerRef} sx={footerSx}>
          {footer}
        </Footer>
      )}
    </Box>
  );
};

export default BasicCard;
