import { AxiosRequestConfig } from 'axios';
import { useMutationApi } from './global/useApi';
import { EODFieldParams } from '../types/report';

export type FocusEODFormPayload = {
  fields?: EODFieldParams[];
  tasks?: (string | number)[];
  emoji?: {
    code: string;
    url: string;
  };
  name: string;
  overview?: string;
  desired_outcome?: string;
  success_measurement?: string;
};

type UsePatchEODFormsParams = {
  id?: string | number;
};
export function usePatchEODForms(
  params: UsePatchEODFormsParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<Partial<FocusEODFormPayload>, any>(
    `/forms/eod-forms/${params.id}/`,
    {},
    { ...axiosConfig, method: 'PATCH' },
  );
}
