import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Moment } from 'moment';
import React from 'react';

import BasicDatePicker from '../../Pickers/BasicDatePicker';
import { useForm } from '../../BasicForm';
import { M3MenuItem } from '../../M3/M3MenuItem';
import RichEditor from '../../RichEditor/RichEditor';

import { EODFieldParams } from '../../../types/report';
import { IterableObject } from '../../../types/types';

type Props = {
  item: EODFieldParams;
  onDataChange?: (data: IterableObject, passed: boolean, total: number) => void;
};

const dateFieldSx: SxProps = {
  width: '100%',
  maxWidth: 420,
};

const autoFormOffProps: { [key: string]: 'off' | undefined } = {
  autoSave: 'off',
  autoCorrect: 'off',
  autoComplete: 'off',
  autoCapitalize: 'off',
};

type FormQuestionFieldTypeProps = Props & {};
const FormQuestionFieldType = ({
  onDataChange,
  item,
}: FormQuestionFieldTypeProps) => {
  const { formState, handleChange } = useForm<IterableObject>({});

  const renderFormElement = (item: EODFieldParams) => {
    const inputTextProps: TextFieldProps = {
      type:
        item.type === 'number'
          ? 'number'
          : item.type === 'input'
          ? 'text'
          : undefined,
      placeholder: item.caption || undefined,
    };

    if (item.type === 'select') {
      return (
        <FormControl fullWidth>
          <Select
            fullWidth
            {...autoFormOffProps}
            name={item.id}
            value={formState[item.id]}
            onChange={handleChange}
          >
            {item.select_options?.map((value, index) => (
              <M3MenuItem key={index} value={value}>
                {value}
              </M3MenuItem>
            ))}
          </Select>
          {!!item.caption && !formState[item.id] && (
            <FormHelperText
              sx={{
                top: 12,
                left: 0,
                bottom: 0,
                fontSize: 16,
                opacity: 0.58,
                position: 'absolute',
                pointerEvents: 'none',
              }}
            >
              {item.caption}
            </FormHelperText>
          )}
        </FormControl>
      );
    }

    if (item.type === 'datepicker') {
      return (
        <Box sx={dateFieldSx}>
          <BasicDatePicker
            format='YYYY-MM-DD'
            defaultValue={formState[item.id]}
            onChange={(value: Moment | null) => {
              handleChange({
                target: {
                  name: item.id,
                  value: value ? value.format('YYYY-MM-DD') : null,
                },
              });
            }}
            textFieldProps={{
              fullWidth: true,
              sx: {
                maxWidth: '100%',
              },
            }}
          />
        </Box>
      );
    }

    if (item.type === 'input' || item.type === 'number') {
      return (
        <TextField
          fullWidth
          {...autoFormOffProps}
          {...inputTextProps}
          name={item.id}
          value={formState[item.id]}
          onChange={handleChange}
        />
      );
    }

    // default to textarea/richtext
    return (
      <RichEditor
        withAIAssist={false}
        withAIEditPrompt={false}
        value={formState[item.id]}
        placeholder={item.caption || 'Write here...'}
        onValueChange={(html: string) => {
          handleChange({
            target: {
              name: item.id,
              value: html,
            },
          });
        }}
      />
    );
  };

  const renderFormElementError = (message: string) => {
    return (
      <Typography
        component='div'
        fontSize={12}
        sx={{
          mt: 1,
          color: 'red',
        }}
        className='model-view-help-text'
      >
        {message}
      </Typography>
    );
  };

  const renderField = (item: EODFieldParams) => {
    let errorMsg = false;

    return (
      <Stack id={`eod_${item.id}`} key={item.id} sx={{ mb: 1 }}>
        <Typography
          component='div'
          display='flex'
          alignItems='center'
          justifyContent='space-evenly'
          fontWeight={500}
          className='model-view-typography'
        >
          <span
            style={{
              flex: 1,
              color: errorMsg ? 'red' : undefined,
            }}
          >
            {item.label}
          </span>
          <br />
          {item.required && (
            <Typography
              component='div'
              textTransform='uppercase'
              sx={{
                fontSize: 9,
                opacity: 0.5,
                fontWeight: 'bold',
              }}
              className='model-view-typography'
            >
              (Required)
            </Typography>
          )}
        </Typography>
        <Box
          sx={{
            pt: 1,
            pb: 1.4,
            flex: 1,
          }}
        >
          {renderFormElement(item)}
          {errorMsg
            ? renderFormElementError(errorMsg)
            : item.help_text && (
                <Typography
                  fontSize={12}
                  sx={{
                    mt: 1,
                    opacity: 0.5,
                  }}
                  className='model-view-help-text'
                >
                  {item.help_text}
                </Typography>
              )}
        </Box>
      </Stack>
    );
  };

  return renderField(item);
};

export default FormQuestionFieldType;
