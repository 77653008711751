import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import BasicIframe from './BasicIframe';

import { useCurrentProfile } from '../hooks/profile';
import { useAppProvider } from '../providers/app/app';
import { parseURL } from '../utils/url';

type Props = {};

const StagePipelineBanner = (props: Props) => {
  const { toolbarHeight, topBannerHeight, isDarkMode, mediaQuery } =
    useAppProvider();
  const { search } = useLocation();
  const { data: currentProfile } = useCurrentProfile();
  const [{ email }] = useState(() => parseURL(search));
  /**
   * The mobile version flag is for the pizza tracker which they break their
   * media query to max-width to 600px
   */
  const isMobileVersion = mediaQuery.mobile && mediaQuery.size === 'phone';
  const mobileVersionHeight = 86;

  return (
    <>
      <Box
        sx={{
          top: isMobileVersion ? -28 : 0,
          left: 0,
          right: 0,
          height: isMobileVersion ? mobileVersionHeight + 6 : toolbarHeight,
          position: 'fixed',
          overflow: 'hidden',
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-on-primary-light)',
          zIndex: (theme) => theme.zIndex.drawer + 5,
          '&:before': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            background: '#fff',
            position: 'absolute',
            pointerEvents: 'none',
          },
        }}
        className='stage-pipeline-banner'
      >
        <Box
          sx={{
            top: -4,
            position: 'relative',
            height: isMobileVersion
              ? mobileVersionHeight + 20
              : topBannerHeight,
          }}
        >
          <BasicIframe
            src={`https://go2io.retool.com/embedded/public/00afc09d-c302-4cf6-ae29-42e8f3b2fb2e#candidate=${
              email || currentProfile!.email
            }&bg=${
              isDarkMode
                ? 'var(--md-sys-color-background-dark)'
                : 'var(--md-sys-color-on-primary-light)'
            }`}
            style={{
              transform: isMobileVersion ? `scale(0.8)` : undefined,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ height: toolbarHeight }} />
    </>
  );
};

export default StagePipelineBanner;
