import { Box, Popover, SxProps } from '@mui/material';
import React, {
  CSSProperties,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import IntercomSvgIcon from '../SvgIcons/IntercomSvgIcon';
import { M3IconButton } from '../M3/M3Button';
import { M3MenuItem } from '../M3/M3MenuItem';

import { IntercomArticle } from '../../types/intercom';
import { getArticleEditLink } from '../../utils/article';
import { useAppProvider } from '../../providers/app/app';

type ContentArticleMenuOptionsProps = {
  article: IntercomArticle;
  sx?: SxProps;
  paperStyle?: CSSProperties;
  popover?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const ContentArticleMenuOptions = ({
  article,
  sx,
  paperStyle,
  popover = true,
  onOpenChange,
}: ContentArticleMenuOptionsProps) => {
  const { isDarkMode } = useAppProvider();
  const moreOptionRef = useRef(null);
  const [isMoreOptionOpen, setIsMoreOptionOpen] = useState(false);

  useEffect(() => {
    onOpenChange?.(isMoreOptionOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMoreOptionOpen]);

  if (!article) return null;

  if (!popover) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}
      >
        <M3MenuItem
          sx={{ borderRadius: 5 }}
          onClick={(evt: SyntheticEvent) => {
            evt.stopPropagation();
            setIsMoreOptionOpen(false);
            window.open(article.url, '_blank');
          }}
        >
          <OpenInNewOutlinedIcon
            sx={{
              fontSize: 20,
              mr: 1,
              ml: -0.5,
            }}
          />
          Visit this article
        </M3MenuItem>
        <M3MenuItem
          sx={{ borderRadius: 5 }}
          onClick={(evt: SyntheticEvent) => {
            evt.stopPropagation();
            setIsMoreOptionOpen(false);
            window.open(getArticleEditLink(article.id), '_blank');
          }}
        >
          <IntercomSvgIcon
            height={16}
            width={16}
            fillColor={isDarkMode ? '#fff' : '#000'}
            wrapperStyle={{
              marginTop: -2,
              marginRight: 10,
              marginLeft: -2,
            }}
          />
          Edit in Intercom
        </M3MenuItem>
      </Box>
    );
  }

  return (
    <Box
      ref={moreOptionRef}
      sx={{
        ml: 1,
        mr: -1.4,
        position: 'relative',
        ...sx,
      }}
    >
      <M3IconButton
        onClick={(evt) => {
          evt.stopPropagation();
          setIsMoreOptionOpen(true);
        }}
      >
        <MoreVertOutlinedIcon />
      </M3IconButton>
      <Popover
        open={isMoreOptionOpen}
        anchorEl={moreOptionRef.current}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => setIsMoreOptionOpen(false)}
        PaperProps={{
          style: {
            minWidth: 200,
            transform: `translate3d(-36px, 0, 0)`,
            ...paperStyle,
          },
        }}
      >
        <M3MenuItem
          onClick={(evt: SyntheticEvent) => {
            evt.stopPropagation();
            setIsMoreOptionOpen(false);
            window.open(article.url, '_blank');
          }}
        >
          <OpenInNewOutlinedIcon
            sx={{
              fontSize: 20,
              mr: 1,
              ml: -0.5,
            }}
          />
          Visit this article
        </M3MenuItem>
        <M3MenuItem
          onClick={(evt: SyntheticEvent) => {
            evt.stopPropagation();
            setIsMoreOptionOpen(false);
            window.open(getArticleEditLink(article.id), '_blank');
          }}
        >
          <IntercomSvgIcon
            height={16}
            width={16}
            fillColor={isDarkMode ? '#fff' : '#000'}
            wrapperStyle={{
              marginTop: -2,
              marginRight: 10,
              marginLeft: -2,
            }}
          />
          Edit in Intercom
        </M3MenuItem>
      </Popover>
    </Box>
  );
};

export default ContentArticleMenuOptions;
