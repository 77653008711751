import React from 'react';
import clsx from 'clsx';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

import { M3IconButton } from '../../M3/M3Button';
import { SxProps } from '@mui/material';

type ScrollArrowProps = {
  direction: 'left' | 'right';
  isLgScreen: boolean;
  onClick: () => void;
  sx?: SxProps;
};

const ScrollArrow: React.FC<ScrollArrowProps> = ({
  direction,
  isLgScreen,
  onClick,
  sx,
}) => {
  if (isLgScreen) return null;

  return (
    <M3IconButton
      sx={{
        marginTop: 4,
        pointerEvents: 'auto',
        ...sx,
      }}
      active
      onClick={onClick}
      className={clsx({
        [`schedule-days-arrow-${direction}`]: true,
      })}
    >
      {direction === 'left' ? (
        <KeyboardArrowLeftOutlinedIcon />
      ) : (
        <KeyboardArrowLeftOutlinedIcon
          style={{
            transform: 'rotateZ(180deg)',
          }}
        />
      )}
    </M3IconButton>
  );
};

export default ScrollArrow;
