import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import AppNavigationBar from '../components/UserNumbers/AppNavigationBar';

import { useDomoEmbedStaticChart } from '../hooks/services/domo';
import { useAppProvider } from '../providers/app/app';
import { parseURL } from '../utils/url';

type Props = {
  embedId?: string;
  embedType?: string;
  fullPage?: boolean;
};

const DomoEmbed = ({
  embedId: propsEmbedId,
  embedType: propsEmbedType,
  fullPage = true,
}: Props) => {
  const { embedId } = useParams();
  const { search } = useLocation();
  const { toolbarHeight } = useAppProvider();
  const { embed_type } = parseURL(search);

  let params = {
    embed_id: propsEmbedId ?? embedId!,
    embed_type: propsEmbedType ?? embed_type ?? 'stories',
  };

  const { data: html } = useDomoEmbedStaticChart(params);

  const renderIframe = (html: string) => {
    return (
      <Box
        sx={{
          zIndex: 1,
          overflow: 'hidden',
          background: '#fff',
          position: 'relative',
          height: fullPage ? `calc(100vh - ${toolbarHeight}px)` : '100%',
        }}
      >
        {!!html && (
          <iframe
            title={params.embed_id}
            srcDoc={html}
            style={{
              border: 0,
              margin: 0,
              padding: '0 20px 0',
              width: '100%',
              height: '100%',
              background: 'transparent',
            }}
          ></iframe>
        )}
      </Box>
    );
  };

  return (
    <>
      {fullPage && <AppNavigationBar />}
      {renderIframe(html)}
    </>
  );
};

export default DomoEmbed;
