import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { ReactRenderElement } from '../../../../types/types';
import { M3Card } from '../../../M3/M3Card';
import M3SurfaceContainer from '../../../M3/M3SurfaceContainer';
import AbsoluteCenterBox from '../../../AbsoluteCenterBox';

interface TimeInfoCardProps {
  timeInfo: ReactRenderElement;
  title: ReactRenderElement;
  icon: ReactRenderElement;
  popupVersion?: boolean;
}

const TimeInfoCard: FC<TimeInfoCardProps> = ({
  timeInfo,
  title,
  icon,
  popupVersion = false,
}) => {
  return (
    <Box
      display='flex'
      sx={{ flex: 1, width: 0, minWidth: popupVersion ? 200 : 240 }}
    >
      <M3Card noHover sx={{ flex: 1 }}>
        <M3SurfaceContainer
          elevation={1}
          wrapperSx={{ height: '100%' }}
          sx={{
            p: popupVersion ? 2 : 3,
            pb: 0,
            pt: 2,
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <AbsoluteCenterBox
            sx={{
              left: 0,
              top: undefined,
              right: undefined,
              pointerEvents: 'none',
              transform: 'translate3d(-22%, 20%, 0',
            }}
          >
            {icon}
          </AbsoluteCenterBox>
          <Typography
            component='div'
            fontSize={popupVersion ? 18 : 22}
            lineHeight={1.2}
            fontWeight={500}
            sx={{ height: 55, whiteSpace: 'nowrap' }}
          >
            {title}
          </Typography>
          <Box
            display='flex'
            justifyContent='space-between'
            pb={popupVersion ? 1.5 : 2}
          >
            <Box>
              <Typography component='div' fontSize={22} fontWeight={500}>
                {timeInfo}
              </Typography>
            </Box>
          </Box>
        </M3SurfaceContainer>
      </M3Card>
    </Box>
  );
};

export default TimeInfoCard;
