import React from 'react';

import PageWait from '../components/PageWait';
import MainContainer from '../components/MainContainer';

type SystemAdministratorCustomProps = {};

const SystemAdministratorCustom = (props: SystemAdministratorCustomProps) => {
  return (
    <MainContainer>
      <PageWait
        title='Custom'
        message="We're still working on this page! Please check back soon for updates, and thank you for your patience."
      />
    </MainContainer>
  );
};

export default SystemAdministratorCustom;
