import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

import { M3Card } from '../M3/M3Card';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';

type Props = {};

const InboxScheduleChangRequestItemDetailSkeleton = (props: Props) => {
  return (
    <>
      <M3Card sx={{ pointerEvents: 'none' }} className='no-hover'>
        <M3SurfaceContainer elevation={1} sx={{ p: 3 }}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              m: -3,
              mb: 4,
              p: 3,
              pr: 2,
              height: 69,
              background: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <Skeleton variant='text' sx={{ fontSize: 26, width: 300 }} />
            <Stack direction='row' alignItems='flex-start' gap={2}>
              <Skeleton
                variant='rectangular'
                sx={{
                  width: 100,
                  height: 40,
                  borderRadius: 20,
                }}
              />
              <Skeleton
                variant='rectangular'
                sx={{
                  width: 100,
                  height: 40,
                  borderRadius: 20,
                }}
              />
            </Stack>
          </Box>
          <Box>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='flex-start'
              sx={{ mb: 2, pl: 1, pt: 0.1 }}
            >
              <Stack
                flex={1}
                gap={2}
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <Skeleton variant='circular' width={60} height={60} />
                <Box>
                  <Skeleton variant='text' sx={{ fontSize: 22, width: 300 }} />
                  <Stack flex={1} gap={2} direction='row'>
                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{ fontSize: 12, width: 100 }}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
              <Box>
                <Skeleton
                  variant='text'
                  sx={{ fontSize: 12, width: 155, ml: 1 }}
                />
                <Box
                  display='flex'
                  alignItems='center'
                  sx={{
                    borderRadius: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Box>
                    <Skeleton
                      variant='text'
                      sx={{ fontSize: 12, width: 50, ml: 1, mr: 1 }}
                    />
                    <Skeleton
                      variant='text'
                      sx={{ fontSize: 12, width: 100, ml: 1, mr: 1 }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      height: 50,
                      width: 55,
                    }}
                  >
                    <Skeleton variant='circular' width={40} height={40} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ opacity: 0.5, mt: 5 }}>
              <Skeleton
                variant='rectangular'
                sx={{
                  height: 300,
                }}
              />
            </Box>
          </Box>
        </M3SurfaceContainer>
        {/* <Skeleton variant='text' sx={{ fontSize: 16, mt: 1 }} />
        <Skeleton variant='text' sx={{ fontSize: 16, mt: 1 }} />
        <Skeleton variant='text' sx={{ fontSize: 16, mt: 1 }} /> */}
      </M3Card>
    </>
  );
};

export default InboxScheduleChangRequestItemDetailSkeleton;
