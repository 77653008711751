import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { JobDescriptionItemResult } from '../types/job-description';
import { BasicQuery, ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';

export type UsePostJobDescriptionParams = {
  title: string;
  division?: number | string;
  position_summary?: string;
  principal_duties?: string;
  other_responsibilities?: string;
  success_indicator?: string;
};

type PostJobDescriptionResult = JobDescriptionItemResult & {
  division: number | null;
  department: number | null;
  reports_to: number | null;
};
export function usePostJobDescription(
  data: any = {},
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UsePostJobDescriptionParams, PostJobDescriptionResult>(
    `/hr/job-descriptions/`,
    data,
    axiosConfig,
  );
}

type UsePatchJobDescriptionParams = {
  id: string | number;
};
export function usePatchJobDescription(
  data: UsePatchJobDescriptionParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<
    Partial<JobDescriptionItemResult>,
    PostJobDescriptionResult
  >(
    `/hr/job-descriptions/${data.id}/`,
    {},
    { ...axiosConfig, method: 'PATCH' },
  );
}

export type UseJobDescriptionsProps = ListQuery & {
  title?: string;
  divisions?: number | string | null;
};
export function useJobDescriptions<R = JobDescriptionItemResult>(
  params: UseJobDescriptionsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseJobDescriptionsProps>({
    params,
    baseIdentifier: 'useJobDescriptions',
    identifierKeys: ['title', 'divisions'],
    queryParamKeys: ['title', 'divisions'],
  });

  return useQueryApi<UseJobDescriptionsProps, ListResult<R>>(
    identifiers,
    `/hr/job-descriptions/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseJobDescriptionByIdParams = BasicQuery & {
  id: number | string;
};
export function useJobDescriptionById<R = JobDescriptionItemResult>(
  params: UseJobDescriptionByIdParams,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseJobDescriptionByIdParams>({
      params,
      baseIdentifier: 'useJobDescriptionById',
      identifierKeys: ['id'],
    });

  return useQueryApi<UseJobDescriptionByIdParams, R>(
    identifiers,
    `/hr/job-descriptions/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

type UseJobDescriptionAddMemberProps = {
  id?: string | number | null;
  segment: 'add-members' | 'remove-members';
};
export function useJobDescriptionAddRemoveMembers(
  params: UseJobDescriptionAddMemberProps,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<
    {
      staff_ids?: (string | number | null)[];
    },
    any
  >(`/hr/job-descriptions/${params.id}/${params.segment}/`, {}, axiosConfig);
}

type UseDeleteJobDescriptionParams = {
  id?: string | number;
};
export function useDeleteJobDescription(
  params: UseDeleteJobDescriptionParams,
  axiosConfig?: Partial<AxiosRequestConfig>,
) {
  return useMutationApi<UseDeleteJobDescriptionParams, any>(
    `/hr/job-descriptions/${params.id}`,
    {},
    { ...axiosConfig, method: 'DELETE' },
  );
}
