import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as portal from '../../services/portal';
import { EmojiItem } from '../../types/emojis';
import { defaultReactQueryParams } from '../../utils/request';
import { QueryParams } from '../global/useApi';
import { useIdentifier } from '../global/useIdentifier';

type UseEmojisProps = {
  search?: string;
};
export function useEmojis<R = EmojiItem[]>(
  props: UseEmojisProps = {},
  reactQueryParams?: Partial<QueryParams>,
) {
  const { identifiers, queryParams } = useIdentifier<UseEmojisProps>({
    params: props,
    baseIdentifier: 'useEmojis',
    identifierKeys: ['search'],
    queryParamKeys: ['search'],
  });

  async function api({
    signal,
  }: QueryFunctionContext): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await portal.request({
          url: '/emojis',
          signal,
          headers: {
            'Content-Type': 'application/json',
          },
          params: queryParams,
        });
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  return useQuery(identifiers, api, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}
