import { createContext, useCallback, useContext, useState } from 'react';
import moment, { Moment } from 'moment';

import { useAuthProvider } from '../auth/auth';
import { useRefAssign } from '../../hooks/global/useRefAlwaysUpdated';
import { UserProfile } from '../../types/profile';
import { IterableObject, ReactRenderElement } from '../../types/types';
import { TimeTrackerDailySummaryItem } from '../../types/worklogs';
import { request as cosmosService } from '../../services/cosmos';
import { getConfigWithAuthorization } from '../../services/base';
import { getWeekRange } from '../../utils/date';
import { toURL } from '../../utils/url';

type TimeTrackerProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type TimeTrackerInitialState = {
  shiftDate: Moment;
  pushSyncCounter: number;
  timeTrackerDailySummary: IterableObject<IterableObject<number>>;
  activeTimeTrackerTaskWorklog: {
    day: string;
    task_id: number;
    total: number;
  } | null;
  workedLogDailySummary: TimeTrackerDailySummaryItem[];
  isPushing: boolean;
  error: Error | null;
};
/**
 * Reducers that mutate the state
 */
type TimeTrackerReducers = {
  pushToCosmos: (payload: any, user?: UserProfile) => void;
  syncFromCosmos: (init?: boolean, user?: UserProfile) => void;
  setActiveTimeTrackerTaskWorklog: (
    data: TimeTrackerInitialState['activeTimeTrackerTaskWorklog'],
  ) => void;
  fetchCurrentWorkedLogDailySummary: (
    save?: boolean,
    date?: Moment | string,
  ) => Promise<void | TimeTrackerDailySummaryItem[]>;
  fetchMorePreviousWorkedLogDailySummary: (
    save?: boolean,
    date?: Moment | string,
    weekReduction?: number,
  ) => Promise<void | TimeTrackerDailySummaryItem[]>;
};
/**
 * Single store
 */
type TimeTrackerStore = TimeTrackerInitialState & TimeTrackerReducers;
/**
 * Initial state / store
 */
const initialStore: TimeTrackerStore = {
  shiftDate: moment().startOf('day'),
  pushSyncCounter: 0,
  timeTrackerDailySummary: {},
  activeTimeTrackerTaskWorklog: null,
  workedLogDailySummary: [],
  isPushing: false,
  error: null,
  pushToCosmos: (payload: any, user?: UserProfile) => {
    throw new Error('Implementation required');
  },
  syncFromCosmos: (init?: boolean, user?: UserProfile) => {
    throw new Error('Implementation required');
  },
  setActiveTimeTrackerTaskWorklog: (
    data: TimeTrackerInitialState['activeTimeTrackerTaskWorklog'],
  ) => {
    throw new Error('Implementation required');
  },
  fetchCurrentWorkedLogDailySummary: (
    save?: boolean,
    date?: Moment | string,
  ) => {
    throw new Error('Implementation required');
  },
  fetchMorePreviousWorkedLogDailySummary: (
    save?: boolean,
    date?: Moment | string,
    weekReduction?: number,
  ) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const TimeTrackerContext = createContext<TimeTrackerStore>(initialStore);

export function useTimeTrackerProvider(): TimeTrackerStore {
  return useContext(TimeTrackerContext);
}

export function TimeTrackerProvider({ children }: TimeTrackerProviderProps) {
  const [state, setState] = useState<TimeTrackerStore>(initialStore);
  const { getTokenSilently } = useAuthProvider();

  const setActiveTimeTrackerTaskWorklog = useCallback(
    (data: TimeTrackerInitialState['activeTimeTrackerTaskWorklog']) => {
      setState((state) => ({
        ...state,
        activeTimeTrackerTaskWorklog: data,
      }));
    },
    [setState],
  );

  const shiftDateRef = useRefAssign(state.shiftDate);
  const fetchCurrentWorkedLogDailySummary = useCallback(
    async (save?: boolean, date?: Moment | string) => {
      try {
        const { start, end } = getWeekRange(date || shiftDateRef.current);
        const workedLogDailySummary: TimeTrackerDailySummaryItem[] =
          await cosmosService.get(
            toURL('/api/time-tracker/worklogs/daily-summary/', {
              start_date: start.utc().format(),
              end_date: end.utc().format(),
              timezone: moment.tz.guess(true),
              exclude_active: true,
            }),
            getConfigWithAuthorization(await getTokenSilently()),
          );

        if (save) {
          setState((state) => ({ ...state, workedLogDailySummary }));
        }

        return workedLogDailySummary;
      } catch (e) {
        throw e;
      }
    },
    [shiftDateRef, setState, getTokenSilently],
  );

  const fetchMorePreviousWorkedLogDailySummary = useCallback(
    async (save?: boolean, date?: Moment | string, weekReduction?: number) => {
      try {
        const { start, end } = getWeekRange(
          moment(date || shiftDateRef.current)
            .subtract(weekReduction, 'week')
            .endOf('week')
            .add(1, 'day'),
        );
        const workedLogDailySummary: TimeTrackerDailySummaryItem[] =
          await cosmosService.get(
            toURL('/api/time-tracker/worklogs/daily-summary/', {
              start_date: start.utc().format(),
              end_date: end.utc().format(),
              timezone: moment.tz.guess(true),
              exclude_active: true,
            }),
            getConfigWithAuthorization(await getTokenSilently()),
          );

        if (save) {
          setState((state) => ({
            ...state,
            workedLogDailySummary: mergePreviousLogs(
              state.workedLogDailySummary,
              workedLogDailySummary,
            ),
          }));
        }

        return workedLogDailySummary;
      } catch (e) {
        throw e;
      }
    },
    [shiftDateRef, setState, getTokenSilently],
  );

  /**
   * Define all side effects here...
   */
  return (
    <TimeTrackerContext.Provider
      value={{
        ...state,
        setActiveTimeTrackerTaskWorklog,
        fetchCurrentWorkedLogDailySummary,
        fetchMorePreviousWorkedLogDailySummary,
      }}
    >
      {children}
    </TimeTrackerContext.Provider>
  );
}

function mergePreviousLogs(
  currentWorkedLogs: TimeTrackerDailySummaryItem[] = [],
  previousWorkedLogs: TimeTrackerDailySummaryItem[] = [],
) {
  const newWorkedLogs = [...currentWorkedLogs];

  previousWorkedLogs.reverse().forEach((item) => {
    let key = `${item.day}_${item.task}_${item.task_name}`;
    let index = newWorkedLogs.findIndex(
      (item) => key === `${item.day}_${item.task}_${item.task_name}`,
    );

    if (index > -1) {
      newWorkedLogs[index] = item;
    } else {
      newWorkedLogs.unshift(item);
    }
  });

  return newWorkedLogs;
}
