import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as auth from '../../services/auth';
import { cleanURL } from '../../utils/url';
import { parseError } from '../global/useApi';

export function useSignup<D = any, R = any>() {
  async function api(data: D): Promise<AxiosResponse<R>> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: AxiosResponse<R> = await auth.request({
          url: cleanURL('api/users/signup/'),
          method: 'POST',
          data,
        });
        resolve(result);
      } catch (e) {
        reject(parseError(e));
      }
    });
  }

  return useMutation(api);
}
