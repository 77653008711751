import React, { SyntheticEvent } from 'react';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { WorkspaceItem } from '../providers/workspace';
import { getUserInitials } from '../utils/user';
import { useAppProvider } from '../providers/app/app';
import { M3MenuItem } from './M3/M3MenuItem';

type Props = {
  item: WorkspaceItem;
  active?: boolean;
  showDetail?: boolean;
  onClick?: (evt: SyntheticEvent) => void;
  backgroundColor?: string | undefined;
};

const WorkspaceMenuAvatarItem = ({
  item,
  active,
  backgroundColor,
  onClick,
  showDetail,
}: Props) => {
  const { isDarkMode } = useAppProvider();
  const workspaceName = item.name.replace(/^-+/g, '');
  let content = null;

  if (showDetail) {
    content = (
      <M3MenuItem
        active={active}
        onClick={onClick}
        sx={{
          flex: 1,
          justifyContent: 'flex-start',
        }}
      >
        <Avatar
          sx={{
            mr: 2,
            width: 40,
            height: 40,
            fontSize: 18,
            cursor: 'pointer',
            fontFamily: 'Archivo Black',
            whiteSpace: 'nowrap',
            color: isDarkMode
              ? 'var(--md-sys-color-background-dark) !important'
              : 'var(--md-ref-palette-primary100)',
            background: isDarkMode
              ? 'var(--md-ref-palette-primary80) !important'
              : 'var(--md-ref-palette-primary40)',
            '&:hover': {
              opacity: 1,
            },
            ...(active
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0.5,
                }),
          }}
          style={{ backgroundColor }}
        >
          {
            getUserInitials(
              workspaceName,
              workspaceName.charAt(0) === '-' ? 2 : 1,
            ).initial
          }
        </Avatar>
        <Box flex={1} width={0} pr={2}>
          <Tooltip title={item.name} placement='right'>
            <Typography component='div' flex={1} className='text-truncate'>
              {item.name}
            </Typography>
          </Tooltip>
        </Box>
      </M3MenuItem>
    );
  } else {
    content = (
      <Tooltip title={item.name} placement='right'>
        <Avatar
          sx={{
            width: 40,
            height: 40,
            fontSize: 18,
            cursor: 'pointer',
            fontFamily: 'Archivo Black',
            whiteSpace: 'nowrap',
            color: isDarkMode
              ? 'var(--md-sys-color-background-dark) !important'
              : 'var(--md-ref-palette-primary100)',
            background: isDarkMode
              ? 'var(--md-ref-palette-primary80) !important'
              : 'var(--md-ref-palette-primary40)',
            '&:hover': {
              opacity: 1,
            },
            ...(active
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0.5,
                }),
          }}
          style={{ backgroundColor }}
          onClick={onClick}
        >
          {
            getUserInitials(
              workspaceName,
              workspaceName.charAt(0) === '-' ? 2 : 1,
            ).initial
          }
        </Avatar>
      </Tooltip>
    );
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        height: showDetail ? undefined : 54,
        width: '100%',
        cursor: 'pointer',
      }}
    >
      {content}
    </Box>
  );
};

export default WorkspaceMenuAvatarItem;
